import React from "react";
import TextInput from "../../../../components/common/form/input/TextInput";
import Textarea from "../../../../components/common/form/input/Textarea";
import SimpleMultipleValueSelect from "../../../../components/common/form/select/multiple/SimpleMultipleValueSelect";
import {useBranchesContext} from "../../../../contexts/Branches";
import ColorPickerSelect from "../../../../components/common/form/color/ColorPickerSelect";

export default function Base({data, errors, updateField, updateFieldNoRender}: any) {
  const {branches} = useBranchesContext()

  return (
    <div className={'flex flex-col px-3 gap-y-6'}>
      <TextInput
        label={'Код'}
        textTooltip={'Код'}
        placeholder={'Введите код'}
        name={'code'}
        value={data?.code}
        onChange={value => updateFieldNoRender('code', value)}
        errors={errors}
        required={true}
      />

      <TextInput
        label={'Название'}
        textTooltip={'Название'}
        placeholder={'Введите название'}
        name={'title'}
        value={data?.title}
        onChange={value => updateFieldNoRender('title', value)}
        errors={errors}
      />

      <Textarea
        label='Описание'
        textTooltip='Описание'
        placeholder='Введите описание'
        name={'description'}
        value={data?.description}
        onChange={value => updateFieldNoRender('description', value)}
        errors={errors}
      />

      <TextInput
        type={'number'}
        min={'0'}
        label={'Сортировка'}
        textTooltip={'Сортировка'}
        placeholder={'564'}
        name={'position'}
        value={data?.position}
        onChange={value => updateFieldNoRender('position', value)}
        errors={errors}
      />

      <div className={'flex w-full gap-x-6'}>
        <ColorPickerSelect
          label={'Цвет текста'}
          textTooltip={'Нет информации'}
          name={'color'}
          placeholder={'Синий #61A0FF'}
          defaultColor={data.color}
          value={data?.color}
          onChange={(value) => updateField('color', value)}
          className={'w-1/2'}
        />
        <ColorPickerSelect
          label={'Цвет фона'}
          textTooltip={'Цвет фона'}
          name={'background'}
          placeholder={'Фиолетовый #9747FF'}
          defaultColor={data.background}
          value={data.background}
          onChange={(value) => updateField('background', value)}
          className={'w-1/2'}
        />
      </div>

      <SimpleMultipleValueSelect
        label={'Филиалы'}
        textTooltip={'Филиалы'}
        options={branches.map(branch => ({label: branch.title, value: branch.id}))}
        value={data.branches || []}
        onChange={value => updateField('branches', value)}
        name={'branches'}
        placeholder={'Выберите филиалы'}
        errors={errors}
      />

    </div>
  )
}
