import React, {useEffect, useId} from "react";
// @ts-ignore
import DatePicker, {registerLocale} from 'react-datepicker';
import "../../../../styles/datepicker.sass"
// @ts-ignore
import calendarIcon from "../../../../images/calendar.svg"
import Tooltip from "../../Tooltip";
import ru from "date-fns/locale/ru";

registerLocale("ru", ru);

export default function SimpleDatePicker(
  {
    label,
    textTooltip,
    required = false,
    value = null,
    onChange,
    dateFormat = "dd-MM-yyyy",
    classNameInput = '',
    errors,
    name = '',
    placeholder = '',
    errorLabel = true,
  }: any
) {
  const id = useId()
  const error = errors[name];

  useEffect(() => {
    delete errors[name];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div>
      {
        label &&
          <label htmlFor={id} className="flex items-center mb-2 font-medium text-sm">
            { label }
              <span className='text-error-main'>{required && '*'}</span>
            {
              textTooltip && <Tooltip textTooltip={textTooltip} defaultText={name}/>
            }
          </label>
      }
      <div className={'relative'}>
        <DatePicker
          id={id}
          selected={value ? Date.parse(value.split('.').reverse().join('.')) : null}
          className={`outline-0 disabled:text-gray-40 disabled:pointer-events-none text-gray-50 py-[10px] px-[14px] w-full text-sm bg-white border-gray-20 border-[1px] rounded-lg pr-[40px] ${classNameInput} ${error && 'border border-red-500 disabled:border disabled:border-red-500'} `}
          onChange={(date: Date) => {
            onChange(date)
          }}
          locale={'ru'}
          dateFormat={dateFormat}
          placeholderText={placeholder}
        />
        <img
          className={`absolute top-1/2 -translate-y-1/2 pointer-events-none right-[10px]`}
          src={calendarIcon}
          alt={'input-icon'}
        />
      </div>

      {error && errorLabel && (<span className={'text-error-font text-xs font-medium'}>{error}</span>)}
    </div>

  )
}
