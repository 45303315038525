import React, {useEffect, useId, useState} from "react";
import BaseButton from "../../../components/common/Button/BaseButton";
import SingleSimpleValueSelect from "../../../components/common/form/select/SingleSimpleValueSelect";
import Textarea from "../../../components/common/form/input/Textarea";
import ImageLoadMultiple from "../../../components/common/form/image/ImageLoadMultiple"
import {fetchEstimations} from "../../../providers/dictionaries/orders";

export default function Base({data, errors, updateField, updateFieldNoRender}: any) {
  const [estimations, setEstimations] = useState<any>([])
  //const [statuses, setStatuses] = useState<any>([])
  const id = useId()

  useEffect(() => {
    fetchEstimations().then((data) => {
      setEstimations(data)
    }).catch(() => {})
    //fetchOrderStatuses().then((data) => {
    //  setStatuses(data)
    //}).catch(() => {})
  }, [])

  return (
    <div className={"flex flex-col px-3 gap-y-6"}>

      <div className={"border-[1px] border-gray-20 rounded-xl p-4"}>
        <label htmlFor={id} className="flex items-center mb-2 font-medium text-sm">
          Статус заказа
        </label>
        <div className={"flex h-[35px] w-full"}>
          <BaseButton onClick={() => updateField('status','moderating')}
                      className={`w-1/2 ${data.status === 'moderating' ? "bg-interactive-elem text-white" : "bg-gray-30 text-gray-40"} rounded-r-none`}>На модерации</BaseButton>
          <BaseButton onClick={() => updateField('status','published')}
                      className={`w-1/2 ${data.status === 'published' ? "bg-interactive-elem text-white" : "bg-gray-30 text-gray-40 "} rounded-none`}>Опубликован</BaseButton>
          <BaseButton onClick={() => updateField('status', 'hidden')}
                      className={`w-1/2 ${data.status === 'hidden' ? "bg-error-main text-white" : "bg-gray-30 text-gray-40 "} rounded-l-none`}>Скрыт</BaseButton>
        </div>
      </div>

      <SingleSimpleValueSelect
        label={'Оценка'}
        name={'estimation'}
        placeholder={'Выберите вариант'}
        value={data.estimation}
        options={estimations}
        onChange={(option: any) => {updateField('estimation', option)}}
      />

      <Textarea
        label='Текст отзыва'
        textTooltip='Текст отзыва'
        placeholder='Введите текст'
        name={'text'}
        value={data.text || ''}
        onChange={value => updateFieldNoRender('text', value.trim().length !== 0 ? value : null)}
        errors={errors}
      />

      <Textarea
        label='Ответ на отзыв'
        textTooltip='Ответ на отзыв'
        placeholder='Введите текст'
        name={'answer'}
        value={data.answer || ''}
        onChange={value => updateFieldNoRender('answer', value.trim().length !== 0 ? value : undefined)}
        errors={errors}
      />

      <ImageLoadMultiple
        label="Фотографии клиента (формат: JPG, JPEG, PNG. Можно загрузить до 4 изображений)"
        onChange={(value: any) => updateField('images', value)}
        onDelete={(value: any) => {
          //console.log(value)
          if (value?.id) {
            if (data?.detachedImages) {
              return updateField('detachedImages', [...data?.detachedImages, value?.id])
            }
            return updateField('detachedImages', [value?.id])
          }
        }}
        value={data.images}
        errors={errors}
        name='images'
        max={4}
        classNameImgWrapper={'!h-[88px]'}
      />
    </div>
  )
}
