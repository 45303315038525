import {apiClient} from "../../libs/api/apiClient";

async function fetchPhoneCodes() {
  const { data }: any = await apiClient.dictionaries.phoneCodes()

  return data.map((code: any) => ({
    label: code.title,
    value: code.id
  }))
}

export default fetchPhoneCodes
