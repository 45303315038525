import { apiClient } from "../libs/api/apiClient";
import { useCompaniesContext } from "./Companies";
import { useBranchesContext } from "./Branches";
import {MakeContext} from "./common";

const [ProductsContextWrapper, useProductsContext] = MakeContext({
  useEverything: () => {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    return {
      isReady: () => (company && branch),
      fetchAll: (queryParams: string) => apiClient.products.getAll(company.id, branch.id, queryParams),
      updateDeps: [company?.id, branch?.id],
      startLimit: 10
    }
  },
  makeValue: (products: any, updateProducts: any, updateFilters: any) => ({
    products, updateProducts, updateFilters
  })
})

export {ProductsContextWrapper, useProductsContext};
