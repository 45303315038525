import {apiClient} from "../../libs/api/apiClient";

export async function fetchLoyaltyPrograms() {
  const { data }: any = await apiClient.dictionaries.loyaltyProgram()

  return data.map((platform: any) => ({
    label: platform.title,
    value: platform.value
  }))
}

export async function fetchRules() {
  const { data }: any = await apiClient.dictionaries.loyaltyProgram()

  return data.map((platform: any) => ({
    label: platform.title,
    value: platform.value
  }))
}
