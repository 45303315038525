import React, {useState} from 'react'
import Checkbox from './../../../components/common/form/checkbox/Checkbox';
import ChevronIcon from '../../../components/icons/ChevronIcon';
import TextInput from '../../../components/common/form/input/TextInput';
import DropDownComment from '../../../components/common/Comment/DropDown';


export default function PaymentSystems({data, updateField, errors, updateFieldNoRender}: any) {
  const [showYooKassa, setShowYooKassa] = useState(true)

  return (
    <div className={'flex flex-col px-3 gap-y-6'}>
        <Checkbox
          label='Передавать заказы только после фактической оплаты (Online)'
          name='paymentSystems.sendOnlyAfterPayment'
          onChange={(value: any) => updateField('paymentSystems.sendOnlyAfterPayment', value)}
          value={data.paymentSystems.sendOnlyAfterPayment}
          errors={errors}
        />

        <div className={`${showYooKassa ? 'h-auto' : 'h-[65px]'} overflow-hidden border-[1px] border-gray-20 rounded-lg`}>
          <div onClick={() => setShowYooKassa(!showYooKassa)} className='flex items-center justify-between cursor-pointer p-4'>
            <div className='text-[22px] font-semibold'>ЮKassa</div>
            <ChevronIcon className={`${showYooKassa && 'rotate-180'}`}/>
          </div>
          <div className='p-4'>
            <div className='flex gap-4 mb-4'>
              <TextInput
                className='w-1/2'
                name='paymentSystems.yooKassa.shopId'
                label='Логин'
                textTooltip='Логин'
                placeholder='Текстовое поле'
                value={data.paymentSystems?.yooKassa?.shopId}
                onChange={(value: any) => updateFieldNoRender('paymentSystems.yooKassa.shopId', value)}
                errors={errors}
              />

              <TextInput
                className='w-1/2'
                name='paymentSystems.yooKassa.secretKey'
                label='Секретный ключ'
                textTooltip='Секретный ключ'
                placeholder='Введите секретный ключ'
                value={data.paymentSystems?.yooKassa?.secretKey}
                onChange={(value: any) => updateFieldNoRender('paymentSystems.yooKassa.secretKey', value)}
                errors={errors}
              />
            </div>
          </div>
          <div className='p-4'>
            <DropDownComment title={''}>
              <div className={"flex flex-col font-medium mt-3 gap-y-2 max-w-[90%]"}>
                <div className='mb-6'>
                  Для корректного получения статусов оплаты необходимо указать адрес для уведомлений в Личном кабинете Юкассы.
                </div>
                <ul>
                  <li>
                      {`• Адрес для уведомлений – ${process.env.REACT_APP_PUBLIC_API_URL || '{адрес}'}/api/yookassa/webhook`}
                  </li>
                  <li>
                    {'• В разделе Интеграция — HTTP-уведомления укажите URL для уведомлений.'}
                  </li>
                  <li>
                    {'• О каких событиях уведомлять — payment.succeeded, payment.waiting_for_capture, payment.canceled, refund.succeeded'}
                  </li>
                </ul>
              </div>
            </DropDownComment>
          </div>
        </div>

    </div>
  )
}
