//

const initForm = {
  review: {},
  preorder: 0,
  orderStatusCode: 'accepted',
  deliveryType: 'courier',
  createdAt: (new Date()).toLocaleString(),
}

export default initForm
