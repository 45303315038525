import React from "react";
import TextInput from "../../../components/common/form/input/TextInput";

export default function Checkout({data, errors, updateFieldNoRender}: any) {
  return (
    <div className={'border-gray-20 border-[1px] rounded-lg p-4 flex flex-col gap-y-3'}>
      <TextInput
        type='number'
        min='0'
        label='Минимальный заказ на доставку'
        textTooltip='Минимальное количество (шт.)'
        placeholder='0'
        name={'deliveryFromTotal'}
        value={data?.deliveryFromTotal}
        onChange={value => updateFieldNoRender('deliveryFromTotal', value)}
        errors={errors}
      />
      <TextInput
        type='number'
        min='0'
        label='Бесплатная доставка от'
        textTooltip='Бесплатная доставка от'
        placeholder='0'
        name={'freeDeliveryFromTotal'}
        value={data?.freeDeliveryFromTotal}
        onChange={value => updateFieldNoRender('freeDeliveryFromTotal', value)}
        errors={errors}
      />
      <TextInput
        type='number'
        min='0'
        label='Стоимость доставки'
        textTooltip='Стоимость доставки'
        placeholder='0'
        name={'deliveryPrice'}
        value={data?.deliveryPrice}
        onChange={value => updateFieldNoRender('deliveryPrice', value)}
        errors={errors}
      />
      <TextInput
        type='number'
        min='0'
        label='Максимальное время доставки (мин.)'
        textTooltip='Максимальное время доставки (мин.)'
        placeholder='0'
        name={'deliveryMaxTime'}
        value={data?.deliveryMaxTime}
        onChange={value => updateFieldNoRender('deliveryMaxTime', value)}
        errors={errors}
      />
      <TextInput
        type='number'
        min='0'
        label='Минимальное время доставки (мин.)'
        textTooltip='Минимальное время доставки (мин.)'
        placeholder='0'
        name={'deliveryMinTime'}
        value={data?.deliveryMinTime}
        onChange={value => updateFieldNoRender('deliveryMinTime', value)}
        errors={errors}
      />
      <TextInput
        label='Артикул товара «Платная доставка»'
        textTooltip='Артикул товара «Платная доставка»'
        name={'deliveryArticle'}
        placeholder='0'
        value={data?.deliveryArticle || ''}
        onChange={value => updateFieldNoRender('deliveryArticle', value.trim().length !== 0 ? value : undefined)}
        errors={errors}
      />
      <TextInput
        type='number'
        min='0'
        label='Минимальное время предзаказа на доставку (мин.)'
        textTooltip='Минимальное время предзаказа на доставку (мин.)'
        placeholder='0'
        name={'preorderOnDeliveryMinTime'}
        value={data?.preorderOnDeliveryMinTime}
        onChange={value => updateFieldNoRender('preorderOnDeliveryMinTime', value)}
        errors={errors}
      />
    </div>
  )
}
