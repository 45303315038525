import * as React from "react";
import { forwardRef, LegacyRef } from "react";

export type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  error?: string,
}

const Input = forwardRef<HTMLInputElement, Props>(function Input(props: Props, ref) {
  const {
    error,
    children,
    type = "text",
    disabled = false,
    readOnly = false,
    required = false,
    ...other
  } = props;

  return (
    <input
      type={ type }
      readOnly={ readOnly }
      disabled={ disabled }
      required={ required }
      className={ `outline-0 border-0 w-full h-auto bg-white disabled:text-gray-40` }
      { ...other }
      ref={ref as LegacyRef<HTMLInputElement>}
    />
  )
})

export default Input
