import {MakeContext} from "./common";
import {useCompaniesContext} from "./Companies";
import {useBranchesContext} from "./Branches";
import {apiClient} from "../libs/api/apiClient";
import React, {createContext, useContext, useEffect, useState} from "react";
import { showErrorToast } from "../libs/helpers/toasts";
import { useParams } from "react-router-dom";

const [FunnelsContextWrapper, useFunnelsContext] = MakeContext({
  useEverything: () => {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    return {
      isReady: () => (company && branch),
      fetchAll: (queryParams: any) => apiClient.funnels.getAll(company.id, branch.id, queryParams),
      updateDeps: [company?.id, branch?.id],
      startLimit: 10
    }
  },
  makeValue: (funnels: any, updateFunnels: any, updateFilters: any) => ({
    funnels, updateFunnels, updateFilters
  })
})

export {FunnelsContextWrapper, useFunnelsContext};

interface IContextProps {
  funnelId: any,
  actions: any
  timers: any
  conditions: any
  filters: any
  inputs: any
  initialAction: any
  dataLoading: boolean
  hasFunnelDataError: boolean
  setInitialAction: (value: any) => void
}

const FunnelData = createContext<IContextProps>({
  funnelId: null,
  actions: [],
  timers: [],
  filters: [],
  conditions: [],
  inputs: [],
  initialAction: null,
  dataLoading: false,
  hasFunnelDataError: false,
  setInitialAction: (value: any) => {}
})

export function FunnelDataWrapper({children}: any) {
  const {company} = useCompaniesContext()
  const {branch} = useBranchesContext()
  const { id: funnelId } = useParams<{ id?: string }>()
  
  const [initialAction, setInitialAction] = useState<any>(null)
  const [actions, setActions] = useState<any>([])
  const [timers, setTimers] = useState<any>([])
  const [conditions, setConditions] = useState<any>([])
  const [filters, setFilters] = useState<any>([])
  const [inputs, setInputs] = useState<any>([])
  const [dataLoading, setDataLoading] = useState<boolean>(false)
  const [hasFunnelDataError, setHasFunnelDataError] = useState<boolean>(false)

  const getNodeConstructorData = () => {
    setDataLoading(true)
    apiClient.funnels.getConstructorNodeData(company.id, branch.id, funnelId, '').then((data: any) => {
      if (data?.actions) {
        setActions(data?.actions)
      }
      if (data?.conditions) {
        setConditions(data?.conditions)
      }
      if (data?.filters) {
        setFilters(data?.filters)
      }
      if (data?.inputs) {
        setInputs(data?.inputs)
      }
      if (data?.timers) {
        setTimers(data?.timers)
      }
    }).catch((reason) => {
      showErrorToast({
        content: "Произошла ошибка при получении данных для воронки. Пожалуйста перезагрузите страницу.",
      })
      setHasFunnelDataError(true)
    }).finally(() => setDataLoading(false))
  }

  useEffect(() => {
    if (company && branch) {
      //getNodeConstructorData()
    }
  }, [company, branch])

  useEffect(() => {
    if (company && branch) {
      getNodeConstructorData()
    }
  }, [initialAction])

  return (
    <FunnelData.Provider value={{ funnelId, actions, timers, conditions, inputs, hasFunnelDataError, filters, dataLoading, initialAction, setInitialAction}}>
      { children }
    </FunnelData.Provider>
  )
}

export function useFunnelDataContext() {
  return useContext(FunnelData)
}
