import React, {useEffect, useState} from 'react'
import TextInput from '../../../components/common/form/input/TextInput'
import Checkbox from '../../../components/common/form/checkbox/Checkbox'
import ColorSelect from '../../../components/common/form/select/ColorSelect'
import BaseButton from '../../../components/common/Button/BaseButton'
import {COLORS} from '../../../constants/colors';
// @ts-ignore
import deleteIcon from '../../../images/delete.svg'
// @ts-ignore
import pencilIcon from '../../../images/pencil.svg'
// @ts-ignore
import applyIcon from '../../../images/apply.svg'
import ascSortIcon from '../../../images/sort_asc.svg'
import descSortIcon from '../../../images/sort-desc.svg'
import nextId from "react-id-generator";

export default function Statuses(
  {
    data,
    updateData,
    updateField,
    errors,
    requiredStatuses = [],
    isEdit = false
  }: any) {
  const [directionCode, setDirectionCode] = useState('asc')
  const [directionText, setDirectionText] = useState('asc')
  const [directionNotification, setDirectionNotification] = useState('asc')


  const addStatus = () => {
    let statuses = data.statuses.slice(0)

    statuses.push({
      "key": nextId(),
      "code": "",
      "title": "",
      "color": COLORS.at(0),
      "listen": false,
      "notification": "",
      "required": false,
      'edit': true,
    })

    updateField('statuses', statuses);
  }

  const delStatus = (index: any) => {
    let statuses = data.statuses.slice(0)

    statuses.splice(index, 1)

    updateField('statuses', statuses);
  }

  const editRow = (statuses: any) => {
    updateData({statuses: statuses})
  }

  const saveEditStatus = (statuses: any) => {
    updateField('statuses', statuses);
  }

  const sortByCode = () => {
    let statuses = data.statuses.slice(0)
    statuses.sort((a: any, b: any) => {
      if (directionCode === 'asc') {
        return (a.code > b.code) ? 1 : (a.code < b.code) ? -1 : 0
      } else {
        return (a.code < b.code) ? 1 : (a.code > b.code) ? -1 : 0
      }
    });
    updateData({statuses: statuses})
    setDirectionCode(directionCode === 'asc' ? 'desc' : 'asc')
    setDirectionText('asc')
    setDirectionNotification('asc')
  }

  const sortByText = () => {
    let statuses = data.statuses.slice(0)
    statuses.sort((a: any, b: any) => {
      if (directionText === 'asc') {
        return (a.title > b.title) ? 1 : (a.title < b.title) ? -1 : 0
      } else {
        return (a.title < b.title) ? 1 : (a.title > b.title) ? -1 : 0
      }
    });
    updateData({statuses: statuses})
    setDirectionText(directionText === 'asc' ? 'desc' : 'asc')
    setDirectionCode('asc')
    setDirectionNotification('asc')
  }

  const sortByNotification = () => {
    let statuses = data.statuses.slice(0)
    statuses.sort((a: any, b: any) => {
      if (directionNotification === 'asc') {
        return (a.notification > b.notification) ? 1 : (a.notification < b.notification) ? -1 : 0
      } else {
        return (a.notification < b.notification) ? 1 : (a.notification > b.notification) ? -1 : 0
      }
    });
    updateData({statuses: statuses})
    setDirectionNotification(directionNotification === 'asc' ? 'desc' : 'asc')
    setDirectionCode('asc')
    setDirectionText('asc')
  }

  return (
    <>
      <div className='flex h-16 border-b-[1px] border-gray-30'>
        <div className='w-[4%] flex items-center justify-center text-center'>ID</div>
        <div className='w-[10%] flex items-center' onClick={sortByCode}>
          <span className={'mr-2'}>Код</span>
          <img className={'cursor-pointer'} src={directionCode === 'asc' ? ascSortIcon : descSortIcon} alt=""/>
        </div>
        <div className='w-[24%] flex items-center' onClick={sortByText}>
          <span className={'mr-2'}>Название статуса</span>
          <img className={'cursor-pointer'} src={directionText === 'asc' ? ascSortIcon : descSortIcon} alt=""/>
        </div>
        <div className='w-[30%] flex items-center' onClick={sortByNotification}>
          <span className={'mr-2'}>Уведомления пользователю</span>
          <img className={'cursor-pointer'} src={directionNotification === 'asc' ? ascSortIcon : descSortIcon} alt=""/>
        </div>
        <div className='w-[8%] flex items-center justify-center text-center'>Цвет</div>
        <div className='w-[13%] flex items-center justify-center text-center'>Отслеживать изменения</div>
        <div className='w-[11%] flex items-center justify-center text-center'>Действия</div>
      </div>

      <div>
        {
          data?.statuses?.map((status: any, index: any) => (
            <div key={status.key} className='flex h-16 border-b-[1px] border-gray-30'>
              <div className='w-[4%] flex items-center justify-center text-center'>{status.id}</div>

              <div className='w-[10%] p-1 flex items-center'>
                <TextInput
                  errorLabel={false}
                  name={`statuses.${index}.code`}
                  errors={errors}
                  disabled={!data.statuses[index].edit || (requiredStatuses.some(({code}: any) => code === status.code) && status.required)}
                  placeholder='Код'
                  value={status.code}
                  onChange={(value: any) => {
                    let statuses = data.statuses.slice(0)
                    statuses[index].code = value
                    editRow(statuses)
                  }}
                  classNameInput='text-center disabled:border-0'
                />
              </div>

              <div className='w-[24%] p-1 flex items-center'>
                <TextInput
                  errorLabel={false}
                  className='w-full'
                  name={`statuses.${index}.title`}
                  errors={errors}
                  placeholder='Текст'
                  value={status.title}
                  disabled={!data.statuses[index].edit}
                  onChange={(value: any) => {
                    let statuses = data.statuses.slice(0)
                    statuses[index].title = value
                    editRow(statuses)
                  }}
                  classNameInput='disabled:border-0'
                />
              </div>

              <div className='w-[30%] p-1 flex items-center'>
                <TextInput
                  errorLabel={false}
                  className='w-full'
                  name={`statuses.${index}.notification`}
                  errors={errors}
                  placeholder='Текст'
                  disabled={!data.statuses[index].edit}
                  value={status.notification}
                  onChange={(value: any) => {
                    let statuses = data.statuses.slice(0)
                    statuses[index].notification = value
                    editRow(statuses)
                  }}
                  classNameInput={'disabled:border-0'}
                />
              </div>

              <div className='w-[8%] flex items-center justify-center text-center'>
                <ColorSelect
                  options={COLORS}
                  value={status.color}
                  defaultColor={status.color}
                  disabled={!data.statuses[index].edit}
                  onChange={(value: any) => {
                    let statuses = data.statuses.slice(0)
                    statuses[index].color = value
                    editRow(statuses)
                  }}
                />
              </div>

              <div className='w-[13%] flex items-center justify-center text-center'>
                <Checkbox
                  onChange={(value: any) => {
                    let statuses = data.statuses.slice(0)
                    statuses[index].listen = value
                    editRow(statuses)
                  }}
                  disabled={!data.statuses[index].edit}
                  value={status.listen}
                  errors={errors}             
                    />
              </div>

              <div className='w-[11%] flex items-center justify-center'>
                <img className='cursor-pointer' onClick={() => {
                  let statuses = data.statuses.slice(0)
                  if (statuses[index].edit) {
                    statuses[index].edit = false
                    editRow(statuses)
                    saveEditStatus(statuses)
                  } else {
                    statuses[index].edit = true
                    editRow(statuses)
                  }
                }} src={data.statuses[index].edit ? applyIcon : pencilIcon} alt=""/>
                {
                  (!requiredStatuses.some(({code}: any) => code === status.code) || (!status.required)) &&
                    <img className='cursor-pointer' onClick={() => delStatus(index)} src={deleteIcon} alt=""/>
                }
              </div>
            </div>
          ))
        }
      </div>

      <BaseButton className={'text-white bg-interactive-elem border-btn-secondary rounded-md border-[1px] py-[7px] absolute top-[-10%] right-0'} onClick={() => addStatus()}>Добавить статус</BaseButton>

    </>
  )
}
