import React, {useState} from "react";
import DataTable from "../../components/common/Table/DataTable";
import {createColumnHelper} from "@tanstack/react-table";
import {useStocksContext} from "../../contexts/Stocks";
import {Link} from "react-router-dom";
import {getDateAndTime} from "../../libs/helpers/datetime";
import {submitAction} from "./massActions";
import {showSuccessToast} from "../../libs/helpers/toasts";
import {useCompaniesContext} from "../../contexts/Companies";
import useForm from "../../hooks/useForm";
import initForm from "./initForm";
import SingleSimpleValueSelect from "../../components/common/form/select/SingleSimpleValueSelect";
import BaseButton from "../../components/common/Button/BaseButton";
import SimpleMultipleValueSelect from "../../components/common/form/select/multiple/SimpleMultipleValueSelect";
import {useBranchesContext} from "../../contexts/Branches";

export default function TableStocks() {
  const {stocks, updateStocks, loading, pagination, limits, filters}: any = useStocksContext()
  const {company} = useCompaniesContext()
  const {branch, branches} = useBranchesContext()

  const {data, updateField, updateData, submit, changedFieldsRefresh} = useForm(JSON.parse(JSON.stringify(initForm)))

  const [action, setAction] = useState<null | string>(null)

  const columnHelper = createColumnHelper<any>()

  const columns = [
    columnHelper.accessor(row => row.id, {
      id: 'ID',
      header: () => 'ID',
      cell: info => <div className={'w-[5%] flex items-center justify-center text-center'}>{ info.getValue() }</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[5%] flex items-center justify-center text-center'
      }
    }),
    columnHelper.accessor(row => row.image, {
      id: 'image',
      header: () => 'Фото',
      cell: info =>
        <div className={'w-[min(12%,150px)] py-1 flex items-center justify-center text-center'}>
          {
            info.getValue() ?
              <img src={info.getValue()} alt=""/> :
              <div
                className='h-[60px] w-full flex items-center justify-center text-[10px] text-gray-40 border-[1.5px] border-btn-secondary rounded-xl'>Image</div>
          }
        </div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[min(12%,150px)]  flex items-center justify-center text-center'
      }
    }),
    columnHelper.accessor(row => row.title, {
      id: 'title',
      header: () => 'Название',
      cell: info => <div className={'w-[25%] px-3 flex items-center text-interactive-elem'}>
        <Link to={`/stocks/${info.row?.original?.id}/edit`}>
          {info.getValue()}
        </Link>
      </div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[25%] pl-3',
        sortField: 'title'
      }
    }),
    columnHelper.accessor(row => row.position, {
      id: 'position',
      header: () => 'Сортировка',
      cell: info => {
        console.log({info})
        return (<div className={' w-[15%] flex justify-center items-center'}>
          {info.getValue()}
        </div>)
      },
      enableSorting: true,
      meta: {
        widthClass: ' w-[15%] flex justify-center text-center ',
        sortField: 'position'
      }
    }),
    columnHelper.accessor(row => row.endAt, {
      id: 'endAt',
      header: () => 'Дата окончания',
      cell: info => <div
        className={'font-normal break-words text-interactive-text flex items-center  w-[20%]'}>{info.getValue() ? getDateAndTime(info.getValue()) : ''}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'flex justify-center text-center  w-[20%]',
        sortField: 'endAt'
      }
    }),
    columnHelper.accessor(row => row.branchesCount, {
      id: 'branchesCount',
      header: () => 'Ф',
      cell: info => <div className={' w-[5%] flex items-center justify-center'}>{ info.getValue() }</div>,
      enableSorting: true,
      meta: {
        widthClass: ' w-[5%] justify-center',
        sortField: 'branchesCount'
      }
    }),
    columnHelper.accessor(row => row.platforms.join(' / '), {
      id: 'platforms',
      header: () => 'Платформа',
      cell: info => <div className={' w-[25%] flex justify-center items-center'}>{ info.getValue() }</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[25%]  justify-center',
        sortField: 'platforms'
      }
    }),
  ]

  //not working
  const submitMassAction = () => {
    if (action === null) return
    submit((form: any) => {
      console.log([...form.entries()])
      submitAction(() => {
        showSuccessToast({
          content: 'Действие выполнено успешно'
        })
        changedFieldsRefresh()
        updateStocks(limits, filters)
        updateData({
          stocksIds: [],
        })
        setAction(null)
      }, action, 'stocks', company.id, branch.id, form)
    })
  }

  return (
    <div>
      {
        branch ?
          <>
            {
              stocks && stocks.length ?
                <div>
                  <DataTable
                    data={stocks}
                    columns={columns}
                    usePagination={true}
                    defaultLimit={10}
                    defaultPage={1}
                    pagination={pagination}
                    updateData={(params: any) => updateStocks(params)}
                    loading={loading}
                    useMassAction={true}
                    selectedRows={data.stocksIds || []}
                    onChangeSelectionRows={(values: any) => {
                      updateField('stocksIds', values)
                    }}
                    rowsWithCustomBg={stocks.filter((stock: any) => !stock.active).map(({id}: any) => ({id, color: 'bg-error-main/[.5]'}))}
                  />
                  {data.stocksIds?.length > 0 && <div className={"w-full rounded-xl bg-white px-3 py-5 my-10 flex items-center justify-between"}>
                      <div className={"w-5/6 flex items-center justify-center gap-x-4"}>
                          <SingleSimpleValueSelect
                              name={'xs'}
                              placeholder={'Выберите действие'}
                              value={action}
                              options={[
                                {label: 'Выберите действие', value: null},
                                {label: 'Привязать филиал', value: 'attach'},
                                {label: 'Отвязать филиал', value: 'detach'}
                              ]}
                              onChange={(option: any) => {
                                setAction(option)
                              }}
                          />
                        {
                          (action === 'attach' || action === 'detach')  &&
                            <SimpleMultipleValueSelect
                                label={undefined}
                                textTooltip={undefined}
                                options={branches.filter(({id}: any) => id !== branch?.id).map((branch: { title: any; id: any; }) => ({label: branch.title, value: branch.id}))}
                                value={data.branchesIds || []}
                                onChange={(value: any) => updateField('branchesIds', value)}
                                name={'branchesIds'}
                                placeholder={'Выберите филиалы'}
                                errors={{}}
                                className={'w-[350px]'}
                            />
                        }
                      </div>

                      <BaseButton onClick={submitMassAction}
                                  className={"w-[250px] ml-5 bg-interactive-elem text-white"}>Изменить {data.stocksIds?.length} акции
                      </BaseButton>
                  </div>}
                </div>
                :
                <div className="text-gray-40 text-3xl text-center mt-[35vh]">
                  {loading ? 'Загрузка...' : 'У вас еще нет акций'}
                </div>
            }
          </>
          :
          <div className="text-gray-40 text-3xl text-center mt-[35vh]">
            Выберите филиал
          </div>
      }
    </div>
  )
}
