import React from "react";
import {useCompaniesContext} from "../../../../contexts/Companies";
import {useBranchesContext} from "../../../../contexts/Branches";

import useForm from "../../../../hooks/useForm";
import {apiClient} from "../../../../libs/api/apiClient";
import initForm from "../initForm";
import Breadcrumbs from "../../../../components/common/Breadcrumbs";
import BaseButton from "../../../../components/common/Button/BaseButton";
import Base from "../Base"
import {useNavigate} from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../../../libs/helpers/toasts";

export default function CreateLoyaltyStatus() {
  const {company} = useCompaniesContext()
  const {branch} = useBranchesContext()

  const navigate = useNavigate()
  const {data, updateField, updateFieldNoRender, errors, updateErrors, submit} = useForm(JSON.parse(JSON.stringify(initForm)))

  const handleSubmit = () => {
    submit(async (form: any) => {
      apiClient.statusesLoyalty.create(company?.id, branch?.id, {body: form}).then(({data, errors}: any) => {
        if (data) {
          showSuccessToast({
            content: "Статус успешно создан",
          })
          navigate('/loyalty-programs')
        } else {
          updateErrors(errors || {});

          showErrorToast({
            content: 'Что-то пошло не так'
          })

        }
      })
    })

  }

  return (
    <div className='px-8 pb-8 pt-2 text-gray-50'>
      <div className='mb-8'>
        <div className='text-[44px] leading-[60px] mb-2'>Программа лояльности</div>
        <Breadcrumbs
          links={[
            {to: `/loyalty-programs`, label: 'Список статусов'},
            {to: '', label: 'Создание статуса'},
          ]}
          back={`/loyalty-programs`}
        />
      </div>
      <div className={'rounded-xl bg-white py-5'}>
        <Base errors={errors} data={data} updateField={updateField} updateFieldNoRender={updateFieldNoRender}/>
        <div className={'mt-6 border-gray-10 border-y-[1px] flex justify-end z-[50] w-full sticky bottom-0 bg-white'}>
          <BaseButton
            className={'w-[225px] bg-interactive-elem text-white m-4 py-[7px] '} onClick={handleSubmit}>Добавить статус
          </BaseButton>
        </div>
      </div>
    </div>
  )
}
