import React from 'react'
// @ts-ignore
import arrowDownImg from '../../../images/arrow-down.svg'

export default function FormWrapper({children, tabs=[], tab, setTab, withArrowGroup = true, tabsHasError=[], heightForm = 'min-h-[770px]'}: any) {
  return (
    <div className={`flex w-full py-3 bg-white rounded-2xl ${heightForm}`}>
      <div className='min-w-[250px] break-words text-sm border-r-[1px]'>
        {
          tabs && tabs.map((elem: any) => {
            if (elem?.options) {
              return (
                <div key={elem.label}>
                  <div className='flex justify-between align-center pl-2 py-[14px]'>
                    <span className='font-bold'>{ elem.label }</span>
                    {
                      withArrowGroup && <img src={arrowDownImg} alt="" />
                    }
                  </div>

                  <div>
                    {
                      elem.options.map((option: any) => (
                        <div
                          key={elem.tag}
                          className={`font-medium pl-9 py-[14px] cursor-pointer ${tabsHasError.includes(elem.tag) ? 'text-red-500' : ''}  ` + (option.tag == tab ? 'bg-interactive-bg text-interactive-text border-r-[3px] border-interactive-text mr-[-1px]' : '')}
                          onClick={() => setTab(option.tag)}
                        >{ option.label }<span className='text-error-main'>{elem?.required && '*'}</span></div>
                      ))
                    }
                  </div>
                </div>
              )
            }

            return (
              <div
                key={elem.tag}
                className={`font-medium pl-2 py-[14px] cursor-pointer ${tabsHasError.includes(elem.tag) ? 'text-red-500' : ''} ` + (elem.tag == tab ? 'bg-interactive-bg text-interactive-text border-r-[3px] border-interactive-text mr-[-1px]' : '')}
                onClick={() => setTab(elem.tag)}
              >{ elem.label }<span className='text-error-main'>{elem?.required && '*'}</span></div>
            )
          })
        }
      </div>

      { children }
    </div>
  )
}
