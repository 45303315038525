//

const initForm = {
  isActive: true,
  isEstimatable: true,
  additionalPhotos: [],
  detachedImages: [],
  foodValuePerHundredGram: {},
  foodValuePerPortion: {},
  __branches_select: [],
  __categories__select: [],
  openHours: {
    monday: {
      active: true,
      hours: ['00:00-24:00']
    },
    tuesday: {
      active: true,
      hours: ['00:00-24:00']
    },
    wednesday: {
      active: true,
      hours: ['00:00-24:00']
    },
    thursday: {
      active: true,
      hours: ['00:00-24:00']
    },
    friday: {
      active: true,
      hours: ['00:00-24:00']
    },
    saturday: {
      active: true,
      hours: ['00:00-24:00']
    },
    sunday: {
      active: true,
      hours: ['00:00-24:00']
    },
  },
  platforms: ['site', 'ios', 'vk', 'android'],
  isTop: false,
  canBuyForFree: false,
  isDividedByBranches: false,
  __goodsData: [],
  goods: [],
  __tagsData: [],
  tags: [],
  optionsGroups: [],
  __optionsGroups__select: [],
  __skuGroups__select: [],
  __addToOrder__select: [],
  skuGroups: [],
}

export default initForm
