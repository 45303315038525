import React from "react"
import BaseButton from "../Button/BaseButton";
// @ts-ignore
import conditionImg from "../../../images/condition.svg"
// @ts-ignore
import flashImg from "../../../images/flash.svg"
// @ts-ignore
import stopWatchImg from "../../../images/stopwatch.svg"
// @ts-ignore
import backArrow from "../../../images/arrow-left.svg"
import StepSearch from "../../../pages/Funnels/CreateFunnel/StepSearch";

export enum AddActionEnum {
  condition = 'condition',
  action = 'action',
  timeout = 'timeout'
}

interface IProps {
  reset: () => void,
  title: string,
  handleAddAction: (action: AddActionEnum) => void
  handleSearchNode: (search: string) => void
  initialAction: string
}
export default function FunnelHeader({ reset, title, handleAddAction, handleSearchNode, initialAction }: IProps) {

  return (
    <div className={`flex justify-between items-center px-8 h-[88px] bg-gray-50 text-white`}>
      <div className={'w-5/12 flex flex-row items-center gap-x-4'}>
        <img className={'cursor-pointer p-6 pl-0'} src={backArrow} alt="" onClick={reset} />
        {title}
      </div>
      {
        initialAction && <div className={'w-7/12 flex flex-row items-center justify-between gap-x-4'}>
          <div className={'flex flex-row gap-x-4 w-1/2 items-center justify-end'}>
            <BaseButton
              className={'w-[130px] bg-funnels-yellow text-black flex flex-row items-center gap-x-2 hover:opacity-80'}
              onClick={() => handleAddAction(AddActionEnum.condition)}>
              <img src={conditionImg} alt="" />
              Условие
            </BaseButton>
            <BaseButton
              className={'w-[130px] bg-funnels-green text-black flex flex-row items-center gap-x-2 hover:opacity-80'}
              onClick={() => handleAddAction(AddActionEnum.action)}>
              <img src={flashImg} alt="" />
              Действие
            </BaseButton>
            {
              initialAction !== 'cart' &&
              <BaseButton
                className={'w-[130px] bg-funnels-orange text-black flex flex-row items-center gap-x-2 hover:opacity-80'}
                onClick={() => handleAddAction(AddActionEnum.timeout)}>
                <img src={stopWatchImg} alt="" />
                Таймер
              </BaseButton>
            }
          </div>
          <StepSearch
            className={'w-1/2'}
            fetchData={(value: any) => {
              if (value) {
                handleSearchNode(value)
              }
            }}
          />
        </div>
      }
    </div>
  )
}
