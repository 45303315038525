import React, { useEffect, useRef, useState } from "react";
import BaseButton from "../Button/BaseButton";
import ExportModal from "./ExportModal";
import { fetchExportFiles, startExport } from "./helpers";
import { useCompaniesContext } from "../../../contexts/Companies";
import { useBranchesContext } from "../../../contexts/Branches";
import { showErrorToast, showWarningToast } from "../../../libs/helpers/toasts";
import ChevronIcon from '../../icons/ChevronIcon'
import fileDownload from 'js-file-download'

interface IProps {
	title: string,
	types?: Array<EncodingType>
	filter?: any
	order?: any
	classNameWrapper?: string
}

type EncodingType = {
	type: string,
	title: string
}


const Export = ({
	title,
	types = [],
	filter = null,
	order = null,
	classNameWrapper = ''
}: IProps) => {
	const {company}: any = useCompaniesContext()
	const {branch}: any = useBranchesContext()
	const [showExportModal, setShowExportModal] = useState(false)
	const [showExportFiles, setShowExportFiles] = useState(false)
	const [target, setTarget] = useState<any>(null)
	const [exportFiles, setExportFiles] = useState<any>([])

	const handleExport = ({fileType, encoding, email}: {fileType: any, encoding: any, email: string}) => {
		if ('limit' in filter) {
			delete filter?.limit;
		}
		
		const exportData = { type: fileType, encoding, ...filter, order, emails: email }

		startExport(company?.id, branch?.id, exportData, target)
	}

	const handleModal = (type: string) => {
		setTarget(type);
		setShowExportModal(true)
	}

	const getExportFiles = () => {
		Promise.allSettled(types.map(({type}: any) => (
			new Promise((resolve, reject) => {
				fetchExportFiles(company?.id, branch?.id, type).then((currentExportFiles: any) => {
					resolve(currentExportFiles)
				}).catch((e) => {
					console.log(e)
					reject(e)
				})
			})
		))).then(results => {
			let files: any = []
			results.map((result: any) => {
				const { value: val, reason }: any = result
				if (reason === 'cancel') {
					return
				}

				files = [...files, ...val]
			})
			setExportFiles(files)
		}).catch((reason) => {
			console.log(reason)
		})
	}

	const apiDefinition = async (fileUrl: string) => {
		return new Promise<any>((resolve, reject) => {
			fetch(new URL(fileUrl)).then(async response => {
				response.clone().blob()
					.then(data => resolve(data))
					.catch(() => response.text())
					.then(data => resolve(data))
			})
			.catch(error => {
				reject(error)
			})
		})
	}

	const download = async (fileUrl: string, fileName: string) => {
		try {
			const response: any = await apiDefinition(fileUrl);
			fileDownload(response, fileName);
		} catch (error) {
			showErrorToast({
				content: 'При загрузке файла произошла ошибка!'
			})
			console.log('error downloading file export', error)
		}
	};

	useEffect(() => {
		if (company && branch) {
			getExportFiles()
		}
	}, [company, branch])

	return (
		<div className={`flex flex-col gap-y-4 border-gray-20 border-[1px] p-4 rounded-xl w-full ${classNameWrapper}`}>
			<div className={'flex flex-row items-center justify-between'}>
				<p className={"font-medium"}>{title}</p>
				<div className={"flex justify-between gap-x-3"}>
					<div className={'flex gap-x-4 items-end'}>
						{
							types?.map(({title, type}: any) => (
								<BaseButton onClick={() => handleModal(type)} className={"bg-interactive-elem text-white"}>{title}</BaseButton>
							))
						}
					</div>
				</div>
			</div>

			<div className={' flex flex-row gap-x-2 items-center text-xs cursor-pointer hover:text-interactive-text'} onClick={() => {
				if (exportFiles?.length == 0) {
					showWarningToast({
						content: "Готовые файлы экспорта пока отсутствуют"
					})
				} else {
					setShowExportFiles(!showExportFiles)
				}
			}}>
				<span>{showExportFiles ? 'Скрыть готовые файлы экспорта' : 'Показать готовые файлы экспорта'}</span>
				<ChevronIcon className={`duration-300 ${showExportFiles && 'rotate-180'}`} />
			</div>

			<div className={'flex flex-col gap-y-4 w-full overflow-hidden duration-500'} style={{ height: showExportFiles ? `${55 * (exportFiles?.length)}px` : '0px' }}>
					{
						exportFiles?.map((file, index) => (
							<div className={'flex flex-row gap-x-4 items-center min-h-[40px]'}>
								<div className={'text-sm'}>{index + 1}</div>
								<div className={'text-sm'}>{file?.name}</div>
								<div className={'text-sm'}>Кодировка: {file?.encoding || 'не определена'}</div>
								<div className={'text-sm'}>
									<BaseButton 
									className={"border-interactive-elem text-interactive-elem rounded-xl py-[7px] px-3 border-[1px]"} 
									onClick={() => download(file?.link, file?.name)}>Скачать</BaseButton>
								</div>
							</div>
						))
					}
			</div>

			<ExportModal
				title={'Экспорт'}
				onReject={(response) => {
					setShowExportModal(false)
				}}
				onResolve={({ isConfirmed, data }: any) => {
					if (isConfirmed) {
						handleExport(data)
					}
					setShowExportModal(false)
				}} 
				isOpen={showExportModal}			
				classNameWrapper={'min-w-[400px]'}
				target={target}
			/>
		</div>
	);
}

//const ExportWrapper


export default Export;