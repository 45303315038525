export interface Address {
    block?: string|null
    branch_order_city_title?: string|null
    city?: string | null
    city_fias_id?: string | null
    city_with_type?: string | null
    code?: string | null
    deleted_at?: string | null
    guid?: string | null
    house?: string | null
    id?: Number | null
    kladr_id?: string | null
    lat?: string | null
    lon?: string | null
    region?: string | null
    region_with_type?: string | null
    slug?: string | null
    street?: string | null
    street_with_type?: string | null
    title?: string | null
}

export const getSimpleAddress = (address: Address) => {
    return `${address?.city_with_type ? address.city_with_type : address?.city}, ${address?.title ? address?.title : `${address?.street} ${address?.house}`}`
}