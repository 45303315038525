import React, {useEffect, useRef, useState} from "react"
import {MAX_FILE_SIZE} from "../../../../constants/files";
import BaseButton from "../../Button/BaseButton";
import Tooltip from "../../Tooltip";
import { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import {loadAndProcessImage} from "./CropAndResizeImage";

interface IImageLoadProps {
  className?: string
  classPlaceholderBox?: string
  checkImageValid?: (img: HTMLImageElement, dataUrl: string, file: File) => string | null
  onInput: Function
  name: string
  errors: any
  label?: string
  placeholder?: string
  defaultValue?: any
  onRemove?: Function
  required?: boolean
  textTooltip?: string
  forceAspectRatio?: any
  resizeTo?: Array<number>|any
  allowUpscale?: any
  isBase64?: boolean
}

export default function ImageLoad({
  className='',
  classPlaceholderBox='',
  checkImageValid=()=>null,
  onInput,
  name,
  errors = {},
  label = 'Загрузить изображение',
  placeholder='Image',
  defaultValue=null,
  onRemove=()=>{},
  required = false,
  textTooltip = '',
  forceAspectRatio = null,
  resizeTo = null,
  allowUpscale = false,
  isBase64 = false
}: IImageLoadProps) {
  const input = useRef<any>(null);
  const [files, setFiles] = useState<any>([])
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [incorrectFilesError, setIncorrectFilesError] = useState('');
  const [defaultUrl, setDefaultUrl] = useState(defaultValue)
  const error = errors[name];

  useEffect(() => {
    setDefaultUrl(defaultValue)
  }, [defaultValue])

  useEffect(() => {

    if (isBase64) {
      const base64 = selectedFiles[0] || defaultUrl
      if (base64 != null) {
        onInput && onInput(base64);
      }
    } else {
      const f = files[0] || defaultUrl
      if ( typeof f !== 'string') {
        if (f != null) {
          onInput && onInput(f);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const handleInput = ({target}: any) => {
    let targetFiles: any = []
    const urls: any = []
    const reasons: any[] = []

    setIncorrectFilesError('')
    delete errors[name]

    const bigFiles = [];
    targetFiles = targetFiles.filter((file: any) => {
      if (MAX_FILE_SIZE < file.size) {
        bigFiles.push(file.name)
      }

      return MAX_FILE_SIZE >= file.size
    })

    if (0 < bigFiles.length) {
      setIncorrectFilesError(`Не все файлы были добавлены: слишком большой размер. Максимальный размер файла: 5Mb`)
    }

    Promise.allSettled([...target.files].map(getImageUrl)).then(results => {
      results.map((result, index) => {
        const {value, reason}: any = result
        if (reason === 'cancel')
          return

        if (value) {
          urls.push(value.url);
          targetFiles.push(value.file);
        }
        reason && reasons.push(reason);
      })
      0 < urls.length && setFiles((files: any) => [...files, ...targetFiles]);
      0 < urls.length && setSelectedFiles((selectedFiles: any) => [...selectedFiles, ...urls])
      0 < reasons.length && (setIncorrectFilesError(value => `${0 >= value.length && 'Не все выбранные файлы были добавлены: ' || ', '} ${reasons.join(', ')}`))
    }).then(() => {
      if (input.current)
        input.current.value = null
    })
  }

  const cropperRef = useRef<ReactCropperElement>(null)
  function getImageUrl(file: any) {
    return loadAndProcessImage(file, {
      cropperRef,
      forceAspectRatio,
      resizeTo,
      allowUpscale,
      checkImageValid,
    });
  }

  const handleClear = () => {
    input.current.value = null;
    setDefaultUrl(null);
    setFiles([]);
    setSelectedFiles([]);
    setIncorrectFilesError('');
    onRemove()
    delete errors[name];
  }

  const handleClick = () => {
    input.current.click();
  }

  return (
    <div className={`flex gap-4 ${className}`}>
      {selectedFiles.length > 0 &&
        <div className="flex flex-wrap gap-2">
          {selectedFiles.map((item: any, index: any) => (
            <div key={item} className=''>
              <div className={`h-[76px] relative rounded-lg overflow-hidden flex item-center justify-center`}>
                <img src={item} className="h-full w-auto" alt={'uploaded-image'} />
              </div>
            </div>
          ))}
        </div>
      }

      {
        !!defaultUrl &&  selectedFiles.length == 0 &&
        <div className="flex flex-wrap gap-2">
          <div className=''>
            <div className={`h-[76px] relative overflow-hidden`}>
              <img src={defaultUrl} className="h-full w-auto rounded-lg" alt={'uploaded-image'} />
            </div>
          </div>
        </div>
      }

      {
        selectedFiles.length == 0 && !defaultUrl &&
        <div onClick={handleClick} className={`flex items-center justify-center text-[10px] text-gray-40 min-w-[76px] max-w-[76px] h-[76px] border-[1.5px] border-btn-secondary rounded-xl cursor-pointer ${classPlaceholderBox}`}>
          { placeholder }
        </div>
      }

      <input className="invisible absolute w-0" ref={input} onChange={handleInput} type="file" name="images" accept="image/*" multiple={false} />
      <div className="flex flex-col justify-between">
        {
          label &&
          <label className="flex items-center mb-2 font-medium text-sm">
            { label }
              <span className='text-error-main'>{required && '*'}</span>
            {
              textTooltip && <Tooltip textTooltip={textTooltip} defaultText={name}/>
            }
          </label>
        }
        {
          (selectedFiles.length === 0 && !defaultUrl) &&
          <BaseButton className="border-btn-secondary text-interactive-text rounded-md border-[1px] py-[7px] w-min" onClick={handleClick}>Загрузить</BaseButton>
        }
        {
          !(selectedFiles.length === 0 && !defaultUrl) &&
          <BaseButton className="border-state-error-main text-error-font rounded-md border-[1px] py-[7px] w-min" onClick={handleClear}>Удалить</BaseButton>
        }
      </div>
      {error && (<span className={'text-error-font text-xs font-medium'}>{error}</span>)}
      {incorrectFilesError && (<span className={'text-error-font text-xs font-medium'}>{incorrectFilesError}</span>)}
    </div>
  )
}
