import React, {useEffect, useState} from "react"
import {useBranchesContext} from "../../contexts/Branches";
import {createColumnHelper} from "@tanstack/react-table";
import {Link, useNavigate} from "react-router-dom";
import BaseButton from "../../components/common/Button/BaseButton";
import FormWrapper from "../../components/common/form/FormWrapper";
import initialTabs from "./tabs";
import CreateFormFields from "../../components/common/form/CreateFormFields";
import DataTable from "../../components/common/Table/DataTable";
import {useFunnelsContext} from "../../contexts/Funnels";
import CreateModal from "../../components/common/Modal/Funnels/CreateModal";
import useForm from "../../hooks/useForm";
import {apiClient} from "../../libs/api/apiClient";
import {useCompaniesContext} from "../../contexts/Companies";
import {fetchInitialTypes, fetchOverlapTypes} from "../../providers/dictionaries/funnels";
import {showErrorToast, showSuccessToast} from "../../libs/helpers/toasts"
import SingleSimpleValueSelect from "../../components/common/form/select/SingleSimpleValueSelect";
import {submitAction} from "./massActions";
import {getFormData} from "../../libs/helpers/formData";
import initialData from "./initForm";

const defaultForm = {
  startAt: null,
  endAt: null,
  isActive: true,
  initialActionType: '',
  title: '',
  //position: '',
  overlapType: '',
  branches: [],
}

export default function Funnels() {
  const {company} = useCompaniesContext()
  const [tabs, setTabs] = useState<any>(initialTabs)
  const [selectedRows, setSelectedRows] = useState([])
  const [tab, setTab] = useState('funnels')
  const [action, setAction] = useState<any>(null)
  const [funnelGroupId, setFunnelGroupId] = useState<any>(null)
  const [funnelGroups, setFunnelGroups] = useState<any>([])
  const {branch} = useBranchesContext()
  const navigate = useNavigate();
  const [initialNodeTypes, setInitialNodeTypes] = useState<any>([])
  const [overlapTypes, setOverlapTypes] = useState<any>([])
  const [isShowModal, setIsShowModal] = useState<any>(false)
  const [isEditModal, setIsEditModal] = useState<boolean>(false)
  const [editFunnelId, setEditFunnelId] = useState<any>(null)
  const [hasFunnelGroups, setHasFunnelGroups] = useState(false)

  const {
    funnels,
    pagination,
    loading: funnelsLoading,
    limits,
    filters,
    updateFunnels,
    updateFilters
  }: any = useFunnelsContext()
  const {data, updateData, errors, submit, updateField, updateFieldNoRender, changedFieldsRefresh, updateErrors} = useForm(initialData)

  const columnHelper = createColumnHelper<any>()
  const columns = [
    columnHelper.accessor(row => row.id, {
      id: 'id',
      header: () => 'ID',
      cell: info => <div
        className={' w-[5%] font-normal break-all flex items-center justify-center ml-1'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: ' w-[5%] flex justify-center text-center ml-1',
      }
    }),
    columnHelper.accessor(row => row.title, {
      id: 'title',
      header: () => 'Заголовок',
      cell: info => <div className={'w-[20%] break-words flex items-center text-interactive-elem px-2'}>
        <Link to={`/funnels/${info.row?.original?.id}/constructor`}>
          {info.getValue()}
        </Link>
      </div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[20%] flex justify-start text-center px-2',
        sortField: 'title'
      }
    }),
    columnHelper.accessor(row => row.initialActionType, {
      id: 'initialActionType',
      header: () => 'Событие запуска',
      cell: info => <div className={'w-[15%] flex items-center'}>{initialNodeTypes?.find((type: any) => type.code == info.getValue())?.label || ''}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[15%] flex justify-start text-center ',
      }
    }),
    columnHelper.accessor(row => row.isActive, {
      id: 'isActive',
      header: () => 'Активность',
      cell: info => <div
        className={'w-[20%] font-normal text-center flex justify-center items-center'}>{info.getValue() ? 'Активна' : 'Не активна'}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[20%] flex justify-center  text-center',
        sortField: 'is-active'
      }
    }),
    columnHelper.accessor(row => row.position, {
      id: 'position',
      header: () => 'Сорт.',
      cell: info => <div
        className={'w-[15%]   font-normal text-center flex justify-center items-center'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[15%]  flex justify-center text-center',
        sortField: 'position'
      }
    }),
    columnHelper.accessor(row => row.id, {
      id: 'actions',
      header: () => 'Действия',
      cell: info => <div className='w-[15%]  flex justify-center items-center gap-4 mr-4'>
        <BaseButton onClick={() => openEditModal(info.getValue())}
                    className={'text-interactive-text border-btn-secondary rounded-md border-[1px] py-[7px]'}>Редактировать</BaseButton>
      </div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[15%]  flex justify-center text-center px-2 ',
      }
    }),
  ]

  const openEditModal = (id: any) => {
    if (company && branch) {
      setIsEditModal(true)
      apiClient.funnels.get(company.id, branch.id, id).then(({data, message, errors}: any) => {
        if (message || errors) {
          showErrorToast({
            content: message || 'Что-то пошло не так'
          })
        } else {
          updateData(data)
          setIsShowModal(true)
          setEditFunnelId(id)
        }
      }).catch((reason: any) => {
        showErrorToast({
          content: reason || 'Что-то пошло не так'
        })
      })
    }
  }

  const updateFunnel = (response: any) => {
    if (response?.isConfirmed) {
      if (company && branch && editFunnelId) {
        submit(async (form: any) => {
          apiClient.funnels.update(company.id, branch.id, editFunnelId, {body: form}).then(({
                                                                                              data,
                                                                                              errors,
                                                                                              message
                                                                                            }: any) => {
            if (message || errors) {
              if (errors) {
                updateErrors(errors)
              }
              showErrorToast({
                content: message || 'Что-то пошло не так'
              })
            } else {
              if (data?.id) {
                showSuccessToast({
                  content: 'Воронка успешно изменена'
                })
                updateFunnels(limits, filters)
                setIsEditModal(false)
                setIsShowModal(false)
                updateData(defaultForm)
                changedFieldsRefresh()
              } else {
                showErrorToast({
                  content: 'Что-то пошло не так'
                })
              }
            }
          }).catch((reason) => {
            showErrorToast({
              content: reason || 'Что-то пошло не так'
            })
          })
        }, 'changed')
      } else {
        showErrorToast({
          content: 'Не выбрана компания или филиал'
        })
      }
    } else {
      setIsEditModal(false)
      setIsShowModal(false)
      updateData(defaultForm)
      changedFieldsRefresh()
    }
  }

  const createFunnel = (response: any) => {
    if (response?.isConfirmed) {
      if (company && branch) {
        submit(async (form: FormData) => {
          if (!form.get('position')) {
            form.delete('position')
          }
          apiClient.funnels.create(company.id, branch.id, {body: form}).then(({data, errors, message}: any) => {
            if (message || errors) {
              if (errors) {
                updateErrors(errors)
              }
              showErrorToast({
                content: message || 'Что-то пошло не так'
              })
            } else {
              if (data?.id) {
                setIsShowModal(false)
                updateData(defaultForm)
                changedFieldsRefresh()
                navigate(`/funnels/${data?.id}/constructor`, {state: data})
              } else {
                showErrorToast({
                  content: 'Что-то пошло не так'
                })
              }
            }
          }).catch((reason) => {
            showErrorToast({
              content: reason || 'Что-то пошло не так'
            })
          })
        })
      } else {
        showErrorToast({
          content: 'Не выбрана компания или филиал'
        })
      }
    } else {
      setIsShowModal(false)
      updateData(defaultForm)
      changedFieldsRefresh()
    }
  }

  const submitMassAction = () => {
    if (action === null) return
    const form = getFormData('create', {funnelsIds: selectedRows, funnelGroupId}, new FormData())
    submitAction(() => {
      showSuccessToast({
        content: 'Действие выполнено успешно'
      })
      updateFunnels(limits, filters)
      setAction(null)
      setFunnelGroupId(null)
      setSelectedRows([])
    }, action, company.id, branch.id, form)
  }

  useEffect(() => {
    if (company && branch && !hasFunnelGroups) {
      setHasFunnelGroups(true);
      apiClient.funnelGroup.getAll(company.id, branch.id, '').then(({data}: any) => {
        let temp = tabs
        setFunnelGroups(data.map(({id, title}: any) => ({label: title, value: id})))
        temp.find(({tag}: any) => tag == 'groups').options = data.map(({id, title}: any) => ({label: title, tag: id}))
        setTabs(temp)
      }).catch(() => {
        setHasFunnelGroups(false)
      })
    }
  }, [company, branch])

  useEffect(() => {
    if (tab !== 'funnels') {
      updateFilters('filter.funnel-group', tab)
    } else {
      updateFilters('filter', {})
    }
    updateFunnels({limit: 10, page: 1}, filters)
    setSelectedRows([])
  }, [tab])

  useEffect(() => {
    fetchInitialTypes().then((data: any) => {
      setInitialNodeTypes(data)
    }).catch(() => {
    })
    fetchOverlapTypes().then((data: any) => {
      setOverlapTypes(data)
    }).catch(() => {
    })
  }, [])

  return (
    <div className="px-8 pb-8 pt-2">
      <div className="flex flex-col">
        <div className="flex justify-between items-end">
          <div>
            <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Воронки</div>
            <div
              className="text-gray-40 text-base">{tab == 'funnels' ? 'Список воронок' : tabs.find((tab: any) => tab.tag === 'groups')?.options.find(({tag}: any) => tab == tag)?.label}</div>
          </div>
          <div className={'flex flex-row justify-end items-center'}>
            {
              (!!branch && tab == 'funnels') &&
                <BaseButton onClick={() => setIsShowModal(true)}
                            className={"ml-5 bg-interactive-elem text-white"}
                >
                    Добавить воронку
                </BaseButton>
            }
            {
              (!!branch && tab == 'funnels') &&
                <Link to={'/funnels/create-group'}>
                    <BaseButton className={"ml-5 bg-interactive-elem text-white"}>Добавить группу воронок</BaseButton>
                </Link>
            }
            {
              (!!branch && tab !== 'funnels') &&
                <Link to={`/funnels/${tab}/edit-group`}>
                    <BaseButton className={"ml-5 bg-interactive-elem text-white"}>Редактировать группу
                        воронок</BaseButton>
                </Link>
            }
          </div>
        </div>

      </div>
      <div className={'mt-8'}>
        {
          branch ?
            <div className={'flex flex-col gap-y-4'}>
              <FormWrapper
                tabs={tabs}
                tab={tab}
                setTab={setTab}
                heightForm={'min-h-[350px]'}
                withArrowGroup={false}
              >
                <CreateFormFields
                  show={tab === 'funnels'}
                  setTab={setTab}
                >
                  <DataTable
                    data={funnels || []}
                    columns={columns}
                    updateData={(params: any) => updateFunnels(params, filters)}
                    pagination={pagination}
                    loading={funnelsLoading}
                    useMassAction={true}
                    usePagination={true}
                    selectedRows={selectedRows}
                    onChangeSelectionRows={(values: any) => setSelectedRows(values)}
                    defaultLimit={10}
                    defaultPage={1}
                    rowsWithCustomBg={funnels.filter((funnel: any) => !funnel.isActive).map(({id}: any) => ({
                      id,
                      color: 'bg-error-main/[.5]'
                    }))}
                  />
                </CreateFormFields>

                {
                  tabs.find((tab: any) => tab.tag === 'groups')?.options.map((option: any) => (
                    <CreateFormFields
                      show={tab === option.tag}
                      setTab={setTab}
                    >
                      <DataTable
                        data={funnels || []}
                        columns={columns}
                        updateData={(params: any) => updateFunnels(params, filters)}
                        pagination={pagination}
                        loading={funnelsLoading}
                        useMassAction={true}
                        usePagination={true}
                        selectedRows={selectedRows}
                        onChangeSelectionRows={(values: any) => setSelectedRows(values)}
                        defaultLimit={10}
                        defaultPage={1}
                        defaultOrder={{ 'position': 'asc' }}
                        rowsWithCustomBg={funnels.filter((funnel: any) => !funnel.isActive).map(({id}: any) => ({
                          id,
                          color: 'bg-error-main/[.5]'
                        }))}
                      />
                    </CreateFormFields>
                  ))
                }

              </FormWrapper>
              {selectedRows.length > 0 &&
                  <div className={"w-full rounded-xl bg-white px-3 py-5 gap-x-3 mb-20 flex items-center justify-between"}>
                      <div className={"w-fit flex items-center justify-center gap-x-4"}>
                          <SingleSimpleValueSelect
                              name={'xs'}
                              placeholder={'Выберите действие'}
                              value={action}
                              options={[
                                {label: 'Выберите действие', value: null},
                                {label: 'Активировать', value: 'activate'},
                                {label: 'Деактивировать', value: 'deactivate'},
                                {label: 'Удалить', value: 'remove'},
                                {label: 'Переместить в группу', value: 'move'},
                                {label: 'Копировать', value: 'copy'}
                              ]}
                              onChange={(option: any) => {
                                setAction(option)
                              }}
                          />
                      </div>

                    {
                      action == 'move' &&
                        <div className={"w-1/2"}>
                            <SingleSimpleValueSelect
                                name={'funnelGroupId'}
                                placeholder={'Выберите группу воронок'}
                                value={funnelGroupId || ''}
                                options={funnelGroups}
                                onChange={(value: any) => setFunnelGroupId(value)}
                                errors={errors}
                                required={true}
                            />
                        </div>
                    }
                      <BaseButton onClick={submitMassAction}
                                  className={"w-[250px] ml-5 bg-interactive-elem text-white"}>Изменить {selectedRows.length} воронок
                      </BaseButton>
                  </div>}
            </div> :
            <div className="text-gray-40 text-3xl text-center mt-[35vh]">
              Выберите филиал
            </div>
        }
      </div>
      {
        isShowModal && <CreateModal
              data={data}
              errors={errors}
              updateField={updateField}
              updateFieldNoRender={updateFieldNoRender}
              title={isEditModal ? 'Изменение воронки' : 'Создание воронки'}
              isOpen={isShowModal}
              isEdit={isEditModal}
              onReject={(response: any) => {
                changedFieldsRefresh()
                updateData(defaultForm)
                setIsShowModal(false)
                setIsEditModal(false)
                setEditFunnelId(null)
                if (response?.update) {
                  updateFunnels(limits, filters)
                }
              }}
              onResolve={(response: any) => {
                if (isEditModal) {
                  updateFunnel(response)
                } else {
                  createFunnel(response)
                }
                changedFieldsRefresh()
                updateData(defaultForm)
              }}
              startActionOptions={initialNodeTypes}
              overlapTypes={overlapTypes}
              allowOutsideClick={false}
              classNameWrapper={'w-[50%] max-w-[750px]'}
          />
      }
    </div>
  )
}
