import {apiClient} from "../../libs/api/apiClient";
import {showErrorToast} from "../../libs/helpers/toasts";


enum ActionEnum {
  remove = 'delete',
  detach = 'detach',
  attach = 'attach',
}

enum Tabs {
  options= 'options',
  optionsGroup= 'optionsGroup',
  skuGroup= 'skuGroup',
}

export const submitAction = (callback: any, action: string, tab: string, companyId: any, branchId: any, data: any) => {
  switch (tab) {
    case Tabs.options:
      switch (action) {
        case ActionEnum.attach:
          bulkOptionsAttach(callback, companyId, branchId, data)
          break
        case ActionEnum.detach:
          bulkOptionsDetach(callback, companyId, branchId, data)
          break
        case ActionEnum.remove:
          bulkOptionsRemove(callback, companyId, branchId, data)
          break
        default:
          break
      }
      break
    case Tabs.optionsGroup:
      switch (action) {
        case ActionEnum.attach:
          bulkOptionsGroupAttach(callback, companyId, branchId, data)
          break
        case ActionEnum.detach:
          bulkOptionsGroupDetach(callback, companyId, branchId, data)
          break
        case ActionEnum.remove:
          bulkOptionsGroupRemove(callback, companyId, branchId, data)
          break
        default:
          break
      }
      break
    case Tabs.skuGroup:
      switch (action) {
        case ActionEnum.attach:
          bulkSkuGroupAttach(callback, companyId, branchId, data)
          break
        case ActionEnum.detach:
          bulkSkuGroupDetach(callback, companyId, branchId, data)
          break
        case ActionEnum.remove:
          bulkSkuGroupRemove(callback, companyId, branchId, data)
          break
        default:
          break
      }
      break

    default:
      break
  }
}

/*-------------------------------------------REMOVE----------*/

 const bulkOptionsRemove = (callback: any, companyId: any, branchId: any, data: any) => {
   apiClient.options.bulkRemove(companyId, branchId, {body: data})
     .then(({data, errors, message}: any) => {
       if (errors || message) {
         showErrorToast({
           content: 'Что-то пошло не так'
         })
       } else {
         callback()
       }
     })
     .catch(() => {
       showErrorToast({
         content: 'Что-то пошло не так'
       })
     })
 }

 const bulkOptionsGroupRemove = (callback: any, companyId: any, branchId: any, data: any) => {
   apiClient.optionsGroups.bulkRemove(companyId, branchId, {body: data})
     .then(({data, errors, message}: any) => {
       if (errors || message) {
         showErrorToast({
           content: 'Что-то пошло не так'
         })
       } else {
         callback()
       }
     })
     .catch(() => {
       showErrorToast({
         content: 'Что-то пошло не так'
       })
     })
 }

 const bulkSkuGroupRemove = (callback: any, companyId: any, branchId: any, data: any) => {
   apiClient.skuGroups.bulkRemove(companyId, branchId, {body: data})
     .then(({data, errors, message}: any) => {
       if (errors || message) {
         showErrorToast({
           content: 'Что-то пошло не так'
         })
       } else {
         callback()
       }
     })
     .catch(() => {
       showErrorToast({
         content: 'Что-то пошло не так'
       })
     })
 }

/*-------------------------------------------ATTACH----------*/
 const bulkOptionsAttach = (callback: any, companyId: any, branchId: any, data: any) => {
   apiClient.options.bulkAttach(companyId, branchId, {body: data})
     .then(({data, errors, message}: any) => {
       if (errors || message) {
         showErrorToast({
           content: 'Что-то пошло не так'
         })
       } else {
         callback()
       }
     })
     .catch(() => {
       showErrorToast({
         content: 'Что-то пошло не так'
       })
     })
 }

 const bulkOptionsGroupAttach = (callback: any, companyId: any, branchId: any, data: any) => {
   apiClient.optionsGroups.bulkAttach(companyId, branchId, {body: data})
     .then(({data, errors, message}: any) => {
       if (errors || message) {
         showErrorToast({
           content: 'Что-то пошло не так'
         })
       } else {
         callback()
       }
     })
     .catch(() => {
       showErrorToast({
         content: 'Что-то пошло не так'
       })
     })
 }

 const bulkSkuGroupAttach = (callback: any, companyId: any, branchId: any, data: any) => {
   apiClient.skuGroups.bulkAttach(companyId, branchId, {body: data})
     .then(({data, errors, message}: any) => {
       if (errors || message) {
         showErrorToast({
           content: 'Что-то пошло не так'
         })
       } else {
         callback()
       }
     })
     .catch(() => {
       showErrorToast({
         content: 'Что-то пошло не так'
       })
     })
 }

/*-------------------------------------------DETACH----------*/

 const bulkOptionsDetach = (callback: any, companyId: any, branchId: any, data: any) => {
   apiClient.options.bulkDetach(companyId, branchId, {body: data})
     .then(({data, errors, message}: any) => {
       if (errors || message) {
         showErrorToast({
           content: 'Что-то пошло не так'
         })
       } else {
         callback()
       }
     })
     .catch(() => {
       showErrorToast({
         content: 'Что-то пошло не так'
       })
     })
 }

 const bulkOptionsGroupDetach = (callback: any, companyId: any, branchId: any, data: any) => {
   apiClient.optionsGroups.bulkDetach(companyId, branchId, {body: data})
     .then(({data, errors, message}: any) => {
       if (errors || message) {
         showErrorToast({
           content: 'Что-то пошло не так'
         })
       } else {
         callback()
       }
     })
     .catch(() => {
       showErrorToast({
         content: 'Что-то пошло не так'
       })
     })
 }

 const bulkSkuGroupDetach = (callback: any, companyId: any, branchId: any, data: any) => {
   apiClient.skuGroups.bulkDetach(companyId, branchId, {body: data})
     .then(({data, errors, message}: any) => {
       if (errors || message) {
         showErrorToast({
           content: 'Что-то пошло не так'
         })
       } else {
         callback()
       }
     })
     .catch(() => {
       showErrorToast({
         content: 'Что-то пошло не так'
       })
     })
 }
