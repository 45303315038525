import crownIcon from "../../components/defaultIcons/fi-sr-crown.svg";
import fireIcon from "../../components/defaultIcons/fi-sr-fire-flame-curved.svg";
import fishIcon from "../../components/defaultIcons/fi-sr-fish.svg";
import giftIcon from "../../components/defaultIcons/fi-sr-gift.svg";
import hatChefIcon from "../../components/defaultIcons/fi-sr-hat-chef.svg";
import heartIcon from "../../components/defaultIcons/fi-sr-heart.svg";
import startIcon from "../../components/defaultIcons/fi-sr-star.svg";
import pepperIcon from "../../components/defaultIcons/fi-sr-pepper.svg";
import tagsIcon from "../../components/defaultIcons/fi-sr-tags.svg";
import thumbsUpIcon from "../../components/defaultIcons/fi-sr-thumbs-up.svg";
export const ICONS_TAG = [
  {
    value: '1',
    icon: crownIcon,
    active: false,
  },
  {
    value: '2',
    icon: fireIcon,
    active: false,
  },
  {
    value: '3',
    icon: fishIcon,
    active: false,
  },
  {
    value: '4',
    icon: giftIcon,
    active: false,
  },
  {
    value: '5',
    icon: hatChefIcon,
    active: false,
  },
  {
    value: '6',
    icon: heartIcon,
    active: false,
  },
  {
    value: '7',
    icon: startIcon,
    active: false,
  },
  {
    value: '8',
    icon: pepperIcon,
    active: false,
  },
  {
    value: '9',
    icon: tagsIcon,
    active: false,
  },
  {
    value: '10',
    icon: thumbsUpIcon,
    active: false,
  },
]
