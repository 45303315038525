import React, {useEffect, useId} from "react";

export default function RadioButton(
  {
    className = '',
    labelClassName= '',
    label='',
    name,
    value,
    onChange,
    errors = {},
    disabled = false,
    ...props
  }) {
  const id = useId();
  const error = errors[name]

  const handleChange = ({target}) => {
    onChange(target.checked)
  }

  useEffect(() => {
    delete errors[name];
  }, [value]);
  return (
    <div className={`${className}`}>
      <div className={`flex items-center cursor-pointer`}>
        <label
          htmlFor={id}
          className={`relative flex items-center text-sm text-gray-90 font-normal cursor-pointer ${labelClassName}`}
        >
          <input
            type="radio"
            id={id}
            checked={value}
            name={name}
            onChange={handleChange}
            disabled={disabled}
            className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border-[2px] border-gray-20 text-green-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:opacity-0 before:transition-opacity checked:border-interactive-elem checked:before:bg-interactive-elem"
            {...props}/>
          <div
            className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-green-500 opacity-0 transition-opacity peer-checked:opacity-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-3.5 w-3.5"
              viewBox="0 0 16 16"
              fill="#55B37A"
            >
              <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
            </svg>
          </div>
          {label}
        </label>
      </div>
      {error && (<span className={`text-error-font text-xs font-medium`}>{error}</span>)}
    </div>
  )
}
