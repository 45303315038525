import {apiClient} from "../../libs/api/apiClient";
import {showErrorToast} from "../../libs/helpers/toasts";


enum ActionEnum {
  activate = 'activate',
  deactivate = 'deactivate',
  remove = 'remove',
  move = 'move',
  copy = 'copy',
}

export const submitAction = (callback: any, action: string, companyId: any, branchId: any, data: any) => {
  switch (action) {
    case ActionEnum.activate:
      bulkActivate(callback, companyId, branchId, data)
      break
    case ActionEnum.deactivate:
      bulkDeactivate(callback, companyId, branchId, data)
      break
    case ActionEnum.remove:
      bulkRemove(callback, companyId, branchId, data)
      break
    case ActionEnum.move:
      bulkMove(callback, companyId, branchId, data)
      break
    case ActionEnum.copy:
      bulkCopy(callback, companyId, branchId, data)
      break
    default:
      break
  }
}

const bulkActivate = (callback: any, companyId: any, branchId: any, data: any) => {
  apiClient.funnels.bulk.bulkActivate(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      } else {
        callback()
      }
    })
    .catch(() => {
      showErrorToast({
        content: 'Что-то пошло не так'
      })
    })
}

const bulkDeactivate = (callback: any, companyId: any, branchId: any, data: any) => {
  apiClient.funnels.bulk.bulkDeactivate(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      } else {
        callback()
      }
    })
    .catch(() => {
      showErrorToast({
        content: 'Что-то пошло не так'
      })
    })
}

const bulkRemove = (callback: any, companyId: any, branchId: any, data: any) => {
  apiClient.funnels.bulk.bulkRemove(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      } else {
        callback()
      }
    })
    .catch(() => {
      showErrorToast({
        content: 'Что-то пошло не так'
      })
    })
}

const bulkMove = (callback: any, companyId: any, branchId: any, data: any) => {
  apiClient.funnels.bulk.bulkMove(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      } else {
        callback()
      }
    })
    .catch(() => {
      showErrorToast({
        content: 'Что-то пошло не так'
      })
    })
}

const bulkCopy = (callback: any, companyId: any, branchId: any, data: any) => {
  apiClient.funnels.bulk.bulkCopy(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      } else {
        callback()
      }
    })
    .catch(() => {
      showErrorToast({
        content: 'Что-то пошло не так'
      })
    })
}
