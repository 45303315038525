import React, {useEffect, useId, useRef, useState} from "react";
import Tooltip from "../../Tooltip";
import 'react-quill/dist/quill.snow.css';
import ReactQuill  from "react-quill";
import useValueThrottle from "../../../../hooks/useValueThrottle";

export default function TextEditor({
  value,
  onChange=(value: any)=>{},
  onClick=()=>{},
  onKeyDown=()=>{},
  className = '',
  placeholder = '',
  label = '',
  errors = {},
  required = false,
  textTooltip = '',
  name= '',
  errorLabel = true,
}) {
  const id = useId();
  const ref = useRef<any>(null)
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['link'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['clean']
    ]
  };

  const error = errors[name];

  useEffect(() => {
    delete errors[name];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const [editorContent, setEditorContent] = useState(value);

  function change(value: any) {
    setEditorContent(value)
    if (ref.current?.unprivilegedEditor.getLength() <= 1) {
      onChange('')
    } else {
      onChange(value)
    }
  }

  const [value0, setValue0] = useValueThrottle<string | undefined>(editorContent, change, 0)


  return (
    <div className={`${className} text-gray-50`}>
    {
      label &&
      <label htmlFor={id} className="flex items-center mb-2 font-medium text-sm">
        { label }
          <span className='text-error-main'>{required && '*'}</span>
        {
          textTooltip && <Tooltip textTooltip={textTooltip}/>
        }
      </label>
    }

    <ReactQuill
      className={`${error && errorLabel && 'error-border'}`}
        modules={modules}
        placeholder={placeholder}
        theme="snow"
        value={value0}
        onChange={setValue0}
        preserveWhitespace={true}
        ref={ref}
    />
      {error && errorLabel && (<span className={'text-error-font text-xs font-medium'}>{error}</span>)}
  </div>
  )
}
