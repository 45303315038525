import React, { useEffect } from 'react'
import BaseButton from '../../../components/common/Button/BaseButton'
import { apiClient } from '../../../libs/api/apiClient'
import useForm from '../../../hooks/useForm'
import initForm from '../initForm'
import Textarea from './../../../components/common/form/input/Textarea';

export default function MessageTemplates({data, updateField, errors, updateFieldNoRender} : any) {

  return (
    <div className={'flex flex-col px-3 gap-y-6'}>
        {

          <>
            <Textarea
              label='Текст сообщения при поступлении нового заказа'
              textTooltip='Текст сообщения при поступлении нового заказа'
              placeholder='Текстовое поле'
              name={'messageTemplates.newOrder'}
              value={data.messageTemplates.newOrder}
              onChange={value => updateFieldNoRender('messageTemplates.newOrder', value)}
              errors={errors}
              maxLength={undefined}
            />

            <Textarea
              label='Текст сообщения после того как заказ оформлен'
              textTooltip='Текст сообщения после того как заказ оформлен'
              placeholder='Текстовое поле'
              name={'messageTemplates.orderPlaced'}
              value={data.messageTemplates.orderPlaced}
              onChange={value => updateFieldNoRender('messageTemplates.orderPlaced', value)}
              errors={errors}
              maxLength={undefined}
            />

            <Textarea
              label='Текст сообщения если способ оплаты (Online)'
              textTooltip='Текст сообщения если способ оплаты (Online)'
              placeholder='Текстовое поле'
              name={'messageTemplates.onlinePayment'}
              value={data.messageTemplates.onlinePayment}
              onChange={value => updateFieldNoRender('messageTemplates.onlinePayment', value)}
              errors={errors}
              maxLength={undefined}
            />

            <Textarea
              label='Заголовок на странице, когда заказ оформлен'
              textTooltip='Заголовок на странице, когда заказ оформлен'
              placeholder='Текстовое поле'
              name={'messageTemplates.titleOnPageAfterOrderPlaced'}
              value={data.messageTemplates.titleOnPageAfterOrderPlaced}
              onChange={value => updateFieldNoRender('messageTemplates.titleOnPageAfterOrderPlaced', value)}
              errors={errors}
              maxLength={undefined}
            />

            <Textarea
              label='Информация на странице, когда заказ оформлен'
              textTooltip='Информация на странице, когда заказ оформлен'
              placeholder='Текстовое поле'
              name={'messageTemplates.descriptionOnPageAfterOrderPlaced'}
              value={data.messageTemplates.descriptionOnPageAfterOrderPlaced}
              onChange={value => updateFieldNoRender('messageTemplates.descriptionOnPageAfterOrderPlaced', value)}
              errors={errors}
              maxLength={undefined}
            />
          </>
        }

    </div>
  )
}
