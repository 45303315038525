import {apiClient} from "../../libs/api/apiClient";
import {showErrorToast} from "../../libs/helpers/toasts";


enum ActionEnum {
  remove = 'delete',
  detach = 'detach',
  attach = 'attach',
  copy = 'copy',
  activate = 'activate',
  deactivate = 'deactivate',
}

enum Tabs {
  companies = 'companies',
  customers = 'customers',
  orders = 'orders',
  promoCodes = 'promo'

}

export const submitAction = (callback: any, action: string, tab: string, companyId: any, branchId: any, data: any) => {
  switch (tab) {
    case Tabs.companies:
      switch (action) {
        case ActionEnum.attach:
          bulkCompaniesAttach(callback, companyId, branchId, data)
          break
        case ActionEnum.detach:
          bulkCompaniesDetach(callback, companyId, branchId, data)
          break
        case ActionEnum.remove:
          bulkCompaniesRemove(callback, companyId, branchId, data)
          break
        case ActionEnum.copy:
          //bulkCompaniesCopy(callback, companyId, branchId, data)
          break
        case ActionEnum.activate:
          //bulkCompaniesActivate(callback, companyId, branchId, data)
          break
        case ActionEnum.deactivate:
          //bulkCompaniesDeactivate(callback, companyId, branchId, data)
          break
        default:
          break
      }
      break
    case Tabs.customers:
      switch (action) {
        case ActionEnum.attach:
          bulkCustomersAttach(callback, companyId, branchId, data)
          break
        case ActionEnum.detach:
          bulkCustomersDetach(callback, companyId, branchId, data)
          break
        case ActionEnum.remove:
          bulkCustomersRemove(callback, companyId, branchId, data)
          break
        case ActionEnum.copy:
          //bulkCustomersCopy(callback, companyId, branchId, data)
          break
        case ActionEnum.activate:
          //bulkCustomersActivate(callback, companyId, branchId, data)
          break
        case ActionEnum.deactivate:
          //bulkCustomersDeactivate(callback, companyId, branchId, data)
          break
        default:
          break
      }
      break
    case Tabs.orders:
      switch (action) {
        case ActionEnum.attach:
          bulkOrdersAttach(callback, companyId, branchId, data)
          break
        case ActionEnum.detach:
          bulkOrdersDetach(callback, companyId, branchId, data)
          break
        case ActionEnum.remove:
          bulkOrdersRemove(callback, companyId, branchId, data)
          break
        case ActionEnum.copy:
          //bulkOrdersCopy(callback, companyId, branchId, data)
          break
        case ActionEnum.activate:
          //bulkOrdersActivate(callback, companyId, branchId, data)
          break
        case ActionEnum.deactivate:
          //bulkOrdersDeactivate(callback, companyId, branchId, data)
          break
        default:
          break
      }
      break
    case Tabs.promoCodes:
      switch (action) {
        case ActionEnum.attach:
          bulkPromoCodesAttach(callback, companyId, branchId, data)
          break
        case ActionEnum.detach:
          bulkPromoCodesDetach(callback, companyId, branchId, data)
          break
        case ActionEnum.remove:
          bulkPromoCodesRemove(callback, companyId, branchId, data)
          break
        default:
          break
      }
      break
    default:
      break
  }
}

const bulkCompaniesRemove = (callback: any, companyId: any, branchId: any, data: any) => {
  apiClient.companiesTags.bulkRemove(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      } else {
        callback()
      }
    })
    .catch(() => {
      showErrorToast({
        content: 'Что-то пошло не так'
      })
    })
}

const bulkCustomersRemove = (callback: any, companyId: any, branchId: any, data: any) => {
  apiClient.customersTags.bulkRemove(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      } else {
        callback()
      }
    })
    .catch(() => {
      showErrorToast({
        content: 'Что-то пошло не так'
      })
    })
}

const bulkOrdersRemove = (callback: any, companyId: any, branchId: any, data: any) => {
  apiClient.ordersTags.bulkRemove(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      } else {
        callback()
      }
    })
    .catch(() => {
      showErrorToast({
        content: 'Что-то пошло не так'
      })
    })
}

const bulkPromoCodesRemove = (callback: any, companyId: any, branchId: any, data: any) => {
  apiClient.promoTags.bulkRemove(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      } else {
        callback()
      }
    })
    .catch(() => {
      showErrorToast({
        content: 'Что-то пошло не так'
      })
    })
}


const bulkCompaniesAttach = (callback: any, companyId: any, branchId: any, data: any) => {
  apiClient.companiesTags.bulkAttach(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      } else {
        callback()
      }
    })
    .catch(() => {
      showErrorToast({
        content: 'Что-то пошло не так'
      })
    })
}

const bulkCustomersAttach = (callback: any, companyId: any, branchId: any, data: any) => {
  apiClient.customersTags.bulkAttach(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      } else {
        callback()
      }
    })
    .catch(() => {
      showErrorToast({
        content: 'Что-то пошло не так'
      })
    })
}

const bulkOrdersAttach = (callback: any, companyId: any, branchId: any, data: any) => {
  apiClient.ordersTags.bulkAttach(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      } else {
        callback()
      }
    })
    .catch(() => {
      showErrorToast({
        content: 'Что-то пошло не так'
      })
    })
}

const bulkPromoCodesAttach = (callback: any, companyId: any, branchId: any, data: any) => {
  apiClient.promoTags.bulkAttach(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      } else {
        callback()
      }
    })
    .catch(() => {
      showErrorToast({
        content: 'Что-то пошло не так'
      })
    })
}


const bulkCompaniesDetach = (callback: any, companyId: any, branchId: any, data: any) => {
  apiClient.companiesTags.bulkDetach(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      } else {
        callback()
      }
    })
    .catch(() => {
      showErrorToast({
        content: 'Что-то пошло не так'
      })
    })
}

const bulkCustomersDetach = (callback: any, companyId: any, branchId: any, data: any) => {
  apiClient.customersTags.bulkDetach(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      } else {
        callback()
      }
    })
    .catch(() => {
      showErrorToast({
        content: 'Что-то пошло не так'
      })
    })
}

const bulkOrdersDetach = (callback: any, companyId: any, branchId: any, data: any) => {
  apiClient.ordersTags.bulkDetach(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      } else {
        callback()
      }
    })
    .catch(() => {
      showErrorToast({
        content: 'Что-то пошло не так'
      })
    })
}

const bulkPromoCodesDetach = (callback: any, companyId: any, branchId: any, data: any) => {
  apiClient.promoTags.bulkDetach(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      } else {
        callback()
      }
    })
    .catch(() => {
      showErrorToast({
        content: 'Что-то пошло не так'
      })
    })
}



// const bulkPromoCodesActivate = (callback: any, companyId: any, branchId: any, data: any) => {
//   apiClient.promoTags.bulkActivate(companyId, branchId, {body: data})
//     .then(({data, errors, message}: any) => {
//       if (errors || message) {
//         showErrorToast({
//           content: 'Что-то пошло не так'
//         })
//       } else {
//         callback()
//       }
//     })
//     .catch(() => {
//       showErrorToast({
//         content: 'Что-то пошло не так'
//       })
//     })
// }


// const bulkCustomersActivate = (callback: any, companyId: any, branchId: any, data: any) => {
//   apiClient.customersTags.bulkActivate(companyId, branchId, {body: data})
//     .then(({data, errors, message}: any) => {
//       if (errors || message) {
//         showErrorToast({
//           content: 'Что-то пошло не так'
//         })
//       } else {
//         callback()
//       }
//     })
//     .catch(() => {
//       showErrorToast({
//         content: 'Что-то пошло не так'
//       })
//     })
// }

// const bulkOrdersActivate = (callback: any, companyId: any, branchId: any, data: any) => {
//   apiClient.ordersTags.bulkActivate(companyId, branchId, {body: data})
//     .then(({data, errors, message}: any) => {
//       if (errors || message) {
//         showErrorToast({
//           content: 'Что-то пошло не так'
//         })
//       } else {
//         callback()
//       }
//     })
//     .catch(() => {
//       showErrorToast({
//         content: 'Что-то пошло не так'
//       })
//     })
// }
//
// const bulkCompaniesActivate = (callback: any, companyId: any, branchId: any, data: any) => {
//   apiClient.promoTags.bulkActivate(companyId, branchId, {body: data})
//     .then(({data, errors, message}: any) => {
//       if (errors || message) {
//         showErrorToast({
//           content: 'Что-то пошло не так'
//         })
//       } else {
//         callback()
//       }
//     })
//     .catch(() => {
//       showErrorToast({
//         content: 'Что-то пошло не так'
//       })
//     })
// }

// const bulkPromoCodesDeactivate = (callback: any, companyId: any, branchId: any, data: any) => {
//   apiClient.promoTags.bulkDeactivate(companyId, branchId, {body: data})
//     .then(({data, errors, message}: any) => {
//       if (errors || message) {
//         showErrorToast({
//           content: 'Что-то пошло не так'
//         })
//       } else {
//         callback()
//       }
//     })
//     .catch(() => {
//       showErrorToast({
//         content: 'Что-то пошло не так'
//       })
//     })
// }


// const bulkCustomersDeactivate = (callback: any, companyId: any, branchId: any, data: any) => {
//   apiClient.customersTags.bulkDeactivate(companyId, branchId, {body: data})
//     .then(({data, errors, message}: any) => {
//       if (errors || message) {
//         showErrorToast({
//           content: 'Что-то пошло не так'
//         })
//       } else {
//         callback()
//       }
//     })
//     .catch(() => {
//       showErrorToast({
//         content: 'Что-то пошло не так'
//       })
//     })
// }

// const bulkOrdersDeactivate = (callback: any, companyId: any, branchId: any, data: any) => {
//   apiClient.ordersTags.bulkDeactivate(companyId, branchId, {body: data})
//     .then(({data, errors, message}: any) => {
//       if (errors || message) {
//         showErrorToast({
//           content: 'Что-то пошло не так'
//         })
//       } else {
//         callback()
//       }
//     })
//     .catch(() => {
//       showErrorToast({
//         content: 'Что-то пошло не так'
//       })
//     })
// }
//
// const bulkCompaniesDeactivate = (callback: any, companyId: any, branchId: any, data: any) => {
//   apiClient.promoTags.bulkDeactivate(companyId, branchId, {body: data})
//     .then(({data, errors, message}: any) => {
//       if (errors || message) {
//         showErrorToast({
//           content: 'Что-то пошло не так'
//         })
//       } else {
//         callback()
//       }
//     })
//     .catch(() => {
//       showErrorToast({
//         content: 'Что-то пошло не так'
//       })
//     })
// }


// const bulkPromoCodesCopy = (callback: any, companyId: any, branchId: any, data: any) => {
//   apiClient.promoTags.bulkCopy(companyId, branchId, {body: data})
//     .then(({data, errors, message}: any) => {
//       if (errors || message) {
//         showErrorToast({
//           content: 'Что-то пошло не так'
//         })
//       } else {
//         callback()
//       }
//     })
//     .catch(() => {
//       showErrorToast({
//         content: 'Что-то пошло не так'
//       })
//     })
// }


// const bulkCustomersCopy = (callback: any, companyId: any, branchId: any, data: any) => {
//   apiClient.customersTags.bulkCopy(companyId, branchId, {body: data})
//     .then(({data, errors, message}: any) => {
//       if (errors || message) {
//         showErrorToast({
//           content: 'Что-то пошло не так'
//         })
//       } else {
//         callback()
//       }
//     })
//     .catch(() => {
//       showErrorToast({
//         content: 'Что-то пошло не так'
//       })
//     })
// }

// const bulkOrdersCopy = (callback: any, companyId: any, branchId: any, data: any) => {
//   apiClient.ordersTags.bulkCopy(companyId, branchId, {body: data})
//     .then(({data, errors, message}: any) => {
//       if (errors || message) {
//         showErrorToast({
//           content: 'Что-то пошло не так'
//         })
//       } else {
//         callback()
//       }
//     })
//     .catch(() => {
//       showErrorToast({
//         content: 'Что-то пошло не так'
//       })
//     })
// }
//
// const bulkCompaniesCopy = (callback: any, companyId: any, branchId: any, data: any) => {
//   apiClient.promoTags.bulkCopy(companyId, branchId, {body: data})
//     .then(({data, errors, message}: any) => {
//       if (errors || message) {
//         showErrorToast({
//           content: 'Что-то пошло не так'
//         })
//       } else {
//         callback()
//       }
//     })
//     .catch(() => {
//       showErrorToast({
//         content: 'Что-то пошло не так'
//       })
//     })
// }
