import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import BaseButton from "../../components/common/Button/BaseButton";
import Checkbox from "../../components/common/form/checkbox/Checkbox";
import DataTable from "../../components/common/Table/DataTable";
import {createColumnHelper} from "@tanstack/react-table";
import Actions from "../../components/common/Table/Actions";
import {useOrdersContext} from "../../contexts/Orders";
import {getDateAndTime} from "../../libs/helpers/datetime";
import androidIcon from "../../images/android.svg"
import iosIcon from "../../images/ios.svg"
import siteIcon from "../../images/site.svg"
import phoneIcon from "../../images/phone.svg"
import editIcon from "../../images/pencil.svg"
import fileIcon from "../../images/file.svg"
import successIcon from "../../images/check.svg"
import vkIcon from "../../images/vk.svg"
import RangeDatePicker from "../../components/common/form/datetime/RangeDatePicker";
import {inputModal} from "../../components/common/Modal/InputModal";
import {useBranchesContext} from "../../contexts/Branches";
import {apiClient} from "../../libs/api/apiClient";
import {useCompaniesContext} from "../../contexts/Companies";
import {showErrorToast, showSuccessToast} from "../../libs/helpers/toasts";
import {getFormData} from "../../libs/helpers/formData";
import SingleSimpleValueSelect from "../../components/common/form/select/SingleSimpleValueSelect";
import Export from "../../components/common/Export";
import { ExportBlocks } from "../../components/common/Export/helpers";
import TagLabel from "../../components/common/form/select/datatable/tags/TagLabel"
import { useSidebarContext } from "../../contexts/Sidebar";
import { getSimpleAddress } from "../../libs/helpers/address";
import ExternalResponseContainer from "./externalResponseContainer";

enum SourceEnum {
  SITE = 'site',
  PHONE = 'phone',
  IOS = 'ios',
  ANDROID = 'android',
  VK = 'vk'
}

enum PaymentStatus {
  PENDING = 'pending',
  WAITING_CONFIRMATION = 'waiting-confirmation',
  SUCCEEDED = 'succeeded',
  CANCELED = 'canceled',
}

export default function Orders() {
  const {company}: any = useCompaniesContext()
  const {branch}: any = useBranchesContext()
  const { hiddenMode } = useSidebarContext()
  const [search, setSearch] = useState('')
  const {orders, updateOrders, limits, filters, updateFilters, pagination, loading: loadingOrders}: any = useOrdersContext()
  const [applyFilter, setApplyFilter] = useState(false)
  const [dateFilter, setDateFilter] = useState([(new Date()).toDateString(), (new Date()).toDateString()])
  const [, setOrdersDisablingReason] = useState<any>(null)
  const [usePickup, setUsePickup] = useState(false)
  const [useCourier, setUseCourier] = useState(false)

  //TODO: modal cansel
  const modalCancelOrder = (type = 'all') => {
    inputModal({
      title: 'Укажите причину',
      inputType: 'textarea',
      inputLabel: 'Причина приостановки заказов',
      inputPlaceholder: 'Введите причину',
      showCancelButton: true,
      confirmButtonText: 'Отключить',
      cancelButtonText: 'Отменить',
      useValidation: false,
      classNameWrapper: 'w-[600px]'
    }).then((result) => {
      if (result.isConfirmed) {
        if (result?.value) {
          setOrdersDisablingReason(result?.value)
        }
        let sendData = {
          pickup: usePickup,
          courier: useCourier,
          reason: result?.value
        }

        if (type === 'pickup') {
          sendData.pickup = !usePickup
          setUsePickup(!usePickup)
        }
        if (type === 'courier') {
          sendData.courier = !useCourier
          setUseCourier(!useCourier)
        }
        if (type === 'all') {
          sendData.pickup = !(useCourier || usePickup)
          sendData.courier = !(useCourier || usePickup)
          setUsePickup(!(useCourier || usePickup))
          setUseCourier(!(useCourier || usePickup))
        }
        cancelOrders(sendData)
      }
    }).catch(()=>{})
  }

  const getSourceIcon = (source: any) => {
    switch (source) {
      case SourceEnum.SITE:
        return siteIcon
      case SourceEnum.PHONE:
        return phoneIcon
      case SourceEnum.IOS:
        return iosIcon
      case SourceEnum.ANDROID:
        return androidIcon
      case SourceEnum.VK:
        return vkIcon
      default:
        return siteIcon
    }
  }

  const cancelOrders = ({pickup, courier, reason}: any) => {
    const data = {
      order: {
        usePickup: pickup,
        useCourier: courier,
        ordersDisablingReason: reason
      }
    }
    const formData = getFormData('update', {...data, _method: "patch"}, new FormData());
    apiClient.branches.update(company.id, branch.id, {body: formData}).then(({data, errors, message}: any) => {
      if (data) {
        setUsePickup(data.order.usePickup)
        setUseCourier(data.order.useCourier)
      } else {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      }
    }).catch(() => {})
  }

  const searching = (value: any) => {
    setApplyFilter(true)
    setSearch(value || '')
    updateFilters('search', value || null)
  }

  const getPaymentIcon = (paymentStatus: null|PaymentStatus) => {
    if (!paymentStatus) return <></>

    switch (paymentStatus) {
      case PaymentStatus.SUCCEEDED:
        return <img src={successIcon} alt="" className={'w-[20px] h-[20px] ml-[10px]'} />
      case PaymentStatus.CANCELED:
      case PaymentStatus.WAITING_CONFIRMATION:
      case PaymentStatus.PENDING:
      default:
        return <></>  
    }
  }

  const columnHelper = createColumnHelper<any>()

  const columns = [
    columnHelper.accessor(row => row.id, {
      id: 'id',
      header: () => 'ID',
      cell: info => <div
        className={'w-[max(70px,5%)] font-normal break-all flex items-center mx-3'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[max(70px,5%)] flex justify-start text-center mx-3',
        sortField: 'created-at'
      }
    }),
    columnHelper.accessor(row => row.createdAt, {
      id: 'createdAt',
      header: () => 'Создан',
      cell: info => <div
        className={'w-[max(100px,7%)] font-normal break-words flex items-center mr-3'}>{getDateAndTime(info.getValue())}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[max(100px,7%)] flex justify-start text-center mr-3',
        sortField: 'createdAt'
      }
    }),
    columnHelper.accessor(row => row.customer, {
      id: 'customer',
      header: () => 'Клиент',
      cell: info => <div
        className={'w-1/3 font-normal break-all flex flex-col items-start mr-3 my-3'}>
        {
          (!info.getValue() && !info.row.original?.clientPhone) ? 
          <p className="text-error-font">Клиент не найден</p> :
          <div className=" break-all flex flex-col items-start">
            {
                info.getValue()?.id && <Link className={'text-interactive-elem'} to={info.getValue()?.id != null ? `/clients/${info.getValue()?.id}/edit` : '/orders'}>
                  {info.getValue()?.name}
                </Link>
            }
            <p>{info.getValue()?.phone || info.row.original?.clientPhone}</p>
          </div>
        }
        {
          info.row?.original?.deliveryAddress && <p className={'text-gray-40'}>{info.row?.original?.deliveryAddress?.title}</p>
        }
        {
          info.row?.original?.pickupPoint && <p className={'text-gray-40'}><span className="underline">Точка самовывоза:</span> {getSimpleAddress(info.row?.original?.pickupPoint?.address) ?? 'не определена'}</p>
        }
        {//TODO: Необходимо выводить не метки клиента, а метки заказов. Они генерируются с помощью воронок. Надо будет узнать поле с метками заказов и изменить тут вывод
          <div className={'flex flex-col w-auto'}>
            {
                (info.row?.original?.tags && info.row?.original?.tags?.length) ?
                  info.row?.original?.tags?.map((tag: any) => {
                  return <TagLabel classNameBorder={'w-fit'} marginTags={'my-1'} backgroundColor={tag.background} textColor={tag.color}>{tag.title}</TagLabel>
                })
                : <></>
            }
          </div>
        }
        {
          info.row.original?.preorder && info.row.original?.preorderAt && <div className={`p-2 bg-gray-20/[.2] rounded-lg text-[10px] leading-[10px] !font-size-[10px] flex items-center text-center`}>
            Доставить: {getDateAndTime(info.row.original?.preorderAt)}
          </div>
        }
        <ExternalResponseContainer order={info.row.original} updateOrders={updateOrders} limits={limits} filters={filters} />
      </div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-1/3 flex justify-start text-center mr-3',
        // sortField: 'customer'
      }
    }),
    columnHelper.accessor(row => row.amount, {
      id: 'amount',
      header: () => 'Сумма',
      cell: info => <div
        className={'w-[max(70px,5%)] font-normal break-all flex items-center justify-center'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[max(70px,5%)] flex justify-center',
      }
    }),
    columnHelper.accessor(row => row.discount, {
      id: 'discount',
      header: () => 'Скидка',
      cell: info => <div
        className={'w-[max(70px,5%)] font-normal break-all flex items-center justify-center'}>{info.getValue()+(info.row.original?.bonusDiscount || 0)}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[max(70px,5%)] flex justify-center',
      }
    }),
    columnHelper.accessor(row => row.total, {
      id: 'total',
      header: () => 'Итого',
      cell: info => <div
        className={'w-[max(70px,5%)] font-normal break-all flex items-center justify-center'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[max(70px,5%)] flex justify-center',
      }
    }),
    columnHelper.accessor(row => row.paymentType, {
      id: 'paymentType',
      header: () => 'Оплата',
      cell: info => <div
        className={'w-[max(100px,8%)] font-normal break-word flex items-center justify-center'}>
          {info.getValue()}
          {getPaymentIcon(info.row.original?.payment?.status || null)}
        </div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[max(100px,8%)] flex justify-center',
      }
    }),
    columnHelper.accessor(row => row.source, {
      id: 'source',
      header: () => 'Источник',
      cell: info => <div
        className={'w-[max(70px,5%)] font-normal flex justify-center items-center '}>
        <img className={'w-[20px] h-[20px]'} src={getSourceIcon(info.getValue())} alt="" />
      </div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[max(70px,5%)] flex justify-center text-center ',
      }
    }),
    columnHelper.accessor(row => row.orderStatus.title, {
      id: '-',
      header: () => 'Статус',
      cell: info => <div className="w-[max(200px,10%)] flex items-center">
        <SingleSimpleValueSelect
          name={'status'}
          textTooltip={'Статус заказа'}
          placeholder={'Выберите статус заказа'}
          value={info.row?.original?.orderStatus?.code}
          options={company && company?.statuses ? company?.statuses.map(({code, title}: any) => {return {label: title, value: code}}) : []}
          onChange={(option: any) => {
            apiClient.orders.update(company?.id, branch?.id, info.row?.original.id, {body: {status: option, _method: "patch"}}).then(({data, errors}: any) => {
              if (data) {
                showSuccessToast({
                  content: "Заказ успешно изменен",
                })
                updateOrders(limits, filters)
              } else {
                showErrorToast({
                  content: 'Что-то пошло не так'
                })

              }
            })
          }}
        />
      </div>
      ,
      enableSorting: false,
      meta: {
        widthClass: 'w-[max(200px,10%)] flex justify-center text-center',
      }
    }),
    columnHelper.accessor(row => row.estimation, {
      id: 'estimation',
      header: () => 'Оценка',
      cell: info => <div
        className={'w-[max(70px,5%)] font-normal flex justify-center items-center '}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[max(70px,5%)] flex justify-center text-center ',
      }
    }),
    columnHelper.accessor(row => row.id, {
      id: '-',
      header: () => '',
      cell: info => <div
        className={'w-[max(100px,6%)] font-normal flex flex-row gap-x-4 px-2 justify-center items-center'}>
        <Link className={'text-interactive-elem'} to={info.row?.original?.id != null ? `/orders/${info.row?.original?.id}` : '/orders'}>
          <img className={'w-[20px] h-[20px]'} src={fileIcon} alt=""/>
        </Link>
        <Link className={'text-interactive-elem'} to={info.row?.original?.id != null ? `/orders/${info.row?.original?.id}/edit` : '/orders'}>
          <img className={'w-[20px] h-[20px]'} src={editIcon} alt="" />
        </Link>
      </div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[max(100px,6%)] flex justify-center text-center',
      }
    }),
  ]

  useEffect(() => {
    setApplyFilter(true)
    updateFilters('filter', {'createdAt][after': dateFilter[0], 'createdAt][before': dateFilter[1]})
  }, [dateFilter])

  useEffect(() => {
    if (company && branch) {
      apiClient.branches.get(company.id, branch.id).then(({data, errors, message}: any) => {
        if (data) {
          setUsePickup(data.order.usePickup)
          setUseCourier(data.order.useCourier)
        } else {
          showErrorToast({
            content:'Не удалось загрузить данные о возможности доставки и самовывоза'
          })
        }
      }).catch(()=>{})
    }
  }, [branch])

  if (!applyFilter && orders?.length === 0) return (
    <div className="px-8 pb-8 pt-2">
      <div className="flex flex-col gap-y-6">
        <div className="flex justify-between items-end">
          <div>
            <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Заказы</div>
            <div className="text-gray-40 text-base">Список заказов</div>
          </div>
          {
            branch &&
            <Link to={'create'}>
              <BaseButton className={"ml-5 bg-interactive-elem text-white"}>Создать заказ</BaseButton>
            </Link>
          }
        </div>
      </div>
      <div className="text-gray-40 text-3xl text-center mt-[35vh]">
        Заказов нет
      </div>
    </div>
  )

  return (
    <div className={`px-8 pb-8 pt-2 ${hiddenMode ? 'w-[calc(99vw-60px)]' : 'w-[calc(99vw-340px)]'}`}>
      <div className="flex flex-col gap-y-6">
        <div>
          <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Заказы</div>
          <div className="text-gray-40 text-base">Список заказов</div>
        </div>
        {
          !!branch ?
          <>
            <div className="flex flex-row items-center gap-x-6">
              <Checkbox
                label={'Отключить прием заказов на доставку'}
                name={'xs'}
                onChange={()=>{console.log('+')}}
                value={!useCourier}
                onClick={() => {
                  if (!useCourier) {
                    setUseCourier(true)
                    cancelOrders({pickup: usePickup, courier: true, reason: null})
                  } else {
                    modalCancelOrder('courier')
                  }
                }}
              />
              <Checkbox
                label={'Отключить прием заказов на самовывоз'}
                name={'xs'}
                onChange={()=>{console.log('+')}}
                value={!usePickup}
                onClick={() => {
                  if (!usePickup) {
                    setUsePickup(true)
                    cancelOrders({pickup: true, courier: useCourier, reason: null})
                  } else {
                    modalCancelOrder('pickup')
                  }
                }}
              />
              <Checkbox
                label={'Отключить прием заказов'}
                name={'xs'}
                onChange={(value: any)=>{
                  console.log(`прием заказов = ${value}`)
                }}
                value={!useCourier && !usePickup}
                onClick={() => {
                  if (!usePickup && !useCourier) {
                    setUsePickup(true)
                    setUseCourier(true)
                    cancelOrders({pickup: true, courier: true, reason: null})
                  } else {
                    modalCancelOrder()
                  }
                }}
              />
            </div>
            <div className="flex flex-row gap-x-6 items-center justify-between">
              <div className={'flex flex-row gap-x-4'}>
              <Actions
                updateValue={(value: any) => {
                  searching(value)
                }}
                fetchData={(value: any) => {}}
                classNameSearch={'min-w-[250px]'}
                searchValue={search}
                classNameMarginSearch={'mt-0'}
              />
              <div className="flex items-center gap-x-2">
                <RangeDatePicker
                  required={false}
                  values={dateFilter}
                  placeholder='Выберите промежуток дат'
                  onChange={([startAt, endAt]: any) => {
                    setDateFilter([startAt, endAt])
                  }}
                  errors={{}}
                  name={'dateFilter'}
                />
                <BaseButton onClick={() => {updateOrders(limits, filters)}} className={"bg-interactive-elem text-white"}>Найти</BaseButton>
              </div>
              </div>
              <div className={'flex flex-row gap-x-4'}>
                <Link to={'create'}>
                  <BaseButton className={"bg-interactive-elem text-white"}>Создать заказ</BaseButton>
                </Link>
              </div>
            </div>
              <Export
                title={'Экспорт заказов'}
                types={[{ title: 'Экспортировать', type: ExportBlocks.orders }]}
                filter={filters}
                order={limits?.order}
              />
            <DataTable
              data={orders}
              columns={columns}
              usePagination={true}
              pagination={pagination}
              updateData={(params: any)=>{updateOrders(params, filters)}}
              loading={loadingOrders}
              useMassAction={false}
              classNameTable={'mt-0'}
            />
          </> :
          <div className="text-gray-40 text-3xl text-center mt-[35vh]">
            Выберите филиал
          </div>
        }
      </div>
    </div>
  )
}
