const tabs = [
  {
    label: 'Основаная информация',
    tag: 'baseInformation',
    required: true,
    fields: [
      'preorder',
      'createdAt',
      'orderStatusCode',
      'clientName',
      'clientPhone',
      'customer.name',
      'customer.phone',
      'deliveryType',
      'deliveryAddress',
      'preorderAt',
      'paymentTypeCode',
      'personCount',
      'comment',
      'promoCodeId',
    ],
  },
  {
    label: 'Состав заказа',
    tag: 'orderComposition',
    required: true,
    fields: [
      'goods'
    ],
  },
  {
    label: 'Отзыв',
    tag: 'review',
    fields: [
      'review.status',
      'review.estimation',
      'review.text',
      'review.answer',
      'review.images'
    ]
  },
]

export default tabs
