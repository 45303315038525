import React, { useEffect, useState } from 'react'
import Checkbox from '../../../components/common/form/checkbox/Checkbox';
import { useCompaniesContext } from '../../../contexts/Companies';
import BaseButton from "../../../components/common/Button/BaseButton";
import { showSuccessToast } from "../../../libs/helpers/toasts";
import { dialogModal } from "../../../components/common/Modal/DialogModal";
import { apiClient } from "../../../libs/api/apiClient";

// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard';
import InputWrapper from "../../../components/v2/form/input/common/InputWrapper";
import Input from "../../../components/v2/form/input/Input";
import Spoiler from "../../../components/v2/Spoiler";
import TokenInput from '../../../components/v2/form/input/TokenInput';

type Frontpad = {
  secret: undefined | string,
  salesChannel: undefined | {
    default: undefined | string,
    site: undefined | string,
    vk: undefined | string,
    ios: undefined | string,
    android: undefined | string
  },
  branch: undefined | string,
  pointSale: undefined | string,
  phoneMask: undefined | string,
  paymentDefault: undefined | string,
  paymentTransfer: undefined | string,
  paymentOnline: undefined | string,
  maxBonusDiscount: undefined | number,
  statuses: undefined | { [key in string]: number },
  integrationIdentifierSecret: undefined | string,
  usePersonalDiscounts: undefined | boolean,
  usePoints: undefined | boolean,
  usePromo: undefined | boolean
}

const isDirty = (previous: object, current: object): boolean => {
  return Object
    .keys(current)
    .reduce((changed: boolean, key: string) => {
      if (undefined === previous[key]) {
        return true;
      }

      if (null === current[key] && null === previous[key]) {
        return changed || false;
      }

      if (null !== current[key] && typeof current[key] === 'object') {
        return changed || isDirty(previous[key], current[key]);
      }

      return changed || previous[key] !== current[key]
    }, false);
}

export default function Frontpad({ data, updateField, errors }: any) {
  const { company } = useCompaniesContext()
  const [loadSecretToken, setLoadSecretToken] = useState(false);
  const [properties, setProperties] = useState<Frontpad>(data.integrations?.frontpad || {})
  const [initialProperties, setInitialProperties] = useState<Frontpad>(properties)

  const updateIntegration = () => {
    // Костыль. Объект frontpad обновляется полностью, кроме secret
    const update = { ...properties };
    if (update.secret === initialProperties.secret) {
      delete update.secret;
    }
    updateField(`integrations.frontpad`, update);
  }

  const updateProperties = (updating: object) => {
    delete errors['integrations.frontpad.integrationIdentifierSecret'];
    setProperties(options => ({...options, ...updating}));
  }

  const updateSecretToken = () => {
    if (! properties.integrationIdentifierSecret) {
      setLoadSecretToken(true);

      apiClient.branches.updateSecterToken(company.id)
        .then(({ data }) => {
          updateProperties({integrationIdentifierSecret: data.secret});
        })
        .finally(() => {
          setLoadSecretToken(false);
        });

      return;
    }

    dialogModal({
      isOpen: false,
      title: 'Внимание!',
      text: 'Вы уверены что хотите обновить секретный ключ?',
      showCancelButton: true,
      confirmButtonText: 'Обновить',
      cancelButtonText: 'Вернуться',
    })
      .then((result) => {
        if (! result.isConfirmed) {
          return;
        }

        setLoadSecretToken(true);
        apiClient.branches.updateSecterToken(company.id).then(({ data }) => {
          updateProperties({integrationIdentifierSecret: data.secret});
        })
      })
      .catch(() => {
      })
      .then(() => {
        setLoadSecretToken(false);
      });
  }

  useEffect(() => {
    if (! isDirty(data.integrations.frontpad || {}, properties)) {
      return;
    }

    updateIntegration();
  }, [JSON.stringify(properties)]);

  return (
    <Spoiler title={ "FrontPad" } defaultOpen={true}>
      <div className={'space-y-8'}>
        <div className={'grid grid-cols-3 gap-4'}>
          <div>
            <InputWrapper>
              <InputWrapper.Label>
                API секрет
              </InputWrapper.Label>
              <TokenInput
                defaultValue={ properties.secret }
                name={ 'integrations.frontpad.secret' }
                placeholder={ 'API секрет' }
                onBlur={ (event) => updateProperties({ secret: event.target.value }) }
              />
            </InputWrapper>
          </div>
        </div>

        <div className={ 'grid grid-cols-3 gap-4' }>
          <div>
            <InputWrapper>
              <InputWrapper.Label>
                Канал продаж (Default)
              </InputWrapper.Label>
              <Input
                defaultValue={ properties.salesChannel?.default }
                name={ 'integrations.frontpad.salesChannel.default' }
                placeholder={ 'Канал продаж (Default)' }
                onBlur={ (event) => updateProperties({
                  salesChannel: {
                    ...properties.salesChannel,
                    default: event.target.value
                  }
                }) }
              />
            </InputWrapper>
          </div>

          <div>
            <InputWrapper>
              <InputWrapper.Label>
                Канал продаж (Сайт)
              </InputWrapper.Label>
              <Input
                defaultValue={ properties.salesChannel?.site }
                name={ 'integrations.frontpad.salesChannel.site' }
                placeholder={ 'Канал продаж (Сайт)' }
                onBlur={ (event) => updateProperties({
                  salesChannel: {
                    ...properties.salesChannel,
                    site: event.target.value
                  }
                }) }
              />
            </InputWrapper>
          </div>

          <div>
            <InputWrapper>
              <InputWrapper.Label>
                Канал продаж (Приложение Вконтакте)
              </InputWrapper.Label>
              <Input
                defaultValue={ properties.salesChannel?.vk }
                name={ 'integrations.frontpad.salesChannel.vk' }
                placeholder={ 'Канал продаж (Приложение Вконтакте)' }
                onBlur={ (event) => updateProperties({
                  salesChannel: {
                    ...properties.salesChannel,
                    vk: event.target.value
                  }
                }) }
              />
            </InputWrapper>
          </div>

          <div>
            <InputWrapper>
              <InputWrapper.Label>
                Канал продаж (Моб. приложение IOS)
              </InputWrapper.Label>
              <Input
                defaultValue={ properties.salesChannel?.ios }
                name={ 'integrations.frontpad.salesChannel.ios' }
                placeholder={ 'Канал продаж (Моб. приложение IOS)' }
                onBlur={ (event) => updateProperties({
                  salesChannel: {
                    ...properties.salesChannel,
                    ios: event.target.value
                  }
                }) }
              />
            </InputWrapper>
          </div>

          <div>
            <InputWrapper>
              <InputWrapper.Label>
                Канал продаж (Моб. приложение Android)
              </InputWrapper.Label>
              <Input
                defaultValue={ properties.salesChannel?.android }
                name={ 'integrations.frontpad.salesChannel.android' }
                placeholder={ 'Канал продаж (Моб. приложение Android)' }
                onBlur={ (event) => updateProperties({
                  salesChannel: {
                    ...properties.salesChannel,
                    android: event.target.value
                  }
                }) }
              />
            </InputWrapper>
          </div>
        </div>

        <div className={ 'grid grid-cols-2 gap-4' }>
          <div>
            <InputWrapper>
              <InputWrapper.Label>
                Филиал
              </InputWrapper.Label>
              <Input
                defaultValue={ properties.branch }
                name={ 'integrations.frontpad.branch' }
                placeholder={ 'Филиал' }
                onBlur={ (event) => updateProperties({ branch: event.target.value }) }
              />
            </InputWrapper>
          </div>

          <div>
            <InputWrapper>
              <InputWrapper.Label>
                Точка продаж
              </InputWrapper.Label>
              <Input
                defaultValue={ properties.pointSale }
                name={ 'integrations.frontpad.pointSale' }
                placeholder={ 'Точка продаж' }
                onBlur={ (event) => updateProperties({ pointSale: event.target.value }) }
              />
            </InputWrapper>
          </div>
        </div>

        <div className={ 'grid grid-cols-3 gap-4' }>
          <div>
            <InputWrapper>
              <InputWrapper.Label>
                Маска телефона
              </InputWrapper.Label>
              <Input
                defaultValue={ properties.phoneMask }
                name={ 'integrations.frontpad.phoneMask' }
                placeholder={ 'Маска телефона' }
                onBlur={ (event) => updateProperties({ phoneMask: event.target.value }) }
              />
            </InputWrapper>
          </div>
        </div>

        <div className={ 'grid grid-cols-3 gap-4' }>
          <div>
            <InputWrapper>
              <InputWrapper.Label>
                Оплата (Default)
              </InputWrapper.Label>
              <Input
                defaultValue={ properties.paymentDefault }
                name={ 'integrations.frontpad.paymentDefault' }
                placeholder={ 'Оплата (Default)' }
                onBlur={ (event) => updateProperties({ paymentDefault: event.target.value }) }
              />
            </InputWrapper>
          </div>

          <div>
            <InputWrapper>
              <InputWrapper.Label>
                Оплата (Моб. банк)
              </InputWrapper.Label>
              <Input
                defaultValue={ properties.paymentTransfer }
                name={ 'integrations.frontpad.paymentTransfer' }
                placeholder={ 'Оплата (Моб. банк)' }
                onBlur={ (event) => updateProperties({ paymentTransfer: event.target.value }) }
              />
            </InputWrapper>
          </div>

          <div>
            <InputWrapper>
              <InputWrapper.Label>
                Оплата (Online)
              </InputWrapper.Label>
              <Input
                defaultValue={ properties.paymentOnline }
                name={ 'integrations.frontpad.paymentOnline' }
                placeholder={ 'Оплата (Online)' }
                onBlur={ (event) => updateProperties({ paymentOnline: event.target.value }) }
              />
            </InputWrapper>
          </div>
        </div>

        <div className={ 'grid grid-cols-3 gap-4' }>
          <div>
            <InputWrapper>
              <InputWrapper.Label>
                Лимит списания баллов (0-100)
              </InputWrapper.Label>
              <Input
                defaultValue={ properties.maxBonusDiscount ?? 0 }
                name={ 'integrations.frontpad.maxBonusDiscount' }
                placeholder={ 'Лимит списания баллов (0-100)' }
                max={ 100 }
                min={ 0 }
                onBlur={ (event) => {
                  const value = Math.max(0, Math.min(100, +event.target.value));
                  event.target.value = value.toString();
                  updateProperties({ maxBonusDiscount: value });
                } }
              />
            </InputWrapper>
          </div>
        </div>

        <div className={ 'grid grid-cols-3 gap-4' }>
          {
            company?.statuses?.map((status: { title: string, code: string }) => (
              <div key={`statuses-${status.code}`}>
                <InputWrapper>
                  <InputWrapper.Label>
                    { `Статус заказа (${ status?.title })` }
                  </InputWrapper.Label>
                  <Input
                    defaultValue={ properties?.statuses?.[status.code] }
                    name={ `integrations.frontpad.statuses.${ status?.code }` }
                    placeholder={ `Статус заказа (${ status?.title })` }
                    onBlur={ (event) => updateProperties({
                      statuses: {
                        ...properties.statuses,
                        [status.code]: event.target.value
                      }
                    }) }
                  />
                </InputWrapper>
              </div>
            ))
          }
        </div>

        <div className={ 'grid grid-cols-1 mb-4' }>
          <div className={ `flex items-end gap-5 py-3 px-4 border-gray-20 border-[1px] rounded-lg` }>
            <div className={ 'w-full' }>
              <InputWrapper
                error={ errors['integrations.frontpad.integrationIdentifierSecret'] }
              >
                <InputWrapper.Label>
                  Секретный ключ
                </InputWrapper.Label>
                <span
                  className={ 'pointer-events-none select-none' }>{ properties.integrationIdentifierSecret || 'Секретный ключ' }</span>
              </InputWrapper>
            </div>

            <BaseButton onClick={ updateSecretToken }
                        className={ "bg-interactive-elem text-white h-[42px] min-w-[110px]" }>
              {
                loadSecretToken ?
                  <div className={ 'flex flex-row items-center justify-center' }>
                    <svg
                      className="animate-spin h-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                              strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </div> : 'Обновить'
              }
            </BaseButton>
            <CopyToClipboard text={ properties.integrationIdentifierSecret }
                             onCopy={ () => showSuccessToast({ content: "Секретный ключ скопирован", }) }>
              <BaseButton onClick={ () => window.navigator }
                          className={ "bg-interactive-elem text-white h-[42px]" }> Копировать </BaseButton>
            </CopyToClipboard>
          </div>
        </div>

        {/* Инвертируем значение, ибо бек принемает use..., а лейбал: 'Не использовать...' */ }
        <div className='flex flex-col gap-2'>
          <Checkbox
            label='Не использовать персональные скидки'
            name={ 'integrations.frontpad.usePersonalDiscounts' }
            value={ ! Boolean(+(properties?.usePersonalDiscounts || 0)) }
            onChange={ (value: any) => updateProperties({ usePersonalDiscounts: ! value }) }
          />

          <Checkbox
            label='Не использовать баллы'
            name={ 'integrations.frontpad.usePoints' }
            value={ ! Boolean(+(properties?.usePoints || 0)) }
            onChange={ (value: any) => updateProperties({ usePoints: ! value }) }
          />

          <Checkbox
            label='Не использовать промокоды'
            name={ 'integrations.frontpad.usePromo' }
            value={ ! Boolean(+(properties?.usePromo || 0)) }
            onChange={ (value: any) => updateProperties({ usePromo: ! value }) }
          />

          <Spoiler title={ 'Подробнее' } size={'sm'} toggleTextColor={'text-interactive-text'}>
            <div className={ "flex flex-col font-medium mt-3 gap-y-2 max-w-[90%]" }>
              <div className='mb-4'>
                Для того что бы связь работала, необходимо выполнить следующие пункты:
              </div>
              <ul className='mb-4'>
                <li>
                  1. Включить функцию API на вашем тарифном плане в FrontPad
                </li>
                <li>
                  2. Скопировать и вставить значение API Секрет
                </li>
                <li>
                  3. Указать Webhook URL в настройках FrontPad: { (data.integrations.frontpad.webhook && (
                  <span className='font-semibold underline'>{ data.integrations.frontpad.webhook }</span>)) || (
                  <span
                    className={ 'text-gray-500' }>ссылка на веб-хук будет доступна после сохранения настроек</span>) }
                </li>
              </ul>
              <p>
                Чтобы отслеживать заказы оформленные в Result.Rest необходимо создать новый канал продаж в FrontPad и
                указать "API код" канала продаж в настройках.
              </p>
              <p>
                Для корректной передачи заказов необходимо указать "Артикул" для всех элементов каталога (товары,опции),
                артикул должен быть одинаковый для FrontPad и для Result.Rest
              </p>
              Каналы продаж — могут различаться в зависимости от платформы. У каждой платформы может быть свой канал
              продаж.
              <p>

                Оплата (default) – это вариант оплаты применяемый ко всем заказам по умолчанию.
                Оставьте поле пустым, чтобы указанные варианты (наличный / безналичный) переносились в заказе в
                FrontPad.
              </p>
              <p>
                Оплата (On-line) – это вариант оплаты применяемый в случае, когда пользователь выбрал оплату on-line.
              </p>
              <p>
                Важно понимать, что наличие данного статуса в заказе, не гарантирует фактическую оплату заказа он-лайн.
                Передачу заказов после фактической оплаты можно настроить в разделе "Платёжная система"
              </p>
            </div>
            <div>
            </div>
          </Spoiler>
        </div>
      </div>
    </Spoiler>
  );
}
