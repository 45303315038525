import {apiClient} from "../../libs/api/apiClient";

export async function fetchTimerTypes() {
  const { data }: any = await apiClient.dictionaries.funnelsTimerTypes()

  return data.map((type: any) => ({
    label: type.title,
    value: type.value,
    code: type.code
  }))
}

export async function fetchInitialTypes() {
  const { data }: any = await apiClient.dictionaries.funnelsInitialTypes()

  return data.map((type: any) => ({
    label: type.title,
    value: type.value,
    code: type.code
  }))
}

export async function fetchOverlapTypes() {
  const { data }: any = await apiClient.dictionaries.funnelsOverlapTypes()

  return data.map((type: any) => ({
    label: type.title,
    value: type.value,
    code: type.code
  }))
}
