import React, { useState, useEffect } from 'react'
import TextInput from '../../../components/common/form/input/TextInput'
import SingleSimpleSelect from '../../../components/common/form/select/SingleSimpleSelect'
import ImageLoadMultiple from '../../../components/common/form/image/ImageLoadMultiple'
import fetchTimeZones from '../../../providers/dictionaries/timeZones'

export default function Base({data, updateField, errors, updateFieldNoRender}: any) {

  const [timeZones, setTimeZones] = useState([])
  useEffect(() => {
    fetchTimeZones().then(data => {
      setTimeZones(data)
    })
  }, [])


  useEffect(() => {
    // Это нужно чтобы установить выбранный элемент в селекторе
    if (timeZones.length && data?.base?.timeZoneId && !data?.base?.__time_zone_select) {
      updateField('base.__time_zone_select', timeZones.filter(((item: any) => item.value == data?.base?.timeZoneId)).at(0))
    }
  }, [data, timeZones])

  return (
    <div className={'flex flex-col px-3 gap-y-6'}>
        {
          <>
            <TextInput
              label='Название филиала'
              textTooltip='Название филиала'
              placeholder='Введите название филиала'
              name={'base.title'}
              errors={errors}
              onChange={value => updateFieldNoRender('base.title', value)}
              value={data?.base?.title}
              required={true}
            />

            <SingleSimpleSelect
              label='GMT (Часовой пояс)'
              textTooltip='GMT (Часовой пояс)'
              placeholder='GMT+3 (Киров, Кировская область)'
              name={'base.timeZoneId'}
              errors={errors}
              options={timeZones}
              onChange={(option: any) => {
                updateField('base.__time_zone_select', option)
                updateField('base.timeZoneId', option.value)
              }}
              value={data?.base?.__time_zone_select}
              required={true}
            />

            <ImageLoadMultiple
              label="Слайды в шапке ПК (формат: JPG, JPEG, PNG. Можно загрузить до 10 изображений)"
              onChange={(value: any) => updateField('base.slidesDesktop', value)}
              onDelete={(value: any) => {
                console.log(value)
                if (value?.id) {
                  if (data?.base?.detachedSlidesDesktop) {
                    return updateField('base.detachedSlidesDesktop', [...data?.base?.detachedSlidesDesktop, value?.id])
                  }
                  return updateField('base.detachedSlidesDesktop', [value?.id])
                }
              }}
              value={data?.base?.slidesDesktop}
              errors={errors}
              name='base.slidesDesktop'
              max={10}
              resizeTo={[-1, -1]}
              forceAspectRatio={215/71}
              classNameImgWrapper={'!h-[88px]'}
            />

            <ImageLoadMultiple
              label="Слайды в шапке Смартфон (формат: JPG, JPEG, PNG. Можно загрузить до 10 изображений)"
              onChange={(value: any) => updateField('base.slidesMobile', value)}
              onDelete={(value: any) => {
                if (value?.id) {
                  if (data?.base?.detachedSlidesMobile) {
                    return updateField('base.detachedSlidesMobile', [...data?.base?.detachedSlidesMobile, value?.id])
                  }
                  return updateField('base.detachedSlidesMobile', [value?.id])
                }
              }}
              value={data?.base?.slidesMobile}
              errors={errors}
              name='base.slidesMobile'
              max={10}
              resizeTo={[440, 440]}
              classNameImgWrapper={'!h-[88px]'}
            />

            {/*<CityLocation
              value={data?.base?.city}
              onChange={value => updateField('base.city', value)}
              name='base.city'
              placeholder='Введите краткое описание'
              label='Город'
              textTooltip='Город'
              required={true}
              errors={errors}
            />*/}
          </>
        }

    </div>
  )
}
