import React, {useState} from "react";
import {Link} from "react-router-dom";
import BaseButton from "../../components/common/Button/BaseButton";
import FormWrapper from "../../components/common/form/FormWrapper";
import CreateFormFields from "../../components/common/form/CreateFormFields";
import tabs from "./tabs";
import {createColumnHelper} from "@tanstack/react-table";
import {History} from "./types";
import {getDateAndTime} from "../../libs/helpers/datetime";
import {HistoryActiveContextWrapper, HistoryArchiveContextWrapper} from "../../contexts/Histories";
import {ActiveHistoriesTable} from "./ActiveHistoriesTable";
import {ArchiveHistoriesTable} from "./ArchiveHistoriesTable";
import { useBranchesContext } from "../../contexts/Branches";

export default function Histories() {
  const [tab, setTab] = useState('active')
  const { branch } = useBranchesContext()

  const columnHelper = createColumnHelper<History>()

  const columns = [
    columnHelper.accessor(row => row.id, {
      id: 'id',
      header: () => 'ID',
      cell: info => <div
        className={'w-[5%] font-normal break-all flex items-center ml-5'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[5%] flex justify-start text-center ml-5',
      }
    }),
    columnHelper.accessor(row => row.createdAt, {
      id: 'createdAt',
      header: () => 'Дата',
      cell: info => <div
        className={'w-[15%] font-normal text-center flex items-center ml-5'}>{getDateAndTime(info.getValue())}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[15%] flex justify-start text-center ml-5',
        sortField: 'created-at'
      }
    }),
    columnHelper.accessor(row => row.title, {
      id: 'title',
      header: () => 'Заголовок',
      cell: info => <div className={'w-[30%] flex items-center text-interactive-elem'}>
        <Link to={`/histories/${info.row?.original?.id}/edit`}>
          {info.getValue()}
        </Link>
      </div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[30%] flex justify-start text-center px-2 ',
      }
    }),
    columnHelper.accessor(row => row.startPublishedAt, {
      id: 'startPublishedAt',
      header: () => 'Дата и время начала',
      cell: info => <div
        className={'w-[20%] font-normal text-center flex items-center px-2'}>{info.getValue() ? getDateAndTime(info.getValue() || '') : 'Не указано'}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[20%] flex justify-start text-center px-2 ',
        sortField: 'start-published-at'
      }
    }),
    columnHelper.accessor(row => row.endPublishedAt, {
      id: 'endPublishedAt',
      header: () => 'Дата и время окончания',
      cell: info => <div
        className={'w-[20%] font-normal text-center flex items-center ml-5'}>{info.getValue() ? getDateAndTime(info.getValue() || '') : 'Не указано'}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[20%] flex justify-start text-center ml-5',
        sortField: 'end-published-at'
      }
    }),
    columnHelper.accessor(row => row.platforms, {
      id: 'platforms',
      header: () => 'Место размещения',
      cell: info => <div
        className={'w-[20%] font-normal break-all text-center flex items-center px-2'}>{info.getValue() ? info.getValue()?.join('/') : ''}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[20%] flex justify-start text-center px-2 ',
      }
    }),
  ]

  return (
    <div className="px-8 pb-8 pt-2">
      <div className="flex flex-col">
        <div className="flex justify-between items-end">
          <div>
            <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Истории</div>
            <div className="text-gray-40 text-base">Список историй</div>
          </div>
          <div className={'flex flex-row justify-end items-center'}>
            {
              !!branch && 
              <Link to={'create'}>
                <BaseButton className={"ml-5 bg-interactive-elem text-white"}>Добавить историю</BaseButton>
              </Link>
            }
          </div>
        </div>

      </div>
      <div className={'mt-8'}>
        {
          branch ?
          <FormWrapper
            tabs={tabs}
            tab={tab}
            setTab={setTab}
            heightForm={'min-h-[350px] max-h-[700px]'}
          >
            <CreateFormFields
              show={tab === 'active'}
              setTab={setTab}
            >
              <HistoryActiveContextWrapper>
                <ActiveHistoriesTable columns={columns} />
              </HistoryActiveContextWrapper>
            </CreateFormFields>

            <CreateFormFields
              show={tab === 'archive'}
              setTab={setTab}
            >
              <HistoryArchiveContextWrapper>
                <ArchiveHistoriesTable columns={columns} />
              </HistoryArchiveContextWrapper>
            </CreateFormFields>

          </FormWrapper> :
          <div className="text-gray-40 text-3xl text-center mt-[35vh]">
            Выберите филиал
          </div>
        }
      </div>
    </div>
  )
}
