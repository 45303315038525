import React from "react";



export const CompanyBlockedError = ({company = ''}) => {
    let errorText = <div>Ваша компания заблокирована. Необходимо продлить тариф.</div>
    if (company) {
        errorText = <div>Ваша компания «<span className="font-bold">{company}</span>» заблокирована. Необходимо продлить тариф.</div>
    }

    return <div className="text-black">
        {errorText} <a className="underline text-interactive-text" href="https://vk.me/result.rest">Обратитесь в поддержку</a>
    </div>
}