import React, { ReactElement, useEffect, useId, useRef, useState } from "react";
import Tooltip from "../../Tooltip";

interface ITextInputProps {
  type?: string
  name: string
  value?: string
  initialValueReplaceOnly?: boolean // Изначальное значение нельзя изменить, только заменить
  disabled?: boolean
  readOnly?: boolean
  onChange?: Function|any
  onClick?: Function|any
  onKeyDown?: Function|any
  onBlur?: Function|any
  getAuxElement?: (auxState: any, setAuxState: (s: any) => void, setDisabled: (s: boolean) => void) => ReactElement
  autoComplete?: string
  className?: string
  classNameInput?: string
  placeholder?: string
  label?: string
  errors: object
  icon?: string
  errorLabel?: boolean
  required?: boolean
  textTooltip?: string
  min?: any
  max?: any,
  maxLength?: number,
  showLength?: boolean,
  valueThrottle?: boolean
}

export default function TextInput({
  type='text',
  name,
  value,
  initialValueReplaceOnly=false,
  disabled = false,
  readOnly = false,
  onChange=(value: any)=> {},
  onBlur,
  onClick=()=>{},
  onKeyDown=()=>{},
  getAuxElement,
  autoComplete = 'off',
  className = '',
  classNameInput = '',
  placeholder = '',
  label = '',
  errors = {},
  icon = '',
  errorLabel = true,
  required = false,
  textTooltip = '',
  maxLength,
  showLength = false,
  valueThrottle = true,
  ...props
}: ITextInputProps) {
  const id = useId();
  const input = useRef(null);
  const error = errors[name];

  const [local, setLocal] = useState(value);

  const [auxState, setAuxState] = useState<any>(undefined)
  const [inputLength, setInputLength] = useState(local?.length || 0)
  const [isDisabled, setDisabled] = useState<boolean>(disabled);
  const [initialValue, setInitialValue] = useState(value);

  useEffect(() => {
    setDisabled(disabled)
  }, [disabled]);

  useEffect(() => {
    setInputLength(local?.length || 0);
  }, [local]);

  useEffect(() => {
    delete errors[name];
    setInputLength(value?.length || 0);
    setLocal(value);
  }, [value]);

  return (
    <div className={`${className}`}>
      {
        label &&
        <label htmlFor={id} className="flex items-center mb-2 font-medium text-sm">
          { label }
            <span className='text-error-main'>{required && '*'}</span>
          {
            textTooltip && <Tooltip textTooltip={textTooltip} defaultText={name}/>
          }
        </label>
      }
      <div className="relative">
        <input
          id={id}
          type={type}
          name={name}
          value={local}
          disabled={isDisabled}
          onChange={({target}) => {
            if (maxLength && target.value?.length > maxLength) {
              return;
            }

            setLocal(target.value);
            onChange(target.value || '');
          }}
          onBlur={() => {
            onBlur && onBlur(local);
          }}
          onSelect={(e) => {
            if (initialValueReplaceOnly
                && initialValue == e.currentTarget.value
                && (e.currentTarget.selectionStart !== 0 || e.currentTarget.selectionEnd !== e.currentTarget.value.length)
            ) {
              e.currentTarget.select();
            }
          }}
          onClick={onClick}
          onKeyDown={onKeyDown}
          readOnly={readOnly}
          className={`outline-0 disabled:text-gray-40 text-gray-50 py-[10px] px-[14px] w-full text-sm bg-white border-gray-20 border-[1px] rounded-lg ${icon && 'pr-[40px]'} ${classNameInput} ${error && 'border border-red-500 disabled:border disabled:border-red-500'} `}
          placeholder={placeholder}
          autoComplete={autoComplete}
          ref={input}
          {...props}
        />
        {getAuxElement && <div
            className={`absolute right-3 top-2.5 flex justify-end text-main cursor-pointer text-sm text-interactive-elem`}>{getAuxElement(auxState, setAuxState, setDisabled)}</div>
        }
        {showLength && maxLength && maxLength > 0 && <div
            className={`absolute right-3 top-2.5 flex justify-end text-main cursor-pointer text-sm text-gray-20`}>{inputLength}/{maxLength}</div>
        }
        {
          icon && (
            <img
              className={`absolute top-1/2 -translate-y-1/2 pointer-events-none right-[10px]`}
              src={icon}
              alt={'input-icon'}
            />
          )
        }
      </div>
      {error && errorLabel && (<span className={'text-error-font text-xs font-medium'}>{error}</span>)}
    </div>
  )
}
