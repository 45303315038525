import React, { HTMLProps } from 'react'

export default function ChevronIcon({ color = 'interactive', ...props }: HTMLProps<HTMLDivElement>) {
  const colors = {
    interactive: '#55B37A',
    gray: '#333333'
  }

  return (
    <div { ...props }>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 10L12 14L17 10" stroke={ colors[color] } strokeWidth="2"/>
      </svg>
    </div>
  )
}
