import {apiClient} from "../../libs/api/apiClient";

async function fetchCurrencies() {
  const { data }: any = await apiClient.dictionaries.currencies()

  return data.map((currency: any) => ({
    label: currency.title,
    value: currency.id
  }))
}

export default fetchCurrencies
