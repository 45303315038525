import React, {useEffect, useState} from "react";
import BaseButton from "../../components/common/Button/BaseButton";
import { Link } from "react-router-dom";
import { useCompaniesContext } from "../../contexts/Companies";
// @ts-ignore
import pencilIcon from '../../images/pencil.svg'
import DataTable from "../../components/common/Table/DataTable";
import {createColumnHelper} from '@tanstack/react-table'
import {apiClient} from "../../libs/api/apiClient";
import { auth } from "../../libs/auth";
import Actions from "../../components/common/Table/Actions";
import {showSuccessToast, showErrorToast} from "../../libs/helpers/toasts";
import Loader from "../../components/common/Loaders/Loader";

type CompanyData = {
  id: number,
  title: string,
  status: string,
}

export enum CompanyStatus {
  ACTIVE = 'active',
  BLOCKED = 'blocked',
  INACTIVE = 'inactive',
}

const Companies = () => {
  const {company, selectCompany, preparePaginationQueryParams} = useCompaniesContext()
  const [dataCompanies, setDataCompanies] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingCacheButton, setLoadingCacheButton] = useState(null)
  const [pagination, setPagination] = useState({})
  const [search, setSearch] = useState('')
  const [hasData, setHasData] = useState(false)
  const [filters, setFilters] = useState({
    limit: 10,
    page: 1,
    order: null,
    search: null
  })
  const companyStatus = {
    active: {
      title: 'Активна',
      color: 'bg-interactive-elem'
    },
    blocked: {
      title: 'Заблокирована',
      color: 'bg-error-font',
    },
    inactive: {
      title: 'Не активна',
      color: 'bg-gray-40'
    }
  }

  const clearCache = (id: any) => {
    setLoadingCacheButton(id)
    apiClient.companies.clearCache(id).then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: message || 'Произошла ошибка при сбросе кэша'
        })
      } else {
        showSuccessToast({
          content: 'Кэш успешно сброшен'
        })
      }
    }).catch((e: any) => {
      showErrorToast({
        content: e || 'Произошла ошибка при сбросе кэша'
      })
    }).finally(() => {
      setLoadingCacheButton(null)
    })
  }

  const columnHelper = createColumnHelper<CompanyData>()

  const columns = [
    columnHelper.display({
      id: 'editAction',
      cell: props => (
        <div className={'w-[4%] flex items-center justify-center text-center'}>
          <Link to={`/companies/${props.row?.original?.id}/edit`}>
            <img src={pencilIcon} alt=""/>
          </Link>
        </div>
      ),
      meta: {
        widthClass: 'w-[4%]'
      }
    }),
    columnHelper.accessor(row => row.id, {
      id: 'ID',
      header: () => 'ID',
      cell: info => <div className={'w-[10%] flex items-center'}>{ info.getValue() }</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[10%]'
      }
    }),
    columnHelper.accessor(row => row.title, {
      id: 'companyName',
      header: () => 'Компании',
      cell: info => <div className={'w-[30%] flex items-center'}>{ info.getValue() }</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[30%]',
        sortField: 'title'
      }
    }),
    columnHelper.accessor(row => row.id, {
      id: 'tariff',
      header: () => 'Тариф',
      cell: info => <div className={'w-[15%] flex items-center'}>Тариф</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[15%]',
      }
    }),
    columnHelper.accessor(row => row.status, {
      id: 'status',
      header: () => 'Активность',
      cell: info => (
        <div className={'w-[20%] flex flex-row items-center'}>
          <div className={`h-[10px] w-[10px] rounded-full mr-[5px] ${info.getValue() ? companyStatus[info.getValue()]?.color : 'bg-gray-40'}`}></div>
          <span>{companyStatus[info.getValue()]?.title ?? 'Не активна'}</span>
        </div>
      ),
      enableSorting: true,
      meta: {
        widthClass: 'w-[20%]'
      }
    }),
    columnHelper.display({
      id: 'actions',
      header: () => 'Действия',
      cell: props => (
        <div className={'w-[30%] flex items-center gap-4'}>
          <BaseButton onClick={() => {selectCompany(props.row?.original?.id)}} className={`text-interactive-text ${company?.id == props.row?.original?.id && 'bg-interactive-text text-white'} hover:bg-interactive-text hover:text-white border-btn-secondary rounded-md border-[1px] py-[7px]`}>{company?.id == props.row?.original?.id ? 'Выбрано' : 'Выбрать'}</BaseButton>
          <BaseButton onClick={() => clearCache(props.row?.original?.id)} className={'text-interactive-text hover:text-white hover:bg-interactive-text  border-btn-secondary rounded-md border-[1px] py-[7px]'}>
            {
              props.row?.original?.id == loadingCacheButton ? <Loader title={''} className={''} SVGClassName={'!text-white'} /> : 'Очистить кеш'}
          </BaseButton>
        </div>
      ),
      meta: {
        widthClass: 'w-[30%]'
      }
    }),
  ]

  const getCompanies = (params = {limit: 10}) => {
    setLoading(true)
    if (params) {
      updatePagination(params)
    }
    apiClient.companies.getAll(params ? preparePaginationQueryParams(filters) : '').then(({data, meta, links}: any) => {
      if (data.length) {
        setHasData(true)
      }
      setDataCompanies(data)
      if (meta) {
        setPagination(meta)
      }
      setLoading(false)
    })
  }

  const updatePagination = ({limit, order, page}: any) => {
    let tempData = filters
    if (limit) {
      tempData['limit'] = limit
    }
    if (page) {
      tempData['page'] = page
    }
    if (order) {
      tempData['order'] = order
    }

    setFilters(filters)
  }

  useEffect(() => {
    getCompanies();
    auth.update().then().catch()
  }, [])

  return (
    <div className="px-8 pb-8 pt-3">
      <div className="flex justify-between items-end">
        <div>
          <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Компании</div>
          <div className="text-gray-40 text-base">Список компаний</div>
        </div>

        {
          (!hasData && dataCompanies?.length === 0) &&
          <Link to={'/companies/create'}>
            <BaseButton className={'bg-interactive-elem text-white'}>Создать компанию</BaseButton>
          </Link>
        }
      </div>

      {
        (hasData || dataCompanies?.length > 0) ?
          <div>
            <Actions
              updateValue={(value: any) => {
                setSearch(value || '')
              }}
              fetchData={(value: any) => {
                let tempData = filters
                tempData['search'] = value || null
                setFilters(filters)
                getCompanies(filters)
              }}
              searchValue={search}
              classNameChildren={'w-1/2 flex items-center justify-end'}
            >
              <Link to={'/companies/create'}>
                <BaseButton className={'bg-interactive-elem text-white'}>Создать компанию</BaseButton>
              </Link>
            </Actions>
            <DataTable
              data={dataCompanies}
              columns={columns}
              usePagination={true}
              defaultLimit={10}
              defaultPage={1}
              pagination={pagination}
              updateData={(params: any) => getCompanies(params)}
              loading={loading}
              selectedRows={[]}
            />
          </div>
          :
        <div className="text-gray-40 text-3xl text-center mt-[35vh]">
          {loading ? 'Загрузка...' : 'Создайте свою первую компанию'}
        </div>
      }
    </div>
  );
}

export default Companies
