import React, {useEffect} from 'react'
import TextInput from '../../../components/common/form/input/TextInput'
import Checkbox from './../../../components/common/form/checkbox/Checkbox';
import Textarea from './../../../components/common/form/input/Textarea';
import CheckboxList from '../../../components/common/form/checkbox/CheckboxList';
import TextInputMultipleList from '../../../components/common/form/input/TextInputMultipleList';
import TextInputsList from '../../../components/common/form/input/TextInputsList';
import SingleSimpleSelect from '../../../components/common/form/select/SingleSimpleSelect';
import ScheduleTime from '../../../components/common/form/ScheduleTime';
import TextEditor from '../../../components/common/form/input/TextEditor';
import {useCompaniesContext} from "../../../contexts/Companies";

export default function Extended({ data, errors, updateField, currencies=[], phoneCodes=[], updateFieldNoRender}: any) {
  const {company} = useCompaniesContext()
  //const [platforms, setPlatforms] = useState([])

  useEffect(() => {
    updateField('extended.__currency_select', currencies.at(0))
  }, [currencies])

  useEffect(() => {
    updateField('extended.__phoneCode_select', phoneCodes.at(0))
  }, [phoneCodes])

  /*useEffect(() => {
    fetchPlatforms().then((data: any) => {
      setPlatforms(data)
    })
  }, [])*/

  return (
    <div className={'flex flex-col px-3 gap-y-6'}>
      <div className={`flex flex-row gap-x-3`}>
        <TextInput
          label='Регион (Для геосервисов)'
          textTooltip='Регион необходимо указывать без типа субъекта, например: Чувашия, Пермский, Кировская.'
          placeholder='Сыктывкар, Киров, Кирово-Чепецк'
          name={'extended.regions'}
          errors={errors}
          className={'grow'}
          onChange={(value: any) => updateFieldNoRender('extended.regions', value.split(','))}
          value={data.extended.regions.join(',')}
        />

        <TextInput
          label='Радиус'
          textTooltip='Указывается в метрах, максимальное значение 100км'
          placeholder=''
          type={'number'}
          min={0}
          max={100000}
          name={'extended.dadataSuggestionsRadiusLimit'}
          errors={errors}
          className={'grow'}
          onChange={(value: any) => updateFieldNoRender('extended.dadataSuggestionsRadiusLimit', value)}
          value={data.extended?.dadataSuggestionsRadiusLimit}
        />
      </div>

        <TextInputMultipleList
          name={'extended.orderCities'}
          values={data.extended.orderCities}
          fields={['code', 'city', 'title']}
          placeholders = {['ID', 'Название', 'Полное название']}
          labels = {['Код', 'Полное название', 'Город']}
          fieldTypes={['input', 'city', 'input']}
          textTooltips={['Код', 'Полное название', 'Город']}
          onChange={(value: any) => updateField('extended.orderCities', value)}
          label='Города (Для формы оформления заказов)'
          errors={errors}
        />

        <ScheduleTime
          textTooltip={'Режим работы'}
          name='extended.openHours'
          value={data.extended.openHours}
          onChange={(value: any) => updateField('extended.openHours', value)}
          errors={errors}
        />

        <Checkbox
          label={'Разрешить оформление заказов в нерабочее время'}
          name={'extended.allowOrderAfterHours'}
          onChange={(value: any) => updateField('extended.allowOrderAfterHours', value)}
          value={data.extended.allowOrderAfterHours}
          errors={errors}
        />

        <TextInputsList
          textTooltip='Адреса заведений'
          required={true}
          label='Адреса заведений'
          name='extended.addresses'
          values={data.extended.addresses}
          onChange={(value: any) => updateField('extended.addresses', value)}
          placeholder='Введите адрес заведения'
          errors={errors}
        />

        <TextInput
          textTooltip='Краткое описание'
          label='Краткое описание'
          className='w-full'
          placeholder='Введите краткое описание'
          onChange={(value: any) => updateFieldNoRender('extended.shortDescription', value)}
          value={data.extended.shortDescription}
          name={'extended.shortDescription'}
          errors={errors}
        />

        <TextEditor
          label="Подробное описание"
          textTooltip="Введите подробное описание"
          placeholder='Подробное описание'
          value={data.extended.description}
          onChange={(value: any) => updateFieldNoRender('extended.description', value)}
        />

        <TextEditor
          label='Оплата'
          textTooltip='Оплата'
          placeholder='Оплата'
          onChange={value => updateFieldNoRender('extended.paymentDescription', value)}
          value={data.extended.paymentDescription}
        />

        <TextEditor
          label='Доставка'
          textTooltip='Доставка'
          placeholder='Доставка'
          onChange={value => updateFieldNoRender('extended.deliveryDescription', value)}
          value={data.extended.deliveryDescription}
        />

        <Textarea
          textTooltip='Акции'
          label='Акции'
          placeholder='Акции'
          name={'extended.stockDescription'}
          errors={errors}
          onChange={value => updateFieldNoRender('extended.stockDescription', value)}
          value={data.extended.stockDescription}
          maxLength={undefined}
        />

        {/* <TextInput
          label='Программа лояльности'
          className='w-full'
          placeholder='Программа лояльности'
          onChange={value => updateFieldNoRender('extended.loyaltyProgramDescription', value)}
          value={data.extended.loyaltyProgramDescription}
          name={'extended.loyaltyProgramDescription'}
          errors={errors}
        /> */}

        <TextInput
          textTooltip='Текст на странице “Корзина”'
          label='Текст на странице “Корзина”'
          className='w-full'
          placeholder='Введите текст на странице “Корзина”'
          onChange={(value: any) => updateFieldNoRender('extended.cartText', value)}
          value={data.extended.cartText}
          name={'extended.cartText'}
          errors={errors}
        />

        <TextInput
          textTooltip='Текст на странице “Оформление заказа”'
          label='Текст на странице “Оформление заказа”'
          className='w-full'
          placeholder='Введите текст на странице “Оформление заказа”'
          onChange={(value: any) => updateFieldNoRender('extended.orderPageText', value)}
          value={data.extended.orderPageText}
          name={'extended.orderPageText'}
          errors={errors}
        />

        <TextInput
          textTooltip='Текст в подвале'
          label='Текст в подвале'
          className='w-full'
          placeholder='Введите текст в подвале'
          onChange={(value: any) => updateFieldNoRender('extended.footerText', value)}
          value={data.extended.footerText}
          name={'extended.footerText'}
          errors={errors}
        />

        <div className='flex gap-4'>
          <SingleSimpleSelect
            textTooltip='Валюта'
            className={'grow'}
            classNameInput=''
            label='Валюта'
            placeholder='Рубль'
            name={'base.currency'}
            errors={errors}
            options={currencies}
            onChange={(option: any) => {
              updateField('extended.__currency_select', option)
              updateField('extended.currency', option.value)
            }}
            value={data?.extended?.__currency_select}
          />

          <SingleSimpleSelect
            textTooltip='Код телефонов'
            className={'grow'}
            classNameInput=''
            label='Код телефонов'
            placeholder='+7'
            name={'base.phoneCode'}
            errors={errors}
            options={phoneCodes}
            onChange={(option: any) => {
              updateField('extended.__phoneCode_select', option)
              updateField('extended.phoneCode', option.value)
            }}
            value={data?.extended?.__phoneCode_select}
          />

        </div>

        {/*<TextInputsList
          inputType='number'
          textTooltip='Сообщество ВКонтакте'
          label='Сообщество ВКонтакте'
          className='w-full'
          placeholder='Введите ID сообщество ВКонтакте'
          onChange={(value: any) => updateFieldNoRender('extended.vkCommunities', value)}
          values={data.extended?.vkCommunities}
          name={'vkCommunities'}
          errors={errors}
          vk={true}
          vkId={company?.vkAppId}
        />*/}

        <TextInputMultipleList
          name={'extended.vkCommunities'}
          values={data.extended?.vkCommunities}
          fields={['groupId', 'key']}
          placeholders = {['ID', 'Ключ']}
          labels = {['ID', 'Ключ']}
          fieldTypes={['input', 'input']}
          textTooltips={['ID', 'Ключ']}
          onChange={(value: any) => updateField('extended.vkCommunities', value)}
          label='Сообщество ВКонтакте'
          errors={errors}
          vk={true}
          vkId={company?.vkAppId}
          className='w-full'
        />

        {/*<Checkbox
          label={'Разрешить привязку / изменение телефона к авторизированному пользователю'}
          name={'extended.allowAssignPhone'}
          onChange={(value: any) => updateField('extended.allowAssignPhone', value)}
          value={data.extended.allowAssignPhone}
          errors={errors}
        />*/}

        <Checkbox
            label={'Разрешить оформление заказа без подтверждения номера телефона'}
            name={'extended.allowGuestOrderInMobile'}
            onChange={(value: any) => updateField('extended.allowGuestOrderInMobile', value)}
            value={data.extended.allowGuestOrderInMobile}
            errors={errors}
          />

        {
          /*<SimpleMultipleValueSelect
            label={'Разрешить оформление заказов без авторизации'}
            textTooltip={'Разрешить оформление заказов без авторизации'}
            options={platforms.map((platform: any) => ({label: platform.label, value: platform.value}))}
            value={data.extended.allowGuestOrderInMobile || []}
            onChange={(value: any) => updateField('extended.allowGuestOrderInMobile', value)}
            name={'extended.allowGuestOrderInMobile'}
            placeholder={'Выберите филиалы'}
            errors={errors}
          />*/
        }

        <CheckboxList
          label='Варианты входа:'
          name='extended.allowedAuthorizeTypes'
          errors={errors}
          options={[
            {
              label: 'Телефон + СМС-код',
              name: 'sms',
            },
            {
              label: 'Телефон + звонок',
              name: 'call',
            },
            {
              label: 'ВКонтакте',
              name: 'vk',
            },
            {
              label: 'Telegram (бот)',
              name: 'tg',
            },
          ]}
          values={data.extended.allowedAuthorizeTypes}
          onChange={(value: any) => updateField('extended.allowedAuthorizeTypes', value)}
        />
 
        {
          data.extended.allowedAuthorizeTypes.includes('tg') &&
          <div className={`py-3 px-4 border-gray-20 border-[1px] rounded-lg`}>
            <TextInput
              textTooltip='Токен Telegram Bot'
              label='Токен Telegram Bot'
              placeholder='Токен Telegram Bot'
              name={'notifications.tgBotToken'}
              errors={errors}
              onChange={(value: any) => updateFieldNoRender('notifications.tgBotToken', value)}
              value={data.notifications.tgBotToken}
            />
          </div>
        }
    </div>
  )
}
