import React, {useEffect, useRef, useState} from "react";
import DropDown from "../select/DropDown";
import TextInput from "./TextInput";

interface PropsType {
  value?: any;
  onChange: (value: any) => void;
  name: string;
  classNameInput?: string;
  placeholder?: string;
  label?: string;
  errors?: object;
  errorLabel?: boolean;
  dropDownData?: any
}

export default function TextInputWithSearch(
  {
    value,
    onChange,
    name,
    classNameInput = '',
    placeholder='',
    label='',
    errors = {},
    errorLabel = true,
    dropDownData = [],
    ...props
  }: PropsType) {

  //const id = useId();
  const wrapper = useRef<any>(null);
  const [options, setOptions] = useState<any>(dropDownData);
  const [show, setShow] = useState(false);
  const [inputValue, setInputValue] = useState<any>('');
  //const [loading, setLoading] = useState(false);

  const error = errors[name];

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!inputValue || !inputValue.length) {
        setOptions(dropDownData)
        return;
      }

      const temp = dropDownData.filter(({label}: any) => label.toLowerCase().includes(inputValue.toLowerCase()))
      setOptions(temp)
    }, 500);

    return () => clearTimeout(timeout)
  }, [value, inputValue]);

  useEffect(() => {
    setOptions(dropDownData || [])
    const option = dropDownData.find((item: any) => item.value == value)
    setInputValue(option?.label || '')
  }, [dropDownData])

  useEffect(() => {
    const option = dropDownData.find((item: any) => item.value == value)
    setInputValue(option?.label || '')

    if (!option || !option?.value || 0 === option?.value.length) {
      setOptions(dropDownData);
    } else {
      const temp = dropDownData.filter(({label}: any) => label.toLowerCase().includes(option?.label.toLowerCase()))
      setOptions(temp)
    }

  }, [value]);


  /* Закрываем при клике вне поля */
  useEffect(() => {
    function handleClick(event: any) {
      if (wrapper.current && !wrapper.current.contains(event.target)) {
        setShow(false);
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }

  }, [show, setShow, wrapper]);

  function handleKeyDown(event: any) {
    if (8 === event.keyCode) {
    //  setOptions([]);
      if (inputValue.length <= 1) {
        onChange(null);
      }
    }
  }

  return (
    <div ref={wrapper} className={'relative w-full'}>
      <TextInput
        errors={{}}
        label={label}
        //id={id}
        classNameInput={classNameInput}
        autoComplete={'off'}
        onChange={(value: any) => setInputValue(value)}
        value={inputValue}
        name={name}
        placeholder={placeholder}
        onClick={() => setShow(show => !show || false)}
        onKeyDown={handleKeyDown}
        {...props}
      />
      <DropDown
        items={options}
        value={value}
        setShow={setShow}
        show={show}
        onChange={(option) => {
          setShow(false);

          onChange(option.value);
          delete errors[name];
          setInputValue(option.label)
        }}
      />
      {error && errorLabel && (<span className={'text-error-font text-xs font-medium'}>{error}</span>)}
    </div>
  )
}
