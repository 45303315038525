import React, { useState, useEffect } from 'react'
import Breadcrumbs from './../../../components/common/Breadcrumbs';
import { useCompaniesContext } from '../../../contexts/Companies';
import { useBranchesContext } from '../../../contexts/Branches';
import useForm from './../../../hooks/useForm';
import { useNavigate, useParams } from "react-router-dom";

import Base from './Base';
import Extended from './Extended';
import Notifications from './Notifications';
import Contacts from './Contacts';
import Order from './Order';
import Integrations from './Integrations';
import PaymentSystems from './PaymentSystems';
import Metrics from './Metrics';

import { apiClient } from '../../../libs/api/apiClient';
import { initUpdateForm } from '../initForm';
import Mailing from './Mailing';
import Sms from './Sms';
import MessageTemplates from './MessageTemplates';
import tabs from '../tabs';
import FormWrapper from '../../../components/common/form/FormWrapper';
import { checkBranchAction } from "../../../libs/helpers/permissions";
import { showErrorToast, showSuccessToast } from "../../../libs/helpers/toasts";
import { dialogModal } from "../../../components/common/Modal/DialogModal";
import { useFormContext } from '../../../contexts/Form';
import EditFormFields from "../../../components/common/form/EditFormFields";

export default function EditBranch() {
  const { company } = useCompaniesContext()
  const { updateBranches } = useBranchesContext()
  const { needUpdate, setNeedUpdate } = useFormContext()

  const [tab, setTab] = useState('base')
  const [branch, setBranches] = useState()
  const [loading, setLoading] = useState<boolean>(true)

  const { data, updateData, updateField, updateFieldNoRender, errors, updateErrors, submit, changedFieldsRefresh, isDirty } = useForm(JSON.parse(JSON.stringify(initUpdateForm)))

  const navigate = useNavigate();
  const { id: branchId } = useParams<{ id?: string }>()

  const reset = () => {
    navigate('/branches')
  }

  const denyRemoveBranch = !checkBranchAction('delete', '([0-9])+', branchId)

  const removeBranch = () => {
    dialogModal({
      isOpen: false,
      title: 'Внимание!',
      text: 'Вы уверены что хотите удалить филиал?',
      showCancelButton: true,
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Вернуться',
    }).then((result) => {
      if (result.isConfirmed) {
        apiClient.branches.remove(company?.id, branchId)
          .then(({ data, errors }: any) => {
            updateBranches()
            showSuccessToast({
              content: "Филиал успешно удален"
            })
            navigate('/branches')
          }
          ).catch((err) => {
            showErrorToast({
              content: 'Что-то пошло не так'
            })
          })
      }
    }).catch(() => { })
  }

  const handleSubmit = () => {
    submit((form: any) => {
      apiClient.branches.update(company?.id, branchId, { body: form })
        .then(({ data, errors, message, success }: any) => {
          if (errors || message) {
            updateErrors(errors || {})
            showErrorToast({
              content: 'Что-то пошло не так'
            })
          } else {
            changedFieldsRefresh()
            getBranchInfo(branchId)
            updateBranches()
            showSuccessToast({
              content: "Филиал успешно изменен",
            })
          }
        }
        ).catch(() => { }).finally(() => setNeedUpdate(false))
    }, 'changed')
  }

  const getBranchInfo = (id: any) => {
    if (company?.id) {
      setLoading(true)

      // prefetch dictionaries
      apiClient.dictionaries.timeZones().then();
      apiClient.dictionaries.phoneCodes().then();
      apiClient.dictionaries.currencies().then();
      apiClient.dictionaries.locationSources().then();

      apiClient.branches.get(company.id, id).then(({ data, errors }: any) => {
        if (data) {
          updateData(data)
          setBranches(data)
        } else {
          console.log(errors)
          updateErrors(errors || {})
        }
        setLoading(false)
      })

    }
  }

  useEffect(() => {
    getBranchInfo(branchId)
  }, [company?.id])

  useEffect(() => {
    if (needUpdate) {
      handleSubmit()
    }
  }, [needUpdate])

  const changeTab = (tag: any) => {
    if (Object.keys(isDirty()).length !== 0) {
      dialogModal({
        isOpen: false,
        title: 'Не сохраненные данные!',
        text: 'Вы действительно хотите перейти на следующий шаг? Все не сохраненные данные будут удалены',
        showDenyButton: true,
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: 'Сохранить и продолжить',
        denyButtonText: `Без сохранения`,
        cancelButtonText: 'Вернуться',
      }).then((result) => {
        if (result.isConfirmed) {
          handleSubmit()
          setTab(tag)
        } else if (result.isDenied) {
          changedFieldsRefresh()
          getBranchInfo(branchId)
          setTab(tag)
        }
      }).catch(() => { })
    } else {
      setTab(tag)
    }
  }

  useEffect(() => {
    if (!checkBranchAction('update', '([0-9])+', branchId)) {
      showErrorToast({
        content: "Нет доступа"
      })
      navigate('/branches')
    }
  }, [])

  if (loading) return (
    <div className="text-gray-40 text-3xl text-center mt-[35vh]">
      <span>Загрузка...</span>
    </div>
  )

  return (
    <div className='px-8 pb-8 pt-2 text-gray-50'>
      <div className='mb-8'>
        <div className='text-[44px] leading-[60px] mb-2'>Филиалы</div>
        <Breadcrumbs
          links={[
            { to: `/branches`, label: 'Список филиалов' },
            { to: '', label: 'Редактирование филиала' },
          ]}

          back={`/branches`}
        />
      </div>

      <FormWrapper
        tabs={tabs}
        tab={tab}
        setTab={changeTab}
      >
        <EditFormFields
          show={tab === 'base'}
          reset={reset}
          remove={removeBranch}
          back={'/branches'}
          handleSubmit={handleSubmit}
          denyRemove={denyRemoveBranch}
        >
          <Base
            data={data}
            updateField={updateField}
            updateFieldNoRender={updateFieldNoRender}
            errors={errors}
          />
        </EditFormFields>

        <EditFormFields
          show={tab === 'extended'}
          reset={reset}
          remove={removeBranch}
          back={'/branches'}
          handleSubmit={handleSubmit}
          denyRemove={denyRemoveBranch}
        >
          <Extended
            data={data}
            updateField={updateField}
            updateFieldNoRender={updateFieldNoRender}
            errors={errors}

          />
        </EditFormFields>

        <EditFormFields
          show={tab === 'notifications'}
          reset={reset}
          back={'/branches'}
          remove={removeBranch}
          handleSubmit={handleSubmit}
          denyRemove={denyRemoveBranch}
        >
          <Notifications
            data={data}
            updateField={updateField}
            updateFieldNoRender={updateFieldNoRender}
            errors={errors}
          />
        </EditFormFields>

        <EditFormFields
          show={tab === 'contacts'}
          reset={reset}
          back={'/branches'}
          remove={removeBranch}
          handleSubmit={handleSubmit}
          denyRemove={denyRemoveBranch}
        >
          <Contacts
            data={data}
            updateField={updateField}
            updateFieldNoRender={updateFieldNoRender}
            errors={errors}

          />
        </EditFormFields>

        <EditFormFields
          show={tab === 'order'}
          reset={reset}
          back={'/branches'}
          remove={removeBranch}
          handleSubmit={handleSubmit}
          denyRemove={denyRemoveBranch}
        >
          <Order
            data={data}
            updateField={updateField}
            errors={errors}
          />
        </EditFormFields>

        <EditFormFields
          show={tab === 'integrations'}
          reset={reset}
          back={'/branches'}
          remove={removeBranch}
          handleSubmit={handleSubmit}
          denyRemove={denyRemoveBranch}
        >
          <Integrations
            data={data}
            updateField={updateField}
            updateFieldNoRender={updateFieldNoRender}
            errors={errors}
          />

        </EditFormFields>

        <EditFormFields
          show={tab === 'paymentSystems'}
          reset={reset}
          back={'/branches'}
          remove={removeBranch}
          handleSubmit={handleSubmit}
          denyRemove={denyRemoveBranch}
        >
          <PaymentSystems
            data={data}
            updateField={updateField}
            updateFieldNoRender={updateFieldNoRender}
            errors={errors}
          />
        </EditFormFields>

        <EditFormFields
          show={tab === 'metrics'}
          reset={reset}
          back={'/branches'}
          remove={removeBranch}
          handleSubmit={handleSubmit}
          denyRemove={denyRemoveBranch}
        >
          <Metrics
            data={data}
            updateField={updateField}
            updateFieldNoRender={updateFieldNoRender}
            errors={errors}
          />
        </EditFormFields>

        <EditFormFields
          show={tab === 'mailing'}
          reset={reset}
          back={'/branches'}
          remove={removeBranch}
          handleSubmit={handleSubmit}
          denyRemove={denyRemoveBranch}
        >
          <Mailing
            data={data}
            updateField={updateField}
            updateFieldNoRender={updateFieldNoRender}
            errors={errors}
          />
        </EditFormFields>

        <EditFormFields
          show={tab === 'sms'}
          reset={reset}
          back={'/branches'}
          remove={removeBranch}
          handleSubmit={handleSubmit}
          denyRemove={denyRemoveBranch}
        >
          <Sms
            data={data}
            updateField={updateField}
            updateFieldNoRender={updateFieldNoRender}
            errors={errors}
          />
        </EditFormFields>

        <EditFormFields
          show={tab === 'messageTemplates'}
          reset={reset}
          back={'/branches'}
          remove={removeBranch}
          handleSubmit={handleSubmit}
          denyRemove={denyRemoveBranch}
        >
          <MessageTemplates
            data={data}
            updateField={updateField}
            updateFieldNoRender={updateFieldNoRender}
            errors={errors}
          />
        </EditFormFields>

      </FormWrapper>
    </div>
  )
}
