import React, {useEffect, useId, useRef, useState} from "react";
import Tooltip from "../../Tooltip";
import useValueThrottle from "../../../../hooks/useValueThrottle";

export default function Textarea({
  name,
  value,
  disabled = false,
  readOnly = false,
  onChange=(value: any)=>{},
  onClick=()=>{},
  onKeyDown=()=>{},
  autoComplete = 'off',
  className = '',
  placeholder = '',
  label = '',
  errors = {},
  required = false,
  textTooltip = '',
  showLength = false,
  maxLength = undefined
}) {
  const id = useId();
  const input = useRef(null);
  const [inputLength, setInputLength] = useState(value?.length || 0)
  const error = errors[name];
  const [value0, setValue0] = useValueThrottle<string | undefined>(value, onChange, 0 )

  useEffect(() => {
    delete errors[name];
    setInputLength(value?.length || 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className={`${className}`}>
    {
      label &&
      <label htmlFor={id} className="flex items-center mb-2 font-medium text-sm">
        { label }
          <span className='text-error-main'>{required && '*'}</span>
        {
          textTooltip && <Tooltip textTooltip={textTooltip} defaultText={name}/>
        }
      </label>
    }
    <div className={'relative'}>
      <textarea
        id={id}
        name={name}
        value={value0}
        disabled={disabled}
        onChange={({target}) => {
          if (maxLength && target.value?.length > maxLength)
            return

          setInputLength(target.value?.length || 0)
          setValue0(target.value || '')
        }}
        onClick={onClick}
        onKeyDown={onKeyDown}
        readOnly={readOnly}
        className={`min-h-[46px] h-[46px] outline-0 disabled:bg-gray-10 disabled:text-gray-40 disabled:pointer-events-none text-gray-50 py-[10px] px-[14px] w-full text-sm bg-white border-gray-20 border-[1px] rounded-lg ${error && 'border border-error-font' || 'border-0'}`}
        placeholder={placeholder}
        autoComplete={autoComplete}
        ref={input}
      ></textarea>
      {showLength && maxLength && maxLength > 0 && <div
          className={`absolute right-3 top-2.5 flex justify-end text-main cursor-pointer text-sm text-gray-20`}>{inputLength}/{maxLength}</div>
      }
    </div>
    {error && (<span className={'text-error-font text-xs font-medium'}>{error}</span>)}
  </div>
  )
}
