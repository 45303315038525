import React, {useEffect, useState} from "react";
import {Node} from "reactflow";
import TimerNodeBase from "./TimerNodeBase";
import {NodeTypeEnum} from "../index";
import useForm from "../../../../hooks/useForm";
import ConditionNodeBase from "./ConditionNodeBase";
import TextInput from "../../../../components/common/form/input/TextInput";
import BaseButton from "../../../../components/common/Button/BaseButton";
// @ts-ignore
import clipImg from "../../../../images/clip.svg";
import {useFunnelDataContext} from "../../../../contexts/Funnels";
import ActionNodeBase from "./ActionNodeBase";
// @ts-ignore
import trashImg from "../../../../images/trash.svg";
// @ts-ignore
import copyImg from "../../../../images/copy.svg";
import {useFunnelNodeContext} from "../../../../contexts/FunnelNodes";
import Loader from "../../../../components/common/Loaders/Loader";
import {getFormData} from "../../../../libs/helpers/formData";
import {apiClient} from "../../../../libs/api/apiClient";
import {useCompaniesContext} from "../../../../contexts/Companies";
import {useBranchesContext} from "../../../../contexts/Branches";
import {showErrorToast} from "../../../../libs/helpers/toasts";

interface IProps {
  node: Node | null,
  reset: () => void
  onSubmit: (data: any) => void
}

export default function CommonForm(
  {
    node,
    reset,
    onSubmit
  }: IProps) {
  const {company} = useCompaniesContext()
  const {branch} = useBranchesContext()
  const {copyNode, removeNode} = useFunnelNodeContext()
  const {funnelId, inputs, timers, actions, conditions, filters} = useFunnelDataContext()
  const {data, updateField, updateData, updateErrors, isDirty, changedFieldsRefresh, errors} = useForm(JSON.parse(JSON.stringify(node?.data)) || {})
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (isDirty()) {
      changedFieldsRefresh()
    }
    updateData(JSON.parse(JSON.stringify(node?.data)) || {})
  }, [node?.id])

  const validateForm = () => {
    if (company && branch) {
      setLoading(true)
      let form = {
        id: node?.id,
        type: node?.type,
        data: null
      }

      if (node?.type == NodeTypeEnum.condition) {
        form.data = data?.conditions
      }

      if (node?.type == NodeTypeEnum.action) {
        form.data = data?.actions
      }

      if (node?.type == NodeTypeEnum.timer) {
        form.data = data
      }

      const formdata = getFormData('create', form, new FormData())

      apiClient.funnels.validate(company.id, branch.id, funnelId, {body: formdata}).then(({errors, message}: any) => {
        if (errors || message) {
          showErrorToast({
            content: message || errors[0] || 'Ошибка. Проверьте заполненность всех полей, а также правильность введенных данных.'
          })
          updateErrors(errors)
        } else {
          //console.log(data)
          onSubmit(data)
          changedFieldsRefresh()
          updateData({})
        }
      }).catch((reason: any) => {
        console.log(reason)
      }).finally(() => setLoading(false))
    }
  }

  const cancelForm = () => {
    changedFieldsRefresh()
    updateData({})
    reset()
  }

  return (
    <div className={'flex flex-col gap-y-2 max-h-full min-h-full'}>
      <TextInput
        classNameInput={'!border-0 !border-b-[1px] !rounded-[0px] text-xl !p-0'}
        errors={errors}
        name={''}
        value={data?.title || ''}
        onChange={(value: any) => updateField('title', value)}
      />
      <div className={'flex flex-row gap-x-2 items-center'}>
        <BaseButton className={'border-[1px] py-[7px] px-2 rounded-xl flex flex-row gap-x-2 border-funnels-green bg-white text-funnels-green hover:text-white hover:bg-funnels-green'}>
          <img src={clipImg} alt=""/>
          Ссылка на шаг
        </BaseButton>
        <div className={'text-gray-40 text-sm'}>• ID: {node?.id}</div>
      </div>
      {
        node?.type == NodeTypeEnum.timer && <TimerNodeBase
              data={data}
              timers={timers}
              updateField={updateField}
              errors={errors}
          />
      }
      {
        node?.type == NodeTypeEnum.condition && <ConditionNodeBase
              conditions={{
                terms: conditions?.terms,
                definitions: conditions?.definitions,
                inputs: inputs,
                filters: filters
              }}
              data={data}
              updateField={updateField}
              errors={errors}
              />
      }

      {
        node?.type == NodeTypeEnum.action &&
          <ActionNodeBase
            conditions={{
              terms: conditions?.terms,
              definitions: conditions?.definitions,
              inputs: inputs,
              filters: filters
            }}
            actions={actions}
            data={data}
            updateField={updateField}
            errors={errors}
          />
      }
      <div className={'flex flex-col p-6 w-full max-w-full bg-white border-t-[1px] border-gray-20 bottom-0 sticky '}>
        <div className={'flex flex-row items-center justify-center gap-x-4 pb-4'}>
          <div
            className={'rounded-xl cursor-pointer p-3 border-[1px] bg-white border-funnels-red hover:bg-gray-10'}
            onClick={(event) => {
              event.stopPropagation()
              event.preventDefault()
              removeNode(node?.id)
              cancelForm()
            }}>
            <img src={trashImg} alt=""/>
          </div>
          <div
            className={'rounded-xl cursor-pointer p-3 border-[1px] bg-white border-funnels-green hover:bg-gray-10'}
            onClick={(event) => {
              event.stopPropagation()
              event.preventDefault()
              copyNode(node?.id)
              cancelForm()
            }}>
            <img src={copyImg} alt=""/>
          </div>
        </div>
        <div className={'flex flex-row items-center justify-center gap-x-4'}>
          <BaseButton
            onClick={() => {
              cancelForm()
            }}
            className={'border-[1px] w-1/2 py-[14px] px-2 rounded-xl flex flex-row gap-x-2 border-funnels-red bg-funnels-red text-white hover:bg-funnels-red/[.7]'}>
            Отменить
          </BaseButton>
          <BaseButton onClick={() => {
            validateForm()
          }} className={'border-[1px] w-1/2 py-[14px] px-2 rounded-xl flex flex-row gap-x-2 border-funnels-green bg-funnels-green text-white hover:bg-funnels-green/[.7]'}>
            {loading ? <Loader title={''} className={''} SVGClassName={'!text-white'} /> : 'Сохранить'}
          </BaseButton>
        </div>
      </div>
    </div>
  )
}
