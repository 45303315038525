import React, {useEffect, useState} from 'react'
import TextInput from '../../../components/common/form/input/TextInput'
import TextInputsList from '../../../components/common/form/input/TextInputsList'
import Textarea from '../../../components/common/form/input/Textarea'
import ImageLoad from '../../../components/common/form/image/ImageLoad'
import SingleSimpleSelect from "../../../components/common/form/select/SingleSimpleSelect";
import Tooltip from "../../../components/common/Tooltip";
import Checkbox from "../../../components/common/form/checkbox/Checkbox";
import DropDownComment from '../../../components/common/Comment/DropDown'

export default function MainSettings({data, updateField, errors, domainsPrograms, currentActiveDomainProgram}) {
  const [domainProgramsSelect, setDomainProgramsSelect] = useState(domainsPrograms.at(currentActiveDomainProgram))

  useEffect(() => {
    setDomainProgramsSelect(domainsPrograms.at(currentActiveDomainProgram))
  }, [currentActiveDomainProgram])


  return (
    <>
      <TextInput
        label='Название компании'
        placeholder='Введите название'
        name={'title'}
        errors={errors}
        onChange={(value) => {
          updateField('title', value)
        }}
        value={data.title}
        required={true}
        textTooltip={'Нет информации'}
      />

      <SingleSimpleSelect
        label={'Выбор домена'}
        name={'domainsPrograms'}
        options={domainsPrograms}
        onChange={(option) => {
          setDomainProgramsSelect(option)
        }}
        value={domainProgramsSelect}
        errors={errors}
        required={true}
        textTooltip={'Нет информации'}
      />

      {
        domainProgramsSelect.value === 'externalDomain' ? (
          <div className='flex flex-col gap-y-2 w-full'>
            <TextInput
              label='Домен'
              placeholder='Введите домен'
              name={'externalDomain'}
              errors={errors}
              onChange={(value) => {
                updateField('externalDomain', value)
              }}
              value={data.externalDomain}
            />
            <DropDownComment title={'test'}>
              <div className={"flex flex-col font-medium mt-3 gap-y-2 max-w-[90%]"}>
                <div className='mb-6'>
                  Для привязки собственного домена необходимо указать адреса в личном кабинете сайта, где был куплен
                  домен.
                </div>
                <ul>
                  <li>
                    {`Укажите «А» запись = ${process.env.REACT_APP_EXTERNAL_IP}`}{process.env.REACT_APP_EXTERNAL_IPv6 ? ` и «АAAA» запись = ${process.env.REACT_APP_EXTERNAL_IPv6}` : ''}
                  </li>
                  {/*<li>
                    {'Либо пропишите DNS адреса:'}
                    <ul>
                      <li>{`ns1.${process.env.REACT_APP_EXTERNAL_IP || 'адрес'}`}</li>
                      <li>{`ns2.${process.env.REACT_APP_EXTERNAL_IP || 'адрес'}`}</li>
                      <li>{`ns3.${process.env.REACT_APP_EXTERNAL_IP || 'адрес'}`}</li>
                      <li>{`ns4.${process.env.REACT_APP_EXTERNAL_IP || 'адрес'}`}</li>
                    </ul>
                  </li>*/}
                </ul>
              </div>
            </DropDownComment>
          </div>
        ) : (
          <div className={'flex flex-row'}>
            <TextInput
              className={'w-5/12 pr-4'}
              label='Поддомен'
              placeholder='Введите поддомен'
              name={'internalDomain'}
              errors={errors}
              onChange={(value) => {
                updateField('internalDomain', value)
              }}
              value={data.internalDomain}
              textTooltip={'Нет информации'}
            />
            <div className={'gap-y-4 pl-4 w-7/12'}>
              <label htmlFor="tech_domain"
                     className={'block mb-4 font-medium text-sm'}>
                Выбранный домен
              </label>
              <span id={'tech_domain'}>
                  {`.${process.env.REACT_APP_TECHNICAL_DOMAIN ? process.env.REACT_APP_TECHNICAL_DOMAIN : 'dev.resultrest.site'}`}
              </span>
            </div>
          </div>
        )
      }

      <TextInput
        name={'ymapsKey'}
        label='Ключ API MapKit SDK Full'
        textTooltip='Ключ API MapKit SDK Full'
        placeholder='300'
        value={data.ymapsKey}
        onChange={(value: any) => updateField('ymapsKey', value)}
        errors={errors}
      />

      <TextInput
        name={'appMetricaKey'}
        label='Ключ API AppMetrica'
        textTooltip='Ключ API AppMetrica'
        placeholder='05dd3dd84ff948fdae2bc4fb91f13e22bb1f289ceef0037'
        value={data.appMetricaKey}
        onChange={(value: any) => updateField('appMetricaKey', value)}
        errors={errors}
      />

      <Textarea
        label='Конфигурация Firebase для мобильного приложения (Android)'
        placeholder='{...}'
        name={'firebaseAndroidConfig'}
        errors={errors}
        onChange={(value) => {
          updateField('firebaseAndroidConfig', value)
        }}
        value={data.firebaseAndroidConfig || ''}
        textTooltip={'Полученный google-services.json'}
      />

      <Textarea
        label='Конфигурация Firebase для мобильного приложения (iOS)'
        placeholder='{...}'
        name={'firebaseIosConfig'}
        errors={errors}
        onChange={(value) => {
          updateField('firebaseIosConfig', value)
        }}
        value={data.firebaseIosConfig || ''}
        textTooltip={'Полученный google-services.json'}
      />

      <TextInputsList
        label='E-mail адреса администратора'
        placeholder='Введите e-mail адрес'
        name={'emails'}
        errors={errors}
        onChange={(value) => {
          updateField('emails', value)
        }}
        values={data.emails}
        required={true}
        textTooltip={'Нет информации'}
      />


      {/*В будущем нужно будет раскоментить, когда захотят избавиться от "основного/одного" приложения*/}
      {/*<div className={"py-3 px-4 border-gray-20 border-[1px] rounded-lg"}>*/}
      {/*  <label className="flex items-center mb-2 font-medium">ВК mini app*/}
      {/*    <Tooltip textTooltip={"ВК MINI APP"} defaultText={"vk"}/>*/}
      {/*  </label>*/}
      {/*<div className={"flex flex-col gap-y-3"}>*/}
      {/*  <TextInput*/}
      {/*    label='ID приложения'*/}
      {/*    placeholder='Введите ID приложения'*/}
      {/*    name={'vkAppId'}*/}
      {/*    errors={errors}*/}
      {/*    onChange={(value) => {*/}
      {/*      updateField('vkAppId', value)*/}
      {/*    }}*/}
      {/*    value={data.vkAppId}*/}
      {/*  />*/}
      {/*  <TextInput*/}
      {/*    label='Защищенный ключ'*/}
      {/*    placeholder='Введите защищенный ключ'*/}
      {/*    name={'vkSecretKey'}*/}
      {/*    errors={errors}*/}
      {/*    onChange={(value) => {*/}
      {/*      updateField('vkSecretKey', value)*/}
      {/*    }}*/}
      {/*    value={data.vkSecretKey}*/}
      {/*  />*/}
      {/*  <TextInput*/}
      {/*    label='Сервисный ключ доступа'*/}
      {/*    placeholder='Введите сервисный ключ доступа'*/}
      {/*    name={'vkServiceKey'}*/}
      {/*    errors={errors}*/}
      {/*    onChange={(value) => {*/}
      {/*      updateField('vkServiceKey', value)*/}
      {/*    }}*/}
      {/*    value={data.vkServiceKey}*/}
      {/*  />*/}
      {/*</div>*/}
      {/*</div>*/}

      <Textarea
        label='Краткое описание'
        placeholder='Введите краткое описание'
        name={'shortDescription'}
        errors={errors}
        onChange={(value) => {
          updateField('shortDescription', value)
        }}
        value={data.shortDescription || ''}
        textTooltip={'Нет информации'}
      />

      <Textarea
        label='Подробное описание'
        placeholder='Введите подробное описание'
        name={'description'}
        errors={errors}
        onChange={(value) => {
          updateField('description', value)
        }}
        value={data?.description || ''}
        textTooltip={'Нет информации'}
      />

      <ImageLoad
        label='Логотип (формат: JPG, JPEG, PNG)'
        onInput={value => {
          if (typeof value !== "undefined") {
            if (value !== null) {
              updateField('logo', value)
            }
          }
        }}
        onRemove={() => {
          console.log('remove')
          updateField('logoDeleted', true)
        }}
        name={'logo'}
        errors={errors}
        defaultValue={data.logo}
        required={false}
        resizeTo={[64, 64]}
      />

      <Checkbox
        className='w-full'
        label={'Обязательно сверять адреса с зонами доставки'}
        name={`isCustomerAddressMustBeInDeliveryZone`}
        value={data.isCustomerAddressMustBeInDeliveryZone}
        onChange={(value: any) => updateField('isCustomerAddressMustBeInDeliveryZone', value)}
      />

      <Checkbox
        className='w-full'
        label={'Округлять скидки в сторону клиента'}
        name={`isRoundDiscountsForCustomer`}
        value={data.isRoundDiscountsForCustomer}
        onChange={(value: any) => updateField('isRoundDiscountsForCustomer', value)}
      />

      <TextInput
        label='Ссылка на ios приложение'
        textTooltip='Ссылка на ios приложение'
        placeholder='Введите ссылку'
        name={'iosAppLink'}
        errors={errors}
        onChange={(value: any) => updateField('iosAppLink', value)}
        value={data.iosAppLink}
        required={false}
      />

      <TextInput
        label='Ссылка на android приложение'
        textTooltip='Ссылка на android приложение'
        placeholder='Введите ссылку'
        name={'androidAppLink'}
        errors={errors}
        onChange={(value: any) => updateField('androidAppLink', value)}
        value={data.androidAppLink}
        required={false}
      />

      <TextInput
        label='Ссылка на приложение в рустор'
        textTooltip='Ссылка на приложение в рустор'
        placeholder='Введите ссылку'
        name={'rustoreAppLink'}
        errors={errors}
        onChange={(value: any) => updateField('rustoreAppLink', value)}
        value={data.rustoreAppLink}
        required={false}
      />

      <TextInput
        label='Ссылка для отображения в qr коде в футере'
        textTooltip='Ссылка для отображения в qr коде в футере'
        placeholder='Введите ссылку'
        name={'footerQrCodeLink'}
        errors={errors}
        onChange={(value: any) => updateField('footerQrCodeLink', value)}
        value={data.footerQrCodeLink}
        required={false}
      />

      <TextInput
        label='ID организации в яндекс для отзывов'
        textTooltip='ID организации в яндекс для отзывов'
        placeholder='Введите ID'
        name={'organizationYandexId'}
        errors={errors}
        onChange={(value: any) => updateField('organizationYandexId', value)}
        value={data.organizationYandexId}
        required={false}
      />

      <ImageLoad
        label='Скриншот для отображения в футере (формат: JPG, JPEG, PNG)'
        onInput={value => {
          if (typeof value !== "undefined") {
            if (value !== null) {
              updateField('footerScreenshot', value)
            }
          }
        }}
        onRemove={() => {
          console.log('remove')
          updateField('footerScreenshotDeleted', true)
        }}
        name={'footerScreenshot'}
        errors={errors}
        defaultValue={data.footerScreenshot}
        required={false}
        resizeTo={[300, 650]}
      />
    </>
  )
}
