import React, {useEffect, useId, useRef, useState} from "react";
import MapBase from "./MapBase";
import BaseButton from "../Button/BaseButton";

export default function MapZoneSelect(
  {
    value,
    onChange,
    name,
    errors,
    label,
    required = false,
    className = 'w-full h-[400px]',
    fillColor = '#66FFB3',
    strokeColor = '#0000FF',
    strokeColorEditor = '#FF0000',
    ...props
  }) {
  let [mode, setMode] = useState('finish')

  const id = useId();

  const error = errors[name];

  useEffect(() => {
    delete errors[name];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const polyRef = useRef<any>()
  const eventsRef = useRef<any>({})

  eventsRef.current.onChange = onChange;
  eventsRef.current.setIsDrawing = (isDrawing: any) => {
    if (isDrawing && mode == 'editDraw') {
      setMode('draw')
    } else if (!isDrawing && mode == 'draw') {
      setMode('editDraw');
    }
  }
  eventsRef.current.restartDrawing = () => {
    if (mode == 'draw' && polyRef.current)
      polyRef.current.editor.startDrawing()
  }

  let onMapInit = (myMap: any) => {
    let myPolygon = polyRef.current = new ymaps.Polygon([value || []], {}, {
      editorDrawingCursor: "crosshair",
      // editorMaxPoints: 15,
      fillColor: fillColor,
      strokeColor: strokeColor,
      strokeWidth: 3,
      opacity: 0.5
    })

    myMap.geoObjects.add(myPolygon);
    myPolygon.events.add("geometrychange", () => {
      let coordinates = myPolygon.geometry.getCoordinates()[0]
      if (!myPolygon._updatingGeometry) {
        eventsRef.current.onChange(coordinates)
      }
      if (coordinates.length == 0) {
        eventsRef.current.restartDrawing()
      }
    })

    let stateMonitor = new ymaps.Monitor(myPolygon.editor.state)
    stateMonitor.add("drawing", function (newValue: any) {
      myPolygon.options.set("strokeColor", newValue ? strokeColorEditor : strokeColor)
      eventsRef.current.setIsDrawing(newValue);
    })

    if (value && value.length > 0) {
      const [p0, p1] = myPolygon.geometry.getBounds();
      myMap.setBounds(myPolygon.geometry.getBounds())
      //myMap.setCenter([(p0[0] + p1[0]) / 2, (p0[1] + p1[1]) / 2])
    }

    myPolygon.editor.stopDrawing()
  }

  if (polyRef.current) {
    if (value != null) {
      polyRef.current._updatingGeometry = true;
      polyRef.current.geometry.setCoordinates([value])
      polyRef.current._updatingGeometry = false;
    }
    polyRef.current.options.set("fillColor", fillColor)
  }

  let startDraw = () => {
    polyRef.current && polyRef.current.editor.startDrawing()
    setMode('draw')
  }
  let startEditDraw = () => {
    polyRef.current && polyRef.current.editor.stopDrawing()
    setMode('editDraw')
  }
  let finishEdit = () => {
    polyRef.current && polyRef.current.editor.stopEditing()
    setMode('finish')
  }
  let saveEdit = () => {
    polyRef.current && polyRef.current.editor.stopEditing()
  }

  return (
    <div>
      {
        label &&
          <label htmlFor={id} className="flex items-center mb-2 font-medium text-sm">
            {label}
              <span className='text-error-main'>{required && '*'}</span>
          </label>
      }
      <div className={'bg-gray-50 rounded-t-xl'}>
        <div className={'h-[72px] mx-6 flex justify-between items-center'}>
          {
            mode == 'finish' ? <BaseButton onClick={startDraw}
                                           className={'h-[32px] font-medium bg-interactive-elem text-white'}>Редактировать</BaseButton>
              : <div className={'flex'}>
                <BaseButton onClick={startDraw}
                            className={`${mode == 'draw' ? 'bg-interactive-bg' : ''} h-[32px] mr-1 text-interactive-text border-[2px] border-btn-secondary hover:bg-interactive-bg`}
                            disabled={mode == 'finish'}>Режим
                  рисования</BaseButton>
                <BaseButton onClick={startEditDraw}
                            className={`${mode == 'editDraw' ? 'bg-interactive-bg' : ''} h-[32px] ml-1 text-interactive-text border-[2px] border-btn-secondary hover:bg-interactive-bg`}>Режим
                  правки</BaseButton>
              </div>
          }
          <BaseButton onClick={finishEdit}
                      className={'h-[32px] font-medium bg-interactive-elem text-white'}>Завершить</BaseButton>
        </div>
      </div>

      <MapBase onMapInit={onMapInit} className={className} {...props}></MapBase>
      {
        mode == 'finish' ? ''
          : <div className={'bg-gray-50 rounded-b-xl'}>
            <div className={'h-[72px] mx-6 flex justify-between items-center'}>
              <p className={'text-white'}>Не забудьте сохранить изменения</p>
              <BaseButton onClick={saveEdit}
                          className={'h-[32px] font-medium bg-interactive-elem text-white'}>Сохранить</BaseButton>
            </div>
          </div>
      }
      {error && (<span className={'text-error-font text-xs font-medium'}>{error}</span>)}
    </div>
  )
}
