import React, { useState, useEffect } from 'react'
import Breadcrumbs from './../../../components/common/Breadcrumbs';
import FormWrapper from '../../../components/common/form/FormWrapper';
import useForm from './../../../hooks/useForm';
import { useCompaniesContext } from '../../../contexts/Companies';
import { useBranchesContext } from '../../../contexts/Branches';
import tabs from '../tabs'
import initForm from '../initForm';
import { useNavigate } from "react-router-dom";
import { apiClient } from '../../../libs/api/apiClient';

import Base from './Base';
import Show from './Show';
import MetaTags from './MetaTags';
import {CategoriesContextWrapper} from "../../../contexts/Categories";
import {showErrorToast} from "../../../libs/helpers/toasts";
import CreateFormFields from "../../../components/common/form/CreateFormFields";


export default function CreateCategory() {
  const { company } = useCompaniesContext()
  const { branch } = useBranchesContext()

  const [tab, setTab] = useState('base')
  const [loading, setLoading] = useState(false)
  const {data, updateField, updateFieldNoRender, errors, updateErrors, submit} = useForm(JSON.parse(JSON.stringify(initForm)))

  const navigate = useNavigate();

  const handleSubmit = () => {
    submit(async (form: any) => {
      setLoading(true)
      apiClient.categories.create(company.id, branch.id, {body: form}).then(({data, errors}: any) => {
        if (data) {
          // updateBranches()
          navigate("/categories")
        } else {
          updateErrors(errors || {});
          checkErrors(errors, true)

          showErrorToast({
            content: 'Что-то пошло не так'
          })
        }
      }).catch(() => {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      }).finally(() => setLoading(false))
    })
  }

  // Выглядит как срань, ею и является
  // Если придумаешь вариант получше отыскать на каком табе ошибка, то флаг в руки
  const [tabsHasError, setTabsHasError] = useState([])

  useEffect(() => {
    const interval = setInterval(() => {
      if ( Object.keys(errors).length > 0) {
        checkErrors(errors)
      } else if (tabsHasError.length > 0) {
        setTabsHasError([])
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [JSON.stringify(errors)]);

  const checkErrors = (err=errors, tab = false) => {
    let tabsError: any = []
    let errorsName = Object.keys(err)

    tabs.map((elem: any) => {
      if (elem?.options) {
        elem?.options.forEach((elemInOptions: any) => {
          if (elemInOptions?.fields) {
            let hasError = false
            for (let i = 0; i < elemInOptions.fields.length; i++) {
              let fieldName = elemInOptions.fields[i];
              for (let j = 0; j < errorsName.length; j++) {
                let error = errorsName[j]

                if (error.includes(fieldName)) {
                  hasError = true
                  break
                }
              }

              if (hasError) {
                break
              }
            }

            if (hasError) {
              if (tab && !tabsError.length) {
                setTab(elemInOptions.tag)
              }
              tabsError.push(elemInOptions.tag)
            }
          }
        })
      }
      // Для таба первого уровня
      if (elem?.fields) {
        let hasError = false
        for (let i = 0; i < elem.fields.length; i++) {
          let fieldName = elem.fields[i];
          for (let j = 0; j < errorsName.length; j++) {
            let error = errorsName[j]

            if (error.includes(fieldName)) {
              hasError = true
              break
            }
          }

          if (hasError) {
            break
          }
        }

        if (hasError) {
          if (tab && !tabsError.length) {
            setTab(elem.tag)
          }
          tabsError.push(elem.tag)
        }
      }
    })

    setTabsHasError(tabsError)
  }

  return (
    <div className='px-8 pb-8 pt-2 text-gray-50'>
      <div className='mb-8'>
        <div className='text-[44px] leading-[60px] mb-2'>Каталог</div>
        <Breadcrumbs
          links={[
            {to: `/categories`, label: 'Список разделов'},
            {to: '', label: 'Создание раздела'},
          ]}

          back={`/categories`}
        />
      </div>

      <FormWrapper
        tabs={tabs}
        tab={tab}
        setTab={setTab}
        tabsHasError={tabsHasError}
      >
        <CreateFormFields
          show={tab === 'base'}
          nextOption='show'
          setTab={setTab}
        >
          <CategoriesContextWrapper>
            <Base
              data={data}
              errors={errors}
              updateFieldNoRender={updateFieldNoRender}
              updateField={updateField}
            />
          </CategoriesContextWrapper>
        </CreateFormFields>

        <CreateFormFields
          show={tab === 'show'}
          nextOption='metaTags'
          setTab={setTab}
        >
          <Show
            data={data}
            errors={errors}
            updateField={updateField}
          />
        </CreateFormFields>

        <CreateFormFields
          show={tab === 'metaTags'}
          setTab={setTab}
          onSubmit={handleSubmit}
          submitLoading={loading}
          submitLabel='Создать раздел'
        >
          <MetaTags
            data={data}
            errors={errors}
            updateFieldNoRender={updateFieldNoRender}
            updateField={updateField}
          />
        </CreateFormFields>

      </FormWrapper>
    </div>
  )
}
