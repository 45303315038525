import {apiClient} from "../../libs/api/apiClient";

export async function fetchStatuses() {
  const { data }: any = await apiClient.dictionaries.promoCodeStatuses()

  return data.map((promo: any) => ({
    label: promo.title,
    value: promo.value
  }))
}

export async function fetchTypes() {
  const { data }: any = await apiClient.dictionaries.promoCodeTypes()

  return data.map((promo: any) => ({
    label: promo.title,
    value: promo.value
  }))
}

export async function fetchDeliveryTypes() {
  const { data }: any = await apiClient.dictionaries.promoCodeDeliveryTypes()

  return data.map((promo: any) => ({
    label: promo.title,
    value: promo.value
  }))
}
