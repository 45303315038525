import * as React from "react";
import { HTMLAttributes, useEffect, useState } from "react";
import InputWrapper from "../common/InputWrapper";
import ChevronIcon from "../../../../icons/ChevronIcon";
import DropDown from "../common/DropDown";
import DropDownOption from "../common/dropdown/DropDownOption";
import { Modify } from "../../../../../types/utils";


export type Option<T> = {
  label: string,
  value: T
}

type Props<T> = Modify<HTMLAttributes<HTMLDivElement>, {
  value: T,
  label?: string,
  error?: string,
  options: Option<T>[],
  onChange: (option: Option<T>) => void
}>

export default function SingleSelect<T>(props: Props<T>) {
  const { error, label, options, value, onChange } = props;
  const [selected, setSelected] = useState<Option<T>>(options.find((option) => option.value === value) || {} as Option<T>);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    let selected = options.find((option) => option.value === value);

    if (! selected) {
      selected = options.find((option) => ! option.value);
    }

    if (! selected) {
      selected = { label: "Не выбрано", value: '' as T }
    }

    setSelected(selected);
  }, [JSON.stringify(options), value]);

  const handleSelectItem = (item: Option<T>) => {
      setIsOpen(false);
      setSelected(item);
      onChange(item);
  }

  return (
    <div className={ `relative w-full` }>
      <InputWrapper error={ error } onClick={() => setIsOpen((state) => ! state)}>
        { label && (
          <InputWrapper.Label>
            { label }
          </InputWrapper.Label>
        ) }
        <InputWrapper.After>
          <ChevronIcon className={ 'pointer-events-none' }/>
        </InputWrapper.After>
        <span className={'w-full flex items-center'}>{ selected.label }</span>
      </InputWrapper>
      <DropDown<Option<T>> items={ options } isOpen={ isOpen } setIsOpen={setIsOpen}>
        {/* @ts-ignore */}
        <DropDown.Item<Option<T>>>
          { ({ item }) => (
            <DropDownOption onClick={() => handleSelectItem(item)} isSelected={selected.value === item.value}>
              { item.label }
            </DropDownOption>
          ) }
        </DropDown.Item>
      </DropDown>
    </div>
  );
}