import {useEffect} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";

export default function VerifyEmail() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const url = searchParams.get('url');

        if (url) {
            fetch(url)
                .then(response => {
                    200 === response.status && navigate('/', {replace: true});
                })
                .catch(reason => console.log(reason));
        }
    }, []);


    /* TODO: реализовать отображение ошибок */
    return (<></>);
}