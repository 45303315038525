import React, {useEffect} from "react";
import BaseButton from "../../components/common/Button/BaseButton";
import {useNavigate} from "react-router-dom";
import {auth} from "../../libs/auth";


export default function Dashboard() {
  const navigate = useNavigate()
  useEffect(() => {
    if (auth &&
      auth.current &&
      auth.current.permissions &&
      auth.current.permissions.length > 0) {
      navigate('/companies')
    }
  }, [auth?.current?.permissions])

  return (
    <div className={'w-full h-[90%] flex flex-col justify-center items-center'}>
      <div className={'flex flex-col items-center mb-8'}>
        <span className={'font-bold text-3xl'}>Здравствуйте!</span>
        <span className={'font-bold text-3xl text-center'}>Для создания компании пожалуйста</span>
        <span className={'font-bold text-3xl text-center'}>напишите нам в сообщения группы</span>
      </div>
      <div className={'flex flex-col items-center'}>
        <a className={'disabled:pointer-events-none flex justify-center items-center transition duration-300 ease-out text-sm rounded-lg text-sm px-[22px] py-3 bg-interactive-elem text-white'} target="_blank" href={'https://vk.me/result.rest'}>Написать</a>
      </div>
    </div>
  )
}
