import React, { useEffect, useState } from 'react'
import ChevronIcon from '../../../components/icons/ChevronIcon';
import Checkbox from '../../../components/common/form/checkbox/Checkbox';
import { useCompaniesContext } from '../../../contexts/Companies';
import { apiClient } from "../../../libs/api/apiClient";
import BaseButton from "../../../components/common/Button/BaseButton";
import { showSuccessToast } from "../../../libs/helpers/toasts";
import { dialogModal } from "../../../components/common/Modal/DialogModal";
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Spoiler from "../../../components/v2/Spoiler";
import InputWrapper from "../../../components/v2/form/input/common/InputWrapper";
import Input from "../../../components/v2/form/input/Input";
import SingleSelect, { Option } from "../../../components/v2/form/input/select/SingleSelect";
import TokenInput from '../../../components/v2/form/input/TokenInput';
import { diff } from "../../../libs/helpers/objects";
import { ScheduleItem } from "../../../types/branch";

interface IIKO {
  apiLogin: undefined | string;
  organizationId: undefined | string;
  externalMenuId: undefined | string;
  terminalGroupId: undefined | string;
  bonusWalletId: undefined | string;
  maxBonusDiscount: undefined | string;
  universalDiscountId: undefined | string;
  isCouponNeedSend: boolean;
  usePoints: boolean;
  usePromo: boolean;
  webhook: string;
  authorizationToken: string;
  integrationIdentifierSecret: undefined | string;
  isAutoUpdatePricesEnabled: undefined | boolean;

  payments: undefined | {
    cash: undefined | string;
    'on-delivery': undefined | string;
    online: undefined | string;
    transfer: undefined | string;
    loyaltyCard: undefined | string;
  },
  statuses:  undefined | {
    Unconfirmed: undefined | string;
    WaitCooking: undefined | string;
    ReadyForCooking: undefined | string;
    CookingStarted: undefined | string;
    CookingCompleted: undefined | string;
    Waiting: undefined | string;
    OnWay: undefined | string;
    Delivered: undefined | string;
    Closed: undefined | string;
    Cancelled: undefined | string;
  },
  salesChannel: undefined | {
    vk: string,
    site: string,
    ios: string,
    android: string,
  }
  options: Options
}

interface Options {
  organizationId: Array<Option<string>>;
  externalMenuId: Array<Option<string>>;
  terminalGroupId: Array<Option<string>>;
  bonusWalletId: Array<Option<string>>;
  universalDiscountId: Array<Option<string>>;
  payments: {
    cash: Array<Option<string>>;
    'on-delivery': Array<Option<string>>;
    online: Array<Option<string>>;
    transfer: Array<Option<string>>;
    loyaltyCard: Array<Option<string>>;
  },
  statuses: {
    Unconfirmed: Array<Option<string>>
    WaitCooking: Array<Option<string>>
    ReadyForCooking: Array<Option<string>>
    CookingStarted: Array<Option<string>>
    CookingCompleted: Array<Option<string>>
    Waiting: Array<Option<string>>
    OnWay: Array<Option<string>>
    Delivered: Array<Option<string>>
    Closed: Array<Option<string>>
    Cancelled: Array<Option<string>>
  },
  marketingSources: Array<Option<string>>
}

type UpdateField = (name: string, value: any) => void;
type UpdateProperties = () => void;

interface Props {
  data: {
    id: number,
    integrations: {
      iiko: IIKO
    },
    schedule: {
      [k in string]: ScheduleItem
    }
  },
  updateField: UpdateField,
  errors: {[k in string]: string}
}

const fetchOptions = async (companyId: bigint, branchId: bigint, options: IIKO): Promise<Options> => {
  try {
    const response = await apiClient.branches.integrations.iiko.options(companyId, branchId, { body: { options } });

    if (! response) {
      return {} as Options
    }

    if ("string" === typeof response) {
      return {} as Options
    }

    const { data } = response as any;

    return data;
  } catch (e) {
  }

  return {} as Options;
}

const showOptionsUpdatedToast = () => {
  showSuccessToast({
    content: 'Варианты опций успешно обновлены!'
  });
}

const isDirty = (previous: IIKO, current: IIKO): boolean => {
  return 0 < Object.keys(diff<IIKO>(previous, current)).length
}

export default function IIKO({ data, updateField, errors }: Props) {
  const { company } = useCompaniesContext();
  const [statuses, setStatuses] = useState<Option<string>[]>([])
  const [options, setOptions] = useState<Options>(data.integrations.iiko?.options);
  const [properties, setProperties] = useState<IIKO>(data.integrations?.iiko || {})
  const [loadSecretToken, setLoadSecretToken] = useState(false);
  const [initialProperties, _] = useState<IIKO>(properties)

  useEffect(() => {
    if (! company?.statuses) {
      return;
    }

    setStatuses([{label: 'Не выбрано', value: ''}, ...company?.statuses.map((status: any) => ({
      label: `${ status.title } (${ status.code })`,
      value: status.code
    }))]);
  }, [company?.id]);

  const clear = () => {
    setOptions({} as Options);
    setProperties({} as IIKO);
  };

  const updateOptions = async (state: IIKO) => {
    if (! (state.apiLogin)) {
      clear()
      return;
    }

    const options = await fetchOptions(company.id, BigInt(data.id), diff<IIKO>(initialProperties, properties));

    if (undefined === options) {
      return;
    }

    if (0 === Object.keys(options).length) {
      return;
    }

    setOptions(options);
    await updateProperties({options});

    showOptionsUpdatedToast()
  };

  const updateProperties = async (updating: object) => {
    delete errors['integrations.iiko.integrationIdentifierSecret'];

    setProperties(options => {
      const state = {...options, ...updating};
      const keys = Object.keys(updating);

      if (! keys.some((key) => ['apiLogin', 'organizationId', 'terminalGroupId'].includes(key))) {
        return state;
      }

      updateOptions(state);

      return state;
    });
  }

  const updateIntegration: UpdateProperties = () => {
    // Костыль. Объект iiko обновляется полностью, кроме apiLogin
    const update = { ...properties };
    if (update.apiLogin === initialProperties.apiLogin) {
      delete update.apiLogin;
    }
    updateField(`integrations.iiko`, update)
  }

  useEffect(() => {
    if (! isDirty(data.integrations.iiko || {}, properties)) {
      return;
    }

    updateIntegration();
  }, [JSON.stringify(properties)]);


  const updateSecretToken = () => {
    if (! properties.integrationIdentifierSecret) {
      setLoadSecretToken(true);

      apiClient.branches.updateSecterToken(company.id)
        .then(async ({ data }) => {
          await updateProperties({integrationIdentifierSecret: data.secret});
        })
        .finally(() => {
          setLoadSecretToken(false);
        });

      return;
    }

    dialogModal({
      isOpen: false,
      title: 'Внимание!',
      text: 'Вы уверены что хотите обновить секретный ключ?',
      showCancelButton: true,
      confirmButtonText: 'Обновить',
      cancelButtonText: 'Вернуться',
    })
      .then((result) => {
        if (! result.isConfirmed) {
          return;
        }

        setLoadSecretToken(true);
        apiClient.branches.updateSecterToken(company.id).then(async ({ data }) => {
          await updateProperties({integrationIdentifierSecret: data.secret});
        })
      })
      .catch(() => {
      })
      .then(() => {
        setLoadSecretToken(false);
      });
  }

  const handleUpdateIsAutoUpdatePricesEnable = async (value: boolean) => {
    await updateProperties({ isAutoUpdatePricesEnabled: value });

    if (value) {
      return;
    }

    updateField('schedule.iikoPrice', []);
  }

  return (
    <Spoiler title={ 'IIKO' } defaultOpen={ true }>
      {/* @ts-ignore */ }
      <Spoiler.Toggle>
        { ({ isOpen, toggleIsOpen, title }) => (
          <div className={ `text-[22px] font-semibold flex items-center justify-between cursor-pointer` }>
            <div onClick={ () => toggleIsOpen() }>{ title }</div>
            <div className={ 'flex items-center gap-4' }>
              <BaseButton
                onClick={ async () => await updateOptions(properties) }
                className={ `bg-interactive-elem text-white py-[7px]` }
              >Обновить данные
              </BaseButton>
              <ChevronIcon onClick={ () => toggleIsOpen() } className={ `${ isOpen && 'rotate-180' } duration-300` }/>
            </div>
          </div>
        ) }
      </Spoiler.Toggle>

      <div className={ 'space-y-8' }>
        <div className='grid grid-cols-2 gap-4'>
          <div>
            <InputWrapper>
              <InputWrapper.Label>
                API логин Cloud API
              </InputWrapper.Label>
              <TokenInput
                name={ 'integrations.iiko.apiLogin' }
                defaultValue={ data.integrations?.iiko.apiLogin }
                error={ errors['integrations.iiko.apiLogin'] }
                onBlur={ (event) => updateProperties({ apiLogin: event.target.value }) }
              />
            </InputWrapper>
          </div>
        </div>

        <div className='grid grid-cols-2 gap-4'>
          <SingleSelect<string>
            value={ properties.organizationId || '' }
            options={ options?.organizationId || [] }
            label={ 'Организация' }
            onChange={ (option) => updateProperties({ organizationId: option.value }) }
          />

          <SingleSelect<string>
            value={ properties.terminalGroupId || '' }
            options={ options?.terminalGroupId || [] }
            label={ 'Терминальная группа' }
            onChange={ (option) => updateProperties({ terminalGroupId: option.value }) }
          />

          <SingleSelect<string>
            value={ properties.externalMenuId || '' }
            options={ options?.externalMenuId || [] }
            label={ 'Внешнее меню' }
            onChange={ (option) => updateProperties({ externalMenuId: option.value }) }
          />
        </div>

        <div className='grid grid-cols-2 gap-4'>
          <SingleSelect<string>
            value={ properties.payments?.cash || '' }
            options={ options?.payments?.cash || [] }
            label={ 'Тип оплаты, Наличные' }
            onChange={ (option) => updateProperties({ payments: { ...properties.payments, cash: option.value } }) }
          />

          <SingleSelect<string>
            value={ properties.payments?.['on-delivery'] || '' }
            options={ options?.payments?.['on-delivery'] || [] }
            label={ 'Тип оплаты, Картой при получении' }
            onChange={ (option) => updateProperties({
              payments: {
                ...properties.payments,
                ['on-delivery']: option.value
              }
            }) }
          />

          <SingleSelect<string>
            value={ properties.payments?.online || '' }
            options={ options?.payments?.online || [] }
            label={ 'Тип оплаты, Online' }
            onChange={ (option) => updateProperties({ payments: { ...properties.payments, online: option.value } }) }
          />

          <SingleSelect<string>
            value={ properties.payments?.transfer || '' }
            options={ options?.payments?.transfer || [] }
            label={ 'Тип оплаты, Мобильный банк (Перевод)' }
            onChange={ (option) => updateProperties({
              payments: {
                ...properties.payments,
                transfer: option.value
              }
            }) }
          />

          <SingleSelect<string>
            value={ properties.payments?.loyaltyCard || '' }
            options={ options?.payments?.loyaltyCard || [] }
            label={ 'Тип оплаты, Оплата бонусными баллами' }
            onChange={ (option) => updateProperties({
              payments: {
                ...properties.payments,
                loyaltyCard: option.value
              }
            }) }
          />
        </div>

        <div className='grid grid-cols-2 gap-4'>
          <SingleSelect<string>
            value={ properties.bonusWalletId || '' }
            options={ options?.bonusWalletId || [] }
            label={ 'Тип оплаты, Оплата бонусными баллами' }
            onChange={ (option) => updateProperties({ bonusWalletId: option.value }) }
          />

          <div>
            <InputWrapper>
              <InputWrapper.Label>
                Лимит списания баллов (0-100)
              </InputWrapper.Label>
              <Input
                defaultValue={ properties.maxBonusDiscount }
                name={ 'integrations.iiko.maxBonusDiscount' }
                placeholder={ 'Лимит списания баллов (0-100)' }
                max={ 100 }
                min={ 0 }
                onBlur={ (event) => {
                  const value = Math.max(0, Math.min(100, +event.target.value));
                  event.target.value = value.toString();
                  updateProperties({ maxBonusDiscount: value });
                } }
              />
            </InputWrapper>
          </div>
        </div>

        <div className='grid grid-cols-2 gap-4'>
          <SingleSelect<string>
            value={ properties.universalDiscountId || '' }
            options={ options?.universalDiscountId || [] }
            label={ 'Идентификатор универсальной скидки' }
            onChange={ (option) => updateProperties({ universalDiscountId: option.value }) }
          />
        </div>

        <div className='grid grid-cols-2 gap-4'>
          <SingleSelect<string>
            value={ properties.salesChannel?.vk || '' }
            options={ options?.marketingSources || [] }
            label={ 'Источник приложение Вконтакте' }
            onChange={ (option) => updateProperties({
              salesChannel: {
                ...properties.salesChannel,
                vk: option.value
              }
            }) }
          />

          <SingleSelect<string>
            value={ properties.salesChannel?.ios || '' }
            options={ options?.marketingSources || [] }
            label={ 'Источник мобильное приложение iOS' }
            onChange={ (option) => updateProperties({
              salesChannel: {
                ...properties.salesChannel,
                ios: option.value
              }
            }) }
          />

          <SingleSelect<string>
            value={ properties.salesChannel?.site || '' }
            options={ options?.marketingSources || [] }
            label={ 'Источник сайт' }
            onChange={ (option) => updateProperties({
              salesChannel: {
                ...properties.salesChannel,
                site: option.value
              }
            }) }
          />

          <SingleSelect<string>
            value={ properties.salesChannel?.android || '' }
            options={ options?.marketingSources || [] }
            label={ 'Источник мобильное приложение Android' }
            onChange={ (option) => updateProperties({
              salesChannel: {
                ...properties.salesChannel,
                android: option.value
              }
            }) }
          />
        </div>

        <div className='grid grid-cols-4 gap-4'>
          <SingleSelect<string>
            value={ properties.statuses?.Unconfirmed || '' }
            options={ statuses || [] }
            label={ 'Статус заказа (Unconfirmed)' }
            onChange={ (option) => updateProperties({
              statuses: {
                ...properties.statuses,
                Unconfirmed: option.value
              }
            }) }
          />

          <SingleSelect<string>
            value={ properties.statuses?.WaitCooking || '' }
            options={ statuses || [] }
            label={ 'Статус заказа (WaitCooking)' }
            onChange={ (option) => updateProperties({
              statuses: {
                ...properties.statuses,
                WaitCooking: option.value
              }
            }) }
          />

          <SingleSelect<string>
            value={ properties.statuses?.ReadyForCooking || '' }
            options={ statuses || [] }
            label={ 'Статус заказа (ReadyForCooking)' }
            onChange={ (option) => updateProperties({
              statuses: {
                ...properties.statuses,
                ReadyForCooking: option.value
              }
            }) }
          />

          <SingleSelect<string>
            value={ properties.statuses?.CookingStarted || '' }
            options={ statuses || [] }
            label={ 'Статус заказа (CookingStarted)' }
            onChange={ (option) => updateProperties({
              statuses: {
                ...properties.statuses,
                CookingStarted: option.value
              }
            }) }
          />

          <SingleSelect<string>
            value={ properties.statuses?.CookingCompleted || '' }
            options={ statuses || [] }
            label={ 'Статус заказа (CookingCompleted)' }
            onChange={ (option) => updateProperties({
              statuses: {
                ...properties.statuses,
                CookingCompleted: option.value
              }
            }) }
          />

          <SingleSelect<string>
            value={ properties.statuses?.Waiting || '' }
            options={ statuses || [] }
            label={ 'Статус заказа (Waiting)' }
            onChange={ (option) => updateProperties({ statuses: { ...properties.statuses, Waiting: option.value } }) }
          />

          <SingleSelect<string>
            value={ properties.statuses?.OnWay || '' }
            options={ statuses || [] }
            label={ 'Статус заказа (OnWay)' }
            onChange={ (option) => updateProperties({ statuses: { ...properties.statuses, OnWay: option.value } }) }
          />

          <SingleSelect<string>
            value={ properties.statuses?.Delivered || '' }
            options={ statuses || [] }
            label={ 'Статус заказа (Delivered)' }
            onChange={ (option) => updateProperties({
              statuses: {
                ...properties.statuses,
                Delivered: option.value
              }
            }) }
          />

          <SingleSelect<string>
            value={ properties.statuses?.Closed || '' }
            options={ statuses }
            label={ 'Статус заказа (Closed)' }
            onChange={ (option) => updateProperties({ statuses: { ...properties.statuses, Closed: option.value } }) }
          />

          <SingleSelect<string>
            value={ properties.statuses?.Cancelled || '' }
            options={ statuses || [] }
            label={ 'Статус заказа (Cancelled)' }
            onChange={ (option) => updateProperties({
              statuses: {
                ...properties.statuses,
                Cancelled: option.value
              }
            }) }
          />
        </div>

        <div className={'flex flex-col gap-4'}>
          <div>
            <Checkbox
              label='Автоматическая синхронизация цен'
              name={ 'integrations.iiko.isAutoUpdatePricesEnabled' }
              value={ Boolean(+(properties?.isAutoUpdatePricesEnabled || 0)) }
              onChange={ handleUpdateIsAutoUpdatePricesEnable }
            />
          </div>

          { (Boolean(+(properties?.isAutoUpdatePricesEnabled || 0))) && (
            <div>
              <InputWrapper error={ errors[`schedule.iikoPrices.executeAt`] }>
                <InputWrapper.Label>
                  Время синхронизации
                </InputWrapper.Label>
                <Input
                  type={ 'time' }
                  id={ `executeAt` }
                  defaultValue={ data?.schedule?.iikoPrice?.executeAt }
                  onBlur={ (event) => updateField('schedule.iikoPrice.executeAt', event.target.value) }
                />
              </InputWrapper>
            </div>
          )}
        </div>

        <div className={ 'grid grid-cols-1 mb-4' }>
          <div className={ `flex items-end gap-5 py-3 px-4 border-gray-20 border-[1px] rounded-lg` }>
            <div className={ 'w-full' }>
              <InputWrapper
                error={ errors['integrations.iiko.integrationIdentifierSecret'] }
              >
                <InputWrapper.Label>
                  Секретный ключ
                </InputWrapper.Label>
                <span
                  className={ 'pointer-events-none select-none' }>{ properties.integrationIdentifierSecret || 'Секретный ключ' }</span>
              </InputWrapper>
            </div>

            <BaseButton onClick={ updateSecretToken }
                        className={ "bg-interactive-elem text-white h-[42px] min-w-[110px]" }>
              {
                loadSecretToken ?
                  <div className={ 'flex flex-row items-center justify-center' }>
                    <svg
                      className="animate-spin h-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                              strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </div> : 'Обновить'
              }
            </BaseButton>
            <CopyToClipboard text={ properties.integrationIdentifierSecret }
                             onCopy={ () => showSuccessToast({ content: "Секретный ключ скопирован", }) }>
              <BaseButton onClick={ () => window.navigator }
                          className={ "bg-interactive-elem text-white h-[42px]" }> Копировать </BaseButton>
            </CopyToClipboard>
          </div>
        </div>

        <div className='flex flex-col gap-2'>
          {/* Инвертируем значение, ибо бек принемает send..., а лейбал: 'Не...' */ }
          <Checkbox
            label='Не передавать промокоды'
            name={ 'integrations.iiko.isCouponNeedSend' }
            value={ ! Boolean(+(properties?.isCouponNeedSend || 0)) }
            onChange={ (value: any) => updateProperties({ isCouponNeedSend: ! value }) }
          />

          <Checkbox
            label='Не использовать баллы'
            name={ 'integrations.partner.usePoints' }
            value={ ! Boolean(+(properties?.usePoints || 0)) }
            onChange={ (value: any) => updateProperties({ usePoints: ! value }) }
          />

          <Checkbox
            label='Не использовать промокоды'
            name={ 'integrations.partner.usePromo' }
            value={ ! Boolean(+(properties?.usePromo || 0)) }
            onChange={ (value: any) => updateProperties({ usePromo: ! value }) }
          />

          <div className={ 'mt-2' }>
            <Spoiler title={ 'Подробнее' } size={ 'sm' } toggleTextColor={ "text-interactive-text" }>
              <div className={ "flex flex-col font-medium mt-3 gap-y-2 max-w-[90%]" }>
                <div className='mb-4'>
                  Для того что бы связь работала, необходимо выполнить следующие пункты:
                </div>
                <ul className='mb-4'>
                  <li>
                    1. Включить функцию API на вашем тарифном плане в IIKO
                  </li>
                  <li>
                    2. Указать Webhook URL в настройках: { (data.integrations.iiko.webhook && (
                    <span
                      className='font-semibold underline'>{ data.integrations.iiko.webhook }</span>)) || (
                    <span
                      className={ 'text-gray-500' }>ссылка на веб-хук будет доступна после сохранения настроек</span>) }
                  </li>
                  <li>
                    3. Скопировать и вставить токен
                    авторизации <code>{ data.integrations.iiko.authorizationToken }</code>
                  </li>
                </ul>
              </div>
            </Spoiler>
          </div>
        </div>
      </div>
    </Spoiler>
  )
}
