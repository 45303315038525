import React, { useState } from "react";
import SingleSimpleValueSelect from "../../../components/common/form/select/SingleSimpleValueSelect";

export default function Loyalty({data, updateField, errors, loyaltyPrograms}: any) {
  const noneSelectLoyaltyPropgram = {label: 'Не использовать', value: 'none'};
  const loyaltyProgramsWithNoneSelect = [...loyaltyPrograms, noneSelectLoyaltyPropgram].filter((program) => program.value !== 'sbis-presto');

  return (
    <>
      <SingleSimpleValueSelect
        label={'Выбор программы лояльности'}
        name={'loyaltyProgram'}
        options={loyaltyProgramsWithNoneSelect}
        onChange={(value: any) => {
          console.log(value)
          updateField('loyaltyProgram', value)
        }}
        value={data.loyaltyProgram? data.loyaltyProgram :  'Не использовать'}
        errors={errors}
        textTooltip={'Программа лояльности'}

      />
    </>
  )
}
