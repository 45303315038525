import React from "react";
import {useCompaniesContext} from "../../../../contexts/Companies";
import {useBranchesContext} from "../../../../contexts/Branches";
import {customModal} from "../../../../components/common/Modal/CustomModal";
import {apiClient} from "../../../../libs/api/apiClient";
import {showErrorToast, showSuccessToast} from "../../../../libs/helpers/toasts";
import {IExternalResponseProps} from "../../externalResponseContainer";

export default function ExternalResponse({order, updateOrders, limits, filters}: IExternalResponseProps) {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    const showErrorDetails = (data: any) => {
        customModal({
            title: data?.id ? `Статус заказа #${data?.id}` : 'Статус заказа',
            html: renderErrorDetails(data),
            showCancelButton: true,
            showCloseButton: true,
            showSubmitButton: true,
            allowOutsideClick: false,
            cancelButtonText: 'Закрыть',
            confirmButtonText: 'Отправить повторно',
            confirmButtonClassName: 'bg-error-font border-2 border-error-font text-white py-[7px]',
            classNameWrapper: 'min-w-[400px] max-w-[800px] overflow-y-auto'
        }).then((response: any) => {
            if (response?.isConfirmed) {
                apiClient.orders.resendToExternal(company?.id, branch?.id, data?.id).then(({data, message}: any) => {
                    if (message) {
                        showErrorToast({
                            content: message || 'При отправке запроса произошла ошибка'
                        })
                    } else {
                        showSuccessToast({
                            content: 'Запрос успешно отправлен'
                        })
                        updateOrders(limits, filters)
                    }
                }).catch((reason: any) => {
                    showErrorToast({
                        content: reason || 'При отправке запроса произошла ошибка'
                    })
                })
            }
        }).catch(() => {
        })
    }

    const renderErrorDetails = (data: any) => {
        return (
            <div className={`flex flex-col gap-y-4`}>
                <div>Статус: <span className={`text-error-main`}>{data?.externalResponse?.status}</span></div>
                {
                    data?.externalResponse?.warnings && Object.keys(data?.externalResponse?.warnings).length > 0 &&
                    <div className={`flex flex-col gap-y-2`}>
                        Предупреждения:
                        <div className={`flex flex-col gap-x-2 text-error-main text-xs`}>
                            {Object.entries(data?.externalResponse?.warnings)?.map(([attribute, value]: any) => (
                                <div>{attribute} = {value}</div>
                            ))}
                        </div>
                    </div>
                }
                <pre
                    className={`break-words w-full h-[300px] overflow-auto max-h-[300px] text-xs bg-gray-10 border-gray-40 border-[1px] rounded-lg px-2`}>
          {JSON.stringify(data?.externalResponse?.reason, null, 2)}
        </pre>
            </div>
        )
    }

    return (
        <>
            {
                order?.externalResponse && order?.externalResponse?.status &&
                <div className={`text-error-font cursor-pointer text-xs underline`}
                     onClick={() => showErrorDetails(order)}>status_error: {order?.externalResponse?.status}</div>
            }
            {
                order.externalResponse && order.externalResponse?.reason?.result === 'success' &&
                <div
                    className={`text-interactive-text text-xs`}>{order?.externalResponse?.reason?.integration} ID: {order?.externalResponse?.reason?.order_id} №: {order?.externalResponse?.reason?.order_number}</div>
            }
        </>
    )
}