import {apiClient} from "../../libs/api/apiClient";

async function fetchChannels() {
  const { data }: any = await apiClient.dictionaries.channels()

  return data.map((channel: any) => ({
    label: channel.title,
    value: channel.value
  }))
}

export default fetchChannels
