import React, {useRef} from "react";
import {Node} from "reactflow";
// @ts-ignore
import clipImg from "../../../images/clip.svg"
import CommonForm from "./forms/CommonForm";
import {useFunnelNodeContext} from "../../../contexts/FunnelNodes";

interface IProps {
  showSidebar: boolean
  setShowSidebar: (show: boolean) => void
  node: Node | null
}

export default function Sidebar({showSidebar, setShowSidebar, node}: IProps) {
  const {updateNode} = useFunnelNodeContext()
  const wrapper = useRef<any>(null);

  return (
    <div
      ref={wrapper}
      className={`absolute bg-transparent duration-500 ease-in-out h-full overflow-hidden right-0 top-0 w-full z-[1010] ${
        showSidebar ? "translate-x-0 " : "translate-x-full"
      }`}
    >
      <div
        className={`shadow-2xl top-0 right-0 w-[40vw] bg-white p-6 flex flex-col text-white fixed h-full z-[1010] rounded-tl-xl rounded-bl-xl ease-in-out duration-500 ${
          showSidebar ? "translate-x-0 " : "translate-x-full"
        }`}
      >
        {
          showSidebar && <CommonForm
            node={node}
            reset={() => {
              setShowSidebar(false)
            }}
            onSubmit={(data: any) => {
              if (node) {
                updateNode(node.id, data)
                setShowSidebar(false)
              }
            }}
          />
        }
      </div>
    </div>
  )
}
