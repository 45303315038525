import React, { useEffect, useState } from "react";
import Tooltip from "../../../Tooltip";
// @ts-ignore
import closeIcon from '../../../../../images/greenClose.svg'
import DataTableModal from "./DataTableModal";
import BaseButton from "../../../Button/BaseButton";

//TODO: сделать сброс фильтров и поиска при любом закрытии модалки
export function DatatableSelect({
  children,
  data,
  filteredData,
  errors,
  errorLabel = true,
  label,
  className = '',
  loading = false,
  required = false,
  textTooltip = '',
  name,
  placeholder,
  values = [],
  updateValues,
  removeItem,
  updateData,
  columns,
  resetFilters = () => {},
  buttonPlaceholder = 'Выберите товар'
}: any) {
  const [show, setShow] = useState(false);
  const error = errors[name];

  useEffect(() => {
    delete errors[name];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (!show) {
      resetFilters()
    }
  }, [show])

  return (
    <div className={`${className}`}>
      {
        label &&
        <label className="flex items-center mb-2 font-medium text-sm">
          {label}
          <span className='text-error-main'>{required && '*'}</span>
          {
            textTooltip && <Tooltip textTooltip={textTooltip} defaultText={name} />
          }
        </label>
      }
      <div className={'relative w-full'}>
        <div className="flex items-center justify-between py-[20px] px-[14px] w-full text-sm bg-white border-gray-20 border-[1px] rounded-2xl" >
          <div className="flex gap-2 items-center">
            <div className={'flex flex-row flex-wrap items-center'}>
              {
                loading ? <p className={"text-gray-20"}>Загрузка...</p> :
                  values.length ? values.map((value: any, index: any) => (
                    <div className="flex flex-row items-center p-3">
                      <span className="mr-2 text-ellipsis overflow-hidden whitespace-nowrap text-black">{data.find((item: any) => item?.id === value)?.title || ''}</span>
                      <img src={closeIcon} alt="" className="w-[16px] h-[16px] cursor-pointer" onClick={() => {
                        removeItem(index)
                      }} />
                    </div>
                  )
                  ) :
                    <span className="text-gray-20">{placeholder}</span>
              }

            </div>
          </div>
          <BaseButton className={'bg-white border-interactive-elem border-[2px] text-interactive-elem mr-[10px] py-[7px]'} onClick={() => setShow(show => !show)}>{buttonPlaceholder}</BaseButton>

        </div>

        <DataTableModal
          title={placeholder}
          setShow={setShow}
          show={show}
          data={data || []}
          loading={loading}
          columns={columns}
          updateData={updateData}
          selectedRows={values}
          onSubmit={(rowSelection: any) => {
            updateValues(rowSelection)
          }}
          buttonPlaceholder={buttonPlaceholder}
        >
          {children}
        </DataTableModal>
      </div>
      {error && errorLabel && (<span className={'text-error-font text-xs font-medium'}>{error}</span>)}
    </div>
  )
}
