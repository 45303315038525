
import BaseButton from "../Button/BaseButton";
import React, { useEffect, useId, useRef, useState } from "react";
// @ts-ignore
import closeIcon from "../../../images/greenClose.svg"
import TextInput from "../form/input/TextInput";
import SingleSimpleValueSelect from "../form/select/SingleSimpleValueSelect";

interface ModalProps {
  isOpen: boolean
  onResolve: any
  onReject: any
  title: string
  showDenyButton?: boolean
  showCancelButton?: boolean
  showCloseButton?: boolean
  showSubmitButton?: boolean
  allowOutsideClick?: boolean
  confirmButtonText?: string
  denyButtonText?: string
  cancelButtonText?: string
  classNameWrapper?: string
  target?: string
}

enum Encoding {
	UTF8 = 'utf-8',
	W1251 = 'windows-1251'
}

enum File {
	CSV = 'csv',
	YML = 'yml',
	XML = 'xml'
}


export default function ExportModal(
  {
    isOpen,
    onResolve,
    onReject,
    title,
    showDenyButton = false,
    showCancelButton = false,
    showCloseButton = true,
    showSubmitButton = true,
    allowOutsideClick = false,
    confirmButtonText = 'Экспорт',
    denyButtonText = 'Вернуться',
    cancelButtonText = 'Отмена',
    classNameWrapper = '',
    target,
  }: ModalProps) {
  const id = useId();
  const wrapper = useRef<any>(null)
	const [fileType, setFileType] = useState(File.CSV)
	const [encoding, setEncoding] = useState(Encoding.UTF8)
	const [email, setEmail] = useState('')


  /* Закрываем при клике вне поля*/
  useEffect(() => {
    function handleClick(event: any) {
      if (wrapper.current && !wrapper.current.contains(event.target) && allowOutsideClick) {
        onReject({ isCancelled: true })
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }

  }, [isOpen, onReject, wrapper]);

  return (
    <div id={id} className={`fixed grid w-full h-full overflow-y-auto modal-wrapper ${!isOpen && 'hidden'}`}>
      <div ref={wrapper} className={`grid relative bg-white rounded-[10px] max-w-[90%] max-h-full modal ${classNameWrapper}`}>
        <div className={'flex justify-between px-4 py-[14px] items-center'}>
          <span className={'text-xl'}>{title}</span>
          {
            showCloseButton &&
            <img src={closeIcon} className={'w-[20px] cursor-pointer'} onClick={() => {
              onReject({ isCancelled: true })
            }} alt="" />
          }
        </div>
        <div className="p-4">
          <div className={'flex flex-col gap-y-4'}>
            <SingleSimpleValueSelect
              label={'Формат файла'}
              name={'type'}
              placeholder={'Формат файла'}
              value={fileType}
              options={
                target === 'goods' ? 
                [
                  { label: 'CSV', value: File.CSV },
                  { label: 'YML', value: File.YML },
                  { label: 'XML', value: File.XML },
                ] : [
                  { label: 'CSV', value: File.CSV },
                  { label: 'XML', value: File.XML },
                ]
              }
              onChange={(option: any) => {
                setFileType(option)
              }}
            />

            {
              fileType !== File.YML && 
              <>
                <SingleSimpleValueSelect
                  label={'Кодировка файла'}
                  name={'encoding'}
                  placeholder={'Выберите кодировку'}
                  value={encoding}
                  options={[
                    { label: 'UTF-8', value: Encoding.UTF8 },
                    { label: 'Windows-1251', value: Encoding.W1251 },
                  ]}
                  onChange={(option: any) => {
                    setEncoding(option)
                  }}
                />

                <TextInput
                  label={'Уведомить о завершении на Email'}
                  value={email}
                  onChange={(value: any) => setEmail(value)}
                  name={'email'}
                  placeholder={'Введите email через запятую'}
                  errors={{}}
                />
              </>
            }

          </div>
        </div>

        <div className="flex flex-row justify-between py-4 px-4 gap-y-4">
          {
            showDenyButton && <BaseButton className={'bg-error-font border-2 text-white border-error-font py-[7px]'} onClick={() => {
              onResolve({ isConfirmed: false, isDenied: true })
            }}>{denyButtonText}</BaseButton>
          }
          <div className={`flex flex-row gap-x-4 items-center justify-between ${!showDenyButton && 'w-full'}`}>
            {
              showCancelButton && <BaseButton className={'bg-white border-2 text-error-font border-error-font py-[7px]'} onClick={() => {
                onReject({ isCancelled: true })
              }}>{cancelButtonText}</BaseButton>
            }
            {
              showSubmitButton &&
              <BaseButton className={'bg-interactive-elem border-2 border-interactive-elem text-white py-[7px]'} onClick={() => {
                onResolve({ isConfirmed: true, data: {fileType, encoding, email}})
              }}>{confirmButtonText}</BaseButton>
            }
          </div>
        </div>
      </div>
    </div>
  )
}