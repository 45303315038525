import React, { useEffect, useState } from 'react'
import TextInputsList from './../../../components/common/form/input/TextInputsList';
import BaseButton from '../../../components/common/Button/BaseButton';
import { apiClient } from '../../../libs/api/apiClient';
import TextInput from '../../../components/common/form/input/TextInput';
import { useCompaniesContext } from '../../../contexts/Companies';
import { dialogModal } from './../../../components/common/Modal/DialogModal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { showSuccessToast } from "../../../libs/helpers/toasts";

export default function Notifications({errors, data, updateField, updateFieldNoRender}: any) {
  const { company } = useCompaniesContext()
  const [loadSecretToken, setLoadSecretToken] = useState(false)

  const updateSecretToken = () => {
    if (data.notifications.apiSecret) {
      dialogModal({
        isOpen: false,
        title: 'Внимание!',
        text: 'Вы уверены что хотите обновить токен для вебхуков?',
        showCancelButton: true,
        confirmButtonText: 'Обновить',
        cancelButtonText: 'Вернуться',
      }).then((result) => {
        if (result.isConfirmed) {
          setLoadSecretToken(true)
          apiClient.branches.updateSecterToken(company.id).then(({data}) => {
            updateField('notifications.apiSecret', data.secret)
            setLoadSecretToken(false)
          })
        }
      }).catch(() => {})
    } else {
      setLoadSecretToken(true)
      apiClient.branches.updateSecterToken(company.id).then(({data}) => {
        updateField('notifications.apiSecret', data.secret)
        setLoadSecretToken(false)
      })
    }
  }

  return (
    <div className={'flex flex-col px-3 gap-y-6'}>
        <TextInputsList
          name={'notifications.vkAdministrators'}
          label="Администраторы ВКонтакте"
          textTooltip="Администраторы ВКонтакте"
          errors={errors}
          onChange={value => updateField('notifications.vkAdministrators', value)}
          values={data.notifications.vkAdministrators || []}
          placeholder="Администратор"
        />

        <TextInputsList
          name={'notifications.emailsForServiceNotifications'}
          label="E-mail для уведомлений от сервиса"
          textTooltip="E-mail для уведомлений от сервиса"
          errors={errors}
          onChange={value => updateField('notifications.emailsForServiceNotifications', value)}
          values={data.notifications.emailsForServiceNotifications || []}
          placeholder="Введите E-mail на который будете получать уведомления о заказах и сообщения от сервиса"
        />

        <TextInputsList
          name={'notifications.tgChatIdsForSystemNotifications'}
          label="ID чатов в telegram для уведомлений от сервиса"
          textTooltip="ID чатов в telegram для уведомлений от сервиса"
          errors={errors}
          onChange={value => updateField('notifications.tgChatIdsForSystemNotifications', value)}
          values={data.notifications.tgChatIdsForSystemNotifications || []}
          placeholder="ID"
        />

        <div className={`flex items-end gap-5 py-3 px-4 border-gray-20 border-[1px] rounded-lg`}>
          <TextInput
            className='w-full'
            textTooltip='Токен для вебхуков'
            label='Токен для вебхуков'
            placeholder='Токен'
            name={'notifications.apiSecret'}
            errors={errors}
            value={data.notifications.apiSecret}
            disabled={true}
          />

          <BaseButton onClick={updateSecretToken} className={"bg-interactive-elem text-white h-[42px] min-w-[110px]"}> 
            {
              loadSecretToken ?
              <div className={'flex flex-row items-center justify-center'}>
                <svg
                  className="animate-spin h-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div> : 'Обновить'
            }
          </BaseButton>
          <CopyToClipboard text={data.notifications.apiSecret} onCopy={() => showSuccessToast({ content: "Токен скопирован", })}>
            <BaseButton onClick={() => window.navigator} className={"bg-interactive-elem text-white h-[42px]"}> Копировать </BaseButton>
          </CopyToClipboard>
        </div>
    </div>
  )
}
