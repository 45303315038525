import React, {useEffect, useState} from "react";
import BaseButton from "../../components/common/Button/BaseButton";
import {createColumnHelper} from "@tanstack/react-table";
import DataTable from "../../components/common/Table/DataTable";
import {useBranchesContext} from "../../contexts/Branches";
import SingleSimpleValueSelect from "../../components/common/form/select/SingleSimpleValueSelect";
import RangeDatePicker from "../../components/common/form/datetime/RangeDatePicker";
import {useReportProductsContext} from "../../contexts/Reports";
import Export from "../../components/common/Export";
import { ExportBlocks } from "../../components/common/Export/helpers";

export default function ReportProducts() {
  const [filterBranch, setBranch] = useState(null)
  const [applyFilter, setApplyFilter] = useState(false)
  const [dateFilter, setDateFilter] = useState<any>([null, null])

  const {reports, updateReports, updateFilters, filters, limits, pagination, loading}: any = useReportProductsContext()

  const {branches, branch} = useBranchesContext()

  const columnHelper = createColumnHelper<any>()
  const columns = [
    columnHelper.accessor(row => row.id, {
      id: 'id',
      header: () => 'ID',
      cell: info => <div
        className={'w-[70px] font-normal break-all flex justify-start items-center ml-4'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[70px] flex justify-start text-center ml-4',
      }
    }),
    columnHelper.accessor(row => row.title, {
      id: 'title',
      header: () => 'Наименование',
      cell: info => <div
        className={'grow font-normal break-words flex justify-start items-center mx-2'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'grow flex justify-start text-center mx-2',
        sortField: 'title'
      }
    }),
    columnHelper.accessor(row => row.price, {
      id: 'price',
      header: () => 'Цена',
      cell: info => <div
        className={'w-[85px] font-normal break-all flex justify-center items-center mx-2'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[85px] flex justify-center text-center mx-2',
        sortField: 'price'
      }
    }),
    columnHelper.accessor(row => row.count, {
      id: 'count',
      header: () => 'Кол-во',
      cell: info => <div
        className={'w-[85px] font-normal flex items-center justify-center mx-2'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[85px] flex justify-center text-center mx-2',
        sortField: 'count'
      }
    }),
    columnHelper.accessor(row => row.sum, {
      id: 'sum',
      header: () => 'Сумма',
      cell: info => <div
        className={'w-[85px] font-normal break-all flex justify-center items-center mx-2'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[85px] flex justify-center text-center mx-2',
        sortField: 'sum'
      }
    }),
    columnHelper.accessor(row => row.countRecommended, {
      id: 'countRecommended',
      header: () => 'Кол-во (реком.)',
      cell: info => <div
        className={'w-[150px] font-normal flex justify-center items-center'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[150px] flex justify-center text-center',
        sortField: 'countRecommended'
      }
    }),
    columnHelper.accessor(row => row.sumRecommended, {
      id: 'sumRecommended',
      header: () => 'Сумма (реком.)',
      cell: info => <div
        className={'w-[150px] font-normal flex justify-center items-center mx-2'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[150px] flex justify-center text-center mx-2',
        sortField: 'sumRecommended'
      }
    }),
  ]

  useEffect(() => {
    setApplyFilter(true)
    const filter = {
      createdAt: {
        after: dateFilter[0] || null,
        before: dateFilter[1] || null,
      },
      branches: filterBranch || null,
    }
    updateFilters('filter', filter)
  }, [dateFilter, filterBranch])

  if (!branch) {
    return <div>
      <div className="px-8 pb-8 pt-2">
        <div className="flex flex-col justify-between">
          <div>
            <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Отчеты</div>
            <div className="text-gray-40 text-base">Статистика продаж</div>
          </div>
          <div className="text-gray-40 text-3xl text-center mt-[35vh]">
            Выберите филиал
          </div>
        </div>
      </div>
    </div>
  }

  if (!applyFilter && reports?.length === 0) return (
    <div className="px-8 pb-8 pt-2">
      <div className="flex flex-col gap-y-6">
        <div className="flex justify-between items-end">
          <div>
            <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Отчеты</div>
            <div className="text-gray-40 text-base">Статистика продаж</div>
          </div>
        </div>
      </div>
      <div className="text-gray-40 text-3xl text-center mt-[35vh]">
        Статистики за продаж нет
      </div>
    </div>
  )

  return (
    <div className="px-8 pb-8 pt-2">
      <div className="flex flex-col gap-y-6">
        <div className="flex justify-between items-end">
          <div>
            <p className="text-gray-50 text-[44px] leading-[60px] mb-2">Отчеты</p>
            <p className="text-gray-40 text-base">Статистика продаж</p>
          </div>
        </div>
        <div className={"w-full flex justify-between items-center gap-x-4"}>
          <div className={"w-1/2 flex gap-x-4 "}>
            <SingleSimpleValueSelect
              options={branches?.map((branch: any) => ({label: branch.title, value: branch.id})) || []}
              value={filterBranch}
              onChange={(value: any) => {
                setBranch(value)
                //updateFilters('filter.branches', value)
              }}
              placeholder={'Выбрать филиал'}
              className={"w-full"}
              removeItem={true}
              removeValue={() => {
                setBranch(null)
                //updateFilters('filter.branches', null)
              }}
            />
            <div className={"w-full"}>
              <RangeDatePicker
                required={false}
                values={dateFilter}
                placeholder='Выберите промежуток дат'
                onChange={([startAt, endAt]: any) => {
                  setDateFilter([startAt, endAt])
                }}
                errors={{}}
                name={'dateFilter'}
                label={undefined}
                textTooltip={undefined}
              />
            </div>

          </div>
          <div className={"w-1/2 flex justify-end"}>
            <BaseButton onClick={() => {
              updateReports({...limits, page: 1}, filters)
            }} className={"ml-5 bg-interactive-elem text-white"}>Показать результат</BaseButton>
          </div>
        </div>
        <Export
          title={'Экспорт статистики товаров'}
          types={[{ title: 'Экспортировать', type: ExportBlocks.reportGoods }]}
          filter={filters || null}
          order={limits?.order || null}
        />
        <DataTable
          data={reports || []}
          columns={columns}
          usePagination={true}
          defaultLimit={5}
          defaultPage={1}
          pagination={pagination}
          updateData={(params: any) => updateReports(params, filters)}
          loading={loading}
          classNameTable={'mt-2'}
        />
      </div>
    </div>
  )

}
