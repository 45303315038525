import React from 'react'

export default function CheckIcon({className='', color='interactive'}) {
  const colors = {
    interactive: '#55B37A',
    white: '#ffffff'
  }

  return (
    <div className={`${className}`}>
      <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 7L5 11L15 1" stroke={colors[color]} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </div>
  )
}
