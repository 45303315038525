import React, {useEffect, useState} from "react";
import { useBranchesContext } from "../../../../contexts/Branches";
import { useCompaniesContext } from "../../../../contexts/Companies";
import initForm from "../../Rules/initForm";
import useForm from "../../../../hooks/useForm";
import Breadcrumbs from "../../../../components/common/Breadcrumbs";
import BaseButton from "../../../../components/common/Button/BaseButton";
import { useNavigate, useParams } from "react-router-dom";
import { dialogModal } from "../../../../components/common/Modal/DialogModal";
import { apiClient } from "../../../../libs/api/apiClient";
import { showErrorToast, showSuccessToast } from "../../../../libs/helpers/toasts";
import Base from "../Base";
import { LoyaltyStatusContextWrapper } from "../../../../contexts/LoyaltyPrograms";
import {useFormContext} from "../../../../contexts/Form";


export default function EditRules() {
  const { company } = useCompaniesContext();
  const { branch } = useBranchesContext();
  const {checkIsFormDirty, openDialog,  needUpdate, setNeedUpdate } = useFormContext()

  const navigate = useNavigate()
  const {id: ruleId} = useParams<{id?: string}>()
  const {data, updateData, updateField, updateFieldNoRender, errors,updateErrors,changedFieldsRefresh,submit,} = useForm(JSON.parse(JSON.stringify(initForm)));

  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    getRule()
  }, [company, branch])

  
  const getRule = () => {
    if (company && branch) {
      setLoading(true)
      // prefetch dictionaries
      apiClient.dictionaries.rulesTypesLoyaltyPrograms().then();

      apiClient.rulesLoyalty.get(company.id, branch.id, ruleId).then(({data, errors}: any) => {
        if (data) {
          console.log(data)
          updateData(data)
        } else {
          updateErrors(errors || {})
          showErrorToast({
            content: 'Что-то пошло не так'
          })
        }
        setLoading(false)
      }).catch((err) => {
        setLoading(false)
      })
    }
  }

  const reset = () => {
    navigate('/loyalty-programs')
  }

  const removeRule = () => {
    dialogModal({
      isOpen: false,
      title: 'Внимание!',
      text: 'Вы уверены что хотите удалить правило?',
      showCancelButton: true,
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Вернуться',
    }).then((result) => {
      if (result.isConfirmed) {
        apiClient.rulesLoyalty.remove(company.id, branch.id, ruleId)
          .then(({data, errors}: any) => {
            showSuccessToast({
              content: "Правило успешно удалено",
            })
              navigate('/loyalty-programs')
            }
          ).catch((err) => {
          updateErrors(errors || {})
          showErrorToast({
            content: 'Что-то пошло не так'
          })
        })
      }
    }).catch(() => {})
  }

  const handleSubmit = () => {
    submit(async (form: any) => {
      apiClient.rulesLoyalty.update(company?.id, branch?.id, ruleId,{body: form}).then(({data, errors}: any) => {
        if (data) {
          showSuccessToast({
            content: "Правило успешно изменено",
          })
          changedFieldsRefresh()
        } else {
          updateErrors(errors || {});

          showErrorToast({
            content: 'Что-то пошло не так'
          })

        }
      }).catch(() => { }).finally(() => setNeedUpdate(false))
    }, 'changed')

  }

  useEffect(() => {
    if (needUpdate) {
      handleSubmit()
    }
  }, [needUpdate])

  if (loading) return (
    <div className="text-gray-40 text-3xl text-center mt-[35vh]">
      <span>Загрузка...</span>
    </div>
  )

  return (
    <div className="px-8 pb-8 pt-2 text-gray-50">
      <div className="mb-8">
        <div className="text-[44px] leading-[60px] mb-2">Правила</div>
        <Breadcrumbs
          links={[
            {to: `/loyalty-programs`, label: 'Правила'},
            {to: '', label: 'Редактирование правила'},
          ]}
          back={`/loyalty-programs`}
        />
      </div>
      <div className={'rounded-xl bg-white py-5'}>
        <LoyaltyStatusContextWrapper>
          <Base
            errors={errors}
            data={data}
            updateField={updateField}
            updateFieldNoRender={updateFieldNoRender}
          />
        </LoyaltyStatusContextWrapper>

        <div
          className={'mt-6 border-gray-10 border-y-[1px] flex justify-between z-[50] w-full sticky bottom-0 bg-white'}>
          <BaseButton
            className={`bg-red-500 border-red-500 border-[2px] text-white m-4 py-[7px] ${company && branch ? '' : 'hidden'}`}
            onClick={removeRule}>Удалить</BaseButton>
          <div className={'flex flex-row items-center'}>
            <BaseButton
              className={'border-red-500 border-[2px] text-red-500  m-4 py-[7px] '}
              onClick={(e: any) => {
                if (checkIsFormDirty()) {
                  e.preventDefault()
                  openDialog('/loyalty-programs')

                }else reset()
              }}
            >
              Отмена
            </BaseButton>
            <BaseButton
              className={'w-[225px] bg-interactive-elem text-white m-4 py-[7px] '} onClick={handleSubmit}>Сохранить
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  )
  }
