import React, { useEffect, useRef, useState } from "react";
import { ColorPicker, toColor, useColor } from "react-color-palette";
// @ts-ignore
import closeIcon from '../../../../images/close.svg';
import "../../../../styles/colorPickerSelect.sass"

interface IProps {
  options?: Array<any>
  value?: string,
  onChange?: Function
  name?: string,
  defaultColor?: string,
  disabled?: Boolean,
}

export default function ColorSelect({
  options = [],
  value = '#666fff',
  onChange = () => {},
  name,
  defaultColor = '#666fff',
  disabled = false,
  ...props
}: IProps) {
  const [show, setShow] = useState(false);
  const [color, setColor] = useColor("hex", defaultColor);
  const wrapper = useRef<any>(null);

  const onChangeColor = (color) => {
    setColor(color)
    onChange(color.hex || '')
  }
  /* Закрываем при клике вне поля */
  useEffect(() => {
    function handleClick(event: MouseEvent) {
      if (wrapper.current && !wrapper.current.contains(event.target)) {
        setShow(false);
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }

  }, [show, setShow, wrapper]);

  useEffect(() => {
    setColor(toColor('hex', defaultColor))
  }, [defaultColor, setColor])

  return (
    <div className={'relative'}>
      <div
        className="flex gap-1 p-[8px] border-[1px] h-[42px] border-gray-20 rounded-lg w-[50px] cursor-pointer"
        onClick={() => {
          if (!disabled) {
            setShow(show => !show)
          }
        }}
      >
        <div className={`w-full rounded-[20%]`} style={{backgroundColor: color.hex}}></div>
      </div>
      <div className={`fixed grid w-full h-full modal-wrapper ${!show && 'hidden'}`}>
        <div ref={wrapper} className={'grid relative bg-white rounded-[10px] modal'}>
          <div className={'flex justify-between px-[10px] py-[13px] items-center'}>
            <span>Выбор цвета</span>
            <img src={closeIcon} className={'w-[20px]'} onClick={()=>setShow(false)} alt=""/>
          </div>
          <ColorPicker width={300} height={200} color={color} onChange={(color) => onChangeColor(color)} onChangeComplete={(color) => {onChangeColor(color)}} hideHSV alpha={true} />
        </div>
      </div>
    </div>
  )
}
