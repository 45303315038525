import React, {MutableRefObject, useEffect, useMemo, useRef, useState} from 'react';
import BaseButton from "../../../../../components/common/Button/BaseButton";
import {renderField} from "./renderFields";
import Checkbox from "../../../../../components/common/form/checkbox/Checkbox";
import {apiRequest} from "../../../../../libs/api/apiRequest";
import {showErrorToast} from "../../../../../libs/helpers/toasts";

// @ts-ignore
import trashImg from "../../../../../images/delete.svg"

type IInputItems = {
    value: any,
    title: string
}

type IDefinitionInput = {
    name: string,
    updateWhenChanged?: Array<string>,
    updateUrl?: string,
    items?: Array<IInputItems>
}

type IActionDefinition = {
    title: string,
    inputs: Array<IDefinitionInput>,
    isPromoCodeForCopyCanBeAdded?: boolean,
}

type IActionData = {
    body: {
        isPromoCodeForCopyCanBeAdded?: boolean,
        promoCodeForCopy?: any,
    }
}

type IAction = {
    id: string,
    type: string,
    data: IActionData,
    children: Array<IAction>
}

type IUpdateField = (data: IAction, value: any, field: string) => void
type IInputsLoadings = { [key in string]: boolean };

type IProps = {
    definition: IActionDefinition,
    action: IAction
    updateField: IUpdateField,
    customUpdate: Function,
    conditions: any,
    errors: any,
}

type IInputsProps = IProps & {
    loading: IInputsLoadings
}

type IActionBlockProps = IProps & {
    removeBlock: Function,
    removeField: (data: IAction, field: string) => void,
    showCustomSelectModal: (data: IAction, field: string, value: any, updateField: IUpdateField) => void
}

function Inputs({loading, definition, action, conditions, updateField, errors, customUpdate}: IInputsProps) {
    if (definition.inputs?.length <= 0) {
        return (<></>);
    }

    return definition.inputs.map((type: any, index) => (
        <div key={`${action.id}-input-${index}`} className={'w-full'}>
            {renderField(type, action, conditions, updateField, errors, customUpdate, definition)}
        </div>
    ));
}

const buildFieldDependencies = (definition: IActionDefinition): { [key in string]: IDefinitionInput } => {
    let dependencies = {}

    definition.inputs.forEach(function (input) {
        if (undefined === input.updateWhenChanged) {
            return;
        }

        if (!dependencies.hasOwnProperty(input.name)) {
            dependencies[input.name] = input
            return;
        }

        dependencies[input.name].updateWhenChanged = [...dependencies[input.name].updateWhenChanged, ...input.updateWhenChanged];
    });

    return dependencies;
}

const processDependencies = (dependencies: { [key in string]: IDefinitionInput }, action: IAction, previous: MutableRefObject<IAction|undefined>, setLoading: React.Dispatch<React.SetStateAction<IInputsLoadings>>) => {
    Object.entries(dependencies).forEach(function ([_, input]) {
        if (undefined === input.updateWhenChanged) {
            return;
        }

        let query = "";
        let changed = false;

        input.updateWhenChanged.forEach(function (dependency) {
            let value;

            if (undefined === (value = action.data.body[dependency])) {
                return;
            }

            if (previous.current && value === previous.current.data.body[dependency]) {
                return;
            }

            setLoading(prev => ({...prev, [input.name]: true}));
            query += `filter[${dependency}]=${value}`;

            changed = true;
        });

        if (! changed) {
            return;
        }

        apiRequest(`${input.updateUrl}?${query}`)
            .then(({data: items, message, errors}: any) => {
                if (message || errors) {
                    showErrorToast({
                        content: message || 'Что-то пошло не так'
                    })
                    return
                }

                input.items = items;
            })
            .catch((reason: any) => {
                showErrorToast({
                    content: reason || 'Что-то пошло не так'
                })
            })
            .finally(() => {
                setLoading(prev => ({...prev, [input.name]: false}));
            });

        previous.current = action;
    })
}

export default function ActionBlock(props: IActionBlockProps) {
    const {
        definition,
        action,
        conditions,
        errors,
        removeBlock,
        removeField,
        updateField,
        customUpdate,
        showCustomSelectModal
    } = props;

    const [loading, setLoading] = useState<IInputsLoadings>(definition.inputs.reduce((loading, input) => ({...loading, [input.name]: false}), {}));

    const {isPromoCodeForCopyCanBeAdded = false} = definition;
    const {
        isPromoCodeForCopyCanBeAdded: isPromoCodeForCopyCanBeAddedValue = false,
        promoCodeForCopy = ""
    } = action.data.body

    const dependencies = useMemo(
        () => buildFieldDependencies(definition),
        [definition]
    );

    const previousAction = useRef<IAction>();

    useEffect(() => {
        processDependencies(dependencies, action, previousAction, setLoading);
    }, [JSON.stringify(action)]);

    return (
        <div className={'flex flex-col gap-y-4'}>
            <div className={`flex flex-col min-w-[200px] p-2 border-[1px] border-gray-20 rounded-xl gap-y-4`}>
                <div className={'relative flex flex-col items-center gap-y-3'}>
                    <div className={'flex flex-row justify-between items-center w-full'}>
                        <div>{definition.title}</div>

                        <BaseButton
                            onClick={() => removeBlock(action.id)}
                            className={'bg-white border-[1px] h-[42px] !py-0 border-funnels-green rounded-lg'}>
                            <img className={'w-5 h-5'} src={trashImg} alt=""/>
                        </BaseButton>
                    </div>

                    <Inputs loading={loading} definition={definition} action={action} conditions={conditions}
                            updateField={updateField}
                            errors={errors} customUpdate={customUpdate}/>

                    {
                        isPromoCodeForCopyCanBeAdded ?
                            <div className={`w-full flex flex-row flex-wrap`}>
                                <Checkbox
                                    className={'text-sm h-[42px]'}
                                    value={isPromoCodeForCopyCanBeAddedValue}
                                    label={'Добавить копию промокода'}
                                    onChange={(value: any) => {
                                        updateField(action, value, 'isPromoCodeForCopyCanBeAdded')
                                        if (!value) {
                                            removeField(action, 'promoCodeForCopy')
                                        }
                                    }}
                                    name={`${action.id}-isPromoCodeForCopyCanBeAdded`}
                                />
                                {
                                    isPromoCodeForCopyCanBeAddedValue ?
                                        <div
                                            className={`w-full flex flex-row flex-wrap items-center ${(promoCodeForCopy) ? 'gap-x-3' : ''}`}>
                                            <div className={'flex flex-row flex-wrap items-center gap-x-2'}>
                                                {promoCodeForCopy}
                                            </div>
                                            <BaseButton
                                                onClick={() => showCustomSelectModal(action, 'promoCodeForCopy', promoCodeForCopy, updateField)}
                                                className={'py-[7px] bg-interactive-elem text-white cursor-pointer rounded-lg'}>
                                                {(promoCodeForCopy) ? 'Изменить' : 'Выбрать'}
                                            </BaseButton>
                                        </div> : <></>
                                }
                            </div> : <></>
                    }
                </div>
            </div>
        </div>
    );
}