import React, {useEffect, useState} from "react";

import {PromoTagsContextWrapper} from "../../../contexts/Tags";
import initFormBase from "../initFormBase";
import Base from "../CreatePromo/Base";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import BaseButton from "../../../components/common/Button/BaseButton";
import useForm from "../../../hooks/useForm";
import {apiClient} from "../../../libs/api/apiClient";
import {useCompaniesContext} from "../../../contexts/Companies";
import {useBranchesContext} from "../../../contexts/Branches";
import {useNavigate, useParams} from "react-router-dom";
import {ProductsContextWrapper} from "../../../contexts/Products";
import {CategoriesContextWrapper} from "../../../contexts/Categories";
import {showErrorToast, showSuccessToast} from "../../../libs/helpers/toasts";
import {dialogModal} from "../../../components/common/Modal/DialogModal";
import {useFormContext} from "../../../contexts/Form";


export default function EditPromo() {
  const {company} = useCompaniesContext()
  const { branch } = useBranchesContext()
  const {checkIsFormDirty, openDialog,  needUpdate, setNeedUpdate } = useFormContext()

  const {data, updateData, updateField, updateFieldNoRender, errors, updateErrors, changedFieldsRefresh, submit} = useForm(JSON.parse(JSON.stringify(initFormBase)))
  const {id: promoId} = useParams<{id?: string}>()
  const navigate = useNavigate()

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    getPromo()
  }, [company, branch])

  const handleSubmit = () => {
    submit(async (form) => {
      apiClient.promo.update(company?.id, branch?.id, promoId,{body: form}).then(({data, errors}) => {
        if (data) {
          showSuccessToast({
            content: "Промокод успешно изменен",
          })
          changedFieldsRefresh()
          getPromo()
        } else {
          updateErrors(errors || {});

          showErrorToast({
            content: 'Что-то пошло не так'
          })

        }
      }).catch(() => { }).finally(() => setNeedUpdate(false))
    }, 'changed')
  }

  useEffect(() => {
    if (needUpdate) {
      handleSubmit()
    }
  }, [needUpdate])

  const reset = () => {
    navigate('/promo')
  }

  const getPromo = () => {
    if (company && branch) {
      setLoading(true)
      // prefetch dictionaries
      apiClient.dictionaries.platforms().then();
      apiClient.dictionaries.promoCodeTypes().then();
      apiClient.dictionaries.promoCodeStatuses().then();
      apiClient.dictionaries.promoCodeDeliveryTypes().then();

      apiClient.promo.get(company.id, branch.id, promoId).then(({data, errors}) => {
        if (data) {
          updateData(data)
        } else {
          updateErrors(errors || {})
          showErrorToast({
            content: 'Что-то пошло не так'
          })
        }
        setLoading(false)
      }).catch((err) => {
        setLoading(false)
      })
    }
  }

  const handleRemove = () => {
    dialogModal({
      isOpen: false,
      title: 'Внимание!',
      text: 'Вы уверены что хотите удалить промокод?',
      showCancelButton: true,
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Вернуться',
    }).then((result) => {
      if (result.isConfirmed) {
        apiClient.promo.remove(company.id, branch.id, promoId)
          .then(() => {
            showSuccessToast({
              content: "Промокод успешно удален",
            })
            navigate('/promo')
          }).catch((err) => {
            updateErrors(errors || {})
            //updateData(company)
          showErrorToast({
            content: 'Что-то пошло не так'
          })
        })
      }
    }).catch(() => {})
  }

  if (!branch) return (
    <div className="text-gray-40 text-3xl text-center mt-[35vh]">
      <span>Выберите филиал</span>
    </div>
  )

  if (loading) return (
    <div className="text-gray-40 text-3xl text-center mt-[35vh]">
      <span>Загрузка...</span>
    </div>
  )

  return (
    <>
      <div className='px-8 pb-8 pt-2 text-gray-50'>
        <div className='mb-8'>
          <div className='text-[44px] leading-[60px] mb-2'>Промокоды</div>
          <Breadcrumbs
            links={[
              {to: `/promo`, label: 'Список промокодов'},
              {to: '', label: 'Редактирование промокода'},
            ]}

            back={`/promo`}
          />
        </div>
        <div className={'rounded-xl bg-white py-5'}>
          <ProductsContextWrapper>
            <CategoriesContextWrapper>
              <PromoTagsContextWrapper>
                <Base errors={errors} data={data} updateField={updateField} updateFieldNoRender={updateFieldNoRender} update={true}/>
              </PromoTagsContextWrapper>
            </CategoriesContextWrapper>
          </ProductsContextWrapper>
          <div className={'mt-6 border-gray-10 border-y-[1px] flex justify-between z-[50] w-full sticky bottom-0 bg-white'}>
            <BaseButton
              className={'w-[225px] bg-error-main text-white m-4 py-[7px] '} onClick={handleRemove}>Удалить промокод
            </BaseButton>
            <div className={'flex flex-row'}>
              <BaseButton
                className={'border-error-main border-2 text-error-main m-4 py-[7px] '}
                onClick={(e: any) => {
                  if (checkIsFormDirty()) {
                    e.preventDefault()
                    openDialog('/promo')

                  }else reset()
                }}
              >
                Отмена
              </BaseButton>
              <BaseButton
                className={'w-[225px] bg-interactive-elem text-white m-4 py-[7px] '} onClick={handleSubmit}>Сохранить промокод
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
