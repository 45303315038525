import React from "react";
import Search from "./Search";

//компонент для поисковой строки + дополнительных кнопок, селектов и т.д
export default function Actions(
  {
    children,
    searchValue = '',
    classNameSearch = 'w-1/2',
    classNameChildren = 'w-1/2',
    updateValue = (value: any) => {},
    fetchData = (value: any) => {},
    showSearch = true,
    classNameMarginSearch = 'mt-8',
  }: any
) {

  return (
    <div className={`flex flex-row items-center justify-between ${classNameMarginSearch}`}>
      <div className={classNameSearch}>
        {
          showSearch &&
            <Search value={searchValue}
                    disabled={false}
                    placeholder={'Поиск'}
                    updateValue={(value: any) => updateValue(value)}
                    fetchData={(value: any) => fetchData(value)}
            />
        }
      </div>
      <div className={classNameChildren}>
        {children}
      </div>
    </div>
  )
}
