import moment, { Moment } from "moment"


export function getDate(datetime: string) {
  return transformDate(datetime).format('DD.MM.YYYY')
}

export function getDateAndTime(datetime: string, format = 'DD.MM.YYYY HH:mm:ss') {
  return transformDate(datetime).format(format)
}

export function getTime(datetime:string, format = '') {
  return transformDate(datetime).format('HH:mm:ss')
}

export const getDayAndFullMonth = (receivedDate: Date) => {
  return new Intl.DateTimeFormat("ru-RU", {
    day: "2-digit",
    month: "long",
    year: "numeric",
    weekday: "long"
  }).format(receivedDate)
}

const transformDate = (datetime: string): Moment => {
  const date = moment(datetime)
  const utcOffset = moment.parseZone(datetime).utcOffset() || 0

  return date.utcOffset(utcOffset, false)
}