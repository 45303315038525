import React, {useEffect, useRef, useState} from "react";
import Tooltip from "../../Tooltip";
import TagLabel from "../../form/select/datatable/tags/TagLabel";
import BaseButton from "../../Button/BaseButton";
import DataTableModal from "../../form/select/datatable/DataTableModal";
// @ts-ignore
import closeIcon from "../../../../images/greenClose.svg";


export function TagsFilter(
  {
    children,
    data,
    errors,
    errorLabel = true,
    label,
    className = '',
    required = false,
    textTooltip = '',
    name,
    loading = false,
    placeholder,
    values = [],
    updateValues,
    updateData,
    columns,
    buttonPlaceholder = 'Изменить метки',
    innerPadding = 'py-[20px] px-[14px]',
    removeItem = (index: any) => {},
  }: any) {
  const [show, setShow] = useState(false);
  const wrapper = useRef<any>(null);
  const error = errors[name];

  useEffect(() => {
    delete errors[name];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  /* Закрываем при клике вне поля */
  useEffect(() => {
    function handleClick(event: any) {
      if (wrapper.current && !wrapper.current.contains(event.target)) {
        setShow(false);
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }

  }, [show, setShow, wrapper]);

  return (
    <div className={`${className}`}>
      {
        label &&
          <label className="flex items-center mb-2 font-medium text-sm">
            {label}
              <span className='text-error-main'>{required && '*'}</span>
            {
              textTooltip && <Tooltip textTooltip={textTooltip} defaultText={name}/>
            }
          </label>
      }
      <div ref={wrapper} className={'relative w-full max-w-full'}>
        <div className={`flex items-center justify-between w-full text-sm bg-white border-gray-20 border-[1px] rounded-2xl ${innerPadding}`}>
          <div className="flex gap-2 items-center mr-3 overflow-x-auto">
            <div className={'flex flex-row items-center'}>
              {
                values.length ? values?.map((value: any, index: any) => (
                    <div className="flex flex-row items-center p-3">
                      <TagLabel
                        backgroundColor={data.find((item: any) => item.id === value)?.background}
                        textColor={data.find((item: any) => item.id === value)?.color}
                        marginTags={'my-0 mr-2'}
                      >
                        {data.find((item: any) => item.id === value)?.text}
                      </TagLabel>
                      <img src={closeIcon} alt="" className="w-[16px] h-[16px] cursor-pointer" onClick={() => {
                        removeItem(index)
                      }}/>
                    </div>
                  )) :
                  <span className="text-gray-20">{placeholder}</span>
              }

            </div>
          </div>
          <BaseButton
            className={'bg-white border-interactive-elem border-[2px] text-interactive-elem mr-[10px] py-[7px] '}
            onClick={() => setShow(show => !show)}>
            {buttonPlaceholder}
          </BaseButton>

        </div>

        <DataTableModal
          title={placeholder}
          setShow={setShow}
          show={show}
          data={data}
          loading={loading}
          columns={columns}
          updateData={updateData}
          selectedRows={values}
          showSearch={false}
          onSubmit={(rowSelection: any) => {
            updateValues(rowSelection)
          }}
          buttonPlaceholder={'Сохранить'}
        />
      </div>
      {error && errorLabel && (<span className={'text-error-font text-xs font-medium'}>{error}</span>)}
    </div>
  )
}
