import {apiClient} from "../../libs/api/apiClient";
import {showErrorToast} from "../../libs/helpers/toasts";

enum ActionEnum {
  detach = 'detach',
  attach = 'attach',
  activate = 'activate',
  deactivate = 'deactivate',
  copy = 'copy',
  delete = 'delete',
}

enum Tabs {
  promoCodes = 'promoCodes',

}

export const submitAction = (callback: any, action: string, tab: string, companyId: any, branchId: any, data: any) => {
  switch (tab) {
    case Tabs.promoCodes:
      switch (action) {
        case ActionEnum.attach:
          bulkPromoCodesAttach(callback, companyId, branchId, data)
          break
        case ActionEnum.detach:
          bulkPromoCodesDetach(callback, companyId, branchId, data)
          break
        case ActionEnum.activate:
          bulkPromoCodesActivate(callback, companyId, branchId, data)
          break
        case ActionEnum.deactivate:
          bulkPromoCodesDeactivate(callback, companyId, branchId, data)
          break
        case ActionEnum.copy:
          bulkPromoCodesCopy(callback, companyId, branchId, data)
          break
        case ActionEnum.delete:
          //bulkPromoCodesDelete(callback, companyId, branchId, data)
          break
        default:
          break
      }
      break
    default:
      break
  }
}

const bulkPromoCodesAttach = (callback: any, companyId: any, branchId: any, data: any) => {
  apiClient.promo.bulkAttach(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      } else {
        callback()
      }
    })
    .catch(() => {
      showErrorToast({
        content: 'Что-то пошло не так'
      })
    })
}

const bulkPromoCodesDetach = (callback: any, companyId: any, branchId: any, data: any) => {
  apiClient.promo.bulkDetach(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      } else {
        callback()
      }
    })
    .catch(() => {
      showErrorToast({
        content: 'Что-то пошло не так'
      })
    })
}


const bulkPromoCodesActivate = (callback: any, companyId: any, branchId: any, data: any) => {
  apiClient.promo.bulkActivate(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      } else {
        callback()
      }
    })
    .catch(() => {
      showErrorToast({
        content: 'Что-то пошло не так'
      })
    })
}


const bulkPromoCodesDeactivate = (callback: any, companyId: any, branchId: any, data: any) => {
  apiClient.promo.bulkDeactivate(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      } else {
        callback()
      }
    })
    .catch(() => {
      showErrorToast({
        content: 'Что-то пошло не так'
      })
    })
}

const bulkPromoCodesCopy = (callback: any, companyId: any, branchId: any, data: any) => {
  apiClient.promo.bulkCopy(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      } else {
        callback()
      }
    })
    .catch(() => {
      showErrorToast({
        content: 'Что-то пошло не так'
      })
    })
}

const bulkPromoCodesDelete = (callback: any, companyId: any, branchId: any, data: any) => {
  /*apiClient.promo.bulkDelete(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      } else {
        callback()
      }
    })
    .catch(() => {
      showErrorToast({
        content: 'Что-то пошло не так'
      })
    })*/
}
