import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import FormWrapper from "../../../components/common/form/FormWrapper";
import tabs from "./tabs";
import CreateFormFields from "../../../components/common/form/CreateFormFields";
import {useCompaniesContext} from "../../../contexts/Companies";
import {useBranchesContext} from "../../../contexts/Branches";
import useForm from "../../../hooks/useForm";
import initForm from "../initForm";
import Base from "../forms/Base";
import Clients from "../forms/Clients";
import fetchPlatforms from "../../../providers/dictionaries/platforms";
import {apiClient} from "../../../libs/api/apiClient";
import {useNavigate} from "react-router-dom";
import {CustomersTagsContextWrapper} from "../../../contexts/Tags";
import {showErrorToast, showSuccessToast} from "../../../libs/helpers/toasts";

export default function CreateHistory() {
  const {company}: any = useCompaniesContext()
  const {branch}: any = useBranchesContext()
  const {data, updateField, updateFieldNoRender, errors, updateErrors, submit} = useForm(JSON.parse(JSON.stringify(initForm)))
  const [tab, setTab] = useState('base')
  const [platforms, setPlatforms] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleSubmit = () => {
    submit(async (form: any) => {
      setLoading(true)
      apiClient.histories.create(company?.id, branch?.id, {body: form}).then(({data, errors}: any) => {
        if (data) {
          showSuccessToast({
            content: "История успешно создана"
          })
          navigate('/histories')
        } else {
          updateErrors(errors || {});
          checkErrors(errors, true)

          showErrorToast({
            content: 'Что-то пошло не так'
          })

        }
      }).catch((e) => {console.log(e)})
        .finally(() => {
        setLoading(false)
      })
    })
  }

  useEffect(() => {
    fetchPlatforms().then((data) => {
      setPlatforms(data.map(({label, value}: any) => {return {label: label, name: value}}))
    })
  }, [])

  // Выглядит как срань, ею и является
  // Если придумаешь вариант получше отыскать на каком табе ошибка, то флаг в руки
  const [tabsHasError, setTabsHasError] = useState<any>([])

  useEffect(() => {
    const interval = setInterval(() => {
      if ( Object.keys(errors).length > 0) {
        checkErrors(errors)
      } else if (tabsHasError.length > 0) {
        setTabsHasError([])
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [JSON.stringify(errors)]);

  const checkErrors = (err=errors, tab = false) => {
    let tabsError: any = []
    let errorsName = Object.keys(err)

    tabs.map(elem => {
      if (elem?.fields) {
        let hasError = false
        for (let i = 0; i < elem.fields.length; i++) {
          let fieldName = elem.fields[i];
          for (let j = 0; j < errorsName.length; j++) {
            let error = errorsName[j]

            if (error.includes(fieldName) && error.startsWith(fieldName)) {
              hasError = true
              break
            }
          }

          if (hasError) {
            break
          }
        }

        if (hasError) {
          if (tab && !tabsError.length) {
            setTab(elem.tag)
          }
          tabsError.push(elem.tag)
        }
      }
    })

    setTabsHasError(tabsError)
  }

  return (
    <div className='px-8 pb-8 pt-2 text-gray-50'>
      <div className='mb-8'>
        <div className='text-[44px] leading-[60px] mb-2'>Истории</div>
        <Breadcrumbs
          links={[
            {to: `/histories`, label: 'Список историй'},
            {to: '', label: 'Создание истории'},
          ]}
          back={`/histories`}
        />
      </div>
      <div className={'rounded-xl bg-white py-5'}>
        <FormWrapper
          tabs={tabs}
          tab={tab}
          setTab={setTab}
          tabsHasError={tabsHasError}
        >
          <CreateFormFields
            show={tab === 'base'}
            nextOption='clients'
            setTab={setTab}
            submitLoading={loading}
          >
            <Base data={data} updateField={updateField} updateFieldNoRender={updateFieldNoRender} errors={errors} platforms={platforms}/>
          </CreateFormFields>
          <CreateFormFields
            show={tab === 'clients'}
            setTab={setTab}
            onSubmit={handleSubmit}
            submitLabel='Создать историю'
            submitLoading={loading}
          >
            <CustomersTagsContextWrapper>
              <Clients data={data} updateField={updateField} updateFieldNoRender={updateFieldNoRender} errors={errors} tagsArray={[]}/>
            </CustomersTagsContextWrapper>
          </CreateFormFields>

        </FormWrapper>
      </div>
    </div>
  )
}
