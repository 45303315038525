import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { dialogModal } from "../components/common/Modal/DialogModal";

interface FormContextType {
    needUpdate: boolean
    navigate: any
    setNeedUpdate: any
    openDialog: any,
    checkIsFormDirty: () => boolean,
    setIsFormDirtyFunc: (x: () => boolean) => void
}

const FormContext = createContext<FormContextType>({
    checkIsFormDirty: () => false,
    setIsFormDirtyFunc: () => {},
    needUpdate: false,
    navigate: (value: any) => { },
    setNeedUpdate: (value: boolean) => { },
    openDialog: (url: any) => {}
});

export function FormContextWrapper({ children }: any) {
    const [needUpdate, setNeedUpdate] = useState(false)
    const navigate = useNavigate()
    const location = useLocation();

    useEffect(() => {
        setNeedUpdate(false)
    }, [location]);
    
    /*useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        
        return () => {
            window.removeEventListener('beforeunload', alertUser)
        }
    }, [])

    const alertUser = e => {
        if (checkIsFormDirty()) {
            e.preventDefault()
            e.returnValue = ''
        }
    }*/

    const openDialog = (url: any) => {
        dialogModal({
            isOpen: false,
            title: 'Не сохраненные данные!',
            text: 'Вы действительно хотите перейти? Все не сохраненные данные будут удалены',
            showDenyButton: true,
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonText: 'Сохранить и продолжить',
            denyButtonText: `Без сохранения`,
            cancelButtonText: 'Вернуться',
        }).then((result) => {
            if (result.isConfirmed) {
                setNeedUpdate(true)
            } else if (result.isDenied) {
                navigate(url)
            }
        }).catch(() => { })
    }

    const [checkIsFormDirty, setCheckIsFormDirty] = useState(() => () => false)
    function setIsFormDirtyFunc(f: () => boolean) {
        setCheckIsFormDirty(() => f)
    }

    return (
        <FormContext.Provider value={{ checkIsFormDirty, setIsFormDirtyFunc, openDialog, needUpdate, setNeedUpdate, navigate }}>
            {children}
        </FormContext.Provider>
    )
}

export function useFormContext() {
    return useContext(FormContext)
}
