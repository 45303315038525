import React, { useEffect, useState } from "react";
import useForm from "../../../hooks/useForm";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import TextInput from "../../../components/common/form/input/TextInput";
import { useBranchesContext } from "../../../contexts/Branches";
import BaseButton from "../../../components/common/Button/BaseButton";
import SimpleMultipleSelect from "../../../components/common/form/select/multiple/SimpleMultipleSelect";
import { useCompaniesContext } from "../../../contexts/Companies";
import { apiClient } from "../../../libs/api/apiClient";

import { useNavigate } from "react-router-dom";
import Checkbox from "../../../components/common/form/checkbox/Checkbox";
import { showErrorToast } from "../../../libs/helpers/toasts";

export default function CreateEmployee() {
  const { branches } = useBranchesContext();
  const { company } = useCompaniesContext();
  const [branchesSelectData, setBranchesSelectData] = useState([]);
  const [loading, setLoading] = useState(false)
  const { data, updateField, errors, updateErrors, submit } =
    useForm({
      //name: '',
      userId: "",
      vkId: "",
      __companyEdit: false,
      __branchEdit__select: [],
      __companyEdit__select: [],
      __catalog__select: [],
      __order__select: [],
      __showOrder__select: [],
      __ipl__select: [],
      __clients__select: [],
      __reports__select: [],
      __promoCodes__select: [],
      __cartRules__select: [],
      __events__select: [],
      __distributions__select: [],
      __loyaltyProgram__select: [],
      __tags__select: [],
      __histories__select: [],
    });

  const navigate = useNavigate();

  const onSubmit = () => {
    prepareForm();
    submit(async (form: any) => {
      setLoading(true)
      apiClient.employees
        .create(company.id, { body: form })
        .then(({ data, message, errors }: any) => {
          if (errors && message) {
            updateErrors(errors || {});

            showErrorToast({
              content: "Что-то пошло не так",
            });
          } else {
            navigate("/employees");
          }
        }).catch(() => {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      }).finally(() => setLoading(false))
    });
  };

  const prepareForm = () => {
    let permissions = [];
    if (data.__permissionsForEmployee) {
      Object.entries(data?.__permissionsForEmployee).map((key: any, index: any) => {
        if (typeof key[1] === "object") {
          key[1]?.forEach((branchId: any) => {
            let action = "*";
            if (key[0] === "showOrder") {
              action = "view";
            }
            permissions.push(
              `company.${company.id}.branch.${branchId}.${
                action === "view" ? "orders" : key[0]
              }.${action}`
            );
            //updateField(`permissions.company.${company.id}.branches.${branchId}.${key[0]}.${action}`, true);
          });
        } else if (typeof key[1] === "string") {
          branches.forEach((branch: any) => {
            let action = "*";
            if (key[0] === "showOrder") {
              action = "view";
            }
            permissions.push(
              `company.${company.id}.branch.${branch.id}.${
                action === "view" ? "orders" : key[0]
              }.${action}`
            );
            //updateField(`permissions.company.${company.id}.branches.${branch.id}.${key[0]}.${action}`, true)
          });
        }
      });
      //console.log(permissions)
      if (data?.__permissionsForEmployee?.branchEdit) {
        if (data?.__permissionsForEmployee?.branchEdit === "*") {
          branchesSelectData.forEach((item: any) => {
            permissions.push(`company.${company.id}.branch.${item.value}.view`);
            permissions.push(
              `company.${company.id}.branch.${item.value}.update`
            );
          });
        } else if (
          typeof data?.__permissionsForEmployee?.branchEdit === "object"
        ) {
          data?.__permissionsForEmployee?.branchEdit.forEach(
            (branchId: any) => {
              permissions.push(`company.${company.id}.branch.${branchId}.view`);
              permissions.push(
                `company.${company.id}.branch.${branchId}.update`
              );
            }
          );
        }
      }
    }

    if (data.__companyEdit) {
      permissions.push(`company.${company.id}.update`);
      permissions.push(`company.${company.id}.view`);
    }

    if (permissions.length > 0) {
      updateField("permissions", permissions);
    }
  };

  useEffect(() => {
    const branchesData = branches.map((branch: any) => {
      return {
        label: branch.title,
        value: branch.id,
      };
    });
    setBranchesSelectData(branchesData);
  }, [branches.map(({ id }: any) => id).join(",")]);

  return (
    <>
      <div className="px-8 pb-8 pt-3 text-gray-50">
        <div className="">
          <div className="text-[44px] leading-[60px] mb-2">Сотрудники</div>
          <Breadcrumbs
            links={[
              { to: "/employees", label: "Список сотрудников" },
              { to: "", label: "Добавление сотрудника" },
            ]}
            back="/employees"
          />
        </div>
        <div className="flex py-3 mt-3 h-fit bg-white rounded-2xl">
          <div
            className={`w-full pl-6 pr-3 py-2 flex flex-col gap-y-4 justify-between`}
          >
            <div className={`flex flex-col gap-y-4`}>
              {/*<TextInput
                errors={errors}
                value={data.name}
                label={'Имя сотрудника'}
                name={'name'}
                onChange={(value) => {updateField('name', value)}}
                placeholder={'Введите имя сотрудника'}
                textTooltip={'Имя сотрудника'}
              />*/}

              <TextInput
                errors={errors}
                value={data.userId}
                label={"ID сотрудника в Result.Rest"}
                name={"userId"}
                onChange={(value: any) => {
                  updateField("userId", value);
                }}
                placeholder={"Введите ID"}
                textTooltip={"ID сотрудника в Result.Rest"}
              />

              <TextInput
                errors={errors}
                value={data.vkId}
                label={"ID пользователя VK"}
                name={"vkId"}
                onChange={(value: any) => {
                  updateField("vkId", value);
                }}
                placeholder={"Введите vk id"}
                textTooltip={"ID пользователя VK"}
              />

              <div
                className={`border-[1px] border-gray-20 rounded-xl p-4 mt-4 ${
                  errors["permissions"] ? "border-red-500" : ""
                }`}
              >
                {errors["permissions"] && (
                  <span className={"text-error-font text-xs font-medium mb-2"}>
                    {errors["permissions"]}
                  </span>
                )}
                <div className={"text-[22px] font-semibold mb-4"}>
                  Возможности
                </div>
                {branchesSelectData.length === 0 ? (
                  <div className={"text-center"}>
                    Загрузка информации о филиалах...
                  </div>
                ) : (
                  <div className={"flex flex-col gap-4  h-fit"}>
                    <Checkbox
                      label={"Редактирование компании"}
                      onChange={(value: any) => {
                        updateField("__companyEdit", value);
                      }}
                      disabled={false}
                      errors={errors}
                      name={"__companyEdit"}
                      value={data.__companyEdit}
                    />

                    <SimpleMultipleSelect
                      label={"Редактирование филиалов"}
                      options={branchesSelectData}
                      value={data.__branchEdit__select}
                      onChange={(value: any) => {
                        delete errors["permissions"];
                        updateField("__branchEdit__select", value);
                        updateField(
                          "__permissionsForEmployee.branchEdit",
                          value === "*"
                            ? value
                            : value.map((item: any) => item.value)
                        );
                      }}
                      name={"catalog"}
                      placeholder={"Выберите филиалы"}
                      errors={errors}
                      textTooltip={"Редактирование филиалов"}
                    />

                    <SimpleMultipleSelect
                      label={"Каталог"}
                      options={branchesSelectData}
                      value={data.__catalog__select}
                      onChange={(value: any) => {
                        delete errors["permissions"];
                        updateField("__catalog__select", value);
                        updateField(
                          "__permissionsForEmployee.catalog",
                          value === "*"
                            ? value
                            : value.map((item: any) => item.value)
                        );
                      }}
                      name={"catalog"}
                      placeholder={"Выберите филиалы"}
                      errors={errors}
                      textTooltip={"Каталог"}
                    />

                    <SimpleMultipleSelect
                      label={"Заказы"}
                      options={branchesSelectData}
                      value={data.__order__select}
                      onChange={(value: any) => {
                        delete errors["permissions"];
                        updateField("__order__select", value);
                        updateField(
                          "__permissionsForEmployee.orders",
                          value === "*"
                            ? value
                            : value.map((item: any) => item.value)
                        );
                      }}
                      name={"order"}
                      placeholder={"Выберите филиалы"}
                      errors={errors}
                      textTooltip={"Заказы"}
                    />

                    <SimpleMultipleSelect
                      label={"Заказы (только просмотр)"}
                      options={branchesSelectData}
                      value={data.__showOrder__select}
                      onChange={(value: any) => {
                        delete errors["permissions"];
                        updateField("__showOrder__select", value);
                        updateField(
                          "__permissionsForEmployee.showOrder",
                          value === "*"
                            ? value
                            : value.map((item: any) => item.value)
                        );
                      }}
                      name={"showOrder"}
                      placeholder={"Выберите филиалы"}
                      errors={errors}
                      textTooltip={"Заказы (только просмотр)"}
                    />

                    <SimpleMultipleSelect
                      label={"Клиенты"}
                      options={branchesSelectData}
                      value={data.__clients__select}
                      onChange={(value: any) => {
                        delete errors["permissions"];
                        updateField("__clients__select", value);
                        updateField(
                          "__permissionsForEmployee.clients",
                          value === "*"
                            ? value
                            : value.map((item: any) => item.value)
                        );
                      }}
                      name={"clients"}
                      placeholder={"Выберите филиалы"}
                      errors={errors}
                      textTooltip={"Клиенты"}
                    />

                    <SimpleMultipleSelect
                      label={"NPS"}
                      options={branchesSelectData}
                      value={data.__ipl__select}
                      onChange={(value: any) => {
                        delete errors["permissions"];
                        updateField("__ipl__select", value);
                        updateField(
                          "__permissionsForEmployee.consumerLoyaltyIndex",
                          value === "*"
                            ? value
                            : value.map((item: any) => item.value)
                        );
                      }}
                      name={"ipl"}
                      placeholder={"Выберите филиалы"}
                      errors={errors}
                      textTooltip={"NPS"}
                    />

                    <SimpleMultipleSelect
                      label={"Истории"}
                      options={branchesSelectData}
                      value={data.__histories__select}
                      onChange={(value: any) => {
                        delete errors["permissions"];
                        updateField("__histories__select", value);
                        updateField(
                          "__permissionsForEmployee.histories",
                          value === "*"
                            ? value
                            : value.map((item: any) => item.value)
                        );
                      }}
                      name={"histories"}
                      placeholder={"Выберите филиалы"}
                      errors={errors}
                      textTooltip={"Истории"}
                    />

                    <SimpleMultipleSelect
                      label={"Акции и Промокоды"}
                      options={branchesSelectData}
                      value={data.__promoCodes__select}
                      onChange={(value: any) => {
                        delete errors["permissions"];
                        updateField("__promoCodes__select", value);
                        updateField(
                          "__permissionsForEmployee.offers",
                          value === "*"
                            ? value
                            : value.map((item: any) => item.value)
                        );
                      }}
                      name={"promoCodes"}
                      placeholder={"Выберите филиалы"}
                      errors={errors}
                      textTooltip={"Акции и Промокоды"}
                    />

                    <SimpleMultipleSelect
                      label={"Воронки"}
                      options={branchesSelectData}
                      value={data.__events__select}
                      onChange={(value: any) => {
                        delete errors["permissions"];
                        updateField("__events__select", value);
                        updateField(
                          "__permissionsForEmployee.events",
                          value === "*"
                            ? value
                            : value.map((item: any) => item.value)
                        );
                      }}
                      name={"events"}
                      placeholder={"Выберите филиалы"}
                      errors={errors}
                      textTooltip={"Воронки"}
                    />

                    <SimpleMultipleSelect
                      label={"Рассылки"}
                      options={branchesSelectData}
                      value={data.__distributions__select}
                      onChange={(value: any) => {
                        delete errors["permissions"];
                        updateField("__distributions__select", value);
                        updateField(
                          "__permissionsForEmployee.distributions",
                          value === "*"
                            ? value
                            : value.map((item: any) => item.value)
                        );
                      }}
                      name={"distributions"}
                      placeholder={"Выберите филиалы"}
                      errors={errors}
                      textTooltip={"Рассылки"}
                    />

                    <SimpleMultipleSelect
                      label={"Программа лояльности / Бонусная система"}
                      options={branchesSelectData}
                      value={data.__loyaltyProgram__select}
                      onChange={(value: any) => {
                        delete errors["permissions"];
                        updateField("__loyaltyProgram__select", value);
                        updateField(
                          "__permissionsForEmployee.loyaltyProgram",
                          value === "*"
                            ? value
                            : value.map((item: any) => item.value)
                        );
                      }}
                      name={"loyaltyProgram"}
                      placeholder={"Выберите филиалы"}
                      errors={errors}
                      textTooltip={"Программа лояльности / Бонусная система"}
                    />

                    <SimpleMultipleSelect
                      label={"Метки"}
                      options={branchesSelectData}
                      value={data.__tags__select}
                      onChange={(value: any) => {
                        delete errors["permissions"];
                        updateField("__tags__select", value);
                        updateField(
                          "__permissionsForEmployee.labels",
                          value === "*"
                            ? value
                            : value.map((item: any) => item.value)
                        );
                      }}
                      name={"tags"}
                      placeholder={"Выберите филиалы"}
                      errors={errors}
                      textTooltip={"Метки"}
                    />

                    <SimpleMultipleSelect
                      label={"Отчеты"}
                      options={branchesSelectData}
                      value={data.__reports__select}
                      onChange={(value: any) => {
                        delete errors["permissions"];
                        updateField("__reports__select", value);
                        updateField(
                          "__permissionsForEmployee.reports",
                          value === "*"
                            ? value
                            : value.map((item: any) => item.value)
                        );
                      }}
                      name={"reports"}
                      placeholder={"Выберите филиалы"}
                      errors={errors}
                      textTooltip={"Отчеты"}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className={"mt-6 border-gray-10 border-y-[1px] flex justify-end z-[50] w-full sticky bottom-0 bg-white"}>
              <BaseButton
                disabled={loading}
                className={`w-[225px] bg-interactive-elem text-white m-4 py-[7px] ${loading && 'bg-interactive-text'}`}
                onClick={onSubmit}>
                {
                  loading ?
                    <div className={'flex flex-row items-center justify-center w-[120px]'}>
                      <svg
                        className="animate-spin h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </div>
                    : "Добавить сотрудника"
                }
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
