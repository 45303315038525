import React from "react";
import TextInput from "../../../components/common/form/input/TextInput";
import Checkbox from "../../../components/common/form/checkbox/Checkbox";
import DateTimePicker from "../../../components/common/form/datetime/DateTimePicker";
import CheckboxList from "../../../components/common/form/checkbox/CheckboxList";
import MediaLoad from "../../../components/common/form/media/MediaLoad";
import ImageLoad from "../../../components/common/form/image/ImageLoad";
import SimpleMultipleValueSelect from "../../../components/common/form/select/multiple/SimpleMultipleValueSelect";
import { useBranchesContext } from "../../../contexts/Branches";

export default function Base({data, updateField, errors, platforms, updateFieldNoRender}: any) {
  const { branches } = useBranchesContext()


  return (
    <div className={'flex flex-col px-3 gap-y-6'}>
      <TextInput
        label={'Заголовок'}
        textTooltip={'Заголовок'}
        placeholder={'Введите заголовок'}
        required={true}
        errors={errors}
        value={data.title}
        onChange={(value: any) => updateFieldNoRender('title', value)}
        name={'title'}
      />

      <ImageLoad
        label='Превью (формат: JPG, JPEG, PNG)'
        errors={errors}
        name={'preview'}
        defaultValue={data.preview}
        onInput={(value: any) => {
          if (typeof value !== 'string') {
            if (value != null) {
              updateField('preview', value)
            }
          }
        }}
        forceAspectRatio={0.785}
        resizeTo={[-1, 600]}
      />

      <MediaLoad
        label='Медиа-контент (Можно загрузить изображение или видео)'
        onInput={(value: any) => {
          //console.log(`image value = ${value}`)
          if (typeof value !== "string") {
            if (value != null) {
              updateField('media', value)
            }
          }
        }}
        onRemove={() => {
          updateField('media', null)
          console.log('remove')
        }}
        name={'media'}
        errors={errors}
        defaultValue={data.media}
        required={true}
        className={''}
        classPlaceholderBox={''}
      />

      <Checkbox
        label={'Добавить ссылку'}
        name={'hasLink'}
        onChange={(value: any) => updateField('hasLink', value)}
        value={data.hasLink}
        errors={errors}
        required={false}
      />

      {
        data.hasLink ?
          <div className={'flex flex-col gap-y-6'}>
            <TextInput
              label={'Текст ссылки'}
              textTooltip={'Текст ссылки'}
              placeholder={'Введите текст'}
              required={true}
              errors={errors}
              value={data.textLink}
              onChange={(value: any) => updateFieldNoRender('textLink', value)}
              name={'textLink'}
            />

            <TextInput
              label={'URL ссылки'}
              textTooltip={'URL ссылки'}
              placeholder={'Введите url'}
              required={true}
              errors={errors}
              value={data.url}
              onChange={(value: any) => updateFieldNoRender('url', value)}
              name={'url'}
            />
          </div> : <></>
      }

      <DateTimePicker
        value={data.startPublishedAt || null}
        onChange={(date: Date) => {
          updateField('startPublishedAt', date ? date : null)
        }}
        errors={errors}
        name={'startPublishedAt'}
        label={'Дата и время начала отображения'}
        textTooltip={'Дата и время начала отображения'}
        required={false}
        placeholder={'Выберите дату и время'}
      />

      <DateTimePicker
        value={data.endPublishedAt || null}
        onChange={(date: Date) => {
          updateField('endPublishedAt', date ? date : null)
        }}
        errors={errors}
        name={'endPublishedAt'}
        label={'Дата и время окончания отображения'}
        textTooltip={'Дата и время окончания отображения'}
        required={false}
        placeholder={'Выберите дату и время'}
      />

      <TextInput
        type={'number'}
        min={'0'}
        label={'Сортировка'}
        textTooltip={'Сортировка'}
        placeholder={'500'}
        name={'position'}
        value={data.position}
        onChange={(value: any) => updateFieldNoRender('position', value)}
        errors={errors}
      />

      <SimpleMultipleValueSelect
        label={'Филиалы'}
        textTooltip={'Филиалы'}
        options={branches.map((branch: any) => ({ label: branch.title, value: branch.id }))}
        value={data.branches || []}
        onChange={(value: any) => updateField('branches', value)}
        name={'branches'}
        placeholder={'Выберите филиалы'}
        errors={errors}
      />

      <Checkbox
        label={'Закрепить историю'}
        name={'fixed'}
        onChange={(value: any) => updateField('fixed', value)}
        value={data.fixed}
        errors={errors}
        required={false}
      />

      <CheckboxList
        label='Платформы'
        name='platforms'
        errors={errors}
        options={platforms}
        values={data.platforms || []}
        onChange={(value: any) => {
          updateField('platforms', value)
        }}
        required={false}
      />
    </div>
  )
}
