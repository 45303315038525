import ResponseType from "../../types/response";
import {apiRequest, dictionaryApiRequest, publicApiRequest} from "./apiRequest"

export const apiClient = {
  auth: {
    login: (data: object): Promise<ResponseType|string|void> => apiRequest('/api/auth/login', {method: "POST", ...data}),
    logout: (): Promise<ResponseType|string|void> => apiRequest('/api/auth/logout'),
    me: (): Promise<ResponseType|string|void> => apiRequest('/api/users/me'),
    permissions: (): Promise<ResponseType|string|void> => apiRequest('/api/users/permissions'),
  },
  companies: {
    getAll: (queryParams: string): Promise<ResponseType|string|void> => apiRequest(`/api/companies${queryParams}`),
    get: (id: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${id}`),
    clearCache: (id: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${id}/cache/clear`),
    create: (data: object): Promise<ResponseType|string|void> => apiRequest('/api/companies', {method: "POST", ...data}),
    update: (id: string, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${id}`, {method: "POST", ...data}),
    remove: (id: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${id}`, {method: "DELETE"}),
  },
  branches: {
    getAll: (companyId: bigint, queryParams: string): Promise<ResponseType|string|void> => apiRequest(`/api/companies/${companyId}/branches${queryParams}`),
    get:(companyId: bigint, branchId: bigint): Promise<ResponseType|string|void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}`),
    create: (companyId: bigint, data: object): Promise<ResponseType|string|void> => apiRequest(`/api/companies/${companyId}/branches`, {method: "POST", ...data}),
    update: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}`, {method: "POST", ...data}),
    remove: (companyId: bigint, branchId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}`, {method: "DELETE"}),
    updateSecterToken: (companyId: bigint): Promise<ResponseType | any | void> => apiRequest(`/api/companies/${companyId}/branches/secret`),

    integrations: {
      iiko: {
        options: (companyId: bigint, branchId: bigint, data: object) => apiRequest(`/api/companies/${companyId}/branches/${branchId}/external-crm/iiko/options`, {method: "POST", ...data})
      },
      sbisPresto: {
        options: (companyId: bigint, branchId: bigint, data: object) => apiRequest(`/api/companies/${companyId}/branches/${branchId}/external-crm/sbisPresto/options`, {method: "POST", ...data})
      },
      mobiDel: {
        options: (companyId: bigint, branchId: bigint) => apiRequest(`/api/companies/${companyId}/branches/${branchId}/external-crm/mobiDel/options`, {method: "POST"})
      }
    }
  },
  deliveryZones: {
    getAllByBranch: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType|string|void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/delivery-zones${queryParams}`),
    getAll:(companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType|string|void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/delivery-zones${queryParams}`),
    get:(companyId: bigint, branchId: bigint, deliveryZoneId: bigint): Promise<ResponseType|string|void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/delivery-zones/${deliveryZoneId}`),
    create: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType|string|void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/delivery-zones`, {method: "POST", ...data}),
    createImport: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType|string|void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/delivery-zones/import`, {method: "POST", ...data}),
    update: (companyId: bigint, branchId: bigint, deliveryZoneId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/delivery-zones/${deliveryZoneId}`, {method: "POST", ...data}),
    remove: (companyId: bigint, branchId: bigint, deliveryZoneId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/delivery-zones/${deliveryZoneId}`, {method: "DELETE"}),
  },
  categories: {
    getAll: (companyId: bigint, branchId: bigint, queryParams: string) => apiRequest(`/api/companies/${companyId}/branches/${branchId}/categories${queryParams}`),
    get:(companyId: bigint, branchId: bigint, categoryId: string) => apiRequest(`/api/companies/${companyId}/branches/${branchId}/categories/${categoryId}`),
    create: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType|string|void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/categories`, {method: "POST", ...data}),
    update: (companyId: bigint, branchId: bigint, categoryId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/categories/${categoryId}`, {method: "POST", ...data}),
    remove: (companyId: bigint, branchId: bigint, categoryId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/categories/${categoryId}`, {method: "DELETE"}),

    bulkAttach: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/categories/bulk/branches/attach`, {method: "POST", ...data}),
    bulkDetach: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/categories/bulk/branches/detach`, {method: "POST", ...data}),

    bulkActivate: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/categories/bulk/activate`, {method: "POST", ...data}),
    bulkDeactivate: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/categories/bulk/deactivate`, {method: "POST", ...data}),
  },
  suggestions: {
    city: (city: string, companyId: any) : Promise<ResponseType|string|void> => publicApiRequest(`/api/suggestions/addresses/city${city ? `?query=${encodeURI(city)}` : ''}`, {companyId: companyId}),
    cityToHouse: (companyId: any, queryParams: string): Promise<ResponseType | string | void> => publicApiRequest(`/api/suggestions/addresses/city-to-house${queryParams ? `?query=${encodeURI(queryParams)}` : ''}`, { companyId: companyId }),
    streetToHouse: (companyId: any, address: string, guid: any): Promise<ResponseType | string | void> => publicApiRequest(`/api/suggestions/addresses/street-to-house${address ? `?query=${encodeURI(address)}` : ''}${guid && address ? `&fiasId=${guid}` : guid ? `?fiasId=${guid}` : ''}`, { companyId: companyId }),
    streetToHouseWithSomeCities: (companyId: any, address: string, guidIds: any): Promise<ResponseType | string | void> => publicApiRequest(`/api/suggestions/addresses/street-to-house${address ? `?query=${encodeURI(address)}` : ''}${guidIds && guidIds.length && address ? `${guidIds.map((id: any) => (`&fiasIds[]=${id}`)).join('')}` : guidIds && guidIds.length ? `?${guidIds.map((id: any) => (`fiasIds[]=${id}&`)).join('')}` : ''}`, { companyId: companyId }),
  },
  dictionaries: {
    timeZones: (): Promise<ResponseType|string|void> => dictionaryApiRequest('/api/dictionary/time-zones'),
    currencies: (): Promise<ResponseType|string|void> => dictionaryApiRequest('/api/dictionary/currencies'),
    phoneCodes: (): Promise<ResponseType|string|void> => dictionaryApiRequest('/api/dictionary/phone-codes'),
    locationSources: (): Promise<ResponseType|string|void> => dictionaryApiRequest('/api/dictionary/location-sources'),
    platforms: (): Promise<ResponseType|string|void> => dictionaryApiRequest('/api/dictionary/platforms'),
    channels: (): Promise<ResponseType|string|void> => dictionaryApiRequest('/api/dictionary/notifications/channels'),
    outputTypes: (): Promise<ResponseType|string|void> => dictionaryApiRequest('/api/dictionary/output-types'),
    measureUnits: (): Promise<ResponseType|string|void> => dictionaryApiRequest('/api/dictionary/measure-units'),
    goodAddToOrder: (): Promise<ResponseType|string|void> => dictionaryApiRequest('/api/dictionary/good-add-to-orders'),
    promoCodeTypes: (): Promise<ResponseType|string|void> => dictionaryApiRequest('/api/dictionary/promo-code-types'),
    promoCodeStatuses: (): Promise<ResponseType|string|void> => dictionaryApiRequest('/api/dictionary/promo-code-statuses'),
    promoCodeDeliveryTypes: (): Promise<ResponseType|string|void> => dictionaryApiRequest('/api/dictionary/delivery-types'),
    paymentTypes: (): Promise<ResponseType|string|void> => dictionaryApiRequest('/api/dictionary/payment-types'),
    reviewStatuses: (): Promise<ResponseType|string|void> => dictionaryApiRequest('/api/dictionary/review-statuses'),
    reviewEstimations: (): Promise<ResponseType|string|void> => dictionaryApiRequest('/api/dictionary/review-estimations'),
    rulesTypesLoyaltyPrograms: (): Promise<ResponseType|string|void> => dictionaryApiRequest('/api/dictionary/bonus-programs/rules/types'),
    loyaltyProgram: (): Promise<ResponseType|string|void> => dictionaryApiRequest('/api/dictionary/bonus-programs'),
    requiredOrderStatuses: (): Promise<ResponseType|string|void> => dictionaryApiRequest('/api/dictionary/required-order-statuses'),
    npsAnswerTypes: (): Promise<ResponseType|string|void> => dictionaryApiRequest('/api/dictionary/nps-question-answer-types'),
    npsSources: (): Promise<ResponseType|string|void> => dictionaryApiRequest('/api/dictionary/sources'),
    funnelsInitialTypes: (): Promise<ResponseType|string|void> => dictionaryApiRequest('/api/dictionary/funnels/nodes/initial-types'),
    funnelsTimerTypes: (): Promise<ResponseType|string|void> => dictionaryApiRequest('/api/dictionary/funnels/nodes/timer-types'),
    funnelsOverlapTypes: (): Promise<ResponseType|string|void> => dictionaryApiRequest('/api/dictionary/funnels/overlap-types'),
    appEventsTypes: (): Promise<ResponseType|string|void> => dictionaryApiRequest('/api/dictionary/app-events/types')
  },
  employees: {
    getAll: (companyId: bigint, queryParams: string): Promise<ResponseType|string|void> => apiRequest(`/api/companies/${companyId}/employees${queryParams}`),
    get: (companyId: bigint, userId: bigint): Promise<ResponseType|string|void> => apiRequest(`/api/companies/${companyId}/employees/${userId}`),
    update: (companyId: bigint, userId: bigint, data: object): Promise<ResponseType|string|void> => apiRequest(`/api/companies/${companyId}/employees/${userId}`, {method: "POST", ...data}),
    create: (companyId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/employees`, {method: "POST", ...data}),
    remove: (companyId: bigint, userId: bigint): Promise<ResponseType|string|void> => apiRequest(`/api/companies/${companyId}/employees/${userId}`, {method: "DELETE"})
  },
  products: {
    getAll: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods${queryParams}`),
    get: (companyId: bigint, branchId: bigint, goodId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/${goodId}`),
    create: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods`, {method: "POST", ...data}),
    copy: (companyId: bigint, branchId: bigint, data: object, goodId: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/${goodId}/copy`, {method: "POST", ...data}),
    update: (companyId: bigint, branchId: bigint, goodId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/${goodId}`, {method: "POST", ...data}),
    remove: (companyId: bigint, branchId: bigint, goodId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/${goodId}`, {method: "DELETE"}),

    bulkActivate: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/bulk/activate`, {method: "POST", ...data}),
    bulkDeactivate: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/bulk/deactivate`, {method: "POST", ...data}),
    bulkMove: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/bulk/categories/move`, {method: "POST", ...data}),
    bulkAttachCategories: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/bulk/categories/attach`, {method: "POST", ...data}),
    bulkDetachCategories: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/bulk/categories/detach`, {method: "POST", ...data}),
    bulkCopy: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/bulk/categories/copy`, {method: "POST", ...data}),
    bulkAttachBranch: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/bulk/branches/attach`, {method: "POST", ...data}),
    bulkDetachBranch: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/bulk/branches/detach`, {method: "POST", ...data}),
    bulkMergeSku: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/bulk/sku-group/merge`, {method: "POST", ...data}),
    bulkRemove: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/bulk/remove`, { method: "POST", ...data }),
  },
  options: {
    getAll: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/options${queryParams}`),
    get: (companyId: bigint, branchId: bigint, goodOptionId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/options/${goodOptionId}`),
    create: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/options`, {method: "POST", ...data}),
    update: (companyId: bigint, branchId: bigint, goodOptionId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/options/${goodOptionId}`, {method: "POST", ...data}),
    remove: (companyId: bigint, branchId: bigint, goodOptionId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/options/${goodOptionId}`, {method: "DELETE"}),
    bulkRemove: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/options/bulk/delete`, { method: "POST", ...data }),
    bulkAttach: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/options/bulk/branches/attach`, { method: "POST", ...data }),
    bulkDetach: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/options/bulk/branches/detach`, { method: "POST", ...data }),
  },
  optionsGroups: {
    getAll: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/options-groups${queryParams}`),
    get: (companyId: bigint, branchId: bigint, optionsGroupId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/options-groups/${optionsGroupId}`),
    create: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/options-groups`, {method: "POST", ...data}),
    update: (companyId: bigint, branchId: bigint, optionsGroupId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/options-groups/${optionsGroupId}`, {method: "POST", ...data}),
    remove: (companyId: bigint, branchId: bigint, optionsGroupId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/options-groups/${optionsGroupId}`, {method: "DELETE"}),
    bulkRemove: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/options-groups/bulk/delete`, { method: "POST", ...data }),
    bulkAttach: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/options-groups/bulk/branches/attach`, { method: "POST", ...data }),
    bulkDetach: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/options-groups/bulk/branches/detach`, { method: "POST", ...data }),
  },
  skuGroups: {
    getAll: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/sku-groups${queryParams}`),
    get: (companyId: bigint, branchId: bigint, skuGroupId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/sku-groups/${skuGroupId}`),
    create: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/sku-groups`, {method: "POST", ...data}),
    update: (companyId: bigint, branchId: bigint, skuGroupId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/sku-groups/${skuGroupId}`, {method: "POST", ...data}),
    remove: (companyId: bigint, branchId: bigint, skuGroupId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/sku-groups/${skuGroupId}`, {method: "DELETE"}),
    bulkRemove: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/sku-groups/bulk/delete`, { method: "POST", ...data }),
    bulkAttach: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/sku-groups/bulk/branches/attach`, { method: "POST", ...data }),
    bulkDetach: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/sku-groups/bulk/branches/detach`, { method: "POST", ...data }),
  },
  customers: {
    getAll: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/customers${queryParams}`),
    get: (companyId: bigint, branchId: bigint, customerId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/customers/${customerId}`),
    create: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/customers`, {method: "POST", ...data}),
    update: (companyId: bigint, branchId: bigint, customerId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/customers/${customerId}`, {method: "POST", ...data}),
    remove: (companyId: bigint, branchId: bigint, customerId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/customers/${customerId}`, {method: "DELETE"}),

    bulkAttach: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/customers/bulk/tags/attach`, {method: "POST", ...data}),
    bulkDetach: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/customers/bulk/tags/detach`, {method: "POST", ...data}),
    
    getCustomersVkEvents: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/customers/vk-events${queryParams}`)
  },
  orders: {
    getAll: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/orders${queryParams}`),
    get: (companyId: bigint, branchId: bigint, orderId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/orders/${orderId}`),
    resendToExternal: (companyId: bigint, branchId: bigint, orderId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/orders/${orderId}/resend-external`),
    create: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/orders`, {method: "POST", ...data}),
    update: (companyId: bigint, branchId: bigint, orderId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/orders/${orderId}`, {method: "POST", ...data}),
    remove: (companyId: bigint, branchId: bigint, orderId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/orders/${orderId}`, {method: "DELETE"}),
  },
  histories: {
    getAll: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/histories${queryParams}`),
    getAllActive: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/histories/active/list${queryParams}`),
    getAllArchive: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/histories/archive/list${queryParams}`),
    get: (companyId: bigint, branchId: bigint, historyId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/histories/${historyId}`),
    create: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/histories`, {method: "POST", ...data}),
    update: (companyId: bigint, branchId: bigint, historyId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/histories/${historyId}`, {method: "POST", ...data}),
    remove: (companyId: bigint, branchId: bigint, historyId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/histories/${historyId}`, {method: "DELETE"}),
  },
  mailings: {
    getAll: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/distributions${queryParams.length > 1 ? queryParams+'&filter[isSent]=0' : '?filter[isSent]=0'}`),
    getAllArchive: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/distributions${queryParams.length > 1 ? queryParams+'&filter[isSent]=1' : '?filter[isSent]=1'}`),
    get: (companyId: bigint, branchId: bigint, mailingId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/distributions/${mailingId}`),
    create: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/distributions`, {method: "POST", ...data}),
    update: (companyId: bigint, branchId: bigint, mailingId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/distributions/${mailingId}`, {method: "POST", ...data}),
    remove: (companyId: bigint, branchId: bigint, mailingId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/distributions/${mailingId}`, {method: "DELETE"}),
  },
  rulesLoyalty: {
    getAll: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/bonus-program/rules${queryParams}`),
    get: (companyId: bigint, branchId: bigint, bonusProgramRuleId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/bonus-program/rules/${bonusProgramRuleId}`),
    create: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/bonus-program/rules`, {method: "POST", ...data}),
    update: (companyId: bigint, branchId: bigint, bonusProgramRuleId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/bonus-program/rules/${bonusProgramRuleId}`, {method: "POST", ...data}),
    remove: (companyId: bigint, branchId: bigint, bonusProgramRuleId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/bonus-program/rules/${bonusProgramRuleId}`, {method: "DELETE"}),
  },
  statusesLoyalty: {
    getAll: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/bonus-program/statuses${queryParams}`),
    get: (companyId: bigint, branchId: bigint, bonusProgramStatusId: string | undefined): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/bonus-program/statuses/${bonusProgramStatusId}`),
    create: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/bonus-program/statuses`, {method: "POST", ...data}),
    update: (companyId: bigint, branchId: bigint, bonusProgramStatusId: string | undefined, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/bonus-program/statuses/${bonusProgramStatusId}`, {method: "POST", ...data}),
    remove: (companyId: bigint, branchId: bigint, bonusProgramStatusId: string | undefined): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/bonus-program/statuses/${bonusProgramStatusId}`, {method: "DELETE"}),
  },
  transactionsLoyalty: {
    getAll: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/bonus-program/transactions${queryParams}`),
    get: (companyId: bigint, branchId: bigint, bonusProgramTransactionId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/bonus-program/transactions/${bonusProgramTransactionId}`),
    create: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/bonus-program/transactions`, {method: "POST", ...data}),
    update: (companyId: bigint, branchId: bigint, bonusProgramTransactionId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/bonus-program/transactions/${bonusProgramTransactionId}`, {method: "POST", ...data}),
    remove: (companyId: bigint, branchId: bigint, bonusProgramTransactionId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/bonus-program/transactions/${bonusProgramTransactionId}`, {method: "DELETE"}),
  },
  companiesTags: {
    getAll: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/companies${queryParams}`),
    get: (companyId: bigint, branchId: bigint, companyTagId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/companies/${companyTagId}`),
    create: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/companies`, {method: "POST", ...data}),
    update: (companyId: bigint, branchId: bigint, companyTagId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/companies/${companyTagId}`, {method: "POST", ...data}),
    remove: (companyId: bigint, branchId: bigint, companyTagId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/companies/${companyTagId}`, {method: "DELETE"}),
    bulkRemove: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/companies/bulk/delete`, {method: "POST", ...data}),
    bulkAttach: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/companies/bulk/branches/attach`, {method: "POST", ...data}),
    bulkDetach: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/companies/bulk/branches/detach`, {method: "POST", ...data}),
  },
  customersTags: {
    getAllCompany: (companyId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/tags/customers${queryParams}`),
    getAll: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/customers${queryParams}`),
    get: (companyId: bigint, branchId: bigint, customerTagId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/customers/${customerTagId}`),
    create: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/customers`, {method: "POST", ...data}),
    update: (companyId: bigint, branchId: bigint, customerTagId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/customers/${customerTagId}`, {method: "POST", ...data}),
    remove: (companyId: bigint, branchId: bigint, customerTagId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/customers/${customerTagId}`, {method: "DELETE"}),
    bulkRemove: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/customers/bulk/delete`, {method: "POST", ...data}),
    bulkAttach: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/customers/bulk/branches/attach`, {method: "POST", ...data}),
    bulkDetach: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/customers/bulk/branches/detach`, {method: "POST", ...data}),
  },
  ordersTags: {
    getAll: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/orders${queryParams}`),
    get: (companyId: bigint, branchId: bigint, orderTagId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/orders/${orderTagId}`),
    create: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/orders`, {method: "POST", ...data}),
    update: (companyId: bigint, branchId: bigint, orderTagId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/orders/${orderTagId}`, {method: "POST", ...data}),
    remove: (companyId: bigint, branchId: bigint, orderTagId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/orders/${orderTagId}`, {method: "DELETE"}),

    bulkRemove: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/orders/bulk/delete`, {method: "POST", ...data}),
    bulkAttach: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/orders/bulk/branches/attach`, {method: "POST", ...data}),
    bulkDetach: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/orders/bulk/branches/detach`, {method: "POST", ...data}),
  },
  promoTags: {
    getAll: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/promo-codes${queryParams}`),
    get: (companyId: bigint, branchId: bigint, promoCodeTagId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/promo-codes/${promoCodeTagId}`),
    create: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/promo-codes`, {method: "POST", ...data}),
    update: (companyId: bigint, branchId: bigint, promoCodeTagId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/promo-codes/${promoCodeTagId}`, {method: "POST", ...data}),
    remove: (companyId: bigint, branchId: bigint, promoCodeTagId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/promo-codes/${promoCodeTagId}`, {method: "DELETE"}),

    bulkRemove: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/promo-codes/bulk/delete`, {method: "POST", ...data}),
    bulkAttach: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/promo-codes/bulk/branches/attach`, {method: "POST", ...data}),
    bulkDetach: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/tags/promo-codes/bulk/branches/detach`, {method: "POST", ...data}),
  },
  promo: {
    getAll: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/promo-codes${queryParams}`),
    get: (companyId: bigint, branchId: bigint, promoCodeId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/promo-codes/${promoCodeId}`),
    create: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/promo-codes`, {method: "POST", ...data}),
    update: (companyId: bigint, branchId: bigint, promoCodeId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/promo-codes/${promoCodeId}`, {method: "POST", ...data}),
    remove: (companyId: bigint, branchId: bigint, promoCodeId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/promo-codes/${promoCodeId}`, {method: "DELETE"}),

    bulkAttach: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/promo-codes/bulk/branches/attach`, {method: "POST", ...data}),
    bulkDetach: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/promo-codes/bulk/branches/detach`, {method: "POST", ...data}),
    bulkCopy: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/promo-codes/bulk/copy`, { method: "POST", ...data }),
    bulkActivate: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/promo-codes/bulk/activate`, {method: "POST", ...data}),
    bulkDeactivate: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/promo-codes/bulk/deactivate`, {method: "POST", ...data}),

  },
  stocks: {
    getAll: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/stocks${queryParams}`),
    get: (companyId: bigint, branchId: bigint, stockId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/stocks/${stockId}`),
    create: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/stocks`, {method: "POST", ...data}),
    update: (companyId: bigint, branchId: bigint, stockId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/stocks/${stockId}`, {method: "POST", ...data}),
    remove: (companyId: bigint, branchId: bigint, stockId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/stocks/${stockId}`, {method: "DELETE"}),

    bulkAttach: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/stocks/bulk/branches/attach`, {method: "POST", ...data}),
    bulkDetach: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/stocks/bulk/branches/detach`, {method: "POST", ...data}),
  },
  nps: {
    getConditions: (companyId: bigint, branchId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/nps/questions/conditions`),
    answers: {
      getAll: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/nps/questions-results${queryParams}`)
    },
    questions: {
      getAll: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/nps/questions${queryParams}`),
      get: (companyId: bigint, branchId: bigint, questionId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/nps/questions/${questionId}`),
      create: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/nps/questions`, {method: "POST", ...data}),
      update: (companyId: bigint, branchId: bigint, questionId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/nps/questions/${questionId}`, {method: "POST", ...data}),
      remove: (companyId: bigint, branchId: bigint, questionId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/nps/questions/${questionId}`, {method: "DELETE"}),
    },
    orderReviews: {
      getAll: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/nps/order-reviews${queryParams}`),
      get: (companyId: bigint, branchId: bigint, reviewId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/nps/order-reviews/${reviewId}`),
      update: (companyId: bigint, branchId: bigint, reviewId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/nps/order-reviews/${reviewId}`, {method: "POST", ...data}),
    },
    goodOrderReviews: {
      getAll: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/nps/order-good-reviews${queryParams}`),
      get: (companyId: bigint, branchId: bigint, reviewId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/nps/order-good-reviews/${reviewId}`),
    },
  },
  import: {
    iiko: {
      goods: {
        start: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/import/iiko`, {method: "POST", ...data}),
        refresh: (companyId: bigint, branchId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/import/iiko/refresh`),
        get: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/import/iiko`, {method: "GET", ...data}),
      }
    },
    mobiDel: {
      goods: {
        start: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/import/mobiDel`, {method: "POST", ...data}),
        refresh: (companyId: bigint, branchId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/import/mobiDel/refresh`),
        get: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/import/mobiDel`, {method: "GET", ...data}),
      }
    },
    sbisPresto: {
      goods: {
        start: (companyId: bigint, branchId: bigint, data?: object = {}): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/import/sbisPresto`, {method: "POST", ...data}),
        refresh: (companyId: bigint, branchId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/import/sbisPresto/refresh`),
        get: (companyId: bigint, branchId: bigint, data?: object = {}): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/import/sbisPresto`, {method: "GET", ...data}),
      }
    },
    goods: {
      getFrontpadGoods: (companyId: bigint, branchId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/import/frontpad`),
      getPartnerGoods: (companyId: bigint, branchId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/import/partner`),
      refreshFrontpadGoods: (companyId: bigint, branchId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/import/frontpad/refresh`),
      refreshPartnerGoods: (companyId: bigint, branchId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/import/partner/refresh`),
      csvParse: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/import/csv-parse`, {method: "POST", ...data}),
      start: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/import`, {method: "POST", ...data}),
      cancel: (companyId: bigint, branchId: bigint, batchId: any, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/import/batch/${batchId}/cancel`, {method: "POST", ...data}),
      checkProgress: (companyId: bigint, branchId: bigint, batchId: any): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/import/batch/${batchId}`),
      getVkGroups: (companyId: bigint, branchId: bigint): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/import/vk/groups`),
      refreshVkGoods: (companyId: bigint, branchId: bigint, data: any): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/import/vk/refresh`, {method: 'POST', ...data}),
      getVkGroupGood: (companyId: bigint, branchId: bigint, groupId: string | number, queryParams: string = ''): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/import/vk/groups/${groupId}${queryParams}`)
    }
  },
  funnelGroup: {
    getAll: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/funnel-groups${queryParams}`),
    get: (companyId: bigint, branchId: bigint, funnelGroupId: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/funnel-groups/${funnelGroupId}`),
    create: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/funnel-groups`, {method: "POST", ...data}),
    update: (companyId: bigint, branchId: bigint, funnelGroupId: string, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/funnel-groups/${funnelGroupId}`, {method: "POST", ...data}),
    remove: (companyId: bigint, branchId: bigint, funnelGroupId: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/funnel-groups/${funnelGroupId}`, {method: "DELETE"}),
  },
  funnels: {
    getAll: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/funnels${queryParams}`),
    get: (companyId: bigint, branchId: bigint, funnelId: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/funnels/${funnelId}`),
    create: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/funnels`, {method: "POST", ...data}),
    remove: (companyId: bigint, branchId: bigint, funnelId: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/funnels/${funnelId}`, { method: "DELETE" }),
    update: (companyId: bigint, branchId: bigint, funnelId: string, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/funnels/${funnelId}`, {method: "POST", ...data}),
    getConstructorNodeData: (companyId: bigint, branchId: bigint, funnelId: any, queryParams: any): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/funnels/${funnelId}/nodes-constructor-data`),
    validate: (companyId: bigint, branchId: bigint, funnelId: bigint, data: any): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/funnels/${funnelId}/nodes/validate`, {method: "POST", ...data}),
    bulk: {
      bulkActivate: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/funnels/bulk/activate`, {method: "POST", ...data}),
      bulkDeactivate: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/funnels/bulk/deactivate`, {method: "POST", ...data}),
      bulkRemove: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/funnels/bulk/delete`, {method: "POST", ...data}),
      bulkMove: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/funnels/bulk/funnel-groups/move`, {method: "POST", ...data}),
      bulkCopy: (companyId: bigint, branchId: bigint, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/funnels/bulk/copy`, {method: "POST", ...data}),
    }
  },
  export: {
    reports: {
      orders: (companyId: bigint, branchId: bigint, queryParams: string, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/reports/orders/export${queryParams}`, { method: "POST", ...data }),
      customers: (companyId: bigint, branchId: bigint, queryParams: string, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/reports/orders/customers/export${queryParams}`, { method: "POST", ...data }),
      products: (companyId: bigint, branchId: bigint, queryParams: string, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/reports/products/export${queryParams}`, { method: "POST", ...data }),
      promoCodes: (companyId: bigint, branchId: bigint, queryParams: string, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/reports/promocodes/export${queryParams}`, { method: "POST", ...data }),
    },
    get: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/exports${queryParams}`),
    promoCodes: (companyId: bigint, branchId: bigint, queryParams: string, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/promo-codes/export${queryParams}`, { method: "POST", ...data }),
    goods: (companyId: bigint, branchId: bigint, queryParams: string, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/goods/export${queryParams}`, { method: "POST", ...data }),
    goodsRating: (companyId: bigint, branchId: bigint, queryParams: string, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/nps/order-good-reviews/export${queryParams}`, { method: "POST", ...data }),
    orders: (companyId: bigint, branchId: bigint, queryParams: string, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/orders/export${queryParams}`, { method: "POST", ...data }),
    customers: (companyId: bigint, branchId: bigint, queryParams: string, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/customers/export${queryParams}`, { method: "POST", ...data }),
    reviews: (companyId: bigint, branchId: bigint, queryParams: string, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/nps/order-reviews/export${queryParams}`, { method: "POST", ...data }),
    questionResults: (companyId: bigint, branchId: bigint, queryParams: string, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/nps/questions-results/export${queryParams}`, { method: "POST", ...data }),
    customersVkEvents: (companyId: bigint, branchId: bigint, queryParams: string, data: object): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/customers/vk-events/export${queryParams}`, { method: "POST", ...data })
  },

  reports: {
    orders: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/reports/orders${queryParams}`),
    products: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/reports/products${queryParams}`),
    promoCodes: (companyId: bigint, branchId: bigint, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/reports/promocodes${queryParams}`),
    promoCode: (companyId: bigint, branchId: bigint, promoCode: string, queryParams: string): Promise<ResponseType | string | void> => apiRequest(`/api/companies/${companyId}/branches/${branchId}/reports/promocodes/${promoCode}/${queryParams}`),
  }
}
