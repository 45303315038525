import React, {createContext, useContext, useState} from "react";
import {useReactFlow} from "reactflow";
import {showWarningToast} from "../libs/helpers/toasts";
import {v4 as uuid} from "uuid";
import {AddActionEnum} from "../components/common/Header/FunnelHeader";
import {NodeTypeEnum} from "../pages/Funnels/CreateFunnel";

interface IContextProps {
  removeNode: (id: any) => void
  copyNode: (id: any) => void
  updateNode: (id: any, data: any) => void
  addNode: (action: AddActionEnum) => void
  focusNode: (toStartNode: boolean, search: string) => void
  isValidConnection: (connection: any) => boolean
}

const FunnelNodes = createContext<IContextProps>({
    removeNode: (id: any) => {},
    copyNode: (id: any) => {},
    updateNode: (id: any, data: any) => {},
    addNode: (action: AddActionEnum) => {},
    focusNode: (toStartNode: boolean = false, search: string = '') => {},
    isValidConnection: (connection: any) => true
  }
)

export function FunnelNodesWrapper({children}: any) {
  const {getNodes, setNodes, getEdges, setEdges, setCenter, project} = useReactFlow()
  const [stepCounter, setStepCounter] = useState(1)

  const isValidConnection = (connection: any) => {
    const edges = getEdges()

    const sourceEdgeCount = edges.filter((edge) => edge.source == connection.source && edge.sourceHandle == connection.sourceHandle).length
    const targetEdgeCount = edges.filter((edge) => edge.target == connection.target && edge.targetHandle == connection.targetHandle).length

    if (sourceEdgeCount > 0) return false

    return true;
  }

  const updateNode = (id: any, data: any) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id == id) {
          node.data = data;
          node.selected = false
        }
        return node;
      })
    );
  }

  const removeNode = (id: any) => {
    setEdges((edges) => edges.filter((edge) => (edge.source != id && edge.target != id)))
    setNodes((nodes) => nodes.filter((node) => node.id != id))
  }

  const copyNode = (id: any) => {
    const node = getNodes().find((node) => node.id == id);

    if (!node) {
      showWarningToast({
        content: 'Не удалось скопировать узел',
        position: 'bottom-center'
      })
      return
    }
    
    let data = JSON.parse(JSON.stringify(node.data));

    
    switch (node.type) {
      case NodeTypeEnum.action:
        copyNodeData(data.actions)
        break
      case NodeTypeEnum.condition:
        copyNodeData(data.conditions)
        break
      default:
        break
    }

    let newNode = {
      id: uuid(),
      position: {x: node.position.x + 100, y: node.position.y + 100},
      data: {...data},
      type: node.type,
    }

    setNodes((nodes) => nodes.concat(newNode))
  }

  const copyNodeData = (data: any) => {
    data?.children?.forEach(copyNodeData)

    if ('id' in data) {
      data.id = uuid()
    }
  }

  const addNode = (action: AddActionEnum) => {
    const position = project({ x: 100, y: 100 })

    let newNode: any = {
      id: uuid(),
      position: position,
      data: {
        title: `Шаг ${stepCounter}`
      }
    }

    switch (action) {
      case AddActionEnum.action:
        newNode.type = NodeTypeEnum.action
        break
      case AddActionEnum.condition:
        newNode.type = NodeTypeEnum.condition
        break
      case AddActionEnum.timeout:
        newNode.type = NodeTypeEnum.timer
        break
    }

    setStepCounter((step) => (step + 1))

    setNodes((nds) => nds.concat(newNode));
  }

  const focusNode = (toStartNode: boolean = false, search: string = '') => {
    const nodes = getNodes()

    if (nodes.length > 0) {
      const node: any = toStartNode ?
        nodes[0] :
        nodes.find((node) => node.id?.toLowerCase().includes(search.toLowerCase()) || node.data?.title?.toLowerCase().includes(search.toLowerCase()))

      if (!node) {
        showWarningToast({
          content: 'Шаг не найден',
          position: "bottom-center"
        })
        return
      }

      const x = node.position.x + node.width / 2
      const y = node.position.y + node.height / 2
      const zoom = 1.25

      setCenter(x, y, { zoom, duration: 1000 })
    }
  }

  return (
    <FunnelNodes.Provider value={{removeNode, copyNode, updateNode, addNode, focusNode, isValidConnection}}>
      { children }
    </FunnelNodes.Provider>
  )
}

export function useFunnelNodeContext() {
  return useContext(FunnelNodes)
}
