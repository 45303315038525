const tabs = [
  {
    label: 'Активные',
    tag: 'active',
    fields: []
  },
  {
    label: 'Архивные',
    tag: 'archive',
    fields: []
  },

]

export default tabs
