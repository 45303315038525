import React, {useEffect, useState} from "react";
import {useCompaniesContext} from "../../../contexts/Companies";
import {useBranchesContext} from "../../../contexts/Branches";
import useForm from "../../../hooks/useForm";
import initForm from "../initForm";
import fetchPlatforms from "../../../providers/dictionaries/platforms";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import FormWrapper from "../../../components/common/form/FormWrapper";
import tabs from "../CreateMailing/tabs";
import EditFormFields from "../../../components/common/form/EditFormFields";
import Base from "../forms/Base";
import Clients from "../forms/Clients";
import {useNavigate, useParams} from "react-router-dom";
import {CustomersTagsContextWrapper} from "../../../contexts/Tags";
import {apiClient} from "../../../libs/api/apiClient";
import {showErrorToast, showSuccessToast} from "../../../libs/helpers/toasts";
import {dialogModal} from "../../../components/common/Modal/DialogModal";
import {preparePaginationQueryParams} from "../../../contexts/common";
import { useFormContext } from "../../../contexts/Form";

export default function EditMailing() {
  const {company} = useCompaniesContext()
  const {branch, branches} = useBranchesContext()
  const {needUpdate, setNeedUpdate} = useFormContext()
  const {data, updateField, updateFieldNoRender, errors, changedFieldsRefresh, isDirty, updateData, updateErrors, submit} = useForm(JSON.parse(JSON.stringify(initForm)))
  const navigate = useNavigate()
  const {id: mailingId} = useParams<{id?: string}>()

  const [tab, setTab] = useState('base')
  const [platforms, setPlatforms] = useState([])
  const [loading, setLoading] = useState<boolean>(true)
  const [allCustomerTags, setAllCustomerTags] = useState<any>([])

  const handleSubmit = () => {
    submit(async (form: any) => {
      apiClient.mailings.update(company?.id, branch?.id, mailingId,{body: form}).then(({data, errors}: any) => {
        if (data) {
          showSuccessToast({
            content: "Рассылка успешно изменена"
          })
          changedFieldsRefresh()
          getMailing()
        } else {
          updateErrors(errors || {});

          showErrorToast({
            content: 'Что-то пошло не так'
          })

        }
      }).catch((e) => console.log(e)).finally(() => setNeedUpdate(false))
    }, 'changed')
  }

  const getMailing = () => {
    if (company && branch) {
      setLoading(true)
      apiClient.mailings.get(company.id, branch.id, mailingId).then(({data, errors}: any) => {
        if (data) {
          updateData(data)
        } else {
          updateErrors(errors || {})
          showErrorToast({
            content: 'Что-то пошло не так'
          })
        }
        setLoading(false)
      }).catch((err) => {
        setLoading(false)
      })
    }
  }

  useEffect(() => {
    if (needUpdate) {
      handleSubmit()
    }
  }, [needUpdate])

  const removeMailing = () => {
    dialogModal({
      isOpen: false,
      title: 'Внимание!',
      text: 'Вы уверены что хотите удалить рассылку?',
      showCancelButton: true,
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Вернуться',
    }).then((result) => {
      if (result.isConfirmed) {
        apiClient.mailings.remove(company.id, branch.id, mailingId)
          .then(({data, errors}: any) => {
            showSuccessToast({
              content: "Рассылка успешно удалена",
            })
            navigate('/mailings')
          }).catch((err) => {
          updateErrors(errors || {})
          //updateData(company)
          showErrorToast({
            content: 'Что-то пошло не так'
          })
        })
      }
    }).catch(() => {})
  }

  const reset = () => {
    navigate('/mailings')
  }

  useEffect(() => {
    fetchPlatforms().then((data) => {
      setPlatforms(data.map(({label, value}: any) => {return {label: label, name: value}}))
    })
  }, [])

  useEffect(() => {
    getMailing()
  }, [branch])

  useEffect(() => {
    if (company && branches?.length) {
      const queryParams = preparePaginationQueryParams({ filter: { branches: data.branches === '*' ? branches.map(({ id }: any) => (id)) : data.branches } })

      apiClient.customersTags.getAllCompany(company.id, queryParams).then(({data, message, errors}: any) => {
        if (data) {
          setAllCustomerTags(data)
        } else {
          showErrorToast({
            content: message || `При загрузке меток клиентов произошла ошибка`
          })
        }
      }).catch(() => {
        showErrorToast({
          content: `При загрузке меток клиентов произошла ошибка`
        })
      })
    }
  }, [company, data.branches])

  const changeTab = (tag: any) => {
    if (Object.keys(isDirty()).length !== 0) {
      dialogModal({
        isOpen: false,
        title: 'Не сохраненные данные!',
        text: 'Вы действительно хотите перейти на следующий шаг? Все не сохраненные данные будут удалены',
        showDenyButton: true,
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: 'Сохранить и продолжить',
        denyButtonText: `Без сохранения`,
        cancelButtonText: 'Вернуться',
      }).then((result) => {
        if (result.isConfirmed) {
          handleSubmit()
          setTab(tag)
        } else if (result.isDenied) {
          changedFieldsRefresh()
          getMailing()
          setTab(tag)
        }
      }).catch(() => {})
    } else {
      setTab(tag)
    }
  }

  return (
    <div className='px-8 pb-8 pt-2 text-gray-50'>
      <div className='mb-8'>
        <div className='text-[44px] leading-[60px] mb-2'>Рассылки</div>
        <Breadcrumbs
          links={[
            {to: `/mailings`, label: 'Список рассылок'},
            {to: '', label: 'Редактирование рассылки'},
          ]}
          back={`/mailings`}
        />
      </div>
      <div className={'rounded-xl bg-white py-5'}>
        <FormWrapper
          tabs={tabs}
          tab={tab}
          setTab={changeTab}
        >
          <EditFormFields
            show={tab === 'base'}
            denyRemoveAction={false}
            remove={removeMailing}
            reset={reset}
            back={'/mailings'}
            handleSubmit={handleSubmit}
          >
            <Base data={data} updateField={updateField} updateFieldNoRender={updateFieldNoRender} errors={errors} platforms={platforms} branches={branches}/>
          </EditFormFields>
          <EditFormFields
            show={tab === 'clients'}
            denyRemoveAction={false}
            remove={removeMailing}
            reset={reset}
            back={'/mailings'}
            handleSubmit={handleSubmit}
          ><CustomersTagsContextWrapper>
            <Clients data={data} updateField={updateField} updateFieldNoRender={updateFieldNoRender} errors={errors} tagsArray={allCustomerTags}/>
          </CustomersTagsContextWrapper>
          </EditFormFields>

        </FormWrapper>
      </div>
    </div>
  )
}
