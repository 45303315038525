
const initialData = {
  startAt: null,
  endAt: null,
  isActive: true,
  initialActionType: '',
  title: '',
  //position: '',
  overlapType: '',
  branches: [],
}

export default initialData