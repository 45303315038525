
import BaseButton from "../../Button/BaseButton";
import React, {useEffect, useId, useRef} from "react";
// @ts-ignore
import closeIcon from "../../../../images/greenClose.svg"
import TextInput from "../../form/input/TextInput";
import RangeDatePicker from "../../form/datetime/RangeDatePicker";
import SingleSimpleValueSelect from "../../form/select/SingleSimpleValueSelect";
import Checkbox from "../../form/checkbox/Checkbox";
import SimpleMultipleValueSelect from "../../form/select/multiple/SimpleMultipleValueSelect";
import {useBranchesContext} from "../../../../contexts/Branches";
import { useCompaniesContext } from "../../../../contexts/Companies";
import { dialogModal } from "../DialogModal";
import { apiClient } from "../../../../libs/api/apiClient";
import { showErrorToast, showSuccessToast } from "../../../../libs/helpers/toasts";

interface ModalProps {
  isEdit?: boolean
  isOpen: boolean
  onResolve: any
  onReject: any
  title: string
  data: any
  updateField: (field: string, value: any) => void
  updateFieldNoRender: (field: string, value: any) => void
  errors: any
  startActionOptions: any[]
  overlapTypes: any[]
  showDenyButton?: boolean
  showCancelButton?: boolean
  showCloseButton?: boolean
  showSubmitButton?: boolean
  allowOutsideClick?: boolean
  confirmButtonText?: string
  denyButtonText?: string
  cancelButtonText?: string
  classNameWrapper?: string
}

enum startActionOptionsEnum {
  WEBHOOOK = 'webhook',
  CART = 'cart',
  ORDER_CREATED = 'order-created',
  OPEN = 'open',
  ORDER_STATUS_CHANGED = 'order-status-changed',
  PAYMENT_STATUS_CHANGED = 'payment-status-changed',
  NPS_FILLED = 'nps-filled',
  ORDER_REVIEW_CREATED = 'order-review-created',
  ORDER_GOOD_REVIEW_CREATED = 'order-good-review-created',
  AUTHORIZED = 'authorized'
}

enum overlapTypesEnum {
  ALLOW = 'allow',
  ALLOW_AFTER_FINISHED = 'allow-after-finished'
}

export default function CreateModal(
  {
    isEdit,
    isOpen,
    onResolve,
    onReject,
    title,
    data,
    updateField,
    updateFieldNoRender,
    errors,
    startActionOptions,
    overlapTypes,
    showDenyButton = false,
    showCancelButton = false,
    showCloseButton = true,
    showSubmitButton = true,
    allowOutsideClick = false,
    confirmButtonText = 'Сохранить',
    denyButtonText = 'Вернуться',
    cancelButtonText = 'Отмена',
    classNameWrapper = ''
  }: ModalProps) {
  const id = useId();
  //const input = useRef(null);
  const {company} = useCompaniesContext()
  const {branch, branches} = useBranchesContext()
  const wrapper = useRef<any>(null)

  /* Закрываем при клике вне поля*/
  useEffect(() => {
    function handleClick(event: any) {
      if (wrapper.current && !wrapper.current.contains(event.target) && allowOutsideClick) {
        onReject({isCancelled: true})
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }

  }, [isOpen, onReject, wrapper]);

  const removeFunnel = () => {
    dialogModal({
      isOpen: false,
      title: 'Внимание!',
      text: 'Вы уверены что хотите удалить воронку?',
      showCancelButton: true,
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Вернуться',
    }).then((result) => {
      if (result.isConfirmed) {
        apiClient.funnels.remove(company?.id, branch?.id, data?.id)
          .then(({ data, message, errors }: any) => {
            if (message || errors) {
              showErrorToast({
                content: 'Что-то пошло не так'
              })
            } else {
              showSuccessToast({
                content: "Воронка успешно удалена",
              })
              onReject({ isCancelled: true, update: true })
            }
          }).catch((err) => {
            showErrorToast({
              content: 'Что-то пошло не так'
            })
          })
      }
    }).catch(() => { })
  }

  return (
    <div id={id} className={`fixed grid w-full h-full overflow-y-auto modal-wrapper ${!isOpen && 'hidden'}`}>
      <div ref={wrapper} className={`grid relative bg-white rounded-[10px] max-w-[90%] max-h-full modal ${classNameWrapper}`}>
        <div className={'flex justify-between px-4 py-[14px] items-center'}>
          <span className={'text-xl'}>{title}</span>
          {
            showCloseButton &&
              <img src={closeIcon} className={'w-[20px] cursor-pointer'} onClick={() => {
                onReject({isCancelled: true})
              }} alt=""/>
          }
        </div>
        <div className="p-4">
          <div className={'flex flex-col gap-y-4'}>
            <TextInput
              label={'Название воронки'}
              textTooltip={'Название воронки'}
              placeholder={'Введите название'}
              required={true}
              errors={errors}
              value={data?.title || ''}
              onChange={(value: any) => updateFieldNoRender('title', value)}
              name={'title'}
            />

            <SingleSimpleValueSelect
              name={'initialActionType'}
              label={'Событие запуска'}
              textTooltip={'Событие запуска'}
              placeholder={'Выберите событие запуска'}
              value={data?.initialActionType}
              options={startActionOptions || []}
              onChange={(option: any) => {
                updateField('initialActionType', option)
                if (data?.initialActionType == startActionOptionsEnum.CART) {
                  updateField('overlapType', overlapTypesEnum.ALLOW)
                }
              }}
              howMushShowItems={5}
              disabled={isEdit}
              required={true}
              errors={errors}
            />

            {
              data?.initialActionType == startActionOptionsEnum.WEBHOOOK && <div className={'flex flex-col gap-y-4'}>
                <div className={'flex flex-col text-sm'}>
                  <div>События типа «Входящий запрос» срабатывают при получении запроса на адрес:</div>
                  <div className={'text-interactive-elem cursor-pointer'}>{`${process.env.REACT_APP_PUBLIC_API_URL}/api/branch/${branch?.id}/webhook/funnels`}</div>
                </div>

                <div className={'flex flex-col text-sm gap-y-4'}>
                  <div>Доступные параметры запроса:</div>
                  <div className={'p-[15px] flex flex-col text-gray-20 border-[1px] border-gray-20 rounded-xl'}>
                    <div className={'pb-[20px]'}>
                      Вы можете присылать любые параметры в своих запросах и использовать их в Условиях и Действиях.
                      Ниже представлен список специальных параметров:
                    </div>
                    <div>
                      <div><span className={'text-interactive-elem'}>customerId</span>: Для получения данных Клиента по его ID.</div>
                      <div><span className={'text-interactive-elem'}>customerPhone</span>: Для получения данных Клиента по номеру телефона.</div>
                      <div><span className={'text-interactive-elem'}>orderId</span>: Для получения данных о Заказе по его ID.</div>
                    </div>
                  </div>
                </div>
              </div>
            }

            <TextInput
              type={'number'}
              min={'0'}
              label={'Сортировка'}
              textTooltip={'Сортировка'}
              placeholder={'564'}
              name={'position'}
              value={data?.position || ''}
              onChange={(value: any) => updateFieldNoRender('position', value)}
              errors={errors}
            />

            <SingleSimpleValueSelect
              name={'overlapType'}
              label={'Повторный проход'}
              textTooltip={'Повторный проход'}
              placeholder={'Выберите тип'}
              value={data?.overlapType}
              options={data?.initialActionType == startActionOptionsEnum.CART ? overlapTypes?.filter((type: any) => type.value == overlapTypesEnum.ALLOW) : overlapTypes || []}
              onChange={(option: any) => {
                updateField('overlapType', option)
              }}
              howMushShowItems={5}
              required={true}
              errors={errors}
            />

            <SimpleMultipleValueSelect
              label={'Филиалы'}
              textTooltip={'Филиалы'}
              options={branches?.map((branch: any) => ({label: branch.title, value: branch.id}))}
              value={data?.branches || []}
              onChange={(value: any) => updateField('branches', value)}
              name={'branches'}
              placeholder={'Выберите филиалы'}
              errors={errors}
            />

            <RangeDatePicker
              required={false}
              values={[data?.startAt, data?.endAt]}
              label={'Период активности'}
              textTooltip={'Период активности'}
              placeholder='Выберите промежуток дат'
              onChange={([startAt, endAt]: any) => {
                updateField('startAt', startAt)
                updateField('endAt', endAt)
              }}
              errors={errors}
              name={'activityDate'}
            />

            <div className={'flex flex-row gap-x-14'}>
              <Checkbox
                label={'Активность воронки'}
                name={'isActive'}
                onChange={(value: any) => updateField('isActive', value)}
                value={data.isActive}
                errors={errors}
                required={true}
              />
            </div>


          </div>
        </div>

        <div className="flex flex-row justify-between py-4 px-4 gap-y-4">
            {
              showDenyButton && <BaseButton className={'bg-error-font border-2 text-white border-error-font py-[7px]'} onClick={() => {
                onResolve({isConfirmed: false, isDenied: true})
              }}>{denyButtonText}</BaseButton>
            }
            {
              isEdit && <BaseButton className={'bg-error-font border-2 text-white border-error-font py-[7px]'} onClick={() => {
                removeFunnel();
              }}>Удалить</BaseButton>
            }
          <div className={`flex flex-row gap-x-4 items-center justify-between`}>
            {
              showCancelButton && <BaseButton className={'bg-white border-2 text-error-font border-error-font py-[7px]'} onClick={() => {
                onReject({isCancelled: true})
              }}>{cancelButtonText}</BaseButton>
            }
            {
              showSubmitButton &&
                <BaseButton className={'bg-interactive-elem border-2 border-interactive-elem text-white py-[7px]'} onClick={() => {
                  onResolve({isConfirmed: true, isDenied: false})
                }}>{confirmButtonText}</BaseButton>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
