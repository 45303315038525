import {apiClient} from "../../libs/api/apiClient";
import {showErrorToast} from "../../libs/helpers/toasts";

enum ActionEnum {
  detach = 'detach',
  attach = 'attach',
}

enum Tabs {
  stocks = 'stocks',
}

export const submitAction = (callback: any, action: string, tab: string, companyId: any, branchId: any, data: any) => {
  switch (tab) {
    case Tabs.stocks:
      switch (action) {
        case ActionEnum.attach:
          bulkStocksAttach(callback, companyId, branchId, data)
          break
        case ActionEnum.detach:
          bulkStocksDetach(callback, companyId, branchId, data)
          break
        default:
          break
      }
      break

    default:
      break
  }
}

 const bulkStocksAttach = (callback: any, companyId: any, branchId: any, data: any) => {
   apiClient.stocks.bulkAttach(companyId, branchId, {body: data})
     .then(({data, errors, message}: any) => {
       if (errors || message) {
         showErrorToast({
           content: 'Что-то пошло не так'
         })
       } else {
         callback()
       }
     })
     .catch(() => {
       showErrorToast({
         content: 'Что-то пошло не так'
       })
     })
 }

 const bulkStocksDetach = (callback: any, companyId: any, branchId: any, data: any) => {
   apiClient.stocks.bulkDetach(companyId, branchId, {body: data})
     .then(({data, errors, message}: any) => {
       if (errors || message) {
         showErrorToast({
           content: 'Что-то пошло не так'
         })
       } else {
         callback()
       }
     })
     .catch(() => {
       showErrorToast({
         content: 'Что-то пошло не так'
       })
     })
 }
