import React, {useState} from "react";
import {Link} from "react-router-dom";

import BaseButton from "../../../components/common/Button/BaseButton";
import {useClientsContext} from "../../../contexts/Clients";
import TextInput from "../../../components/common/form/input/TextInput";
import SingleSimpleSelect from "../../../components/common/form/select/SingleSimpleSelect";
import DataTable from "../../../components/common/Table/DataTable";
import {createColumnHelper} from "@tanstack/react-table";
import Actions from "../../../components/common/Table/Actions";
import SingleSimpleValueSelect from "../../../components/common/form/select/SingleSimpleValueSelect";
import TagLabel from "../../../components/common/form/select/datatable/tags/TagLabel";
import {getDateAndTime} from "../../../libs/helpers/datetime";
import {TagsFilter} from "../../../components/common/Table/Filters/TagsFilter";
import {useCustomersTagsContext} from "../../../contexts/Tags";
import useForm from "../../../hooks/useForm";
import {useBranchesContext} from "../../../contexts/Branches";
import {TagsMultipleSelect} from "../../../components/common/form/select/datatable/tags/TagsMultipleSelect";
import {submitAction} from "../massActions";
import {useCompaniesContext} from "../../../contexts/Companies";
import {showSuccessToast} from "../../../libs/helpers/toasts";
import Export from "../../../components/common/Export";
import { ExportBlocks } from "../../../components/common/Export/helpers";


export default function AllClients() {
  const {
    clients,
    updateFilters,
    filters,
    updateClients,
    pagination,
    loading
  }: any = useClientsContext()
  const {branch} = useBranchesContext()
  const {company} = useCompaniesContext()
  const {customersTags, updateCustomersTags, loading: loadingTags}: any = useCustomersTagsContext()
  const {data, updateField, updateData, errors, submit, changedFieldsRefresh} = useForm({
    customersIds: [],
    tagsIds: []
  })

  const [search, setSearch] = useState('')
  const [action, setAction] = useState(null)
  const [applyFilter, setApplyFilter] = useState(false)
  const [filter, setFilter] = useState(null)
  const [countFilterFrom, setCountFilterFrom] = useState<any>(0)
  const [countFilterTo, setCountFilterTo] = useState<any>(0)
  const [orderBy, setOrderBy] = useState<any>(null)
  const [order, setOrder] = useState('asc')

  const searching = (value: any) => {
    setApplyFilter(true)
    setSearch(value || '')
    updateFilters('search', value || null, false)
  }

  const statisticFields = [
    {
      months: 1,
      sumOrderField: 'ordersSumOneMonth',
      countOrderField: 'ordersCountOneMonth'
    },
    {
      months: 3,
      sumOrderField: 'ordersSumThreeMonths',
      countOrderField: 'ordersCountThreeMonths'
    },
    {
      months: 6,
      sumOrderField: 'ordersSumSixMonths',
      countOrderField: 'ordersCountSixMonths'
    },
    {
      months: 12,
      sumOrderField: 'ordersSumYear',
      countOrderField: 'ordersCountYear'
    }
  ]

  //not working
  const submitMassAction = () => {
    //console.log(action)
    if (action === null) return
    submit((form: any) => {
      //console.log([...form.entries()])
      submitAction(() => {
        showSuccessToast({
          content: 'Действие выполнено успешно'
        })
        changedFieldsRefresh()
        updateClients(filters)
        updateData({
          customersIds: [],
          tagsIds: []
        })
        setAction(null)
      }, action, 'allClients', company.id, branch.id, form)
    })
  }

  const columnHelper = createColumnHelper<any>()
  const columnTags = createColumnHelper<any>()

  const findData = () => {
    setApplyFilter(true)
    updateClients({...filters, page: 1})
  }

  //-------------------------column clients------------------------
  const columnsClients = [
    columnHelper.accessor(row => row.id, {
      id: 'id',
      header: () => 'ID',
      cell: info => <div
        className={'w-[80px] font-normal break-all flex justify-center items-center'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[80px] flex justify-center text-center',
        sortField: 'id'
      }
    }),
    columnHelper.accessor(row => row.lastOrderAt, {
      id: 'lastOrder',
      header: () => 'Посл. заказ',
      cell: info => <div className={'w-[120px] max-w-[120px] font-normal flex text-center justify-center items-center break-words text-interactive-text'}>
        {info.getValue() === null ? 'нет' : getDateAndTime(info.getValue())}
      </div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[120px] max-w-[120px] text-center justify-center',
        sortField: 'last_order_at'
      }
    }),
    columnHelper.accessor(row => row.name, {
      id: 'client',
      header: () => 'Клиент',
      cell: info =>
        <div className={'flex flex-col grow ml-2 py-2'}>
          <Link className={'text-interactive-elem'} to={info.row?.original?.id != null ? `/clients/${info.row?.original?.id}/edit` : '/clients'}>
            <p className={"font-medium text-base"}>{info.getValue()}</p>
          </Link>
          {
            info.row?.original?.phone &&
              <Link to={info.row?.original?.id != null ? `/clients/${info.row?.original?.id}/edit` : '/clients'}>
                  <div>{info.row?.original?.phone}</div>
              </Link>
          }
          <div className={'flex flex-col w-auto'}>
            {
              (info.row?.original?.tags && info.row?.original?.tags.length) ?
                info.row?.original?.tags.map((tag: any) => {
                  return <TagLabel classNameBorder={'w-fit'} marginTags={'my-1'} backgroundColor={tag.background} textColor={tag.color}>{tag.title}</TagLabel>
                })
                : <></>
            }
          </div>
        </div>,
      enableSorting: false,
      meta: {
        widthClass: 'grow ml-2',
      }
    }),
  ]

  //-------------------------column tags------------------------
  const columnsTags = [
    columnTags.accessor(row => row.code, {
      id: 'code',
      header: () => 'Код',
      cell: info => <div
        className={'w-[100px] flex items-center text-center mx-4 [&:not(:hover)]:truncate [&:not(:hover)]:z-50'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[120px] flex items-center justify-center text-center mx-2'
      }
    }),
    columnTags.accessor(row => row.text, {
      id: 'text',
      header: () => 'Метки (Теги)',
      cell: info =>
        <TagLabel
          img={info?.row?.original?.icon}
          textColor={info.row?.original?.color}
          backgroundColor={info.row?.original?.background}>
          {info.getValue()}
        </TagLabel>,
      enableSorting: false,
      meta: {
        widthClass: 'min-w-[80px] flex items-center justify-center text-center',
      }
    }),
  ]

  for (let x of statisticFields) {
    columnsClients.push(columnHelper.accessor(row => row.id, {
      id: 'position',
      header: () => `За ${x.months} мес.`,
      cell: info => <div className={'w-[max(100px,10%)] flex flex-col font-normal break-all items-center justify-center'}>
        <div>{info?.row?.original[x.countOrderField]}</div>
        {
          info?.row?.original[x.sumOrderField] && <div>({info?.row?.original[x.sumOrderField]} руб.)</div>
        }

      </div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[max(100px,10%)] justify-center',
        sortField: 'position'
      }
    }))
  }

  if (!branch) {
    return <div>
      <div className="px-8 pb-8 pt-2">
        <div className="flex flex-col justify-between">
          <div>
            <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Клиенты</div>
            <div className="text-gray-40 text-base">Список клиентов</div>
          </div>
          <div className="text-gray-40 text-3xl text-center mt-[35vh]">
            Выберите филиал
          </div>
        </div>
      </div>
    </div>
  }

  if (!applyFilter && clients?.length === 0) return (
    <div className="px-8 pb-8 pt-2">
      <div className="flex flex-col gap-y-6">
        <div className="flex justify-between items-end">
          <div>
            <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Клиенты</div>
            <div className="text-gray-40 text-base">Список клиентов</div>
          </div>
          <Link to={'create'}>
            <BaseButton className={"ml-5 bg-interactive-elem text-white"}>Добавить клиента</BaseButton>
          </Link>
        </div>
      </div>
      <div className="text-gray-40 text-3xl text-center mt-[35vh]">
        {loading? 'Загрузка...' : 'Клиентов нет'}
      </div>
    </div>
  )


  return (
    <div className="px-8 pb-8 pt-2">
      <div className="flex flex-col gap-y-6">
        <div className="flex justify-between items-end">
          <div>
            <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Клиенты</div>
            <div className="text-gray-40 text-base">Список клиентов</div>
          </div>
        </div>
        <div className={"flex flex-row gap-x-4"}>
          <div className={"flex gap-x-4 w-[55%]"}>
            <div className={"w-[50%]"}>
              <SingleSimpleValueSelect
                label={'Фильтр'}
                name={'xs'}
                placeholder={'Кол-во заказов за n мес.'}
                value={filter}
                options={[
                  {label: 'Сбросить', value: 'reset'},
                  {label: 'Кол-во заказов за 1 мес.', value: 'ordersCountOneMonth'},
                  {label: 'Кол-во заказов за 2 мес.', value: 'ordersCountTwoMonth'},
                  {label: 'Кол-во заказов за 3 мес.', value: 'ordersCountThreeMonth'},
                  {label: 'Кол-во заказов за 4 мес.', value: 'ordersCountFourMonth'},
                  {label: 'Кол-во заказов за 5 мес.', value: 'ordersCountFiveMonth'},
                  {label: 'Кол-во заказов за 6 мес.', value: 'ordersCountSixMonth'},
                  {label: 'Кол-во заказов за 7 мес.', value: 'ordersCountSevenMonth'},
                  {label: 'Кол-во заказов за 8 мес.', value: 'ordersCountEightMonth'},
                  {label: 'Кол-во заказов за 9 мес.', value: 'ordersCountNineMonth'},
                  {label: 'Кол-во заказов за 10 мес.', value: 'ordersCountTenMonth'},
                  {label: 'Кол-во заказов за 11 мес.', value: 'ordersCountElevenMonth'},
                  {label: 'Кол-во заказов за 12 мес.', value: 'ordersCountYear'},
                ]}
                onChange={(option: any) => {
                  setFilter(option)
                  if (option === 'reset') {
                    setFilter(null)
                    updateFilters('filter', {tags: filters.filter?.tags})
                    setCountFilterFrom(0)
                    setCountFilterTo(0)
                    return
                  }
                  updateFilters('filter', {tags: filters.filter?.tags, [option]: {to: countFilterTo, from: countFilterFrom}})
                }}
              />
            </div>
            <div className={"w-[25%]"}>
              <TextInput
                type={'number'}
                min={'0'}
                label={'Количество От'}
                placeholder={'Введите кол-во'}
                value={countFilterFrom}
                onChange={(value: any) => {
                  setCountFilterFrom(value)
                  if (filter) {
                    updateFilters(`filter.${filter}.from`, value)
                  }
                }}
               errors={{}}
               name={''}
              />
            </div>
            <div className={"w-[25%]"}>
              <TextInput
                type={'number'}
                min={'0'}
                label={'Количество До'}
                placeholder={'5'}
                value={countFilterTo}
                onChange={(value: any) => {
                  setCountFilterTo(value)
                  if (filter) {
                    updateFilters(`filter.${filter}.to`, value)
                  }
                }}
                errors={{}}
                name={''}
              />
            </div>
          </div>
          <div className={"flex gap-x-4 w-[45%]"}>
            <div className={'w-[60%]'}>
              <SingleSimpleSelect
                label={'Сортировка'}
                name={'sort'}
                placeholder={'Кол-во заказов за n мес.'}
                value={orderBy}
                options={[
                  {label: 'Сбросить', value: 'reset'},
                  {label: 'Кол-во заказов за 1 мес.', value: 'ordersCountOneMonth'},
                  {label: 'Кол-во заказов за 2 мес.', value: 'ordersCountTwoMonth'},
                  {label: 'Кол-во заказов за 3 мес.', value: 'ordersCountThreeMonth'},
                  {label: 'Кол-во заказов за 4 мес.', value: 'ordersCountFourMonth'},
                  {label: 'Кол-во заказов за 5 мес.', value: 'ordersCountFiveMonth'},
                  {label: 'Кол-во заказов за 6 мес.', value: 'ordersCountSixMonth'},
                  {label: 'Кол-во заказов за 7 мес.', value: 'ordersCountSevenMonth'},
                  {label: 'Кол-во заказов за 8 мес.', value: 'ordersCountEightMonth'},
                  {label: 'Кол-во заказов за 9 мес.', value: 'ordersCountNineMonth'},
                  {label: 'Кол-во заказов за 10 мес.', value: 'ordersCountTenMonth'},
                  {label: 'Кол-во заказов за 11 мес.', value: 'ordersCountElevenMonth'},
                  {label: 'Кол-во заказов за 12 мес.', value: 'ordersCountYear'},
                ]}
                onChange={(option: any) => {
                  setOrderBy(option)
                  if (option.value === 'reset') {
                    setOrderBy(null)
                    updateFilters('order', null)
                    setOrder('asc')
                    return
                  }
                  updateFilters(`order.${option.value}`, order)
                }}
              />
            </div>
            <div className={'w-[40%]'}>
              <SingleSimpleValueSelect
                label={'Тип'}
                name={'type'}
                placeholder={'По возрастанию'}
                value={order}
                options={[
                  {label: 'По возврастанию', value: 'asc'},
                  {label: 'По убыванию', value: 'desc'},
                ]}
                onChange={(option: any) => {
                  setOrder(option)
                  updateFilters(`order.${orderBy?.value}`, option)
                }}
              />
            </div>
          </div>
        </div>
        <div className={"w-[55%]"}>
          <TagsFilter
            innerPadding={'py-[5px] px-2 rounded-xl'}
            data={customersTags?.map(({title, ...o}: any) => ({text: title, ...o})) || []}
            values={data.tags}
            errors={errors}
            name={'tags'}
            loading={loadingTags}
            placeholder={'Добавьте метки'}
            buttonPlaceholder={'Добавить'}
            updateValues={(values: any) => {
              updateFilters('filter.tags', values)
              updateField('tags', values)
              updateCustomersTags()
            }}
            updateData={() => {
              console.log('update')
            }}
            columns={columnsTags}
            removeItem={(index: any) => {
              let options = data.tags.slice()
              options.splice(index, 1)
              updateField('tags', options)
              updateFilters('filter.tags', options)
              updateCustomersTags()
            }}
          />
        </div>
        <Actions
          updateValue={(value: any) => {
            searching(value)
          }}
          fetchData={(value: any) => {}}
          searchValue={search}
        >
          <div className={'flex flex-row items-center justify-between w-full'}>
            <BaseButton onClick={() => findData()} className={"ml-5 bg-interactive-elem text-white"}>Найти</BaseButton>
            <Link to={'create'}>
              <BaseButton className={"ml-5 bg-interactive-elem text-white"}>Добавить клиента</BaseButton>
            </Link>
          </div>

        </Actions>
        
        <Export
          title={'Экспорт клиентов'}
          types={[{ title: 'Экспортировать', type: ExportBlocks.customers }]}
          filter={filters || null}
          order={pagination?.order || null}
        />
        
        <DataTable
          data={clients}
          columns={columnsClients}
          usePagination={true}
          defaultLimit={10}
          defaultPage={1}
          pagination={pagination}
          updateData={(params: any) => updateClients(params)}
          loading={loading}
          useMassAction={true}
          selectedRows={data.customersIds || []}
          onChangeSelectionRows={(values: any) => {
            updateField('customersIds', values)
          }}
          classNameTable={'mt-0'}
        />
        {data.customersIds?.length > 0 && <div className={"w-full rounded-xl bg-white px-3 py-5 mb-20 flex items-center justify-between"}>
          <div className={"w-5/6 flex items-center justify-center gap-x-4"}>
            <SingleSimpleValueSelect
              name={'xs'}
              placeholder={'Выберите действие'}
              value={action}
              options={[
                {label: 'Выберите действие', value: null},
                {label: 'Добавить метку', value: 'attach'},
                {label: 'Удалить метку', value: 'detach'}
              ]}
              onChange={(option: any) => {
                setAction(option)
              }}
            />

            {
              (action === 'attach' || action === 'detach') &&
                <div className={"w-full"}>
                    <TagsMultipleSelect
                        innerPadding={'py-[5px] px-2 rounded-xl'}
                        data={customersTags?.map(({title, ...o}: any) => ({text : title, ...o})) || []}
                        values={data.tagsIds}
                        errors={errors}
                        name={'tagsIds'}
                        loading={loading}
                        placeholder={'Выберите метки'}
                        updateValues={(values: any) => updateField('tagsIds', values)}
                        updateData={() => {
                          console.log('update')
                        }}
                        limitsMode={true}
                        columns={columnsTags}
                        buttonPlaceholder={'Изменить метки'}
                        textTooltip={'Метки'}
                        removeItem={(index: any) => {
                          let options = data.tagsIds.slice()
                          options.splice(index, 1)
                          updateField('tagsIds', options)
                        }}
                    />
                </div>

            }
          </div>

          <BaseButton onClick={submitMassAction}
            className={"w-[250px] ml-5 bg-interactive-elem text-white"}>Изменить {data.customersIds?.length} клиентов
          </BaseButton>
        </div>}
      </div>


    </div>
  )
}
