import {apiClient} from "../../libs/api/apiClient";

async function fetchPlatforms() {
  const { data }: any = await apiClient.dictionaries.platforms()

  return data.map((platform: any) => ({
    label: platform.title,
    value: platform.value
  }))
}

export default fetchPlatforms
