import React, {useEffect, useState} from "react";
import TextInput from "./TextInput";
import Tooltip from "../../Tooltip";
// @ts-ignore
import deleteIcon from "../../../../images/delete.svg";

interface IProps {
  name: string,
  values: any[],
  fields: any[],
  labels?: any[],
  placeholders: any[],
  types?: any[],
  fieldTypes: any[],
  options?: any,
  textTooltips?: any[],
  disabled?: boolean,
  readOnly?: boolean,
  onChange: (value: any) => void,
  autoComplete?: string,
  className?: string,
  label?: string,
  errors: any,
  required?: boolean,
  textTooltip?: any,
  remove?: boolean,
  lengthCounter?: any[],
  maxLengthInput?: any[],
  maxLength?: any,
}

//Этот компонент создан для выставления ограничений по длине строки (чтобы понимать максимальное кол-во введенных символов)
//а также чтобы не городить все в одном компоненте
//Это не есть хорошо, что повторяется весь функционал, но пока есть что есть:)
// P.S. при наличии времени и желании можно что то придумать
export default function TextInputMultipleListWithRestriction(
  {
    name,
    values = [],
    fields = [],
    labels = [],
    placeholders = [],
    types = [],
    fieldTypes = [],
    options = [[], [], []],
    textTooltips = [],
    disabled = false,
    readOnly = false,
    onChange = (value: any) => {},
    autoComplete = 'off',
    className = '',
    label = '',
    errors = {},
    required = false,
    textTooltip = '',
    remove = false,
    lengthCounter=[],
    maxLengthInput = [],
    maxLength = null,
    ...props
  }: IProps) {
  const error = errors[name];
  const [tempValues, setTempValues] = useState<any>([])

  useEffect(() => {
    let item: any = {}
    fields.forEach(field => item[field] = '')
    values ? maxLength && values.length + 1 > maxLength ? setTempValues([...values]) : setTempValues([...values, item]) : onChange([]);

    delete errors[name];
  }, [values]);

  return (
    <div className={`${className} py-3 px-4 border-gray-20 border-[1px] rounded-lg`}>
      {
        label &&
          <label className="flex items-center mb-2 font-medium text-sm">
            {label}
              <span className='text-error-main'>{required && '*'}</span>
            {
              textTooltip && <Tooltip textTooltip={textTooltip} defaultText={name}/>
            }
          </label>
      }
      {
        labels &&
          <>
              <div className="flex items-center gap-2 mb-2">
                {
                  labels.map((item, index) => (
                    <div key={item} className="font-medium" style={{flexBasis: `${100 / labels.length}%`}}>
                      <div className="flex items-center">
                        {item}
                        {textTooltips.at(index) && <Tooltip textTooltip={textTooltips.at(index)}/>}
                      </div>
                    </div>
                  ))
                }
                  <div className="w-14"></div>
              </div>
          </>
      }
      <div className="flex flex-col gap-y-3">
        {
          tempValues && tempValues.map((row: any, index: any) => (
            <div key={`${name}.${index}`} className="flex items-center gap-2">
              {
                fields.map((field, fieldIndex) => (
                  <div className={'w-full relative'}>
                    <TextInput
                      key={`${name}.${index}.${field}`}
                      type={types.at(fieldIndex) || 'text'}
                      name={`${name}.${index}.${field}`}
                      value={tempValues[index][field]}
                      disabled={disabled}
                      onBlur={(value: any) => {
                        let temp: any = tempValues.slice(0);

                        temp[index][field] = value
                        temp = temp.filter((row: any) => Object.values(row).some(value => !!value))

                        onChange(temp)
                      }}
                      readOnly={readOnly}
                      placeholder={placeholders.at(fieldIndex)}
                      className="grow"
                      errors={errors}
                      errorLabel={false}
                      autoComplete={autoComplete}
                      maxLength={maxLengthInput[fieldIndex]}
                      showLength
                      {...props}
                    />
                  </div>
                ))
              }
              {
                remove && <img src={deleteIcon} alt=""
                               className={'cursor-pointer mx-2 ' + (index === tempValues.length - 1 ? 'opacity-0' : '')}
                               onClick={() => {
                                 if (index !== tempValues.length - 1) setTempValues(tempValues.filter((_: any, i: any) => i !== index))
                               }}/>
              }
              <span className="text-sm font-bold w-12">№{index + 1}</span>
            </div>
          ))
        }
      </div>
      {error && (<span className={'text-error-font text-xs font-medium'}>{error}</span>)}
    </div>

  )
}
