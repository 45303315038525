const tabs = [
  {
    label: 'Основная информация',
    tag: 'base',
    required: true,
    fields: [
      'base.title',
      'base.timeZoneId',
      'base.slidesDesktop',
      'base.slidesMobile',
    ]
  },
  {
    label: 'Расширенная информация',
    tag: 'extended',
    required: true,
    fields: [
      'extended.regions',
      'extended.orderCities',
      'extended.openHours',
      'extended.allowOrderAfterHours',
      'extended.addresses',
      'extended.shortDescription',
      'extended.description',
      'extended.paymentDescription',
      'extended.deliveryDescription',
      'extended.stockDescription',
      'extended.cartText',
      'extended.orderPageText',
      'extended.footerText',
      'extended.currency',
      'extended.phoneCode',
      'extended.vkCommunities',
      'extended.allowAssignPhone',
      'extended.allowGuestOrderInMobile',
      'extended.allowedAuthorizeTypes',
    ]
  },
  {
    label: 'Уведомления',
    tag: 'notifications',
    fields: [
      'notifications.vkAdministrators',
      'notifications.vkAppOpenedNotify',
      'notifications.emailsForServiceNotifications',
      'notifications.apiSecret',
    ]
  },
  {
    label: 'Контактная информация',
    tag: 'contacts',
    required: true,
    fields: [
      'contacts.items',
      'contacts.telegramLink',
      'contacts.iosAppLink',
      'contacts.androidAppLink'
    ]
  },
  {
    label: 'Оформление заказа',
    tag: 'order',
    required: true,
    fields: [
      'order.pickupOnly',
      'order.hideDeliveryPrice',
      'order.preorderAllowedTimes',
      'order.fields',
      'order.paymentTypes',
      'order.paymentTypes',
      'order.deliveryTypes',
      'order.pickupPoints',
    ]
  },
  {
    label: 'Внешние программы учета',
    tag: 'integrations',
    fields: [
      'integrations.locationSource',
      'notifications.emailsForErrorNotifications',
      'notifications.vkForErrorNotifications',
      'notifications.tgForErrorNotifications',
      'integrations.frontpad',
      //'integrations.iiko',
      'integrations.partner',
    ]
  },
  {
    label: 'Платежная системы',
    tag: 'paymentSystems',
    fields: [
      'paymentSystems.sendOnlyAfterPayment',
      'paymentSystems.yooKassa',
    ]
  },

  {
    label: 'Прочие интеграции',
    options: [
      {
        label: 'Метрики',
        tag: 'metrics',
        fields: [
          'integrations.keys',
          'integration.ymetrika',
          'integration.googleAnalytics',
          'integration.yandexWebmaster',
          'integration.googleWebmaster',
          'integration.vkWidget',
          'integration.yandexGeo',
          'integration.vkRetarget',
          'integration.facebookPixel',
        ]
      },
      {
        label: 'Сервисы рассылок',
        tag: 'mailing',
        fields: [
          'integrations.senler',
          'integrations.thModerator'
        ]
      },
    ],
  },
  {
    label: 'СМС Сервисы',
    tag: 'sms',
    fields: [
      'integrations.smsService',
      'integrations.phoneCallService'
    ]
  },
  {
    label: 'Шаблоны сообщений',
    tag: 'messageTemplates',
    fields: [
      'messageTemplates'
    ]
  },
]

export default tabs
