import React, {useEffect, useRef} from "react";

export default function MapBase({onMapInit, className, mapRef = null}: any) {
  let mapElemRef = useRef<any>();
  let mapObjRef = useRef<any>();

  const tryInitMap = (s: any) => {
    if (mapObjRef.current || s.stop)
      return;
    //console.log("trying to init ymaps")
    ymaps.ready(() => {
      if (mapElemRef.current) {
        if (mapObjRef.current == null) {
          //console.log("creating ymaps")
          mapObjRef.current = new ymaps.Map(mapElemRef.current, {
            center: [55.76, 37.64],
            zoom: 7
          })
          if (mapRef) mapRef.current = mapObjRef.current;
          onMapInit(mapObjRef.current);
        }
      } else {
        setTimeout(() => tryInitMap(s), 500)
      }
    })
  }

  useEffect(() => {
    let s = { stop: false };
    tryInitMap(s);
    return () => { s.stop = true; }
  })

  return (
    <div ref={mapElemRef} className={className}></div>
  )
}
