import React from "react";
import {createColumnHelper} from "@tanstack/react-table";
import DataTable from "../../../components/common/Table/DataTable";
import Checkbox from "../../../components/common/form/checkbox/Checkbox";
import {Link} from "react-router-dom";
import {useNpsQuestionContext} from "../../../contexts/NPS";
import {useBranchesContext} from "../../../contexts/Branches";

export default function Questions() {
  const columnHelper = createColumnHelper<any>()
  const {npsQuestions, updateNpsQuestions, loading, pagination, filters}: any = useNpsQuestionContext()

  const columns = [
    columnHelper.accessor(row => row.id, {
      id: 'id',
      header: () => 'ID',
      cell: info => <div
        className={'w-[70px] font-normal break-all flex justify-center items-center mx-2'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[70px] flex justify-center text-center mx-2',
      }
    }),
    columnHelper.accessor(row => row.text, {
      id: 'text',
      header: () => 'Вопрос',
      cell: info => <div className={"w-2/3 py-2 font-normal flex text-interactive-elem flex-col justify-center items-start"}>
        <Link to={info.row?.original?.id != null ? `/nps/${info.row?.original?.id}/edit-question` : '/nps'}>
          {info.getValue()}
        </Link>
      </div>,
      enableSorting: false,
      meta: {
        widthClass: "w-2/3 flex justify-start",
      }
    }),
    columnHelper.accessor(row => row.position, {
      id: 'position',
      header: () => 'Сортировка',
      cell: info => <div
        className={'w-[110px] font-normal break-all flex justify-center items-center '}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[110px] flex justify-center text-center ',
      }
    }),
    columnHelper.accessor(row => row.isRequired, {
      id: 'isRequired',
      header: () => 'Обязательный',
      cell: info => <div className={" w-[130px] flex justify-center items-center"}>
        <Checkbox
          value={info.getValue()}
          onChange={() => {}}
          name={'isRequired'}
          checkboxClassName={'cursor-default'}
        />
      </div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[130px] flex justify-center text-center',
      }
    }),
  ]

  return (
      <DataTable
        data={npsQuestions || []}
        columns={columns}
        usePagination={true}
        pagination={pagination}
        loading={loading}
        defaultLimit={10}
        defaultPage={1}
        updateData={(params: any) => updateNpsQuestions(params, filters)}
        classNameTable={'mt-0'}
      />
  )
}
