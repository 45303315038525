import React from "react";
import ActionSelect from "./select/ActionSelect";

interface IProps {
  data: any,
  conditions?: any
  actions: any,
  inputs?: any,
  updateField: (field: string, value: any) => void,
  errors?: any
}

export default function ActionNodeBase(
  {
    conditions,
    actions,
    data,
    updateField,
    errors
  }: IProps) {

  return (
    <div className={'flex flex-col gap-y-6 text-black py-8 overflow-y-auto min-h-[70%]'}>
      <ActionSelect
        conditions={conditions}
        value={data?.actions || {}}
        actions={actions}
        onChange={(value: any) => updateField('actions', value)}
        errors={errors}
      />
    </div>
  )
}
