import React, {useState} from "react";
import {Link} from "react-router-dom";

import tabs from "./tabs";
import {
  LoyaltyStatusContextWrapper,
  LoyaltyRulesContextWrapper,
  LoyaltyTransactionsContextWrapper
} from "../../contexts/LoyaltyPrograms"
import FormWrapper from "../../components/common/form/FormWrapper";
import BaseButton from "../../components/common/Button/BaseButton";
import CreateFormFields from "../../components/common/form/CreateFormFields";
import TableStatuses from "./Statuses"
import TableRules from "./Rules"
import TableTransactions from "./Transactions"
import {useBranchesContext} from "../../contexts/Branches";

export default function LoyaltyPrograms() {
  const [tab, setTab] = useState('statuses')
  const {branch} = useBranchesContext()

  if (!branch) {
    return <div>
      <div className="px-8 pb-8 pt-2">
        <div className="flex flex-col justify-between">
          <div>
            <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Программа лояльности</div>
          </div>
          <div className="text-gray-40 text-3xl text-center mt-[35vh]">
            Выберите филиал
          </div>
        </div>
      </div>
    </div>
  }


  return (
    <div className="px-8 pb-8 pt-2">
      <div className="flex flex-col gap-y-6">
        <div className="flex justify-between items-end">
          <div>
            <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Программа лояльности</div>
            <div className="text-gray-40 text-base">Список {tab === 'statuses' ? 'статусов' : tab === 'rules' ? 'правил' : 'транзакций'}</div>
          </div>
          {tab === 'statuses' && <Link to={'create-status'}>
              <BaseButton className={"ml-5 bg-interactive-elem text-white"}>Добавить статус</BaseButton>
          </Link>}
          {tab === 'rules' && <Link to={'create-rule'}>
              <BaseButton className={"ml-5 bg-interactive-elem text-white"}>Добавить правило</BaseButton>
          </Link>}
          {tab === 'transactions' && <Link to={'create-transaction'}>
              <BaseButton className={"ml-5 bg-interactive-elem text-white"}>Добавить транзакцию</BaseButton>
          </Link>}
        </div>
      </div>
      <div className={'mt-8'}>

        <FormWrapper
          tabs={tabs}
          tab={tab}
          setTab={setTab}
          heightForm={'min-h-[350px]'}
        >
          <CreateFormFields
            show={tab === 'statuses'}
            setTab={setTab}
          >
            <LoyaltyStatusContextWrapper>
              <TableStatuses />
            </LoyaltyStatusContextWrapper>
          </CreateFormFields>

          <CreateFormFields
            show={tab === 'rules'}
            setTab={setTab}
          >

            <LoyaltyRulesContextWrapper>
              <TableRules/>
            </LoyaltyRulesContextWrapper>
          </CreateFormFields>

          <CreateFormFields
            show={tab === 'transactions'}
            setTab={setTab}
          >
            <LoyaltyTransactionsContextWrapper>
              <TableTransactions/>
            </LoyaltyTransactionsContextWrapper>
          </CreateFormFields>

        </FormWrapper>
      </div>
    </div>
  )
}
