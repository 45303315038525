import React, {useEffect, useState} from "react";
import TextInput from "../../../components/common/form/input/TextInput";
import Textarea from "../../../components/common/form/input/Textarea";
import SingleSimpleValueSelect from "../../../components/common/form/select/SingleSimpleValueSelect";
import Checkbox from "../../../components/common/form/checkbox/Checkbox";
import SimpleMultipleValueSelect from "../../../components/common/form/select/multiple/SimpleMultipleValueSelect";
import {useBranchesContext} from "../../../contexts/Branches";
import TextInputMultipleList from "../../../components/common/form/input/TextInputMultipleList";
import {TagsMultipleSelect} from "../../../components/common/form/select/datatable/tags/TagsMultipleSelect";
import {usePromoTagsContext} from "../../../contexts/Tags";
import TagLabel from "../../../components/common/form/select/datatable/tags/TagLabel";
import {createColumnHelper} from "@tanstack/react-table";
import {useProductsContext} from "../../../contexts/Products";
import {DatatableSelect} from "../../../components/common/form/select/datatable/DatatableSelect";
import {useCategoriesContext} from "../../../contexts/Categories";
import CheckboxList from "../../../components/common/form/checkbox/CheckboxList";
import fetchPlatforms from "../../../providers/dictionaries/platforms";
import RangeDatePicker from "../../../components/common/form/datetime/RangeDatePicker";
import {fetchDeliveryTypes, fetchStatuses, fetchTypes} from "../../../providers/dictionaries/promoCodes";
import SimpleDatePicker from "../../../components/common/form/datetime/SimpleDatePicker";

enum ExportTypeEnum {
  PERSENT = 'percent',
  FIXED = 'fixed',
  FREE = 'free',
  GIFT = 'gift',
  CART_RULES = 'cart-rules',
}

export default function Base({data, errors, updateField, update = false, updateFieldNoRender}: any) {
  const [generate, setGenerate] = useState(false)
  const [platforms, setPlatforms] = useState([])
  const [types, setTypes] = useState([])
  const [statuses, setStatuses] = useState([])
  const [deliveryTypes, setDeliveryTypes] = useState([])

  const {branches} = useBranchesContext()
  const {promoTags, loading: loadingTags}: any = usePromoTagsContext()
  const {products, updateProducts, updateFilters, filters, loading : loadingProducts}: any = useProductsContext()
  const {categories} = useCategoriesContext()

  const columnHelper = createColumnHelper<any>()

  useEffect(() => {
    fetchPlatforms().then(data => {
      setPlatforms(data.map((platform: any) => ({ label: platform.label, name: platform.value })))
    })
    fetchTypes().then(data => {
      setTypes(data.map((type: any) => ({ label: type.label, value: type.value })))
    })
    fetchStatuses().then(data => {
      setStatuses(data.map((status: any) => ({ label: status.label, value: status.value })))
    })
    fetchDeliveryTypes().then(data => {
      setDeliveryTypes(data.map((type: any) => ({ label: type.label, name: type.value })))
    })
  }, [])

  //--------------------good columns---------------------
  const goodColumns = [
    columnHelper.accessor(row => row.id, {
      id: 'ID',
      header: () => 'ID',
      cell: info => <div className={'w-[85px] flex items-center justify-center text-center'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[85px] flex items-center justify-center text-center'
      }
    }),
    columnHelper.accessor(row => row.image, {
      id: 'image',
      header: () => 'Фото',
      cell: info =>
        <div className={'mr-4 w-[64px] flex items-center justify-center text-center'}>
          {
            info.getValue() ?
              <img src={info.getValue()} alt=""/> :
              <div
                className='h-[60px] w-[60px] flex items-center justify-center text-[10px] text-gray-40 border-[1.5px] border-btn-secondary rounded-xl'>Image</div>
          }
        </div>,
      enableSorting: false,
      meta: {
        widthClass: 'mr-4 w-[64px] flex items-center justify-center text-center'
      }
    }),
    columnHelper.accessor(row => row.title, {
      id: 'title',
      header: () => 'Название',
      cell: info =>
        <div className={'grow flex items-center'}>
          {info.getValue()}
        </div>,
      enableSorting: true,
      meta: {
        widthClass: 'grow',
        sortField: 'title'
      }
    }),
    columnHelper.accessor(row => row.weight, {
      id: 'weight',
      header: () => 'Вес',
      cell: info => <div className={'max-w-[48px] grow flex items-center justify-center'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'max-w-[48px] grow justify-center',
        sortField: 'weight'
      }
    }),
    columnHelper.accessor(row => row.price, {
      id: 'price',
      header: () => 'Цена',
      cell: info => <div className={'max-w-[75px] grow flex items-center justify-center'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'max-w-[75px] grow justify-center',
        sortField: 'price'
      }
    }),
  ]

  //--------------------tags columns----------------
  const columnsTags = [
    columnHelper.accessor(row => row.code, {
      id: 'code',
      header: () => 'Код',
      cell: info => <div
        className={'w-[120px] flex items-center justify-center text-center mx-2'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[120px] flex items-center justify-center text-center mx-2'
      }
    }),
    columnHelper.accessor(row => row.text, {
      id: 'title',
      header: () => 'Метки (Теги)',
      cell: info =>
        <TagLabel
          img={info?.row?.original?.icon}
          textColor={info.row?.original?.color}
          backgroundColor={info.row?.original?.background}>
          {info.getValue()}
        </TagLabel>,
      enableSorting: true,
      meta: {
        widthClass: 'min-w-[80px] flex items-center justify-center text-center',

      }
    }),
  ]

  return (
    <div className={'flex flex-col px-3 gap-y-6'}>

      <TextInput
        label={'Название'}
        textTooltip={'Название'}
        placeholder={'Введите название'}
        name={'title'}
        value={data.title}
        onChange={(value: any) => updateFieldNoRender('title', value)}
        errors={errors}
        required={true}
      />

      <div className={"flex flex-col gap-y-6"}>
        <p className={"font-medium text-sm"}>Массовая генерация промо-кодов</p>
        <Checkbox
          label={'Сгенерировать массово'}
          name={'generate'}
          onChange={(value: any) => updateField('generate', value)}
          value={data.generate}
          onClick={() => setGenerate(!generate)}
        />
        {
          generate ? (
              <div className={"flex flex-col border-gray-20 border-[1px] p-4 rounded-xl"}>
                <Textarea
                  label={'Шаблон кода'}
                  textTooltip={'Шаблон кода*'}
                  placeholder={'DL????'}
                  name={'codeTemplate'}
                  value={data.codeTemplate}
                  onChange={value => updateFieldNoRender('codeTemplate', value)}
                  errors={errors}
                  required={true}
                  maxLength={undefined}
                />
                <div className={"flex flex-col gap-y-5 my-2"}>
                  <div className={"flex flex-col"}>
                    <p className={"flex gap-x-2"}><p className={"text-interactive-text"}>#</p> — будет заменен на
                      случайную цифру
                    </p>
                    <p className={"flex gap-x-2"}><p className={"text-interactive-text"}>%</p> — будет заменен на
                      случайную букву латинского алфавита
                    </p>
                    <p className={"flex gap-x-2"}><p className={"text-interactive-text"}>?</p> — будет заменен на
                      случайную цифру или букву
                    </p>
                  </div>
                  <div className={"flex flex-col"}>
                    <p className={"flex gap-x-2"}>Пример 1: <p className={"text-interactive-text"}>DL####</p> (возможный
                      код DL3786)
                    </p>
                    <p className={"flex gap-x-2"}>Пример 2: <p className={"text-interactive-text"}>DL%%%%</p> (возможный
                      код DLAFGH)
                    </p>
                    <p className={"flex gap-x-2"}>Пример 3: <p className={"text-interactive-text"}>DL????</p> (возможный
                      код DL7A7Y)
                    </p>
                    <p className={"flex gap-x-2"}>Пример 4: <p className={"text-interactive-text"}>DL##%%??</p> (возможный
                      код DL51ABC9)
                    </p>
                  </div>
                </div>
                <TextInput
                  type={'number'}
                  min={'1'}
                  max={'100'}
                  label={'Количество промокодов от 1 до 100'}
                  textTooltip={'Количество промокодов от 1 до 100'}
                  placeholder={'56'}
                  name={'generateCount'}
                  value={data.generateCount}
                  onChange={(value: any) => updateFieldNoRender('generateCount', value)}
                  errors={errors}
                />
              </div>
            )
            : <Textarea
              label={'Коды'}
              textTooltip={'Коды'}
              placeholder={'DL777AB'}
              name={update ? 'code' : 'codes'}
              value={update ? data.code : data.codes}
              onChange={(value: string) => {
                update ? updateFieldNoRender('code', value) : updateFieldNoRender('codes', value.split(' '));
              }}
              errors={errors}
              required={true}
              maxLength={undefined}
            />
        }
      </div>


      <Textarea
        label={'Краткое описание'}
        textTooltip={'Краткое описание'}
        placeholder={'Введите краткое описание'}
        name={'shortDescription'}
        value={data.shortDescription}
        onChange={(value: any) => updateFieldNoRender('shortDescription', value)}
        errors={errors}
        maxLength={undefined}
      />

      <SingleSimpleValueSelect
        name={'status'}
        label={'Статус'}
        textTooltip={'Статус'}
        placeholder={'Активен'}
        value={data.status}
        options={statuses}
        onChange={(value: any) => updateField('status', value)}
        required={true}
      />

      <SingleSimpleValueSelect
        label={'Тип'}
        textTooltip={'Тип'}
        placeholder={'Скидка'}
        name={'type'}
        value={data.type}
        options={types}
        onChange={(value: any) => updateField('type', value)}
        required={true}
      />

      {data.type === ExportTypeEnum.PERSENT &&
          <>
              <TextInput
                  min={'0'}
                  max={'100'}
                  type={'number'}
                  label={'Величина скидки (%)'}
                  textTooltip={'Величина скидки (%)'}
                  placeholder={'Введите число (%)'}
                  name={'percentValue'}
                  value={data.percentValue}
                  onChange={(value: any) => updateFieldNoRender('percentValue', value)}
                  errors={errors}
                  required={true}
              />

              <TextInput
                  min={'0'}
                  max={'100'}
                  type={'number'}
                  label={'Величина скидки при условии самовывоза (%)'}
                  textTooltip={'Величина скидки при условии самовывоза (%)'}
                  placeholder={'Введите число (%)'}
                  name={'onPickupPercentValue'}
                  value={data.onPickupPercentValue}
                  onChange={(value: any) => updateFieldNoRender('onPickupPercentValue', value)}
                  errors={errors}
                  required={false}
              />
          </>
      }

      {data.type === ExportTypeEnum.FIXED &&
          <>
            <TextInput
                min={'0'}
                type={'number'}
                label={'Величина скидки (руб)'}
                textTooltip={'Величина скидки (руб)'}
                placeholder={'Введите число (руб)'}
                name={'fixedValue'}
                value={data.fixedValue}
                onChange={(value: any) => updateFieldNoRender('fixedValue', value)}
                errors={errors}
                required={true}
            />

            <TextInput
                min={'0'}
                type={'number'}
                label={'Величина скидки при условии самовывоза (руб)'}
                textTooltip={'Величина скидки при условии самовывоза (руб)'}
                placeholder={'Введите число (руб)'}
                name={'onPickupFixedValue'}
                value={data.onPickupFixedValue}
                onChange={(value: any) => updateFieldNoRender('onPickupFixedValue', value)}
                errors={errors}
                required={true}
            />
          </>
      }

      {
        (data.type === ExportTypeEnum.FIXED || data.type === ExportTypeEnum.PERSENT) &&
          <DatatableSelect
          data={products || []}
          filteredData={products || []}
          loading={loadingProducts}
          label={'Скидка действует на товары'}
          textTooltip={'Скидка действует на товары'}
          values={data.goods}
          errors={errors}
          name={'goods'}
          placeholder={'Выберите товары'}
          removeItem={(index: any) => {
            let goods = data.goods.slice()
            goods.splice(index, 1)
            updateField('goods', goods)
          }}
          updateValues={(values: any) => {
            updateField('goods', values)
            updateProducts(null, null)
          }}
          updateData={(params: any) => {
            if ('search' in params) {
              updateFilters('search', params.search)
              delete params.search
            }
            updateProducts(params, filters)
          }}
          columns={goodColumns}
          resetFilters={() => {
            updateFilters('filter.categories', null)
            updateField('__tableCategories2', '')
            updateProducts(null, null)
          }}
        >
          <SingleSimpleValueSelect
            placeholder={'Выберите раздел'}
            errors={{}}
            name='__tableCategories2'
            options={categories.map(({title, id}: any) => ({label: title, value: id}))}
            onChange={(value: any) => {
              updateFilters('filter.categories', value)
              updateProducts(null, filters)
              updateField('__tableCategories2', value)
            }}
            removeValue={() => {
              updateFilters('filter.categories', null)
              updateProducts(null, filters)
              updateField('__tableCategories2', '')
            }}
            removeItem={true}
            value={data.__tableCategories2 || ''}
          />
        </DatatableSelect>
      }

      {data.type === ExportTypeEnum.GIFT &&
          <>
              <DatatableSelect
                  data={products || []}
                  filteredData={products || []}
                  loading={loadingProducts}
                  label={'Подарки'}
                  textTooltip={'Подарки'}
                  values={data.giftsGoods}
                  errors={errors}
                  name={'giftsGoods'}
                  placeholder={'Выберите подарок'}
                  buttonPlaceholder={'Выбрать товары'}
                  removeItem={(index: any) => {
                    let goods = data.giftsGoods.slice()
                    goods.splice(index, 1)
                    updateField('giftsGoods', goods)
                  }}
                  updateValues={(values: any) => updateField('giftsGoods', values)}
                  updateData={(params: any) => {
                    if ('search' in params) {
                      updateFilters('search', params.search)
                      delete params.search
                    }
                    updateProducts(params, filters)
                  }}
                  columns={goodColumns}
                  resetFilters={() => {
                    updateFilters('filter.categories', null)
                    updateField('__tableCategories1', '')
                    updateProducts(null, null)
                  }}
              >
                  <SingleSimpleValueSelect
                      placeholder={'Выберите раздел'}
                      errors={{}}
                      name='__tableCategories1'
                      options={categories.map(({title, id}: any) => ({label: title, value: id}))}
                      onChange={(value: any) => {
                        updateFilters('filter.categories', value)
                        updateProducts(null, filters)
                        updateField('__tableCategories1', value)
                      }}
                      removeValue={() => {
                        updateFilters('filter.categories', null)
                        updateProducts(null, filters)
                        updateField('__tableCategories1', '')
                      }}
                      removeItem={true}
                      value={data.__tableCategories1 || ''}
                  />
              </DatatableSelect>
          </>
      }

      {data.type === ExportTypeEnum.FREE &&
          <>
            <TextInput
              min={'0'}
              type={'number'}
              label={'Количество бесплатных товаров'}
              textTooltip={'Количество бесплатных товаров'}
              placeholder={'Введите количество'}
              name={'freeGoodsCount'}
              value={data.freeGoodsCount}
              onChange={(value: any) => updateFieldNoRender('freeGoodsCount', value)}
              errors={errors}
            />

            <DatatableSelect
              data={products || []}
              filteredData={products || []}
              loading={loadingProducts}
              label={'Среди бесплатных товаров'}
              textTooltip={'Среди бесплатных товаров'}
              values={data.freeGoods}
              errors={errors}
              name={'freeGoods'}
              placeholder={'Выберите товары'}
              buttonPlaceholder={'Выбрать товары'}
              removeItem={(index: any) => {
                let goods = data.freeGoods.slice()
                goods.splice(index, 1)
                updateField('freeGoods', goods)
              }}
              updateValues={(values: any) => updateField('freeGoods', values)}
              updateData={(params: any) => {
                if ('search' in params) {
                  updateFilters('search', params.search)
                  delete params.search
                }
                updateProducts(params, filters)
              }}
              columns={goodColumns}
              resetFilters={() => {
                updateFilters('filter.categories', null)
                updateField('__tableCategories1', '')
                updateProducts(null, null)
              }}
            >
              <SingleSimpleValueSelect
                placeholder={'Выберите раздел'}
                errors={{}}
                name='__tableCategories1'
                options={categories.map(({title, id}: any) => ({label: title, value: id}))}
                onChange={(value: any) => {
                  updateFilters('filter.categories', value)
                  updateProducts(null, filters)
                  updateField('__tableCategories1', value)
                }}
                removeValue={() => {
                  updateFilters('filter.categories', null)
                  updateProducts(null, filters)
                  updateField('__tableCategories1', '')
                }}
                removeItem={true}
                value={data.__tableCategories1 || ''}
              />
            </DatatableSelect>
          </>
      }

      <div className={"border-gray-20 rounded-xl border-[1px] p-4"}>
        <TagsMultipleSelect
          data={promoTags?.map(({title, ...o}: any) => ({text: title, ...o})) || []}
          values={data.tags}
          errors={errors}
          name={'tags'}
          label={'Метки'}
          loading={loadingTags}
          placeholder={'Выберите метки'}
          buttonPlaceholder={'Выбрать метки'}
          updateValues={(values: any) => updateField('tags', values)}
          updateData={() => {
            console.log('update')
          }}
          columns={columnsTags}
          removeItem={(index: any) => {
            let options = data.tags.slice()
            options.splice(index, 1)
            updateField('tags', options)
          }}
        />
      </div>

      <Checkbox
        label={'Многоразовый'}
        name={'reusable'}
        onChange={(value: any) => updateField('reusable', value)}
        value={data.reusable}
      />

      {
        data?.reusable &&
          <div className={'w-full flex flex-col gap-y-4'}>
              <TextInput
                  type={'number'}
                  min={'0'}
                  label={'Доступное количество акциваций промокода'}
                  textTooltip={'Доступное количество акциваций промокода'}
                  placeholder={''}
                  name={'maxUsageCount'}
                  value={data.maxUsageCount}
                  onChange={(value: any) => updateFieldNoRender('maxUsageCount', value)}
                  errors={errors}
              />

              <Checkbox
                  label={'Одноразовый для каждого клиента'}
                  name={'isOneTimePerCustomer'}
                  onChange={(value: any) => {
                    updateField('isOneTimePerCustomer', value)
                  }}
                  value={data.isOneTimePerCustomer}
              />

            {
              data.isOneTimePerCustomer &&
              <SimpleDatePicker
                  value={data.reusablePerCustomerExpiresAt}
                  onChange={(date: Date) => {
                    updateField('reusablePerCustomerExpiresAt', date ? date.toLocaleDateString() : null)
                  }}
                  errors={errors}
                  name={'reusablePerCustomerExpiresAt'}
                  label={'Проверять начиная с даты'}
                  textTooltip={'Проверять начиная с даты'}
                  required={false}
                  placeholder={'Выберите дату'}
              />
            }
          </div>

      }

      {
        data.type !== ExportTypeEnum.CART_RULES && 
        <Checkbox
          label={'Не применять прочие скидки'}
          name={'excludesOtherDiscounts'}
          onChange={(value: any) => updateField('excludesOtherDiscounts', value)}
          value={data.excludesOtherDiscounts}
        />
      }

      {
        data.type !== ExportTypeEnum.CART_RULES && 
        <Checkbox
          label={'Не применять прочие акции'}
          name={'excludesOtherStocks'}
          onChange={(value: any) => updateField('excludesOtherStocks', value)}
          value={data.excludesOtherStocks}
        />
      }

      <Checkbox
        label={'Первый заказ клиента'}
        name={'onFirstOrder'}
        onChange={(value: any) => updateField('onFirstOrder', value)}
        value={data.onFirstOrder}
      />

      <div className={"flex w-full gap-x-6 border-gray-20 border-[1px] p-4 rounded-xl"}>
        <div className={"w-1/2"}>
          <TextInput
            type={'number'}
            min={'0'}
            label={'Кол-во товаров в корзине'}
            textTooltip={'Кол-во товаров в корзине'}
            placeholder={'Минимум'}
            name={'minCartGoodsCount'}
            value={data.minCartGoodsCount}
            onChange={(value: any) => updateFieldNoRender('minCartGoodsCount', value)}
            errors={errors}
          />
        </div>
        <div className={"w-1/2 flex items-end"}>
          <TextInput
            type={'number'}
            min={'0'}
            placeholder={'Максимум'}
            name={'maxCartGoodsCount'}
            value={data.maxCartGoodsCount}
            onChange={(value: any) => updateFieldNoRender('maxCartGoodsCount', value)}
            errors={errors}
            className={"w-full"}
          />
        </div>
      </div>

      <DatatableSelect
        data={products || []}
        filteredData={products || []}
        loading={loadingProducts}
        label={'Обязательные товары в заказе'}
        textTooltip={'Обязательные товары в заказе'}
        values={data.requiredGoods}
        errors={errors}
        name={'requiredGoods'}
        placeholder={'Выберите обязательные товары'}
        removeItem={(index: any) => {
          let requiredGoods = data.requiredGoods.slice()
          requiredGoods.splice(index, 1)
          updateField('requiredGoods', requiredGoods)
        }}
        updateValues={(values: any) => {
          updateField('requiredGoods', values)
          updateProducts(null, null)
        }}
        updateData={(params: any) => {
          if ('search' in params) {
            updateFilters('search', params.search)
            delete params.search
          }
          updateProducts(params, filters)
        }}
        columns={goodColumns}
        resetFilters={() => {
          updateFilters('filter.categories', null)
          updateField('__tableCategories3', '')
          updateProducts(null, null)
        }}
      >
        <SingleSimpleValueSelect
          placeholder={'Выберите раздел'}
          errors={{}}
          name='__tableCategories3'
          options={categories.map(({title, id}: any) => ({label: title, value: id}))}
          onChange={(value: any) => {
            updateFilters('filter.categories', value)
            updateProducts(null, filters)
            updateField('__tableCategories3', value)
          }}
          removeValue={() => {
            updateFilters('filter.categories', null)
            updateProducts(null, filters)
            updateField('__tableCategories3', '')
          }}
          removeItem={true}
          value={data.__tableCategories3 || ''}
        />
      </DatatableSelect>

      {data?.requiredGoods && data?.requiredGoods?.length > 0 && <div className={"flex w-full gap-x-6 border-gray-20 border-[1px] p-4 rounded-xl"}>
        <div className={"w-1/2"}>
          <TextInput
            type={'number'}
            min={'0'}
            label={'Кол-во обязательных товаров в корзине'}
            textTooltip={'Кол-во обязательных товаров в корзине'}
            placeholder={'Минимум'}
            name={'minCartRequiredGoodsCount'}
            value={data.minCartRequiredGoodsCount}
            onChange={(value: any) => updateFieldNoRender('minCartRequiredGoodsCount', value)}
            errors={errors}
          />
        </div>
        <div className={"w-1/2 flex items-end"}>
          <TextInput
            type={'number'}
            min={'0'}
            placeholder={'Максимум'}
            name={'maxCartRequiredGoodsCount'}
            value={data.maxCartRequiredGoodsCount}
            onChange={(value: any) => updateFieldNoRender('maxCartRequiredGoodsCount', value)}
            errors={errors}
            className={"w-full"}
          />
        </div>
      </div>}

      <div className={"flex w-full gap-x-6 border-gray-20 border-[1px] p-4 rounded-xl"}>
        <div className={"w-1/2"}>
          <TextInput
            type={'number'}
            min={'0'}
            label={'Сумма заказа'}
            textTooltip={'Сумма заказа'}
            placeholder={'Минимум'}
            name={'minCartAmount'}
            value={data.minCartAmount}
            onChange={(value: any) => updateFieldNoRender('minCartAmount', value)}
            errors={errors}
          />
        </div>
        <div className={"w-1/2 flex items-end"}>
          <TextInput
            type={'number'}
            min={'0'}
            placeholder={'Максимум'}
            name={'maxCartAmount'}
            value={data.maxCartAmount}
            onChange={(value: any) => updateFieldNoRender('maxCartAmount', value)}
            errors={errors}
            className={"w-full"}
          />
        </div>
      </div>

      <div className={"flex w-full gap-x-6 border-gray-20 border-[1px] p-4 rounded-xl"}>
        <div className={"w-1/2"}>
          <TextInput
            type={'time'}
            label={'Время заказа (C __ До __)  '}
            textTooltip={'Время заказа'}
            placeholder={'С 23:00'}
            name={'orderTimeStart'}
            value={data.orderTimeStart}
            onChange={(value: any) => updateFieldNoRender('orderTimeStart', value)}
            errors={errors}
          />
        </div>
        <div className={"w-1/2 flex items-end"}>
          <TextInput
            type={'time'}
            placeholder={'До 12:00'}
            name={'orderTimeEnd'}
            value={data.orderTimeEnd}
            onChange={(value: any) => updateFieldNoRender('orderTimeEnd', value)}
            errors={errors}
            className={"w-full"}
          />
        </div>
      </div>

      <div className={"flex w-full gap-x-6 border-gray-20 border-[1px] p-4 rounded-xl"}>
        <div className={"w-1/2"}>
          <TextInput
            type={'time'}
            label={'Время предзаказа (C __ До __)  '}
            textTooltip={'Время предзаказа'}
            placeholder={'С 23:00'}
            name={'preorderTimeStart'}
            value={data.preorderTimeStart}
            onChange={(value: any) => updateFieldNoRender('preorderTimeStart', value)}
            errors={errors}
          />
        </div>
        <div className={"w-1/2 flex items-end"}>
          <TextInput
            type={'time'}
            placeholder={'До 12:00'}
            name={'preorderTimeEnd'}
            value={data.preorderTimeEnd}
            onChange={(value: any) => updateFieldNoRender('preorderTimeEnd', value)}
            errors={errors}
            className={"w-full"}
          />
        </div>
      </div>

      <RangeDatePicker
        required={false}
        values={[data.preorderAtStart, data.preorderAtEnd]}
        label={'Дата предзаказа ( С __ До __ )'}
        textTooltip={'Дата предзаказа'}
        placeholder='Выберите промежуток дат'
        onChange={([startAt, endAt]: any) => {
          updateField('preorderAtStart', startAt)
          updateField('preorderAtEnd', endAt)
        }}
        errors={errors}
        name={'preorderAtStart'}
      />

      <div>
        <p className={"font-medium text-sm"}>Дни недели (оформления заказа)</p>
        <div className={"flex flex-row mt-3 gap-x-6"}>
          <Checkbox
            label={'Понедельник'}
            name={'orderOnMonday'}
            onChange={(value: any) => updateField('orderOnMonday', value)}
            value={data.orderOnMonday}
          />
          <Checkbox
            label={'Вторник'}
            name={'orderOnTuesday'}
            onChange={(value: any) => updateField('orderOnTuesday', value)}
            value={data.orderOnTuesday}
          />
          <Checkbox
            label={'Среда'}
            name={'orderOnWednesday'}
            onChange={(value: any) => updateField('orderOnWednesday', value)}
            value={data.orderOnWednesday}
          />
          <Checkbox
            label={'Четверг'}
            name={'orderOnThursday'}
            onChange={(value: any) => updateField('orderOnThursday', value)}
            value={data.orderOnThursday}
          />
          <Checkbox
            label={'Пятница'}
            name={'orderOnFriday'}
            onChange={(value: any) => updateField('orderOnFriday', value)}
            value={data.orderOnFriday}
          />
          <Checkbox
            label={'Суббота'}
            name={'orderOnSaturday'}
            onChange={(value: any) => updateField('orderOnSaturday', value)}
            value={data.orderOnSaturday}
          />
          <Checkbox
            label={'Воскресенье'}
            name={'orderOnSunday'}
            onChange={(value: any) => updateField('orderOnSunday', value)}
            value={data.orderOnSunday}
          />
        </div>
      </div>

      <div>
        <p className={"font-medium text-sm"}>Дни недели (предзаказа)</p>
        <div className={"flex flex-row mt-3 gap-x-6"}>
          <Checkbox
            label={'Понедельник'}
            name={'preorderOnMonday'}
            onChange={(value: any) => updateField('preorderOnMonday', value)}
            value={data.preorderOnMonday}
          />
          <Checkbox
            label={'Вторник'}
            name={'preorderOnTuesday'}
            onChange={(value: any) => updateField('preorderOnTuesday', value)}
            value={data.preorderOnTuesday}
          />
          <Checkbox
            label={'Среда'}
            name={'preorderOnWednesday'}
            onChange={(value: any) => updateField('preorderOnWednesday', value)}
            value={data.preorderOnWednesday}
          />
          <Checkbox
            label={'Четверг'}
            name={'preorderOnThursday'}
            onChange={(value: any) => updateField('preorderOnThursday', value)}
            value={data.preorderOnThursday}
          />
          <Checkbox
            label={'Пятница'}
            name={'preorderOnFriday'}
            onChange={(value: any) => updateField('preorderOnFriday', value)}
            value={data.preorderOnFriday}
          />
          <Checkbox
            label={'Суббота'}
            name={'preorderOnSaturday'}
            onChange={(value: any) => updateField('preorderOnSaturday', value)}
            value={data.preorderOnSaturday}
          />
          <Checkbox
            label={'Воскресенье'}
            name={'preorderOnSunday'}
            onChange={(value: any) => updateField('preorderOnSunday', value)}
            value={data.preorderOnSunday}
          />
        </div>
      </div>

      <div>
        <p className={"font-medium text-sm"}>Способ получения</p>
        <div className={"flex flex-row mt-3 gap-x-6"}>
          <CheckboxList
            name={'deliveryTypes'}
            errors={errors}
            options={deliveryTypes}
            values={data.deliveryTypes || []}
            onChange={(value: any) => {
              updateField('deliveryTypes', value);
            }}
            label={undefined}
          />
        </div>
      </div>

      <div className={"w-1/2"}>
        <CheckboxList
          label='Платформы'
          name='platforms'
          errors={errors}
          options={platforms}
          values={data.platforms || []}
          onChange={(value: any) => {
            updateField('platforms', value)
          }}
        />
      </div>



      <RangeDatePicker
        required={false}
        values={[data.startAt, data.endAt]}
        label={'Период действия промокода ( С __ До __ )'}
        textTooltip='Период действия промокода'
        placeholder='Выберите промежуток дат'
        onChange={([startAt, endAt]: any) => {
          updateField('startAt', startAt)
          updateField('endAt', endAt)
        }}
        errors={errors}
        name={'promoData'}
      />

      <SimpleMultipleValueSelect
        label={'Филиалы'}
        textTooltip={'Филиалы'}
        options={branches.map((branch: any) => ({label: branch.title, value: branch.id}))}
        value={data.branches || []}
        onChange={(value: any) => updateField('branches', value)}
        name={'branches'}
        placeholder={'Выберите филиалы'}
        errors={errors}
      />

      <TextInputMultipleList
        name={'toAddToCart'}
        values={data.toAddToCart || []}
        fields={['goodId', 'quantity']}
        fieldTypes={['input', 'input']}
        types={['number', 'number']}
        placeholders={['214586', '2']}
        labels={['ID товара', 'Количество']}
        textTooltips={['ID товара', 'Количество']}
        onChange={(value: any) => updateField('toAddToCart', value)}
        label={'Дополнительные действия - Добавить товары в корзину'}
        textTooltip={'Дополнительные действия - Добавить товары в корзину'}
        errors={errors}
        remove={true}
      />

    </div>
  )
}
