import React, {memo} from "react"
import {Handle, Position} from "reactflow";
// @ts-ignore
import timerImg from "../../../../images/stopwatch.svg"
// @ts-ignore
import copyImg from "../../../../images/copy.svg"
// @ts-ignore
import trashImg from "../../../../images/trash.svg"
import {useFunnelNodeContext} from "../../../../contexts/FunnelNodes";
import {TimerType} from "../forms/TimerNodeBase";
import {getDateAndTime} from "../../../../libs/helpers/datetime";

export const weeklyOptions = [
  {label: 'Текущей', value: 'current'},
  {label: 'Следующей', value: 'next'},
]

export const daysOptions = [
  {label: 'Понедельник', value: 0},
  {label: 'Вторник', value: 1},
  {label: 'Среда', value: 2},
  {label: 'Четверг', value: 3},
  {label: 'Пятница', value: 4},
  {label: 'Суббота', value: 5},
  {label: 'Воскресенье', value: 6},
]

const TimerNode = ({id, data, isConnectable}: any) => {
  const {removeNode, copyNode, isValidConnection} = useFunnelNodeContext()

  const getText = () => {
    if (data?.body?.type) {
      switch (data?.body?.type) {
        case TimerType.today:
          return `до ${data?.body?.value}`
        case TimerType.tomorrow:
          return `до Завтра ${data?.body?.value}`
        case TimerType.nearestWeekday:
          return  `до ${daysOptions.find(({value}: any) => value == data?.body?.weekday)?.label}, ${data?.body?.hours}`
        case TimerType.nearestTime:
          return `до ${data?.body?.value}`
        case TimerType.weekday:
          return `до ${daysOptions.find(({value}: any) => value == data?.body?.weekday)?.label}, ${data?.body?.hours}`
        case TimerType.datetime:
          return `до ${data?.body?.value}`
        case TimerType.days:
          return `${data?.body?.value} дн.`
        case TimerType.hours:
          return `${data?.body?.value} ч.`
        case TimerType.minutes:
          return `${data?.body?.value} мин.`
        case TimerType.seconds:
          return `${data?.body?.value} сек.`
        default:
          return ''
      }
    }

    return ''
  }

  return (
    <div className={'relative group'}>
      <div className={'h-[10px] bg-transparent'}></div>
      <div className={'rounded-2xl bg-funnels-orange text-sm min-w-[300px] max-w-[300px] shadow-xl'}>
      <Handle
        className={'!top-[34px]'}
        type="target"
        position={Position.Left}
        id={'in'}
        isConnectable={isConnectable}
        isConnectableStart={false}
        isValidConnection={(connection) => isValidConnection(connection)}
        onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()
        }}
      />
      <div className={'rounded-t-2xl px-3 py-[10px] h-[46px]'}>
        <div className={'flex flex-row items-center justify-between gap-x-2'}>
          <div className={'flex flex-row items-center gap-x-2'}>
            <img src={timerImg} alt=""/>
            <span>Таймер</span>
          </div>
          <div>
            {data?.title}
          </div>
        </div>
      </div>

        {
          getText() ?
            <div className={'flex py-8 px-3 bg-white flex-wrap'}>
              Подождать {getText()}, затем продолжить
            </div> :
            <div className={'flex py-10 px-3 bg-white text-gray-40'}>
            <div className={'flex w-full px-2 py-[15px] border-[1px] cursor-pointer hover:bg-gray-10/[.8] border-dashed rounded-xl items-center justify-center'}>
              Нажмите на блок, чтобы добавить таймер
            </div>
          </div>
        }

      <div className={'flex px-3 bg-white text-gray-20 rounded-b-2xl'}>
        <div className={'flex w-full px-3 py-[14px] border-t-[1px] border-gray-30'}>
          Переход на следующий шаг
        </div>
      </div>
      <Handle
        className={'!bottom-[15px] !top-auto !right-[10px]'}
        type="source"
        position={Position.Right}
        id={'out'}
        isConnectable={isConnectable}
        isValidConnection={(connection) => isValidConnection(connection)}
        onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()
        }}
      />
    </div>
      <div className={'absolute bottom-[100%] w-full duration-200 flex flex-row items-center justify-between left-0 scale-0 transition-all group-hover:scale-100'}>
        <div
          className={'rounded-xl cursor-pointer p-3 border-[1px] bg-white border-funnels-red hover:bg-gray-10'}
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
            removeNode(id)
          }}>
          <img src={trashImg} alt=""/>
        </div>
        <div
          className={'rounded-xl cursor-pointer p-3 border-[1px] bg-white border-funnels-green hover:bg-gray-10'}
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
            copyNode(id)
          }}>
          <img src={copyImg} alt=""/>
        </div>
      </div>
    </div>
  )
}

export default memo(TimerNode)
