import React from 'react'
import TextInput from './input/TextInput'
import Checkbox from './checkbox/Checkbox'
import Tooltip from "../Tooltip";


export default function ScheduleTime({
  value,
  name,
  onChange,
  errors,
  label = 'Режим работы',
  notActiveLabel='Выходной',
  required = false,
  textTooltip = '',
}: any) {
  const update = (v: any, day: any, type: any) => {
    let tempValue = JSON.parse(JSON.stringify(value))

    let [prevFrom, prevUntil] = tempValue[day]?.hours.at(0).split('-')

    if (type === 'from') {
      tempValue[day].hours = [`${v || '00:00'}-${prevUntil}`]
    } else if (type === 'until') {
      tempValue[day].hours = [`${prevFrom}-${(!v || v == '00:00') ? '24:00' : v}`]
    } else if (type === 'active') {
      tempValue[day].active = v
    }

    onChange(tempValue)
  }

  return (
    <div className='flex flex-col gap-y-2 text-sm font-medium p-4 border-gray-20 border-[1px] rounded-lg'>
      {
        label &&
        <label className="flex items-center mb-2 font-medium text-sm">
          { label }
            <span className='text-error-main'>{required && '*'}</span>
          {
            textTooltip && <Tooltip textTooltip={textTooltip} defaultText={name}/>
          }
        </label>
      }
      {
        [
          {label: 'Пн:', key: 'monday'},
          {label: 'Вт:', key: 'tuesday'},
          {label: 'Ср:', key: 'wednesday'},
          {label: 'Чт:', key: 'thursday'},
          {label: 'Пт:', key: 'friday'},
          {label: 'Сб:', key: 'saturday'},
          {label: 'Вс:', key: 'sunday'},
        ].map(day => (
          <div key={day.key} className='flex gap-2 items-center'>
            <div className='w-10 text-center'>{ day.label }</div>

            <Checkbox
              name={''}
              value={value[day?.key]?.active}
              onChange={(value: any) => update(value, day.key, 'active')}
              errors={errors}
            />

            {
              value[day?.key]?.active || notActiveLabel === null ?
              <>
                <TextInput
                  type='time'
                  className='grow'
                  placeholder='С 00:00'
                  onChange={(value: any) => update(value, day?.key, 'from')}
                  value={value[day?.key]?.hours?.at(0).split('-').at(0)}
                  name={`${name}.${day.key}.hours`}
                  errors={errors}
                />
                <TextInput
                  type='time'
                  className='grow'
                  placeholder='До 00:00'
                  onChange={(value: any) => update(value, day.key, 'until')}
                  value={value[day?.key]?.hours?.at(0).split('-').at(1) == '24:00' ? '00:00' : value[day?.key]?.hours?.at(0).split('-').at(1)}
                  name={`${name}.${day.key}.hours`}
                  errors={errors}
                />
              </> :
              <div className='grow h-11 flex items-center justify-center'>{notActiveLabel}</div>
            }

          </div>
        ))
      }

    </div>
  )
}
