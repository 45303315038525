import React, {useId, useRef, useState} from "react";
// @ts-ignore
import searchIcon from "../../../images/search.svg"
// @ts-ignore
import resetImg from "../../../images/greenClose.svg"

export default function StepSearch(
  {
    name = 'stepSearch',
    //value = '',
    updateValue = (value: any) => {},
    fetchData = (value: any) => {},
    className = '',
    classNameInput = '',
    disabled = false,
    placeholder = 'Поиск',
    autoComplete = 'off',
    ...props
  }) {
  const id = useId()
  const input = useRef(null)
  const [value, setValue] = useState('')

  const handleKeyDown = (e: any) => {
      if (e.key === 'Enter') {
        fetchData(value)
      }
  }

  return (
    <div className={`${className}`}>
      <div className={'relative'}>
        <input
          id={id}
          type={'text'}
          name={name}
          value={value}
          disabled={false}
          onChange={({target}) => {
            setValue(target.value || '')
          }}
          readOnly={false}
          className={`outline-0 disabled:text-gray-40 disabled:pointer-events-none text-gray-50 py-[13px] px-[14px] w-full text-sm bg-white border-gray-20 border-[1px] rounded-lg pl-[60px] pr-[40px] ${classNameInput}`}
          placeholder={placeholder}
          autoComplete={autoComplete}
          onKeyUp={handleKeyDown}
          ref={input}
          {...props}
        />
        <img
          src={searchIcon}
          className={'absolute top-1/2 -translate-y-1/2 w-[20px] h-[20px] cursor-pointer left-[20px]'}
          alt=""
          onClick={() => {
            console.log('search')
            fetchData(value)
          }}
        />
        <img
          src={resetImg}
          className={'absolute top-1/2 -translate-y-1/2 w-[15px] h-[15px] cursor-pointer right-[20px]'}
          alt=""
          onClick={() => {
            setValue('')
          }}
        />
      </div>
    </div>
  )
}
