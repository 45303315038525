import React from "react";
import DataTable from "../../../components/common/Table/DataTable";
import {createColumnHelper} from "@tanstack/react-table";
import {Link} from "react-router-dom";
import {useLoyaltyRulesContext} from "../../../contexts/LoyaltyPrograms";

export default function TableRules() {
  const {rules, updateRules, loading, pagination}: any = useLoyaltyRulesContext();

  const columnHelper = createColumnHelper<any>()

  const columns = [
    columnHelper.accessor(row => row.id, {
      id: 'id',
      header: () => 'ID',
      cell: info => <div
        className={'w-[70px] font-normal break-all flex items-center pl-2'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[70px] flex justify-start text-center pl-2',
      }
    }),
    columnHelper.accessor(row => row.title, {
      id: 'title',
      header: () => 'Наименование',
      cell: info => <div className={'grow font-normal break-all flex items-center pl-2'}>
        <Link className={'text-interactive-elem'} to={`/loyalty-programs/rules/${info.row?.original?.id}/edit`}>
          {info.getValue()}
        </Link>
      </div>,
      enableSorting: true,
      meta: {
        widthClass: 'grow flex justify-start text-center pl-2',
        sortField: 'title'
      }
    }),
    columnHelper.accessor(row => row.bonusProgramStatus.title, {
      id: 'statusTitle',
      header: () => 'Статус',
      cell: info => <div
        className={'w-[20%] font-normal break-word flex items-center justify-center'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[20%] flex justify-center',
      }
    }),
  ]

  return (
    <>
      <DataTable
        data={rules}
        columns={columns}
        usePagination={true}
        pagination={pagination}
        updateData={(params: any) => {updateRules(params)}}
        loading={loading}
        useMassAction={false}
        selectedRows={[]}
        onChangeSelectionRows={(values: any) => {}}
        classNameTable={'mt-0'}
      />
    </>
  )
}
