import {apiClient} from "../../libs/api/apiClient";

async function fetchLocationSources(withNull=true) {
  const { data }: any = await apiClient.dictionaries.locationSources()

  let items = data.map((locationSource: any) => ({
    label: locationSource.title,
    value: locationSource.value
  }))

  if (withNull) {
    return [{label: 'Не использовать', value: ''}, ...items]
  }

  return items
}

export default fetchLocationSources
