import PropTypes from "prop-types";
import React, {useEffect, useId, useRef, useState} from "react";

const defaultMaxHeight = 104;

export default function DropDown({items, value, show, setShow, onChange, type='label', howMushShowItems = 3}: any) {
  const id = useId();
  const container = useRef<any>(null);
  const wrapper = useRef(null);
  const [position, setPosition] = useState('bottom');
  const [maxHeight, setMaxHeight] = useState(defaultMaxHeight);
  const [containerPosition, setContainerPosition] = useState(0);

  useEffect(() => {
    function handleClick(event: any) {
      const {clientY} = event;

      if (!show) {
        setContainerPosition(clientY);
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }

  }, [show, setShow, wrapper]);

  useEffect(() => {
    if (!container) {
      return;
    }

    const nodes = Array.from(container.current.childNodes);
    const first: any = nodes.slice(0, howMushShowItems);

    const height = first.reduce((total: any, item: any) => total + item.getBoundingClientRect().height, 0) + 8;
    setMaxHeight(defaultMaxHeight < height && height || defaultMaxHeight);
  }, [items, show])

  useEffect(() => {
    if (show) {
      setPosition('bottom');
      const windowHeight = window.innerHeight

      //const {height: containerHeight} = container.current.getBoundingClientRect()
      const fromContainerToBottom = Math.abs(windowHeight - containerPosition);
      //containerHeight > fromContainerToBottom && setPosition('top');
      //containerHeight < fromContainerToBottom && setPosition('bottom');
      maxHeight > fromContainerToBottom && setPosition('top');
      maxHeight <= fromContainerToBottom && setPosition('bottom');
    }
  }, [show, containerPosition]);

  const positionClassName = 'bottom' === position && 'top-[105%]' || 'bottom-[105%]'
  const dropDownClassName = show && `shadow-dropdown opacity-100 py-2 !max-h-[300px]` || 'opacity-0 !z-0'

  return (
    <div className={`${dropDownClassName} ${positionClassName} transition-all max-h-0 duration-500 absolute w-full bg-white border border-gray-10 z-[1011]`} ref={wrapper}>
      <div style={{maxHeight}} className={`${!show && '!max-h-[0px]' || ''} flex flex-col gap-1 px-2 overflow-y-auto overflow-x-hidden transition-all duration-500 pretty-scroll`} ref={container}>
        {type === 'label' && items?.map((item: any, index: any) => (<div className={`rounded min-h-[32px] flex shrink-0 cursor-pointer select-none items-center px-3 transition duration-300 hover:bg-gray-10 ${value?.value === item.value && 'bg-gray-10' || ''} `} key={id + index} onClick={() => onChange(item)}>{item.label}</div>))}
        {type === 'location' && items?.map((item: any, index: any) => (<div className={`rounded min-h-[32px] flex shrink-0 cursor-pointer select-none items-center px-3 transition duration-300 hover:bg-gray-10 ${value?.guid === item.guid && 'bg-gray-10' || ''} `} key={id + index} onClick={() => onChange(item)}>{item.title}</div>))}
        {type === 'color' && items?.map((item: any, index: any) => (<div className={`rounded min-h-[32px] flex justify-center shrink-0 cursor-pointer select-none items-center transition duration-300 hover:bg-gray-10 ${value === item && 'bg-gray-10' || ''} `} key={id + index} onClick={() => onChange(item)}><div className="w-[10px] h-[10px] rounded-full" style={{'background': item || ''}}></div></div>))}
        {type === 'icon' && items?.map((item: any, index: any) => (<div className={`rounded min-h-[32px] flex justify-center shrink-0 cursor-pointer select-none items-center transition duration-300 hover:bg-gray-10 ${value === item && 'bg-gray-10' || ''} `} key={id + index} onClick={() => onChange(item)}><img className="w-6 h-6" src={item.icon}/></div>))}
      </div>
    </div>
  )
}

DropDown.propTypes = {
  items: PropTypes.array.isRequired,
  value: PropTypes.object,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  howMushShowItems: PropTypes.number,
}
