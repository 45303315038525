import {apiClient} from "../libs/api/apiClient";
import {useCompaniesContext} from "./Companies";
import {useBranchesContext} from "./Branches";
import {MakeContext} from "./common";

const [MailingsContextWrapper, useMailingsContext] = MakeContext({
  useEverything: () => {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    return {
      isReady: () => (company && branch),
      fetchAll: (queryParams: any) => apiClient.mailings.getAll(company.id, branch.id, queryParams),
      updateDeps: [company?.id, branch?.id]
    }
  },
  makeValue: (mailings: any, updateMailings: any, updateFilters: any) => ({
    mailings, updateMailings, updateFilters
  })
})

export {MailingsContextWrapper, useMailingsContext};

const [MailingsArchiveContextWrapper, useMailingsArchiveContext] = MakeContext({
  useEverything: () => {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    return {
      isReady: () => (company && branch),
      fetchAll: (queryParams: any) => apiClient.mailings.getAllArchive(company.id, branch.id, queryParams),
      updateDeps: [company?.id, branch?.id],
      startLimit: 10
    }
  },
  makeValue: (archiveMailings: any, updateArchiveMailings: any, updateArchiveFilters: any) => ({
    archiveMailings, updateArchiveMailings, updateArchiveFilters
  })
})

export {MailingsArchiveContextWrapper, useMailingsArchiveContext};
