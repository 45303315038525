import React, {useEffect, useRef, useState} from "react";
import { v4 as uuid } from "uuid";

export function SVGWrap({src, load = true, ...props}: any) {
  const ref = useRef<SVGSVGElement | null>(null)
  const [fallback, setFallback] = useState(false)

  /**
   * Метод для решения коллизии ссылок на id в свг 
   * (Например: Если в свг вставлена пнг картинка, которая прокидывается в <rect></rect> через ссылку на id из <pattern></pattern>).
   * Берет свг контент ищет все айдишники внутри, и заменяет их на рандомные 
   * 
   * @param text SVG content
   */
  const replaceIds = (text: string) => {
    const ids = text.match(/id="(.*?)"/g)

    if (ids && ids.length > 0) {
      ids.forEach((id: string) => {
        const value: string|undefined = id.match(/"(.*?)"/g)?.at(0)
        if (value) {
            text = text.replace(new RegExp(value.substring(1, value.length - 1), 'g'), uuid())
        }
      });
    }

    return text;
  }

  useEffect(() => {
    if (!load) return
    fetch(src, {
      method: "GET"
    })
      .then(r => r.text())
      .then((text) => {
        const container = document.createElement('div')
        container.innerHTML = replaceIds(text)
        const svg = container.getElementsByTagName('svg')[0];
        // Remove any invalid XML tags as per http://validator.w3.org
        svg.removeAttribute('xmlns:a');
        // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
        if(!svg.getAttribute('viewBox') && svg.getAttribute('height') && svg.getAttribute('width')) {
          svg.setAttribute('viewBox', '0 0 ' + svg.getAttribute('height') + ' ' + svg.getAttribute('width'))
        }
        if (ref.current) {
          try {
            while (ref.current.firstChild) {
              ref.current.removeChild(ref.current.firstChild)
            }

            for (let i = 0; svg.attributes[i]; i++) {
              let attr = svg.attributes[i]
              if (!props.hasOwnProperty(attr.name))
                ref.current.setAttribute(attr.name, attr.value)
            }
            while (svg.firstChild) {
              let el = svg.firstChild
              svg.removeChild(el)
              ref.current.appendChild(el)
            }
            setFallback(false);
          } catch (e) {
            console.log(e)
            setFallback(true)
          }
        }
      })
      .catch(e => {
        console.log(e)
        setFallback(true);
      });
  }, [src, load])

  return <svg ref={ref} {...props}>{(fallback || !load) ? <image {...props} xlinkHref={src} ></image> : <></>}</svg>
}
