import React from 'react'
import Checkbox from '../../../components/common/form/checkbox/Checkbox'
import TextInput from '../../../components/common/form/input/TextInput'
import Textarea from './../../../components/common/form/input/Textarea';


export default function MetaTags({data, updateField, updateFieldNoRender, errors}: any) {
  return (
   <>
    <Checkbox
      label='Разделять по филиалам'
      name={'isDividedByBranches'}
      value={data.isDividedByBranches}
      onChange={value => updateField('isDividedByBranches', value)}
      errors={errors}
    />

    <TextInput
      label='Meta Title'
      textTooltip='Meta Title'
      placeholder='Введите мета-название раздела'
      name={'metaTitle'}
      value={data.metaTitle}
      onChange={value => updateFieldNoRender('metaTitle', value)}
      errors={errors}
    />

    <TextInput
      label='Meta Keywords'
      textTooltip='Meta Keywords'
      placeholder='Введите ключевые слова'
      name={'metaKeywords'}
      value={data.metaKeywords}
      onChange={value => updateFieldNoRender('metaKeywords', value)}
      errors={errors}
    />

    <Textarea
      label='Meta Description'
      textTooltip='Meta Description'
      placeholder='Введите мета-описание раздела'
      name={'metaDescription'}
      value={data.metaDescription}
      onChange={value => updateFieldNoRender('metaDescription', value)}
      errors={errors}
    />
   </>
  )
}
