import React, {useEffect, useMemo, useState} from "react";
import BaseButton from "../../components/common/Button/BaseButton";
import {createColumnHelper} from "@tanstack/react-table";
import DataTable from "../../components/common/Table/DataTable";
import TextInput from "../../components/common/form/input/TextInput";
import {useBranchesContext} from "../../contexts/Branches";
import SingleSimpleValueSelect from "../../components/common/form/select/SingleSimpleValueSelect";
import RangeDatePicker from "../../components/common/form/datetime/RangeDatePicker";
import {useCompaniesContext} from "../../contexts/Companies";
import {apiClient} from "../../libs/api/apiClient";
import {preparePaginationQueryParams} from "../../contexts/common";
import {showErrorToast} from "../../libs/helpers/toasts";
import Export from "../../components/common/Export";
import { ExportBlocks } from "../../components/common/Export/helpers";
import { useSidebarContext } from "../../contexts/Sidebar";


export default function ReportPromoCodes() {
  const {hiddenMode} = useSidebarContext()
  const {company} = useCompaniesContext()
  const {branches, branch} = useBranchesContext()
  const columnHelper = createColumnHelper<any>()

  const [brch, setBrnch] = useState(null)
  const [filters, setFilters] = useState<any>({})
  const [reports, setReports] = useState<any>([])
  const [orders, setOrders] = useState<any>(null)
  const [promoCodes, setPromoCodes] = useState<any>([])
  const [dateFilter, setDateFilter] = useState([null, null])
  const [source, setSource] = useState(null)
  const [search, setSearch] = useState(null)
  const [data, setData] = useState<any>([])

  const defaultColumns = [
    columnHelper.accessor(row => row.date, {
      id: 'date',
      header: () => 'Дата',
      cell: info => <div
        className={'min-w-[120px] font-normal break-words flex justify-start items-center ml-4'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'min-w-[120px] flex justify-start text-center ml-4',
        //sortField: 'date'
      },
    }),
    columnHelper.accessor(row => row.ordersCount, {
      id: 'ordersCount',
      header: () => 'Все промокоды',
      cell: info => <div
        className={'min-w-[140px] font-normal flex justify-center items-center'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'min-w-[140px] flex justify-start text-center',
        //sortField: 'ordersCount'
      },
    }),
  ]

  const submitFilter = () => {
    const filter = {
      filter: {
        createdAt: {
          after: dateFilter[0] || null,
          before: dateFilter[1] || null,
        },
        branches: brch ? [brch] : null
      }
    }
    setFilters(filter)
    getPromoCodesStats()
  }

  useEffect(() => {
    getPromoCodesStats()
  }, [orders])

  const getPromoCodesStats = () => {
    if (company && branch) {
      apiClient.reports.promoCodes(company.id, branch.id, preparePaginationQueryParams({...filters, order: orders || null}))
        .then(({data, promoCodes, message, errors}: any) => {
          setReports(data || {})
          setPromoCodes(Object.entries(promoCodes).map(([key,value]: any) => ({code: key, title: value})) || [])
        }).catch((e) => {
          showErrorToast({
            content: 'Ошибка при загрузке статистики'
          })
        })
    }
  }

  const columns = useMemo(() => {
    let temp: any[] = []

    promoCodes.forEach((x: any) => {
      temp.push(columnHelper.accessor(row => row[x.code], {
        id: `${x.code}`,
        header: () =>
          <div className={"flex flex-col"}>
            <p>{x.code}</p>
            <p className={'text-gray-20'}>{x.title}</p>
          </div>,
        cell: info => {
          return (<div className={'w-[200px] font-normal flex flex-col justify-center items-center'}>
            <div>
              {info.getValue()?.ordersTotal} {info.getValue()?.ordersCount}
            </div>
            <div>
              ({info.getValue()?.ordersCostPrice})
            </div>
          </div>)
        },
        enableSorting: false,
        meta: {
          widthClass: 'w-[200px] flex justify-center items-center text-center',
        },
        minSize: 250,
      }))
    })

    return defaultColumns.concat(temp)
  }, [promoCodes])

  useEffect(() => {
    const reportsData = Object.entries(reports).map(([key, value]: any) => {
      let res = {...value}
      res.date = (key == 'allTime') ? 'Все дни' : key
      return res
    })

    setData(reportsData)
  }, [reports])

  useEffect(() => {
    getPromoCodesStats()
  }, [company, branch])


  if (!branch) {
    return <div>
      <div className="px-8 pb-8 pt-2 w-[calc(99vw-285px)]">
        <div className="flex flex-col justify-between">
          <div>
            <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Отчеты</div>
            <div className="text-gray-40 text-base">Статистика промокодов</div>
          </div>
          <div className="text-gray-40 text-3xl text-center mt-[35vh]">
            Выберите филиал
          </div>
        </div>
      </div>
    </div>
  }


  return (
    <div className={`px-8 pb-8 pt-2 ${hiddenMode ? 'w-[calc(99vw-60px)]' : 'w-[calc(99vw-340px)]'}`}>
      <div className="flex flex-col gap-y-6">
        <div className="flex justify-between items-end">
          <div>
            <p className="text-gray-50 text-[44px] leading-[60px] mb-2">Отчеты</p>
            <p className="text-gray-40 text-base">Статистика промокодов</p>
          </div>
        </div>
        <div className={"w-full flex justify-between items-end gap-x-4"}>
          <div className={"w-2/3 flex flex-col gap-x-4 gap-y-4 "}>
            <div className={"flex gap-x-4 items-center"}>
              <SingleSimpleValueSelect
                options={branches.map((branch: any) => ({label: branch.title, value: branch.id}))}
                value={brch}
                onChange={(value: any) => setBrnch(value)}
                placeholder={'Филиал 1'}
                className={"w-full"}
              />
              <SingleSimpleValueSelect
                options={[]}
                value={source}
                onChange={(value: any) => setSource(value)}
                placeholder={'Все источники'}
                className={"w-full"}
              />
              <div className={"w-full"}>
                <RangeDatePicker
                  required={false}
                  values={dateFilter}
                  placeholder='Выберите промежуток дат'
                  onChange={([startDate, endDate]: any) => {
                    setDateFilter([startDate, endDate])
                  }}
                  errors={{}}
                  name={'date'}
                  label={undefined}
                  textTooltip={undefined}
                />
              </div>
            </div>
            <div className={"flex"}>
              <TextInput
                placeholder='Введите промокоды'
                value={search || ''}
                onChange={(value: any) => setSearch(value)}
                className={"w-full"}
                errors={{}}
                name={'input-promoCode'}
              />
            </div>

          </div>
          <div className={"w-1/3 flex justify-end"}>
            <BaseButton onClick={() => submitFilter()} className={"ml-5 bg-interactive-elem text-white"}>Показать результат</BaseButton>
          </div>
        </div>
        <Export
          title={'Экспорт статистики промокодов'}
          types={[{ title: 'Экспортировать', type: ExportBlocks.reportPromoCodes }]}
          filter={filters || null}
          order={null}
        />
        <DataTable
          data={data || []}
          columns={columns}
          usePagination={false}
          //pagination={pagination}
          defaultOrder={{date: 'desc'}}
          updateData={(params: any) => {
            setOrders(params?.order || null)
          }}
          //loading={loading}
          classNameTable={'mt-0 overflow-x-auto pb-4 px-4'}
          heightHeaderRow={'h-24 w-fit'}
          classNameRow={'w-fit'}
        />
      </div>
    </div>
  )

}
