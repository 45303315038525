import React, {useEffect, useState} from "react";
import {createColumnHelper} from "@tanstack/react-table";
import BaseButton from "../../components/common/Button/BaseButton";
import {useBranchesContext} from "../../contexts/Branches";
import SingleSimpleValueSelect from "../../components/common/form/select/SingleSimpleValueSelect";
import DataTable from "../../components/common/Table/DataTable";
import {useProductsContext} from "../../contexts/Products";
import {useNpsGoodReviewContext} from "../../contexts/NPS";
import {getDateAndTime} from "../../libs/helpers/datetime";
import RangeDatePicker from "../../components/common/form/datetime/RangeDatePicker";
import Export from "../../components/common/Export";
import { ExportBlocks } from "../../components/common/Export/helpers";

export default function FoodRating() {
  const {products}: any = useProductsContext()
  const {branches, branch} = useBranchesContext()
  const [applyFilter, setApplyFilter] = useState(false)
  const [branchFilter, setBranchFilter] = useState(null)
  const [productFilter, setProductFilter] = useState(null)
  const [dateFilter, setDateFilter] = useState([null, null])

  const {goodReviews, updateNpsGoodReviews, loading, pagination, limits, filters, updateFilters}: any = useNpsGoodReviewContext()

  const columnHelper = createColumnHelper<any>()

  const submitFilter = () => {
    setApplyFilter(true)
    const filter = {
      createdAt: {
        after: dateFilter[0] || null,
        before: dateFilter[1] || null,
      },
      branches: branchFilter || null,
      goods: productFilter || null
    }
    updateFilters('filter', filter)
    updateNpsGoodReviews(limits, filters)
  }


  const columns = [
    columnHelper.accessor(row => row.createdAt, {
      id: 'createdAt',
      header: () => 'Дата/Время ',
      cell: info => <div
        className={'w-[140px] font-normal break-words flex justify-center items-center mx-2'}>{getDateAndTime(info.getValue())}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[140px] flex justify-center text-center mx-2',
        sortField: 'createdAt'
      }
    }),
    columnHelper.accessor(row => row.order, {
      id: 'idOrder',
      header: () => 'ID Заказа',
      cell: info => <div
        className={'w-[100px] font-normal break-all flex justify-center items-center mx-2'}>{info.getValue()?.id || ''}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[100px] flex justify-center text-center mx-2',
      }
    }),
    columnHelper.accessor(row => row.order, {
      id: 'idClient',
      header: () => 'ID Клиента',
      cell: info => <div
        className={'w-[110px] font-normal break-all flex justify-center items-center mx-2'}>{info.getValue()?.customer?.id || ''}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[110px] flex justify-center text-center mx-2',
      }
    }),
    columnHelper.accessor(row => row.good, {
      id: 'good',
      header: () => 'Блюдо',
      cell: info => <div
        className={"w-2/3 font-normal flex flex-col justify-center gap-y-2 py-2"}>
        {
          info.getValue()?.title || ''
        }
      </div>,
      enableSorting: false,
      meta: {
        widthClass: "w-2/3 flex justify-start mx-2",
      }
    }),
    columnHelper.accessor(row => row.estimation, {
      id: 'estimation',
      header: () => 'Оценка',
      cell: info => <div
        className={'w-[70px] font-normal flex flex-col justify-center items-center gap-y-2 py-2 mx-3'}>
        {
          info.getValue() || ''
        }
      </div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[70px] flex justify-center py-2 mx-3',
      }
    }),
  ]

  if (!branch) return <div>
    <div className="px-8 pb-8 pt-2">
      <div className="flex flex-col justify-between">
        <div>
          <div className="text-gray-50 text-[44px] leading-[60px] mb-2">NPS</div>
          <div className="text-gray-40 text-base">Список оценок блюд</div>
        </div>
        <div className="text-gray-40 text-3xl text-center mt-[35vh]">
          Выберите филиал
        </div>
      </div>
    </div>
  </div>

  if (!applyFilter && goodReviews?.length === 0) return (
    <div className="px-8 pb-8 pt-2">
      <div className="flex flex-col gap-y-6">
        <div className="flex justify-between items-end">
          <div>
            <div className="text-gray-50 text-[44px] leading-[60px] mb-2">NPS</div>
            <div className="text-gray-40 text-base">Список оценок блюд</div>
          </div>
        </div>
      </div>
      <div className="text-gray-40 text-3xl text-center mt-[35vh]">
        {loading ? 'Загрузка...' : 'Оценок блюд нет'}
      </div>
    </div>
  )

  return (
    <div className={"px-8 pb-8 pt-2"}>
      <div className={"flex flex-col gap-y-6"}>

        <div className={"flex justify-between items-end"}>
          <div>
            <div className={"text-gray-50 text-[44px] leading-[60px] mb-2"}>NPS</div>
            <div className={"text-gray-40 text-base"}>Список оценок блюд</div>
          </div>
        </div>

        <div className={"w-full flex flex-row items-center justify-between gap-x-3"}>
          <div className={"w-1/2 flex gap-x-3"}>
            <SingleSimpleValueSelect
              name={'branches'}
              placeholder={'Филиал 1'}
              value={branchFilter}
              options={branches.map(({title, id}: any) => ({label: title, value: id}))}
              onChange={(value: any) => {
                setBranchFilter(value)
                //updateFilters('filter.branches', value)
              }}
              removeItem={true}
              removeValue={() => {
                setBranchFilter(null)
                //updateFilters('filter.branches', null)
              }}
            />
            <SingleSimpleValueSelect
              name={'goods'}
              placeholder={'Все блюда'}
              value={productFilter}
              options={products.map(({title, id}: any) => ({label: title, value: id}))}
              onChange={(value: any) => {
                setProductFilter(value)
                //updateFilters('filter.goods', [value])
              }}
              removeItem={true}
              removeValue={() => {
                setProductFilter(null)
                //updateFilters('filter.goods', null)
              }}
            />
          </div>
          <div className={"w-1/2 flex gap-x-3 items-center"}>
            <RangeDatePicker
              required={false}
              values={dateFilter || [null, null]}
              placeholder='Выберите промежуток дат'
              onChange={([startAt, endAt]: any) => {
                setDateFilter([startAt, endAt]);
                //updateFilters('filter.createdAt.before', endAt || null)
                //updateFilters('filter.createdAt.after', startAt || null)
              }}
              errors={{}}
              name={'dateFilter'}
              label={undefined}
              textTooltip={undefined}
            />
            <BaseButton onClick={submitFilter} className={"w-1/2 bg-interactive-elem text-white"}>Найти</BaseButton>
          </div>
        </div>

        <Export
          title={'Экспорт оценок блюд'}
          types={[{ title: 'Экспортировать', type: ExportBlocks.goodsRating }]}
          filter={filters || null}
          order={limits?.order || null}
          classNameWrapper={'mt-4'}
        />

        <DataTable
          data={goodReviews || []}
          columns={columns}
          usePagination={true}
          defaultLimit={10}
          defaultPage={1}
          pagination={pagination}
          updateData={(params: any) => updateNpsGoodReviews(params, filters)}
          loading={loading}
          classNameTable={'mt-0'}
        />

      </div>
    </div>
  )
}
