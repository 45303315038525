import React, { useEffect, useState } from 'react'
import { useCompaniesContext } from '../../../contexts/Companies'
import { Link, useLocation } from 'react-router-dom'
import ChevronIcon from '../../icons/ChevronIcon'
// @ts-ignore
import fileIcon from "../../../images/paper.svg"
import { useFormContext } from '../../../contexts/Form'
import edit from "../../../images/edit.svg";

const getCompanyTitle = (company: any) => {
  const id = `(ID ${ company.id })`;

  return (
    <div className={'w-full contents'}>
      <span className='grow relative pr-2 leading-5 line-clamp-2'>
        { company.title }
      </span>
      <span className={ 'self-end flex-shrink-0 leading-8 text-sm' }>
        { id }
      </span>
    </div>
  )
}

export default function Company({ hiddenMode = false, setHiddenMode }: any) {
  const { company, companies, selectCompany } = useCompaniesContext()
  const { checkIsFormDirty, openDialog } = useFormContext()
  const location = useLocation()

  const [showCompanies, setShowCompanies] = useState(false);
  const [companyMenuHeight, setCompanyMenuHeight] = useState(0);
  const [companyMenuOverflowClass, setCompanyMenuOverflowClass] = useState('');

  useEffect(() => {
    setShowCompanies(false)
  }, [location])

  useEffect(() => {
    if (true === showCompanies) {
      setCompanyMenuHeight(64 * (companies?.length + 1));

      setTimeout(() => {
        setCompanyMenuOverflowClass('overflow-y-auto')
      }, 500)

      return;
    }

    if (false === showCompanies) {
      setTimeout(() => {
        setCompanyMenuHeight(0);
      }, 400);

      setTimeout(() => {
        setCompanyMenuOverflowClass('');
      }, 500);
    }
  }, [showCompanies]);

  if (hiddenMode) {
    return (
      <div className='w-full bg-white text-gray-50 font-medium'>
        {
          companies?.length === 0 &&
            <Link to={ '/companies' } onClick={ () => setHiddenMode(false) }>
                <div className='h-16 w-16 py-2 px-[13px] flex items-center justify-center'>
                    <img className='' src={ fileIcon } alt=""/>
                </div>
            </Link>
        }
        {
          (company && companies.length) ?
            <>
              <div onClick={ () => setHiddenMode(false) }
                   className='w-full flex items-center cursor-pointer bg-interactive-bg h-16'>
                <div className='h-full w-16 py-2 px-[13px] flex items-center justify-center'>
                  <img className='' src={ company.logo ? company.logo : fileIcon } alt=""/>
                </div>
              </div>
            </>
            :
            <span></span>
        }
      </div>
    )
  }

  return (
    <div className='w-full bg-white text-gray-50 font-medium'>
      {
        companies?.length === 0 &&
          <Link to={ '/companies' }>
              <div className='h-16 w-full flex items-center cursor-pointer px-4'>
                  Создание компании
              </div>
          </Link>
      }
      {
        (company && companies.length) ?
          <>
            <div className='w-full bg-interactive-bg min-h-16 h-auto py-2 relative group'>
              <div className={ 'flex items-center cursor-pointer' } onClick={ () => setShowCompanies(! showCompanies) }>
                <div className='h-full w-16 py-2 px-[13px] flex items-center justify-center flex-shrink-0'>
                  <img className='' src={ company.logo ? company.logo : fileIcon } alt=""/>
                </div>
                <div className='grow relative leading-5 line-clamp-2'>
                  { company.title }
                </div>
                <div className='h-full w-14 flex items-center justify-center'>
                  <ChevronIcon color='gray' className={ `duration-300 ${ showCompanies && 'rotate-180' }` }/>
                </div>
              </div>
              <Link to={ `/companies/${ company.id }/edit` }
                    className={ `flex items-center bottom-0 justify-center text-center absolute -left-16 duration-500 group-hover:left-0 z-50 bg-white bg-opacity-90 h-full w-14` }>
                <img className={ 'h-5' } src={ edit } alt=""/>
              </Link>
            </div>

            <div
              className={ `w-full overflow-hidden duration-500 max-h-[684px] relative company-menu ${ companyMenuOverflowClass }` }
              style={ { height: `${ companyMenuHeight }px` } }>
              {
                companies.map((comp: any) => (
                  <div key={ comp.id } onClick={ () => {
                    selectCompany(comp.id);
                    setShowCompanies(false)
                  } } className={ 'h-16 w-full flex items-center cursor-pointer last-of-type:pb-4 group' }>
                    <div className='h-full w-16 py-2 px-[13px] flex items-center justify-center flex-shrink-0'>
                      <img className='' src={ comp.logo ? comp.logo : fileIcon } alt=""/>
                    </div>
                    { getCompanyTitle(comp) }
                    <Link to={ `/companies/${ comp.id }/edit` }
                          className={ `flex items-center justify-center text-center absolute -left-16 duration-500 ${ showCompanies ? 'group-hover:left-0' : '' }  bg-white bg-opacity-90 h-16 w-16` }>
                      <img className={ 'h-5' } src={ edit } alt=""/>
                    </Link>
                  </div>
                ))
              }

              <Link className={ 'sticky bottom-0 bg-white block' } to="/companies" onClick={ (e) => {
                if (checkIsFormDirty()) {
                  e.preventDefault()
                  openDialog('/companies')
                } else {
                  setShowCompanies(false)
                }
              } }>
                <div className='h-16 w-full border-t-2 border-gray-10 flex items-center justify-center cursor-pointer'>
                  Мои компании
                </div>
              </Link>
            </div>
          </>
          :
          <span></span>
      }
    </div>
  )
}
