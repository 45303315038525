import React from 'react'
import TextInput from '../../../components/common/form/input/TextInput';
import { YandexGeocoder, YandexSuggest } from "../EditBranch/Metrics";

export default function Metrics({ data, errors, updateFieldNoRender }: any) {
  return (
    <div className={ 'flex flex-col px-3 gap-y-6' }>
      <div className='text-[22px] leading-7 font-semibold'>Метрики</div>

      <div className='grid grid-cols-2 gap-4'>
        <TextInput
          name={ 'integration.ymetrika' }
          label='Яндекс метрика'
          textTooltip='Яндекс метрика'
          placeholder='600'
          value={ data.integrations.keys.ymetrika }
          onChange={ (value: any) => updateFieldNoRender('integrations.keys.ymetrika', value) }
          errors={ errors }
        />

        <TextInput
          name={ 'integration.googleAnalytics' }
          label='Google метрика'
          textTooltip='Google метрика'
          placeholder='1000'
          value={ data.integrations.keys.googleAnalytics }
          onChange={ (value: any) => updateFieldNoRender('integrations.keys.googleAnalytics', value) }
          errors={ errors }
        />

        <TextInput
          name={ 'integration.yandexWebmaster' }
          label='Яндекс вебмастер'
          textTooltip='Яндекс вебмастер'
          placeholder='300'
          value={ data.integrations.keys.yandexWebmaster }
          onChange={ (value: any) => updateFieldNoRender('integrations.keys.yandexWebmaster', value) }
          errors={ errors }
        />

        <TextInput
          name={ 'integration.googleWebmaster' }
          label='Google вебмастер'
          textTooltip='Google вебмастер'
          placeholder='90'
          value={ data.integrations.keys.googleWebmaster }
          onChange={ (value: any) => updateFieldNoRender('integrations.keys.googleWebmaster', value) }
          errors={ errors }
        />

        <TextInput
          name={ 'integration.vkWidget' }
          label='Виджет сообщений ВК'
          textTooltip='Виджет сообщений ВК'
          placeholder='300'
          value={ data.integrations.keys.vkWidget }
          onChange={ (value: any) => updateFieldNoRender('integrations.keys.vkWidget', value) }
          errors={ errors }
        />

        <TextInput
          name={ 'integrations.keys.dadata.token' }
          label='DaData (Токен)'
          textTooltip='DaData (Токен)'
          placeholder='300'
          value={ data.integrations.keys?.dadata?.token }
          onChange={ (value: any) => updateFieldNoRender('integrations.keys.dadata.token', value) }
          errors={ errors }
        />

        <TextInput
          name={ 'integrations.keys.dadata.secret' }
          label='DaData (Секрет)'
          textTooltip='DaData (Секрет)'
          placeholder='300'
          value={ data.integrations.keys?.dadata?.secret }
          onChange={ (value: any) => updateFieldNoRender('integrations.keys.dadata.secret', value) }
          errors={ errors }
        />


        <TextInput
          name={ 'integration.vkRetarget' }
          label='Ретаргетинг ВК'
          textTooltip='Ретаргетинг ВК'
          placeholder='300'
          value={ data.integrations.keys.vkRetarget }
          onChange={ (value: any) => updateFieldNoRender('integrations.keys.vkRetarget', value) }
          errors={ errors }
        />

        <TextInput
          name={ 'integration.facebookPixel' }
          label='Pixel Facebook'
          textTooltip='Pixel Facebook'
          placeholder='90'
          value={ data.integrations.keys.facebookPixel }
          onChange={ (value: any) => updateFieldNoRender('integrations.keys.facebookPixel', value) }
          errors={ errors }
        />
      </div>
      <div className={ 'grid grid-cols-2 gap-4' }>
        <YandexGeocoder data={ data } errors={ errors } updateFieldNoRender={ updateFieldNoRender }/>
        <YandexSuggest data={ data } errors={ errors } updateFieldNoRender={ updateFieldNoRender }/>
      </div>
    </div>
  )
}
