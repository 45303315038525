import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../../../components/common/Breadcrumbs";
import Base from "../CreateQuestions/Base";
import BaseButton from "../../../../components/common/Button/BaseButton";
import useForm from "../../../../hooks/useForm";
import initForm from "../initForm";
import {dialogModal} from "../../../../components/common/Modal/DialogModal";
import {apiClient} from "../../../../libs/api/apiClient";
import {showErrorToast, showSuccessToast} from "../../../../libs/helpers/toasts";
import {useCompaniesContext} from "../../../../contexts/Companies";
import {useBranchesContext} from "../../../../contexts/Branches";
import {useNavigate, useParams} from "react-router-dom";


export default function EditQuestions() {
  const {company}: any = useCompaniesContext()
  const {branch}: any = useBranchesContext()
  const [loading, setLoading] = useState(true)

  const {data, updateData, updateField, updateFieldNoRender, changedFieldsRefresh, errors, updateErrors, submit} = useForm(JSON.parse(JSON.stringify(initForm)))
  const {id: questionId} = useParams<{id?: string}>()
  const navigate = useNavigate()

  const handleSubmit = () => {
    submit(async (form: any) => {
      apiClient.nps.questions.update(company?.id, branch?.id, questionId,{body: form}).then(({data, errors}: any) => {
        if (data) {
          showSuccessToast({
            content: "Вопрос успешно изменен"
          })
          changedFieldsRefresh()
          getQuestion()
          navigate('/nps')
        } else {
          updateErrors(errors || {});

          showErrorToast({
            content: 'Что-то пошло не так'
          })

        }
      }).catch((e) => console.log(e))
    }, 'changed')
  }

  const getQuestion = () => {
    if (company && branch) {
      setLoading(true)
      // prefetch dictionaries
      apiClient.dictionaries.npsAnswerTypes().then();

      apiClient.nps.questions.get(company.id, branch.id, questionId).then(({data, errors}: any) => {
        if (data) {
          data.conditions = JSON.parse(JSON.stringify(data?.conditions), (key, value) => {
            if (key == 'children' && !value) {
              return []
            }

            if (value instanceof Array) {
              return value
            }

            if (isNaN(Number(value))) {
              return value
            }

            if (key == 'deletable' || key == 'isGroup') {
              return Boolean(+value)
            }

            return +value
          }) || []
          
          updateData(data)
        } else {
          updateErrors(errors || {})
          showErrorToast({
            content: 'Что-то пошло не так'
          })
        }
        setLoading(false)
      }).catch((err) => {
        setLoading(false)
        console.log(err)
      })
    }
  }

  const handleRemove = () => {
    dialogModal({
      isOpen: false,
      title: 'Внимание!',
      text: 'Вы уверены что хотите удалить вопрос?',
      showCancelButton: true,
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Вернуться',
    }).then((result) => {
      if (result.isConfirmed) {
        apiClient.nps.questions.remove(company.id, branch.id, questionId)
          .then(({data, errors}: any) => {
            showSuccessToast({
              content: "Вопрос успешно удален",
            })
            navigate('/nps')
          }).catch((err) => {
          updateErrors(errors || {})
          //updateData(company)
          showErrorToast({
            content: 'Что-то пошло не так'
          })
        })
      }
    }).catch(() => {})
  }

  useEffect(() => {
    getQuestion()
  }, [company, branch])

  if (!branch) return (
    <div className="text-gray-40 text-3xl text-center mt-[35vh]">
      <span>Выберите филиал</span>
    </div>
  )

  if (loading) return (
    <div className="text-gray-40 text-3xl text-center mt-[35vh]">
      <span>Загрузка...</span>
    </div>
  )

  return (
    <div className='px-8 pb-8 pt-2 text-gray-50'>
      <div className='mb-8'>
        <div className='text-[44px] leading-[60px] mb-2'>NPS</div>
        <Breadcrumbs
          links={[
            {to: `/nps`, label: 'Список вопросов'},
            {to: '', label: `Редактирование вопроса`},
          ]}

          back={`/nps`}
        />
      </div>
      <div className={'rounded-xl bg-white py-5'}>
        <Base data={data} updateField={updateField} errors={errors} updateFieldNoRender={updateFieldNoRender}/>
        <div className={'mt-6 border-gray-10 border-y-[1px] flex justify-between z-[50] w-full sticky bottom-0 bg-white'}>
          <BaseButton
            className={'bg-error-font text-white m-4 py-[7px]'} onClick={handleRemove}>Удалить вопрос
          </BaseButton>
          <BaseButton
            className={'bg-interactive-elem text-white m-4 py-[7px] '} onClick={handleSubmit}>Сохранить
          </BaseButton>
        </div>
      </div>
    </div>
  )
}
