import React, {useEffect, useState} from 'react'

import {apiClient} from "../../../libs/api/apiClient";
import { DatatableSelect } from '../../../components/common/form/select/datatable/DatatableSelect';
import Checkbox from '../../../components/common/form/checkbox/Checkbox'
import TextInput from './../../../components/common/form/input/TextInput';
import ImageLoad from './../../../components/common/form/image/ImageLoad';
import Textarea from './../../../components/common/form/input/Textarea';
import ImageLoadMultiple from '../../../components/common/form/image/ImageLoadMultiple';
import SingleSimpleValueSelect from "../../../components/common/form/select/SingleSimpleValueSelect";
import SimpleMultipleValueSelect from "../../../components/common/form/select/multiple/SimpleMultipleValueSelect";
import ScheduleTime from '../../../components/common/form/ScheduleTime';
import CheckboxList from '../../../components/common/form/checkbox/CheckboxList';
import Tooltip from "../../../components/common/Tooltip";
import {createColumnHelper} from "@tanstack/react-table";
import {useCategoriesContext} from "../../../contexts/Categories";
import {useProductsContext} from "../../../contexts/Products";
import TextEditor from '../../../components/common/form/input/TextEditor';

export default function Base({data, updateField,updateFieldNoRender, errors, branches, platforms}: any) {
  const columnHelper = createColumnHelper<any>()
  const [allMeasureUnits, setAllMeasureUnits] = useState<any>([])
  const { categories } = useCategoriesContext()
  const { products, filters, updateProducts, updateFilters, loading }: any = useProductsContext()

  useEffect(() => {
    apiClient.dictionaries.measureUnits().then(({ data }: any) => {
      setAllMeasureUnits(data);
    })
  }, [])

  const goodColumns = [
    columnHelper.accessor(row => row.id, {
      id: 'ID',
      header: () => 'ID',
      cell: info => <div className={'w-[85px] flex items-center justify-center text-center'}>{ info.getValue() }</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[85px] flex items-center justify-center text-center'
      }
    }),
    columnHelper.accessor(row => row.image, {
      id: 'image',
      header: () => 'Фото',
      cell: info =>
        <div className={'mr-4 w-[64px] flex items-center justify-center text-center'}>
          {
            info.getValue() ?
              <img src={info.getValue()} alt="" />
              :
              <div className='h-[60px] w-[60px] flex items-center justify-center text-[10px] text-gray-40 border-[1.5px] border-btn-secondary rounded-xl'>Image</div>
          }
        </div>,
      enableSorting: false,
      meta: {
        widthClass: 'mr-4 w-[64px] flex items-center justify-center text-center'
      }
    }),
    columnHelper.accessor(row => row.title, {
      id: 'title',
      header: () => 'Название',
      cell: info =>
        <div className={'grow flex items-center'}>
          { info.getValue() }
        </div>,
      enableSorting: true,
      meta: {
        widthClass: 'grow',
        sortField: 'title'
      }
    }),
    columnHelper.accessor(row => row.weight, {
      id: 'weight',
      header: () => 'Вес',
      cell: info => <div className={'max-w-[48px] grow flex items-center justify-center'}>{ info.getValue() }</div>,
      enableSorting: true,
      meta: {
        widthClass: 'max-w-[48px] grow justify-center',
        sortField: 'weight'
      }
    }),
    columnHelper.accessor(row => row.price, {
      id: 'price',
      header: () => 'Цена',
      cell: info => <div className={'max-w-[75px] grow flex items-center justify-center'}>{ info.getValue() }</div>,
      enableSorting: true,
      meta: {
        widthClass: 'max-w-[75px] grow justify-center',
        sortField: 'price'
      }
    }),
  ]

  return (
    <>
      <Checkbox
        label='Активность товара'
        name={'isActive'}
        value={data.isActive}
        onChange={(value: any) => updateField('isActive', value)}
        errors={errors}
      />

      <Checkbox
        label='Товар будет участвовать в оценке'
        name={'isEstimatable'}
        value={data?.isEstimatable}
        onChange={(value: any) => updateField('isEstimatable', value)}
        errors={errors}
      />

      <TextInput
        label='Название товара'
        textTooltip='Название товара'
        placeholder='Введите название товара'
        name={'title'}
        value={data?.title}
        onChange={(value: any) => updateFieldNoRender('title', value)}
        errors={errors}
        required={true}
      />

      <TextInput
        label='Артикул'
        textTooltip='Артикул'
        placeholder='Введите артикул'
        name={'article'}
        value={data?.article}
        onChange={(value: any) => updateFieldNoRender('article', value)}
        errors={errors}
        required={false}
      />

      <ImageLoad
        label='Основная фотография (формат: JPG, JPEG, PNG)'
        errors={errors}
        name={'image'}
        defaultValue={data?.image}
        onInput={(value: any) => {
          if (value !== data.image) {
            updateField('image', value)
          }
        }}
        resizeTo={[800, 800]}
      />

      <ImageLoadMultiple
        label="Доп. фотографии (формат: JPG, JPEG, PNG. Можно загрузить до 10 изображений)"
        onChange={(value: any) => updateField('images', value)}
        onDelete={(value: any) => {
          if (value && value?.id) {
            let removedIds = data.detachedImages.slice()
            removedIds.push(value.id)
            updateField('detachedImages', removedIds)
          }
        }}
        value={data?.images}
        errors={errors}
        name='images'
        max={10}
        resizeTo={[800, 800]}
        classNameImgWrapper={'!h-[88px]'}
      />

      <TextInput
        type='number'
        min='0'
        label='Сортировка'
        textTooltip='Сортировка'
        placeholder='Введите число'
        name={'position'}
        value={data?.position}
        onChange={(value: any) => updateFieldNoRender('position', value)}
        errors={errors}
      />

      <SimpleMultipleValueSelect
        required={true}
        label={'Разделы каталога'}
        textTooltip={'Разделы каталога'}
        options={categories.map((category: any) => ({label: category.title, value: category.id })) || []}
        value={data?.categories || []}
        onChange={(value: any) => updateField('categories', value)}
        // name={'categories'}
        placeholder={'Выберите разделы'}
        errors={errors}
        wordsArray={['раздел', 'раздела', 'разделов']}
      />

      {
        !!errors['categories']?.at(0) &&
        <span className={'text-error-font text-xs font-medium'}>{errors['categories'].at(0)}</span>
      }

      <TextInput
        required={true}
        type='number'
        min='0'
        label='Цена'
        placeholder='0'
        name={'price'}
        value={data?.price}
        onChange={(value: any) => updateFieldNoRender('price', value)}
        errors={errors}
      />

      <TextInput
        type='number'
        min='0'
        label='Старая цена'
        textTooltip='Старая цена'
        placeholder='0'
        name={'oldPrice'}
        value={data?.oldPrice}
        onChange={(value: any) => updateFieldNoRender('oldPrice', value)}
        errors={errors}
      />

      <TextInput
        type='number'
        min='0'
        label='Себестоимость'
        textTooltip='Себестоимость'
        placeholder='0'
        name={'costPrice'}
        value={data?.costPrice}
        onChange={(value: any) => updateFieldNoRender('costPrice', value)}
        errors={errors}
      />

      <TextInput
        type='number'
        min='0'
        label='Вес (гр.)'
        textTooltip='Вес (гр.)'
        placeholder='0'
        name={'weight'}
        value={data?.weight}
        onChange={(value: any) => updateFieldNoRender('weight', value)}
        errors={errors}
      />

      <TextInput
        type='number'
        min='0'
        label='Объем (мл.)'
        textTooltip='Объем (мл.)'
        placeholder='0'
        name={'volume'}
        value={data?.volume}
        onChange={(value: any) => updateFieldNoRender('volume', value)}
        errors={errors}
      />

      <TextInput
        label='Размер (произвольные единицы)'
        textTooltip='Размер (произвольные единицы)'
        placeholder='0'
        name={'size'}
        value={data?.size}
        onChange={(value: any) => updateFieldNoRender('size', value)}
        errors={errors}
      />

      <div className='flex flex-col gap-4 rounded-2xl border-[1px] border-gray-20 p-4'>
        <div className='text-sm font-bold'>Пищевая ценность на 100 г.</div>

        <TextInput
          type='number'
          min='0'
          label='Энергетическая ценность'
          textTooltip='Энергетическая ценность'
          placeholder='0'
          name={'foodValuePerHundredGram.energyValue'}
          value={data?.foodValuePerHundredGram?.energyValue || ''}
          onChange={(value: any) => updateFieldNoRender('foodValuePerHundredGram.energyValue', value)}
          errors={errors}
        />

        <TextInput
          type='number'
          min='0'
          label='Белки'
          textTooltip='Белки'
          placeholder='0'
          name={'foodValuePerHundredGram.protein'}
          value={data?.foodValuePerHundredGram?.protein || ''}
          onChange={(value: any) => updateFieldNoRender('foodValuePerHundredGram.protein', value)}
          errors={errors}
        />

        <TextInput
          type='number'
          min='0'
          label='Жиры'
          textTooltip='Жиры'
          placeholder='0'
          name={'foodValuePerHundredGram.fat'}
          value={data?.foodValuePerHundredGram?.fat || ''}
          onChange={(value: any) => updateFieldNoRender('foodValuePerHundredGram.fat', value)}
          errors={errors}
        />

        <TextInput
          type='number'
          min='0'
          label='Углеводы'
          textTooltip='Углеводы'
          placeholder='0'
          name={'foodValuePerHundredGram.carbohydrate'}
          value={data?.foodValuePerHundredGram?.carbohydrate || ''}
          onChange={(value: any) => updateFieldNoRender('foodValuePerHundredGram.carbohydrate', value)}
          errors={errors}
        />
      </div>

      <div className='flex flex-col gap-4 rounded-2xl border-[1px] border-gray-20 p-4'>
        <div className='text-sm font-bold'>Пищевая ценность на порцию.</div>

        <TextInput
          type='number'
          min='0'
          label='Энергетическая ценность'
          textTooltip='Энергетическая ценность'
          placeholder='0'
          name={'foodValuePerPortion.energyValue'}
          value={data?.foodValuePerPortion?.energyValue || ''}
          onChange={(value: any) => updateFieldNoRender('foodValuePerPortion.energyValue', value)}
          errors={errors}
        />

        <TextInput
          type='number'
          min='0'
          label='Белки'
          textTooltip='Белки'
          placeholder='0'
          name={'foodValuePerPortion.protein'}
          value={data?.foodValuePerPortion?.protein || ''}
          onChange={(value: any) => updateFieldNoRender('foodValuePerPortion.protein', value)}
          errors={errors}
        />

        <TextInput
          type='number'
          min='0'
          label='Жиры'
          textTooltip='Жиры'
          placeholder='0'
          name={'foodValuePerPortion.fat'}
          value={data?.foodValuePerPortion?.fat || ''}
          onChange={(value: any) => updateFieldNoRender('foodValuePerPortion.fat', value)}
          errors={errors}
        />

        <TextInput
          type='number'
          min='0'
          label='Углеводы'
          textTooltip='Углеводы'
          placeholder='0'
          name={'foodValuePerPortion.carbohydrate'}
          value={data?.foodValuePerPortion?.carbohydrate || ''}
          onChange={(value: any) => updateFieldNoRender('foodValuePerPortion.carbohydrate', value)}
          errors={errors}
        />
      </div>

      <SingleSimpleValueSelect
        label='Ед. измерения'
        textTooltip='Ед. измерения'
        placeholder={'Выберите вариант'}
        errors={errors}
        name='measureUnit'
        value={data.measureUnit || []}
        options={allMeasureUnits.map(({title, value}: any) => ({label : title, value})) || []}
        onChange={(value: any) => updateField('measureUnit', value)}
      />

      <Textarea
        label='Краткое описание'
        textTooltip='Краткое описание'
        required={true}
        placeholder='Введите краткое описание'
        name={'shortDescription'}
        value={data.shortDescription}
        onChange={value => updateFieldNoRender('shortDescription', value)}
        errors={errors}
        maxLength={undefined}
      />

      <TextEditor
        label="Подробное описание"
        textTooltip="Введите подробное описание"
        placeholder='Подробное описание'
        value={data.description}
        onChange={(value: any) => updateFieldNoRender('description', value)}
      />

      {/* // TODO: Товары */}
      <div className='flex flex-col gap-4 rounded-2xl border-[1px] border-gray-20 p-4'>
        <div className={'flex flex-row items-center'}>
          <div className='text-sm font-bold'>Товары</div>
          <Tooltip textTooltip={'Товары'} defaultText={'Товары'}/>
        </div>

        <Checkbox
          label='Можно добавить в корзину'
          name={'goodsCanBeAddedToCart'}
          value={data.goodsCanBeAddedToCart}
          onChange={(value: any) => updateField('goodsCanBeAddedToCart', value)}
          errors={errors}
        />
        <DatatableSelect
          data={products.filter((product: any) => !product.skuParentId) || []}
          filteredData={products || []}
          label={'Товары'}
          textTooltip={'Товары'}
          values={data.goods}
          errors={errors}
          name={'goods'}
          placeholder={'Выберите товары'}
          removeItem={(index: any) => {
            let goods = data.goods.slice()
            goods.splice(index, 1)
            updateField('goods', goods)
          }}
          updateValues={(values: any) => {
            updateField('goods', values)
            if (!values.length) {
              updateField('emptyGoods', true)
            }
          }}
          updateData={(params: any) => {
            if ('search' in params) {
              updateFilters('search', params.search)
              delete params.search
            }
            updateProducts(params, filters)
          }}
          columns={goodColumns}
          resetFilters={() => {
            updateFilters('filter.categories', null)
            updateField('__tableCategories', '')
            updateProducts(null, null)
          }}
          loading={loading}
        >
          <SingleSimpleValueSelect
            required={false}
            placeholder={'Выберите раздел'}
            errors={{}}
            name='__tableCategories'
            options={categories.map((category: any) => ({ label: category.title, value: category.id })) || []}
            onChange={(value: any) => {
              updateFilters('filter.categories', value)
              updateProducts(null, filters)
              updateField('__tableCategories', value)
            }}
            removeValue={() => {
              updateFilters('filter.categories', null)
              updateProducts(null, filters)
              updateField('__tableCategories', '')
            }}
            removeItem={true}
            value={data.__tableCategories || ''}
          />
        </DatatableSelect>

        <TextInput
          label='Заголовок блока "Наборов"'
          textTooltip='Заголовок блока "Наборов"'
          placeholder='Введите заголовок'
          name={'titleOfSetsBlock'}
          value={data.titleOfSetsBlock}
          onChange={(value: any) => updateFieldNoRender('titleOfSetsBlock', value)}
          errors={errors}
        />
      </div>
      <SimpleMultipleValueSelect
        label={'Филиалы'}
        textTooltip={'Филиалы'}
        options={branches || []}
        required={true}
        value={data.branches || []}
        onChange={(value: any) => updateField('branches', value)}
        name={'branches'}
        placeholder={'Выберите филиалы'}
        errors={errors}
      />

      <ScheduleTime
        label={'Показ товара'}
        textTooltip={'Показ товара'}
        notActiveLabel={'Не показывать'}
        name='openHours'
        value={data.openHours}
        onChange={(value: any) => updateField('openHours', value)}
        errors={errors}
      />

      <CheckboxList
        label='Платформы'
        name='platforms'
        errors={errors}
        options={platforms}
        values={data.platforms}
        onChange={(value: any) => updateField('platforms', value)}
      />
    </>
  )
}
