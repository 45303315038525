
import BaseButton from "../../Button/BaseButton";
import React, {useEffect, useId, useRef, useState} from "react";
// @ts-ignore
import closeIcon from "../../../../images/greenClose.svg"
import DataTable from "../../Table/DataTable";
import {createColumnHelper} from "@tanstack/react-table";
import Search from "../../Table/Search";
import {create, InstanceProps} from "react-modal-promise";

interface ModalProps extends InstanceProps<any> {
  isOpen: boolean
  onResolve: any
  onReject: any
  title: string
  data: any
  value: any
  errors?: any
  showDenyButton?: boolean
  showCancelButton?: boolean
  showCloseButton?: boolean
  showSubmitButton?: boolean
  allowOutsideClick?: boolean
  confirmButtonText?: string
  denyButtonText?: string
  cancelButtonText?: string
  classNameWrapper?: string
}

const MultiSelectModal = (
  {
    isOpen,
    onResolve,
    onReject,
    title,
    data,
    value,
    showDenyButton = false,
    showCancelButton = false,
    showCloseButton = true,
    showSubmitButton = true,
    allowOutsideClick = false,
    confirmButtonText = 'Сохранить',
    denyButtonText = 'Вернуться',
    cancelButtonText = 'Отмена',
    classNameWrapper = ''
  }: ModalProps) => {
  const id = useId();
  //const input = useRef(null);
  const wrapper = useRef<any>(null)
  const [selectedRows, setSelectedRows] = useState<any>(value || [])
  const [search, setSearch] = useState<any>('')
  const [options, setOptions] = useState<any>(data || [])
  const columnHelper = createColumnHelper<any>()

  const columns = [
    columnHelper.accessor(row => row.label, {
      id: 'label',
      header: () => 'Название',
      cell: info => <div
        className={'w-[80%] font-normal break-all flex items-center'}>{info.getValue()}{info?.row?.original?.value ? ` (${info?.row?.original?.value})`: ''}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[80%] flex text-center',
      }
    }),
  ]

  /* Закрываем при клике вне поля*/
  useEffect(() => {
    function handleClick(event: any) {
      if (wrapper.current && !wrapper.current.contains(event.target) && allowOutsideClick) {
        onReject({isCancelled: true})
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }

  }, [isOpen, onReject, wrapper]);

  useEffect(() => {
    setOptions(data)
  }, [data])

  useEffect(() => {
    setSelectedRows(value)
  }, [value])

  const fetchData = (value: any) => {
    const filteredData = data?.filter((item: any) => item?.label?.toLowerCase().includes(value.toLowerCase()) || ('value' in item && item?.value?.toString()?.toLowerCase().includes(value.toLowerCase())))
    setOptions(filteredData)
  }

  return (
    <div id={id} className={`fixed grid w-full h-full overflow-y-auto modal-wrapper ${!isOpen && 'hidden'}`}>
      <div ref={wrapper} className={`grid relative bg-white rounded-[10px] max-w-[90%] max-h-full modal ${classNameWrapper}`}>
        <div className={'flex justify-between px-4 py-[14px] items-center'}>
          <span className={'text-xl'}>{title}</span>
          {
            showCloseButton &&
              <img src={closeIcon} className={'w-[20px] cursor-pointer'} onClick={() => {
                onReject({isCancelled: true})
              }} alt=""/>
          }
        </div>
        <div className="p-4">
          <div className={'flex flex-col gap-y-2'}>
            <Search
              name={'searchFunnelData'}
              value={search}
              disabled={false}
              placeholder={'Поиск'}
              updateValue={(value: any) => setSearch(value)}
              fetchData={(value: any) => fetchData(value)}
            />
            <DataTable
              classNameTable={'p-3 mb-[20px] !pt-0'}
              classNameRow={'justify-between'}
              data={options || []}
              columns={columns}
              usePagination={false}
              useMassAction={true}
              selectedRows={selectedRows}
              onChangeSelectionRows={(values: any) => setSelectedRows(values)}
              updateData={(params: any) => console.log(params)}
              fixedHeader={'sticky top-0 bg-white justify-between'}
              rowIdentifierField={'value'}
            />
          </div>
        </div>

        <div className="flex flex-row justify-between py-4 px-4 gap-y-4">
          {
            showDenyButton && <BaseButton className={'bg-error-font border-2 border-error-font text-white py-[7px]'} onClick={() => {
              onResolve({isConfirmed: false, isDenied: true})
            }}>{denyButtonText}</BaseButton>
          }
          <div className={`flex flex-row gap-x-4 items-center justify-between ${!showDenyButton && 'w-full'}`}>
            {
              showCancelButton && <BaseButton className={'bg-white border-2 text-error-font border-error-font py-[7px]'} onClick={() => {
                onReject({isCancelled: true})
              }}>{cancelButtonText}</BaseButton>
            }
            {
              showSubmitButton &&
                <BaseButton className={'bg-interactive-elem border-2 border-interactive-elem text-white py-[7px]'} onClick={() => {
                  onResolve({isConfirmed: true, isDenied: false, values: selectedRows})
                }}>{confirmButtonText}</BaseButton>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export const multiSelectModal = create(MultiSelectModal)
