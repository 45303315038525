import {apiClient} from "../../libs/api/apiClient";
import {showErrorToast} from "../../libs/helpers/toasts";


enum ActionEnum {
  detach = 'detach',
  attach = 'attach',
  activate = 'activate',
  deactivate = 'deactivate',
}

enum Tabs {
  categories= 'categories',

}

export const submitAction = (callback: any, action: string, tab: string, companyId: any, branchId: any, data: any) => {
  switch (tab) {
    case Tabs.categories:
      switch (action) {
        case ActionEnum.attach:
          bulkCategoriesAttach(callback, companyId, branchId, data)
          break
        case ActionEnum.detach:
          bulkCategoriesDetach(callback, companyId, branchId, data)
          break
        case ActionEnum.activate:
          bulkCategoriesActivate(callback, companyId, branchId, data)
          break
        case ActionEnum.deactivate:
          bulkCategoriesDeactivate(callback, companyId, branchId, data)
          break
        default:
          break
      }
      break
    default:
      break
  }
}

const bulkCategoriesAttach = (callback: any, companyId: any, branchId: any, data: any) => {
  apiClient.categories.bulkAttach(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      } else {
        callback()
      }
    })
    .catch(() => {
      showErrorToast({
        content: 'Что-то пошло не так'
      })
    })
}

const bulkCategoriesDetach = (callback: any, companyId: any, branchId: any, data: any) => {
  apiClient.categories.bulkDetach(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      } else {
        callback()
      }
    })
    .catch(() => {
      showErrorToast({
        content: 'Что-то пошло не так'
      })
    })
}

const bulkCategoriesActivate = (callback: any, companyId: any, branchId: any, data: any) => {
  apiClient.categories.bulkActivate(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      } else {
        callback()
      }
    })
    .catch(() => {
      showErrorToast({
        content: 'Что-то пошло не так'
      })
    })
}


const bulkCategoriesDeactivate = (callback: any, companyId: any, branchId: any, data: any) => {
  apiClient.categories.bulkDeactivate(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      } else {
        callback()
      }
    })
    .catch(() => {
      showErrorToast({
        content: 'Что-то пошло не так'
      })
    })
}
