import React, {useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom";

import {useCompaniesContext} from "../../../../contexts/Companies";
import {useBranchesContext} from "../../../../contexts/Branches";
import {useOrdersTagsContext} from "../../../../contexts/Tags";

import Breadcrumbs from "../../../../components/common/Breadcrumbs";
import useForm from "../../../../hooks/useForm";
import initForm from "../initForm";
import BaseButton from "../../../../components/common/Button/BaseButton";
import {apiClient} from "../../../../libs/api/apiClient";
import Base from "../CreateOrdersTag/Base";
import {showErrorToast, showSuccessToast} from "../../../../libs/helpers/toasts";
import {dialogModal} from "../../../../components/common/Modal/DialogModal";

export default function EditOrdersTag() {
  const {company} = useCompaniesContext()
  const {branch} = useBranchesContext()
  const {updateOrdersTags} = useOrdersTagsContext()

  const [loading, setLoading] = useState(true)

  const {data, updateData, updateField, updateFieldNoRender, errors, updateErrors, submit} = useForm(JSON.parse(JSON.stringify(initForm)))

  const navigate = useNavigate()
  const {id} = useParams<{ id?: string }>()

  useEffect(() => {
    if (company && branch) {
      setLoading(true)
      apiClient.ordersTags.get(company.id, branch.id, id).then(({data, errors}) => {
        if (data) {
          for (let key in data)
            if (data[key] === null)
              delete (data[key])
          updateData(data)
          setLoading(false)
        } else {
          updateErrors(errors || {})
        }
      })
    }
  }, [company?.id, branch?.id, id])

  const handleSubmit = () => {
    if (company && branch) {
      submit(form => {
          apiClient.ordersTags.update(company.id, branch.id, id, {body: form}).then(({data, errors, message}) => {
            if (errors || message) {
              updateErrors(errors || {})
              showErrorToast({
                content: 'Что-то пошло не так'
              })
            } else {
              updateOrdersTags()
              showSuccessToast({
                content: 'Сохранение прошло успешно',
              })
            }
          })
        }
        , 'changed')
    }
  }

  const handleRemove = () => {
    dialogModal({
      isOpen: false,
      title: 'Внимание!',
      text: 'Вы уверены что хотите удалить метку?',
      showCancelButton: true,
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Вернуться',
    }).then((result) => {
      if (result.isConfirmed) {
        if (company && branch) {
          apiClient.ordersTags.remove(company?.id, branch.id, id)
            .then(() => {
              updateOrdersTags()
              showSuccessToast({
                content: 'Метка удалена успешно',
              })
              navigate('/tags')
            }).catch((err) => {
            showErrorToast({
              content: 'Что-то пошло не так'
            })
          })
        }
      }
    }).catch(() => {})
  }

  if (loading) return (
    <div className="text-gray-40 text-3xl text-center mt-[35vh]">
      <span>Загрузка...</span>
    </div>
  )

  return (
    <div className='px-8 pb-8 pt-2 text-gray-50'>
      <div className='mb-8'>
        <div className='text-[44px] leading-[60px] mb-2'>Метки</div>
        <Breadcrumbs
          links={[
            {to: `/tags`, label: 'Список меток заказов'},
            {to: '', label: 'Редактирование метки заказов'},
          ]}

          back={`/tags`}
        />
      </div>
      <div className={'rounded-xl bg-white py-5'}>
        <Base errors={errors} data={data} updateField={updateField} updateFieldNoRender={updateFieldNoRender}/>
        <div className={'mt-6 border-gray-10 border-y-[1px] flex justify-end z-[50] w-full sticky bottom-0 bg-white'}>
          <BaseButton
            className={'w-[225px] bg-error-main text-white m-4 py-[7px] '} onClick={handleRemove}>Удалить метку
          </BaseButton>
          <BaseButton
            className={'w-[225px] bg-interactive-elem text-white m-4 py-[7px] '} onClick={handleSubmit}>Сохранить метку
          </BaseButton>
        </div>
      </div>
    </div>
  )
}
