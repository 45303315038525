import React, {useEffect, useState} from "react";
import Base from "../CreateClient/Base";
import BaseButton from "../../../components/common/Button/BaseButton";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import initForm from "../initForm";
import {useNavigate, useParams} from "react-router-dom";
import useForm from "../../../hooks/useForm";
import {apiClient} from "../../../libs/api/apiClient";
import {CustomersTagsContextWrapper} from "../../../contexts/Tags";
import {useCompaniesContext} from "../../../contexts/Companies";
import {useBranchesContext} from "../../../contexts/Branches";
import {showErrorToast, showSuccessToast} from "../../../libs/helpers/toasts";
import {dialogModal} from "../../../components/common/Modal/DialogModal";
import { useSidebarContext } from "../../../contexts/Sidebar";
import {useFormContext} from "../../../contexts/Form";


export default function EditClient() {
  const {company} = useCompaniesContext()
  const {branch} = useBranchesContext()
  const { hiddenMode } = useSidebarContext()
  const {checkIsFormDirty, openDialog,  needUpdate, setNeedUpdate } = useFormContext()

  const [phoneInfo, setPhoneInfo] = useState(null)
  const [loading, setLoading] = useState<boolean>(true)

  const {data, updateData, updateField, updateFieldNoRender, errors, updateErrors, changedFieldsRefresh, submit} = useForm(JSON.parse(JSON.stringify(initForm)))
  const navigate = useNavigate();
  const {id: clientId} = useParams<{id?: string}>()


  const removeClient = () => {
    dialogModal({
      isOpen: false,
      title: 'Внимание!',
      text: 'Вы уверены что хотите удалить клиента?',
      showCancelButton: true,
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Вернуться',
    }).then((result) => {
      if (result.isConfirmed) {
        apiClient.customers.remove(company?.id, branch?.id, clientId)
          .then(() => {
              showSuccessToast({
                content: "Клиент успешно удален",
              })
              navigate('/clients')
            }
          ).catch((err) => {
          updateErrors(errors || {})
          //updateData(company)
          showErrorToast({
            content: 'Что-то пошло не так'
          })
        })
      }
    }).catch(() => {})
  }

  useEffect(() => {
    getClient()
  }, [company, branch])

  const handleSubmit = () => {
    submit(async (form: any) => {
      apiClient.customers.update(company?.id, branch?.id, clientId,{body: form}).then(({data, errors}: any) => {
        if (data) {
          showSuccessToast({
            content: "Клиент успешно изменен"
          })
          changedFieldsRefresh()
          getClient()
        } else {
          updateErrors(errors || {});

          showErrorToast({
            content: 'Что-то пошло не так'
          })

        }
      }).catch(() => { }).finally(() => setNeedUpdate(false))
    }, 'changed')
  }

  useEffect(() => {
    if (needUpdate) {
      handleSubmit()
    }
  }, [needUpdate])
  const reset = () => {
    navigate('/clients')
  }

  const getClient = () => {
    if (company && branch) {
      setLoading(true)


      apiClient.customers.get(company?.id, branch?.id, clientId).then(({data, errors}: any) => {
        if (data) {
          updateData(data)
        } else {
          updateErrors(errors || {})
          showErrorToast({
            content: 'Что-то пошло не так'
          })
        }
        setLoading(false)
      }).catch((err) => {
        setLoading(false)
        console.log(err)
      })
    }
  }

  useEffect(() => {
    if (branch && company) {
      apiClient.branches.get(company.id, branch.id).then(({data, errors}: any) => {
        if (data) {
          setPhoneInfo(data?.extended?.phoneCode || null)
        }
      })
    }
  }, [branch])

  if (!branch) {
    return <div>
      <div className="text-gray-40 text-3xl text-center mt-[35vh]">
        Выберите филиал
      </div>
    </div>
  }

  if (loading) return (
    <div className="text-gray-40 text-3xl text-center mt-[35vh]">
      <span>Загрузка...</span>
    </div>
  )

  return (
    <div className={`px-8 pb-8 pt-2 text-gray-50 ${hiddenMode ? 'w-[calc(99vw-60px)]' : 'w-[calc(99vw-340px)]'}`}>
      <div className='mb-8'>
        <div className='text-[44px] leading-[60px] mb-2'>Клиенты</div>
        <Breadcrumbs
          links={[
            {to: `/clients`, label: 'Список клиентов'},
            {to: '', label: 'Изменение клиента'},
          ]}

          back={`/clients`}
        />
      </div>
      <div className={'rounded-xl bg-white py-5'}>
        <CustomersTagsContextWrapper>
          <Base errors={errors} data={data} updateField={updateField} updateFieldNoRender={updateFieldNoRender} phoneInfo={phoneInfo} isEdit={true}/>
        </CustomersTagsContextWrapper>
        <div className={'mt-6 border-gray-10 border-[1px] flex justify-between z-[0] w-full sticky bottom-0 bg-white'}>
          <BaseButton
            className={'bg-error-main text-white m-4 py-[7px]'} onClick={removeClient}>Удалить
          </BaseButton>
          <div className={'flex flex-row items-center'}>
            <BaseButton
              className={'border-error-main border-2 text-error-main m-4 py-[7px]'}
              onClick={(e: any) => {
                if (checkIsFormDirty()) {
                  e.preventDefault()
                  openDialog('/clients')

                }else reset()
              }}
            >Отмена
            </BaseButton>
          <BaseButton
            className={'w-[225px] bg-interactive-elem text-white m-4 py-[7px]'} onClick={handleSubmit}>Сохранить изменения
          </BaseButton>
          </div>
        </div>
      </div>
    </div>
  )
}
