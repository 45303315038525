import React from 'react'
import Header from "../components/common/Header/Header"
import Sidebar from "../components/common/Sidebar/Sidebar";
import { CompaniesContextWrapper } from "../contexts/Companies";
import { BranchesContextWrapper } from '../contexts/Branches';
import { Outlet } from "react-router-dom";
import { SidebarContextWrapper } from '../contexts/Sidebar';
import { FormContextWrapper } from '../contexts/Form';

export default function Primary() {
  return (
    <>
      <CompaniesContextWrapper>
        <BranchesContextWrapper>
          <FormContextWrapper>
            <SidebarContextWrapper>
              <div className="App flex">
                <Sidebar />
                <div className="w-full min-h-screen max-w-full">
                  <Header />
                  <Outlet />
                </div>
              </div>
            </SidebarContextWrapper>
          </FormContextWrapper>
        </BranchesContextWrapper>
      </CompaniesContextWrapper>
    </>
  )
}
