import { dialogModal } from "../../components/common/Modal/DialogModal";
import {apiClient} from "../../libs/api/apiClient";
import {showErrorToast} from "../../libs/helpers/toasts";

enum ActionEnum {
  detachBranch = 'detachBranch',
  attachBranch = 'attachBranch',
  activate = 'activate',
  deactivate = 'deactivate',
  remove = 'remove',
  copyCategories = 'copyCategories',
  attachCategories = 'attachCategories',
  detachCategories = 'detachCategories',
  mergeSku = 'mergeSku',
}

enum Tabs {
  products = 'products',
}

export const submitAction = (callback: any, action: string, tab: string, companyId: any, branchId: any, data: any) => {
  switch (tab) {
    case Tabs.products:
      switch (action) {
        case ActionEnum.detachBranch:
          bulkProductsDetachBranch(callback, companyId, branchId, data)
          break
        case ActionEnum.attachBranch:
          bulkProductsAttachBranch(callback, companyId, branchId, data)
          break
        case ActionEnum.activate:
          bulkProductsActivate(callback, companyId, branchId, data)
          break
        case ActionEnum.deactivate:
          bulkProductsDeactivate(callback, companyId, branchId, data)
          break
        case ActionEnum.copyCategories:
          bulkProductsCopyCategories(callback, companyId, branchId, data)
          break
        case ActionEnum.attachCategories:
          bulkProductsAttachCategories(callback, companyId, branchId, data)
          break
        case ActionEnum.detachCategories:
          bulkProductsDetachCategories(callback, companyId, branchId, data)
          break
        case ActionEnum.mergeSku:
          bulkProductsMergeSku(callback, companyId, branchId, data)
          break
        case ActionEnum.remove:
          bulkProductsRemove(callback, companyId, branchId, data)
          break
        default:
          break
      }
      break
    default:
      break
  }
}

 const bulkProductsAttachBranch = (callback: any, companyId: any, branchId: any, data: any) => {
   apiClient.products.bulkAttachBranch(companyId, branchId, {body: data})
     .then(({data, errors, message}: any) => {
       if (errors || message) {
         showErrorToast({
           content: 'Что-то пошло не так'
         })
       } else {
         callback()
       }
     })
     .catch(() => {
       showErrorToast({
         content: 'Что-то пошло не так'
       })
     })
 }

 const bulkProductsDetachBranch = (callback: any, companyId: any, branchId: any, data: any) => {
   apiClient.products.bulkDetachBranch(companyId, branchId, {body: data})
     .then(({data, errors, message}: any) => {
       if (errors || message) {
         showErrorToast({
           content: 'Что-то пошло не так'
         })
       } else {
         callback()
       }
     })
     .catch(() => {
       showErrorToast({
         content: 'Что-то пошло не так'
       })
     })
 }


 const bulkProductsActivate = (callback: any, companyId: any, branchId: any, data: any) => {
   apiClient.products.bulkActivate(companyId, branchId, {body: data})
     .then(({data, errors, message}: any) => {
       if (errors || message) {
         showErrorToast({
           content: 'Что-то пошло не так'
         })
       } else {
         callback()
       }
     })
     .catch(() => {
       showErrorToast({
         content: 'Что-то пошло не так'
       })
     })
 }


 const bulkProductsDeactivate = (callback: any, companyId: any, branchId: any, data: any) => {
   apiClient.products.bulkDeactivate(companyId, branchId, {body: data})
     .then(({data, errors, message}: any) => {
       if (errors || message) {
         showErrorToast({
           content: 'Что-то пошло не так'
         })
       } else {
         callback()
       }
     })
     .catch(() => {
       showErrorToast({
         content: 'Что-то пошло не так'
       })
     })
 }

 const bulkProductsCopyCategories = (callback: any, companyId: any, branchId: any, data: any) => {
   apiClient.products.bulkCopy(companyId, branchId, {body: data})
     .then(({data, errors, message}: any) => {
       if (errors || message) {
         showErrorToast({
           content: 'Что-то пошло не так'
         })
       } else {
         callback()
       }
     })
     .catch(() => {
       showErrorToast({
         content: 'Что-то пошло не так'
       })
     })
 }

 const bulkProductsAttachCategories = (callback: any, companyId: any, branchId: any, data: any) => {
   apiClient.products.bulkAttachCategories(companyId, branchId, {body: data})
     .then(({data, errors, message}: any) => {
       if (errors || message) {
         showErrorToast({
           content: 'Что-то пошло не так'
         })
       } else {
         callback()
       }
     })
     .catch(() => {
       showErrorToast({
         content: 'Что-то пошло не так'
       })
     })
 }

const bulkProductsDetachCategories = (callback: any, companyId: any, branchId: any, data: any) => {
  apiClient.products.bulkDetachCategories(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      } else {
        callback()
      }
    })
    .catch(() => {
      showErrorToast({
        content: 'Что-то пошло не так'
      })
    })
}

 const bulkProductsMergeSku = (callback: any, companyId: any, branchId: any, data: any) => {
   apiClient.products.bulkMergeSku(companyId, branchId, {body: data})
     .then(({data, errors, message}: any) => {
       if (errors || message) {
         showErrorToast({
           content: message || 'Что-то пошло не так'
         })
       } else {
         callback()
       }
     })
     .catch((reason: any) => {
       showErrorToast({
         content: 'Что-то пошло не так'
       })
     })
 }

const bulkProductsRemove = (callback: any, companyId: any, branchId: any, data: any) => {
  dialogModal({
    isOpen: false,
    title: 'Внимание!',
    text: 'Вы уверены что хотите удалить выбранные товары?',
    showCancelButton: true,
    confirmButtonText: 'Удалить',
    cancelButtonText: 'Вернуться',
  }).then((result) => {
    if (result.isConfirmed) {
      apiClient.products.bulkRemove(companyId, branchId, { body: data })
        .then(({ data, errors, message }: any) => {
          if (errors || message) {
            showErrorToast({
              content: message || 'Что-то пошло не так'
            })
          } else {
            callback()
          }
        })
        .catch((reason: any) => {
          showErrorToast({
            content: 'Что-то пошло не так'
          })
        })
    }
  }).catch(() => { })
}
