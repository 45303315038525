//

const initForm = {
  name: '',
  phone: '',
  personalDiscount: '',
  telegram: '',
  vkId: '',
  email: '',
  birthDate: '',
}

export default initForm
