import React from "react";
import TextInput from "../../../components/common/form/input/TextInput";
import Textarea from "../../../components/common/form/input/Textarea";


export default function Base({data, errors, updateField, updateFieldNoRender}: any) {

  return (
    <div className={'flex flex-col px-3 gap-y-6'}>
      <TextInput
        label={'Название'}
        textTooltip={'Название'}
        placeholder={'Введите название'}
        name={'title'}
        value={data.title}
        onChange={value => updateFieldNoRender('title', value)}
        errors={errors}
        required={true}
      />

      <Textarea
        label='Описание'
        textTooltip='Описание'
        placeholder='Введите описание'
        name={'description'}
        value={data.description}
        onChange={value => updateFieldNoRender('description', value)}
        errors={errors}
      />

      <TextInput
        type={'number'}
        min={'0'}
        max={'100'}
        label={'Максимальная скидка, (%)'}
        textTooltip={'Максимальная скидка, (%)'}
        placeholder={'Введите скидку в процентах'}
        name={'maxDiscount'}
        value={data.maxDiscount}
        onChange={value => updateFieldNoRender('maxDiscount', value)}
        errors={errors}
      />

      <TextInput
        type={'number'}
        min={'0'}
        label={'Минимальное количество статусных баллов'}
        textTooltip={'Минимальное количество статусных баллов'}
        placeholder={'564'}
        name={'minScore'}
        value={data.minScore}
        onChange={value => updateFieldNoRender('minScore', value)}
        errors={errors}
      />
    </div>
  )
}
