import React, { createContext, useContext, useEffect, useState } from "react";
import { apiClient } from "../libs/api/apiClient";
import { useCompaniesContext } from "./Companies";
import { preparePaginationQueryParams } from "./common";

interface BranchesContextType {
  branches: any;
  branch: any;
  loading: boolean;
  updateBranches: Function;
  selectBranch: Function;
  pagination: {};
}

const BranchesContext = createContext<BranchesContextType>({
  branches: [],
  branch: {},
  loading: false,
  updateBranches: () => {
  },
  selectBranch: () => {
  },
  pagination: {},
});

//TODO: убрать лимиты, а то может случиться что не все филиалы будут получены при первой загрузке
export function BranchesContextWrapper({ children }: any) {
  const { company } = useCompaniesContext()

  const [branches, setBranches] = useState<Array<any>>([]);
  const [branch, setBranch] = useState(null)
  const [loading, setLoading] = useState(true)
  const [pagination, setPagination] = useState({})

  const updateBranches = (params = {}) => {
    if (! company) {
      return;
    }

    setLoading(true)
    apiClient.branches.getAll(company.id, params ? preparePaginationQueryParams(params) : '')
      .then(({ data, meta, links }: any) => {
        setBranches(data)
        if (meta) {
          setPagination(meta)
        }

      })
      .catch(() => {})
      .finally(() => setLoading(false));
  }

  const selectBranch = (id: any) => {
    if (! company?.id) {
      return;
    }

    if (localStorage.getItem(`companies.${ company.id }.branch`) == id) {
      return;
    }

    localStorage.setItem(`companies.${ company.id }.branch`, id);
    refreshBranchData()
  }

  useEffect(() => {
    if (! company) {
      return;
    }

    updateBranches()
  }, [company?.id]);

  useEffect(() => {
    if (! branches || ! company) {
      return;
    }

    if (! branches.at(0)) {
      return;
    }

    const savedBranchId = localStorage.getItem(`companies.${company.id}.branch`)

    if (! company.id || ! savedBranchId) {
      selectBranch(branches.at(0).id);

      return;
    }

    const savedBranch = branches.find((branch: any) => branch.id == savedBranchId);

    if (! savedBranch) {
      selectBranch(branches.at(0).id);

      return;
    }

    selectBranch(savedBranchId);
  }, [branches, branch]);


  useEffect(() => {
    refreshBranchData()
  }, [branches.map(({ id }) => id).join(',')])

  const refreshBranchData = () => {
    if (loading || ! company?.id) {
      return;
    }

    let branchId = localStorage.getItem(`companies.${ company.id }.branch`)

    if (! branchId) {
      return;
    }

    const branch = branches.find((branch: any) => branch.id == branchId)

    if (! branch) {
      localStorage.removeItem(`companies.${ company.id }.branch`);
      setBranch(null);

      return;
    }

    setBranch(branch)
    apiClient.branches.get(company?.id, branch?.id).then(({ data }: any) => {
      setBranch({ ...data, title: data?.base?.title })
    }).catch(() => {})
  }

  return (
    <BranchesContext.Provider value={ { branches, branch, loading, updateBranches, selectBranch, pagination } }>
      { children }
    </BranchesContext.Provider>
  )
}

export function useBranchesContext() {
  return useContext(BranchesContext)
}
