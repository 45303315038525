import React from "react";
import {SVGWrap} from "../../../../util/SVGWrap";

interface ITagLabel {
  backgroundColor: string,
  textColor: string,
  children: string,
  classNameBorder?: string,
  marginTags?: string,
  img?: string,
}
export default function TagLabel({
  children,
  backgroundColor,
  textColor,
  classNameBorder,
  img,
  marginTags = "my-5",
  ...props
 }: ITagLabel) {
  return (
    <div className={`${marginTags}`}>
      <div className={`px-3 h-[26px] flex flex-row justify-center items-center rounded-full ${classNameBorder}`} style={{backgroundColor}}>
        {img && <SVGWrap color={textColor} src={img} className={"mr-1 w-[11px] h-full"}/>}
        <div style={{color: textColor}} className={`h-full text-ellipsis flex items-center font-medium overflow-hidden whitespace-nowrap`}>{children}</div>
      </div>
    </div>
  )
}
