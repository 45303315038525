import {auth} from "../auth";

export function checkPermissionAccessMenuItem(permissions: Array<string>, menuItem: string, companyId: number): boolean {
  const allPermission = `company.${companyId}.*`
  const allBranchPermission = new RegExp(`^company\\.${companyId}\\.branch\\.([0-9])+\\.\\*$`, 'gm')
  const viewBranchPermission = new RegExp(`^company\\.${companyId}\\.branch\\.([0-9])+\\.view$`, 'gm')
  const sectionPermission = new RegExp(`^company\\.${companyId}\\.branch\\.([0-9])+\\.${menuItem}\\.*`, 'gm')
  if (menuItem === 'branches') {
    const branchPermission = permissions.filter((permission: string) => (
      allPermission === permission || allBranchPermission.test(permission) || viewBranchPermission.test(permission)
    ))
    return branchPermission.length > 0
  }
    const accessPermission = permissions.filter((permission: string) => (
      permission === allPermission ||
      allBranchPermission.test(permission) ||
      sectionPermission.test(permission)
    ))

  return accessPermission.length > 0
}

export function checkCompanyCreate(): boolean {
  const permissions = auth.current?.permissions;
  if (permissions.length === 0) {
    return false;
  }
  let accessPermission = permissions.filter((permission: string) => permission === 'company.create')

  return accessPermission.length > 0;
}

export function checkCompanyAction(action = '\\*', companyId = '([0-9])+') {
  const permissions = auth.current?.permissions;
  if (permissions.length === 0) {
    return false;
  }
  const allPermission = new RegExp(`^company\\.${companyId}\\.\\*$`, 'gm')
  const companyActionPermission = new RegExp(`^company\\.${companyId}\\.${action}$`, 'gm')

  let accessPermission = permissions.filter((permission: string) => (
      allPermission.test(permission) ||
      companyActionPermission.test(permission)
    )
  )

  return accessPermission.length > 0;
}

export function checkBranchAction(action = '\\*', companyId: any = '([0-9])+', branchId = '([0-9])+') {
  const permissions = auth.current?.permissions;

  if (!permissions?.length) {
    return false;
  }

  const allPermission = new RegExp(`^company\\.${companyId}\\.\\*$`, 'gm')
  const allPermissionForBranches = new RegExp(`^company\\.${companyId}\\.branch\\.${branchId}\\.\\*$`, 'gm')
  const branchActionPermission = new RegExp(`^company\\.${companyId}\\.branch\\.${branchId}\\.${action}$`, 'gm')

  let accessPermission = permissions.filter((permission: string) => (
      allPermission.test(permission) ||
      allPermissionForBranches.test(permission) ||
      branchActionPermission.test(permission)
    )
  )

  return accessPermission.length > 0;
}

export function checkSectionAction(section = '', action = '\\*', companyId: any = '([0-9])+', branchId: any = '([0-9])+') {
  const permissions = auth.current?.permissions;

  if (permissions.length === 0) {
    return false;
  }

  const sectionAllPermission = new RegExp(`^company\\.${companyId}\\.branch\\.${branchId}\\.${section}\\.\\*`, 'gm')
  const sectionActionPermission = new RegExp(`^company\\.${companyId}\\.branch\\.${branchId}\\.${section}\\.${action}`, 'gm')

  let accessPermission = permissions.filter((permission: string) => (
      sectionAllPermission.test(permission) ||
      sectionActionPermission.test(permission)
    )
  )
  return accessPermission.length > 0;
}
