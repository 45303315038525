import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { auth, userSubject } from '../../libs/auth'

export default function AdminAuth() {
  const { token } = useParams<{token?: string}>()
  const navigate = useNavigate();

  useEffect(() => {
    let user = auth.current

    localStorage.setItem('user', JSON.stringify({permissions: [], ...user, token}));
    userSubject.next({permissions: [], ...user, token});

    auth.update().then(() => navigate('/')).catch()
  }, [])

  return (
    <div>Авторизация...</div>
  )
}
