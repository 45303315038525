const diff = <T extends {}>(o1: T, o2: T) => {
  const changed: T = {} as T;

  for (let key in o1) {
    if (o1.hasOwnProperty(key)) {
      if (typeof o1[key] === 'object' && o1[key] !== null && typeof o2[key] === 'object' && o2[key] !== null) {
        const nestedDiff = diff(o1[key], o2[key]);

        if (Object.keys(nestedDiff).length > 0) {
          changed[key] = nestedDiff;
        }
      } else if (o1[key] !== o2[key]) {
        changed[key] = o2[key];
      }
    }
  }

  for (let key in o2) {
    if (o2.hasOwnProperty(key) && !o1.hasOwnProperty(key)) {
      changed[key] = o2[key];
    }
  }

  return changed;
}

export {diff}