import {create, InstanceProps} from "react-modal-promise";
import BaseButton from "../Button/BaseButton";
import React, {useEffect, useId, useRef, useState} from "react";
// @ts-ignore
import closeIcon from "../../../images/greenClose.svg"
import Cropper from "react-cropper";
//import {showErrorToast} from "../../../libs/helpers/toasts";

interface ModalProps extends InstanceProps<any> {
  isOpen: boolean
  onResolve: any
  onReject: any
  title: string
  html?: JSX.Element
  url?: any,
  fileName?: any,
  forceAspectRatio?: any
  showCancelButton?: boolean
  showCloseButton?: boolean
  allowOutsideClick?: boolean
  confirmButtonText?: string
  cancelButtonText?: string
  preConfirm: Function
  cropperRef: any
  crop: Function,
  validateSize: Function
}

const CropperModal = ({
  isOpen,
  onResolve,
  onReject,
  title,
  fileName,
  html,
  showCancelButton = true,
  showCloseButton = false,
  allowOutsideClick = false,
  confirmButtonText = 'Выбрать',
  cancelButtonText = 'Отмена',
  preConfirm = () => {},
  url,
  cropperRef,
  forceAspectRatio,
  crop = () => {},
  validateSize
}: ModalProps) => {
  const id = useId();
  const wrapper = useRef<any>(null);
  const [validation, setValidation] = useState<any>(null)
  const [imgLoadError, setImgLoadError] = useState<any>(null)
  /* Закрываем при клике вне поля*/
  useEffect(() => {
    function handleClick(event: any) {
      if (wrapper.current && !wrapper.current.contains(event.target) && allowOutsideClick) {
        onResolve({isConfirmed: false})
      }
    }
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [isOpen, onReject, wrapper]);

  const [src, setSrc] = useState(null)

  useEffect(() => {
    if (url) {
      setTimeout(() => {
        setSrc(url)
      }, 300)
    }
  }, [url])

  /*useEffect(() => {
    if (imgLoadError) {
      onResolve({isConfirmed: false})
      showErrorToast({
        autoClose: 10000,
        content: imgLoadError.replace("%s", "изображения")
      })
    }
  }, [imgLoadError])*/

  return (
    <div id={id} className={`fixed grid w-full h-full duration-200 modal-wrapper ${!isOpen ? 'opacity-0' : 'opacity-100'}`}>
      <div ref={wrapper} className={'grid h-[min(99vh,1000px)] w-[min(60vw,900px)] relative bg-white rounded-[10px] overflow-y-auto modal'}>
        <div className={'flex justify-between px-4 py-[14px] items-center'}>
          <span className={'text-xl'}>{title}</span>
          {
            showCloseButton &&
              <img src={closeIcon} className={'w-[20px] cursor-pointer'} onClick={() => {
                onResolve({isConfirmed: false})
              }} alt=""/>
          }
        </div>
        <div className={'h-[calc(min(1000px,99vh)-150px)]'}>
        {
          !imgLoadError && (
          src ?
            <div className={`p-4 box-border flex justify-center overflow-auto duration-300 w-full`}>
                <Cropper
                    style={{overflow : "hidden"}}
                    ref={cropperRef}
                    viewMode={1}
                    className={'h-[calc(min(1000px,99vh)-155px-5rem)] w-[calc(min(60vw,900px)-50px)]'}
                    aspectRatio={forceAspectRatio}
                    onLoad={(e) => {
                      const img = e.target as HTMLImageElement
                      if (img) {
                        const checkImage = () => {
                          const err: string | null = validateSize({width: img.naturalWidth, height: img.naturalHeight});
                          if (err) {
                            setImgLoadError(fileName ? fileName + ': ' + err : err);
                          } else {
                            setImgLoadError(null)
                          }
                        }

                        const isLoaded = img.complete && img.naturalHeight !== 0;
                        if (isLoaded) {
                          checkImage()
                        } else {
                          const _onload = img.onload;
                          img.onload = (...args: any[]) => {
                            checkImage();
                            if (_onload) {
                              _onload.apply(img, args);
                            }
                          }
                        }
                      }
                    }}
                    crop={e => {
                      const cropInfo = crop(e)
                      if (cropInfo && typeof cropInfo === 'string') {
                        setValidation(cropInfo)
                      } else {
                        setValidation(null)
                      }
                    }}
                    movable={true}
                    src={src}
                />
            </div> :
            <div className={`p-4 w-full h-[100px] flex flex-row items-center justify-center`}>
              <svg
                className="animate-spin h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#55B37A" strokeWidth="4"></circle>
                <path className="opacity-75" fill="#55B37A"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
          )
        }

        {
          imgLoadError &&
            <div className={'p-4 gap-y-4 h-full flex flex-row items-center justify-center text-center text-error-font'}>
              {imgLoadError.replace("%s", "изображения")}
            </div>
        }

        {
          !imgLoadError && validation &&
            <div className={'p-4 gap-y-4 h-[5rem] flex flex-row items-center justify-center text-center text-error-font'}>
              {validation.replace("%s", "выбранной области")}
            </div>
        }
        </div>

        <div className="flex flex-row justify-between p-4">
          <div className={`flex flex-row gap-x-4 items-center justify-between w-full`}>
            {
              showCancelButton && <BaseButton className={'bg-white border-2 text-error-font border-error-font py-[7px]'} onClick={() => {
                onResolve({isConfirmed: false})
              }}>{cancelButtonText}</BaseButton>
            }
            {
            !imgLoadError &&
            <BaseButton disabled={validation} className={'bg-interactive-elem border-2 border-interactive-elem text-white py-[7px]'} onClick={() => {
              const value = preConfirm()
              if (value) {
                onResolve({isConfirmed: true, isDenied: false, value: value})
              } else {
                //console.log(`preConfirm error = ${value}`)
              }
            }}>{confirmButtonText}</BaseButton>
            }
          </div>
        </div>
      </div>

    </div>
  )
}


export const openCropperModal = create(CropperModal)
