import React from 'react'
import TextInputMultipleList from '../../../components/common/form/input/TextInputMultipleList'
import Checkbox from '../../../components/common/form/checkbox/Checkbox'
import CheckboxList from '../../../components/common/form/checkbox/CheckboxList';


export default function Order({data, updateField, errors}: any) {
  let fieldsLabels = {
    clientEmail: 'E-mail',
    deliveryType: 'Доставка / Самовывоз',
    delivery: 'Тип доставки',
    pickupPointId: 'Точка самовывоза',
    deliveryDate: 'Дата и время доставки',
    deliveryTime: 'Время доставки',
    city: 'Город',
    street: 'Улица',
    house: 'Дом',
    building: 'Корпус',
    entrance: 'Подьезд',
    floor: 'Этаж',
    flat: 'Квартира',
    changeFromBill: 'Сдачи с купюры',
    personsCount: 'Кол-во персон (приборы)',
    promoCode: 'Промокод',
    comment: 'Комментарий к заказу',
    confirmOrderWithoutCall: 'Подтвердить заказ без звонка',
    contactlessDelivery: 'Бесконтактная доставка',
    birthDate: 'День рождения'
  }

  return (
    <div className={'flex flex-col px-3 gap-y-6'}>
        {/*<Checkbox
          label='Только самовывоз'
          name={'order.pickupOnly'}
          value={data.order.pickupOnly}
          onChange={(value: any) => updateField('order.pickupOnly', value)}
        />*/}

        <Checkbox
          label='Скрывать стоимость доставки'
          name={'order.hideDeliveryPrice'}
          value={data.order.hideDeliveryPrice}
          onChange={(value: any) => updateField('order.hideDeliveryPrice', value)}
        />

        <TextInputMultipleList
          name={'order.preorderAllowedTimes'}
          values={data.order.preorderAllowedTimes}
          fields={['time', 'title']}
          types={['time', 'text']}
          fieldTypes={['input', 'input']}
          placeholders = {['Введите время 00:00', 'Поле для ввода текста (название)']}
          onChange={(value: any) => updateField('order.preorderAllowedTimes', value)}
          label='Доступное время для предзаказа'
          textTooltip='Доступное время для предзаказа'
          errors={errors}
        />

        <div>
          <div className='font-medium mb-2'>Поля формы оформления<span className='text-error-main'>{'*'}</span></div>
          <div className='flex flex-wrap gap-y-2'>
            {
              Object.keys(data.order.fields).map(field => (
                <div className={'flex flex-row gap-x-4 w-1/2 items-center'}>
                <Checkbox
                  className='w-1/2'
                  key={field}
                  label={fieldsLabels[field]}
                  errors={errors}
                  name={`order.fields.${field}.isUsed`}
                  value={data.order.fields[field].isUsed}
                  onChange={(value: any) => {
                    updateField(`order.fields.${field}`, {isUsed: value, isRequired: data.order?.fields[field].isRequired})
                  }}
                />
                  {
                    data.order.fields[field].isUsed && !(['deliveryDate', 'promoCode', 'deliveryType'].includes(field)) &&
                      <Checkbox
                          className='w-1/2'
                          key={field + '_required'}
                          label={'Обязательно'}
                          name={`order.fields.${field}.isRequired`}
                          errors={errors}
                          value={data.order?.fields[field].isRequired || false}
                          onChange={(value: any) => {
                            updateField(`order.fields.${field}`, {isUsed: data.order?.fields[field].isUsed, isRequired: value})
                          }}
                      />
                  }

                </div>
              ))
            }
          </div>
        </div>

        <CheckboxList
          required={true}
          label='Способы оплаты'
          name='order.paymentTypes'
          errors={errors}
          options={[
            {
              label: 'Наличные',
              name: 'cash',
            },
            {
              label: 'Online',
              name: 'online',
            },
            {
              label: 'Картой при получении',
              name: 'on-delivery',
            },
            {
              label: 'Мобильный банк (Перевод)',
              name: 'transfer',
            },
          ]}
          values={data.order.paymentTypes}
          onChange={(value: any) => updateField('order.paymentTypes', value)}
        />

        {/*<TextInputMultipleList
          name={'order.deliveryTypes'}
          values={data.order.deliveryTypes || []}
          fields={['code', 'title']}
          fieldTypes={['input', 'input']}
          placeholders = {['Поле для ввода целого числа (код)', 'Поле для ввода текста (название)']}
          onChange={value => updateField('order.deliveryTypes', value)}
          textTooltip='Тип доставки'
          label='Тип доставки'
          errors={errors}
        />*/}

        <TextInputMultipleList
          name={'order.pickupPoints'}
          values={data.order.pickupPoints}
          fields={['code', 'address']}
          fieldTypes={['input', 'house']}
          placeholders = {['Поле для ввода целого числа (код)', 'Поле для ввода текста (название)']}
          onChange={(value: any) => updateField('order.pickupPoints', value)}
          textTooltip='Точки самовывоза'
          label='Точки самовывоза'
          errors={errors}
          searchWithCity={false}
          citiesGuid={data.extended.orderCities.map(({city}: any) => {return city?.guid})}
        />

    </div>
  )
}
