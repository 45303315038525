import {apiClient} from "../../libs/api/apiClient";

export async function fetchConditions(companyId: any, branchId: any) {
  const { terms, definitions }: any = await apiClient.nps.getConditions(companyId, branchId)

  return { terms, definitions };
}

export async function fetchAnswerTypes() {
  const { data }: any = await apiClient.dictionaries.npsAnswerTypes()

  return data.map((type: any) => ({
    label: type.title,
    value: type.value,
    code: type.code
  }))
}

export async function fetchSources() {
  const { data }: any = await apiClient.dictionaries.npsSources()

  return data.map((source: any) => ({
    label: source.title,
    value: source.value,
    code: source.code
  }))
}
