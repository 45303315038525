import React from "react";

export enum Color {
    SUCCESS = '#55B37A',
    WARNING = '#FF9966'
}

type Props = {
    className?: string,
    color: Color
}

export default function ApplyIcon({className = '', color = Color.SUCCESS}: Props) {
    return (
        <svg className={`${className}`} width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 7L5.5 11L15.5 1" stroke={color} strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    )
}