import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import FormWrapper from "../../../components/common/form/FormWrapper";
import tabs from "../tabs";
import CreateFormFields from "../../../components/common/form/CreateFormFields";
import BaseOrders from "./BaseOrders";
import BaseOrdersComposition from "./BaseOrdersComposition";
import BaseReviews from "./BaseReviews";
import useForm from "../../../hooks/useForm";
import initForm from "../initForm";
import {useNavigate} from "react-router-dom";
import {fetchEstimations, fetchPaymentTypes} from "../../../providers/dictionaries/orders";
import {fetchStatuses, fetchTypes} from "../../../providers/dictionaries/promoCodes";
import {useCompaniesContext} from "../../../contexts/Companies";
import {useBranchesContext} from "../../../contexts/Branches";
import {apiClient} from "../../../libs/api/apiClient";
import {PromoContextWrapper} from "../../../contexts/Promo";
import {ProductsContextWrapper} from "../../../contexts/Products";
import {showErrorToast, showSuccessToast} from "../../../libs/helpers/toasts";

export default function CreateOrders() {
  const {company}: any = useCompaniesContext()
  const {branch}: any = useBranchesContext()
  const {data, updateField, updateFieldNoRender, errors, updateErrors, submit}: any = useForm(JSON.parse(JSON.stringify(initForm)))
  const [orderStatuses, setOrderStatuses] = useState([])
  const [reviewEstimations, setReviewEstimations] = useState([])
  const [paymentTypes, setPaymentTypes] = useState([])
  const [promoTypes, setPromoTypes] = useState([])
  const [pickupPoints, setPickupPoints] = useState([])
  const [phoneInfo, setPhoneInfo] = useState(null)
  const [loading, setLoading] = useState(false)

  const [tab, setTab] = useState('baseInformation')

  const navigate = useNavigate();
  const handleSubmit = () => {
    submit(async (form: any) => {
      setLoading(true)
      apiClient.orders.create(company?.id, branch?.id, {body: form}).then(({data, errors}: any) => {
        if (data) {
          showSuccessToast({
            content: "Заказ успешно создана",
          })
          navigate('/orders')
        } else {
          updateErrors(errors || {});
          checkErrors(errors, true)

          showErrorToast({
            content: 'Что-то пошло не так'
          })

        }
      }).catch(() => {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      }).finally(() => setLoading(false))
    })
  }

  useEffect(() => {

    fetchEstimations().then(data => {
      setReviewEstimations(data)
    })
    fetchPaymentTypes().then(data => {
      setPaymentTypes(data)
    })
    fetchStatuses().then(data => {
      setOrderStatuses(data)
    })
    fetchTypes().then(data => {
      setPromoTypes(data)
    })
  }, [])

  useEffect(() => {
    if (company && branch) {
      apiClient.branches.get(company.id, branch.id).then(({data, errors}: any) => {
        if (data) {
          setPickupPoints(data?.order?.pickupPoints || [])
          setPhoneInfo(data?.extended?.phoneCode || null)
        } else {
          console.log(errors)
        }
      })
    }
  }, [branch])

  // Выглядит как срань, ею и является
  // Если придумаешь вариант получше отыскать на каком табе ошибка, то флаг в руки
  const [tabsHasError, setTabsHasError] = useState([])

  useEffect(() => {
    const interval = setInterval(() => {
      if ( Object.keys(errors).length > 0) {
        checkErrors(errors)
      } else if (tabsHasError.length > 0) {
        setTabsHasError([])
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [JSON.stringify(errors)]);

  const checkErrors = (err=errors, tab = false) => {
    let tabsError: any = []
    let errorsName: any = Object.keys(err)

    tabs.map(elem => {
      if (elem?.fields) {
        let hasError = false
        for (let i = 0; i < elem.fields.length; i++) {
          let fieldName = elem.fields[i];
          for (let j = 0; j < errorsName.length; j++) {
            let error = errorsName[j]

            if (error.includes(fieldName) && error.startsWith(fieldName)) {
              hasError = true
              break
            }
          }

          if (hasError) {
            break
          }
        }

        if (hasError) {
          if (tab && !tabsError.length) {
            setTab(elem.tag)
          }
          tabsError.push(elem.tag)
        }
      }
    })

    setTabsHasError(tabsError)
  }
  return (
    <div className='px-8 pb-8 pt-2 text-gray-50'>
      <div className='mb-8'>
        <div className='text-[44px] leading-[60px] mb-2'>Заказы</div>
        <Breadcrumbs
          links={[
            {to: `/orders`, label: 'Список заказов'},
            {to: '', label: 'Создание заказа'},
          ]}
          back={`/orders`}
        />
      </div>
      <div className={'rounded-xl bg-white py-5'}>
        <FormWrapper
          tabs={tabs}
          tab={tab}
          setTab={setTab}
          tabsHasError={tabsHasError}
        >
          <CreateFormFields
            show={tab === 'baseInformation'}
            nextOption='orderComposition'
            setTab={setTab}
          >
            <PromoContextWrapper>
              <BaseOrders
                data={data}
                updateField={updateField}
                updateFieldNoRender={updateFieldNoRender}
                errors={errors}
                paymentTypes={paymentTypes}
                promoStatuses={orderStatuses}
                promoTypes={promoTypes}
                phoneInfo={phoneInfo}
                pickupPoints={pickupPoints.map(({id, address}: any) => {return {label: address?.title, value: id}})}
                statuses={company && company?.statuses ? company?.statuses.map(({code, title}: any) => {return {label: title, value: code}}) : []}
              />
            </PromoContextWrapper>
          </CreateFormFields>
          <CreateFormFields
            show={tab === 'orderComposition'}
            nextOption='review'
            setTab={setTab}
          >
            <ProductsContextWrapper>
              <BaseOrdersComposition
                data={data}
                updateField={updateField}
                updateFieldNoRender={updateFieldNoRender}
                errors={errors}
              />
            </ProductsContextWrapper>
          </CreateFormFields>
          <CreateFormFields
            show={tab === 'review'}
            setTab={setTab}
            onSubmit={handleSubmit}
            updateFieldNoRender={updateFieldNoRender}
            submitLabel='Создать заказ'
            submitLoading={loading}
          >
            <BaseReviews data={data} updateField={updateField} errors={errors} reviewEstimations={reviewEstimations}/>
          </CreateFormFields>

        </FormWrapper>
      </div>
    </div>
  )
}
