import React from 'react'
import BaseButton from '../Button/BaseButton'
import { auth } from '../../../libs/auth'
import Branches from './Branches'
import useUser from "../../../hooks/useUser";
import {checkBranchAction} from "../../../libs/helpers/permissions";
import { useNavigate} from "react-router-dom";
import { useFormContext } from '../../../contexts/Form';

export default function Header() {
  const {user} = useUser()
  const navigate = useNavigate()
  const {checkIsFormDirty, openDialog} = useFormContext()

  const handleLogout = () => {
    auth.logout().then(data => navigate('/'))
  }

  return (
    <div className={`flex ${(!checkBranchAction('view')) ? 'justify-end' : 'justify-between'} items-center px-8 h-[64px] bg-gray-50 text-white`}>
      {
        (checkBranchAction('view')) && <Branches/>
      }
      <div className='flex items-center h-full cursor-pointer' onClick={() => {
        if (checkIsFormDirty()) {
          openDialog('/personal')
        } else {
          navigate('/personal')
        }
      }}>
        <div className='pr-[6px]'>ID {auth.current.id}</div>
      </div>
    </div>
  )
}
