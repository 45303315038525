import React, { useState, useEffect } from "react";
import BaseButton from "../../components/common/Button/BaseButton";
import { Link } from "react-router-dom";
import { useBranchesContext } from "../../contexts/Branches";
import DataTable from "../../components/common/Table/DataTable";
import {createColumnHelper} from '@tanstack/react-table'
import {apiClient} from "../../libs/api/apiClient";
import Actions from "../../components/common/Table/Actions";
import {preparePaginationQueryParams} from "../../contexts/common";
import {useCompaniesContext} from "../../contexts/Companies";

type BranchData = {
  id: number,
  title: string,
}

export default function Branches() {
  const { company }: any = useCompaniesContext()
  const { branches, branch, selectBranch }: any = useBranchesContext()
  const [dataBranches, setDataBranches] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({})
  const [search, setSearch] = useState('')
  const columnHelper = createColumnHelper<BranchData>()
  const [filters, setFilters] = useState({
    limit: 10,
    page: 1,
    order: null,
    search: null
  })

  const getBranches = (params = {limit: 10}) => {
    if (company) {
      setLoading(true)
      if (params) {
        updatePagination(params)
      }
      apiClient.branches.getAll(company?.id, params ? preparePaginationQueryParams(filters) : '')
        .then(({data, meta, links}: any) => {
          setDataBranches(data)
          if (meta) {
            setPagination(meta)
          }
          setLoading(false)
        })
    }
  }

  const updatePagination = ({limit, order, page}: any) => {
    let tempData = filters
    if (limit) {
      tempData['limit'] = limit
    }
    if (page) {
      tempData['page'] = page
    }
    if (order) {
      tempData['order'] = order
    }

    setFilters(filters)
  }

  const columns = [
    columnHelper.accessor(row => row.id, {
      id: 'ID',
      header: () => 'ID',
      cell: info => <div className={'w-10 flex items-center justify-center text-center'}>{ info.getValue() }</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-10 flex items-center justify-center text-center'
      }
    }),
    columnHelper.accessor(row => row.title, {
      id: 'title',
      header: () => 'Филиалы',
      cell: info => <div className={'grow flex items-center'}>{ info.getValue() }</div>,
      enableSorting: true,
      meta: {
        widthClass: 'grow',
        sortField: 'title'
      }
    }),
    columnHelper.display({
      id: 'actions',
      header: () => 'Действия',
      cell: props => (
        <div className='w-[270px] flex items-center gap-4 mr-4'>
          <Link to={`/branches/${props.row?.original?.id}/edit`}>
            <BaseButton className={'text-interactive-text border-btn-secondary rounded-md border-[1px] py-[7px]'}>Редактировать</BaseButton>
          </Link>
          <BaseButton onClick={() => selectBranch(props.row?.original?.id)} className={`text-interactive-text border-btn-secondary rounded-md border-[1px] py-[7px] ${branch?.id == props.row?.original?.id && 'bg-interactive-text text-white'}`}>{branch?.id == props.row?.original?.id ? 'Выбрано' : 'Выбрать'}</BaseButton>
        </div>
      ),
      meta: {
        widthClass: 'w-[270px]'
      }
    }),
  ]

  useEffect(() => {
    getBranches();
  }, [company?.id])

  return (
    <div className="px-8 pb-8 pt-2">
      <div className="flex justify-between items-end">
        <div>
          <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Филиалы</div>
          <div className="text-gray-40 text-base">Список филиалов</div>
        </div>

        {
          branches?.length === 0 &&
          <Link to={'create'}>
            <BaseButton className={'bg-interactive-elem text-white'}>Создать филиал</BaseButton>
          </Link>
        }
      </div>

      {
        branches?.length > 0 ?
          <div>
            <Actions
              updateValue={(value: any) => {
                setSearch(value || '')
              }}
              fetchData={(value: any) => {
                let tempData = filters
                tempData['search'] = value || null
                setFilters(filters)
                getBranches(filters)
              }}
              searchValue={search}
              classNameChildren={'w-1/2 flex items-center justify-end'}
            >
              <Link to={'create'}>
                <BaseButton className={'bg-interactive-elem text-white'}>Создать филиал</BaseButton>
              </Link>
            </Actions>
            <DataTable
              data={dataBranches}
              columns={columns}
              usePagination={true}
              defaultLimit={10}
              defaultPage={1}
              pagination={pagination}
              updateData={(params: any) => getBranches(params)}
              loading={loading}
              selectedRows={[]}
            />
          </div>
         :
        <div className="text-gray-40 text-3xl text-center mt-[35vh]">
          {loading ? 'Загрузка...' : 'Создайте свой первый филиал'}
        </div>
      }
    </div>
  )
}
