import React from 'react'
import TextInputMultipleList from '../../../components/common/form/input/TextInputMultipleList'
import TextInput from "../../../components/common/form/input/TextInput";

export default function Contacts({data, updateField, errors, updateFieldNoRender}: any) {
  return (
    <div className={'flex flex-col px-3 gap-y-6'}>
          <TextInputMultipleList
            textTooltip='Телефоны для заказа'
            required={true}
            name={'contacts.items'}
            values={data.contacts.items}
            fields={['phone', 'description']}
            fieldTypes={['input', 'input']}
            placeholders = {['+ 7 (900) 800-20-10', 'Введите описание к номеру']}
            onChange={(value: any) => updateField('contacts.items', value)}
            label='Телефоны для заказа'
            errors={errors}
          />

          <TextInput
            label='Ссылка на телеграм'
            textTooltip='Ссылка на телеграм'
            placeholder='Введите ссылку'
            name={'contacts.telegramLink'}
            errors={errors}
            onChange={(value: any) => updateFieldNoRender('contacts.telegramLink', value)}
            value={data.contacts.telegramLink}
            required={false}
          />
    </div>
  )
}
