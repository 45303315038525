import {useBranchesContext} from "../../../contexts/Branches";
import TextInput from "../../../components/common/form/input/TextInput";
import SimpleMultipleValueSelect from "../../../components/common/form/select/multiple/SimpleMultipleValueSelect";
import React, {useEffect, useState} from "react";
import {useCompaniesContext} from "../../../contexts/Companies";
import {apiClient} from "../../../libs/api/apiClient";
import {showErrorToast} from "../../../libs/helpers/toasts";
import { preparePaginationQueryParams } from "../../../contexts/common";


export default function Base(props: any) {
  const {data, errors, updateField, isEdit, funnelGroupId, updateFieldNoRender} = props
  const [funnels, setFunnels] = useState<any>([])
  const [funnelsRequest, setFunnelsRequest] = useState(false);
  const {company} = useCompaniesContext()
  const {branch} = useBranchesContext()

  useEffect(() => {
    if (company && branch && !funnelsRequest) {
      setFunnelsRequest(true)
      apiClient.funnels.getAll(company.id, branch.id, preparePaginationQueryParams({ filter: isEdit ? { 'without-funnel-group': funnelGroupId } : { 'without-any-funnel-group': true } })).then(({data, message, errors}: any) => {
        if (data) {
          const funnelsData = data?.map(({id, title}: any) => ({label: title, value: id}))
          setFunnels(funnelsData)
        } else if (message || errors) {
          showErrorToast({
            content: message || 'Произошла ошибка при загрузке воронок'
          })
        }
      }).catch((reason) => {
        showErrorToast({
          content: reason || 'Произошла ошибка при загрузке воронок'
        })
        setFunnelsRequest(false)
      })
    }
  }, [company, branch])

  return (
    <div className={` flex flex-col px-3 gap-y-6`}>
      <TextInput
        label='Название'
        textTooltip='Название'
        placeholder='Введите название'
        name={'title'}
        value={data.title}
        onChange={(value: any) => updateFieldNoRender('title', value)}
        errors={errors}
        required={true}
      />

      <TextInput
        type='number'
        min='0'
        label='Сортировка группы'
        textTooltip='Сортировка группы'
        placeholder='500'
        name={'position'}
        value={data.position}
        onChange={(value: any) => updateFieldNoRender('position', value)}
        errors={errors}
      />

      <SimpleMultipleValueSelect
        label={'Воронки'}
        textTooltip={'Воронки'}
        options={funnels}
        value={data.funnels || []}
        onChange={(value: any) => updateField('funnels', value)}
        name={'funnels'}
        placeholder={'Выберите воронки'}
        errors={errors}
        wordsArray ={['воронка', 'воронки', 'воронок']}
      />

      {
        /*<SimpleMultipleValueSelect
          label={'Филиалы'}
          textTooltip={'Филиалы'}
          options={branches.map((branch: any) => ({label: branch.title, value: branch.id}))}
          value={data.branches || []}
          onChange={(value: any) => updateField('branches', value)}
          name={'branches'}
          placeholder={'Выберите филиалы'}
          errors={errors}
        />*/
      }
    </div>
  )
}
