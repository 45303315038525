import React from "react";
import {useCompaniesContext} from "../../../../contexts/Companies";
import {useBranchesContext} from "../../../../contexts/Branches";
import {customModal} from "../../../../components/common/Modal/CustomModal";
import {apiClient} from "../../../../libs/api/apiClient";
import {showErrorToast, showSuccessToast} from "../../../../libs/helpers/toasts";
import {IExternalResponseProps} from "../../externalResponseContainer";

const statuses = {
    wait: "text-warnings",
    pending: "text-warnings",
    success: "text-interactive-text",
    canceled: "text-warnings",
    error: "text-error-font",
};

export default function ExternalResponse({order, updateOrders, limits, filters}: IExternalResponseProps) {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    const {externalResponse, externalId} = order;

    if (!externalResponse) {
        return <></>
    }

    function hasWarnings(warnings: object | Array<string>) {
        if (warnings instanceof Array && warnings.length) {
            return true;
        }

        return !!Object.entries(warnings).length;
    }


    const {status, statusMessage, warnings, integration, raw, log} = externalResponse;
    const isHasWarnings = hasWarnings(warnings);
    const shouldDisplayDetails = isHasWarnings || ['canceled', 'error'].includes(status);

    const showDetails = (data: any) => {
        customModal({
            title: data?.id ? `Статус заказа #${data?.id}` : 'Статус заказа',
            html: renderDetails(data),
            showCancelButton: true,
            showCloseButton: true,
            showSubmitButton: ['canceled', 'error'].includes(status),
            allowOutsideClick: false,
            cancelButtonText: 'Закрыть',
            confirmButtonText: 'Отправить повторно',
            confirmButtonClassName: 'bg-error-font border-2 border-error-font text-white py-[7px]',
            classNameWrapper: 'min-w-[400px] max-w-[800px] overflow-y-auto'
        }).then((response: any) => {
            if (response?.isConfirmed) {
                apiClient.orders.resendToExternal(company?.id, branch?.id, data?.id).then(({data, message}: any) => {
                    if (message) {
                        showErrorToast({
                            content: message || 'При отправке запроса произошла ошибка'
                        })
                    } else {
                        showSuccessToast({
                            content: 'Запрос успешно отправлен'
                        })
                        updateOrders(limits, filters)
                    }
                }).catch((reason: any) => {
                    showErrorToast({
                        content: reason || 'При отправке запроса произошла ошибка'
                    })
                })
            }
        }).catch(() => {
        })
    }

    const renderDetails = (order: any) => {
        return (
            <div className={`flex flex-col gap-y-4`}>
                <div><span className={`${statuses[status]}`}>{statusMessage}</span></div>
                {
                    warnings && Object.keys(warnings).length > 0 &&
                    <div className={`flex flex-col gap-y-2`}>
                        Предупреждения:
                        <div className={`flex flex-col gap-x-2 text-error-main text-xs`}>
                            {Object.entries(warnings)?.map(([attribute, value]: any) => (
                                <div>{attribute} = {value}</div>
                            ))}
                        </div>
                    </div>
                }
                <pre
                    className={`break-words w-full h-[300px] overflow-auto max-h-[300px] text-xs bg-gray-10 border-gray-40 border-[1px] rounded-lg px-2`}>{JSON.stringify(raw, null, 2)}</pre>
                <div>
                    {log.map((event: string) => (
                        <p>{event}</p>
                    ))}
                </div>
            </div>
        )
    }

    let message = "";

    if (externalId) {
        message += `, ${integration} ID: ${externalId}`
    }

    message = statusMessage + message;

    if (shouldDisplayDetails) {
        return (
            <>
                <div className={`${statuses[status]} cursor-pointer text-xs underline`}
                     onClick={() => showDetails(order)}>{message}</div>
            </>
        );
    }

    return (
        <>
            <div className={`${statuses[status]} text-xs`}>{message}</div>
        </>
    )
}