import React, {useEffect, useRef, useState} from "react"
// @ts-ignore
import closeIcon from '../../../../../../images/greenClose.svg'
import BaseButton from "../../../../Button/BaseButton";
import DataTable from '../../../../Table/DataTable'
import Actions from "../../../../Table/Actions";

export default function SimpleDataTableModal(
  {
    children,
    title = '',
    show,
    setShow,
    data,
    updateData,
    loading,
    columns,
    onSubmit,
    buttonPlaceholder = 'Выберите товар',
    showSearch = true,
  }: any) {
  //const id = useId();
  //const input = useRef<any>(null);
  const wrapper = useRef<any>(null);
  const [search, setSearch] = useState('')

  /* Закрываем при клике вне поля */
  useEffect(() => {
    function handleClick(event: any) {
      if (wrapper.current && !wrapper.current.contains(event.target)) {
        setShow(false);
        setSearch('')
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }

  }, [show, setShow, wrapper]);

  return (
    <div className={`fixed grid w-full h-full modal-wrapper ${!show && 'hidden'}`}>
      <div ref={wrapper} className={'grid relative bg-white rounded-[10px] w-[60%] max-w-[90%] modal'}>
        <div className={'flex justify-between px-4 py-[14px] items-center'}>
          <span className={'text-xl'}>{title}</span>
          <img src={closeIcon} className={'w-[20px] cursor-pointer'} onClick={() => {
            setShow(false)
            setSearch('')
          }} alt=""/>
        </div>
        <div className="p-4">
          {
            showSearch &&
              <Actions
                  updateValue={(value: any) => {
                    setSearch(value || '')
                    //updateData({search: value || null})
                  }}
                  fetchData={(value: any) => {
                    updateData({search: value || null})
                  }}
                  searchValue={search}
                  classNameChildren={'w-1/2 pl-6'}
              >
                {children}
              </Actions>
          }
          <DataTable
            data={data}
            loading={loading}
            columns={columns}
            updateData={updateData}
            usePagination={false}
            useMassAction={false}
            selectedRows={[]}
          />
        </div>

        <div className="flex flex-row justify-end py-4 px-4 gap-y-4">
          <BaseButton className={'bg-interactive-elem text-white py-[7px]'} onClick={() => {
            onSubmit()
            setShow(false)
            setSearch('')
          }}>{buttonPlaceholder}</BaseButton>
        </div>
      </div>

    </div>
  )
}
