import {create, InstanceProps} from "react-modal-promise";
import BaseButton from "../Button/BaseButton";
import React, {useEffect, useId, useRef} from "react";
// @ts-ignore
import closeIcon from "../../../images/greenClose.svg"

interface ModalProps extends InstanceProps<any> {
  isOpen: boolean
  onResolve: any
  onReject: any
  title: string
  text?: string
  showDenyButton?: boolean
  showCancelButton?: boolean
  showCloseButton?: boolean
  allowOutsideClick?: boolean
  confirmButtonText?: string
  denyButtonText?: string
  cancelButtonText?: string
  textClassName?: string
}

const DialogModal = (
  {
    isOpen,
    onResolve,
    onReject,
    title,
    text,
    showDenyButton = false,
    showCancelButton = false,
    showCloseButton = true,
    allowOutsideClick = true,
    confirmButtonText = 'Сохранить',
    denyButtonText = 'Удалить',
    cancelButtonText = 'Отмена',
    textClassName = ''
  }: ModalProps) => {
  const id = useId();
  //const input = useRef(null);
  const wrapper = useRef<any>(null);

  /* Закрываем при клике вне поля*/
  useEffect(() => {
    function handleClick(event: any) {
      if (wrapper.current && !wrapper.current.contains(event.target) && allowOutsideClick) {
        onReject({isCancelled: true})
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }

  }, [isOpen, onReject, wrapper]);

  return (
    <div id={id} className={`fixed grid w-full h-full modal-wrapper ${!isOpen && 'hidden'}`}>
      <div ref={wrapper} className={'grid relative bg-white rounded-[10px] max-w-[90%] max-h-full modal'}>
        <div className={'flex justify-between px-4 py-[14px] items-center'}>
          <span className={'text-xl'}>{title}</span>
          {
            showCloseButton &&
              <img src={closeIcon} className={'w-[20px] cursor-pointer'} onClick={() => {
                onReject({isCancelled: true})
              }} alt=""/>
          }
        </div>
        <div className={`p-4 ${textClassName}`}>
          {
            text && text?.length && <div className={'text-center px-4'}>{text}</div>
          }
        </div>

        <div className="flex flex-row justify-between py-4 px-4 gap-y-4">
          {
            showDenyButton && <BaseButton className={'bg-error-font border-2 text-white border-error-font py-[7px]'} onClick={() => {
              onResolve({isConfirmed: false, isDenied: true})
            }}>{denyButtonText}</BaseButton>
          }
          <div className={`flex flex-row gap-x-4 items-center justify-between ${!showDenyButton && 'w-full'}`}>
            {
              showCancelButton && <BaseButton className={'bg-white border-2 text-error-font border-error-font py-[7px]'} onClick={() => {
                onReject({isCancelled: true})
              }}>{cancelButtonText}</BaseButton>
            }
            <BaseButton className={'bg-interactive-elem border-2 border-interactive-elem text-white py-[7px]'} onClick={() => {
              onResolve({isConfirmed: true, isDenied: false})
            }}>{confirmButtonText}</BaseButton>
          </div>
        </div>
      </div>

    </div>
  )
}


export const dialogModal = create(DialogModal)
