import React from "react"
import { Link } from 'react-router-dom';
import arrowBackImg from '../../../images/arrow-back.svg'
import { useFormContext } from "../../../contexts/Form";

export default function Breadcrumbs({ links, back, className='' }) {
  const {checkIsFormDirty, openDialog} = useFormContext()

  return (
    <>
      <div className="flex">
        <Link to={back} onClick={(e) => {
          if (checkIsFormDirty()) {
            e.preventDefault()
            openDialog(back)
          }
        }} className="mr-6">
          <img src={arrowBackImg} alt="" />
        </Link>
        {
          links.map((link, index) => (
            <div key={index} className={'text-base text-gray-40'}>
              <Link to={link.to} onClick={(e) => {
                if (checkIsFormDirty()) {
                  e.preventDefault()
                  openDialog(link.to)
                }
              }}>{link.label}</Link>
              {index + 1 < links.length && <span className="mx-1">{'/'}</span>}
            </div>
          ))
        }
      </div>
    </>
  )
}
