import React from "react";
import ConditionSelect from "./select/ConditionSelect";

interface IProps {
  data: any,
  conditions: any,
  updateField: (field: string, value: any) => void,
  errors: any
}

export default function ConditionNodeBase(
  {
    conditions,
    data,
    updateField,
    errors
  }: IProps) {

  return (
    <div className={'flex flex-col gap-y-6 text-black py-8 overflow-y-auto min-h-[70%]'}>
      <ConditionSelect
        value={data?.conditions || {}}
        errors={errors}
        conditions={conditions}
        onChange={(value: any) => updateField('conditions', value)}
      />
    </div>
  )
}
