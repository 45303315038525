import React, { useEffect } from 'react'
import TextInput from './../../../components/common/form/input/TextInput';
import SingleSimpleSelect from '../../../components/common/form/select/SingleSimpleSelect';

const smsOptions = [
  {label: 'Не использовать', value: null},
  {label: 'SMS.RU', value: 'sms-ru'},
  {label: 'REDSMS', value: 'red-sms'}
]

const phoneCallOptions = [
  {label: 'Не использовать', value: null},
  {label: 'SMS.RU', value: 'sms-ru'},
  {label: 'REDSMS', value: 'red-sms'}
]

function SmsRuSms({data, updateFieldNoRender, errors}: any) {
  return (
    <div className="grid grid-cols-2 gap-4">
      <TextInput
        label='API Secret'
        textTooltip='API Secret'
        placeholder='Текстовое поле'
        name={ 'integrations.smsService.options.apiId' }
        value={ data.integrations?.smsService?.options?.apiId }
        onChange={ value => updateFieldNoRender('integrations.smsService.options.apiId', value) }
        errors={ errors }
        initialValueReplaceOnly={true}
      />

      <TextInput
        label='Имя отправителя'
        textTooltip='Имя отправителя'
        placeholder='Текстовое поле'
        name={ 'integrations.smsService.options.senderName' }
        value={ data.integrations?.smsService?.options?.senderName }
        onChange={ value => updateFieldNoRender('integrations.smsService.options.senderName', value) }
        errors={ errors }
      />
    </div>
  );
}

function RedSMSSms({data, updateFieldNoRender, errors}: any) {
  return (
    <div className="grid grid-cols-2 gap-4">
      <TextInput
        label='API Key'
        textTooltip='API Key'
        placeholder='Текстовое поле'
        name={ 'integrations.smsService.apiKey' }
        value={ data.integrations?.smsService?.options?.apiKey }
        onChange={ value => updateFieldNoRender('integrations.smsService.options.apiKey', value) }
        errors={ errors }
        initialValueReplaceOnly={true}
      />

      <TextInput
        label='Логин'
        textTooltip='Логин'
        placeholder='Текстовое поле'
        name={ 'integrations.smsService.login' }
        value={ data.integrations?.smsService?.options?.login }
        onChange={ value => updateFieldNoRender('integrations.smsService.options.login', value) }
        errors={ errors }
      />

      <TextInput
        label='Имя отправителя'
        textTooltip='Имя отправителя'
        placeholder='Текстовое поле'
        name={ 'integrations.smsService.senderName' }
        value={ data.integrations?.smsService?.options?.senderName }
        onChange={ value => updateFieldNoRender('integrations.smsService.options.senderName', value) }
        errors={ errors }
      />
    </div>
  );
}

function SmsRuCall({data, updateFieldNoRender, errors}: any) {
  return (
    <div className="grid grid-cols-2 gap-4">
      <TextInput
        label='API Secret'
        textTooltip='API Secret'
        placeholder='Текстовое поле'
        name={ 'integrations.phoneCallService.options.apiId' }
        value={ data.integrations?.phoneCallService?.options?.apiId }
        onChange={ value => updateFieldNoRender('integrations.phoneCallService.options.apiId', value) }
        errors={ errors }
        initialValueReplaceOnly={true}
      />
    </div>
  );
}

function RedSMSCall({data, updateFieldNoRender, errors}: any) {
  return (
    <div className="grid grid-cols-2 gap-4">
      <TextInput
        label='API Key'
        textTooltip='API Key'
        placeholder='Текстовое поле'
        name={ 'integrations.phoneCallService.apiKey' }
        value={ data.integrations?.phoneCallService?.options?.apiKey }
        onChange={ value => updateFieldNoRender('integrations.phoneCallService.options.apiKey', value) }
        errors={ errors }
        initialValueReplaceOnly={true}
      />

      <TextInput
        label='Логин'
        textTooltip='Логин'
        placeholder='Текстовое поле'
        name={ 'integrations.phoneCallService.login' }
        value={ data.integrations?.phoneCallService?.options?.login }
        onChange={ value => updateFieldNoRender('integrations.phoneCallService.options.login', value) }
        errors={ errors }
      />
    </div>
  );
}

function SmsContainer({data, updateFieldNoRender, errors}: any) {
  const props = {data, updateFieldNoRender, errors};
  const code = data.integrations?.smsService?.code;

  const containers = {
    'sms-ru': <SmsRuSms {...props} />,
    'red-sms': <RedSMSSms {...props} />
  }

  if (! code || ! containers.hasOwnProperty(code)) {
    return (<></>);
  }

  return containers[code];
}

function CallContainer({data, updateFieldNoRender, errors}: any) {
  const props = {data, updateFieldNoRender, errors};
  const code = data.integrations?.phoneCallService?.code;

  const containers = {
    'sms-ru': <SmsRuCall {...props} />,
    'red-sms': <RedSMSCall {...props} />
  }

  if (! code || ! containers.hasOwnProperty(code)) {
    return (<></>);
  }

  return containers[code];
}

export default function Sms({ data, updateField, errors, updateFieldNoRender }: any) {

  useEffect(() => {
    if (! data?.integrations?.smsService && data?.integrations) {
      updateField('integrations.smsService', { __code_select: { label: 'Не использовать', value: null } })
    } else {
      if (data.integrations.smsService?.code && ! data?.integrations?.smsService?.__code_select) {
        updateField('integrations.smsService.__code_select', phoneCallOptions.filter(elem => elem.value === data.integrations.smsService.code).at(0))
      }
    }

    if (! data?.integrations?.phoneCallService && data?.integrations) {
      updateField('integrations.phoneCallService', { __code_select: { label: 'Не использовать', value: null } })
    } else {
      if (data.integrations.phoneCallService?.code && ! data?.integrations?.phoneCallService?.__code_select) {
        updateField('integrations.phoneCallService.__code_select', smsOptions.filter(elem => elem.value === data.integrations.phoneCallService.code).at(0))
      }
    }
  }, [data]);

  return (
    <div className={'flex flex-col px-3 gap-y-6'}>
        {

          <>
            <SingleSimpleSelect
              label={'Выберите сервис для отправки смс'}
              textTooltip={'Выберите сервис для отправки смс'}
              name={'integrations.smsService.code'}
              value={data.integrations?.smsService?.__code_select}
              options={smsOptions}
              onChange={option => {
                updateField('integrations.smsService.__code_select', option)
                updateField('integrations.smsService.code', option.value)
                updateField('integrations.smsService.options', {})
              }}
            />

            <SmsContainer data={data} errors={errors} updateFieldNoRender={updateFieldNoRender} />

            <SingleSimpleSelect
              label={'Авторизация по звонку'}
              textTooltip={'Авторизация по звонку'}
              name={'integrations.phoneCallService.code'}
              value={data.integrations?.phoneCallService?.__code_select}
              options={phoneCallOptions}
              onChange={option => {
                updateField('integrations.phoneCallService.__code_select', option)
                updateField('integrations.phoneCallService.code', option.value)
                updateField('integrations.phoneCallService.options', {})
              }}
            />

            <CallContainer data={data} errors={errors} updateFieldNoRender={updateFieldNoRender} />
          </>
        }
    </div>
  )
}
