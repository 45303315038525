import {openCropperModal} from "../../Modal/CropperModal";

let nextCropperComplete : any = null;

export function showCropAndResizeImage(url: any, file: File | null | undefined, {
  cropperRef,
  forceAspectRatio,
  resizeTo = null,
  allowUpscale = false,
  ...props
}: any) {
  const needResize = resizeTo && (resizeTo[0] > 0 || resizeTo[1] > 0)
  if (!forceAspectRatio && resizeTo && resizeTo[0] > 0 && resizeTo[1] > 0) {
    forceAspectRatio = resizeTo[0] / resizeTo[1];
  }

  const getCropperImg = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      return cropperRef.current?.cropper.getCroppedCanvas()
    } else {
      return null
    }
  }

  const checkSize = ({width, height}: any) => {
    if ((width <= 0 || height <= 0) ||
        !allowUpscale && resizeTo && (
          (resizeTo[0] > 0 && width < resizeTo[0]) ||
          (resizeTo[1] > 0 && height < resizeTo[1])
      )) {
      if (resizeTo[0] > 0 && resizeTo[1] > 0) {
        return "Размер %s должен быть больше " + resizeTo.join('x') +" пикселей"
      } else if (resizeTo[0] > 0) {
        return "Ширина %s должна быть больше " + resizeTo[0] +" пикселей"
      } else {
        return "Высота %s должна быть больше " + resizeTo[1] +" пикселей"
      }
    } else {
      return null;
    }
  }

  const open = () => openCropperModal({
    isOpen: false,
    title: "Обрежьте изображение",
    fileName: file?.name,
    preConfirm: () => {
      //console.log('preConfirm')
      const img = getCropperImg()
      if (img && needResize) {
        if (checkSize(img) != null) return false
        let canvas = document.createElement('canvas')
        let ctx: any = canvas.getContext('2d')
        let w, h;
        if (resizeTo[1] <= 0) {
          w = resizeTo[0];
          h = w / img.width * img.height;
        } else {
          h = resizeTo[1];
          w = h / img.height * img.width;
        }
        w = Math.round(w);
        h = Math.round(h);
        canvas.width = w
        canvas.height = h
        ctx.drawImage(img, 0, 0, w, h)
        return canvas;
      }
      return img
    },
    url: url,
    forceAspectRatio: forceAspectRatio,
    validateSize: checkSize,
    crop: (e: any) => {
      return checkSize(e.detail)
    },
    cropperRef: cropperRef
  }).then((result: any) => {
    cropperRef.current = null
    const status = result.isConfirmed ? (result.value ? 'success' : 'failed to crop') : 'cancel';
    return { status, url: result.isConfirmed ? result.value?.toDataURL() : null }
  }).catch((result: any) => {})

  return new Promise((resolve, reject) => {
    nextCropperComplete = Promise.resolve(nextCropperComplete).then(() => {
      return open().then((v: any) => {
        resolve(v);
      });
    })
  });
}


export async function getFileFromUrl(url: any, name: any, defaultType = 'image/jpeg'){
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
}

export function loadAndProcessImage(file: File, options: any) {
  return new Promise((resolve: any, reject: any) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      if (options.forceAspectRatio || options.resizeTo) {
        showCropAndResizeImage(event?.target?.result, file, options).then(({url, status}) => {
          if (url) {
            getFileFromUrl(url, file.name).then(f => {
              resolve({url, file: f, status})
            }).catch(() => reject('internal error'))
          } else {
            reject(status)
          }
        })
      } else {
        const dataUrl = event?.target?.result as string;
        if (options.checkImageValid) {
          let img = new Image();
          img.onload = () => {
            const err = options.checkImageValid(img, dataUrl, file);
            if (err) {
              reject(err);
            } else {
              resolve({url: dataUrl, file: file, status: 'success'});
            }
          }
          img.onerror = () => {
            resolve({url: dataUrl, file: file, status: 'fail', message: 'failed to load image'});
          }
          img.src = dataUrl
        } else {
          resolve({url: dataUrl, file: file, status: 'success'});
        }
      }
    }

    reader.readAsDataURL(file)
  })
}

// export function getImage(f: File) {
//   return new Promise( (resolve, reject) => {
//     const reader = new FileReader();
//     reader.onload = (event: ProgressEvent<FileReader>) => {
//       if (!event?.target?.result) {
//         reject()
//         return
//       }
//       let image = new Image()
//       image.onload = () => {
//         resolve(image)
//       }
//       image.src = event?.target?.result as string
//     }
//     reader.readAsDataURL(f)
//   })
// }
