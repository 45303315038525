import React, {useEffect, useId} from "react";
// @ts-ignore
import DatePicker, {registerLocale} from 'react-datepicker';
import "../../../../styles/datepicker.sass"
// @ts-ignore
import calendarIcon from "../../../../images/calendar.svg"
import Tooltip from "../../Tooltip";
import ru from "date-fns/locale/ru";

registerLocale("ru", ru);
export default function RangeDatePicker(
  {
    label,
    textTooltip,
    required = false,
    values = [null, null],
    onChange,
    classNameInput = '',
    errors,
    name = '',
    placeholder = ''
  }: any
) {
  const id = useId()
  const error = errors[name];

  const handleChange = (dates: any) => {
    const changedDates: any = []
    dates.forEach((date: any) => {
      date instanceof Date
        ?
        changedDates.push(date.toDateString())
        :
        typeof date === "number"
          ?
          changedDates.push((new Date(date)).toDateString())
          :
          changedDates.push(null)
    })

    onChange(changedDates)
  };

  useEffect(() => {
    delete errors[name];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <div>
      {
        label &&
          <label htmlFor={id} className="flex items-center mb-2 font-medium text-sm">
            { label }
              <span className='text-error-main'>{required && '*'}</span>
            {
              textTooltip && <Tooltip textTooltip={textTooltip} defaultText={name}/>
            }
          </label>
      }
      <div className={'relative'}>
        <DatePicker
          id={id}
          className={`outline-0 disabled:text-gray-40 disabled:pointer-events-none text-gray-50 py-[10px] px-[14px] w-full text-sm bg-white border-gray-20 border-[1px] rounded-lg pr-[40px] ${classNameInput} ${error && 'border border-red-500 disabled:border disabled:border-red-500'} `}
          onChange={handleChange}
          locale={'ru'}
          placeholderText={placeholder}
          startDate={Date.parse(values[0])}
          endDate={Date.parse(values[1])}
          dateFormat={'dd.MM.yyyy'}
          selectsRange
        />
        <img
          className={`absolute top-1/2 -translate-y-1/2 pointer-events-none right-[10px]`}
          src={calendarIcon}
          alt={'input-icon'}
        />
      </div>
    </div>

  )
}
