import React, {useState, useEffect} from 'react'
import EditFormFields from './../../../components/common/form/EditFormFields';
import {useCompaniesContext} from '../../../contexts/Companies';
import {useBranchesContext} from '../../../contexts/Branches';
import {CategoriesContextWrapper} from '../../../contexts/Categories';
import {ProductsContextWrapper} from '../../../contexts/Products';
import {useNavigate, useParams} from "react-router-dom";
import {apiClient} from '../../../libs/api/apiClient';
import FormWrapper from '../../../components/common/form/FormWrapper';
import tabs from '../tabs'
import initForm from '../initForm';
import fetchPlatforms from '../../../providers/dictionaries/platforms';

import Base from './Base';
import Marks from '../forms/Marks';
import MetaTags from '../forms/MetaTags';
import Count from '../forms/Count';
import Breadcrumbs from '../../../components/common/Breadcrumbs';
import useForm from "../../../hooks/useForm";
import { OptionsGroupContextWrapper, SkuGroupContextWrapper } from '../../../contexts/Options';
import {showErrorToast, showSuccessToast} from "../../../libs/helpers/toasts";
import {dialogModal} from "../../../components/common/Modal/DialogModal";
import { useFormContext } from '../../../contexts/Form';

export default function EditProduct() {
  const [tab, setTab] = useState('base')
  const {
    data,
    updateData,
    updateField,
    updateFieldNoRender,
    errors,
    updateErrors,
    submit,
    changedFieldsRefresh,
    isDirty
  } = useForm(JSON.parse(JSON.stringify(initForm)))

  const {company} = useCompaniesContext()
  const {branch, branches} = useBranchesContext()
  const {needUpdate, setNeedUpdate} = useFormContext()
  const [platforms, setPlatforms] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchPlatforms().then(data => {
      setPlatforms(data.map((platform: any) => ({label: platform.label, name: platform.value})))
    })
  }, [])

  const navigate = useNavigate();
  const {id: productId} = useParams<{ id?: string }>()

  const reset = () => {
    navigate('/products')
  }

  const remove = () => {
    dialogModal({
      isOpen: false,
      title: 'Внимание!',
      text: 'Вы уверены что хотите удалить товар?',
      showCancelButton: true,
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Вернуться',
    }).then((result) => {
      if (result.isConfirmed) {
        apiClient.products.remove(company?.id, branch?.id, productId)
          .then(({data, errors}: any) => {
            showSuccessToast({
              content: "Товар успешно удален",
            })
            navigate('/products')
          }).catch((err) => {
          showErrorToast({
            content: 'Что-то пошло не так'
          })
        })
      }
    }).catch(() => {})
  }

  const handleSubmit = () => {
    submit((form: any) => {
      //if (typeof (form.get('image')) == 'string')
      //  form.delete('image')
      apiClient.products.update(company?.id, branch?.id, productId, {body: form})
        .then(({data, errors, message, success}: any) => {
            if (errors || message) {
              updateErrors(errors || {})

              showErrorToast({
                content: 'Что-то пошло не так'
              })
            } else {
              changedFieldsRefresh()
              getProductsInfo(productId)
              //updateProducts()
              showSuccessToast({
                content: "Товар успешно изменен",
              })
            }
          }
        ).catch((e) => console.log(e)).finally(() => setNeedUpdate(false))
    }, 'changed')
  }

  const getProductsInfo = (id: any) => {
    if (company?.id && branch?.id) {
      setLoading(true)
      // prefetch dictionaries
      apiClient.dictionaries.measureUnits().then();
      apiClient.dictionaries.goodAddToOrder().then();

      apiClient.products.get(company.id, branch?.id, id).then(({data, errors}: any) => {
        if (data) {
          updateData(data)
        } else {
          updateErrors(errors || {})
        }
      }).catch((e) => {
      }).finally(() => {
        setLoading(false)
      })
    }
  }

  useEffect(() => {
    getProductsInfo(productId)
  }, [company?.id, branch?.id])

  useEffect(() => {
    if (needUpdate) {
      handleSubmit()
    }
  }, [needUpdate])

  const changeTab = (tag: any) => {
    if (Object.keys(isDirty()).length !== 0) {
      dialogModal({
        isOpen: false,
        title: 'Не сохраненные данные!',
        text: 'Вы действительно хотите перейти на следующий шаг? Все не сохраненные данные будут удалены',
        showDenyButton: true,
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: 'Сохранить и продолжить',
        denyButtonText: `Без сохранения`,
        cancelButtonText: 'Вернуться',
      }).then((result) => {
        if (result.isConfirmed) {
          handleSubmit()
          setTab(tag)
        } else if (result.isDenied) {
          changedFieldsRefresh()
          getProductsInfo(productId)
          setTab(tag)
        }
      }).catch(() => {})
    } else {
      setTab(tag)
    }
  }

  if (loading) return (
    <div className="text-gray-40 text-3xl text-center mt-[35vh]">
      <span>Загрузка...</span>
    </div>
  )

  return (
    <div className='px-8 pb-8 pt-2 text-gray-50'>
      <div className='mb-8'>
        <div className='text-[44px] leading-[60px] mb-2'>Каталог</div>
        <Breadcrumbs
          links={[
            {to: `/products`, label: 'Список товаров'},
            {to: '', label: 'Редактирование товара'},
          ]}

          back={`/products`}
        />
      </div>
      <ProductsContextWrapper>
        <CategoriesContextWrapper>
          <FormWrapper
            tabs={tabs}
            tab={tab}
            setTab={changeTab}
          >
            <EditFormFields
              show={tab === 'base'}
              remove={remove}
              reset={reset}
              back={'/products'}
              handleSubmit={handleSubmit}
            >
              <Base
                data={data}
                updateField={updateField}
                updateFieldNoRender={updateFieldNoRender}
                errors={errors}
                branches={branches.map((branch: any) => ({label: branch.title, value: branch.id}))}
                platforms={platforms}
                edit={true}
                //categories={categories.map(branch => ({ label: branch.title, value: branch.id }))}
                //products={products.filter((product: any) => (!product.skuParentId || product.id != productId))}
                //updateProducts={(params: any) => updateProducts(params)}
              />
            </EditFormFields>

            <EditFormFields
              show={tab === 'marks'}
              remove={remove}
              reset={reset}
              back={'/products'}
              handleSubmit={handleSubmit}
            >
              <OptionsGroupContextWrapper>
                <SkuGroupContextWrapper>
                  <Marks
                    data={data}
                    updateField={updateField}
                    updateFieldNoRender={updateFieldNoRender}
                    errors={errors}
                    tagsArray={company?.tags || []}
                    edit={true}
                    //categories={categories.map(branch => ({ label: branch.title, value: branch.id }))}
                    //products={products.filter((product: any) => (!product.skuParentId || product.id != productId))}
                    //updateProducts={(params: any) => updateProducts(params)}
                  />
                </SkuGroupContextWrapper>
              </OptionsGroupContextWrapper>
            </EditFormFields>

            <EditFormFields
              show={tab === 'metaTags'}
              remove={remove}
              reset={reset}
              back={'/products'}
              handleSubmit={handleSubmit}
            >
              <MetaTags
                data={data}
                updateField={updateField}
                updateFieldNoRender={updateFieldNoRender}
                errors={errors}
              />
            </EditFormFields>


            <EditFormFields
              show={tab === 'count'}
              remove={remove}
              reset={reset}
              back={'/products'}
              handleSubmit={handleSubmit}
            >
              <Count
                data={data}
                updateField={updateField}
                updateFieldNoRender={updateFieldNoRender}
                errors={errors}
              />
            </EditFormFields>

          </FormWrapper>
        </CategoriesContextWrapper>
      </ProductsContextWrapper>

    </div>
  )
}
