import React, {useEffect, useState} from "react";
import TextInput from "./TextInput";
import Tooltip from "../../Tooltip";
import CityLocation from "../select/location/City";
import SingleSimpleValueSelect from "../select/SingleSimpleValueSelect";
import HouseLocation from "../select/location/House";
// @ts-ignore
import deleteIcon from "../../../../images/delete.svg";
import TextInputWithSearch from "./TextInputWithSearch";
import BaseButton from "../../Button/BaseButton";
import {Link} from "react-router-dom";
import { multiSelectModal } from "../../Modal/Funnels/MultiSelectModal";
import closeImg from "../../../../images/greenClose.svg"

export default function TextInputMultipleList(
  {
    name,
    values=[],
    fields =[],
    labels=[],
    placeholders = [],
    types=[],
    fieldTypes =[],
    options = [[],[],[]],
    textTooltips=[],
    disabled = false,
    disabledArray = [],
    readOnly = false,
    onChange,
    onFieldChange = [],
    autoComplete = 'off',
    className = '',
    label = '',
    errors = {},
    required = false,
    textTooltip = '',
    remove = false,
    maxLength,
    vk = false,
    vkId = '',
    hasSelectSameElements = false,
    ...props
}: any) {
  const error = errors[name];
  const [tempValues, setTempValues] = useState<any>([])

  useEffect(() => {
    let item: any = {}
    fields.forEach((field: any) => item[field] = '')
    values ? maxLength && values.length + 1 > maxLength ? setTempValues([...values]) : setTempValues([...values, item]) : onChange([]);

    values && values.length && delete errors[name];
  }, [values]);

  return (
    <div className={`${className} py-3 px-4 border-gray-20 border-[1px] rounded-lg`}>
      {
        label &&
        <label className="flex items-center mb-2 font-medium text-sm">
          { label }
            <span className='text-error-main'>{required && '*'}</span>
          {
            textTooltip && <Tooltip textTooltip={textTooltip} defaultText={name}/>
          }
        </label>
      }
      {
        labels &&
        <>
          <div className="flex items-center gap-2 mb-2">
            {
              labels.map((item: any, index: any) => (
                <div key={item} className="font-medium" style={{flexBasis: `${100 / labels.length}%`}}>
                  <div className="flex items-center text-sm">
                    { item }
                    {textTooltips.at(index) &&  <Tooltip textTooltip={textTooltips.at(index)}/>}
                  </div>
                </div>
              ))
            }
            <div className={`${remove ? 'w-[64px] mx-2' : 'w-[24px]'}`}></div>
          </div>
        </>
      }
      <div className="flex flex-col gap-y-3">
        {
          tempValues && tempValues.map((row: any, index: any) => (
            <div key={`${name}.${index}`} className="flex items-center gap-2">
              {
                fields.map((field: any, fieldIndex: any) => (
                  <div className={'w-full'}>
                    {
                      fieldTypes.at(fieldIndex) === 'input' &&
                        <TextInput
                          key={`${name}.${index}.${field}`}
                          type={types.at(fieldIndex) || 'text'}
                          name={`${name}.${index}.${field}`}
                          value={tempValues[index][field]}
                          disabled={disabledArray.at(fieldIndex)}
                          onChange={(value: any) => {
                            onFieldChange.length && onFieldChange[fieldIndex] && onFieldChange[fieldIndex](value, index, field);
                          }}
                          onBlur={(value: any) => {
                            let temp: any = tempValues.slice(0);

                            // Если значение не менялось, то ничего не делаем 
                            if (temp[index][field] == value) {
                              return;
                            }

                            temp[index][field] = value
                            temp = temp.filter((row: any) => Object.values(row).some(value => !!value))

                            onChange(temp)
                          }}
                          readOnly={readOnly}
                          placeholder={placeholders.at(fieldIndex)}
                          className="grow"
                          errors={errors}
                          errorLabel={false}
                          autoComplete={autoComplete}
                          {...props}
                        />
                    }
                    {
                      fieldTypes.at(fieldIndex) === 'city' &&
                        <CityLocation
                            value={tempValues[index][field]}
                            onChange={value => {
                              onFieldChange && onFieldChange[fieldIndex] && onFieldChange[fieldIndex](value, index, field);

                              let temp: any = tempValues.slice(0);

                              temp[index][field] = value
                              temp = temp.filter((row: any) => Object.values(row).some(value => !!value))

                              onChange(temp)
                            }}
                            name={`${name}.${index}.${field}`}
                            placeholder={placeholders.at(fieldIndex)}
                            errors={errors}
                        />
                    }
                    {
                      fieldTypes.at(fieldIndex) === 'house' &&
                        <HouseLocation
                            value={tempValues[index][field]}
                            onChange={value => {
                              onFieldChange && onFieldChange[fieldIndex] && onFieldChange[fieldIndex](value, index, field);
                            
                              let temp: any = tempValues.slice(0);

                              temp[index][field] = value
                              temp = temp.filter((row: any) => Object.values(row).some(value => !!value))

                              onChange(temp)
                            }}
                            name={`${name}.${index}.${field}`}
                            placeholder={placeholders.at(fieldIndex)}
                            errors={errors}
                            {...props}
                        />
                    }
                    {
                      fieldTypes.at(fieldIndex) === 'select' &&
                        <SingleSimpleValueSelect
                            placeholder={placeholders.at(fieldIndex)}
                            name={`${name}.${index}.${field}`}
                            errors={errors}
                            options={options.at(fieldIndex)}
                            onChange={(option: any) => {
                              onFieldChange && onFieldChange[fieldIndex] && onFieldChange[fieldIndex](option, index, field);
                              
                              let temp: any = tempValues.slice(0);
                              //нужно чтобы нельзя было выбирать одно и то же значение в разных строках
                              //пример: группы СКУ в товарах
                              if (!temp.some((item: any) => item[field] == option) || hasSelectSameElements) {
                                temp[index][field] = option
                                temp = temp.filter((row: any) => Object.values(row).some(value => !!value))

                                onChange(temp)
                              }
                            }}
                            errorLabel={false}
                            value={tempValues[index][field]}
                            required={true}
                            removeItem={true}
                            removeValue={() => {
                              let temp: any = tempValues.slice(0);

                              temp[index][field] = ''
                              temp = temp.filter((row: any) => Object.values(row).some(value => !!value))

                              onChange(temp)
                            }}
                        />
                    }
                    {
                      fieldTypes.at(fieldIndex) === 'search' &&
                      <TextInputWithSearch
                        key={`${name}.${index}.${field}`}
                        type={types.at(fieldIndex) || 'text'}
                        name={`${name}.${index}.${field}`}
                        value={tempValues[index][field]}
                        disabled={disabled}
                        onChange={(value: any) => {
                          onFieldChange && onFieldChange[fieldIndex] && onFieldChange[fieldIndex](value, index, field);
                          
                          let temp: any = tempValues.slice(0);
                          //нужно чтобы нельзя было выбирать одно и то же значение в разных строках
                          //пример: группы СКУ в товарах
                          if (!temp.some((item: any) => item[field] == value)) {
                            temp[index][field] = value
                            temp = temp.filter((row: any) => Object.values(row).some(value => !!value))

                            onChange(temp)
                          }
                        }}
                        readOnly={readOnly}
                        placeholder={placeholders.at(fieldIndex)}
                        className="grow"
                        errors={errors}
                        errorLabel={false}
                        autoComplete={autoComplete}
                        dropDownData={options.at(fieldIndex)}
                        {...props}
                      />
                    }
                    {
                      fieldTypes.at(fieldIndex) === 'multiple-select' &&
                      <div className={`w-full flex flex-row flex-wrap items-center ${(tempValues[index][field] && tempValues[index][field] instanceof Array && tempValues[index][field].length > 0) ? 'gap-x-3' : ''}`}>
                        {/*<div className={'flex flex-row flex-wrap items-center gap-x-2'}>
                          {
                              tempValues[index][field] &&
                              tempValues[index][field] instanceof Array &&
                              options.at(fieldIndex).filter((option: any) => tempValues[index][field]?.includes(option?.value))
                              .map(({ label }: any) => (label))
                              .join(', ')
                          }
                        </div>*/}
                        <BaseButton onClick={() => {
                            multiSelectModal({
                              classNameWrapper: 'max-w-[600px] min-w-[350px] w-[40%]',
                              isOpen: false,
                              title: 'Выбрать значения',
                              showCancelButton: false,
                              confirmButtonText: 'Выбрать',
                              cancelButtonText: 'Вернуться',
                              allowOutsideClick: false,
                              data: options.at(fieldIndex).map((option: any) => ({ label: option?.label, value: option?.value })) || [],
                              value: tempValues[index][field] || []
                            }).then((result) => {
                              if (result.isConfirmed) {
                                let temp: any = tempValues.slice(0);

                                temp[index][field] = result.values
                                temp = temp.filter((row: any) => Object.values(row).some(value => !!value))

                                onChange(temp)
                              }
                            }).catch(() => { })
                        }}
                          className={'py-[7px] bg-interactive-elem text-white cursor-pointer rounded-lg'}>
                            {(tempValues[index][field] && tempValues[index][field] instanceof Array && tempValues[index][field].length > 0) ? `${tempValues[index][field].length} Выбрано` : 'Выбрать'}
                        </BaseButton>
                          {
                            tempValues[index][field] && 
                            tempValues[index][field] instanceof Array &&
                            tempValues[index][field].length > 0 &&
                            <div onClick={() => {
                                let temp: any = tempValues.slice(0);

                                temp[index][field] = null
                                temp = temp.filter((row: any) => Object.values(row).some(value => !!value))

                                onChange(temp)
                            }}>
                              <img src={closeImg} className="w-[16px] h-[16px]" alt="" />
                            </div>
                          }
                        {
                          error && <span className={'text-error-font font-medium text-xs'}>{error}</span>
                        }
                      </div>
                    }
                    {
                      fieldTypes.at(fieldIndex) === 'token-input' &&
                        <TextInput
                          key={`${name}.${index}.${field}`}
                          type={types.at(fieldIndex) || 'text'}
                          name={`${name}.${index}.${field}`}
                          value={tempValues[index][field]}
                          disabled={disabledArray.at(fieldIndex)}
                          onChange={(value: any) => {
                            onFieldChange && onFieldChange[fieldIndex](value, index, field);
                          }}
                          onBlur={(value: any) => {
                            let temp: any = tempValues.slice(0);

                            // Если значение не менялось, то ничего не делаем 
                            if (temp[index][field] == value) {
                              return;
                            }

                            temp[index][field] = value
                            temp = temp.filter((row: any) => Object.values(row).some(value => !!value))

                            onChange(temp)
                          }}
                          readOnly={readOnly}
                          placeholder={placeholders.at(fieldIndex)}
                          className="grow"
                          errors={errors}
                          errorLabel={false}
                          autoComplete={autoComplete}
                          initialValueReplaceOnly={true}
                          {...props}
                        />
                    }
                  </div>
                ))
              }
              {
                remove && <img src={deleteIcon} alt="" className={'cursor-pointer mx-2 ' + (index === tempValues.length - 1 ? 'opacity-0' : '')} onClick={() => {
                  if (index !== tempValues.length - 1) {
                    let temp = tempValues.filter((_: any, i: any) => i !== index);
                    setTempValues(temp)
                    temp = temp.filter((row: any) => Object.values(row).some(value => !!value))
                    onChange(temp)
                  }
                }}/>
              }
              <span className="text-sm font-bold w-12">№{index+1}</span>
            </div>
          ))
        }
      </div>
      {/*-----ВК АППА --- удалить когда захотят избавиться от "основного" приложения*/}
      {(vk) && <div className={"flex flex-col mt-5 gap-y-3 w-full"}>
          <p>Чтобы приложение появилось в указанном сообществе ВКонтакте,
              нужно добавить приложение в сообщество</p>
          <BaseButton
              className={"w-[260px] bg-interactive-elem text-white"}
          ><Link to={`https://vk.com/add_community_app.php?aid=${process.env.REACT_APP_VK_MINI_APP_ID}`} target={"_blank"}>Подключить приложение</Link>
          </BaseButton>
      </div>}
      {/*-----ВК АППА --- открыть когда захотят избавиться от "основного" приложения*/}
      {/*{(vk) && (vkId*/}
      {/*  ? <div className={"flex flex-col mt-5 gap-y-3 w-full"}>*/}
      {/*    <p>Чтобы приложение появилось в указанном сообществе ВКонтакте,*/}
      {/*      нужно добавить приложение в сообщество</p>*/}
      {/*    <BaseButton*/}
      {/*      className={"w-[260px] bg-interactive-elem text-white"}*/}
      {/*    ><Link to={`https://vk.com/add_community_app.php?aid=${vkId}`} target={"_blank"}>Подключить приложение</Link>*/}
      {/*    </BaseButton>*/}
      {/*  </div>*/}
      {/*  : <div className={"mt-5 gap-y-3 w-full"}>*/}
      {/*    <p>Чтобы подключить приложение в сообществе ВКонтакте, нужно заполнить информацию*/}
      {/*      о приложении в компании</p>*/}
      {/*  </div>)*/}
      {/*}*/}
      {error && (<span className={'text-error-font text-xs font-medium'}>{error}</span>)}
    </div>

  )
}
