import {useEffect, useRef, useState} from "react";

export default function useValueThrottle<T>(value: T, setValue: (v: T) => void, timeout: number, enable: boolean = true): [T, (v: T) => void, () => void] {
  const ref = useRef({
    pendingValue: value,
    handle: null as any
  }).current

  function reset(set: boolean) {
    if (ref.handle) {
      // console.log(Date.now() % 10000, "clear", ref.handle)
      clearTimeout(ref.handle)
      if (set) setValue(ref.pendingValue)
      ref.handle = null
    }
  }

  const [localValue, setLocalValue] = useState(value)
  useEffect(() => {
    if (!ref.handle || ref.pendingValue !== value) {
      reset(false)
      setLocalValue(value)
    }
  }, [value])
  useEffect(() => () => reset(true), [])

  if (!enable) {
    return [value, setValue, () => {}]
  }

  return [
    localValue,
    (v: T) => {
      setLocalValue(v)
      reset(false)
      ref.pendingValue = v
      ref.handle = setTimeout(() => {
        // console.log(Date.now() % 10000, "execute", ref.handle)
        ref.handle = null
        setValue(v)
      }, timeout)
      // console.log(Date.now() % 10000, "add", ref.handle)
    },
    () => { reset(true) }
  ]
}
