import React from "react";
import SingleSimpleSelect from "./SingleSimpleSelect";

export default function SingleSimpleValueSelect({
  options,
  value,
  onChange,
  howMushShowItems,
  ...props
}: any) {
  return <SingleSimpleSelect {...props}
    options={options}
    value={options.find((x: any) => x.value == value)}
    onChange={(opt: any) => onChange(opt.value)}
    howMushShowItems={howMushShowItems}
  />
}
