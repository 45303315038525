import {BehaviorSubject} from "rxjs";
import {apiClient} from "./api/apiClient";
import { redirect } from "react-router-dom";

// @ts-ignore
export const userSubject = new BehaviorSubject(typeof window !== 'undefined' && JSON.parse(localStorage.getItem('user')))

export const auth = {
  observable: userSubject.asObservable(),
  get current() {return userSubject.value},
  logout,
  login,
  loginVk,
  checkLoginVk,
  update,
  clearUser,
  register,
  updatePermissions,
}

type SuccessResponse = {
  data: {
    user: object,
    token: string,
  },
}

type ErrorResponse = {
  message?: string,
  errors: object
}

async function login(email: string, password: string, rememberMe: boolean) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email,
          password,
          rememberMe
        })
      });

      if (response.ok) {
        response.json()
            .then(json => {
              const {data}: SuccessResponse = json;
              const {user, token} = data;

              userSubject.next({...user, token});
              localStorage.setItem('user', JSON.stringify({...user, token}));

              resolve({...user, token});
            })
            .catch(reason => reject(reason));
      }

      if (response.status === 422) {
        response.json()
            .then(({message}: ErrorResponse) => reject(message));
      }

    } catch (e) {
      reject("Ошибка авторизации. Пожалуйста, попробуйте позже или обратитесь к администратору")
    }
  })
}

async function loginVk(setLink: (v: string)=>void, userToken: string) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/vk`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`,
        },
      });

      if (response.ok) {
        let data = await response.json()
        if (data.redirectTo != null) {
          setLink(data.redirectTo)
          window.open(data.redirectTo, 'MyWindow','width=600,height=300')?.focus()

          localStorage.removeItem('tokenVk')
          const handle = setInterval(()=>{
            const tokenVk = localStorage.getItem('tokenVk')
            if (tokenVk) {
              auth.checkLoginVk(tokenVk, userToken)
                .then((data) => {
                  resolve(data)
                })
                .catch(reason => {
                  reject(reason)
                })
              localStorage.removeItem('tokenVk')
              clearInterval(handle)
            }
          }
          , 50)
        }
      }

      if (response.status >= 300) {
        response.json()
          .then(({message}: ErrorResponse) => reject(message));
      }

    } catch (e) {
      reject("Ошибка авторизации. Пожалуйста, попробуйте позже или обратитесь к администратору")
    }
  })
}

async function checkLoginVk(code: string, userToken: string) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${userToken
          ? `${process.env.REACT_APP_API_URL}/api/users/vk-link`
          : `${process.env.REACT_APP_API_URL}/api/auth/vk`}`,
    {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            code
          })
        });

      if (response.ok) {
        response.json()
          .then(json => {
            const {data} = json;
            let newUser = null
            if (userToken) { // api/users/vk-link
              if (data) {
                newUser = {...auth.current, ...data}
              }
            } else if (data) { // api/auth/vk
              const {user, token} = data;
              if (user && token) {
                newUser = {...user, token};
              }
            }
            if (newUser) {
              userSubject.next(newUser);
              localStorage.setItem('user', JSON.stringify(newUser));
              resolve(newUser);
            } else reject('Нет юзера');
          })
          .catch(reason => reject(reason));
      }

      if (response.status >= 300) {
        response.json()
          .then(({message}: ErrorResponse) => reject(message));
      }

    } catch (e) {
      reject("Ошибка авторизации по вк.")
    }
  })
}

async function logout() {
  try {
    await apiClient.auth.logout();
  } catch (e) {}

  redirect("./")
  await clearUser()
}

async function clearUser() {
  userSubject.next(null);
  // @ts-ignore
  localStorage.setItem('user', null);
}

async function update() {
  return new Promise(async (resolve) => {
    try {
      // @ts-ignore
      const user = await apiClient.auth.me()
      const updated = {...auth?.current, ...user}
      userSubject.next(updated);
      localStorage.setItem('user', JSON.stringify(updated));

      resolve(updated);
      return;
    } catch (e) {
      console.log('error user update!')
    }

    resolve(auth.current);
  })

}

async function updatePermissions() {
  return new Promise(async (resolve) => {
    try {
      const {data: permissions}: any = await apiClient.auth.permissions()
      //console.log(`permissions = ${permissions}`)
      const updated = Object.assign({}, {...auth.current, permissions: permissions})
      userSubject.next(updated);
      localStorage.setItem('user', JSON.stringify(updated));

      resolve(updated);
    } catch (e) {}

    resolve(auth.current)
  })
}

async function register(name: string, email: string, password: string) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/register`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name,
          email,
          password,
        })
      });

      if (response.ok) {
        response.json()
            .then(json => {
              resolve(json)
            })
            .catch(reason => reject(reason));
      }

      if (response.status === 422) {
        response.json()
            .then(({message}: ErrorResponse) => reject(message));
      }

    } catch (e) {
      reject("Ошибка регистрации. Пожалуйста, попробуйте позже или обратитесь к администратору")
    }
  })
}
