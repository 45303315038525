import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import {usePromoTagsContext} from "../../contexts/Tags";
import useForm from "../../hooks/useForm";
import initForm from "./initForm";

import BaseButton from "../../components/common/Button/BaseButton";
import Actions from "../../components/common/Table/Actions";
import SingleSimpleValueSelect from "../../components/common/form/select/SingleSimpleValueSelect";
import {createColumnHelper} from "@tanstack/react-table";
import TagLabel from "../../components/common/form/select/datatable/tags/TagLabel";
import DataTable from "../../components/common/Table/DataTable";
import {usePromoContext} from "../../contexts/Promo";
import {getDate} from "../../libs/helpers/datetime";
import {fetchStatuses, fetchTypes} from "../../providers/dictionaries/promoCodes";
import {TagsFilter} from "../../components/common/Table/Filters/TagsFilter";
import {useBranchesContext} from "../../contexts/Branches";
import SimpleMultipleValueSelect from "../../components/common/form/select/multiple/SimpleMultipleValueSelect";
import {useCompaniesContext} from "../../contexts/Companies";
import {submitAction} from "./massActions";
import {showSuccessToast} from "../../libs/helpers/toasts";
import Export from "../../components/common/Export";
import { ExportBlocks } from "../../components/common/Export/helpers";

const renderActiveDates = (startAt: any, endAt: any) => {
  if (startAt && endAt) {
    return (
      <p>c { getDate(startAt) } по { getDate(endAt) }</p>
    );
  }

  if (startAt) {
    return (
      <p>c { getDate(startAt) }</p>
    );
  }

  if (endAt) {
    return (
      <p>по { getDate(endAt) }</p>
    );
  }

  return (<></>);
}

export default function Promo() {
  const [search, setSearch] = useState('')
  const [types, setTypes] = useState([])
  const [statuses, setStatuses] = useState<any>([])
  const [applyFilters, setApplyFilters] = useState(false)
  const [action, setAction] = useState<null|string>(null)

  const {promoTags, updatePromoTags, loading: loadingTags}: any = usePromoTagsContext();
  const {promoCodes, updatePromoCodes, updateFilters, filters, limits, loading: loadingPromoCodes, pagination}: any = usePromoContext();
  const {branch, branches} = useBranchesContext()
  const {company} = useCompaniesContext()
  const {data, updateField, updateData, errors, changedFieldsRefresh, submit} = useForm(JSON.parse(JSON.stringify(initForm)))

  const submitMassAction = () => {
    if (action === null) return
    submit((form: any) => {
      //console.log([...form.entries()])
      submitAction(() => {
        showSuccessToast({
          content: 'Действие выполнено успешно'
        })
        changedFieldsRefresh()
        updatePromoCodes(limits, filters)
        updateData({
          promoCodesIds: [],
        })
        setAction(null)
      }, action, 'promoCodes', company.id, branch.id, form)
    })
  }

  const columnMain = createColumnHelper<any>()
  const columnTags = createColumnHelper<any>()
  //-------------------------column table promo------------------------
  const columnsMain = [
    columnMain.accessor(row => row.id, {
      id: 'id',
      header: () => 'ID',
      cell: info => <div
        className={'w-[70px] font-normal break-all flex items-center ml-5'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[70px] flex justify-start text-center ml-5',
        sortField: 'id'
      }
    }),
    columnMain.accessor(row => row.code, {
      id: 'code',
      header: () => 'Код',
      cell: info => <div
        className={'w-[90px] font-normal break-all flex justify-center items-center mr-3'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[90px] flex justify-center text-center mr-3',
        sortField: 'code'
      }
    }),
    columnMain.accessor(row => row.title, {
      id: 'title',
      header: () => 'Заголовок',
      cell: info => <div className={"w-2/4 font-normal break-words flex items-center py-2 mr-3"}>
        <div className={"flex flex-col"}>
          <Link className={'text-interactive-elem'}
                to={info.row?.original?.id != null ? `/promo/${info.row?.original?.id}/edit` : '/promo'}>
            <p className={"font-medium text-base"}>{info.getValue()}</p>
          </Link>
          { renderActiveDates(info.row?.original?.startAt, info.row?.original?.endAt)}
          {info.row?.original?.tags && info.row?.original?.tags.length > 0 &&
              <TagLabel
                  backgroundColor={info.row?.original?.tags[0].background}
                  textColor={info.row?.original?.tags[0].color}
                  marginTags={"my-2"}>
                {info.row?.original?.tags[0].title}
              </TagLabel>
          }
        </div>
      </div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-2/4 flex justify-start text-center mr-3',
        sortField: 'title'
      }
    }),
    columnMain.accessor(row => row.status, {
      id: 'status',
      header: () => 'Статус',
      cell: info => <div
        className={`w-[110px] font-normal flex items-center justify-center`}>
        {statuses.find(({label, value}: { label: string, value: string }) => value === info.getValue())?.label || ''}
      </div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[110px]  flex justify-center',
        sortField: 'status'
      }
    }),
    columnMain.accessor(row => row.platforms.join(', '), {
      id: 'platforms',
      header: () => 'Платформы',
      cell: info => <div
        className={'w-[100px] font-normal break-words flex items-center justify-center'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[100px] flex justify-center',
      }
    }),
  ]
  //-------------------------column tags------------------------
  const columnsTags = [
    columnTags.accessor(row => row.code, {
      id: 'code',
      header: () => 'Код',
      cell: info => <div
        className={'w-[120px] flex items-center justify-center text-center mx-2'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[120px] flex items-center justify-center text-center mx-2'
      }
    }),
    columnTags.accessor(row => row.text, {
      id: 'text',
      header: () => 'Метки (Теги)',
      cell: info =>
        <TagLabel
          img={info?.row?.original?.icon}
          textColor={info.row?.original?.color}
          backgroundColor={info.row?.original?.background}>
          {info.getValue()}
        </TagLabel>,
      enableSorting: false,
      meta: {
        widthClass: 'min-w-[80px] flex items-center justify-center text-center',
      }
    }),
  ]

  const setDefaultStatus = () => {
    updateFilters('filter.status', 'active')
    updatePromoCodes({ ...limits, page: 1 }, filters)
  }

  useEffect(() => {
    fetchTypes().then(data => {
      setTypes(data.map((type: any) => ({label: type.label, value: type.value})))
    })
    fetchStatuses().then(data => {
      setStatuses([{label: 'Любой статус', value: ''}, ...data.map((status: any) => ({ label: status.label, value: status.value }))])
    })
    updatePromoTags(null)
    setDefaultStatus()
  }, [])

  if (!branch) {
    return <div>
      <div className="px-8 pb-8 pt-2">
        <div className="flex flex-col justify-between">
          <div>
            <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Промокоды</div>
            <div className="text-gray-40 text-base">Список промокодов</div>
          </div>
          <div className="text-gray-40 text-3xl text-center mt-[35vh]">
            Выберите филиал
          </div>
        </div>
      </div>
    </div>
  }

  if (!applyFilters && promoCodes && promoCodes?.length === 0) return (
    <div className="px-8 pb-8 pt-2">
      <div className="flex flex-col gap-y-6">
        <div className="flex justify-between items-end">
          <div>
            <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Промокоды</div>
            <div className="text-gray-40 text-base">Список промокодов</div>
          </div>
          <Link to={'create'}>
            <BaseButton className={"ml-5 bg-interactive-elem text-white"}>Добавить промокод</BaseButton>
          </Link>
        </div>
      </div>
      <div className="text-gray-40 text-3xl text-center mt-[35vh]">
        {loadingPromoCodes ? 'Загрузка...' : 'Промокодов нет'}
      </div>
    </div>
  )

  return (
    <div className="px-8 pb-8 pt-2">
      <div className="flex flex-col gap-y-6">
        <div className="flex justify-between items-end">
          <div>
            <p className="text-gray-50 text-[44px] leading-[60px] mb-2">Промокоды</p>
            <p className="text-gray-40 text-base">Список промокодов</p>
          </div>
        </div>
        <div className={"w-full flex justify-between items-center gap-x-4"}>
          <div className={"w-1/2 flex gap-x-4"}>
            <SingleSimpleValueSelect
              name={'xs'}
              placeholder={'Активен'}
              value={data.status}
              options={statuses}
              onChange={(option: any) => {
                setApplyFilters(true)
                updateField('status', option)
                updateFilters('filter.status', option)
                updatePromoCodes({ ...limits, page: 1 }, filters)
              }}
              removeItem={true}
              removeValue={() => {
                updateField('status', null)
                setDefaultStatus()
                //updateFilters('filter.status', null)
                //updatePromoCodes(limits, filters)
              }}
            />
            <SingleSimpleValueSelect
              placeholder={'Любой тип'}
              name={'type'}
              value={data.type}
              options={types}
              onChange={(value: any) => {
                setApplyFilters(true)
                updateField('type', value)
                updateFilters('filter.type', value)
                updatePromoCodes({ ...limits, page: 1 }, filters)
              }}
              required={false}
              removeItem={true}
              removeValue={() => {
                updateField('type', null)
                updateFilters('filter.type', null)
                updatePromoCodes({...limits, page: 1}, filters)
              }}
            />
          </div>
          <div className={"w-1/2"}>
            <TagsFilter
              innerPadding={'py-[5px] px-2 rounded-xl'}
              data={promoTags?.map(({title, ...o}: any) => ({text: title, ...o})) || []}
              values={data.tags}
              errors={errors}
              name={'tags'}
              loading={loadingTags}
              placeholder={'Добавьте метки'}
              buttonPlaceholder={'Добавить'}
              updateValues={(values: any) => {
                setApplyFilters(true)
                updateFilters('filter.tags', values)
                updateField('tags', values)
                updatePromoCodes(limits, filters)
              }}
              updateData={() => {}}
              columns={columnsTags}
              removeItem={(index: any) => {
                let options = data.tags.slice()
                options.splice(index, 1)
                updateField('tags', options)
                updateFilters('filter.tags', options)
                updatePromoCodes(limits, filters)
              }}
            />
          </div>
        </div>
        <div className={"w-full flex justify-between gap-x-4"}>
          <div className={"w-4/5"}>
            <Actions
              updateValue={(value: any) => {
                setSearch(value || '')
              }}
              fetchData={(value: any) => {
                setApplyFilters(true)
                updateFilters('search', value || null)
                updatePromoCodes(limits, filters)
              }}
              searchValue={search}
              classNameMarginSearch={"mt-0"}
              classNameSearch={"w-3/4"}
              classNameChildren={"w-1/4"}
            >
              <BaseButton className={"mx-2 bg-interactive-elem text-white whitespace-nowrap"}>Показать
                результат</BaseButton>
            </Actions>
          </div>
          <Link to={'create'}>
            <BaseButton className={"bg-interactive-elem text-white whitespace-nowrap"}>Добавить промокод</BaseButton>
          </Link>
        </div>
        <Export 
          title={'Экспорт промокодов'}
          types={[{title: 'Экспортировать', type: ExportBlocks.promoCodes}]} 
          filter={filters || null}
          order={limits?.order || null}
          />

        <DataTable
          data={promoCodes || []}
          columns={columnsMain}
          usePagination={true}
          defaultLimit={10}
          defaultPage={1}
          pagination={pagination}
          updateData={(params: object) => {
            updatePromoCodes(params, filters)
          }}
          loading={loadingPromoCodes}
          useMassAction={true}
          selectedRows={data.promoCodesIds || []}
          onChangeSelectionRows={(values: any) => {
            updateField('promoCodesIds', values)
          }}
          classNameTable={'mt-0'}
          defaultOrder={{createdAt: 'desc'}}
          rowsWithCustomBg={promoCodes.map(({id, status}: any) => {
            let row = {id, color: ''}

            if (status == 'inactive') {
              row.color = 'bg-error-main/[.5]'
            } else if (status == 'used') {
              row.color = 'bg-gray-20'
            }

            return row
          })}
        />
      </div>
      {(data.promoCodesIds?.length > 0) &&
          <div className={'w-full rounded-xl mt-6 mb-[120px]  flex justify-between  bg-white'}>
              <div className={'w-full m-4 flex items-center justify-center gap-x-4'}>
                  <SingleSimpleValueSelect
                      name={'outputType'}
                      placeholder={'Выберите действие'}
                      value={action}
                      options={[
                        {label: 'Выберите действие', value: null},
                        {label: 'Активировать', value: 'activate'},
                        {label: 'Деактивировать', value: 'deactivate'},
                        {label: 'Привязать к филиалу', value: 'attach'},
                        {label: 'Отвязать от филиала', value: 'detach'},
                        {label: 'Копировать', value: 'copy'},
                        //{label: 'Удалить', value: 'delete'},
                      ]}
                      onChange={(option: any) => {
                        setAction(option)
                      }}
                  />
                {
                  (action === 'attach' || action === 'detach')  &&
                    <SimpleMultipleValueSelect
                        label={undefined}
                        textTooltip={undefined}
                        options={branches.filter(({id}: any) => id !== branch?.id).map((branch: { title: any; id: any; }) => ({label: branch.title, value: branch.id}))}
                        value={data.branchesIds || []}
                        onChange={(value: any) => updateField('branchesIds', value)}
                        name={'branchesIds'}
                        placeholder={'Выберите филиалы'}
                        errors={{}}
                        className={'w-[350px]'}
                    />
                }
              </div>
              <BaseButton onClick={submitMassAction}
                  className={'w-[320px] ml-4 bg-interactive-elem text-white m-4 py-[7px]'}>Изменить {data.promoCodesIds?.length} промокодов
              </BaseButton>
          </div>}
    </div>
  )
}
