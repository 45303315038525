import React, { useState, useEffect } from 'react'
import Breadcrumbs from './../../../components/common/Breadcrumbs';
import { useCompaniesContext } from '../../../contexts/Companies';
import { useBranchesContext } from '../../../contexts/Branches';
import useForm from './../../../hooks/useForm';
import tabs from '../tabs'
import {initCreateForm} from '../initForm';
import { useNavigate } from "react-router-dom";

import Base from './Base';
import Extended from './Extended';
import Notifications from './Notifications';
import Contacts from './Contacts';
import Order from './Order';
import Integrations from './Integrations';
import PaymentSystems from './PaymentSystems';
import Metrics from './Metrics';
import Mailing from './Mailing';

import MessageTemplates from './MessageTemplates';

import { apiClient } from '../../../libs/api/apiClient';
import fetchTimeZones from '../../../providers/dictionaries/timeZones';
import fetchCurrencies from '../../../providers/dictionaries/currencies';
import fetchPhoneCodes from '../../../providers/dictionaries/phoneCodes';

import Sms from './Sms';
import FormWrapper from '../../../components/common/form/FormWrapper';
import {checkBranchAction} from "../../../libs/helpers/permissions";
import {showErrorToast, showSuccessToast} from "../../../libs/helpers/toasts";
import {auth} from "../../../libs/auth";
import CreateFormFields from "../../../components/common/form/CreateFormFields";


export default function CreateBranch() {
  const { company } = useCompaniesContext()
  const { updateBranches } = useBranchesContext()
  const [tab, setTab] = useState('base')

  const {data, updateField, updateFieldNoRender, errors, updateErrors, submit} = useForm(JSON.parse(JSON.stringify(initCreateForm)))

  const [timeZones, setTimeZones] = useState([])
  const [currencies, setCurrencies] = useState([])
  const [phoneCodes, setPhoneCodes] = useState([])
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate();

  useEffect(() => {
    if (company) {
      if (!checkBranchAction('create', company.id)) {
        showErrorToast({
          content: "Нет доступа"
        })
        navigate('/branches')
      }
      fetchTimeZones().then(data => {
        setTimeZones(data)
        updateField('base.timeZoneId', data.at(0)?.value)
      })

      fetchCurrencies().then(data => {
        setCurrencies(data)

        updateField('extended.currency', data.at(0)?.value)
      })

      fetchPhoneCodes().then(data => {
        setPhoneCodes(data)

        updateField('extended.phoneCode', data.at(0)?.value)
      })
    }
  }, [company])


  const handleSubmit = () => {
    submit(async (form: any) => {
      setLoading(true)
      apiClient.branches.create(company.id, {body: form}).then(({data, errors}: any) => {
        if (data) {
          showSuccessToast({
            content: 'Филиал успешно создан'
          })
          auth.update().then().catch()
          navigate("/branches")
          updateBranches()
        } else {
          updateErrors(errors || {});
          checkErrors(errors, true)

          showErrorToast({
            content: 'Что-то пошло не так'
          })
        }
      }).catch(() => {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      }).finally(() => setLoading(false))
    })
  }

  // Выглядит как срань, ею и является
  // Если придумаешь вариант получше отыскать на каком табе ошибка, то флаг в руки
  const [tabsHasError, setTabsHasError] = useState([])

  useEffect(() => {
    const interval = setInterval(() => {
      if ( Object.keys(errors).length > 0) {
        checkErrors(errors)
      } else if (tabsHasError.length > 0) {
        setTabsHasError([])
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [JSON.stringify(errors)]);

  const checkErrors = (err: any, tab = false) => {
    let tabsError: any = []
    let errorsName = Object.keys(err)

    tabs.map(elem => {
      if (elem?.options) {
        elem?.options.forEach(elemInOptions => {
          if (elemInOptions?.fields) {
            let hasError = false
            for (let i = 0; i < elemInOptions.fields.length; i++) {
              let fieldName = elemInOptions.fields[i];
              for (let j = 0; j < errorsName.length; j++) {
                let error = errorsName[j]

                if (error.includes(fieldName)) {
                  hasError = true
                  break
                }
              }

              if (hasError) {
                break
              }
            }

            if (hasError) {
              if (tab && !tabsError.length) {
                setTab(elemInOptions.tag)
              }
              tabsError.push(elemInOptions.tag)
            }
          }
        })
      }
      // Для таба первого уровня
      if (elem?.fields) {
        let hasError = false
        for (let i = 0; i < elem.fields.length; i++) {
          let fieldName = elem.fields[i];
          for (let j = 0; j < errorsName.length; j++) {
            let error = errorsName[j]

            if (error.includes(fieldName)) {
              hasError = true
              break
            }
          }

          if (hasError) {
            break
          }
        }

        if (hasError) {
          if (tab && !tabsError.length) {
            setTab(elem.tag)
          }
          tabsError.push(elem.tag)
        }
      }
    })

    setTabsHasError(tabsError)
  }


  return (
    <div className='px-8 pb-8 pt-2 text-gray-50'>
      <div className='mb-8'>
        <div className='text-[44px] leading-[60px] mb-2'>Филиалы</div>
        <Breadcrumbs
          links={[
            {to: `/branches`, label: 'Список филиалов'},
            {to: '', label: 'Создание филиала'},
          ]}

          back={`/branches`}
        />
      </div>

      <FormWrapper
        tabs={tabs}
        tab={tab}
        setTab={setTab}
        tabsHasError={tabsHasError}
      >
        <CreateFormFields
          show={tab === 'base'}
          nextOption='extended'
          setTab={setTab}
        >
          <Base
            data={data}
            errors={errors}
            timeZones={timeZones}
            updateField={updateField}
            updateFieldNoRender={updateFieldNoRender}
          />
        </CreateFormFields>

        <CreateFormFields
          show={tab === 'extended'}
          nextOption='notifications'
          setTab={setTab}
        >
          <Extended
            data={data}
            updateField={updateField}
            updateFieldNoRender={updateFieldNoRender}
            errors={errors}
            currencies={currencies}
            phoneCodes={phoneCodes}
          />
        </CreateFormFields>

        <CreateFormFields
          show={tab === 'notifications'}
          nextOption='contacts'
          setTab={setTab}
        >
          <Notifications
            data={data}
            errors={errors}
            updateField={updateField}
            updateFieldNoRender={updateFieldNoRender}
          />
        </CreateFormFields>

        <CreateFormFields
          show={tab === 'contacts'}
          nextOption='order'
          setTab={setTab}
        >
          <Contacts
            data={data}
            updateField={updateField}
            updateFieldNoRender={updateFieldNoRender}
            errors={errors}
          />
        </CreateFormFields>

        <CreateFormFields
          show={tab === 'order'}
          nextOption='integrations'
          setTab={setTab}
        >
          <Order
            data={data}
            updateField={updateField}
            errors={errors}
          />
        </CreateFormFields>

        <CreateFormFields
          show={tab === 'integrations'}
          nextOption='paymentSystems'
          setTab={setTab}
        >
          <Integrations
            data={data}
            updateField={updateField}
            updateFieldNoRender={updateFieldNoRender}
            errors={errors}
          />
        </CreateFormFields>

        <CreateFormFields
          show={tab === 'paymentSystems'}
          nextOption='metrics'
          setTab={setTab}
        >
          <PaymentSystems
            data={data}
            updateField={updateField}
            updateFieldNoRender={updateFieldNoRender}
            errors={errors}
          />
        </CreateFormFields>

        <CreateFormFields
          show={tab === 'metrics'}
          nextOption='mailing'
          setTab={setTab}
        >
          <Metrics
            data={data}
            updateField={updateField}
            updateFieldNoRender={updateFieldNoRender}
            errors={errors}
          />
        </CreateFormFields>

        <CreateFormFields
          show={tab === 'mailing'}
          nextOption='sms'
          setTab={setTab}
        >
          <Mailing
            data={data}
            updateField={updateField}
            updateFieldNoRender={updateFieldNoRender}
            errors={errors}
          />
        </CreateFormFields>

        <CreateFormFields
          show={tab === 'sms'}
          nextOption='messageTemplates'
          setTab={setTab}
        >
          <Sms
            data={data}
            updateField={updateField}
            updateFieldNoRender={updateFieldNoRender}
            errors={errors}
          />
        </CreateFormFields>

        <CreateFormFields
          show={tab === 'messageTemplates'}
          setTab={setTab}
          onSubmit={handleSubmit}
          submitLoading={loading}
          submitLabel='Создать филиал'

        >
          <MessageTemplates
            data={data}
            updateField={updateField}
            updateFieldNoRender={updateFieldNoRender}
            errors={errors}
          />
        </CreateFormFields>


      </FormWrapper>
    </div>
  )
}
