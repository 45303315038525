import React, {useEffect, useId, useState} from "react";
import TextInput from "../../../components/common/form/input/TextInput";
import Textarea from "../../../components/common/form/input/Textarea";
import BaseButton from "../../../components/common/Button/BaseButton";

export default function Base({data, errors, updateField, updateData, updateFieldNoRender}: any) {
  const id = useId()
  const [type, setType] = useState('credit')

  useEffect(() => {
    updateData({...data, type: type})
  },[])

  return (
    <div className={'flex flex-col px-3 gap-y-6'}>
      <div className={"border-[1px] border-gray-20 rounded-xl p-4"}>
        <label htmlFor={id} className="flex items-center mb-2 font-medium text-sm">
          Тип транзакции
        </label>
        <div className={"flex h-[35px] w-full"}>
          <BaseButton 
            onClick={() => {
              updateData({...data, type: 'credit'})
              setType('credit')
            }}
            className={`w-1/2 ${type === 'credit' ? "bg-interactive-elem text-white" : "bg-gray-30 text-gray-40"} rounded-r-none`}>
              Зачисление
          </BaseButton>
          <BaseButton 
            onClick={() => {
              updateData({...data, type: 'debit'})
              setType('debit')
            }}
            className={`w-1/2 ${type === 'debit' ? "bg-interactive-elem text-white" : "bg-gray-30 text-gray-40 "} rounded-l-none`}>
              Списание
          </BaseButton>
        </div>
      </div>

      <Textarea
        label='Телефоны клиентов (До 100 номеров)'
        textTooltip='Телефоны клиентов (До 100 номеров)'
        placeholder='Введите номер телефона клиента'
        name={'phones'}
        value={data.phones}
        onChange={value => updateFieldNoRender('phones', [value])}
        errors={errors}
      />

      <TextInput
        type={'number'}
        min={'0'}
        label={'Количество баллов'}
        textTooltip={'Количество баллов'}
        placeholder={'564'}
        name={'amount'}
        value={data.amount}
        onChange={value => updateFieldNoRender('amount', value)}
        errors={errors}
      />
      {type === 'credit' &&
        <>
          <TextInput
          type={'number'}
          min={'0'}
          label={'Время действия бонусных баллов (Дней)'}
          textTooltip={'Время действия бонусных баллов (Дней)'}
          placeholder={'564'}
          name={'bonusScoreAliveDays'}
          value={data.bonusScoreAliveDays}
          onChange={value => updateFieldNoRender('bonusScoreAliveDays', value)}
          errors={errors}
        />
  
        <TextInput
          type={'number'}
          min={'0'}
          label={'Время действия статусных баллов (Дней)'}
          textTooltip={'Время действия статусных баллов (Дней)'}
          placeholder={'564'}
          name={'statusScoreAliveDays'}
          value={data.statusScoreAliveDays}
          onChange={value => updateFieldNoRender('statusScoreAliveDays', value)}
          errors={errors}
        />
        </>
      }

      <Textarea
        label='Комментарий'
        textTooltip='Комментарий'
        placeholder='Введите причину зачисления или списания'
        name={'comment'}
        value={data.comment}
        onChange={value => updateFieldNoRender('comment', value)}
        errors={errors}
      />
    </div>
  )
}
