import React, {FormEvent, useState} from "react";
import { auth } from "../../libs/auth";
import "../../styles/login-form.css";
import rectangleIcon1 from "../../images/Rectangle_1.svg";
import rectangleIcon2 from "../../images/Rectangle_2.svg";
import vectorEye from "../../images/Vector.svg";
import SuccessRegister from "./Success";
import {Link} from "react-router-dom";
import {ModalVk} from "../Login";
import logoLogin from '../../images/logoForLogin.svg'

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [_, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [success, setSuccess] = useState(false)

  const [type, setType] = useState<'vk' | 'email'>('email');
  const [link, setLink] = useState<string>('')

  function handleRegister(type: 'vk' | 'email') {
    setType(type)
    if (type === 'email') {
      setLoading(true);
      auth
        .register(name, email, password)
        .then((res) => {
          setSuccess(true)
        })
        .catch((reason) => {
          alert(reason);
        })
        .finally(() => {
          setLoading(false);
        });
    }else if (type === 'vk') {
      auth.loginVk(setLink, '')
        .then((user) => {
          // navigate('/')
        }).finally(() => setLoading(false))
    }
  }

  function checkEye() {
    setPasswordShown(!passwordShown);
  }

  return (
    <>
    <div className={`flex items-center justify-center min-h-screen ${success ? 'hidden' : ''}`}>
      <div className="container-form">
        <div className="main-logo-container">
          <div className="main-logo">
          <img src={logoLogin} alt=""></img>
          </div>
          {/* <div className="main-logo-head">
            <span>Result.Rest</span>
          </div> */}
        </div>
        <form onSubmit={(e) => {
          e.preventDefault()
          handleRegister('email')
        }}>
          <div className="name-form">
            <span>Имя</span>
            <br></br>
            <input
              type="input"
              placeholder={"Введите имя"}
              name={"name"}
              onChange={(event) => setName(event.target.value)}
            />
          </div>
          <div className="email-form">
            <span>E-mail</span>
            <br></br>
            <input
              type="email"
              placeholder={"Введите e-mail"}
              name={"email"}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
          <div className="password-form">
            <span>Пароль</span>
            <br></br>
            <input
              placeholder={"Введите пароль"}
              type={passwordShown ? "text" : "password"}
              onChange={(event) => setPassword(event.target.value)}
            ></input>
            <img src={vectorEye} onClick={checkEye} alt={""}></img>
          </div>

          <div className="button-log-in">
            <button>Зарегистрироваться</button>
          </div>
        </form>
        <div onClick={()=>{
          handleRegister('vk')
        }} className="button-vk-id">
          <button>Войти через VK ID</button>
        </div>
        <div className={'flex flex-row items-center justify-center w-full mt-3'}>
          <span className={'text-sm text-gray-40 mr-2'}>Уже есть аккаунт?</span>
          <Link className={'text-sm text-interactive-elem'} to={'/login'}>Войти</Link>
        </div>
      </div>
    </div>
    {type === 'vk' && <ModalVk isOpen={type === 'vk'} onClose={()=>setType('email')} link={link}/>}
    <SuccessRegister show={success} />
    </>
  );
};

export default Register;
