const tabs = [
  {
    label: 'Опции',
    tag: 'options',
    fields: [
    ]
  },
  {
    label: 'Группы опций',
    tag: 'optionsGroup',
    fields: [
    ]
  },
  {
    label: 'Группы "SKU"',
    tag: 'skuGroup',
    fields: [
    ]
  },
]

export default tabs
