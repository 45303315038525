import React, {useState} from "react"
import {useCompaniesContext} from "../../../contexts/Companies";
import {useBranchesContext} from "../../../contexts/Branches";
import useForm from "../../../hooks/useForm";
import initForm from "./initForm";
import {useNavigate} from "react-router-dom";
import {apiClient} from "../../../libs/api/apiClient";
import {showErrorToast, showSuccessToast} from "../../../libs/helpers/toasts";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import Base from "../forms/Base";
import BaseButton from "../../../components/common/Button/BaseButton";


export default function CreateFunnelGroup() {
  const {company} = useCompaniesContext()
  const { branch, branches } = useBranchesContext()
  const {data, updateField, updateFieldNoRender, errors, updateErrors, submit} = useForm(JSON.parse(JSON.stringify(initForm)))
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()


  const handleSubmit = () => {
    if (company && branch) {
      submit(async (form: any) => {
        setLoading(true)
        apiClient.funnelGroup.create(company?.id, branch?.id, {body: form}).then(({data, errors}: any) => {
          if (data) {
            showSuccessToast({
              content: "Группа воронок успешно создана",
            })
            navigate('/funnels')
          } else {
            updateErrors(errors || {});

            showErrorToast({
              content: 'Что-то пошло не так'
            })

          }
        }).catch(() => {
          showErrorToast({
            content: 'Что-то пошло не так'
          })
        }).finally(() => setLoading(false))
      })
    }
  }

  return (
    <div className='px-8 pb-8 pt-2 text-gray-50'>
      <div className='mb-8'>
        <div className='text-[44px] leading-[60px] mb-2'>Создание группы воронок</div>
        <Breadcrumbs
          links={[
            {to: `/funnels`, label: 'Список групп воронок'},
            {to: '', label: 'Создание группы воронок'},
          ]}
          back={`/funnels`}
        />
      </div>
      <div className={'rounded-xl bg-white py-5'}>
        <Base
          errors={errors}
          data={data}
          updateField={updateField}
          updateFieldNoRender={updateFieldNoRender}
          branches={branches.map((branch: any) => ({label: branch.title, value: branch.id}))}
          funnels={[]}
          isEdit={false}
        />
        <div className={'mt-6 border-gray-10 border-y-[1px] flex justify-end z-[50] w-full sticky bottom-0 bg-white'}>
          <BaseButton
            disabled={loading}
            className={`w-[225px] bg-interactive-elem text-white m-4 py-[7px] ${loading && 'bg-interactive-text'}`}
            onClick={handleSubmit}>
            {
              loading ?
                <div className={'flex flex-row items-center justify-center w-[120px]'}>
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
                : "Создать группу воронок"
            }
          </BaseButton>
        </div>
      </div>
    </div>
  )
}
