import React, {useState} from 'react';
import BaseButton from "../../components/common/Button/BaseButton";
import {Link} from "react-router-dom";
import {useDeliveryZonesContext} from "../../contexts/DeliveryZones";
import DataTable from "../../components/common/Table/DataTable";
import {createColumnHelper} from "@tanstack/react-table";
import Actions from "../../components/common/Table/Actions";
import {apiClient} from "../../libs/api/apiClient";
import {useCompaniesContext} from "../../contexts/Companies";
import {useBranchesContext} from "../../contexts/Branches";
import {checkBranchAction} from "../../libs/helpers/permissions";
import {showErrorToast, showSuccessToast} from "../../libs/helpers/toasts";
import {inputModal} from "../../components/common/Modal/InputModal";
import {validateWebsiteUrl} from "../../libs/helpers/validation";
import { getPagination, setPagination } from '../../libs/helpers/pagination';

export default function DeliveryZones() {
  const {company} = useCompaniesContext()
  const {branch} = useBranchesContext()
  const {
    deliveryZones,
    updateDeliveryZones,
    updateFilters,
    pagination,
    loading
  } = useDeliveryZonesContext()

  const [search, setSearch] = useState('')
  const [applyFilter, setApplyFilter] = useState(false)

  const columnHelper = createColumnHelper<any>()

  const importDeliveryZone = () => {
    if (!company || !branch) return
    if (!checkBranchAction('update', company.id, branch.id)) {
      showErrorToast({
        content: "Нет доступа"
      })
      return
    }
    inputModal({
      isOpen: false,
      title: 'Импортировать',
      inputType: 'url',
      inputLabel: 'Ссылка на Яндекс карту',
      inputPlaceholder: 'Введите ссылку на Яндекс карту',
      confirmButtonText: 'Импортировать',
      cancelButtonText: 'Отменить',
      showCancelButton: true,
      showCloseButton: false,
      allowOutsideClick: true,
      validate: (value: any) => {
        return validateWebsiteUrl(value);
      },
      preConfirm: async (value: any) => {
        try {
          const {errors, message}: any = await apiClient.deliveryZones.createImport(company.id, branch.id, {body: {url: value}});
          if (errors || message) {
            showErrorToast({
              content: 'Что-то пошло не так'
            })
            return {success: false, error: message}
          } else {
            showSuccessToast({
              content: 'Зона доставки добавлена успешно'
            })
            updateDeliveryZones()
            return {success: true};
          }
        } catch (e) {
          showErrorToast({
            content: 'Что-то пошло не так'
          })
          return {success: false, error: e};
        }
      }
    }).then(() => {}).catch(() => {})
  }

  const columns = [
    columnHelper.accessor(row => row.id, {
      id: 'ID',
      header: () => 'ID',
      cell: info => <div
        className={'w-[5%] flex items-center justify-start text-center pl-3'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[5%] flex items-center justify-start text-center pl-3'
      }
    }),
    columnHelper.accessor(row => row.color, {
      id: 'color',
      header: () => '',
      cell: info => <div className={'w-[10%] flex justify-center items-center'}>
        <div style={{backgroundColor: info.getValue()}} className={'w-[30px] h-[30px] rounded-lg'}></div>
      </div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[10%] grow justify-center',
        sortField: 'color'
      }
    }),
    columnHelper.accessor(row => row.title, {
      id: 'title',
      header: () => 'Название',
      cell: info => <div className={'w-[45%] flex items-center'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[45%] break-words',
        sortField: 'title'
      }
    }),
    columnHelper.accessor(row => row.isActive, {
      id: 'isActive',
      header: () => 'Активность',
      cell: info => <div className={'w-[12%] flex items-center justify-center'}>{info.getValue() ? 'Активна' : 'Не активна'}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[12%] justify-center',
      }
    }),
    columnHelper.accessor(row => row.position, {
      id: 'position',
      header: () => 'Сортировка',
      cell: info => <div className={'w-[12%] flex items-center justify-center'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[12%] justify-center',
        sortField: 'position'
      }
    }),
    columnHelper.display({
      id: 'actions',
      header: () => 'Действия',
      cell: props => (
        <div className='w-[20%] flex items-center gap-4 justify-center'>
          <Link to={props.row?.original?.id != null ? `/delivery-zones/${props.row?.original?.id}/edit` : '/delivery-zones'}>
            <BaseButton
              className={'text-interactive-text  border-btn-secondary rounded-md border-[1px] py-[7px]'}>Редактировать</BaseButton>
          </Link>
        </div>
      ),
      meta: {
        widthClass: 'w-[20%] justify-center'
      }
    }),
  ]

  if (!branch) {
    return <div>
      <div className="px-8 pb-8 pt-2">
        <div className="flex flex-col justify-between">
          <div>
            <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Филиалы</div>
            <div className="text-gray-40 text-base">Зоны доставки</div>
          </div>
          <div className="text-gray-40 text-3xl text-center mt-[35vh]">
            Выберите филиал
          </div>
        </div>
      </div>
    </div>
  }

  return (
    <div className="px-8 pb-8 pt-2">
      <div className="flex justify-between items-end">
        <div>
          <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Филиалы</div>
          <div className="text-gray-40 text-base">Зоны доставки</div>
        </div>
        {
          (!applyFilter && deliveryZones?.length === 0) &&
            <div className={'flex'}>
                <BaseButton onClick={importDeliveryZone}
                            className={'text-interactive-text border-btn-secondary rounded-md border-[1px] py-[7px]'}>Импортировать</BaseButton>
                <Link to={'create'}>
                    <BaseButton className={'ml-5 bg-interactive-elem text-white border-red-500'}>Добавить зону
                        доставки</BaseButton>
                </Link>
            </div>
        }
      </div>
        {
          (applyFilter || deliveryZones?.length > 0) ?
            <div>
              <Actions
                updateValue={(value: any) => {
                  setSearch(value || '')
                }}
                fetchData={(value: any) => {
                  setApplyFilter(true)
                  updateFilters('search', value || null, true)
                }}
                searchValue={search}
                classNameChildren={'w-1/2 flex items-center justify-end'}
              >
                <BaseButton onClick={importDeliveryZone}
                            className={"border-[1px] border-btn-secondary text-interactive-text"}>Импортировать</BaseButton>
                <Link to={'create'}>
                  <BaseButton className={'ml-5 bg-interactive-elem text-white'}>Добавить зону доставки</BaseButton>
                </Link>
              </Actions>
              <DataTable
                data={deliveryZones}
                columns={columns}
                usePagination={true}
                defaultLimit={getPagination('delivery-zones')?.limit || 10}
                defaultPage={getPagination('delivery-zones')?.page || 1}
                pagination={pagination}
                updateData={(params: any) => {
                  setPagination('delivery-zones', params)
                  updateDeliveryZones(params)
                }}
                loading={loading}
                selectedRows={[]}
              />
            </div>
            :
            <div className="text-gray-40 text-3xl text-center mt-[35vh]">
              {loading ? 'Загрузка...' : 'Добавьте зону доставки'}
            </div>
        }
    </div>
  )
}
