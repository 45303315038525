import React, {useEffect, useRef, useState} from "react";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import Base from "../../DeliveryZones/CreateDeliveryZones/Base";
import useForm from "../../../hooks/useForm";
import initForm from "../initForm";
import {useCompaniesContext} from "../../../contexts/Companies";
import {apiClient} from "../../../libs/api/apiClient";
import {useNavigate} from "react-router-dom";
import BaseButton from "../../../components/common/Button/BaseButton";
import {showErrorToast} from "../../../libs/helpers/toasts";
import { useBranchesContext } from "../../../contexts/Branches";

export default function CreateDeliveryZones() {
  const {company} = useCompaniesContext()
  const {branch}: any = useBranchesContext()
  const [loading, setLoading] = useState(false)
  const {data, updateData, updateField, updateFieldNoRender, errors, updateErrors, submit} = useForm(JSON.parse(JSON.stringify(initForm)))

  const mapRef = useRef<any>(null)

  const navigate = useNavigate();

  useEffect(() => {
    if (company && branch) {
      apiClient.branches.get(company?.id, branch?.id).then(({data}: any) => {
        if (data) {
          let coordinate = data.extended?.orderCities?.at(0);
          if (!!coordinate) {
            mapRef.current?.setCenter([coordinate.city.lat, coordinate.city.lon])
            mapRef.current?.setZoom(10)
          } else {
            mapRef.current?.setCenter([55.76, 37.64]) //moscow
          }
        }
      })
    }
  }, [company?.id, branch?.id])

  const handleSubmit = () => {
    if (company) {
      submit(async (form: any) => {
        setLoading(true)
        apiClient.deliveryZones.create(company.id, branch.id, {body: form}).then(({data, errors, message}: any) => {
          if (data) {
            updateData(data)
            navigate("/delivery-zones")
          } else {
            updateErrors(errors || {});
            showErrorToast({
              content: 'Что-то пошло не так'
            })
          }
        }).catch(() => {
          showErrorToast({
            content: 'Что-то пошло не так'
          })
        }).finally(() => setLoading(false))
      })
    }
  }

  return (
    <div className='px-8 pb-8 pt-2 text-gray-50'>
      <div className='mb-8'>
        <div className='text-[44px] leading-[60px] mb-2'>Филиалы</div>
        <Breadcrumbs
          links={[
            {to: `/delivery-zones`, label: 'Зоны доставки'},
            {to: '', label: 'Добавление зоны доставки'},
          ]}

          back={`/delivery-zones`}
        />
      </div>
      <div className={'rounded-xl bg-white py-5'}>
        <Base errors={errors} data={data} updateField={updateField} mapRef={mapRef} updateFieldNoRender={updateFieldNoRender}/>
        <div className={'mt-6 border-gray-10 border-y-[1px] flex justify-end z-[50] w-full sticky bottom-0 bg-white'}>
          <BaseButton
            disabled={loading}
            className={`w-[225px] bg-interactive-elem text-white m-4 py-[7px] ${loading && 'bg-interactive-text'}`}
            onClick={handleSubmit}>
            {
              loading ?
                <div className={'flex flex-row items-center justify-center w-[120px]'}>
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
                : "Сохранить зону доставки"
            }
          </BaseButton>
        </div>
      </div>
    </div>
  )
}
