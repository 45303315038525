import React, {createContext, useContext, useEffect, useState} from "react";
import { apiClient } from "../libs/api/apiClient";
import { useCompaniesContext } from "./Companies";
import { useBranchesContext } from "./Branches";
import {preparePaginationQueryParams} from "./common";
import { showErrorToast } from "../libs/helpers/toasts";

interface CategoriesContextType {
  categories: any,
  loading: boolean,
  updateCategories: Function,
  updatePagination: Function,
  updateFilters: Function,
  pagination: {}
}

const CategoriesContext = createContext<CategoriesContextType>({
  categories: [],
  loading: false,
  updatePagination: ({ limit, order, page }: any) => {},
  updateCategories: (params: any) => {},
  updateFilters: (path: any, value: any, applyFilter: any) => {},
  pagination: {}
});

export function CategoriesContextWrapper({children}: any) {
  const { company }: any = useCompaniesContext()
  const { branch }: any = useBranchesContext()

  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(true)
  const [pagination, setPagination] = useState({})
  const [filters, setFilters] = useState({})

  const updateCategories = (params = {}) => {
    if (company && branch) {
      setLoading(true)
      if (params) {
        updatePagination(params)
      }
      apiClient.categories.getAll(company.id, branch.id, params ? preparePaginationQueryParams(filters) : '')
        .then(({data, meta, links, message, errors}: any) => {
          if (message || errors) {
            showErrorToast({
              content: 'Произошла ошибка при получении списка разделов'
            })
          } else {
            setCategories(data)
            if (meta) {
              setPagination(meta)
            }
          }
        }).catch(() => {
          showErrorToast({
            content: 'Произошла ошибка при получении списка разделов'
          })
        }).finally(() => setLoading(false))
    }
  }

  const updatePagination = ({limit, order, page}: any) => {
    let tempData = filters
    if (limit) {
      tempData['limit'] = limit
    }
    if (page) {
      tempData['page'] = page
    }
    if (order) {
      tempData['order'] = order
    }

    setFilters(filters)
  }

  const updateFilters = (path: any, value: any, applyFilters = false) => {
    path = path.split('.')

    let tempData = filters;
    path.forEach((key: any, index: any) => {
      if (index === path.length - 1) {
        if (tempData instanceof Object) {
          tempData[key] = value
        } else {
          tempData = {[key]: value}
        }
      } else {
        if (!(tempData[key] instanceof Object)) {
          tempData[key] = {}
        }
        if (!(key in tempData)) {
          tempData[key] = {}
        }
        tempData = tempData[key]
      }
    })
    setFilters(filters)
    if (applyFilters) {
      //console.log(`updateFilters value = ${value} isNull = ${typeof value === null}`);
      updateCategories(filters)
    }
  }

  useEffect(() => {
    updateCategories()
  }, [company?.id, branch?.id])

  return (
    <CategoriesContext.Provider value={{categories, updatePagination, loading, updateCategories, updateFilters, pagination}}>
      { children }
    </CategoriesContext.Provider>
  )
}

export function useCategoriesContext() {
  return useContext(CategoriesContext)
}
