import React, { useState, useEffect } from 'react'
import tabs from '../tabs'
import initForm from '../initForm';
import useForm from '../../../hooks/useForm';
import FormWrapper from '../../../components/common/form/FormWrapper';
import CreateFormFields from '../../../components/common/form/CreateFormFields';
import Breadcrumbs from './../../../components/common/Breadcrumbs/index';
import {OptionsGroupContextWrapper, SkuGroupContextWrapper} from "../../../contexts/Options";
import { useCompaniesContext } from '../../../contexts/Companies';
import { useBranchesContext } from '../../../contexts/Branches';
import {CategoriesContextWrapper} from '../../../contexts/Categories';
import fetchPlatforms from '../../../providers/dictionaries/platforms';
import { apiClient } from '../../../libs/api/apiClient';
import { useNavigate, useSearchParams } from "react-router-dom";

import Base from '../forms/Base';
import Marks from '../forms/Marks';
import MetaTags from '../forms/MetaTags';
import Count from '../forms/Count';

import {ProductsContextWrapper} from "../../../contexts/Products";
import {showErrorToast, showSuccessToast} from "../../../libs/helpers/toasts";

export default function CreateProduct() {
  const { company } = useCompaniesContext()
  const { branch, branches } = useBranchesContext()
  const [tab, setTab] = useState('base')
  const {data, updateData, updateField, updateFieldNoRender, errors, updateErrors, submit, getFormData} = useForm(JSON.parse(JSON.stringify(initForm)))
  const [platforms, setPlatforms] = useState([])
  const [loading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  let [searchParams] = useSearchParams();
  let copyId = searchParams.get('copy')

  useEffect(() => {
    fetchPlatforms().then(data => {
      setPlatforms(data.map((platform: any) => ({ label: platform.label, name: platform.value })))
    })
  }, [])

  const getProductsInfo = (id: any) => {
    if (company?.id && branch?.id) {
      setLoading(true)
      // prefetch dictionaries
      apiClient.dictionaries.measureUnits().then();
      apiClient.dictionaries.goodAddToOrder().then();

      apiClient.products.get(company.id, branch?.id, id).then(({data, errors}: any) => {
        if (data) {
          updateData(data)
        } else {
          copyId = null
          updateErrors(errors || {})
        }
      }).catch((e) => {
      }).finally(() => {
        setLoading(false)
      })
    }
  }

  useEffect(() => {
    if (copyId) {
      getProductsInfo(copyId)
    } else {
      setLoading(false)
    }
  }, [company?.id, branch?.id])

  const navigate = useNavigate();

  const handleSubmit = () => {

    if (copyId) {
      let form: FormData = getFormData('create', data, new FormData());

      if (typeof form.get('image') === 'string') {
        form.delete('image')
      }
      if (form.get('foodValuePerHundredGram[energyValue]') == 'null') {
        form.delete('foodValuePerHundredGram[energyValue]')
      }
      if (form.get('foodValuePerHundredGram[protein]') == 'null') {
        form.delete('foodValuePerHundredGram[protein]')
      }
      if (form.get('foodValuePerHundredGram[fat]') == "null") {
        form.delete('foodValuePerHundredGram[fat]')
      }
      if (form.get('foodValuePerHundredGram[carbohydrate]') == "null") {
        form.delete('foodValuePerHundredGram[carbohydrate]')
      }
      if (form.get('foodValuePerPortion[energyValue]') == "null") {
        form.delete('foodValuePerPortion[energyValue]')
      }
      if (form.get('foodValuePerPortion[protein]') == "null") {
        form.delete('foodValuePerPortion[protein]')
      }
      if (form.get('foodValuePerPortion[fat]') == "null") {
        form.delete('foodValuePerPortion[fat]')
      }
      if (form.get('foodValuePerPortion[carbohydrate]') == "null") {
        form.delete('foodValuePerPortion[carbohydrate]')
      }

      setSubmitLoading(true)
      apiClient.products.copy(company.id, branch.id, {body: form}, copyId).then(({data, errors}: any) => {
        if (data) {
          showSuccessToast({
            content: "Товар успешно создан",
          })
          navigate("/products")
        } else {
          updateErrors(errors || {});
          checkErrors(errors, true)

          showErrorToast({
            content: 'Что-то пошло не так'
          })
        }
      }).catch(() => {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      }).finally(() => setSubmitLoading(false))
    } else {
      submit(async (form: any) => {
        setSubmitLoading(true)
        apiClient.products.create(company.id, branch.id, {body: form}).then(({data, errors}: any) => {
          if (data) {
            showSuccessToast({
              content: "Товар успешно создан",
            })
            navigate("/products")
          } else {
            updateErrors(errors || {});
            checkErrors(errors, true)

            showErrorToast({
              content: 'Что-то пошло не так'
            })
          }
        }).catch(() => {
          showErrorToast({
            content: 'Что-то пошло не так'
          })
        }).finally(() => setSubmitLoading(false))
      })
    }
  }

  // Выглядит как срань, ею и является
  // Если придумаешь вариант получше отыскать на каком табе ошибка, то флаг в руки
  const [tabsHasError, setTabsHasError] = useState([])

  useEffect(() => {
    const interval = setInterval(() => {
      if ( Object.keys(errors).length > 0) {
        checkErrors(errors)
      } else if (tabsHasError.length > 0) {
        setTabsHasError([])
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [JSON.stringify(errors)]);

  const checkErrors = (err=errors, tab = false) => {
    let tabsError: any = []
    let errorsName = Object.keys(err)

    tabs.map((elem: any) => {
      if (elem?.options) {
        elem?.options.forEach((elemInOptions: any) => {
          if (elemInOptions?.fields) {
            let hasError = false
            for (let i = 0; i < elemInOptions.fields.length; i++) {
              let fieldName = elemInOptions.fields[i];
              for (let j = 0; j < errorsName.length; j++) {
                let error = errorsName[j]

                if (error.includes(fieldName)) {
                  hasError = true
                  break
                }
              }

              if (hasError) {
                break
              }
            }

            if (hasError) {
              if (tab && !tabsError.length) {
                setTab(elemInOptions.tag)
              }
              tabsError.push(elemInOptions.tag)
            }
          }
        })
      }
      // Для таба первого уровня
      if (elem?.fields) {
        let hasError = false
        for (let i = 0; i < elem.fields.length; i++) {
          let fieldName = elem.fields[i];
          for (let j = 0; j < errorsName.length; j++) {
            let error = errorsName[j]

            if (error.includes(fieldName) && error.startsWith(fieldName)) {
              hasError = true
              break
            }
          }

          if (hasError) {
            break
          }
        }

        if (hasError) {
          if (tab && !tabsError.length) {
            setTab(elem.tag)
          }
          tabsError.push(elem.tag)
        }
      }
    })

    setTabsHasError(tabsError)
  }

  if (loading) return (
    <div className="text-gray-40 text-3xl text-center mt-[35vh]">
      <span>Загрузка...</span>
    </div>
  )

  return (
    <div className='px-8 pb-8 pt-2 text-gray-50'>
      <div className='mb-8'>
        <div className='text-[44px] leading-[60px] mb-2'>Каталог</div>
        <Breadcrumbs
          links={[
            {to: `/products`, label: 'Список товаров'},
            {to: '', label: 'Создание товара'},
          ]}

          back={`/products`}
        />
      </div>

      <FormWrapper
        tabs={tabs}
        tab={tab}
        setTab={setTab}
        tabsHasError={tabsHasError}
      >
        <CreateFormFields
          show={tab === 'base'}
          nextOption='marks'
          setTab={setTab}
        >
          <ProductsContextWrapper>
            <CategoriesContextWrapper>
              <Base
                data={data}
                updateField={updateField}
                updateFieldNoRender={updateFieldNoRender}
                errors={errors}
                branches={branches.map((branch: any) => ({label: branch.title, value: branch.id}))}
                platforms={platforms}
                //categories={categories.map((category: any) => ({label: category.title, value: category.id}))}
                //products={products.filter((product: any) => product.skuParentId === null)}
                //updateProducts={(params: any) => updateProducts(params)}
              />
            </CategoriesContextWrapper>
          </ProductsContextWrapper>

        </CreateFormFields>

        <CreateFormFields
          show={tab === 'marks'}
          nextOption='metaTags'
          setTab={setTab}
        >
          <ProductsContextWrapper>
            <CategoriesContextWrapper>
              <OptionsGroupContextWrapper>
                <SkuGroupContextWrapper>
                  <Marks
                    data={data}
                    updateField={updateField}
                    updateFieldNoRender={updateFieldNoRender}
                    errors={errors}
                    tagsArray={company?.tags || []}
                    //categories={categories.map(category => ({label: category.title, value: category.id}))}
                    //products={products.filter((product: any) => product.skuParentId === null)}
                    //updateProducts={(params: any) => updateProducts(params)}
                  />
                </SkuGroupContextWrapper>
              </OptionsGroupContextWrapper>
            </CategoriesContextWrapper>
          </ProductsContextWrapper>

        </CreateFormFields>

        <CreateFormFields
          show={tab === 'metaTags'}
          nextOption='count'
          setTab={setTab}
        >
          <MetaTags
            data={data}
            updateField={updateField}
            updateFieldNoRender={updateFieldNoRender}
            errors={errors}
          />
        </CreateFormFields>


        <CreateFormFields
          show={tab === 'count'}
          onSubmit={handleSubmit}
          submitLabel='Добавить товар'
          submitLoading={submitLoading}
        >
          <Count
            data={data}
            updateField={updateField}
            updateFieldNoRender={updateFieldNoRender}
            errors={errors}
          />
        </CreateFormFields>

      </FormWrapper>
    </div>
  )
}
