import React from 'react'
import TextInput from './../../../components/common/form/input/TextInput';
import SingleSimpleSelect from '../../../components/common/form/select/SingleSimpleSelect';

export default function Sms({data, updateField, errors, updateFieldNoRender}: any) {
  return (
    <div className={'flex flex-col px-3 gap-y-6'}>
        <SingleSimpleSelect
          label={'Выберите сервис для отправки смс'}
          textTooltip={'Выберите сервис для отправки смс'}
          name={'integrations.smsService.code'}
          value={data.integrations.smsService.__code_select}
          options={[
            {label: 'Не использовать', value: null},
            {label: 'SMS.RU', value: 'sms-ru'}
          ]}
          onChange={(option: any) => {
            updateField('integrations.smsService.__code_select', option)
            updateField('integrations.smsService.code', option.value)
            updateField('integrations.smsService.options', {})
          }}
        />

        {
          data.integrations.smsService.code === 'sms-ru' &&
          <div className="grid grid-cols-2 gap-4">
            <TextInput
              label='API Secret'
              textTooltip='API Secret'
              placeholder='Текстовое поле'
              name={'integrations.smsService.options.apiId'}
              value={data.integrations.smsService.options.apiId}
              onChange={(value: any) => updateFieldNoRender('integrations.smsService.options.apiId', value)}
              errors={errors}
            />

            <TextInput
              label='Имя отправителя'
              textTooltip='Имя отправителя'
              placeholder='Текстовое поле'
              name={'integrations.smsService.options.senderName'}
              value={data.integrations.smsService.options.senderName}
              onChange={(value: any) => updateFieldNoRender('integrations.smsService.options.senderName', value)}
              errors={errors}
            />
          </div>
        }

        <SingleSimpleSelect
          label={'Авторизация по звонку'}
          textTooltip={'Авторизация по звонку'}
          name={'integrations.phoneCallService.code'}
          value={data.integrations.phoneCallService.__code_select}
          options={[
            {label: 'Не использовать', value: null},
            {label: 'SMS.RU', value: 'sms-ru'}
          ]}
          onChange={(option: any) => {
            updateField('integrations.phoneCallService.__code_select', option)
            updateField('integrations.phoneCallService.code', option.value)
            updateField('integrations.phoneCallService.options', {})
          }}
        />

        {
          data.integrations.phoneCallService.code === 'sms-ru' &&
          <div className="grid grid-cols-2 gap-4">
            <TextInput
              label='API Secret'
              textTooltip='API Secret'
              placeholder='Текстовое поле'
              name={'integrations.phoneCallService.options.apiId'}
              value={data.integrations.phoneCallService.options.apiId}
              onChange={(value: any) => updateFieldNoRender('integrations.phoneCallService.options.apiId', value)}
              errors={errors}
            />
          </div>
        }

    </div>
  )
}
