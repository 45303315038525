import React, {useEffect, useRef, useState} from "react";
import DropDown from "./DropDown";
// @ts-ignore
import chevronDown from '../../../../images/arrow-down.svg'
// @ts-ignore
import closeIcon from "../../../../images/greenClose.svg"
import TextInput from "../input/TextInput";

export default function SingleSimpleSelect({
  options = [],
  value,
  onChange,
  name,
  classNameInput = '',
  placeholder,
  removeValue = () => {},
  removeItem = false,
  howMushShowItems,
  className = '',
  ...props
}: any) {
  const [show, setShow] = useState(false);
  const wrapper = useRef<any>(null);

  if (!options || 'null' === options) {
    options = [];
  }

  const selected = options?.find((option: any) => option.value === value)?.label;

  /* Закрываем при клике вне поля */
  useEffect(() => {
    function handleClick(event: any) {
      if (wrapper.current && !wrapper.current.contains(event.target)) {
        setShow(false);
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }

  }, [show, setShow, wrapper]);

  return (
    <div ref={wrapper} className={`relative w-full ${className || ''}`}>
      <TextInput classNameInput={`${classNameInput} cursor-pointer`} autoComplete={'off'} readOnly={true} value={value?.label || selected || ""} name={name} placeholder={placeholder} {...props} icon={(removeItem && value?.label) ? '' : chevronDown} onClick={() => setShow(show => !show)}/>
      {
        (removeItem && value) && (
          <img
            className={`absolute bottom-[14px] right-[10px] cursor-pointer`}
            src={closeIcon}
            alt={'input-icon'}
            onClick={() => {
              removeValue()
            }}
          />
        )
      }
      <DropDown howMushShowItems={howMushShowItems} items={options} value={value} setShow={setShow} show={show} onChange={(option) => {setShow(false); onChange(option);}}/>
    </div>
  )
}
