import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import {
  OptionsContextWrapper,
  OptionsGroupContextWrapper,
  SkuGroupContextWrapper,
} from "../../contexts/Options";
import tabs from "./tabs";

import FormWrapper from "../../components/common/form/FormWrapper";
import BaseButton from "../../components/common/Button/BaseButton";
import CreateFormFields from "../../components/common/form/CreateFormFields";
import TableOptions from "./Options";
import TableSkuGroups from "./SkuGroups";
import TableOptionsGroups from "./OptionsGroup";
import SingleSimpleSelect from "../../components/common/form/select/SingleSimpleSelect";
import { useBranchesContext } from "../../contexts/Branches";
import {submitAction} from "./massActions";
import {showSuccessToast} from "../../libs/helpers/toasts";
import useForm from "../../hooks/useForm";
import {useCompaniesContext} from "../../contexts/Companies";
import SimpleMultipleValueSelect from "../../components/common/form/select/multiple/SimpleMultipleValueSelect";

export default function Options() {
  const [tab, setTab] = useState('options')
  const { branch, branches } = useBranchesContext()
  const { company } = useCompaniesContext()

  const allOutputTypes = [
    {label: 'Выберите действие', value: null},
    {label: 'Привязать к филиалу', value: 'attach'},
    {label: 'Отвязать от филиала', value: 'detach'},
    {label: 'Удалить', value: 'delete'},
  ];
  const [outputType, setOutputType] = useState({value : ''});
  useEffect(() => setOutputType({value : ''}), [tab]);

  const [selectedOption, setSelectedOption] = useState<number[]>([])
  const [selectedOptionsGroup, setSelectedOptionsGroup] = useState<number[]>([])
  const [selectedSkuGroup, setSelectedSkuGroup] = useState<number[]>([])

  const [optionsUpdate, setOptionsUpdate] = useState<boolean>(false)
  const [optionsGroupUpdate, setOptionsGroupUpdate] = useState<boolean>(false)
  const [skuGroupUpdate, setSkuGroupUpdate] = useState<boolean>(false)


  const {data, updateField, submit, changedFieldsRefresh} = useForm({branches: []})

  const submitMassAction = () => {
    if (outputType === null) return
    if (tab === 'options') {
      updateField('options', selectedOption)
    } else if (tab === 'optionsGroup') {
      updateField('options', selectedOptionsGroup)
    } else if (tab === 'skuGroup') {
      updateField('options', selectedSkuGroup)
    }

    submit( (form: FormData) => {
      const branchesFilter = branches.filter(({ id }: any) => id !== branch?.id).map((branch: { title: any; id: any; }) => (branch.id))
      if (form.get('branches') === '*') {
        form.delete('branches');
        branchesFilter.forEach((branchId: any, index: any) => {
          form.append(`branches[${index}]`, branchId)
        })
      }

      console.log([...form.entries()])

      submitAction(() => {
        showSuccessToast({
          content: 'Действие выполнено успешно'
        })
        changedFieldsRefresh()
        if (outputType?.value === 'delete') {
          if (tab === 'options') {
            setOptionsUpdate(true)
          } else if (tab === 'optionsGroup') {
            setOptionsGroupUpdate(true)
          } else if (tab === 'skuGroup') {
            setSkuGroupUpdate(true)
          }
        }
      }, outputType?.value, tab, company.id, branch.id, form)
    })

  }

  return (
    <div className="px-8 pb-8 pt-2">
      <div className="flex flex-col gap-y-6">
        <div className="flex justify-between items-end">
          <div>
            <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Каталог</div>
            <div className="text-gray-40 text-base">Список опций</div>
          </div>
          {
            !!branch &&
            <>
              {tab === 'options' && <Link to={'create-option'}>
                  <BaseButton className={"ml-5 bg-interactive-elem text-white"}>Добавить опции</BaseButton>
              </Link>}
              {tab === 'optionsGroup' && <Link to={'create-options-group'}>
                  <BaseButton className={"ml-5 bg-interactive-elem text-white"}>Добавить группу опций</BaseButton>
              </Link>}
              {tab === 'skuGroup' && <Link to={'create-sku-group'}>
                  <BaseButton className={"ml-5 bg-interactive-elem text-white"}>Добавить "SKU" группу</BaseButton>
              </Link>}
            </>
          }
        </div>
      </div>
      {
        !!branch ?
        <>
          <div className={'mt-8'}>

            <FormWrapper
              tabs={tabs}
              tab={tab}
              setTab={setTab}
              heightForm={'min-h-[350px]'}
            >
              <CreateFormFields
                show={tab === 'options'}
                setTab={setTab}
              >
                <OptionsContextWrapper>
                  <TableOptions
                    selected={selectedOption}
                    setSelected={setSelectedOption}
                    massUpdate={optionsUpdate}
                    setMassUpdate={setOptionsUpdate}
                  />
                </OptionsContextWrapper>
              </CreateFormFields>

              <CreateFormFields
                show={tab === 'optionsGroup'}
                setTab={setTab}
              >
                <OptionsGroupContextWrapper>
                  <TableOptionsGroups
                    selected={selectedOptionsGroup}
                    setSelected={setSelectedOptionsGroup}
                    massUpdate={optionsGroupUpdate}
                    setMassUpdate={setOptionsGroupUpdate}
                  />
                </OptionsGroupContextWrapper>
              </CreateFormFields>

              <CreateFormFields
                show={tab === 'skuGroup'}
                setTab={setTab}
              >
                <SkuGroupContextWrapper>
                  <TableSkuGroups
                    selected={selectedSkuGroup}
                    setSelected={setSelectedSkuGroup}
                    massUpdate={skuGroupUpdate}
                    setMassUpdate={setSkuGroupUpdate}
                  />
                </SkuGroupContextWrapper>
              </CreateFormFields>

            </FormWrapper>
          </div>
          {(selectedOption.length > 0 && tab === 'options' ||
            selectedOptionsGroup.length > 0 && tab === 'optionsGroup' ||
            selectedSkuGroup.length > 0 && tab === "skuGroup") &&
              <div className={'rounded-xl mt-6 flex justify-between w-full bg-white sticky bottom-0'}>
                  <div className={'w-full gap-x-4 ml-4 flex items-center justify-center'}>
                      <SingleSimpleSelect
                          name={'outputType '}
                          placeholder={'Выберите действие'}
                          value={outputType}
                          options={allOutputTypes}
                          onChange={setOutputType}
                      />
                    {
                      (outputType?.value === 'attach' || outputType?.value === 'detach')  &&
                        <SimpleMultipleValueSelect
                            label={undefined}
                            textTooltip={undefined}
                            options={branches.filter(({id}: any) => id !== branch?.id).map((branch: { title: any; id: any; }) => ({label: branch.title, value: branch.id}))}
                            value={data.branches || []}
                            onChange={(value: any) => updateField('branches', value)}
                            name={'branches'}
                            placeholder={'Выберите филиалы'}
                            errors={{}}
                            className={'w-[350px]'}
                        />
                    }
                  </div>
                {tab === 'options' && <BaseButton
                    onClick={submitMassAction}
                    className={'w-[225px] ml-4 bg-interactive-elem text-white m-4 py-[11px]'}>Изменить {selectedOption.length} Опций
                </BaseButton>}
                {tab === 'optionsGroup' && <BaseButton
                    onClick={submitMassAction}
                    className={'w-[300px] ml-4 bg-interactive-elem text-white m-4 py-[11px]'}>Изменить {selectedOptionsGroup.length} Групп
                    опций
                </BaseButton>}
                {tab === 'skuGroup' && <BaseButton
                    onClick={submitMassAction}
                    className={'w-[300px] ml-4 bg-interactive-elem text-white m-4 py-[11px]'}>Изменить {selectedSkuGroup.length} Групп
                    "SKU"
                </BaseButton>}
              </div>}
        </> :
        <div className="text-gray-40 text-3xl text-center mt-[35vh]">
          Выберите филиал
        </div>
      }
    </div>
  )
}
