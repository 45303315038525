import React from 'react'
import TextInput from '../../../components/common/form/input/TextInput'

export default function Count({data, updateField, updateFieldNoRender, errors}: any) {
  return (
    <>
      <TextInput
        type='number'
        min='0'
        label='Количество (остаток)'
        textTooltip='Количество (остаток)'
        placeholder='0'
        name={'stockBalance'}
        value={data.stockBalance}
        onChange={value => updateFieldNoRender('stockBalance', value)}
        errors={errors}
      />
    </>
  )
}
