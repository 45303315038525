import React, {useEffect, useState} from "react";
import DataTable from "../../../components/common/Table/DataTable";
import {createColumnHelper} from "@tanstack/react-table";
import Checkbox from "../../../components/common/form/checkbox/Checkbox";
import {useSkuGroupContext} from "../../../contexts/Options";
import {Link} from "react-router-dom";
import {apiClient} from "../../../libs/api/apiClient";

export default function TableSkuGroups({selected, setSelected, massUpdate, setMassUpdate}) {
  const {skuGroups, updateSkuGroups, limits, filters, loading, pagination} = useSkuGroupContext();
  const [allOutputTypes, setAllOutputTypes] = useState([])
  useEffect(() => {
    apiClient.dictionaries.outputTypes().then(({data}) => {
      setAllOutputTypes(data);
    })
  }, [])

  useEffect(() => {
    if (massUpdate) {
      updateSkuGroups(limits, filters)
      setMassUpdate(false)
    }
  }, [massUpdate])

  const columnHelper = createColumnHelper()

  const columns = [
    columnHelper.accessor(row => row.id, {
      id: 'id',
      header: () => 'ID',
      cell: info => <div
        className={'w-[70px] font-normal break-all flex items-center mx-3'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[70px] flex justify-start text-center mx-3',
      }
    }),
    columnHelper.accessor(row => row.innerTitle, {
      id: 'innerTitle',
      header: () => 'Название в CRM',
      cell: info => <div
        className={'w-1/3 font-normal break-words flex justify-start items-center mr-3 text-interactive-text'}>
        <Link to={info.row?.original?.id != null ? `/options/${info.row?.original?.id}/edit-sku-group` : '/options'}>
          {info.getValue()}
        </Link>
      </div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-1/3 flex justify-start text-center mr-3',
        sortField: 'innerTitle'
      }
    }),
    columnHelper.accessor(row => row.title, {
      id: 'title',
      header: () => 'Публичное название',
      cell: info => <div
        className={'w-1/3 font-normal break-words flex justify-start items-center mr-3 text-interactive-text'}>
        <Link to={info.row?.original?.id != null ? `/options/${info.row?.original?.id}/edit-sku-group` : '/options'}>
          {info.getValue()}
        </Link>
      </div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-1/3 flex justify-start text-center mr-3',
        sortField: 'title'
      }
    }),
    columnHelper.accessor(row => row.branchesCount, {
      id: 'branchesCount',
      header: () => 'Ф',
      cell: info => <div
        className={'w-[50px] font-normal break-all flex justify-center items-center mr-3'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[50px] flex justify-center items-center mr-3',
      }
    }),
    columnHelper.accessor(row => row.outputType, {
      id: 'outputType',
      header: () => 'Тип вывода',
      cell: info => <div
        className={'w-[140px] font-normal flex  items-center justify-start'}>{allOutputTypes.find(({value}) => value === info.getValue())?.title || ''}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[140px] flex justify-start',
        sortField: 'outputType'
      }
    }),
  ]

  return (
    <DataTable
      data={skuGroups}
      columns={columns}
      usePagination={true}
      defaultLimit={5}
      defaultPage={1}
      pagination={pagination}
      updateData={(params) => updateSkuGroups(params)}
      loading={loading}
      useMassAction={true}
      selectedRows={selected}
      onChangeSelectionRows={(values) => setSelected(values)}
      classNameTable={'mt-0'}
    />
  )
}
