//

const initFormBase = {
  type: 'percent',
  platforms: ['site', 'vk', 'ios', 'android'],
  deliveryTypes: ["courier", "pickup"],
  generate: 0,
  status: 'active',
  toAddToCart: [],
  preorderAtStart: null,
  preorderAtEnd: null,
  startAt: null,
  endAt: null,
  orderOnMonday: true,
  orderOnTuesday: true,
  orderOnWednesday: true,
  orderOnThursday: true,
  orderOnFriday: true,
  orderOnSaturday: true,
  orderOnSunday: true,
  preorderOnMonday: true,
  preorderOnTuesday: true,
  preorderOnWednesday: true,
  preorderOnThursday: true,
  preorderOnFriday: true,
  preorderOnSaturday: true,
  preorderOnSunday: true,
}

export default initFormBase
