import React, {memo} from "react"
import {Handle, Position} from "reactflow";
// @ts-ignore
import flashImg from "../../../../images/flash.svg"
// @ts-ignore
import copyImg from "../../../../images/copy.svg"
// @ts-ignore
import trashImg from "../../../../images/trash.svg"
import {useFunnelNodeContext} from "../../../../contexts/FunnelNodes";
import {useFunnelDataContext} from "../../../../contexts/Funnels";
import {renderConditions} from "./components/conditions";
import {FieldsType} from "../forms/select/ActionSelect";

const ActionNode = ({id, data, isConnectable}: any) => {
  const {removeNode, copyNode, isValidConnection} = useFunnelNodeContext()
  const {conditions, filters} = useFunnelDataContext()
  const {actions} = useFunnelDataContext()

  const renderData = (children: any, action: any, key: any, value: any) => {
    if (key == 'conditions' && conditions?.terms && conditions?.definitions) {
      return renderConditions(value, true, conditions?.terms, conditions?.definitions, filters)
    }

    if (key === 'imageId') {
      return (<></>);
    }

    const inputInfo = action?.inputs?.find((input: any) => input?.name == key)
    if (key == 'isPromoCodeForCopyCanBeAdded') return <></>
    if (key == 'promoCodeForCopy') {
      return (<div className={'bg-funnels-green text-white rounded-2xl py-[7px] px-3 w-fit'}>
          <div className={'flex flex-row items-center gap-x-2'}>
            <div>
              Добавить копию промокода:
            </div>
            <div className="break-all">
              {value}
            </div>
          </div>
      </div>)
    }

    return (
      <div className={'bg-funnels-green text-white rounded-2xl py-[7px] px-3 w-fit'}>
        {
          inputInfo &&
            <div className={'flex flex-row items-center gap-x-2'}>
              <div>
              {inputInfo.label}:
              </div>
              <div className="break-all">
                {transformValue(value, inputInfo)}
              </div>
            </div>
        }
      </div>
    )
  }

  const transformValue = (value: any, inputInfo: any) => {
    if (!value && value !== false && value !== 0) return <></>

    //multiselect or array
    if (value instanceof Array) {
      //array of objects
      if (value?.length > 0 && typeof value[0] == 'object') {
        const properties = inputInfo?.properties || []

        return <div className={'flex flex-col gap-y-2'}>
          {
            value?.map((item) => {
              return <div className={'p-2 border-[1px] rounded-lg border-white'}>
                {
                  Object.entries(item).map(([itemKey, itemValue]: any) => {
                    const property = properties.find((property: any) => property?.name == itemKey)
                    if (property) {
                      if (property.input == FieldsType.singleSelect) {
                        return <div>
                          {property?.label}: {property?.items?.find((propertyItem: any) => propertyItem.value == itemValue)?.title || ' - '}
                        </div>
                      }
                      if (property.input == FieldsType.multiSelect) {
                        return <div>
                          {property?.label}: {itemValue?.length + ' выбрано' || ' - '}
                        </div>
                      }
                      return <div>{property?.label}: {itemValue}</div>
                    }

                    return <></>
                  })
                }
              </div>
            })
          }
        </div>
      } else if (value?.length > 0) {
        //array of string/number
        return inputInfo?.items?.filter((data: any) => value?.includes(data?.value))
          .map((data: any) => (`${data?.title} (${data?.value})`))
          .join(', ')
      } else {
        //length 0
        return <></>
      }
    }

    //image TODO: придумать бы тут что-то получше
    if (typeof value == 'string' && (value.startsWith('data:image') || value.endsWith('.jpg') || value.endsWith('.png') || value.endsWith('.webp'))) {
      return <img src={value} className={'w-[60px] rounded-lg h-[60px]'} alt=""/>
    }

    //single select
    if ('items' in inputInfo) {
      return inputInfo?.items?.find((data: any) => data.value == value)?.title || ' - '
    }

    //textEditor html
    if ('formatted' in inputInfo && inputInfo?.formatted) {
      return <div dangerouslySetInnerHTML={{__html: value}}></div>
    }

    return value?.toString() || ' - '
  }

  const renderAction = (children: any) => {
    const action: any = Object.values(actions)?.find((action: any) => children.type == action.name)

    return (
      <div className={'flex flex-col gap-y-2 w-full border-y-[1px] border-gray-10 py-3'}>
        {
          action && <div>{action?.title}</div>
        }
        {
          children?.data?.body &&
            <div className={'flex flex-col gap-y-2'}>
              {
                Object.entries(children?.data?.body).map(([key, value]: any) => renderData(children, action, key, value))
              }
            </div>
        }
      </div>
    )
  }

  return (
    <div className={'relative group'}>
      <div className={'h-[10px] bg-transparent'}></div>
      <div className={'rounded-2xl bg-funnels-green text-sm min-w-[300px] max-w-[500px] shadow-xl'}>
        <Handle
          className={'!top-[34px]'}
          type="target"
          position={Position.Left}
          id={'in'}
          isConnectable={isConnectable}
          isConnectableStart={false}
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
          }}
          isValidConnection={(connection) => isValidConnection(connection)}
        />
        <div className={'rounded-t-2xl px-3 py-[10px] h-[46px]'}>
          <div className={'flex flex-row items-center justify-between gap-x-2'}>
            <div className={'flex flex-row items-center gap-x-2'}>
              <img src={flashImg} alt=""/>
              <span>Действие</span>
            </div>
            <div>
              {data?.title}
            </div>
          </div>
        </div>

        {
          data?.actions &&
          data.actions instanceof Object &&
          Object.keys(data.actions).length > 0 &&
          'children' in data.actions &&
          data.actions.children instanceof Array &&
          data.actions.children.length > 0 ?
            <div className={'flex flex-col py-3 bg-white'}>
              {
                data.actions.children.map((children: any) => (
                  <div className={'px-3 bg-white'}>
                    {renderAction(children)}
                  </div>
                ))
              }
            </div>

            :
              <div className={'flex py-10 px-3 bg-white text-gray-40'}>
                <div className={'flex w-full px-2 py-[15px] border-[1px] cursor-pointer hover:bg-gray-10/[.8] border-dashed rounded-xl items-center justify-center'}>
                  Нажмите на блок, чтобы добавить действие
                </div>
              </div>

        }

        <div className={'flex px-3 bg-white text-gray-20 rounded-b-2xl'}>
          <div className={'flex w-full px-3 py-[14px] border-t-[1px] border-gray-30'}>
            Переход на следующий шаг
          </div>
        </div>
        <Handle
          className={'!bottom-[15px] !top-auto !right-[10px]'}
          type="source"
          position={Position.Right}
          id={'out'}
          isConnectable={isConnectable}
          isValidConnection={(connection) => isValidConnection(connection)}
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
          }}
        />
      </div>
      <div className={'absolute bottom-[100%] w-full duration-400 flex flex-row items-center justify-between left-0 scale-0 transition-all group-hover:scale-100'}>
        <div className={'rounded-xl cursor-pointer p-3 border-[1px] bg-white border-funnels-red hover:bg-gray-10'}
             onClick={(event) => {
               event.stopPropagation()
               event.preventDefault()
               removeNode(id)
             }}
        >
          <img src={trashImg} alt=""/>
        </div>
        <div className={'rounded-xl cursor-pointer p-3 border-[1px] bg-white border-funnels-green hover:bg-gray-10'}
             onClick={(event) => {
               event.stopPropagation()
               event.preventDefault()
               copyNode(id)
             }}
        >
          <img src={copyImg} alt=""/>
        </div>
      </div>
    </div>
  )
}

export default memo(ActionNode)
