import * as React from 'react';
import { ReactElement, useState } from 'react'
import Checkbox from '../../../components/common/form/checkbox/Checkbox';
import SingleSelect, { Option } from "../../../components/v2/form/input/select/SingleSelect";
import Spoiler from "../../../components/v2/Spoiler";
import InputWrapper from "../../../components/v2/form/input/common/InputWrapper";
import Input from "../../../components/v2/form/input/Input";
import Tooltip from "../../../components/common/Tooltip";
import TokenInput from '../../../components/v2/form/input/TokenInput';

type Props = {
  data: any,
  errors: any,
  updateFieldNoRender: any,
  updateField: any,
}

type Components = {
  [k in string]: ({ data, errors, updateFieldNoRender }: Props) => ReactElement
}

function YooKassa({ data, errors, updateFieldNoRender }: Props) {
  return (
    <>
      <div className='grid grid-cols-2 gap-4 mb-6'>
        <div>
          <InputWrapper error={ errors['paymentSystems.yooKassa.shopId'] }>
            <InputWrapper.Label>
              <span>Логин</span>
              <Tooltip textTooltip={ "Логин" }/>
            </InputWrapper.Label>
            <Input name={ 'paymentSystems.yooKassa.shopId' } placeholder={ 'Введите логин' }
                   defaultValue={ data.paymentSystems?.yooKassa?.shopId }
                   onBlur={ (event) => updateFieldNoRender('paymentSystems.yooKassa.shopId', event.target.value) }
            />
          </InputWrapper>
        </div>

        <div>
          <InputWrapper error={ errors['paymentSystems.yooKassa.secretKey'] }>
            <InputWrapper.Label>
              <span>Секретный ключ</span>
              <Tooltip textTooltip={ "Секретный ключ" }/>
            </InputWrapper.Label>
            <TokenInput name={ 'paymentSystems.yooKassa.secretKey' } placeholder={ 'Введите секретный ключ' }
                   defaultValue={ data.paymentSystems?.yooKassa?.secretKey }
                   onBlur={ (event) => { 
                     if (event.target.value != data.paymentSystems?.yooKassa?.secretKey)
                       updateFieldNoRender('paymentSystems.yooKassa.secretKey', event.target.value);
                   } }
            />
          </InputWrapper>
        </div>
      </div>
      <Spoiler title={ 'Подробнее' } size={ 'sm' } toggleTextColor={ 'text-interactive-text' }>
        <div className={ "flex flex-col font-medium mt-3 gap-y-2 max-w-[90%]" }>
          <div className='mb-6'>
            Для корректного получения статусов оплаты необходимо указать адрес для уведомлений в Личном кабинете
            Юкассы.
          </div>
          <ul>
            <li>
              { `• Адрес для уведомлений – ${ process.env.REACT_APP_PUBLIC_API_URL || '{адрес}' }/api/yookassa/webhook` }
            </li>
            <li>
              { '• В разделе Интеграция — HTTP-уведомления укажите URL для уведомлений.' }
            </li>
            <li>
              { '• О каких событиях уведомлять — payment.succeeded, payment.waiting_for_capture, payment.canceled, refund.succeeded' }
            </li>
          </ul>
        </div>
      </Spoiler>
    </>
  );
}

function PSBank({ data, errors, updateFieldNoRender, updateField }: Props) {
  return (
    <>
      <div className='mb-4 space-y-4'>
        <div className={ 'grid grid-cols-2 gap-4' }>
          <div>
            <InputWrapper error={ errors['paymentSystems.psb.secretKey.0'] }>
              <InputWrapper.Label>
                <span>Секретный ключ. Компонента 1</span>
                <Tooltip textTooltip={ "Секретный ключ. Компонента 1" }/>
              </InputWrapper.Label>
              <TokenInput name={ 'paymentSystems.psb.secretKey.0' } placeholder={ 'Введите секретный ключ' }
                          defaultValue={ data.paymentSystems?.psb?.secretKey?.[0] }
                          onBlur={ (event) => {
                            if (event.target.value != data.paymentSystems?.psb?.secretKey?.[0])
                              updateFieldNoRender('paymentSystems.psb.secretKey.0', event.target.value);
                          } }
              />
            </InputWrapper>
          </div>

          <div>
            <InputWrapper error={ errors['paymentSystems.psb.secretKey.1'] }>
              <InputWrapper.Label>
                <span>Секретный ключ. Компонента 2</span>
                <Tooltip textTooltip={ "Секретный ключ. Компонента 2" }/>
              </InputWrapper.Label>
              <TokenInput name={ 'paymentSystems.psb.secretKey.1' } placeholder={ 'Введите секретный ключ' }
                          defaultValue={ data.paymentSystems?.psb?.secretKey?.[1] }
                          onBlur={ (event) => {
                            if (event.target.value != data.paymentSystems?.psb?.secretKey?.[1])
                              updateFieldNoRender('paymentSystems.psb.secretKey.1', event.target.value);
                          } }
              />
            </InputWrapper>
          </div>
        </div>

        <div className={ 'grid grid-cols-3 gap-4' }>
          <div>
            <InputWrapper error={ errors['paymentSystems.psb.terminal'] }>
              <InputWrapper.Label>
                <span>Терминал</span>
                <Tooltip textTooltip={ "Терминал" }/>
              </InputWrapper.Label>
              <Input name={ 'paymentSystems.psb.terminal' } placeholder={ 'Введите идентификатор терминала' }
                     defaultValue={ data.paymentSystems?.psb?.terminal }
                     onBlur={ (event) => updateFieldNoRender('paymentSystems.psb.terminal', event.target.value) }
              />
            </InputWrapper>
          </div>
        </div>
        <div className={ 'grid grid-cols-1 gap-4' }>
          <Checkbox
            label='E-Mail на форме оформления заказа обязателен только при онлайн оплате'
            name='paymentSystems.psb.isEmailRequiredOnlyWithOnlinePayment'
            onChange={ (value: boolean) => updateField('paymentSystems.psb.isEmailRequiredOnlyWithOnlinePayment', value) }
            value={ data?.paymentSystems?.psb?.isEmailRequiredOnlyWithOnlinePayment }
            errors={ errors }
          />
        </div>
      </div>
    </>
  );
}

const systems: Option<string>[] = [
  {
    label: 'Не использовать',
    value: ''
  },
  {
    label: 'ЮKassa',
    value: 'yooKassa'
  },
  {
    label: 'ПС Банк',
    value: 'psb'
  }
];

const systemComponents: Components = {
  yooKassa: YooKassa,
  psb: PSBank
}

export default function PaymentSystems({ data, updateField, errors, updateFieldNoRender }: any) {
  const [paymentSystem, setPaymentSystem] = useState<keyof Components | undefined>(data.paymentSystems?.paymentSystem)

  let Component;

  if (paymentSystem && Object.getOwnPropertyNames(systemComponents).includes(paymentSystem)) {
    Component = systemComponents[paymentSystem]
  }

  const handleChangePaymentSystem = (item: Option<string>) => {
    setPaymentSystem(item.value);
    updateFieldNoRender('paymentSystems.paymentSystem', item.value);

    delete errors['paymentSystems.paymentSystem']
  }

  return (
    <div className={ 'flex flex-col px-3 gap-y-6' }>
      <Checkbox
        label='Передавать заказы только после фактической оплаты (Online)'
        name='paymentSystems.sendOnlyAfterPayment'
        onChange={ (value: any) => updateField('paymentSystems.sendOnlyAfterPayment', value) }
        value={ data?.paymentSystems?.sendOnlyAfterPayment }
        errors={ errors }
      />

      <SingleSelect<string> value={ paymentSystem || '' } options={ systems } label={ 'Платежный сервис' }
                            onChange={ handleChangePaymentSystem } error={ errors['paymentSystems.paymentSystem'] }/>

      { Component && (<Component data={ data } errors={ errors } updateFieldNoRender={ updateFieldNoRender } updateField={updateField}/>) }

    </div>
  )
}
