import React, {useEffect, useState} from "react";
import TextInput from "./TextInput";
import Tooltip from "../../Tooltip";
import SingleSimpleSelect from "../select/SingleSimpleSelect";
import BaseButton from "../../Button/BaseButton";
import {Link} from "react-router-dom";

export default function TextInputsList({
  name = '',
  values=[],
  disabled = false,
  readOnly = false,
  onChange = (value: any) => {},
  autoComplete = 'off',
  className = '',
  placeholder = '',
  label = '',
  errors = {},
  required = false,
  vk = false,
  vkId = '',
  textTooltip = '',
  inputType='text',
  type = 'input',
  options = [],
}) {
  const error = errors[name];
  const [tempValues, setTempValues] = useState([''])

  useEffect(() => {
    if (!!values) {
      setTempValues([...values, ''])
    } else {
      onChange([])
      setTempValues([''])
    }

    delete errors[name];
  }, [values]);

  return (
    <div className={`py-3 px-4 border-gray-20 border-[1px] rounded-lg ${className}`}>
      {
        label &&
        <label className="flex items-center mb-2 font-medium">
          { label }
            <span className='text-error-main'>{required && '*'}</span>
          {
            textTooltip && <Tooltip textTooltip={textTooltip} defaultText={name}/>
          }
        </label>
      }
      <div className="flex flex-col gap-y-3">
        {
          tempValues && tempValues.map((value, index) => (
            <div key={index} className="flex items-center">
              {
                type === 'input' &&
                  <TextInput
                      type={inputType}
                      name={`${name}.${index}`}
                      value={value}
                      disabled={disabled}
                      onChange={(value: any) => {
                        let temp = tempValues.slice(0);

                        temp[index] = value
                        temp = temp.filter(value => !!value)

                        onChange(temp)
                      }}
                      readOnly={readOnly}
                      placeholder={placeholder}
                      className="w-full"
                      errors={errors}
                      autoComplete={autoComplete}
                  />
              }
              {
                type === 'select' &&
                  <SingleSimpleSelect
                      placeholder={placeholder}
                      name={`${name}.${index}`}
                      errors={errors}
                      options={options}
                      onChange={(option: any) => {
                        let temp = tempValues.slice(0);
                        if (!temp.some((item: any) => item.value == option.value)) {
                          temp[index] = option
                          temp = temp.filter(value => !!value)

                          onChange(temp)
                        }
                      }}
                      value={value}
                      required={true}
                      removeItem={true}
                      removeValue={() => {
                        let temp = tempValues.slice(0);

                        temp[index] = ''
                        temp = temp.filter(value => !!value)

                        onChange(temp)
                      }}
                  />
              }
              <span className="text-sm font-bold ml-4 w-12">№{index+1}</span>
            </div>
          ))
        }
      </div>

      {/*-----ВК АППА --- открыть когда захотят избавиться от "основного" приложения*/}
      {/*{(vk) && (vkId*/}
      {/*  ? <div className={"flex flex-col mt-5 gap-y-3 w-full"}>*/}
      {/*    <p>Чтобы приложение появилось в указанном сообществе ВКонтакте,*/}
      {/*      нужно добавить приложение в сообщество</p>*/}
      {/*    <BaseButton*/}
      {/*        className={"w-[260px] bg-interactive-elem text-white"}*/}
      {/*    ><Link to={`https://vk.com/add_community_app.php?aid=${vkId}`} target={"_blank"}>Подключить приложение</Link>*/}
      {/*    </BaseButton>*/}
      {/*    </div>*/}
      {/*  : <div className={"mt-5 gap-y-3 w-full"}>*/}
      {/*      <p>Чтобы подключить приложение в сообществе ВКонтакте, нужно заполнить информацию*/}
      {/*        о приложении в компании</p>*/}
      {/*    </div>)*/}
      {/*}*/}
      {/*-----ВК АППА --- удалить когда захотят избавиться от "основного" приложения*/}
      {(vk) && <div className={"flex flex-col mt-5 gap-y-3 w-full"}>
        <p>Чтобы приложение появилось в указанном сообществе ВКонтакте,
          нужно добавить приложение в сообщество</p>
        <BaseButton
          className={"w-[260px] bg-interactive-elem text-white"}
        ><Link to={`https://vk.com/add_community_app.php?aid=${process.env.REACT_APP_VK_MINI_APP_ID}`} target={"_blank"}>Подключить приложение</Link>
        </BaseButton>
      </div>}

      {error && (<span className={'text-error-font text-xs font-medium'}>{error}</span>)}
    </div>

  )
}
