import React, {useEffect, useRef, useState} from "react"
import BaseButton from "../../../components/common/Button/BaseButton";
import {createColumnHelper} from "@tanstack/react-table";
import DataTable from "../../../components/common/Table/DataTable";
import CheckboxList from "../../../components/common/form/checkbox/CheckboxList";
import SingleSimpleValueSelect from "../../../components/common/form/select/SingleSimpleValueSelect";
import {declOfNum} from "../../../libs/helpers/words";
import Loader from "../../../components/common/Loaders/Loader";
import {apiClient} from "../../../libs/api/apiClient";
import {useCompaniesContext} from "../../../contexts/Companies";
import {useBranchesContext} from "../../../contexts/Branches";
import {showErrorToast, showSuccessToast} from "../../../libs/helpers/toasts";
// @ts-ignore
import applyImg from "../../../images/apply.svg"
import Checkbox from "../../../components/common/form/checkbox/Checkbox";

export default function ProductFileImport() {
  const {company} = useCompaniesContext()
  const {branch} = useBranchesContext()
  const input = useRef<any>(null);
  const [csv, setCsv] = useState<any>(null)
  const [fields, setFields] = useState<any>(['uid', 'id', 'price', 'shortDescription'])
  const [categories, setCategories] = useState<any>([])
  const [selectCategory, setSelectCategory] = useState(null)
  const [goods, setGoods] = useState<any[]>([])
  const [allMeasureUnits, setAllMeasureUnits] = useState<any>([])
  const [, setGoodsErrors] = useState<any>(null)
  const [errors, setErrors] = useState<any>(null)
  const [selectedRows, setSelectedRows] = useState<any>([])
  const [loading, setLoading] = useState<any>(false)
  const [batchId, setBatchId] = useState<any>(null)
  const [batchInfo, setBatchInfo] = useState<any>(null)
  const [loadingImport, setLoadingImport] = useState<boolean>(false);
  const [isSelectExistsCheckboxChecked, setSelectExistsCheckboxChecked] = useState(false);

  const columnHelper = createColumnHelper<any>()

  const fieldsOptions = [
    {
      label: 'Название',
      name: 'title'
    },
    {
      label: 'Артикул',
      name: 'article'
    },
    {
      label: 'Сортировка',
      name: 'position'
    },
    {
      label: 'Цена',
      name: 'price'
    },
    {
      label: 'Старая цена',
      name: 'oldPrice'
    },
    {
      label: 'Себестоимость',
      name: 'costPrice'
    },
    {
      label: 'Описание',
      name: 'shortDescription'
    },
    {
      label: 'Вес',
      name: 'weight'
    },
    {
      label: 'Обьем',
      name: 'volume'
    },
    {
      label: 'Размер',
      name: 'size'
    },
    {
      label: 'Ед. Изм.',
      name: 'measureUnit'
    },
    {
      label: 'Список меток',
      name: 'tags'
    },
    {
      label: 'Изображение',
      name: 'image'
    },
    {
      label: 'Подробное описание',
      name: 'description'
    }
  ]

  const columns = [
    columnHelper.accessor(row => row.article, {
      id: 'code',
      header: () => 'Артикул',
      cell: info => <div
        className={'w-[10%] font-normal break-all justify-center flex items-center'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[10%] flex justify-center text-center ',
      }
    }),
    columnHelper.accessor(row => row.title, {
      id: 'name',
      header: () => 'Название',
      cell: info => <div
        className={'w-[10%] font-normal break-all justify-center flex items-center'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[10%] flex justify-center text-center ',
      }
    }),
    columnHelper.accessor(row => row.price, {
      id: 'price',
      header: () => 'Цена',
      cell: info => <div
        className={'w-[10%] font-normal break-all justify-center flex items-center'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[10%] flex justify-center text-center ',
      }
    }),
    columnHelper.accessor(row => row.oldPrice, {
      id: 'old_price',
      header: () => 'Старая цена',
      cell: info => <div
        className={'w-[10%] font-normal break-all justify-center flex items-center'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[10%] flex justify-center text-center ',
      }
    }),
    columnHelper.accessor(row => row.costPrice, {
      id: 'cost_price',
      header: () => 'Себестоимость',
      cell: info => <div
        className={'w-[10%] font-normal break-all justify-center flex items-center '}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[10%] flex justify-center text-center ',
      }
    }),
    columnHelper.accessor(row => row.weight, {
      id: 'weight',
      header: () => 'Вес',
      cell: info => <div
        className={'w-[5%] font-normal break-all justify-center flex items-center'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[5%] flex justify-center text-center ',
      }
    }),
    columnHelper.accessor(row => row.volume, {
      id: 'volume',
      header: () => 'Объем',
      cell: info => <div
        className={'w-[5%] font-normal break-all justify-center flex items-center'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[5%] flex justify-center text-center ',
      }
    }),
    columnHelper.accessor(row => row.position, {
      id: 'sort',
      header: () => 'Сортировка',
      cell: info => <div
        className={'w-[10%] font-normal break-all justify-center flex items-center'}>{info.getValue() || '-'}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[10%] flex justify-center text-center ',
      }
    }),
    columnHelper.accessor(row => row.measureUnit, {
      id: 'unit',
      header: () => 'Ед. Изм.',
      cell: info => <div
        className={'w-[5%] font-normal break-all justify-center flex items-center'}>{allMeasureUnits.find(({value}: any) => value == info.getValue())?.title || '-'}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[5%] flex justify-center text-center ',
      }
    }),
    columnHelper.accessor(row => row.tags, {
      id: 'labels',
      header: () => 'Список меток',
      cell: info => <div
        className={'w-[10%] font-normal break-all justify-center flex items-center'}>{company?.tags?.filter((tag: any) => info.getValue() && info.getValue()?.includes(tag.code)).map(({text}: any) => (text)).join(', ') || '-'}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[10%] flex justify-center text-center ',
      }
    }),
    columnHelper.accessor(row => row.exists, {
      id: 'exists',
      header: () => (<Checkbox label={"Наличие в CRM"} name={'isSelectExistsCheckboxChecked'} onChange={(checked: boolean) => setSelectExistsCheckboxChecked(checked)} value={isSelectExistsCheckboxChecked}/>),
      cell: info => <div
        className={'w-[10%] font-normal text-center justify-center items-center break-all flex '}>{info.getValue() && (
        <img src={applyImg} alt=""/>)
      }</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[10%] flex justify-center items-center text-center',
      }
    }),
  ]

  const handleClick = () => {
    input.current.click();
  }

  useEffect(() => {
    apiClient.dictionaries.measureUnits().then(({ data }: any) => {
      setAllMeasureUnits(data);
    })
  }, [])

  const handleInput = () => {
    //send to backend
    if (company && branch) {
      if (!csv)
        return

      const formData = new FormData()
      formData.append('file', csv)
      setLoading(true)

      apiClient.import.goods.csvParse(company.id, branch.id, {body: formData})
        .then(({data, message}: any) => {
          if (data) {
            const {goods, categories, errors, goods_errors} = data
            setCategories(categories)
            setGoods(goods)
            setGoodsErrors(goods_errors)
            setErrors(errors)
          } else {
            showErrorToast({
              content: message || 'Что-то пошло не так'
            })
            setCsv(null)
          }
        })
        .catch((e) => {
          showErrorToast({
            content: e || 'Что-то пошло не так'
          })
          setCsv(null)
        })
        .finally(() => setLoading(false))
    } else {

    }
  }

  useEffect(() => {
    handleInput()
    setBatchId(null)
    setBatchInfo(null)
  }, [csv])

  useEffect(() => {
    setSelectedRows([])
    setSelectCategory(null)
    setErrors(null)
  }, [goods]);

  useEffect(() => {
    if (isSelectExistsCheckboxChecked) {
      setSelectedRows(goods.filter((good) => good.exists).map((good) => good.uid));

      return;
    }

    setSelectedRows([]);
  }, [goods, isSelectExistsCheckboxChecked]);

  const handleSubmit = () => {
    if (company && branch) {
      const filteredGoods = goods.filter((item: any) => selectedRows.includes(item?.uid)).map((item: any) => {
        return Object.keys(item)
          .filter(key => fields.includes(key))
          .reduce((obj, key) => {
            obj[key] = item[key];
            return obj;
          }, {})
      })
      setErrors(null)

      apiClient.import.goods.start(company.id, branch.id, {body: {goods: filteredGoods, category_id: selectCategory}}).then(({data, errors, message}: any) => {
        if (errors || message) {
          showErrorToast({
            content: message || "Произошла ошибка при выполнении импорта"
          })
        } else if (data) {
          setLoadingImport(true)
          setBatchId(data?.batchId)
          return
        }
      }).catch((reason) => {
        showErrorToast({
          content: reason || "Произошла ошибка при выполнении импорта"
        })
      })

    }
  }

  useEffect(() => {
    if (batchId) {
      const interval = setInterval(checkImportInfo, 3000)

      return () => clearInterval(interval)
    }

    return
  }, [batchId])

  const checkImportInfo = () => {
    setLoadingImport(true)
    apiClient.import.goods.checkProgress(company.id, branch.id, batchId).then(({data}: any) => {
      if (data.done ||
        data?.finishedAt ||
        data?.progress == 100) {
        setLoadingImport(false)
        setBatchId(null)
        showSuccessToast({
          content: 'Импорт завершен!'
        })
      }
      setBatchInfo(data)

      if ('errors' in data && data.errors instanceof Object && Object.keys(data.errors).length > 0) {
        setErrors(data?.errors)
      }

    }).catch((reason: any) => {
      console.log(reason)
    })
  }

  const renderInfo = () => {

    return (
      <div className={`flex flex-col gap-y-2`}>
        {
          loadingImport && <Loader title={'Выполняется импорт, пожалуйста подождите...'} />
        }
        <div>Всего записей для импорта: {batchInfo?.totalJobs}</div>
        <div>Обработано записей: {batchInfo?.processedJobs}</div>
        <div>Пропущено записей: {batchInfo?.skipped}</div>
        <div>Количество ошибок: {batchInfo?.failed}</div>
        {
          batchInfo?.failed > 0 && errors && Object.keys(errors).length > 0 &&
          <div className={`text-xs pl-4`}>
            {
              Object.entries(errors).map(([key, value]: any) => {
                const goodIndex = goods.findIndex((good: any) => good?.uid == key)
                
                if (goodIndex < 0) return <></>

                return (
                  <div className={`flex flex-col gap-y-2`}>
                    {
                      value && value instanceof Array && value.map((message: any) => (
                        <div className={'text-error-main'}>Ошибка в строке {goodIndex + 1}: {message}</div>
                      ))
                    }
                  </div>
                )
              })
            }
          </div>
        }
        <div>Обновлено записей: {batchInfo?.updated}</div>
        <div>Добавлено записей: {batchInfo?.added}</div>
      </div>
    )
  }

  return (
    <div className="px-8 pb-8 pt-2">
      <div className="flex flex-col">
        <div className="flex justify-between items-end">
          <div>
            <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Каталог</div>
            <div className="text-gray-40 text-base">Импорт из CSV</div>
          </div>
        </div>

      </div>
      <div className={'mt-8 bg-white p-4 rounded-2xl flex flex-col gap-y-4'}>
        <div className={'flex flex-row justify-between items-center'}>
          <div className={'flex flex-row gap-x-4 items-center'}>
            <span className={'font-bold'}>Импорт из файла:</span>
            <span className={'text-gray-20'}>{input?.current?.files[0]?.name || ''}</span>
          </div>

          <div>
            <BaseButton className={`ml-5 py-[7px] ${csv ? 'text-interactive-elem bg-white border-[1px] border-interactive-elem' : 'bg-interactive-elem text-white'}`} onClick={() => {
              handleClick()
            }}>Загрузить CSV</BaseButton>
          </div>
        </div>
        {
          batchInfo && renderInfo()
        }
        {
          loading ? <Loader title={'Идет загрузка данных...'}/> : <DataTable
            classNameTable={'border-[1px] border-gray-20 rounded-2xl p-3 mb-[20px] !pt-0'}
            data={goods || []}
            columns={columns}
            usePagination={false}
            useMassAction={true}
            selectedRows={selectedRows}
            onChangeSelectionRows={(values: any) => setSelectedRows(values)}
            updateData={(params: any) => {}}
            rowIdentifierField={'uid'}
            fixedHeader={'sticky top-0 bg-white'}
            rowsWithCustomBg={goods.filter((good: any) => errors && Object.keys(errors).includes(good.uid)).map(({uid}: any) => ({id: uid, color: 'bg-error-main/[.5]'}))}
          />
        }
        {
          selectedRows.length > 0 &&
            <div className={'flex flex-col gap-y-4'}>
              <div className={'border-[1px] border-gray-20 p-3 rounded-2xl'}>
                <CheckboxList
                    values={fields || []}
                    options={fieldsOptions}
                    classNameWrapper={'flex-row gap-y-4 flex-wrap'}
                    classNameCheckbox={'w-2/12'}
                    onChange={(values: any) => setFields(values)}
                    name={'fields'}
                    label={undefined}
                />
              </div>
              <div className={'flex flex-row justify-between items-end'}>
                  <div className={'w-[60%]'}>
                    <SingleSimpleValueSelect
                        label={'Раздел'}
                        name={'category'}
                        options={categories.map(({id, title}: any) => ({label: title, value: id}))}
                        onChange={(value: any) => {
                          setSelectCategory(value)
                        }}
                        value={selectCategory}
                        errors={{}}
                        textTooltip={'Раздел'}
                    />
                  </div>
                  <BaseButton disabled={loadingImport} className={`py-[10px] px-[14px] h-fit bg-interactive-elem text-white border-[1px] border-interactive-elem ${loadingImport && 'bg-interactive-text w-[200px]'}`} onClick={() => {
                    handleSubmit()
                  }}>{loadingImport ? <Loader title={''} className={''} SVGClassName={'!text-white'}/> : `Сохранить ${selectedRows.length} ${declOfNum(selectedRows.length, ['товар', 'товара', 'товаров'])}`}</BaseButton>
              </div>
            </div>
        }
      </div>
      <input className="invisible absolute w-0"
             ref={input}
             onChange={(e: any) => {
               setCsv(e?.target?.files[0])
             }}
             type="file"
             name="csvInput"
             accept=".csv"
             multiple={false}
      />
    </div>
  )
}
