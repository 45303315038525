import React, {useEffect, useState} from "react"
import BaseButton from "../../../components/common/Button/BaseButton";
import {createColumnHelper} from "@tanstack/react-table";
import DataTable from "../../../components/common/Table/DataTable";
import CheckboxList from "../../../components/common/form/checkbox/CheckboxList";
import SingleSimpleValueSelect from "../../../components/common/form/select/SingleSimpleValueSelect";
import {declOfNum} from "../../../libs/helpers/words";
import Loader from "../../../components/common/Loaders/Loader";
import {apiClient} from "../../../libs/api/apiClient";
import {useCompaniesContext} from "../../../contexts/Companies";
import {useBranchesContext} from "../../../contexts/Branches";
import {showErrorToast, showSuccessToast} from "../../../libs/helpers/toasts";
// @ts-ignore
import applyImg from "../../../images/apply.svg"
import Checkbox from "../../../components/common/form/checkbox/Checkbox";

export default function ProductVkImport() {
  const {company} = useCompaniesContext()
  const {branch} = useBranchesContext()
  const [csv, setCsv] = useState<any>(null)
  const [fields, setFields] = useState<any>(['uid', 'id' , 'price', 'description', 'title', 'article'])
  const [categories, setCategories] = useState<any>([])
  const [selectCategory, setSelectCategory] = useState(null)
  const [albums, setAlbums] = useState<any>([])
  const [selectAlbum, setSelectAlbum] = useState<any>(null)
  const [goods, setGoods] = useState<any[]>([])
  const [errors, setErrors] = useState<any>(null)
  const [selectedRows, setSelectedRows] = useState<any>([])
  const [loading, setLoading] = useState<any>(false)
  const [batchId, setBatchId] = useState<any>(null)
  const [batchInfo, setBatchInfo] = useState<any>(null)
  const [loadingImport, setLoadingImport] = useState<boolean>(false)
  const [groups, setGroups] = useState<any>([])
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null)
  const [loadingUpdateVkGroups, setLoadingUpdateVkGroups] = useState(false);

  const [isSelectExistsCheckboxChecked, setSelectExistsCheckboxChecked] = useState(false);

  const columnHelper = createColumnHelper<any>()

  const fieldsOptions = [
    {
      label: 'Название',
      name: 'title'
    },
    {
      label: 'Артикул',
      name: 'article'
    },
    {
      label: 'Цена',
      name: 'price'
    },
    {
      label: 'Старая цена',
      name: 'oldPrice'
    },
    {
      label: 'Описание',
      name: 'description'
    },
    {
      label: 'Основное фото',
      name: 'image'
    },
    {
      label: 'Доп. фотографии',
      name: 'images'
    }
  ]

  const columns = [
    columnHelper.accessor(row => row.article, {
      id: 'article',
      header: () => 'Артикул',
      cell: info => <div
        className={'w-[10%] font-normal break-all justify-center flex items-center'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[10%] flex justify-center text-center ',
      }
    }),
    columnHelper.accessor(row => row.image, {
        id: 'image',
        header: () => 'Изображение',
        cell: info => <div className={'w-[10%] font-normal break-all justify-center flex items-center'}>{info.getValue()? 
          <img src={info.getValue()} alt="" className="w-[76px] h-[76px] m-3 rounded-[12px]"/>
          :
          <div className="flex items-center justify-center text-[10px] text-gray-40 min-w-[76px] max-w-[76px] h-[76px] border-[1.5px] border-btn-secondary rounded-xl ">
            Image
          </div>
        }</div>,
        enableSorting: false,
        meta: {
          widthClass: 'w-[10%] flex justify-center text-center ',
        }
      }),
    columnHelper.accessor(row => row.title, {
      id: 'title',
      header: () => 'Название',
      cell: info => <div
        className={'w-[25%] font-normal break-all justify-center flex items-center'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[25%] flex justify-center text-center '
      }
    }),
   
    columnHelper.accessor(row => row.description, {
      id: 'description',
      header: () => 'Описание',
      cell: info => <div
        className={'w-[25%] font-normal py-2 break-all justify-center flex items-center '}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[25%] flex justify-center text-center '
      }
    }),
    columnHelper.accessor(row => row.oldPrice, {
        id: 'old_price',
        header: () => 'Старая цена',
        cell: info => <div
          className={'w-[15%] font-normal break-all justify-center flex items-center'}>{info.getValue()}</div>,
        enableSorting: false,
        meta: {
          widthClass: 'w-[15%] flex justify-center text-center ',
        }
      }),
    columnHelper.accessor(row => row.price, {
        id: 'price',
        header: () => 'Цена',
        cell: info => <div
          className={'w-[7%] font-normal break-all justify-center flex items-center'}>{info.getValue()}</div>,
        enableSorting: false,
        meta: {
          widthClass: 'w-[7%] flex justify-center text-center ',
        }
      }),
 
    columnHelper.accessor(row => row.exists, {
      id: 'exists',
      header: () => (<Checkbox label={"Наличие в CRM"} name={'isSelectExistsCheckboxChecked'} onChange={(checked: boolean) => setSelectExistsCheckboxChecked(checked)} value={isSelectExistsCheckboxChecked}/>),
      cell: info => <div
        className={'w-[20%] font-normal text-center justify-center items-center break-all flex '}>{info.getValue() && (
        <img src={applyImg} alt=""/>)
      }</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[20%] flex justify-center items-center text-center',
      }
    }),
  ]
  
  useEffect(() => {
    if(company && branch){
      apiClient.import.goods.getVkGroups(company.id, branch.id).then(({data, message, errors}: any) => {
        if (message || errors) {
          showErrorToast({
            content: message || 'Произошла ошибка при получении групп'
          })
          return
        }
        setGroups(data)
        const selectedGroup = data.find(group => group.id)
        if(selectedGroup) {
          setSelectedGroup(selectedGroup.id)
          getProductsVkGroup(selectedGroup.id)
        }
      }).catch((reason) => {
        showErrorToast({
          content: reason || 'Произошла ошибка при получении групп'
        })
      })
    }
  }, [company, branch])

  const updateProductsVk = () => {
    if(company && branch && selectedGroup){
      setLoadingUpdateVkGroups(true)
      apiClient.import.goods.refreshVkGoods(company.id, branch.id, {body: {group_id: selectedGroup}})
        .then(data => {
          setLoadingUpdateVkGroups(false)  
          showSuccessToast({
            content: 'Запрос на обновление успешно отправлен!'
          })
        })
        .catch(err => showErrorToast({
          content: err || 'Ошибка при отправке запроса'
        }))
    }
  }

  const getProductsVkGroup = (groupId: number | string, album: string = '') => {
    setLoading(true)
    if(company && branch && groupId){
      apiClient.import.goods.getVkGroupGood(company.id, branch.id, groupId, album ? `?albums[]=${album}` : '')
        .then(({data, message, errors}: any)=> {
          if (message || errors) {
            showErrorToast({
              content: message || 'Произошла ошибка при получении товаров'
            })
            return
          }
          setAlbums(data.albums)
          setGoods(data.goods)
          setCategories(data.categories)
          setLoading(false)
        })
        .catch(err => showErrorToast({
          content: err || 'Произошла ошибка при получении товаров'
        }))
    }
  }

  useEffect(() => {
    setBatchId(null)
    setBatchInfo(null)
  }, [csv])

  useEffect(() => {
    setSelectedRows([])
    setSelectCategory(null)
    setErrors(null)
  }, [goods]);

  useEffect(() => {
    if (isSelectExistsCheckboxChecked) {
      setSelectedRows(goods.filter((good) => good.exists).map((good) => good.uid));

      return;
    }

    setSelectedRows([]);
  }, [goods, isSelectExistsCheckboxChecked]);

  const handleSubmit = () => {
    if (company && branch) {
      const filteredGoods = goods.filter((item: any) => selectedRows.includes(item?.uid)).map((item: any) => {
        return Object.keys(item)
          .filter(key => fields.includes(key))
          .reduce((obj, key) => {
            obj[key] = item[key];
            return obj;
          }, {})
      })

      apiClient.import.goods.start(company.id, branch.id, {body: {goods: filteredGoods, category_id: selectCategory}}).then(({data, errors, message}: any) => {
        if (errors || message) {
          showErrorToast({
            content: message || "Произошла ошибка при выполнении импорта"
          })
        } else if (data) {
          setLoadingImport(true)
          setBatchId(data?.batchId)
          return
        }
      }).catch((reason) => {
        showErrorToast({
          content: reason || "Произошла ошибка при выполнении импорта"
        })
      })

    }
  }

  useEffect(() => {
    if (batchId) {
      const interval = setInterval(checkImportInfo, 3000)

      return () => clearInterval(interval)
    }

    return
  }, [batchId])

  const checkImportInfo = () => {
    setLoadingImport(true)
    apiClient.import.goods.checkProgress(company.id, branch.id, batchId).then(({data}: any) => {
      if (data.done ||
        data?.finishedAt ||
        data?.progress == 100) {
        setLoadingImport(false)
        setBatchId(null)
        showSuccessToast({
          content: 'Импорт завершен!'
        })
      }
      setBatchInfo(data)

      if ('errors' in data && data.errors instanceof Object && Object.keys(data.errors).length > 0) {
        setErrors(data?.errors)
      }

    }).catch((reason: any) => {
      console.log(reason)
    })
  }

  const renderInfo = () => {

    return (
      <div className={`flex flex-col gap-y-2`}>
        {
          loadingImport && <Loader title={'Выполняется импорт, пожалуйста подождите...'} />
        }
        <div>Всего записей для импорта: {batchInfo?.totalJobs}</div>
        <div>Обработано записей: {batchInfo?.processedJobs}</div>
        <div>Пропущено записей: {batchInfo?.skipped}</div>
        <div>Количество ошибок: {batchInfo?.failed}</div>
        <div>Обновлено записей: {batchInfo?.updated}</div>
        <div>Добавлено записей: {batchInfo?.added}</div>
      </div>
    )
  }

  return (
    <div className="px-8 pb-8 pt-2">
      <div className="flex flex-col">
        <div className="flex items-end justify-between">
          <div>
            <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Каталог</div>
            <div className="text-gray-40 text-base">Импорт из Сообщества ВКонтакте</div>
          
          </div>
          <div className="flex flex-row items-end">
            {
              !!albums.length && 
              <div className="ml-5 flex flex-row items-center">
                <div className="text-gray-40 text-base font-bold mr-3">Альбом</div>
                <SingleSimpleValueSelect
                  name={'xs'}
                  placeholder={'Выберите альбом'}
                  value={selectAlbum || ''}
                  options={[{label: 'Все', value: ''}, ...albums?.map((album) => ({label: album, value: album}))]}
                  onChange={album => {
                    setSelectAlbum(album)
                    if (album && selectedGroup) {
                      getProductsVkGroup(selectedGroup, album)
                    }
                  }}
                />
              </div>
            }

            <div className="ml-5 flex flex-row items-center">
              <div className="text-gray-40 text-base font-bold mr-3">Группа</div>
              <SingleSimpleValueSelect
                disabled={!groups.length}
                name={'xs'}
                placeholder={'Выберите группу'}
                value={selectedGroup || ''}
                options={groups?.map((group) => {
                  if (group.id) return {label: group.name, value: group.id}
                })}
                onChange={option => {
                  setSelectAlbum('')
                  setSelectedGroup(option)
                  if(option){
                    getProductsVkGroup(option)
                  }
                }}
              />
            </div>
            <div>
            <BaseButton className={`ml-5 min-w-[162px] py-3 bg-interactive-elem text-white`} onClick={updateProductsVk}>
              {loadingUpdateVkGroups ? <Loader title={''} className={''} SVGClassName={'!text-white'}/> : `Обновить товары`}
            </BaseButton>
            </div>
          </div>
         
         
        </div>

      </div>
      <div className={'mt-8 bg-white p-4 rounded-2xl flex flex-col gap-y-4'}>
        {
          batchInfo && renderInfo()
        }
        {
          loading ? <Loader title={'Идет загрузка данных...'}/> : <DataTable
            classNameTable={'border-[1px] border-gray-20 rounded-2xl p-3 mb-[20px] !pt-0'}
            data={goods || []}
            columns={columns}
            usePagination={false}
            useMassAction={true}
            selectedRows={selectedRows}
            onChangeSelectionRows={(values: any) => setSelectedRows(values)}
            updateData={(params: any) => {}}
            rowIdentifierField={'uid'}
            fixedHeader={'sticky top-0 bg-white'}
            rowsWithCustomBg={goods.filter((good: any) => errors && Object.keys(errors).includes(good.uid)).map(({uid}: any) => ({id: uid, color: 'bg-error-main/[.5]'}))}
          />
        }
        {
          selectedRows.length > 0 &&
            <div className={'flex flex-col gap-y-4'}>
              <div className={'border-[1px] border-gray-20 p-3 rounded-2xl'}>
                <CheckboxList
                    values={fields || []}
                    options={fieldsOptions}
                    classNameWrapper={'flex-row gap-y-4 flex-wrap'}
                    classNameCheckbox={'w-2/12'}
                    onChange={(values: any) => setFields(values)}
                    name={'fields'}
                    showLabel={false}
                    label={undefined}
                />
              </div>
              <div className={'flex flex-row justify-between items-end'}>
                  <div className={'w-[60%]'}>
                    <SingleSimpleValueSelect
                        label={'Раздел'}
                        name={'category'}
                        placeholder={'Выберите раздел'}
                        options={categories.map(({id, title}: any) => ({label: title, value: id}))}
                        onChange={(value: any) => {
                          setSelectCategory(value)
                        }}
                        value={selectCategory}
                        errors={{}}
                        textTooltip={'Раздел'}
                    />
                  </div>
                  <BaseButton disabled={loadingImport} className={`py-[10px] px-[14px] h-fit bg-interactive-elem text-white border-[1px] border-interactive-elem ${loadingImport && 'bg-interactive-text w-[200px]'}`} onClick={() => {
                    handleSubmit()
                  }}>{loadingImport ? <Loader title={''} className={''} SVGClassName={'!text-white'}/> : `Сохранить ${selectedRows.length} ${declOfNum(selectedRows.length, ['товар', 'товара', 'товаров'])}`}</BaseButton>
              </div>
            </div>
        }
      </div>
      {/* <input className="invisible absolute w-0"
             ref={input}
             onChange={(e: any) => {
               setCsv(e?.target?.files[0])
             }}
             type="file"
             name="csvInput"
             accept=".csv"
             multiple={false}
      /> */}
    </div>
  )
}
