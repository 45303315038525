import React, {useEffect} from "react";
import DataTable from "../../../components/common/Table/DataTable";
import {createColumnHelper} from "@tanstack/react-table";
import {useOptionsContext} from "../../../contexts/Options";
import {Link} from "react-router-dom";

export default function TableOptions({selected, setSelected, massUpdate, setMassUpdate}: any) {
  const {options, updateOptions, limits, filters, loading, pagination}: any = useOptionsContext();

  useEffect(() => {
    if (massUpdate) {
      updateOptions(limits, filters)
      setMassUpdate(false)
    }
  }, [massUpdate])

  const columnHelper = createColumnHelper<any>()

  const columns = [
    columnHelper.accessor(row => row.id, {
      id: 'id',
      header: () => 'ID',
      cell: info => <div
        className={'w-[70px] font-normal break-all flex items-center mx-3'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[70px] flex justify-start text-center mx-3',
      }
    }),
    columnHelper.accessor(row => row.article, {
      id: 'article',
      header: () => 'Артикул',
      cell: info => <div
        className={'w-[110px] font-normal break-all flex items-center mr-3'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[110px] flex justify-start text-center mr-3',
        sortField: 'article'
      }
    }),
    columnHelper.accessor(row => row.title, {
      id: 'title',
      header: () => 'Наименование',
      cell: info => <div
        className={'w-1/3 font-normal break-all flex items-center mr-3 text-interactive-text'}>
        <Link to={info.row?.original?.id != null ? `/options/${info.row?.original?.id}/edit-option` : '/options'}>
          {info.getValue()}
        </Link>
      </div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-1/3 flex justify-start text-center mr-3',
        sortField: 'title'
      }
    }),
    columnHelper.accessor(row => row.branchesCount, {
      id: 'branchesCount',
      header: () => 'Ф',
      cell: info => <div
        className={'w-[50px] font-normal break-all flex items-center justify-center mr-3'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[50px] flex justify-center mr-3',
      }
    }),
    columnHelper.accessor(row => row.weight, {
      id: 'weight',
      header: () => 'Вес',
      cell: info => <div
        className={'min-w-[65px] font-normal flex justify-center items-center mr-3'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'min-w-[65px] flex justify-start text-center mr-3',
        sortField: 'weight'
      }
    }),
    columnHelper.accessor(row => row.price, {
      id: 'price',
      header: () => 'Цена',
      cell: info => <div
        className={'min-w-[75px] font-normal flex justify-center items-center '}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'min-w-[75px] flex justify-start text-center ',
        sortField: 'price'
      }
    }),
  ]

  return (
    <>
      <DataTable
        data={options}
        columns={columns}
        usePagination={true}
        defaultLimit={5}
        defaultPage={1}
        pagination={pagination}
        updateData={(params: any) => updateOptions(params)}
        loading={loading}
        useMassAction={true}
        selectedRows={selected}
        onChangeSelectionRows={(values: any) => setSelected(values)}
        classNameTable={'mt-0'}
        rowsWithCustomBg={options.filter((option: any) => !option.isActive).map(({id}: any) => ({id, color: 'bg-error-main/[.5]'}))}
      />
    </>
  )
}
