import React from "react";
import TextInput from "../form/input/TextInput";


export default function EditableTextInput({value, onChange, ...props}: any) {
  return <TextInput
    value={value}
    onBlur={(v: any) => onChange(v)}
    {...props}
  />
}
