import React, { useState } from 'react'
import TextInput from './../../../components/common/form/input/TextInput';
import ChevronIcon from '../../../components/icons/ChevronIcon';
import TextInputMultipleList from '../../../components/common/form/input/TextInputMultipleList';

export default function Senler({data, updateField, errors}: any) {
  const [show, setShow] = useState(true)
  const [initialKey, setInitialKey] = useState(data.integrations.senler?.key)

  const updateSenler = (name: any, value: any) => {
    updateField(`integrations.senler.${name}`, value)

    // Костыль. Объект senler обновляется полностью, кроме ключа
    if (!data.integrations?.senler?.idsOfGroupsWhoUsePromocodes) {
      updateField('integrations.senler.idsOfGroupsWhoUsePromocodes', [])
    }

    if (!data.integrations?.senler?.idsOfGroupsWhoOpenCatalog) {
      updateField('integrations.senler.idsOfGroupsWhoOpenCatalog', [])
    }

    if (!data.integrations?.senler?.idsOfGroupsWhoIseFilterByTag) {
      updateField('integrations.senler.idsOfGroupsWhoIseFilterByTag', [])
    }

    if (!data.integrations?.senler?.idOfGroupsWhoOpenVkApp) {
      updateField('integrations.senler.idOfGroupsWhoOpenVkApp', '')
    }

    if (!data.integrations?.senler?.idOfGroupsWhoMakeOrder) {
      updateField('integrations.senler.idOfGroupsWhoMakeOrder', '')
    }

    if (!data.integrations?.senler?.idOfGroupsWhoOpenSite) {
      updateField('integrations.senler.idOfGroupsWhoOpenSite', '')
    }

    // Если ключ не менялся не отправляем его, потому что он скрыт
    const update = {...data.integrations?.senler}
    if (update.key === initialKey) {
      delete update.key
    }

    updateField(`integrations.senler`, update)
  }

  return (
    <div className={`${show ? 'h-auto' : 'h-[65px] overflow-hidden'}  border-[1px] border-gray-20 rounded-lg`}>
      <div onClick={() => setShow(!show)} className='flex items-center justify-between cursor-pointer p-4'>
        <div className='text-[22px] font-semibold'>Senler</div>
        <ChevronIcon className={`${show && 'rotate-180'}`}/>
      </div>
      <div className='p-4'>
        <div className='flex flex-col gap-4'>
          <TextInput
            label='Ключ из Callback API'
            textTooltip='Ключ из Callback API'
            errors={errors}
            placeholder='Текстовое поле'
            name={'integrations.senler.key'}
            value={initialKey}
            onChange={(value: any) => updateSenler('key', value)}
            initialValueReplaceOnly={true}
          />

          <TextInput
            label='ID группы Вконтакте'
            textTooltip='ID группы Вконтакте'
            errors={errors}
            placeholder='Текстовое поле'
            name={'integrations.senler.vkGroupId'}
            value={data.integrations.senler?.vkGroupId}
            onChange={(value: any) => updateSenler('vkGroupId', value)}
          />

          <TextInput
            label='ID группы подписчиков (Открыли приложение ВК)'
            textTooltip='ID группы подписчиков (Открыли приложение ВК)'
            errors={errors}
            placeholder='Текстовое поле'
            name={'integrations.senler.idOfGroupsWhoOpenVkApp'}
            value={data.integrations.senler?.idOfGroupsWhoOpenVkApp}
            onChange={(value: any) => updateSenler('idOfGroupsWhoOpenVkApp', value)}
          />

          <TextInput
            label='ID группы подписчиков (Оформили заказ)'
            textTooltip='ID группы подписчиков (Оформили заказ)'
            errors={errors}
            placeholder='Текстовое поле'
            name={'integrations.senler.idOfGroupsWhoMakeOrder'}
            value={data.integrations.senler?.idOfGroupsWhoMakeOrder}
            onChange={(value: any) => updateSenler('idOfGroupsWhoMakeOrder', value)}
          />

          <TextInput
            label='ID группы подписчиков (Открыли сайт)'
            textTooltip='ID группы подписчиков (Открыли сайт)'
            errors={errors}
            placeholder='Текстовое поле'
            name={'integrations.senler.idOfGroupsWhoOpenSite'}
            value={data.integrations.senler?.idOfGroupsWhoOpenSite}
            onChange={(value: any) => updateSenler('idOfGroupsWhoOpenSite', value)}
          />

          <TextInputMultipleList
            label='ID группы подписчиков (Активировали промокод)'
            textTooltip='ID группы подписчиков (Активировали промокод)'
            fields={['promocode', 'id']}
            fieldTypes={['input', 'input']}
            placeholders={['Промокод', 'ID группы']}
            name={'integrations.senler.idsOfGroupsWhoUsePromocodes'}
            values={data.integrations.senler?.idsOfGroupsWhoUsePromocodes || []}
            onChange={(value: any) => updateSenler('idsOfGroupsWhoUsePromocodes', value)}
            errors={errors}
          />

          <TextInputMultipleList
            label='ID группы подписчиков (Открыли раздел каталога)'
            textTooltip='ID группы подписчиков (Открыли раздел каталога)'
            fields={['category', 'id']}
            placeholders={['ID Раздела', 'ID группы']}
            fieldTypes={['input', 'input']}
            name={'integrations.senler.idsOfGroupsWhoOpenCatalog'}
            values={data.integrations.senler?.idsOfGroupsWhoOpenCatalog || []}
            onChange={(value: any) => updateSenler('idsOfGroupsWhoOpenCatalog', value)}
            errors={errors}
          />

          <TextInputMultipleList
            label='ID группы подписчиков (Отфильтровали товары по метке)'
            textTooltip='ID группы подписчиков (Отфильтровали товары по метке)'
            fields={['tag', 'id']}
            fieldTypes={['input', 'input']}
            placeholders={['Код метки', 'ID группы']}
            name={'integrations.senler.idsOfGroupsWhoIseFilterByTag'}
            values={data.integrations.senler?.idsOfGroupsWhoIseFilterByTag || []}
            onChange={(value: any) => updateSenler('idsOfGroupsWhoIseFilterByTag', value)}
            errors={errors}
          />

        </div>
      </div>
    </div>
  )
}
