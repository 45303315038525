import React, { useEffect } from "react";
import BaseButton from "../../components/common/Button/BaseButton";
import {createColumnHelper} from "@tanstack/react-table";
import DataTable from "../../components/common/Table/DataTable";
import Base from "./Base";
import useForm from "../../hooks/useForm";
import initForm from "./initForm";
import {useReportOrdersContext} from "../../contexts/Reports";
import {useBranchesContext} from "../../contexts/Branches";
import { ExportBlocks } from "../../components/common/Export/helpers";
import Export from "../../components/common/Export";
import { useSidebarContext } from "../../contexts/Sidebar";


export default function ReportOrders() {
  const {hiddenMode} = useSidebarContext()
  const { data, updateField, errors}: any = useForm(JSON.parse(JSON.stringify(initForm)))
  const {reports, updateReports, filters, pagination, loading, limits, updateFilters}: any = useReportOrdersContext()

  const columnHelper = createColumnHelper<any>()

  const columns = [
    columnHelper.accessor(row => row.date, {
      id: 'date',
      header: () =>
        <div className={"flex flex-col relative "}>
          <p className={"absolute my-3 bottom-5 left-0"}>Клиенты</p>
          <p>Дата</p>
        </div>,
      cell: info => <div
        className={'min-w-[150px] font-normal break-words flex justify-center items-center '}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'min-w-[150px] flex justify-center text-center',
        sortField: 'date'
      }
    }),
    columnHelper.accessor(row => row.ordersCount, {
      id: 'ordersCount',
      header: () => 'Кол-во',
      cell: info => <div
        className={'min-w-[150px] font-normal break-words flex justify-center items-center '}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'min-w-[150px] flex justify-center text-center ',
        sortField: 'ordersCount'
      }
    }),
    columnHelper.accessor(row => row.cancelledCount, {
      id: 'cancelledCount',
      header: () => 'Отмены',
      cell: info => <div
        className={'min-w-[150px] font-normal break-words flex justify-center items-center '}>{info.getValue()} ({info.row?.original?.cancelledAmount})</div>,
      enableSorting: true,
      meta: {
        widthClass: 'min-w-[150px] flex justify-center text-center ',
        sortField: 'cancelledCount'
      }
    }),
    columnHelper.accessor(row => row.totalAmount, {
      id: 'totalAmount',
      header: () => 'Сумма',
      cell: info => <div
        className={'min-w-[150px] font-normal break-words flex justify-center items-center '}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'min-w-[150px] flex justify-center text-center ',
        sortField: 'totalAmount'
      }
    }),
    columnHelper.accessor(row => row.avgTotal, {
      id: 'avgTotal',
      header: () => 'Ср. чек',
      cell: info => <div
        className={'min-w-[150px] font-normal break-words flex justify-center items-center '}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'min-w-[150px] flex justify-center text-center ',
        sortField: 'avgTotal'
      }
    }),
    columnHelper.accessor(row => row.avgCostPrice, {
      id: 'avgCostPrice',
      header: () => 'Себест.',
      cell: info => <div
        className={'min-w-[150px] font-normal break-words flex justify-center items-center '}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'min-w-[150px] flex justify-center text-center ',
        sortField: 'avgCostPrice'
      }
    }),
    columnHelper.accessor(row => row.cashAmount, {
      id: 'cashAmount',
      header: () => 'Наличными',
      cell: info => <div
        className={'min-w-[150px] font-normal break-words flex justify-center items-center '}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'min-w-[150px] flex justify-center text-center ',
        sortField: 'cashAmount'
      }
    }),
    columnHelper.accessor(row => row.onDeliveryAmount, {
      id: 'onDeliveryAmount',
      header: () => 'По картам',
      cell: info => <div
        className={'min-w-[150px] font-normal break-words flex justify-center items-center '}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'min-w-[150px] flex justify-center text-center ',
        sortField: 'onDeliveryAmount'
      }
    }),
    columnHelper.accessor(row => row.transferAmount, {
      id: 'transferAmount',
      header: () => 'Мобильный банк',
      cell: info => <div
        className={'min-w-[150px] font-normal break-words flex justify-center items-center '}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'min-w-[150px] flex justify-center text-center ',
        sortField: 'transferAmount'
      }
    }),
    columnHelper.accessor(row => row.onlinePaidAmount, {
      id: 'onlinePaidAmount',
      header: () => 'Онлайн (оплачен)',
      cell: info => <div
        className={'min-w-[150px] font-normal break-words flex justify-center items-center '}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'min-w-[150px] flex justify-center text-center ',
        sortField: 'onlinePaidAmount'
      }
    }),
    columnHelper.accessor(row => row.onlineNotPaidAmount, {
      id: 'onlineNotPaidAmount',
      header: () => 'Онлайн (не оплачен)',
      cell: info => <div
        className={'min-w-[150px] font-normal break-words flex justify-center items-center '}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'min-w-[150px] flex justify-center text-center ',
        sortField: 'onlineNotPaidAmount'
      }
    }),
    columnHelper.accessor(row => row.siteCount, {
      id: 'siteCount',
      header: () => 'Сайт',
      cell: info => <div
        className={'min-w-[150px] font-normal break-words flex justify-center items-center '}>{info.getValue()} ({info?.row?.original?.siteAmount})</div>,
      enableSorting: true,
      meta: {
        widthClass: 'min-w-[150px] flex justify-center text-center ',
        sortField: 'siteCount'
      }
    }),
    columnHelper.accessor(row => row.vkCount, {
      id: 'vkCount',
      header: () => 'Вконтакте',
      cell: info => <div
        className={'min-w-[150px] font-normal break-words flex justify-center items-center '}>{info.getValue()} ({info?.row?.original?.vkAmount})</div>,
      enableSorting: true,
      meta: {
        widthClass: 'min-w-[150px] flex justify-center text-center ',
        sortField: 'vkCount'
      }
    }),
    columnHelper.accessor(row => row.androidCount, {
      id: 'androidCount',
      header: () => 'Android',
      cell: info => <div
        className={'min-w-[150px] font-normal break-words flex justify-center items-center '}>{info.getValue()} ({info?.row?.original?.androidAmount})</div>,
      enableSorting: true,
      meta: {
        widthClass: 'min-w-[150px] flex justify-center text-center ',
        sortField: 'androidCount'
      }
    }),
    columnHelper.accessor(row => row.iosCount, {
      id: 'iosCount',
      header: () => 'IOS',
      cell: info => <div
        className={'min-w-[150px] font-normal break-words flex justify-center items-center '}>{info.getValue()} ({info?.row?.original?.iosAmount})</div>,
      enableSorting: true,
      meta: {
        widthClass: 'min-w-[150px] flex justify-center text-center ',
        sortField: 'iosCount'
      }
    }),
    columnHelper.accessor(row => row.customersCount, {
      id: 'customersCount',
      header: () => 'Клиенты',
      cell: info => <div
        className={'min-w-[150px] font-normal break-words flex justify-center items-center '}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'min-w-[150px] flex justify-center text-center ',
        sortField: 'customersCount'
      }
    }),
    columnHelper.accessor(row => row.newOrdersCount, {
      id: 'newOrdersCount',
      header: () =>
        <div className={"flex flex-col relative ml-4 mt-3"}>
          <p className={"absolute my-3 bottom-5 left-0"}>Новые клиенты</p>
          <p>Кол-во</p>
        </div>,
      cell: info => <div
        className={'min-w-[150px] font-normal break-words flex justify-center items-center '}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'min-w-[150px] flex justify-center text-center ',
        sortField: 'newOrdersCount'
      }
    }),
    columnHelper.accessor(row => row.newOrdersAmount, {
      id: 'newOrdersAmount',
      header: () => 'Сумма',
      cell: info => <div
        className={'min-w-[150px] font-normal break-words flex justify-center items-center '}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'min-w-[150px] flex justify-center text-center ',
        sortField: 'newOrdersAmount'
      }
    }),
    columnHelper.accessor(row => row.newAvgTotal, {
      id: 'newAvgTotal',
      header: () => 'Ср. чек',
      cell: info => <div
        className={'min-w-[150px] font-normal break-words flex justify-center items-center '}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'min-w-[150px] flex justify-center text-center ',
        sortField: 'newAvgTotal'
      }
    }),
    columnHelper.accessor(row => row.newAvgCostPrice, {
      id: 'newAvgCostPrice',
      header: () => 'Себест.',
      cell: info => <div
        className={'min-w-[150px] font-normal break-words flex justify-center items-center '}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'min-w-[150px] flex justify-center text-center ',
        sortField: 'newAvgCostPrice'
      }
    }),
  ]

  useEffect(() => {
    updateFilters('filter', data)
  }, [data])
  
  const {branch} = useBranchesContext()

  if (!branch) {
    return <div>
      <div className="px-8 pb-8 pt-2">
        <div className="flex flex-col justify-between">
          <div>
            <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Отчеты</div>
            <div className="text-gray-40 text-base">Статистика по заказам</div>
          </div>
          <div className="text-gray-40 text-3xl text-center mt-[35vh]">
            Выберите филиал
          </div>
        </div>
      </div>
    </div>
  }

  return (
    <div className={`px-8 pb-8 pt-2 ${hiddenMode ? 'w-[calc(99vw-60px)]' : 'w-[calc(99vw-340px)]'}`}>
      <div className="flex flex-col gap-y-6">
        <div className="flex justify-between items-end">
          <div>
            <p className="text-gray-50 text-[44px] leading-[60px] mb-2">Отчеты</p>
            <p className="text-gray-40 text-base">Статистика по заказам</p>
          </div>
        </div>
        <Base
          data={data}
          updateField={updateField}
          updateReports={updateReports}
          limits={limits}
          filters={filters}
          updateFilters={updateFilters}
          errors={errors}
        />
        <Export
          title={'Экспорт'}
          types={
            [
              { title: 'Экспорт заказов', type: ExportBlocks.reportOrders },
              { title: 'Экспорт клиентов', type: ExportBlocks.reportCustomers }
            ]
          }
          filter={filters || null}
          order={limits?.order || null}
        />
        <DataTable
          data={reports || []}
          columns={columns}
          usePagination={false}
          defaultLimit={5}
          defaultPage={1}
          defaultOrder={{date: 'desc'}}
          pagination={pagination}
          updateData={(params: any) => updateReports(params, filters)}
          loading={loading}
          classNameTable={'mt-0 overflow-x-auto'}
          classNameRow={'w-fit'}
          heightHeaderRow={'h-[130px] w-fit'}
          fixedHeader={'sticky top-0 bg-white z-[1001] justify-between'}
        />
      </div>
    </div>
  )
}
