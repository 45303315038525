import * as React from "react";

type Props = React.PropsWithChildren & React.HTMLProps<HTMLDivElement>
export default function (props: Props) {
  const {children, ...other} = props;
  
  return (
    <div className={'flex gap-2 w-full items-end'} {...other} >
      {children}
    </div>
  );
}