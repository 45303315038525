import React from "react";
import TextInput from "../../../components/common/form/input/TextInput";
import {useBranchesContext} from "../../../contexts/Branches";
import Checkbox from "../../../components/common/form/checkbox/Checkbox";
import Textarea from "../../../components/common/form/input/Textarea";
import SimpleMultipleValueSelect from "../../../components/common/form/select/multiple/SimpleMultipleValueSelect";
import ImageLoad from "../../../components/common/form/image/ImageLoad";
import {DatatableSelect} from "../../../components/common/form/select/datatable/DatatableSelect";
import {createColumnHelper} from "@tanstack/react-table";
import CheckboxList from "../../../components/common/form/checkbox/CheckboxList";
import DateTimePicker from "../../../components/common/form/datetime/DateTimePicker";
import {useCategoriesContext} from "../../../contexts/Categories";
import {useProductsContext} from "../../../contexts/Products";
import SingleSimpleValueSelect from "../../../components/common/form/select/SingleSimpleValueSelect";
import TextInputMultipleListWithRestriction
  from "../../../components/common/form/input/TextInputMultipleListWithRestriction";
import {TagsMultipleSelect} from "../../../components/common/form/select/datatable/tags/TagsMultipleSelect";
import {useCustomersTagsContext} from "../../../contexts/Tags";
import {Tag} from "../../Histories/types";
import TagLabel from "../../../components/common/form/select/datatable/tags/TagLabel";
import 'react-quill/dist/quill.snow.css';
import TextEditor from "../../../components/common/form/input/TextEditor";

type ProductType = {
  id: number,
  image: any,
  title: string|null,
  weight: any,
  price: any
}

export default function Base(props: any) {
  const {data, errors, updateField, platforms, updateFieldNoRender} = props

  const columnHelper = createColumnHelper<ProductType>()
  const columnTagHelper = createColumnHelper<Tag>()

  const {branches} = useBranchesContext()
  const { categories }: any = useCategoriesContext()
  const { products, updateFilters, filters, limits, updateProducts, loading:loadingProducts }: any = useProductsContext()
  const {customersTags, loading:loadingTags}: any = useCustomersTagsContext()

  const columnsTags = [
    columnTagHelper.accessor(row => row.id, {
      id: 'ID',
      header: () => 'ID',
      cell: info => <div className={'w-[85px] flex items-center justify-center text-center'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[85px] flex items-center justify-center text-center'
      }
    }),
    columnTagHelper.accessor(row => row.text, {
      id: 'title',
      header: () => 'Метки (Теги)',
      cell: info =>
        <TagLabel
          img={info?.row?.original?.icon || ''}
          textColor={info.row?.original?.color || ''}
          backgroundColor={info.row?.original?.background || ''}>
          {info.getValue()}
        </TagLabel>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[64px] ml-4 flex items-center justify-center text-center'
      }
    }),
  ]

  const columns = [
    columnHelper.accessor(row => row.id, {
      id: 'ID',
      header: () => 'ID',
      cell: info => <div className={'w-[85px] flex items-center justify-center text-center'}>{ info.getValue() }</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[85px] flex items-center justify-center text-center'
      }
    }),
    columnHelper.accessor(row => row.image, {
      id: 'image',
      header: () => 'Фото',
      cell: info =>
        <div className={'mr-4 w-[64px] flex items-center justify-center text-center'}>
          {
            info.getValue() ?
              <img src={info.getValue()} alt="" />:
              <div className='h-[60px] w-[60px] flex items-center justify-center text-[10px] text-gray-40 border-[1.5px] border-btn-secondary rounded-xl'>Image</div>
          }
        </div>,
      enableSorting: false,
      meta: {
        widthClass: 'mr-4 w-[64px] flex items-center justify-center text-center'
      }
    }),
    columnHelper.accessor(row => row.title, {
      id: 'title',
      header: () => 'Название',
      cell: info =>
        <div className={'grow flex items-center'}>
          { info.getValue() }
        </div>,
      enableSorting: true,
      meta: {
        widthClass: 'grow',
        sortField: 'title'
      }
    }),
    columnHelper.accessor(row => row.weight, {
      id: 'weight',
      header: () => 'Вес',
      cell: info => <div className={'max-w-[48px] grow flex items-center justify-center'}>{ info.getValue() }</div>,
      enableSorting: true,
      meta: {
        widthClass: 'max-w-[48px] grow justify-center',
        sortField: 'weight'
      }
    }),
    columnHelper.accessor(row => row.price, {
      id: 'price',
      header: () => 'Цена',
      cell: info => <div className={'max-w-[75px] grow flex items-center justify-center'}>{ info.getValue() }</div>,
      enableSorting: true,
      meta: {
        widthClass: 'max-w-[75px] grow justify-center',
        sortField: 'price'
      }
    }),
  ]

  return (
    <div className={` flex flex-col px-3 gap-y-6`}>
      <Checkbox
        label={'Активность акции'}
        name={'active'}
        onChange={(value: any) => updateField('active', value)}
        value={data.active}
        errors={errors}
      />
      <TextInput
        label='Заголовок'
        textTooltip='Заголовок'
        placeholder='Введите заголовок'
        name={'title'}
        value={data.title}
        onChange={(value: any) => updateFieldNoRender('title', value)}
        errors={errors}
        required={true}
      />
      <Textarea
        label='Краткое описание'
        textTooltip='Краткое описание'
        placeholder='Введите краткое описание'
        name={'shortDescription'}
        value={data.shortDescription}
        onChange={value => updateFieldNoRender('shortDescription', value.trim().length !== 0 ? value : '')}
        errors={errors}
        required={false}
        maxLength={undefined}
      />
      <TextEditor
        label="Подробное описание"
        textTooltip="Введите подробное описание"
        placeholder="Подробное описание"
        value={data.description}
        onChange={(value: any) => updateFieldNoRender('description', value)}
      />
{/*

      <Textarea
        label='Подробное описание'
        textTooltip='Введите подробное описание'
        placeholder='Введите подробное описание'
        name={'description'}
        value={data.description || ''}
        onChange={value => updateFieldNoRender('description', value.trim().length !== 0 ? value : undefined)}
        errors={errors}
      /> */}
      <ImageLoad
        label='Основная фотография (формат: JPG, JPEG, PNG)'
        errors={errors}
        name={'image'}
        defaultValue={data.image}
        onInput={(value: any) => {
          if (value) {
            updateField('image', value)
          }
        }}
        resizeTo={[1410,800]}
      />

      <SimpleMultipleValueSelect
        label={'Филиалы'}
        textTooltip={'Филиалы'}
        options={branches.map((branch: any) => ({label: branch.title, value: branch.id}))}
        value={data.branches || []}
        onChange={(value: any) => updateField('branches', value)}
        name={'branches'}
        placeholder={'Выберите филиалы'}
        errors={errors}
      />

      {/*TODO: date picker*/}

      <div className={"flex w-full gap-x-6 border-gray-20 border-[1px] p-4 rounded-xl"}>
        <div className={"w-1/2"}>
          <DateTimePicker
            value={data.startAt || null}
            onChange={(date: Date|null) => {
              updateField('startAt', date || null)
            }}
            errors={errors}
            name={'startAt'}
            label={'Начало действия акции'}
            textTooltip={'Начало действия акции'}
            required={false}
            placeholder={'Выберите дату и время'}
          />
        </div>
        <div className={"w-1/2"}>
          <DateTimePicker
            value={data.endAt || null}
            onChange={(date: Date|null) => {
              updateField('endAt', date || null)
            }}
            errors={errors}
            name={'endAt'}
            label={'Конец действия акции'}
            textTooltip={'Конец действия акции'}
            required={false}
            placeholder={'Выберите дату и время'}
          />
        </div>
      </div>

      <div className='flex flex-col gap-4 rounded-2xl border-[1px] border-gray-20 p-4'>
        <DatatableSelect
          data={products || []}
          filteredData={products || []}
          label={'Товары'}
          textTooltip={'Товары'}
          values={data.goods}
          errors={errors}
          name={'goods'}
          placeholder={'Выберите товары'}
          removeItem={(index: any) => {
            let goods = data.goods.slice()
            goods.splice(index, 1)
            updateField('goods', goods)
          }}
          updateValues={(values: any) => updateField('goods', values)}
          updateData={(params: any) => {
            if ('search' in params) {
              updateFilters('search', params.search)
              delete params.search
            }
            updateProducts(params, filters)
          }}
          columns={columns}
          resetFilters={() => {
            updateFilters('search', null)
            updateFilters('filter', null)
            updateProducts(null, null)
            updateField('__tableCategories', '')
          }}
          loading={loadingProducts}
        >
          <SingleSimpleValueSelect
            required={false}
            placeholder={'Выберите раздел'}
            errors={{}}
            name='__tableCategories'
            options={categories.map((category: any) => ({label: category.title, value: category.id}))}
            onChange={(value: any) => {
              updateFilters('filter.categories', value)
              updateProducts(limits, filters)
              updateField('__tableCategories', value)
            }}
            removeValue={() => {
              updateFilters('filter.categories', null)
              updateProducts(limits, filters)
              updateField('__tableCategories', '')
            }}
            removeItem={true}
            value={data.__tableCategories || ''}
          />
        </DatatableSelect>
      </div>

      <TextInput
        label='Промокод'
        textTooltip='Промокод'
        placeholder='Введите промокод'
        name={'promoCode'}
        value={data.promoCode}
        onChange={(value: any) => updateFieldNoRender('promoCode', value)}
        errors={errors}
        required={false}
      />

      <TextInputMultipleListWithRestriction
        required={false}
        name={'buttons'}
        values={data.buttons || []}
        fields={['url', 'title']}
        fieldTypes={['input', 'input']}
        placeholders = {['URL', 'Текст на кнопке']}
        onChange={(value: any) => updateField('buttons', value)}
        label='Кнопки (Ссылки)'
        textTooltip='Кнопки (Ссылки)'
        errors={errors}
        lengthCounter={[false, true]}
        maxLengthInput={[null, 18]}
      />


      <TextInput
        type='number'
        min='0'
        label='Сортировка'
        textTooltip='Сортировка'
        placeholder='500'
        name={'position'}
        value={data.position}
        onChange={(value: any) => updateFieldNoRender('position', value)}
        errors={errors}
      />

      <TagsMultipleSelect
        data={customersTags?.map(({title, ...o}: any) => ({text: title, ...o})).filter(({id}: any) => !data.withoutTags?.includes(id)) || []}
        values={data.withTags}
        errors={errors}
        name={'withTags'}
        placeholder={'Выберите метки'}
        updateValues={(values: any) => {
          updateField('withTags', values)
          if (!values.length) {
            updateField('emptyWithTags', true)
          }
        }}
        updateData={() => {
        }}
        columns={columnsTags}
        buttonPlaceholder={'Изменить метки'}
        label={'Клиенты с метками'}
        textTooltip={'Клиенты с метками'}
        removeItem={(index: any) => {
          let options = data.withTags.slice()
          options.splice(index, 1)
          updateField('withTags', options)
        }}
        loading={loadingTags}
      />

      <TagsMultipleSelect
        data={customersTags?.map(({title, ...o}: any) => ({text: title, ...o})).filter(({id}: any) => !data.withTags?.includes(id)) || []}
        values={data.withoutTags}
        errors={errors}
        name={'withoutTags'}
        placeholder={'Выберите метки'}
        updateValues={(values: any) => {
          updateField('withoutTags', values)
          if (!values.length) {
            updateField('emptyWithoutTags', true)
          }
        }}
        updateData={() => {
        }}
        columns={columnsTags}
        buttonPlaceholder={'Изменить метки'}
        label={'Клиенты без меток'}
        textTooltip={'Клиенты без меток'}
        removeItem={(index: any) => {
          let options = data.withoutTags.slice()
          options.splice(index, 1)
          updateField('withoutTags', options)
        }}
        loading={loadingTags}
      />

      <CheckboxList
        label='Платформы'
        name='platforms'
        errors={errors}
        options={platforms}
        values={data.platforms || []}
        onChange={(value: any) => {
          updateField('platforms', value)
        }}
      />
    </div>
  )
}
