import * as React from "react";
import { LegacyRef, useEffect, useRef, useState } from "react";
import Input from "./Input";

export type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
  mask: string,
  onFulfilled: (value: string) => void,
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
  defaultValue?: string | undefined;
  disabled?: boolean,
}

const replaceArray = (search: string, replace: string[] | number[], subject: string) => {
  const segments = subject.split(search);

  let result = segments.shift();

  for (const segment of segments) {
    if (0 === replace.length) {
      return result;
    }

    result += (replace.shift() || search) + segment;
  }

  return result;
}

const arrayReplace = (search: string[], replace: string, subject: string) => {
  const pattern = search
    .reduce((segments, segment) => ((! segment || segments.includes(segment)) && segments) || [...segments, ...segment.split(' ')], [] as string[])
    .map((segment) => segment.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"))
    .join('|');

  return subject?.toString().replace(new RegExp(pattern), replace);
}

export default function PhoneMaskedInput(props: Props) {
  const { mask, defaultValue = '', onFulfilled, onChange, disabled = false, ...other } = props;
  const ref = useRef<HTMLInputElement>(null);
  const [chars, setChars] = useState<string[]>(arrayReplace(mask.split("_"), '', defaultValue?.toString() || '').split(''));
  const [isTouched, setTouched] = useState(false);
  const [masked, setMasked] = useState(replaceArray('_', [...chars], mask));
  const [select, setSelect] = useState<string>('');

  const handleMouseUp = () => {
    setSelect(arrayReplace(mask.split("_"), '', window.getSelection()?.toString() || ''));
    setTouched(true);
  }

  const handleBlur = () => {
    setSelect('');
  }

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if ('Backspace' === event.code) {
      setChars(state => {
        const chars = [...state];
        chars.splice(chars.length - (select.length || 1), select.length || 1);

        return chars;
      });

      setSelect('');
      return;
    }

    if (! /[0-9]/.test(event.key)) {
      return;
    }

    if (chars.length >= mask.replace(/[^_]/g, '').length) {
      event.preventDefault();
      return;
    }

    setChars(chars => [...chars, event.key]);
  }

  useEffect(() => {
    setMasked(replaceArray('_', [...chars], mask));
  }, [chars.length]);

  useEffect(() => {
    if (! isTouched) {
      return;
    }

    if (! masked) {
      return;
    }

    if (mask.length !== masked.length) {
      return;
    }

    onFulfilled(masked);
  }, [masked]);

  return (
    <Input { ...other } disabled={disabled} ref={ ref as LegacyRef<HTMLInputElement> } value={ masked } placeholder={mask} defaultValue={ defaultValue }
           max={ mask.length } onKeyUp={ handleKeyUp } onMouseUp={ handleMouseUp } onBlur={ handleBlur } onChange={onChange} />
  );
}