import ResponseType from "../../types/response";
import {checkResponse, prepareConfig} from "./helpers";
import {serialize} from "../helpers/serialize";

export async function apiRequest(path: string, {method = 'GET', query = {}, body = null, ...options} = {}) {
  const config = prepareConfig(method, options);

  // @ts-ignore
  if (body instanceof FormData) {
    delete config.headers['Content-Type'];
    config['body'] = body;
  } else {
    body && (config['body'] = JSON.stringify(body));
  }

  const url = new URL(`${process.env.REACT_APP_API_URL}${path}`)

  if (0 < Object.keys(query).length) {
    url.search = serialize(query);
  }

  const request = new Request(url.toString(), config);

  return new Promise<ResponseType|string|void>((resolve, reject) => {
    fetch(request)
      .then(async response => {
        let reason;

        if ((reason = await checkResponse(response))) {
          reject(reason);
        }

        response.clone().json()
        .then(data => resolve(data))
        .catch(() => response.text())
        .then(data => resolve(data))
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function publicApiRequest(path: string, {method = 'GET', query = {}, body = null, ...options}: any = {}) {
  const config = prepareConfig(method, options);

  // @ts-ignore
  if (body instanceof FormData) {
    delete config.headers['Content-Type'];
    config['body'] = body;
  } else {
    body && (config['body'] = JSON.stringify(body));
  }

  const url = new URL(`${process.env.REACT_APP_PUBLIC_API_URL}${path}`)

  if (0 < Object.keys(query).length) {
    url.search = serialize(query);
  }

  const request = new Request(url.toString(), config);

  return new Promise<ResponseType|string|void>((resolve, reject) => {
    fetch(request)
      .then(async response => {
        let reason;

        if ((reason = await checkResponse(response))) {
          reject(reason);
        }

        response.clone().json()
          .then(data => resolve(data))
          .catch(() => response.text())
          .then(data => resolve(data))
      })
      .catch(error => {
        reject(error)
      })
  })
}

const dictionaryCache: {[path: string]: Promise<any>} = {}
export async function dictionaryApiRequest(path: string) {
  if (dictionaryCache.hasOwnProperty(path)) {
    return dictionaryCache[path];
  } else {
    let p = apiRequest(path);
    dictionaryCache[path] = p;
    return p;
  }
}
