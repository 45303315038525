import React, {useEffect, useReducer} from "react";
import {v4 as uuid} from "uuid"
import SingleSimpleValueSelect from "../../../../../components/common/form/select/SingleSimpleValueSelect";

import { customSelectModal } from "../../../../../components/common/Modal/Funnels/CustomSelectModal";
import { useCompaniesContext } from "../../../../../contexts/Companies";
import { useBranchesContext } from "../../../../../contexts/Branches";
import { showErrorToast } from "../../../../../libs/helpers/toasts";
import ActionBlock from "../helpers/ActionBlock";

// @ts-ignore
import trashImg from "../../../../../images/delete.svg"

interface IConditionsProps {
  conditions?: any
  actions: any
  value?: object|null
  className?: string
  onChange: Function
  loading?:boolean
  errors?: any
}

interface IData {
  id: string,
  data: any,
  children: Array<IData>,
  type: string
}


export enum FieldsType {
  singleSelect = "single-select",
  dateRange = "date-range",
  date = "date",
  time = "time",
  number = "number",
  text = "text",
  multiSelect = "multiple-select",
  image = 'image',
  color = 'color',
  textarea = 'textarea',
  boolean = 'boolean',
  array = 'array',
  conditions = 'conditions'
}

export default function ActionSelect(
  {
    conditions,
    actions,
    value,
    onChange = (value: any) => {},
    errors,
  }: IConditionsProps
) {
  const {company} = useCompaniesContext()
  const {branch} = useBranchesContext()

  const [localData, setLocalData] = useReducer((state: any, updates: any) => ({...state, ...updates}), {
    id: uuid(),
    data: {
      body: {}
    },
    children: [],
    type: 'group'
  });

  useEffect(() => {
    if (! value) {
      return;
    }

    setLocalData(value)
  }, [value])

  const customUpdate = (data: IData, [after, before]: any, names = ['before', 'after']) => {
    const temp = Object.assign({}, data)
    setPropByName(temp.data.body, names[1] || 'after', after)
    setPropByName(temp.data.body, names[0] || 'before', before)
    setLocalData(localData)
    onChange(localData)
  }

  const getPropByName = (obj: object, prop: string): any => {
    if(typeof obj === 'undefined' || !prop) {
      return null
    }

    const _index = prop.indexOf('.')
    if(_index > -1) {
      return getPropByName(obj[prop.substring(0, _index)], prop.substring(_index + 1));
    }

    return obj[prop]
  }

  const setPropByName = (obj: any, prop: string, value: any): void => {
    if(typeof obj === 'undefined') {
      obj = value
      return
    }

    const _index = prop.indexOf('.')

    if(_index > -1) {
      if (typeof obj[prop.substring(0, _index)] === 'undefined') {
        obj[prop] = value
        return
      }

      return setPropByName(obj[prop.substring(0, _index)], prop.substring(_index + 1), value)
    }

    obj[prop] = value
    return
  }

  const addAction = (data: IData, type: any) => {
    let newCond: any = { id: uuid(), data: { body: {} }, type }

    if ('children' in data && data.children instanceof Array) {
      data.children.push(newCond)
    } else {
      data.children = [newCond]
    }
    setLocalData({...localData})
    onChange({...localData})
  }

  const updateField = (data: IData, value: any, field = '') => {

    const temp = Object.assign({}, data)
    setPropByName(temp.data.body, field, value)

    setLocalData({...localData})
    onChange({...localData})
  }

  const removeField = (data: IData, field = '') => {
    const temp = Object.assign({}, data)
    delete temp.data.body[field]

    setLocalData({ ...localData })
    onChange({ ...localData })
  }

  const findAndRemove = (id: string) => {
    const elemIndex = localData?.children.findIndex((data: IData) => data?.id == id)

    if (elemIndex < 0) {
      return
    }

    localData?.children?.splice(elemIndex, 1);

    setLocalData(localData)
    onChange(localData)
  }

  const showCustomSelectModal = (data: IData, name: string, currValue: any, updateField = (data: any, value: any, name: any) => {}) => {
    if (company && branch) {
      customSelectModal({
        classNameWrapper: 'max-w-[600px] min-w-[350px] w-[40%]',
        isOpen: false,
        title: 'Выбрать значения',
        showCancelButton: false,
        confirmButtonText: 'Выбрать',
        cancelButtonText: 'Вернуться',
        allowOutsideClick: false,
        value: currValue || '',
        fields: {'id': 'ID', 'code': 'Код', 'title': 'Название'},
        isMultiply: false,
        valueIdentifier: 'code',
        requestUrl: `/api/companies/${company?.id}/branches/${branch?.id}/promo-codes`
      }).then((result) => {
        if (result.isConfirmed) {
          updateField(data, result.value, name)
        }
      }).catch(() => { })
    } else {
      showErrorToast({
        content: 'Не выбрана компания или филиал'
      })
    }
  }

  return (
    <div className={`flex flex-col gap-y-4`}>
      <div className={'flex flex-col gap-y-4'}>
        <SingleSimpleValueSelect
          className={''}
          name={''}
          placeholder={'Добавить действие'}
          label={'Действия'}
          textTooltip={'Действия'}
          value={''}
          options={Object.values(actions)?.map((action: any) => ({label: action?.title, value: action?.name}))}
          onChange={(value: any) => {
            addAction(localData, value)
          }}
          howMushShowItems={5}
        />
      </div>
      {
          actions &&
          Object.values(localData).length > 0 &&
          'children' in localData &&
          localData.children instanceof Array &&
          localData?.children?.length > 0 &&
          localData.children.map((data: any) => (
              <ActionBlock key={data.id} definition={actions[data.type]} action={data} removeBlock={findAndRemove}
                           conditions={conditions} customUpdate={customUpdate} errors={errors}
                           updateField={updateField} removeField={removeField} showCustomSelectModal={showCustomSelectModal}/>))
      }
    </div>
  )
}
