import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {ToastContainer} from "react-toastify";
import { Container as ModalContainer } from 'react-modal-promise'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

document.title = process.env.REACT_APP_TITLE || 'Result Rest CRM'

root.render(
  <React.StrictMode>
    <App />
    <ToastContainer />
    <ModalContainer isAppendIntances={true}/>
  </React.StrictMode>
);

