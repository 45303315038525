import React, {useEffect, useState} from "react";
import initForm from "../initForm";
import {useNavigate, useParams} from "react-router-dom";
import {apiClient} from "../../../libs/api/apiClient";

import {useCompaniesContext} from "../../../contexts/Companies";
import useForm from "../../../hooks/useForm";

import Breadcrumbs from "../../../components/common/Breadcrumbs";
import Base from "../../DeliveryZones/CreateDeliveryZones/Base";
import BaseButton from "../../../components/common/Button/BaseButton";
import {showErrorToast, showSuccessToast} from "../../../libs/helpers/toasts";
import {dialogModal} from "../../../components/common/Modal/DialogModal";
import { useBranchesContext } from "../../../contexts/Branches";
import {useFormContext} from "../../../contexts/Form";

export default function EditDeliveryZones() {
  const {company} = useCompaniesContext()
  const {branch}: any = useBranchesContext()
  const {checkIsFormDirty, openDialog,  needUpdate, setNeedUpdate } = useFormContext()

  const [loading, setLoading] = useState(true)

  const {
    data,
    updateData,
    updateField,
    updateFieldNoRender,
    errors,
    updateErrors,
    changedFieldsRefresh,
    submit
  } = useForm(JSON.parse(JSON.stringify(initForm)))

  const navigate = useNavigate()
  const {id} = useParams<{ id?: string }>()

  useEffect(() => {
    if (company && branch) {
      setLoading(true)
      apiClient.deliveryZones.get(company.id, branch.id, id).then(({data, errors}: any) => {
        if (data) {
          for (let key in data)
            if (data[key] === null)
              delete (data[key]);
          updateData(data)
        } else {
          updateErrors(errors || {})
        }
      }).catch(() => {}).finally(() => {
        setLoading(false)
      })
    }
  }, [company?.id, branch?.id])

  const handleSubmit = () => {
    submit((form: any) => {
        apiClient.deliveryZones.update(company.id, branch.id, id, {body: form}).then(({data, errors, message}: any) => {
          if (errors || message) {
            updateErrors(errors || {})
            showErrorToast({
              content: 'Что-то пошло не так'
            })
          } else {
            changedFieldsRefresh()
            showSuccessToast({
              content: 'Сохранение прошло успешно'
            })
            navigate('/delivery-zones')
          }
        }).catch(() => { }).finally(() => setNeedUpdate(false))
      }
      , 'changed')
  }

  const removeDeliveryZone = () => {
    dialogModal({
      isOpen: false,
      title: 'Внимание!',
      text: 'Вы уверены что хотите удалить зону доставки?',
      showCancelButton: true,
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Вернуться',
    }).then((result) => {
      if (result.isConfirmed) {
        if (company) {
          apiClient.deliveryZones.remove(company?.id, branch.id, id)
            .then(() => {
              showSuccessToast({
                content: 'Зона доставки удалена успешно'
              })
              navigate('/delivery-zones')
            }).catch((err) => {
            showErrorToast({
              content: 'Что-то пошло не так'
            })
          })
        }
      }
    }).catch(() => {})
  }

  const reset = () => {
    navigate('/delivery-zones')
  }

  useEffect(() => {
    if (needUpdate) {
      handleSubmit()
    }
  }, [needUpdate])

  if (loading) return (
    <div className="text-gray-40 text-3xl text-center mt-[35vh]">
      <span>Загрузка...</span>
    </div>
  )

  return (
    <div className='px-8 pb-8 pt-2 text-gray-50'>
      <div className='mb-8'>
        <div className='text-[44px] leading-[60px] mb-2'>Филиалы</div>
        <Breadcrumbs
          links={[
            {to: `/delivery-zones`, label: 'Зоны доставки'},
            {to: '', label: 'Редактировние зоны доставки'},
          ]}
          back={`/delivery-zones`}
        />
      </div>

      <div className={'rounded-xl bg-white py-5'}>
        <Base errors={errors} data={data} updateField={updateField} updateFieldNoRender={updateFieldNoRender}/>
        <div className={'mt-6 border-gray-10 border-y-[1px] flex justify-between z-[50] w-full sticky bottom-0 bg-white'}>
          <BaseButton
            className={'w-[225px] bg-error-main text-white m-4 py-[7px] '} onClick={removeDeliveryZone}>Удалить зону доставки
          </BaseButton>
          <div className={'flex flex-row'}>
            <BaseButton
              className={'border-error-main border-2 text-error-main m-4 py-[7px] '}
              onClick={(e: any) => {
                if (checkIsFormDirty()) {
                  e.preventDefault()
                  openDialog('/delivery-zones')

                }else reset()
              }}
            >
              Отмена
            </BaseButton>
            <BaseButton
              className={'w-[225px] bg-interactive-elem text-white m-4 py-[7px] '} onClick={handleSubmit}>Сохранить зону доставки
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  )

}
