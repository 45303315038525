// 

const initForm = {
  isActive: true,
  title: '',
  position: 0,
  color: "#008800",
  points: [],
  openHours: {
    monday: {
      active: true,
      hours: ['00:00-24:00']
    },
    tuesday: {
      active: true,
      hours: ['00:00-24:00']
    },
    wednesday: {
      active: true,
      hours: ['00:00-24:00']
    },
    thursday: {
      active: true,
      hours: ['00:00-24:00']
    },
    friday: {
      active: true,
      hours: ['00:00-24:00']
    },
    saturday: {
      active: true,
      hours: ['00:00-24:00']
    },
    sunday: {
      active: true,
      hours: ['00:00-24:00']
    },
  },
  deliveryFromTotal: 0,
  freeDeliveryFromTotal: 0,
  deliveryPrice: 0,
  deliveryMaxTime: 0,
  deliveryMinTime: 0,
  preOrderOnDeliveryMinTime: 0,

  cities: [],
  __branches_select: [],
}

export default initForm
