import * as React from "react";
import { LabelHTMLAttributes } from "react";

type Props = React.PropsWithChildren & LabelHTMLAttributes<HTMLLabelElement>;

export default function InputLabel(props: Props) {
  const {children, ...other} = props;

  return (
    <label className={`${props.className || 'flex items-center mb-2 font-medium text-sm'}`} {...other} >
      {children}
    </label>
  )
}