import * as React from "react";
import { InputHTMLAttributes } from "react";
import Input from "./Input";

type Props = React.PropsWithChildren & InputHTMLAttributes<HTMLInputElement> & {
  error?: string,
}

export default function TokenInput(props: Props) {
  const {
    onSelect,
    ...other
  } = props;
  
  const [initialValue, _] = React.useState(props.defaultValue);

  return (
    <Input
      {...other}
      onSelect={ (event) => {
        const currentTarget = event.currentTarget;
        if (currentTarget.value === initialValue) {
          if (currentTarget.selectionStart !== 0 || currentTarget.selectionEnd !== currentTarget.value.length) {
            currentTarget.select();
          }
        } else {
          onSelect && onSelect(event);
        }
      } }
    />
  )
}
