export async function getBase64FromUrl(url: any, defaultType = 'image/svg') {
  const data = await fetch(url);
  const blob = await data.blob();
  return new File([blob], 'icon.svg', {
    type: data.type || defaultType,
  });
}

export function getImageUrl(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file)
    reader.onload = (event: any) => {
      const t = new Image();

      t.src = event.target.result
      t.onload = () => {
        resolve(event.target.result)
      }
      t.onerror = () => {
        reject(file.name);
      }
    }
  })
}
