import React from 'react'
import TextInput from '../../../components/common/form/input/TextInput'
import Checkbox from '../../../components/common/form/checkbox/Checkbox'


export default function Requisites({data, updateField, errors}: any) {
  return (
    <>
      <Checkbox
        label={'Скрыть реквизиты (не показывать на сайте)'}
        name={'hideDetails'}
        onChange={(value: any) => {
          updateField('hideDetails', value)
        }}
        value={data.hideDetails}
        errors={errors}
        textTooltip={'Нет информации'}
      />

      <TextInput
        label='Название компании'
        placeholder='Введите название'
        name={'companyName'}
        onChange={(value: any) => {
          updateField('companyName', value)
        }}
        value={data.companyName}
        errors={errors}
        textTooltip={'Нет информации'}
      />

      <TextInput
        label='Название компании в родительном падеже'
        placeholder='Введите название'
        name={'companyNamePlural'}
        onChange={(value: any) => {
          updateField('companyNamePlural', value)
        }}
        value={data.companyNamePlural}
        errors={errors}
        textTooltip={'Нет информации'}
      />

      <TextInput
        label='ИНН'
        placeholder='Введите ИНН'
        name={'inn'}
        onChange={(value: any) => {
          updateField('inn', value)
        }}
        value={data.inn}
        errors={errors}
        textTooltip={'12 символов'}
        maxLength={12}
        showLength={true}
      />

      <TextInput
        label='КПП'
        placeholder='Введите КПП'
        name={'kpp'}
        onChange={(value: any) => {
          updateField('kpp', value)
        }}
        value={data.kpp}
        errors={errors}
        textTooltip={'9 символов'}
        required={false}
        maxLength={9}
        showLength={true}
      />

      <TextInput
        label='ОГРН'
        placeholder='Введите ОГРН'
        name={'ogrn'}
        onChange={(value: any) => {
          updateField('ogrn', value)
        }}
        value={data.ogrn}
        errors={errors}
        textTooltip={'13 или 15 символов'}
        maxLength={15}
        showLength={true}
      />

      <TextInput
        label='БИК'
        placeholder='Введите БИК'
        name={'bik'}
        onChange={(value: any) => {
          updateField('bik', value)
        }}
        value={data.bik}
        errors={errors}
        textTooltip={'10 символов'}
        maxLength={10}
        showLength={true}
      />

      <TextInput
        label='Юридический адрес'
        placeholder='Введите юридический адрес'
        name={'address'}
        onChange={(value: any) => {
          updateField('address', value)
        }}
        value={data.address}
        errors={errors}
        textTooltip={'Нет информации'}
      />

      <TextInput
        label='Банк'
        placeholder='Введите банк'
        name={'bank'}
        onChange={(value: any) => {
          updateField('bank', value)
        }}
        value={data.bank}
        errors={errors}
        textTooltip={'Нет информации'}
      />

      <TextInput
        label='Корр. счет'
        placeholder='Введите корр. счет'
        name={'correspondentAccount'}
        onChange={(value: any) => {
          updateField('correspondentAccount', value)
        }}
        value={data.correspondentAccount}
        errors={errors}
        textTooltip={'20 символов'}
        maxLength={20}
        showLength={true}
      />

      <TextInput
        label='Расчетный счет'
        placeholder='Введите расчетный счет'
        name={'paymentAccount'}
        onChange={(value: any) => {
          updateField('paymentAccount', value)
        }}
        value={data.paymentAccount}
        errors={errors}
        textTooltip={'20 символов'}
        maxLength={20}
        showLength={true}
      />

      <TextInput
        label='Телефон'
        placeholder='Введите телефон'
        name={'phone'}
        onChange={(value: any) => {
          updateField('phone', value)
        }}
        value={data.phone}
        errors={errors}
        textTooltip={'Нет информации'}
        required={true}
      />

      <TextInput
        label='E-mail'
        placeholder='Введите e-mail'
        name={'email'}
        onChange={(value: any) => {
          updateField('email', value)
        }}
        value={data.email}
        errors={errors}
        textTooltip={'Нет информации'}
      />
    </>
  )
}
