import * as React from "react";
import { HTMLAttributes, PropsWithChildren } from "react";

type Props = PropsWithChildren & HTMLAttributes<HTMLDivElement> & {
  isSelected: boolean
}
export default function ({ children, isSelected, ...other }: Props) {
  return (
    <div
      className={ `${ isSelected && 'bg-gray-10' } rounded min-h-[32px] flex shrink-0 cursor-pointer select-none items-center px-3 transition duration-300 hover:bg-gray-10` }
      { ...other }
    >
      { children }
    </div>
  );
}