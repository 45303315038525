import React, {useState} from 'react';
import BaseButton from '../../components/common/Button/BaseButton';
import vkLogo from '../../images/vk.svg'
import useUser from '../../hooks/useUser';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../libs/auth';
import {ModalVk} from "../Login";

export const Personal = () => {
	const { user }: any = useUser()
	const navigate = useNavigate()
	const [link, setLink] = useState<string>('')
	const [isOpen, setOpen] = useState<boolean>(false)
	const [errorMessage, setErrorMessage] = useState<''>('')

	const handleLogout = () => {
		auth.logout().then(data => navigate('/'))
	}

	const loginVk = () => {
		if (user && user.token != null) {
			setOpen(true)
			setErrorMessage('')
			auth.loginVk(setLink, user.token)
				.then(()=> {setOpen(false)})
				.catch((e)=> {
						setErrorMessage(e)
				})
		}
	}

	return (
		<div className="px-8 pb-8 pt-2">
			<div className="flex flex-col">
				<div className="flex justify-between items-end">
					<div>
						<div className="text-gray-50 text-[44px] leading-[60px] mb-2">Персональная информация</div>
					</div>
				</div>
			</div>

			<div className={`flex flex-col gap-y-8 rounded-xl border-gray-20 border-[1px] px-4 pb-10`}>
				<div className={`flex flex-row justify-between items-center w-full p-4 border-b-[1px] border-gray-20`}>
					<p className={`text-xl`}>{user?.name ?? 'User'}</p>

					<div className={''}>
						<p>{user?.vk_id && `Ваш VK ID: ${user?.vk_id}`}</p>
						<p>Ваш ID: {user?.id}</p>
					</div>
				</div>

				<div className={`flex flex-row justify-between items-center w-full`}>
					<BaseButton onClick={()=>loginVk()} className={`flex items-center gap-x-2 border-[1px] text-interactive-text border-btn-secondary py-[7px] px-[22px]`}>
						<img src={vkLogo} alt="" />
						Обновить доступ
					</BaseButton>

					<BaseButton onClick={handleLogout} className={'bg-transparent border-[1px] border-btn-secondary py-[7px] px-[22px] text-interactive-text ml-6'}>Выйти</BaseButton>
				</div>
			</div>
			{isOpen &&
				<ModalVk
					title={'Обновить доступ через Вконтакте'}
					errorMessage={errorMessage}
					isOpen={isOpen}
					onClose={() => setOpen(false)}
					link={link}/>}
		</div>
	)
}
