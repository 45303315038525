import React from "react";
import Checkbox from "../../../components/common/form/checkbox/Checkbox";
import {TagsMultipleSelect} from "../../../components/common/form/select/datatable/tags/TagsMultipleSelect";
import {createColumnHelper} from "@tanstack/react-table";
import TagLabel from "../../../components/common/form/select/datatable/tags/TagLabel";

export default function Clients({data, updateField, errors, tagsArray, updateFieldNoRender}: any) {
  const columnHelper = createColumnHelper<any>()

  const columns = [
    columnHelper.accessor(row => row.id, {
      id: 'ID',
      header: () => 'ID',
      cell: info => <div className={'w-[85px] flex items-center justify-center text-center'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[85px] flex items-center justify-center text-center'
      }
    }),
    columnHelper.accessor(row => row.text, {
      id: 'title',
      header: () => 'Метки (Теги)',
      cell: info =>
        <TagLabel
          img={info?.row?.original?.icon}
          textColor={info.row?.original?.color}
          backgroundColor={info.row?.original?.background}>
          {info.getValue()}
        </TagLabel>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[64px] ml-4 flex items-center justify-center text-center'
      }
    }),
  ]

  return (
    <div className={'flex flex-col px-3 gap-y-6'}>
      <Checkbox
        label={'Отправить всем'}
        name={'sendToAll'}
        onChange={(value: any) => updateField('sendToAll', value)}
        value={data.sendToAll}
        errors={errors}
        required={true}
      />

      <TagsMultipleSelect
        data={data.branches?.length > 0 ? tagsArray?.map(({title, ...o}: any) => ({text: title, ...o})).filter(({id}: any) => !data.withoutTags?.includes(id)) || [] : []}
        values={data.withTags || []}
        errors={errors}
        name={'withTags'}
        placeholder={'Выберите метки'}
        updateValues={(values: any) => {
          updateField('withTags', values)
          if (!values.length) {
            updateField('emptyWithTags', true)
          }
        }}
        updateData={() => {}}
        columns={columns}
        buttonPlaceholder={'Изменить метки'}
        label={'Клиенты с метками'}
        textTooltip={'Клиенты с метками'}
        removeItem={(index: any) => {
          let options = data.withTags.slice()
          options.splice(index, 1)
          updateField('withTags', options)
        }}
        loading={false}
      />

      <TagsMultipleSelect
        data={data.branches?.length > 0 ? tagsArray?.map(({title, ...o}: any) => ({text: title, ...o})).filter(({id}: any) => !data.withTags?.includes(id)) || [] : []}
        values={data.withoutTags || []}
        errors={errors}
        name={'withoutTags'}
        placeholder={'Выберите метки'}
        updateValues={(values: any) => {
          updateField('withoutTags', values)
          if (!values.length) {
            updateField('emptyWithoutTags', true)
          }
        }}
        updateData={() => {}}
        columns={columns}
        buttonPlaceholder={'Изменить метки'}
        label={'Клиенты без меток'}
        textTooltip={'Клиенты без меток'}
        removeItem={(index: any) => {
          let options = data.withoutTags.slice()
          options.splice(index, 1)
          updateField('withoutTags', options)
        }}
        loading={false}
      />
    </div>
  )
}
