import React from "react";
import { FieldsType } from "../../forms/helpers/renderFields";

const renderItem = (data: any, terms: any, definitions: any, filters: any) => {

  return (
    <div className={'flex flex-col gap-y-2 w-full border-y-[1px] border-gray-10 py-3'}>
      <div>{terms[data.type]?.title || 'Условие'}</div>
      {
        Object.entries(data?.data?.body).map(([bodyKey, bodyValue]) => (renderField(data, bodyKey, bodyValue, terms, definitions, filters)))
      }
    </div>
  )
}

const renderField = (data: any, key: any, value: any, terms: any, definitions: any, filters: any) => {
  const inputInfo = definitions[data?.type]?.inputs?.find((input: any) => input?.name == key)

  return (
    inputInfo && <div className={'bg-funnels-green text-white rounded-2xl py-[7px] px-3 w-fit'}>
      {
        <div className={'flex flex-row items-center gap-x-2'}>
          <div>
            {inputInfo.label}:
          </div>
          <div>
            {transformValue(value, inputInfo)}
          </div>
        </div>
      }
    </div>
  )
}
const transformValue = (value: any, inputInfo: any) => {
  if (!value && value !== 0) return <></>

  //multiselect or array
  if (value instanceof Array) {
    //array of objects
    if (value.length > 0 && typeof value[0] == 'object') {
      const properties = inputInfo?.properties || []

      return <div className={'flex flex-col gap-y-2'}>
        {
          value.map((item) => {
            return <div className={'p-2 border-[1px] rounded-lg border-white'}>
              {
                Object.entries(item).map(([itemKey, itemValue]: any) => {
                  const property = properties.find((property: any) => property?.name == itemKey)
                  if (property) {
                    if (property.input == FieldsType.singleSelect) {
                      return <div>
                        {property?.label}: <span className={'break-all'}>{property?.items?.find((propertyItem: any) => propertyItem.value == itemValue)?.title || ' - '}</span>
                      </div>
                    }
                    return <div>{property?.label}:  <span className={'break-all'}>{itemValue}</span></div>
                  }

                  return <></>
                })
              }
            </div>
          })
        }
      </div>
    } else if (value.length > 0) {
      //array of string/number
      return <span className="break-word">{inputInfo?.items?.filter((data: any) => value?.map((x: any) => (String(x)))?.includes(String(data.value)))
        .map((data: any) => (`${data?.title} (${data?.value})`))
        .join(', ')}</span>
    } else {
      //length 0
      return <></>
    }
  }

  //image
  if (typeof value == 'string' && value.startsWith('data:image')) {
    return <img src={value} className={'w-[60px] rounded-lg h-[60px]'} alt="" />
  }

  //single select
  if ('items' in inputInfo) {
    return <span className={'break-all'}>{inputInfo?.items?.find((data: any) => data.value == value)?.title || ' - '}</span>
  }

  //textEditor html
  if ('formatted' in inputInfo && inputInfo?.formatted) {
    return <div dangerouslySetInnerHTML={{ __html: value }}></div>
  }

  return <span className={'break-all'}>{value?.toString() || ' - '}</span>
}

export const renderConditions = (data: any, isFirstGroup = false, terms: any, definitions: any, filters: any) => {

  return (
    <div className={`w-full ${data?.data?.isGroup && 'p-3 rounded-lg border-funnels-yellow border-[1px] flex flex-col'}`}>
      {
        'isGroup' in data?.data && data?.data?.isGroup && 
          <div className={`mb-[10px] text-sm text-black`}>
            {terms[data.type]?.title || 'Условие'}
          </div>
      }
      {
        isFirstGroup ?
          <div>
            {
              data?.children?.map((child: any, index: number) => (
                <div className={'w-full'}>
                  {renderConditions(child, false, terms, definitions, filters)}
                  <div className={`flex w-full flex-row py-[4px] text-sm font-bold text-funnels-green justify-center items-center text-center ${(index == data?.children?.length - 1) && 'hidden'}`}>{data?.data?.body?.logicOperator == 'AND' ? `И` : 'ИЛИ'}</div>
                </div>
              ))
            }
          </div>
          :
          <div>
            {
              data?.data?.isGroup ?
                <div className={''}>
                  <div className={`flex flex-col gap-y-2`}>
                    {
                      Object.entries(data?.data?.body).map(([bodyKey, bodyValue]) => (renderField(data, bodyKey, bodyValue, terms, definitions, filters)))
                    }
                  </div>
                  {
                    data?.children?.map((child: any, index: number) => (
                      <div className={`w-full ${Object.keys(data?.data?.body).length > 0 ? 'mt-[10px]' : ''}`}>
                        {renderConditions(child, false, terms, definitions, filters)}
                        <div className={`flex w-full flex-row py-[4px] text-sm font-bold text-funnels-green  justify-center items-center text-center ${(index == data?.children?.length - 1) && 'hidden'}`}>{data?.data?.body?.logicOperator == 'AND' ? `И` : 'ИЛИ'}</div>
                      </div>
                    ))
                  }
                </div> : <div>
                  {renderItem(data, terms, definitions, filters)}
                </div>
            }
          </div>
      }
    </div>
  )
}
