import React, { createContext, useContext, useEffect, useState } from "react";
interface SidebarContextType {
    hiddenMode: boolean;
    setHiddenMode: any;
}

const SidebarContext = createContext<SidebarContextType>({
    hiddenMode: false,
    setHiddenMode: (value: boolean) => {},
});

export function SidebarContextWrapper({ children }: any) {
    const [hiddenMode, setHiddenMode] = useState(Boolean(JSON.parse(localStorage.getItem('che_crm_sidebar_hidden_mode') || 'false')) || false)

    useEffect(() => {
        localStorage.setItem('che_crm_sidebar_hidden_mode', JSON.stringify(hiddenMode))
    }, [hiddenMode])

    return (
        <SidebarContext.Provider value={{ hiddenMode, setHiddenMode }}>
            {children}
        </SidebarContext.Provider>
    )
}

export function useSidebarContext() {
    return useContext(SidebarContext)
}
