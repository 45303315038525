import React from "react";
import ColorPickerSelect from "../../../components/common/form/color/ColorPickerSelect";
import ImageLoad from "../../../components/common/form/image/ImageLoad";
import SingleSimpleValueSelect from "../../../components/common/form/select/SingleSimpleValueSelect";
import TextInput from "../../../components/common/form/input/TextInput";
import Checkbox from "../../../components/common/form/checkbox/Checkbox";

export default function Templates({data, updateField, errors}) {
  const templateList = [
    {value: 'default', label: 'Основной шаблон'},
  ]
  const themeList = [
    {value: 'light', label: 'Светлая'},
    {value: 'dark', label: 'Темная'},
  ]
  const mobileOrientationList = [
    {value: 'vertical', label: 'Развернутые (Вертикальные)'},
    {value: 'horizontal', label: 'Развернутые (Горизонтальные)'},
    {value: 'verticalTwoRow', label: 'Две в ряд (Вертикальные)'},
  ]

  return (
    <>
      <div className={'flex flex-col gap-y-4'}>
        <div className={ 'flex' }>
          <div className={ 'w-1/2 pr-4' }>
            <SingleSimpleValueSelect
              label={ 'Выбор шаблона' }
              name={ 'template.code' }
              options={ templateList }
              onChange={ (value: any) => {
                updateField('template.code', value)
              } }
              value={ data.template.code || '' }
              errors={ errors }
              textTooltip={ 'Нет информации' }
            />
          </div>
          <div className={ 'w-1/2 pl-4' }>
            <SingleSimpleValueSelect
              label={ 'Тема' }
              name={ 'template.options.theme' }
              options={ themeList }
              onChange={ (value: any) => {
                updateField('template.options.theme', value)
              } }
              value={ data.template.options.theme || '' }
              errors={ errors }
              textTooltip={ 'Нет информации' }
            />
          </div>
        </div>

        <div className={ 'flex items-end' }>
          <div className={ 'w-1/3 pr-4' }>
            <ColorPickerSelect
              defaultColor={ data.template?.options?.mainColor }
              label={ 'Основной цвет' }
              name={ 'template.options.mainColor' }
              value={ data.template?.options?.mainColor }
              onChange={ (value) => {
                updateField('template.options.mainColor', value)
              } }
              textTooltip={ 'Нет информации' }
            />
          </div>
          <div className={ 'w-1/3 px-4' }>
            <ColorPickerSelect
              defaultColor={ data?.template?.options?.additionalColor }
              label={ 'Дополнительный цвет' }
              name={ 'template.options.additionalColor' }
              value={ data?.template?.options?.additionalColor }
              onChange={ (value) => {
                updateField('template.options.additionalColor', value)
              } }
              textTooltip={ 'Нет информации' }
            />
          </div>
          <div className={ 'w-1/3 pl-4' }>
            <ColorPickerSelect
              defaultColor={ data?.template?.options?.orderButtonColor }
              label={ 'Цвет кнопки "Добавить в корзину"' }
              name={ 'template.options.orderButtonColor' }
              value={ data?.template?.options?.orderButtonColor }
              onChange={ (value) => {
                updateField('template.options.orderButtonColor', value)
              } }
              textTooltip={ 'Нет информации' }
            />
          </div>
        </div>

        <ImageLoad
          label='Логотип (формат: JPG, JPEG, PNG)'
          defaultValue={ data.template?.logo }
          onInput={ value => {
            if (typeof value !== 'undefined') {
              if (value !== null) {
                updateField('template.logo', value)
              }
            }
          } }
          name={ 'template.logo' }
          onRemove={ () => {
            updateField('template.logo', null)
          } }
          errors={ errors }
          classPlaceholderBox='!w-[195px]'
          required={ true }
          resizeTo={ [470, 141] }
        />

        <ImageLoad
          label='Логотип миниатура (формат: JPG, JPEG, PNG)'
          defaultValue={ data.template?.logoThumbnail }
          onInput={ value => {
            if (typeof value !== 'undefined') {
              if (value !== null) {
                updateField('template.logoThumbnail', value)
              }
            }
          } }
          onRemove={ () => {
            updateField('template.logoThumbnail', null)
          } }
          name={ 'template.logoThumbnail' }
          errors={ errors }
          required={ true }
          resizeTo={ [66, 66] }
        />

        <ImageLoad
          label='Фавиконка (формат: SVG, ICO)'
          defaultValue={ data.template?.favicon }
          onInput={ value => {
            if (typeof value !== 'undefined') {
              if (value !== null) {
                updateField('template.favicon', value)
              }
            }
          } }
          onRemove={ () => {
            updateField('template.favicon', null)
          } }
          name={ 'template.favicon' }
          errors={ errors }
          required={ true }
        />

        <SingleSimpleValueSelect
          label={ 'Вид отображения карточек на мобильных устройствах' }
          name={ 'template.options.cartMobileOrientation' }
          options={ mobileOrientationList }
          onChange={ (value: any) => {
            updateField('template.options.cartMobileOrientation', value)
          } }
          value={ data?.template?.options?.cartMobileOrientation || '' }
          errors={ errors }
          textTooltip={ 'Нет информации' }
        />
      </div>
      <section className={ 'mt-4 space-y-6' }>
        <div className={'flex items-center gap-4'}>
          <Checkbox
            label='Блок призыва установки мобильного приложения'
            name={ 'template.options.isAppCTAShowed' }
            value={ Boolean(+(data.template?.options?.isAppCTAShowed || 0)) }
            onChange={ (value: any) => updateField('template.options.isAppCTAShowed', value) }
          />
        </div>

        <div className={ 'grid grid-cols-3 gap-4' }>
          <ImageLoad
            label='Логотип'
            defaultValue={ data.template?.appCTALogo }
            onInput={ (value: any) => {
              if (typeof value !== 'undefined') {
                if (value !== null) {
                  updateField('template.appCTALogo', value)
                }
              }
            } }
            onRemove={ () => {
              updateField('template.appCTALogo', null)
            } }
            name={ 'template.options.appCTAText' }
            errors={ errors }
            required={ true }
          />

          <TextInput
            name={ 'template.options.appCTATitle' }
            label='Заголовок'
            placeholder='Заголовок'
            value={ data?.template?.options?.appCTATitle }
            onChange={ (value: any) => updateField('template.options.appCTATitle', value) }
            errors={ errors }
          />

          <ColorPickerSelect
            defaultColor={ data?.template?.options?.appCTATitleColor }
            label={ 'Цвет заголовка' }
            placeholder={ 'Цвет заголовка' }
            name={ 'template.options.appCTATitleColor' }
            value={ data?.template?.options?.appCTATitleColor }
            onChange={ (value) => {
              updateField('template.options.appCTATitleColor', value)
            } }
          />
        </div>

        <div className={ 'grid grid-cols-2 gap-4' }>
          <TextInput
            name={ 'template.options.appCTAText' }
            label='Текст'
            placeholder='Текст'
            value={ data?.template?.options?.appCTAText }
            onChange={ (value: any) => updateField('template.options.appCTAText', value) }
            errors={ errors }
          />

          <ColorPickerSelect
            defaultColor={ data?.template?.options?.appCTATextColor }
            label={ 'Цвет текста' }
            placeholder={ 'Цвет текста' }
            name={ 'template.options.appCTATextColor' }
            value={ data?.template?.options?.appCTATextColor }
            onChange={ (value) => {
              updateField('template.options.appCTATextColor', value)
            } }
          />
        </div>
        <div className={ 'grid grid-cols-3 gap-4' }>
          <ColorPickerSelect
            defaultColor={ data?.template?.options?.appCTAButtonTextColor }
            label={ 'Цвет текста кнопки' }
            placeholder={ 'Цвет текста кнопки' }
            name={ 'template.options.appCTAButtonTextColor' }
            value={ data?.template?.options?.appCTAButtonTextColor }
            onChange={ (value) => {
              updateField('template.options.appCTAButtonTextColor', value)
            } }
          />

          <ColorPickerSelect
            defaultColor={ data?.template?.options?.appCTAButtonBackgroundColor }
            label={ 'Цвет фона кнопки' }
            placeholder={ 'Цвет фона кнопки' }
            name={ 'template.options.appCTAButtonBackgroundColor' }
            value={ data?.template?.options?.appCTAButtonBackgroundColor }
            onChange={ (value) => {
              updateField('template.options.appCTAButtonBackgroundColor', value)
            } }
          />

          <ColorPickerSelect
            defaultColor={ data?.template?.options?.appCTABackgroundColor }
            label={ 'Цвет фона' }
            placeholder={ 'Цвет фона' }
            name={ 'template.options.appCTABackgroundColor' }
            value={ data?.template?.options?.appCTABackgroundColor }
            onChange={ (value) => {
              updateField('template.options.appCTABackgroundColor', value)
            } }
          />
        </div>

        <div className={ 'grid grid-cols-2 gap-4' }>
          <TextInput
            name={ 'template.options.appMobileTitle' }
            label='Приложение. Заголовок'
            placeholder='Приложение. Заголовок'
            value={ data?.template?.options?.appMobileTitle }
            onChange={ (value: any) => updateField('template.options.appMobileTitle', value) }
            errors={ errors }
          />

          <TextInput
            name={ 'template.options.appMobileText' }
            label='Приложение. Подзаголовок'
            placeholder='Приложение. Подзаголовок'
            value={ data?.template?.options?.appMobileText }
            onChange={ (value: any) => updateField('template.options.appMobileText', value) }
            errors={ errors }
          />
        </div>
      </section>
    </>
  )
}
