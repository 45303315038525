import React, { useEffect, useState } from "react";
import SingleSimpleValueSelect from "../../../components/common/form/select/SingleSimpleValueSelect";
import TextInput from "../../../components/common/form/input/TextInput";
import Textarea from "../../../components/common/form/input/Textarea";
import { apiClient } from "../../../libs/api/apiClient";
import { useLoyaltyStatusContext } from "../../../contexts/LoyaltyPrograms";


export default function Base({data, errors, updateField, updateFieldNoRender}: any) {
  const [rulesTypes, setRulesTypes] = useState([])
  const {statuses}: any = useLoyaltyStatusContext()

  useEffect(() => {
    apiClient.dictionaries.rulesTypesLoyaltyPrograms().then(({ data }: any) => {
      setRulesTypes(data);
    })
  }, [])


  return (
    
    <div className={'flex flex-col px-3 gap-y-6'}>
      <TextInput
        label={'Заголовок'}
        textTooltip={'Заголовок'}
        placeholder={'Введите заголовок'}
        name={'title'}
        value={data.title}
        onChange={value => updateFieldNoRender('title', value)}
        errors={errors}
        required={true}
      />

      <Textarea
        label='Краткое описание'
        textTooltip='Краткое описание'
        placeholder='Введите краткое описание'
        name={'shortDescription'}
        value={data.shortDescription}
        onChange={value => updateField('shortDescription', value)}
        errors={errors}
      />

      <SingleSimpleValueSelect
        label={'Для статуса'}
        textTooltip={'Для статуса'}
        required={true}
        name={'status'}
        placeholder={'Введите заголовок'}
        value={data?.bonusProgramStatusId || data?.bonusProgramStatus?.id}
        options={statuses?.map((status: any) => ({label: status.title, value: status.id}))}
        onChange={(value: any) => updateField('bonusProgramStatusId', value)}
      />

      <SingleSimpleValueSelect
        label={'Тип правила'}
        name={'ruleType'}
        textTooltip={'Тип правила'}
        required={true}
        placeholder={'Введите заголовок'}
        value={data.type}
        options={rulesTypes?.map((rule: any) => ({ label: rule.title, value: rule.value }))}
        onChange={(value: any) => updateField('type', value)}
      />

      <TextInput
        type={'number'}
        min={'0'}
        label={'Количество начисляемых баллов (%)'}
        textTooltip={'Количество начисляемых баллов (%)'}
        placeholder={'564'}
        name={'percent'}
        value={data.percent}
        onChange={value => updateFieldNoRender('percent', value)}
        errors={errors}
      />

      <TextInput
        type={'number'}
        min={'0'}
        label={'Время действия бонусных баллов (Дней)'}
        textTooltip={'Время действия бонусных баллов (Дней)'}
        placeholder={'564'}
        name={'bonusScoreAliveDays'}
        value={data.bonusScoreAliveDays}
        onChange={value => updateFieldNoRender('bonusScoreAliveDays', value)}
        errors={errors}
      />

      <TextInput
        type={'number'}
        min={'0'}
        label={'Время действия статусных баллов (Дней)'}
        textTooltip={'Время действия статусных баллов (Дней)'}
        placeholder={'564'}
        name={'statusScoreAliveDays'}
        value={data.statusScoreAliveDays}
        onChange={value => updateFieldNoRender('statusScoreAliveDays', value)}
        errors={errors}
      />
    </div>
  )
}
