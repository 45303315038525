import React from "react";
import {default as V1} from "./ExternalResponse/V1/externalResponse";
import {default as V2} from "./ExternalResponse/V2/externalResponse";

export type IOrderWithExternalResponse = {
    externalResponse: any;
    externalId: string;
}

export type IUpdateOrders = (limits: any, filters: any) => void;

export type IExternalResponseProps = {
    order: IOrderWithExternalResponse;
    updateOrders: IUpdateOrders;
    limits: any;
    filters: any;
}

export default function ExternalResponseContainer({order, ...props}: IExternalResponseProps) {
    const version = order.externalResponse?.version;

    if (2 === version) {
        return <V2 order={order} {...props} />;
    } else {
        return (
            <V1 order={order} {...props} />
        );
    }
}