import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import Base from "../form/Base"
import BaseButton from "../../../components/common/Button/BaseButton";
import {ProductsContextWrapper} from "../../../contexts/Products";
import useForm from "../../../hooks/useForm";
import initForm from "../initForm";
import fetchPlatforms from "../../../providers/dictionaries/platforms";
import {useBranchesContext} from "../../../contexts/Branches";
import {CategoriesContextWrapper} from "../../../contexts/Categories";
import {CustomersTagsContextWrapper} from "../../../contexts/Tags";
import {apiClient} from "../../../libs/api/apiClient";
import {useNavigate} from "react-router-dom";
import {useCompaniesContext} from "../../../contexts/Companies";
import {showErrorToast, showSuccessToast} from "../../../libs/helpers/toasts";

export default function CreateStock() {
  const {company} = useCompaniesContext()
  const { branch, branches } = useBranchesContext()
  const {data, updateField, errors, updateErrors, updateFieldNoRender, submit} = useForm(JSON.parse(JSON.stringify(initForm)))
  const [platforms, setPlatforms] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    fetchPlatforms().then(data => {
      setPlatforms(data.map((platform: any) => ({ label: platform.label, name: platform.value })))
    })

  }, [])

  const handleSubmit = () => {
    submit(async (form: any) => {
      setLoading(true)
      apiClient.stocks.create(company?.id, branch?.id, {body: form}).then(({data, errors}: any) => {
        if (data) {
          showSuccessToast({
            content: "Акция успешно создана",
          })
          navigate('/stocks')
        } else {
          updateErrors(errors || {});

          showErrorToast({
            content: 'Что-то пошло не так'
          })

        }
      }).catch(() => {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      }).finally(() => setLoading(false))
    })

  }

  return (
    <div className='px-8 pb-8 pt-2 text-gray-50'>
      <div className='mb-8'>
        <div className='text-[44px] leading-[60px] mb-2'>Акции</div>
        <Breadcrumbs
          links={[
            {to: `/stocks`, label: 'Список акций'},
            {to: '', label: 'Создание акции'},
          ]}
          back={`/stocks`}
        />
      </div>
      <div className={'rounded-xl bg-white py-5'}>
        <ProductsContextWrapper>
          <CategoriesContextWrapper>
            <CustomersTagsContextWrapper>
            <Base
              errors={errors}
              data={data}
              updateField={updateField}
              updateFieldNoRender={updateFieldNoRender}
              branches={branches.map((branch: any) => ({label: branch.title, value: branch.id}))}
              platforms={platforms}
            />
            </CustomersTagsContextWrapper>
          </CategoriesContextWrapper>
        </ProductsContextWrapper>
        <div className={'mt-6 border-gray-10 border-y-[1px] flex justify-end z-[50] w-full sticky bottom-0 bg-white'}>
          <BaseButton
            disabled={loading}
            className={`w-[225px] bg-interactive-elem text-white m-4 py-[7px] ${loading && 'bg-interactive-text'}`}
            onClick={handleSubmit}>
            {
              loading ?
                <div className={'flex flex-row items-center justify-center w-[120px]'}>
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
                : "Добавить акцию"
            }
          </BaseButton>
        </div>
      </div>
    </div>
  )
}
