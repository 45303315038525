import {apiClient} from "../../libs/api/apiClient";

export async function fetchOrderStatuses() {
  const { data }: any = await apiClient.dictionaries.reviewStatuses()

  return data?.map((promo: any) => ({
    label: promo.title,
    value: promo.value
  }))
}

export async function fetchEstimations() {
  const { data }: any = await apiClient.dictionaries.reviewEstimations()

  return data?.map((promo: any) => ({
    label: promo.title,
    value: promo.title
  }))
}

export async function fetchPaymentTypes() {
  const { data }: any = await apiClient.dictionaries.paymentTypes()

  return data?.map((promo: any) => ({
    label: promo.title,
    value: promo.value
  }))
}

