import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import {apiClient} from "../libs/api/apiClient";
import {useCompaniesContext} from "./Companies";
import {useBranchesContext} from "./Branches";
import {preparePaginationQueryParams} from "./common";
import { getPagination } from "../libs/helpers/pagination";

interface DeliveryZonesContextType {
  deliveryZones: any;
  deliveryZone: any;
  loading: boolean;
  updateDeliveryZones: Function;
  selectDeliveryZone: Function;
  updateFilters: Function;
  pagination: {};
}

const DeliveryZonesContext = createContext<DeliveryZonesContextType>({
  deliveryZones: [],
  deliveryZone: {},
  loading: false,
  updateDeliveryZones: () => {},
  selectDeliveryZone: () => {},
  updateFilters: () => {},
  pagination: {},
});

export function DeliveryZonesContextWrapper({children}: any) {
  const {company}: any = useCompaniesContext()
  const {branch}: any = useBranchesContext()

  const [deliveryZones, setDeliveryZones] = useState([])
  const [deliveryZone, setDeliveryZone] = useState(null)
  const [loading, setLoading] = useState(true)
  const [pagination, setPagination] = useState({})
  const [filters, setFilters] = useState({limit: 10})
  const prevReqRef = useRef(0)
  
  const updateDeliveryZones = (params = {limit: 10}) => {
    if (company) {
      setLoading(true)
      const reqId = ++prevReqRef.current
      apiClient.deliveryZones.getAll(company.id, branch.id, params ? preparePaginationQueryParams(params) : '')
        .then(({data, meta, links}: any) => {
          if (prevReqRef.current !== reqId)
            return
          setDeliveryZones(data)
          if (meta) {
            setPagination(meta)
          }
        }).catch(() => {

        }).finally(() => {
          setLoading(false)
        })
    }
  }

  const updatePagination = ({limit, order, page}: any) => {
    let tempData = filters
    if (limit) {
      tempData['limit'] = limit
    }
    if (page) {
      tempData['page'] = page
    }
    if (order) {
      tempData['order'] = order
    }

    setFilters(filters)
  }

  const updateFilters = (path: any, value: any, applyFilters = false) => {
    path = path.split('.')

    let tempData: any = filters;
    path.forEach((key: any, index: any) => {
      if (index === path.length - 1) {
        if (tempData instanceof Object) {
          tempData[key] = value
        } else {
          tempData = {[key]: value}
        }
      } else {
        if (!(tempData[key] instanceof Object)) {
          tempData[key] = {}
        }
        if (!(key in tempData)) {
          tempData[key] = {}
        }
        tempData = tempData[key]
      }
    })
    setFilters(filters)
    if (applyFilters) {
      updateDeliveryZones(filters)
    }
  }

  const selectDeliveryZone = (id: any) => {
    if (localStorage.getItem('deliveryZoneId') != id) {
      localStorage.setItem('deliveryZoneId', id)
      refreshDeliveryZoneData()
    }
  }

  const refreshDeliveryZoneData = () => {
    if (!loading) {
      let deliveryZoneId = localStorage.getItem('deliveryZoneId')

      if (deliveryZoneId) {
        let temp = null
        deliveryZones.forEach((elem: any) => {
          if (elem.id == deliveryZoneId) {
            temp = elem
          }
        })

        if (temp) {
          setDeliveryZone(temp)
          return
        } else {
          localStorage.removeItem('branchId')
          setDeliveryZone(null)
        }
      }
    }
  }

  useEffect(() => {
    if (company && branch) {
      const limits = getPagination('delivery-zones')
      updateDeliveryZones(limits)
    }
  }, [company?.id, branch?.id])

  return (
    <DeliveryZonesContext.Provider
      value={{deliveryZones, deliveryZone, loading, updateDeliveryZones, updateFilters, selectDeliveryZone, pagination}}>
      {children}
    </DeliveryZonesContext.Provider>
  )
}

export function useDeliveryZonesContext() {
  return useContext(DeliveryZonesContext)
}
