import React, { useState } from 'react'
import ChevronIcon from '../../icons/ChevronIcon'

import { Link } from 'react-router-dom'
import { IntegrationLink } from './IntegrationLink'
import { useFormContext } from '../../../contexts/Form'

export default function MenuMultipalLink({ icon, label, items, currentUrl, firstLevel = true }: any) {
  const [showList, setShowList] = useState(false)
  const { checkIsFormDirty, openDialog } = useFormContext();

  return (
    <div className={`${(items.find((item: any) => item.url === currentUrl)) ? firstLevel ? 'bg-zinc-900' : '' : ''} text-white cursor-pointer ${firstLevel ? 'hover:bg-zinc-900' : 'bg-gray-40 text-sm pl-8 h-auto items-center'} `}>
      <div className={`flex items-center w-full ${firstLevel ? 'h-16' : 'h-11'}`}
        onClick={() => {
          if (firstLevel) {
            setShowList(!showList)
          }
        }}
      >
        {
          icon && <div className='h-full w-16 flex items-center justify-center'>
            <img src={icon} alt="" />
          </div>
        }
        <div className='grow font-medium'>{label}</div>
        {
          firstLevel && <div className='h-full w-16 flex items-center justify-center'><ChevronIcon className={`duration-300 ${showList && 'rotate-180'}`} /></div>
        }
      </div>

      <div className={`w-full flex flex-col overflow-hidden duration-500 transition-[height] ${showList ? 'h-auto' : firstLevel ? 'h-0' : 'h-auto'}`} /*style={{height: showList ? `auto` : firstLevel ? '0px' : `auto`}}*/>
        {
          items.map((link: any) => (
            <div>
              {
                'name' in link ?
                  <MenuMultipalLink
                    icon={null}
                    label={link?.label}
                    items={link?.items}
                    currentUrl={currentUrl}
                    firstLevel={false}
                  /> : 'integration' in link ? <IntegrationLink link={link} currentUrl={currentUrl} /> : <Link key={link.url + link.label} to={link.url} onClick={(event) => {
                    if (checkIsFormDirty()) {
                      event.preventDefault()
                      openDialog(link.url)
                    }
                  }}>
                    <div
                      className={`${currentUrl === link.url ? 'bg-zinc-600' : 'bg-gray-40'} bg-gray-40 text-sm h-11 pl-8 flex items-center`}
                    >
                      <div className="">{link.label}</div>
                    </div>
                  </Link>
              }
            </div>
          ))
        }
      </div>
    </div>
  )
}
