import { preparePaginationQueryParams } from "../../../contexts/common"
import { apiClient } from "../../../libs/api/apiClient"
import { showErrorToast, showSuccessToast } from "../../../libs/helpers/toasts"

export enum ExportBlocks {
  goods = 'goods',
  goodsRating = 'goodsRating',
  promoCodes = 'promocodes',
  orders = 'orders',
  customers = 'customers',
  reviews = 'reviews',
  answers = 'answers',
  reportOrders = 'report-orders',
  reportGoods = 'report-products',
  reportPromoCodes = 'report-promocodes',
  reportCustomers = 'report-orders-customers',
  customersVkEvents = 'vk-events'
}

export const startExport = async (companyId: any, branchId: any, data: any, type: string) => {
  const queryParams = preparePaginationQueryParams(data)
  switch (type) {
    case ExportBlocks.goods:
      exportGoods(companyId, branchId, queryParams, data.type)
      break;
    case ExportBlocks.goodsRating:
      exportGoodsRating(companyId, branchId, queryParams)
      break;
    case ExportBlocks.promoCodes:
      exportPromoCodes(companyId, branchId, queryParams)
      break;
    case ExportBlocks.orders:
      exportOrders(companyId, branchId, queryParams)
      break;
    case ExportBlocks.customers:
      exportCustomers(companyId, branchId, queryParams)
      break;
    case ExportBlocks.reviews:
      exportReviews(companyId, branchId, queryParams)
      break;
    case ExportBlocks.answers:
      exportQuestionResults(companyId, branchId, queryParams)
      break;
    case ExportBlocks.reportOrders:
      exportReportsOrders(companyId, branchId, queryParams)
      break;
    case ExportBlocks.reportGoods:
      exportReportsProducts(companyId, branchId, queryParams)
      break;
    case ExportBlocks.reportPromoCodes:
      exportReportPromoCodes(companyId, branchId, queryParams)
      break;
    case ExportBlocks.reportCustomers:
      exportReportsCustomers(companyId, branchId, queryParams)
      break;
    case ExportBlocks.customersVkEvents:
      exportCustomersVkEvents(companyId, branchId, queryParams)
  }
}

export const fetchExportFiles = async (companyId: any, branchId: any, type: string) => {
  const {data}: any = await apiClient.export.get(companyId, branchId, `?exportTarget=${type}`)

  return data
}

const showToast = () => {
  showSuccessToast({
    content: "Заявка на экспорт успешно отправлена"
  })
}

const exportGoods = async (companyId: any, branchId: any, data: any, fileType: string) => {
  if (fileType === 'yml') {
    window.open(`${process.env.REACT_APP_API_URL}/api/companies/${companyId}/branches/${branchId}/xml/yandex${data}`, '_blank')
  }

  await apiClient.export.goods(companyId, branchId, data, {}).then(({ data, errors, message }: any) => {
    if (message || errors) {
      showErrorToast({
        content: message || 'Что-то пошло не так'
      })
    } else {
      showToast()
      return data;
    }
  }).catch((reason: any) => {
    showErrorToast({
      content: reason || 'Что-то пошло не так'
    })
  })
}

const exportGoodsRating = async (companyId: any, branchId: any, data: any) => {
  await apiClient.export.goodsRating(companyId, branchId, data, {}).then(({ data, errors, message }: any) => {
    if (message || errors) {
      showErrorToast({
        content: message || 'Что-то пошло не так'
      })
    } else {
      showToast()
      return data;
    }
  }).catch((reason: any) => {
    showErrorToast({
      content: reason || 'Что-то пошло не так'
    })
  })
}

const exportPromoCodes = async (companyId: any, branchId: any, data: any) => {
  await apiClient.export.promoCodes(companyId, branchId, data, {}).then(({ data, errors, message }: any) => {
    if (message || errors) {
      showErrorToast({
        content: message || 'Что-то пошло не так'
      })
    } else {
      showToast()
      return data;
    }
  }).catch((reason: any) => {
    showErrorToast({
      content: reason || 'Что-то пошло не так'
    })
  })
}

const exportReportPromoCodes = async (companyId: any, branchId: any, data: any) => {
  await apiClient.export.reports.promoCodes(companyId, branchId, data, {}).then(({ data, errors, message }: any) => {
    if (message || errors) {
      showErrorToast({
        content: message || 'Что-то пошло не так'
      })
    } else {
      showToast()
      return data;
    }
  }).catch((reason: any) => {
    showErrorToast({
      content: reason || 'Что-то пошло не так'
    })
  })
}

const exportOrders = async (companyId: any, branchId: any, data: any) => {
  await apiClient.export.orders(companyId, branchId, data, {}).then(({ data, errors, message }: any) => {
    if (message || errors) {
      showErrorToast({
        content: message || 'Что-то пошло не так'
      })
    } else {
      showToast()
      return data;
    }
  }).catch((reason: any) => {
    showErrorToast({
      content: reason || 'Что-то пошло не так'
    })
  })
}

const exportReviews = async (companyId: any, branchId: any, data: any) => {
  await apiClient.export.reviews(companyId, branchId, data, {}).then(({ data, errors, message }: any) => {
    if (message || errors) {
      showErrorToast({
        content: message || 'Что-то пошло не так'
      })
    } else {
      showToast()
      return data;
    }
  }).catch((reason: any) => {
    showErrorToast({
      content: reason || 'Что-то пошло не так'
    })
  })
}

const exportQuestionResults = async (companyId: any, branchId: any, data: any) => {
  await apiClient.export.questionResults(companyId, branchId, data, {}).then(({ data, errors, message }: any) => {
    if (message || errors) {
      showErrorToast({
        content: message || 'Что-то пошло не так'
      })
    } else {
      showToast()
      return data;
    }
  }).catch((reason: any) => {
    showErrorToast({
      content: reason || 'Что-то пошло не так'
    })
  })
}

const exportCustomers = async (companyId: any, branchId: any, data: any) => {
  await apiClient.export.customers(companyId, branchId, data, {}).then(({ data, errors, message }: any) => {
    if (message || errors) {
      showErrorToast({
        content: message || 'Что-то пошло не так'
      })
    } else {
      showToast()
      return data;
    }
  }).catch((reason: any) => {
    showErrorToast({
      content: reason || 'Что-то пошло не так'
    })
  })
}

const exportCustomersVkEvents = async (companyId: any, branchId: any, data: any) => {
  await apiClient.export.customersVkEvents(companyId, branchId, data, {}).then(({ data, errors, message }: any) => {
    if (message || errors) {
      showErrorToast({
        content: message || 'Что-то пошло не так'
      })
    } else {
      showToast()
      return data;
    }
  }).catch((reason: any) => {
    showErrorToast({
      content: reason || 'Что-то пошло не так'
    })
  })
}

const exportReportsOrders = async (companyId: any, branchId: any, data: any) => {
  await apiClient.export.reports.orders(companyId, branchId, data, {}).then(({ data, errors, message }: any) => {
    if (message || errors) {
      showErrorToast({
        content: message || 'Что-то пошло не так'
      })
    } else {
      showToast()
      return data;
    }
  }).catch((reason: any) => {
    showErrorToast({
      content: reason || 'Что-то пошло не так'
    })
  })
}

const exportReportsCustomers = async (companyId: any, branchId: any, data: any) => {
  await apiClient.export.reports.customers(companyId, branchId, data, {}).then(({ data, errors, message }: any) => {
    if (message || errors) {
      showErrorToast({
        content: message || 'Что-то пошло не так'
      })
    } else {
      showToast()
      return data;
    }
  }).catch((reason: any) => {
    showErrorToast({
      content: reason || 'Что-то пошло не так'
    })
  })
}

const exportReportsProducts = async (companyId: any, branchId: any, data: any) => {
  await apiClient.export.reports.products(companyId, branchId, data, {}).then(({ data, errors, message }: any) => {
    if (message || errors) {
      showErrorToast({
        content: message || 'Что-то пошло не так'
      })
    } else {
      showToast()
      return data;
    }
  }).catch((reason: any) => {
    showErrorToast({
      content: reason || 'Что-то пошло не так'
    })
  })
}