import React, {useEffect, useState} from "react";
import Textarea from "../../../../components/common/form/input/Textarea";
import SingleSimpleValueSelect from "../../../../components/common/form/select/SingleSimpleValueSelect";
import Checkbox from "../../../../components/common/form/checkbox/Checkbox";
import TextInput from "../../../../components/common/form/input/TextInput";
//import ConditionsSelect from "../../../../components/common/form/select/conditions/ConditionsSelect";
import {useCompaniesContext} from "../../../../contexts/Companies";
import {useBranchesContext} from "../../../../contexts/Branches";
import {fetchAnswerTypes, fetchConditions} from "../../../../providers/dictionaries/nps";
import ConditionSelect from "../../../Funnels/CreateFunnel/forms/select/ConditionSelect";

export default function Base({errors, data, updateField, updateFieldNoRender}: any) {
  const {company}: any = useCompaniesContext()
  const {branch}: any = useBranchesContext()
  const [conditions, setConditions] = useState<{terms: any, definitions: any}>({terms: null, definitions: null})
  const [answerTypes, setAnswerTypes] = useState([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
      if (company && branch) {
        setLoading(true)
        fetchConditions(company.id, branch.id).then((data) => {
          setConditions(data)
          setLoading(false)
        }).catch(() => {setLoading(false)})
      }

      fetchAnswerTypes().then((data) => {
        setAnswerTypes(data)
      }).catch(() => {})

  }, [branch, company])

  return (
    <div className={"flex flex-col px-3 gap-y-6"}>

      <TextInput
        min={'0'}
        type={'number'}
        label={'Сортировка'}
        textTooltip={'Сортировка'}
        placeholder={'500'}
        name={'position'}
        value={data.position}
        onChange={(value: any) => updateFieldNoRender('position', value)}
        errors={errors}
      />

      <Textarea
        label={'Вопрос'}
        textTooltip={'Вопрос'}
        placeholder={'Введите текст'}
        name={'text'}
        value={data.text || ''}
        onChange={value => updateFieldNoRender('text', value.trim().length !== 0 ? value : null)}
        errors={errors}
        required={true} 
        maxLength={undefined}      
      />

      <Checkbox
        label={'Обязательный'}
        name={'isRequired'}
        onChange={(value: any) => updateField('isRequired', value)}
        value={data.isRequired}
        errors={errors}
      />

      <SingleSimpleValueSelect
        label={'Ответ'}
        textTooltip={'Ответ'}
        name={'answerType'}
        placeholder={'Выберите вариант'}
        value={data.answerType}
        options={answerTypes}
        onChange={(value: any) => updateField('answerType', value)}
        errors={errors}
        required={true}
      />

      {
        conditions && 
        conditions?.definitions && 
        Object.keys(conditions?.definitions)?.length > 0 && <div className={`p-4 border-gray-20 border-[1px] rounded-lg `}>
            <ConditionSelect
              loading={loading}
              conditions={conditions}
              value={data.conditions || null}
              errors={errors}
              onChange={(value: any) => updateField('conditions', value)}
            />
        </div>

      }

    </div>
  )
}
