import React, { useEffect, useState } from 'react'
import Checkbox from './Checkbox'
import Tooltip from "../../Tooltip";

export default function CheckboxList({label, options, values, onChange, name, errors = {}, showLabel = true, required = false, classNameWrapper = 'flex-col gap-2', classNameCheckbox = '',  textTooltip = '',}) {
  const error = errors[name]

  const onInput = (name: any) => {
    delete errors[name];

    let temp = values.slice(0)
    if (temp.includes(name)) {
      temp = temp.filter((item: any) => item != name)
    } else {
      temp.push(name)
    }

    onChange(temp)
  }

  return (
    <div>
      {
        showLabel && 
        <div className='mb-2'>
          {
            label &&
            <label className="flex items-center mb-2 font-medium text-sm">
              { label }
                <span className='text-error-main'>{required && '*'}</span>
              {
                textTooltip && <Tooltip textTooltip={textTooltip} defaultText={name}/>
              }
            </label>
          }
          {error && (<span className={`text-error-font text-xs font-medium`}>{error}</span>)}
        </div>
      }

      <div className={`flex ${classNameWrapper}`}>
        {
          options.map((checkbox: any, index: any) => (
            <Checkbox
              key={index}
              name={checkbox.name}
              errors={error}
              value={values?.includes(checkbox.name)}
              label={checkbox.label}
              onChange={() => onInput(checkbox.name)}
              className={classNameCheckbox}
            />
          ))
        }
      </div>

    </div>
  )
}
