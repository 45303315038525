import React, {createContext, useContext, useEffect, useState} from "react";
import {apiClient} from "../libs/api/apiClient";
import {preparePaginationQueryParams} from "./common";
import { dismissToasts, showCompanyBlockedToast } from "../libs/helpers/toasts";
import { CompanyStatus } from "../pages/Companies";

interface CompaniesContextType {
  company: any;
  companies: any;
  pagination: {};
  preparePaginationQueryParams: Function;
  updateCompanies: Function;
  selectCompany: Function;
  loading: boolean;
}

const CompaniesContext = createContext<CompaniesContextType>({
  company: {},
  companies: [],
  pagination: {},
  preparePaginationQueryParams: () => {},
  updateCompanies: () => {},
  selectCompany: () => {},
  loading: false,
});

export function CompaniesContextWrapper({children}: any) {
  const [companies, setCompanies] = useState<any>([]);
  const [company, setCompany] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [pagination, setPagination] = useState({})
  const [errorToast, setErrorToast] = useState<any>(null)

  const updateCompanies = (params = null) => {
    setLoading(true)
    apiClient.companies.getAll(
      params ? preparePaginationQueryParams(params) : '')
      .then(({data, meta, links}: any) => {
        setCompanies(data)
        if (meta) {
          setPagination(meta)
        }
      }).catch(() => { }).finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    updateCompanies()
  }, [])

  useEffect(() => {
    refreshCompanyData()
  }, [companies])

  useEffect(() => {
    refreshCompanyData()
  }, [companies.map(({id}) => id).join(',')])

  const refreshCompanyData = () => {
    if (companies?.at(0) && !loading) {
      let companyId = localStorage.getItem('companyId')

      if (companyId) {
        let temp: any = null
        companies.forEach((elem: any)=> {
          if (elem.id == companyId) {
            temp = elem
          }
        })

        if (temp) {
          setCompany(temp)
          apiClient.companies.get(temp.id).then(({data}: any) => {
            setCompany(data)
          }).catch(() => { })
          return
        }
      }

      selectCompany(companies?.at(0)?.id)
    }
  }

  useEffect(() => {
    if (company?.status === CompanyStatus.BLOCKED) {
      if (errorToast) return;

      setErrorToast(showCompanyBlockedToast({
        company: company?.title || ''
      }))
    } else {
      dismissToasts()
      setErrorToast(null)
    }
  }, [company])

  const selectCompany = (id: any) => {
    if (localStorage.getItem('companyId') != id) {
      localStorage.setItem('companyId', id)
      refreshCompanyData()
    }
  }

  return (
    <CompaniesContext.Provider value={{company, companies, pagination, preparePaginationQueryParams, updateCompanies, selectCompany, loading }}>
      { children }
    </CompaniesContext.Provider>
  )
}

export function useCompaniesContext() {
  return useContext(CompaniesContext)
}
