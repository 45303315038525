export const getPagination = (key: string) => {
    let obj = JSON.parse(localStorage.getItem('rr_pagination_obj') || "{}")

    if (key in obj) return obj[key]

    return {limit: 10, page: 1}
}

export const setPagination = (key: string, value: any) => {
    let obj = JSON.parse(localStorage.getItem('rr_pagination_obj') || "{}")

    obj[key] = value;

    localStorage.setItem('rr_pagination_obj', JSON.stringify(obj))
}