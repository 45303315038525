import React, {useEffect, useId, useRef, useState} from "react";
import IconModal from "./IconModal";
import {SVGWrap} from "../../util/SVGWrap";

export default function IconSelect({
  options = [],
  value,
  onChange,
  name='',
  setStandartIndex,
  color,
  disabled = false,
  ...props
}: any) {
  const id = useId();
  const [show, setShow] = useState(false);
  const wrapper = useRef<any>(null);

  /* Закрываем при клике вне поля */
  useEffect(() => {
    function handleClick(event: any) {
      if (wrapper.current && !wrapper.current.contains(event.target)) {
        setShow(false);
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }

  }, [show, setShow, wrapper]);

  return (
    <div key={id} ref={wrapper} className={'relative'}>
      <div
        className="flex gap-1 py-2 px-[2px] border-[1px] pr-[20px] pl-[20px] border-gray-20 rounded-lg w-max cursor-pointer"
        onClick={() => {
          if (!disabled) {
            setShow(show => !show)
          }
        }}
      >
      <SVGWrap src={value} width="25px" height="25px" color={color} />
      </div>
      <IconModal show={show} setShow={setShow} items={options} setStandartIndex={(index: any) => setStandartIndex(index)} onSubmit={(icon: any, iconSrc: any) => {setShow(false); onChange(icon, iconSrc);}} {...props}/>
      {/*<DropDown type='icon' items={options} value={value} setShow={setShow} show={show} onChange={(option) => {setShow(false); onChange(option);}}/>*/}
    </div>
  )
}
