import React, {useEffect, useState} from "react";

export default function SingleSelectButton(
  {
    items = [],
    value,
    onChange = (value: any) => {},
    className = ''
  }: {items?: Array<any>, value?: any, onChange: Function, className: string}
) {
  const [index, setIndex] = useState(0)

  useEffect(() => {
    if (items?.length && typeof value != 'undefined' && value !== null) {
      setIndex(items?.findIndex((item: any) => value == item.value) || 0)
    }
  }, [items, value])

  return (
    <div
      onClick={() => {
        const newIndex = index + 1
        setIndex(newIndex < items.length ? newIndex : 0)
        onChange(newIndex < items.length ? items[newIndex] : items[0])
      }}
      className={`border-[1px] border-interactive-elem bg-white rounded-lg items-center justify-center cursor-pointer flex px-2 py-[7px] hover:bg-interactive-elem hover:text-white ${className}`}
    >
      {items[index]?.title || ''}
    </div>
  )
}
