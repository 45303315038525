import React, {FormEvent, useEffect, useState} from "react";
import {auth} from "../../libs/auth";
import '../../styles/login-form.css';
import {Link, useNavigate, useSearchParams} from "react-router-dom";

import vectorEye from '../../images/Vector.svg';
import closeIcon from '../../images/greenClose.svg';
import logoLogin from '../../images/logoForLogin.svg'

const Login = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe] = useState(true);
  const [_, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [link, setLink] = useState<string>('')
  const [type, setType] = useState<'vk' | 'email'>('email');

  //@ts-ignore
  const [params, setParams] = useSearchParams()
  const navigate = useNavigate()

  function handleLogin(type: 'vk' | 'email') {
    setType(type)
    if (type === 'email') {
      setLoading(true);
      auth.login(email, password, rememberMe)
        .then(() => {
          navigate('/')
        })
        .catch(reason => {
          alert(reason)
        })
        .finally(() => {
          setLoading(false);
        })
    } else if (type === 'vk') {
      setLoading(true);
      auth.loginVk(setLink, '')
        .then((user) => {
          navigate('/')
        }).finally(() => setLoading(false))
    }
  }

  useEffect(()=> {
    if (params.get('code')) {
      localStorage.setItem('tokenVk', params.get('code') as string)
      setTimeout(() => window.close(), 200)
    }
  }, [])

  function checkEye() {
    setPasswordShown(!passwordShown);
  }

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="container-form">
        <div className="main-logo-container">
          <div className="main-logo">
            <img src={logoLogin} alt=""></img>
          </div>
   
          {/* <div className="main-logo-head">
            <span>Result.Rest</span>
          </div> */}
        </div>
        <form onSubmit={(e) => {
          e.preventDefault()
          handleLogin('email')
        }}>
          <div className="email-form">
            <span>E-mail</span>
            <br></br>
            <input type='email' placeholder={"Введите e-mail"} name={'email'} onChange={(event) => setEmail(event.target.value)} />
          </div>
          <div className="password-form">
            <span>Пароль</span>
            <br/>
            <input placeholder={"Введите пароль"} type={passwordShown ? 'text': 'password'} onChange={(event) => setPassword(event.target.value)}></input>
            <div className={'cursor-pointer'}><img src={vectorEye} onClick={checkEye}></img></div>
          </div>

          <div className="button-log-in">
            <button>Войти</button>
          </div>
        </form>

        <div onClick={()=>{
          handleLogin('vk')
        }} className="button-vk-id">
          <button>Войти через VK ID</button>
        </div>
        <div className={'flex flex-row items-center justify-center w-full mt-3'}>
          <span className={'text-sm text-gray-40 mr-2'}>Нет аккаунта?</span>
          <Link className={'text-sm text-interactive-elem'} to={'/register'}>Зарегистрироваться</Link>
        </div>

        {type === 'vk' && <ModalVk isOpen={type === 'vk'} onClose={()=>setType('email')} link={link}/>}
      </div>
    </div>
  );
}

export default Login


export function ModalVk({isOpen, onClose, errorMessage, link, title='Вход на сайт через Вконтакте'}: any) {


  return (<div className={`fixed grid w-full h-full modal-wrapper ${!isOpen && 'hidden'}`}>
    <div className={'grid relative bg-white rounded-[10px] w-[50%] max-h-full modal'}>
      <div className={'flex justify-between px-4 py-[14px] items-center '}>
        <span className={'text-xl'}>{errorMessage?.length > 0 ? 'Ошибка!' :title}</span>
        <img src={closeIcon} className={'w-[20px] cursor-pointer'} onClick={() => {
          onClose()
        }} alt=""/>
      </div>
      {errorMessage?.length > 0
        ? <div className={`p-4`}>{errorMessage}</div>

        :<div className={`p-4`}>
        <p className={'text-start px-4'}>Вы будете перенаправлены на страницу ВК для авторизации, пожалуйста следуйте
          инструкциям</p>
        <br/>
        <div className={'flex flex-col justify-center items-center '}>
          <p className={'text-center text-gray-20'}>Если у вас автоматически не открылась страница ВК, то</p>
          <a
            onClick={() => {
            window.open(`${link}`,'MyWindow','width=600,height=300');
            return false;
          }}
            className={'text-interactive-elem border-b-[2px] border-interactive-elem cursor-pointer'}
                >нажмите сюда
          </a>
        </div>
      </div>}
    </div>

  </div>)
}
