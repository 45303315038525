import {Theme, toast, ToastPosition} from "react-toastify"
import { CompanyBlockedError } from "../../components/common/util/CompanyBlockedError"

interface ToastProps {
  content?: string;
  position?: ToastPosition;
  autoClose?: number;
  theme?: Theme;
}

export const showErrorToast = ({content = '', autoClose = 3000, theme = 'colored', position = 'top-right'}: ToastProps) => {
  toast.error(content, {
    position: position,
    autoClose: autoClose,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: theme,
  })
}

export const showSuccessToast = ({content = '', autoClose = 3000, theme = 'colored', position = 'top-right'}: ToastProps) => {
  toast.success(content, {
    position: position,
    autoClose: autoClose,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: theme,
  })
}

export const showWarningToast = ({content = '', autoClose = 3000, theme = 'colored', position = 'top-right'}: ToastProps) => {
  toast.warning(content, {
    position: position,
    autoClose: autoClose,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: theme,
  })
}

export const showCompanyBlockedToast = ({company = '', theme = 'colored', position = 'top-right'}: any) => {
  return toast(CompanyBlockedError({company}), {
    type: 'error',
    position: position,
    hideProgressBar: true,
    closeOnClick: true,
    autoClose: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  })
}

export const dismissToasts = (id = undefined) => {
  toast.dismiss(id)
}
