import React, {memo} from "react"
import {Handle, Position} from "reactflow";
// @ts-ignore
import targetImg from "../../../../images/target.svg"
import {useFunnelNodeContext} from "../../../../contexts/FunnelNodes";

const StartNode = ({data, isConnectable, deletable}: any) => {
  const {isValidConnection} = useFunnelNodeContext()
  return (
      <div className={'rounded-2xl bg-funnels-red text-sm min-w-[300px] shadow-xl'}>
        <div className={'rounded-t-2xl px-3 py-[10px] h-[46px]'}>
          <div className={'flex flex-row items-center gap-x-2'}>
            <img src={targetImg} alt=""/>
            <span>Начало</span>
          </div>
        </div>

        <div className={'flex py-8 px-3 bg-white'}>
          {data?.title || 'Добавление товара в корзину'}
        </div>
        <div className={'flex px-3 bg-white text-gray-20 rounded-b-2xl'}>
          <div className={'flex w-full px-3 py-[14px] border-t-[1px] border-gray-30'}>
            Переход на следующий шаг
          </div>
        </div>
        <Handle
          className={'!bottom-[15px] !top-auto !right-[10px]'}
          type="source"
          position={Position.Right}
          id={'out'}
          isConnectable={isConnectable}
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
          }}
          isValidConnection={(connection) => isValidConnection(connection)}
        />
      </div>
  )
}

export default memo(StartNode)
