import React, {useEffect, useState} from "react";
import useForm from "../../../hooks/useForm";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import TextInput from "../../../components/common/form/input/TextInput";
import {useBranchesContext} from "../../../contexts/Branches";
import BaseButton from "../../../components/common/Button/BaseButton";
import SimpleMultipleSelect from "../../../components/common/form/select/multiple/SimpleMultipleSelect";
import {useCompaniesContext} from "../../../contexts/Companies";
import {apiClient} from "../../../libs/api/apiClient";

import {useNavigate, useParams} from "react-router-dom";
import Checkbox from "../../../components/common/form/checkbox/Checkbox";
import {showErrorToast, showSuccessToast} from "../../../libs/helpers/toasts";
import {dialogModal} from "../../../components/common/Modal/DialogModal";

export default function EditEmployee() {
  const {id} = useParams<{id?: string}>()
  const { branches, updateBranches } = useBranchesContext()
  const { company, updateCompanies } = useCompaniesContext()
  const [loadingData, setLoadingData] = useState(false)
  const [branchesSelectData, setBranchesSelectData] = useState([])
  const {data, updateData, updateField, errors, updateErrors, submit} = useForm({
    //name: '',
    id,
    vkId: '',
    __companyEdit: false,
    __branchEdit__select: [],
    __catalog__select: [],
    __orders__select: [],
    __showOrder__select: [],
    __consumerLoyaltyIndex__select: [],
    __clients__select: [],
    __reports__select: [],
    __offers__select: [],
    __shoppingCartRules__select: [],
    __distributions__select: [],
    __events__select: [],
    __loyaltyProgram__select: [],
    __bots__select: [],
    __labels__select: [],
    __mobileApps__select: [],
    __histories__select: [],
    __permissionsForEmployee: {
      showOrder: [],
      branchEdit: [],
      consumerLoyaltyIndex: [],
      shoppingCartRules: [],
      loyaltyProgram: [],
      orders: [],
      catalog: [],
      clients: [],
      reports: [],
      offers: [],
      distributions: [],
      events: [],
      labels: [],
      histories: [],
    }
  })

  const navigate = useNavigate();

  const handleSubmit = () => {
    prepareForm()
    submit(async (form) => {
      apiClient.employees.update(company.id, id,{body: form}).then(({data, message, errors}) => {
        if (data) {
          navigate("/employees")
        } else {
          updateErrors(errors || {});

          showErrorToast({
            content: 'Что-то пошло не так'
          })
        }
      })
    }, 'changed')
  }

  const prepareForm = () => {
    let permissions = [];
    if (data.__permissionsForEmployee) {
      Object.entries(data?.__permissionsForEmployee).map(([key, value], index) => {
        if (key === 'branchEdit') {
          if (value === "*") {
            branchesSelectData.forEach((item: any) => {
              permissions.push(`company.${company.id}.branch.${item.value}.view`)
              permissions.push(`company.${company.id}.branch.${item.value}.update`)
            })
          } else if (typeof value === 'object'){
            value?.forEach((branchId: any) => {
              permissions.push(`company.${company.id}.branch.${branchId}.view`)
              permissions.push(`company.${company.id}.branch.${branchId}.update`)
            })
          }
          return;
        }
        if (typeof value === 'object') {
          value?.forEach((branchId) => {
            let action = '*';
            if (key === 'showOrder') {
              action = 'view';
            }
            permissions.push(`company.${company.id}.branch.${branchId}.${action === 'view' ? 'orders' : key}.${action}`);
          })
        } else if (typeof value === 'string') {
          branches.forEach((branch) => {
            let action = '*';
            if (key === 'showOrder') {
              action = 'view';
            }
            permissions.push(`company.${company.id}.branch.${branch.id}.${action === 'view' ? 'orders' : key}.${action}`);
          })
        }
      })
    }

    if (data.__companyEdit) {
      permissions.push(`company.${company.id}.update`)
      permissions.push(`company.${company.id}.view`)
    }

    if (permissions.length > 0) {
      updateField('permissions', permissions);
    }
  }

  const transformData = () => {
 
    //console.log('transform')
    if (branchesSelectData.length === 0) {
      return;
    }
    if (branchesSelectData.length > 0) {
    data.permissions?.forEach((permission) => {
      let permissionArr = permission.split('.');
      if (permissionArr.length === 3) {
        if (permissionArr[2] === 'update' || permissionArr[2] === '*') {
          updateField('__companyEdit', true);
        }
      } else if(permissionArr.length === 5) {
        const branchId = permissionArr[3]
        const action = permissionArr[4]
        let branch = branchesSelectData.find((item) => item.value == branchId)

        if (action === 'update' || action === '*') {
          if (!data['__branchEdit__select'].some((item) => item.value == branchId)) {
            let updateBranch = data?.__permissionsForEmployee?.branchEdit.slice()
            updateBranch.push(branchId)
            data['__branchEdit__select'].push(branch)
            updateField('__permissionsForEmployee.branchEdit', updateBranch)
          }
        }
      } else if (permissionArr.length > 5) {
        const branchId = permissionArr[3]
        const section = permissionArr[4]
        const action = permissionArr[5]
        let branch = branchesSelectData.find((item) => item.value == branchId)
        if (action === 'view') {
          if (!data['__showOrder__select'].some((item) => item.value == branchId)) {
            data['__showOrder__select'].push(branch)
            let showOrder = data?.__permissionsForEmployee?.showOrder.slice()
            showOrder.push(branchId)
            updateField('__permissionsForEmployee.showOrder', showOrder)
          }
        } else {
          if (section === 'consumer-loyalty-index') {
            if (!data['__consumerLoyaltyIndex__select'].some((item) => item.value == branchId)) {
              data['__consumerLoyaltyIndex__select'].push(branch)
              let consumerLoyaltyIndex = data?.__permissionsForEmployee?.consumerLoyaltyIndex.slice()
              consumerLoyaltyIndex.push(branchId)
              updateField('__permissionsForEmployee.consumerLoyaltyIndex', consumerLoyaltyIndex)
            }
          } else if (section === 'loyalty-program') {
            if (!data['__loyaltyProgram__select'].some((item) => item.value == branchId)) {
              data['__loyaltyProgram__select'].push(branch)
              let loyaltyProgram = data?.__permissionsForEmployee?.loyaltyProgram.slice()
              loyaltyProgram.push(branchId)
              updateField('__permissionsForEmployee.loyaltyProgram', loyaltyProgram)
            }
          } else if (section === 'mobile-apps') {
            if (!data['__mobileApps__select'].some((item) => item.value == branchId)) {
              data['__mobileApps__select'].push(branch)
              let mobileApps = data?.__permissionsForEmployee?.mobileApps.slice()
              mobileApps.push(branchId)
              updateField('__permissionsForEmployee.mobileApps', mobileApps)
            }
          } else if (section === 'shopping-cart-rules') {
            if (!data['__shoppingCartRules__select'].some((item) => item.value == branchId)) {
              data['__shoppingCartRules__select'].push(branch)
              let shoppingCartRules = data?.__permissionsForEmployee?.shoppingCartRules.slice()
              shoppingCartRules.push(branchId)
              updateField('__permissionsForEmployee.shoppingCartRules', shoppingCartRules)
            }
          } else {
            if (!data['__'+section+'__select'].some((item) => item.value == branchId)) {
              data['__'+section + '__select'].push(branch)
              let sectionArr = data?.__permissionsForEmployee[section].slice()
              sectionArr.push(branchId)
              updateField(`__permissionsForEmployee.${section}`, sectionArr)
            }
          }
        }
        updateData(data)
      }
    })
    }
  }

  useEffect(() => {
    updateBranchesSelectData()
  }, [branches.map(({id}) => id).join(',')])

  //useEffect(() => {
    //transformData()
  //}, [branchesSelectData])

  const updateBranchesSelectData = () => {
    const branchesData = branches.map(branch => {
      return {
        label: branch.title,
        value: branch.id
      }
    })
    setBranchesSelectData(branchesData)
  }

  const getInfo = () => {
    apiClient.employees.get(company.id, id).then((res) => {
      updateData(res.data)
      transformData()
      setLoadingData(true)

    }).catch((error) => {
      console.log(error)
    })
  }

  useEffect(() => {
    if (company) {
      getInfo()
    }
  }, [company?.id])

  useEffect(() => {
    transformData()
  }, [loadingData])

  useEffect(() => {
    if (company && branches && !loadingData) {
      updateBranchesSelectData()
      getInfo()
    }
  }, [])

  if (!loadingData) return (
    <div className="text-gray-40 text-3xl text-center mt-[35vh]">
      <span>Загрузка...</span>
    </div>
  )

  const removeEmployee = () => {
    dialogModal({
      isOpen: false,
      title: 'Внимание!',
      text: 'Вы уверены что хотите удалить сотрудника?',
      showCancelButton: true,
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Вернуться',
    }).then((result) => {
      if (result.isConfirmed) {
        apiClient.employees.remove(company.id, id)
          .then(() => {
            showSuccessToast({
              content: 'Сотрудник удален успешно',
            })
            navigate('/')
          }).catch((err) => {
          updateErrors(errors || {})
          //updateData(company)
          showErrorToast({
            content: 'Что то пошло не так!'
          })
        })
      }
    }).catch(() => {})
  }

  return (
    <>
      <div className='px-8 pb-8 pt-3 text-gray-50 '>
        <div className=''>
          <div className='text-[44px] leading-[60px] mb-2'>Сотрудники</div>
          <Breadcrumbs
            links={[
              {to: '/employees', label: 'Список сотрудников'},
              {to: '', label: 'Редактирование сотрудника'},
            ]}

            back='/employees'
          />
        </div>
        <div className='flex flex-col mt-3 py-3 min-h-[770px] bg-white rounded-2xl'>
          <div className={`w-full pl-6 pr-3 py-2 flex flex-col gap-y-4 justify-between`}>
            <div className={`flex flex-col gap-y-4`}>

              {/*<TextInput
                errors={errors}
                value={data.name}
                label={'Имя сотрудника'}
                name={'name'}
                onChange={(value) => {updateField('name', value)}}
                placeholder={'Введите имя сотрудника'}
                textTooltip={'Имя сотрудника'}
                disabled={true}
              />*/}

              <TextInput
                errors={errors}
                value={data.id}
                label={'ID сотрудника в Result.Rest'}
                name={'id'}
                onChange={(value) => {updateField('userId', value)}}
                placeholder={'Введите ID'}
                textTooltip={'ID сотрудника в Result.Rest'}
                disabled={true}
              />

              <TextInput
                errors={errors}
                value={data.vkId}
                label={'ID пользователя VK'}
                name={'vkId'}
                onChange={(value) => {updateField('vkId', value)}}
                placeholder={'Введите vk id'}
                textTooltip={'ID пользователя VK'}
                disabled={true}
              />

              <div className={`border-[1px] border-gray-20 rounded-xl p-4 mt-4 ${errors['permissions'] ? 'border-red-500' : ''}`}>
                {errors['permissions'] && (<span className={'text-error-font text-xs font-medium mb-2'}>{errors['permissions']}</span>)}
                <div className={'text-[22px] font-semibold mb-4'}>Возможности</div>
                {
                  branchesSelectData.length === 0 ? (
                      <div className={'text-center'}>
                        Загрузка информации о филиалах...
                      </div>)
                    :
                    (
                      <div className={'flex flex-col gap-4'}>
                        <Checkbox
                          label={'Редактирование компании'}
                          onChange={value => {
                            delete errors['permissions']
                            updateField('__companyEdit', value)
                          }}
                          disabled={false}
                          errors={errors}
                          name={'__companyEdit'}
                          value={data.__companyEdit}
                        />

                        <SimpleMultipleSelect
                          label={'Редактирование филиалов'}
                          options={branchesSelectData}
                          value={data.__branchEdit__select}
                          onChange={value => {
                            delete errors['permissions']
                            updateField('__branchEdit__select', value)
                            updateField('__permissionsForEmployee.branchEdit', value === '*' ? value : value.map(item => item.value))
                          }}
                          name={'catalog'}
                          placeholder={'Выберите филиалы'}
                          errors={errors}
                          textTooltip={'Редактирование филиалов'}
                        />

                        <SimpleMultipleSelect
                          label={'Каталог'}
                          options={branchesSelectData}
                          value={data.__catalog__select}
                          onChange={value => {
                            delete errors['permissions']
                            updateField('__catalog__select', value)
                            updateField('__permissionsForEmployee.catalog', value === '*' ? value : value.map(item => item.value))
                          }}
                          name={'catalog'}
                          placeholder={'Выберите филиалы'}
                          errors={errors}
                          textTooltip={'Каталог'}
                        />

                        <SimpleMultipleSelect
                          label={'Заказы'}
                          options={branchesSelectData}
                          value={data.__orders__select}
                          onChange={value => {
                            delete errors['permissions']
                            updateField('__orders__select', value)
                            updateField('__permissionsForEmployee.orders', value === '*' ? value : value.map(item => item.value))
                          }}
                          name={'order'}
                          placeholder={'Выберите филиалы'}
                          errors={errors}
                          textTooltip={'Заказы'}
                        />


                        <SimpleMultipleSelect
                          label={'Заказы (только просмотр)'}
                          options={branchesSelectData}
                          value={data.__showOrder__select}
                          onChange={value => {
                            delete errors['permissions']
                            updateField('__showOrder__select', value)
                            updateField('__permissionsForEmployee.showOrder', value === '*' ? value : value.map(item => item.value))
                          }}
                          name={'showOrder'}
                          placeholder={'Выберите филиалы'}
                          errors={errors}
                          textTooltip={'Заказы (только просмотр)'}
                        />

                        <SimpleMultipleSelect
                          label={'Клиенты'}
                          options={branchesSelectData}
                          value={data.__clients__select}
                          onChange={value => {
                            delete errors['permissions']
                            updateField('__clients__select', value)
                            updateField('__permissionsForEmployee.clients', value === '*' ? value : value.map(item => item.value))
                          }}
                          name={'clients'}
                          placeholder={'Выберите филиалы'}
                          errors={errors}
                          textTooltip={'Клиенты'}
                        />


                        <SimpleMultipleSelect
                          label={'NPS'}
                          options={branchesSelectData}
                          value={data.__consumerLoyaltyIndex__select}
                          onChange={value => {
                            delete errors['permissions']
                            updateField('__consumerLoyaltyIndex__select', value)
                            updateField('__permissionsForEmployee.consumerLoyaltyIndex', value === '*' ? value : value.map(item => item.value))
                          }}
                          name={'ipl'}
                          placeholder={'Выберите филиалы'}
                          errors={errors}
                          textTooltip={'NPS'}
                        />

                        <SimpleMultipleSelect
                          label={"Истории"}
                          options={branchesSelectData}
                          value={data.__histories__select}
                          onChange={(value: any) => {
                            delete errors["permissions"];
                            updateField("__histories__select", value);
                            updateField(
                              "__permissionsForEmployee.histories",
                              value === "*"
                                ? value
                                : value.map((item: any) => item.value)
                            );
                          }}
                          name={"histories"}
                          placeholder={"Выберите филиалы"}
                          errors={errors}
                          textTooltip={"Истории"}
                        />

                        <SimpleMultipleSelect
                          label={'Акции и Промокоды'}
                          options={branchesSelectData}
                          value={data.__offers__select}
                          onChange={value => {
                            delete errors['permissions']
                            updateField('__offers__select', value)
                            updateField('__permissionsForEmployee.offers', value === '*' ? value : value.map(item => item.value))
                          }}
                          name={'promoCodes'}
                          placeholder={'Выберите филиалы'}
                          errors={errors}
                          textTooltip={'Акции и Промокоды'}
                        />

                        <SimpleMultipleSelect
                          label={'Воронки'}
                          options={branchesSelectData}
                          value={data.__events__select}
                          onChange={value => {
                            delete errors['permissions']
                            updateField('__events__select', value)
                            updateField('__permissionsForEmployee.events', value === '*' ? value : value.map(item => item.value))
                          }}
                          name={'events'}
                          placeholder={'Выберите филиалы'}
                          errors={errors}
                          textTooltip={'Воронки'}
                        />

                        <SimpleMultipleSelect
                          label={'Рассылки'}
                          options={branchesSelectData}
                          value={data.__distributions__select}
                          onChange={value => {
                            delete errors['permissions']
                            updateField('__distributions__select', value)
                            updateField('__permissionsForEmployee.distributions', value === '*' ? value : value.map(item => item.value))
                          }}
                          name={'distributions'}
                          placeholder={'Выберите филиалы'}
                          errors={errors}
                          textTooltip={'Рассылки'}
                        />

                        <SimpleMultipleSelect
                          label={'Программа лояльности / Бонусная система'}
                          options={branchesSelectData}
                          value={data.__loyaltyProgram__select}
                          onChange={value => {
                            delete errors['permissions']
                            updateField('__loyaltyProgram__select', value)
                            updateField('__permissionsForEmployee.loyaltyProgram', value === '*' ? value : value.map(item => item.value))
                          }}
                          name={'loyaltyProgram'}
                          placeholder={'Выберите филиалы'}
                          errors={errors}
                          textTooltip={'Программа лояльности / Бонусная система'}
                        />

                        <SimpleMultipleSelect
                          label={'Метки'}
                          options={branchesSelectData}
                          value={data.__labels__select}
                          onChange={value => {
                            delete errors['permissions']
                            updateField('__labels__select', value)
                            updateField('__permissionsForEmployee.labels', value === '*' ? value : value.map(item => item.value))
                          }}
                          name={'tags'}
                          placeholder={'Выберите филиалы'}
                          errors={errors}
                          textTooltip={'Метки'}
                        />

                        
                        <SimpleMultipleSelect
                          label={'Отчеты'}
                          options={branchesSelectData}
                          value={data.__reports__select}
                          onChange={value => {
                            delete errors['permissions']
                            updateField('__reports__select', value)
                            updateField('__permissionsForEmployee.reports', value === '*' ? value : value.map(item => item.value))
                          }}
                          name={'reports'}
                          placeholder={'Выберите филиалы'}
                          errors={errors}
                          textTooltip={'Отчеты'}
                        />

                      </div>)
                }
              </div>
              <div className={'mt-6 border-gray-10 border-y-[1px] flex justify-between z-[50] w-full sticky bottom-0 bg-white'}>
            <BaseButton
              className={'bg-red-500 border-red-500 border-[2px] text-white m-4 py-[7px]'} onClick={() => removeEmployee()}>Удалить
            </BaseButton>
            <BaseButton
              className={'w-[225px] bg-interactive-elem text-white m-4 py-[7px] '} onClick={handleSubmit}>Сохранить изменения
            </BaseButton>
          </div>
            </div>
          </div>
        </div>
        
      </div>
    </>
  )
}
