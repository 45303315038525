import React from "react";
import TextInput from "../../../components/common/form/input/TextInput";
import {useBranchesContext} from "../../../contexts/Branches";
import ScheduleTime from "../../../components/common/form/ScheduleTime";
import Checkout from "./Checkout";
import TextInputMultipleList from "../../../components/common/form/input/TextInputMultipleList";
import ColorPickerSelect from "../../../components/common/form/color/ColorPickerSelect";
import Checkbox from "../../../components/common/form/checkbox/Checkbox";
import MapZoneSelect from "../../../components/common/Map/MapZoneSelect";
import Textarea from "../../../components/common/form/input/Textarea";
import SingleSimpleValueSelect from "../../../components/common/form/select/SingleSimpleValueSelect";

export default function Base(props: any) {
  const {data, errors, updateField, mapRef, updateFieldNoRender} = props
  const {branches} = useBranchesContext()

  return (
      <div className={`flex flex-col px-3 gap-y-6`}>
        <Checkbox
          label={'Активность зоны доставки'}
          name={'isActive'}
          onChange={(value: any) => updateField('isActive', value)}
          value={data.isActive}
          errors={errors}
        />
        <TextInput
          label='Название'
          textTooltip='Название филиала'
          placeholder='Введите название филиала'
          name={'title'}
          value={data.title}
          onChange={(value: any) => updateFieldNoRender('title', value)}
          errors={errors}
          required={true}
        />
        <TextInput
          type={'number'}
          min='0'
          label='Сортировка'
          textTooltip='Название филиала'
          placeholder='Введите название филиала'
          name={'position'}
          value={data.position}
          onChange={(value: any) => updateFieldNoRender('position', value)}
          errors={errors}
          required={true}
        />
        <Textarea
          label='Описание'
          textTooltip='Введите описание'
          placeholder='Введите описание'
          name={'description'}
          value={data.description || ''}
          onChange={value => updateFieldNoRender('description', value.trim().length !== 0 ? value : undefined)}
          errors={errors}
          maxLength={undefined}
        />
        <SingleSimpleValueSelect
          label={'Филиал'}
          textTooltip={'Филиал'}
          options={branches.map((branch: any) => ({label: branch.title, value: branch.id}))}
          value={data.branches || null}
          onChange={(value: any) => updateField('branches', [value])}
          name={'branches'}
          placeholder={'Выберите филиал'}
          errors={errors}
        />
        <Checkout errors={errors} data={data} updateFieldNoRender={updateFieldNoRender}/>
        <ColorPickerSelect
          label={'Цвет зоны'}
          name={'color'}
          defaultColor={data.color}
          value={data.color}
          onChange={(value) => {
            updateField('color', value)
          }}
          textTooltip={'Цвет зоны'}
        />
        <MapZoneSelect
          label={'Редактор зон'}
          name={'points'}
          value={data.points}
          fillColor={data.color}
          onChange={(value: any) => {
            updateField('points', value)
          }}
          errors={errors}
          required={true}
          mapRef={mapRef}
        />
        <ScheduleTime
          textTooltip={'Активности зоны доставки'}
          name='openHours'
          value={data.openHours}
          onChange={(value: any) => updateField('openHours', value)}
          errors={errors}
        />
        <TextInputMultipleList
          name={'cities'}
          values={data.cities}
          fields={['id', 'title', 'address']}
          fieldTypes={['input', 'input', 'input']}
          placeholders={['Название', 'Название', 'Полное название']}
          labels={['Город', 'Улица', 'Дом']}
          textTooltips={['Город', 'Улица', 'Дом']}
          onChange={(value: any) => updateField('cities', value)}
          label='Адреса'
          errors={errors}
          remove={true}
        />
      </div>
  )
}
