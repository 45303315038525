const tabs = [
  {
    label: 'Основная информация',
    tag: 'base',  
    required: true,
    fields: [
      'isActive',
      'title',
      'parentCategoryId',
      'position',
      'description',
      'branches'
    ]
  },
  {
    label: 'Показать в каталоге',
    tag: 'show',
    fields: [
      'openHours',
      'platforms'
    ]
  },
  {
    label: 'Мета теги',
    tag: 'metaTags',
    fields: [
      'isDividedByBranches',
      'metaTitle',
      'metaKeywords',
      'metaDescription',
    ] 
  },
]

export default tabs