import React, {useEffect, useState} from "react";
import Base from "./Base";
import BaseButton from "../../../components/common/Button/BaseButton";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import initForm from "../initForm";
import {useNavigate} from "react-router-dom";
import useForm from "../../../hooks/useForm";
import {CustomersTagsContextWrapper} from "../../../contexts/Tags";
import {useCompaniesContext} from "../../../contexts/Companies";
import {useBranchesContext} from "../../../contexts/Branches";
import {apiClient} from "../../../libs/api/apiClient";
import {showErrorToast, showSuccessToast} from "../../../libs/helpers/toasts";


export default function CreateClient() {
  const {company} = useCompaniesContext()
  const {branch} = useBranchesContext()
  const {data, updateField, updateFieldNoRender, errors, updateErrors, submit} = useForm(JSON.parse(JSON.stringify(initForm)))
  const [phoneInfo, setPhoneInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (company && branch) {
      submit(async (form: any) => {
        setLoading(true)
        apiClient.customers.create(company?.id, branch?.id, {body: form}).then(({data, errors}: any) => {
          if (data) {
            showSuccessToast({
              content: "Клиент успешно создан"
            })
            navigate("/clients")
          } else {
            updateErrors(errors || {});

            showErrorToast({
              content: 'Что-то пошло не так'
            })
          }
        }).catch(() => {
          showErrorToast({
            content: 'Что-то пошло не так'
          })
        }).finally(() => setLoading(false))
      })
    } else {
      showErrorToast({
        content: 'Ошибка при получении данных компании или филиала. Попробуйте позже или обновите страницу'
      })
    }
  }

  useEffect(() => {
    if (branch && company) {
      apiClient.branches.get(company.id, branch.id).then(({data, errors}: any) => {
        if (data) {
          setPhoneInfo(data?.extended?.phoneCode || null)
        }
      })
    }
  }, [branch])

  if (!branch) {
    return <div>
      <div className="text-gray-40 text-3xl text-center mt-[35vh]">
        Выберите филиал
      </div>
    </div>
  }

  return (
    <div className='px-8 pb-8 pt-2 text-gray-50'>
      <div className='mb-8'>
        <div className='text-[44px] leading-[60px] mb-2'>Клиенты</div>
        <Breadcrumbs
          links={[
            {to: `/clients`, label: 'Список клиентов'},
            {to: '', label: 'Добавление клиента'},
          ]}

          back={`/clients`}
        />
      </div>
      <div className={'rounded-xl bg-white py-5'}>
        <CustomersTagsContextWrapper>
          <Base errors={errors} data={data} updateField={updateField} updateFieldNoRender={updateFieldNoRender} phoneInfo={phoneInfo} isEdit={false}/>
        </CustomersTagsContextWrapper>
        <div className={'mt-6 border-gray-10 border-y-[1px] flex justify-end z-[50] w-full sticky bottom-0 bg-white'}>
          <BaseButton
            disabled={loading}
            className={`w-[225px] bg-interactive-elem text-white m-4 py-[7px] ${loading && 'bg-interactive-text'}`}
            onClick={handleSubmit}>
            {
              loading ?
                <div className={'flex flex-row items-center justify-center w-[120px]'}>
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
                : "Добавить клиента"
            }
          </BaseButton>
        </div>
      </div>
    </div>
  )
}
