import React, {useEffect, useState} from 'react'
import BaseButton from "../../components/common/Button/BaseButton";
import {Link} from "react-router-dom";
import {useBranchesContext} from '../../contexts/Branches';
import {useProductsContext} from '../../contexts/Products';
import {createColumnHelper} from '@tanstack/react-table'
import DataTable from '../../components/common/Table/DataTable';
// @ts-ignore
import pencilImg from '../../images/pencil.svg'
// @ts-ignore
import applyImg from '../../images/apply.svg'
import Checkbox from './../../components/common/form/checkbox/Checkbox';
import Actions from "../../components/common/Table/Actions";
import EditableTextInput from "../../components/common/Table/EditableTextInput";
import useForm from "../../hooks/useForm";
import {apiClient} from "../../libs/api/apiClient";
import {useCompaniesContext} from "../../contexts/Companies";
import SingleSimpleValueSelect from "../../components/common/form/select/SingleSimpleValueSelect";
import {useCategoriesContext} from "../../contexts/Categories";
import {showErrorToast, showSuccessToast} from "../../libs/helpers/toasts";
import SimpleMultipleValueSelect from "../../components/common/form/select/multiple/SimpleMultipleValueSelect";
import {submitAction} from "./massActions";
import {useSkuGroupContext} from "../../contexts/Options";
// @ts-ignore
import FileImg from '../../images/file.svg'
import TextInputWithSearch from "../../components/common/form/input/TextInputWithSearch";
import {dialogModal} from "../../components/common/Modal/DialogModal";
import Export from '../../components/common/Export';
import { ExportBlocks } from '../../components/common/Export/helpers';

type ProductType = {
  branchesCount: number|null,
  id: number,
  image: string|null,
  isActive: boolean,
  isTop: boolean,
  oldPrice: number|null,
  position: number|null,
  price: number|null,
  stockBalance: number|null,
  title: string,
  weight: number|null,
  article: string|null,
}

export default function Products() {
  const {company}: any = useCompaniesContext()
  const {branch, branches}: any = useBranchesContext();
  const {products, filters, limits, pagination, loading, updateFilters, updateProducts}: any = useProductsContext()
  const {categories} = useCategoriesContext()
  const {skuGroups}: any = useSkuGroupContext()

  const [allProducts, setAllProducts] = useState<any>([])
  const [action, setAction] = useState<string | null>(null)
  const [selectedRows, setSelectedRows] = useState<any>([])
  const [search, setSearch] = useState('')
  const [editRows, setEditRows] = useState<any[]>([])
  const [applyFilter, setApplyFilter] = useState(false)
  const [category, setCategory] = useState(null)
  const [brchs, setBrchs] = useState<number[]>([])
  const [ctgs, setCtgs] = useState<any>(null)
  const [sku, setSku] = useState<any>(null)
  const [mainSkuGood, setMainSkuGood] = useState(null)

  const {getFormData} = useForm({})

  const columnHelper = createColumnHelper<ProductType>()

  const updateField = (rowId: any, field: any, value: any) => {
    let rows = editRows.slice()
    let currentRow = rows.findIndex(({id}: any) => id === rowId)
    rows[currentRow].newData[field] = value;
    setEditRows(rows)
  }

  const toggleEditAction = (info: any) => {
    let rows = editRows.slice()
    if (!editModeRow(info)) {
      rows.push({
        id: info.row.original.id,
        oldData: info.row.original,
        newData: Object.assign({}, info.row.original)
      });
    } else {
      let removeIndex = rows.findIndex(({id}: any) => id === info.row.original.id)
      handleSubmit(removeIndex)
      rows.splice(removeIndex, 1)
    }
    setEditRows(rows)
  }

  const editModeRow = (info: any): boolean => {
    return editRows.some(({id}: any) => id === info.row.original.id);
  }

  const getEditRow = (info: any): any => {
    return editRows[editRows.findIndex(({id}: any) => id === info.row.original.id)]
  }

  const getUpdatedFields = (id: any) => {
    let data = {}
    Object.entries(editRows[id].oldData).forEach(([key,value]) => {
      if (value !== editRows[id].newData[key]) {
        data[key] = editRows[id].newData[key]
      }
    })
    return data
  }


  const sendApiUpdateRequest = (productId: any, formData: any) => {
    apiClient.products.update(company.id, branch.id, productId, {body: formData})
      .then(({data}: any) => {
        if (data) {
          updateProducts(limits, filters)
          showSuccessToast({
            content: "Товар успешно изменен"
          })
        } else {
          showErrorToast({
            content: 'Что-то пошло не так'
          })
        }
      }).catch((err) => {
      console.log(err)
    })
  }

  const showStopActionWarning = (callback: any) => {
    dialogModal({
      isOpen: false,
      title: 'Подтвердите действие',
      text: 'Напоминаем, что в случае, если позиция участвует в промокодах или воронках, то при деактивации/постановке на СТОП промокод или воронка может работать некорректно.',
      showCancelButton: true,
      confirmButtonText: 'Подтведить',
      cancelButtonText: 'Отменить',
      textClassName: 'max-w-[450px]'
    }).then((result) => {
      if (result.isConfirmed) {
        callback()
      }
    }).catch(() => {})
  }

  const handleIsActiveFieldChanged = (info: any, value: any) => {
    if (!editModeRow(info)) {
      handleIsActiveFieldUpdateAction(info.row.original.id, {
        isActive: !value
      })
    } else {
      updateField(info.row.original.id, 'isActive', !value)
    }
  }

  const handleIsActiveFieldUpdateAction = (id: any, data: any = null) => {
    if (company && branch) {
      let formData = getFormData('update', {...data, _method: "patch"}, new FormData())
      sendApiUpdateRequest(id, formData)
    }
  }

  const handleSubmit = (id: any, data: any = null) => {
    if (company && branch) {
      const updatedFields = getUpdatedFields(id)
      if (Object.keys(updatedFields).length === 0) {
        return
      }

      let formData = getFormData('update', {...updatedFields, _method: "patch"}, new FormData())
      sendApiUpdateRequest(editRows[id]?.newData?.id, formData)

    }
  }

  const columns = [
    columnHelper.accessor(row => row.id, {
      id: 'action',
      header: () => '',
      cell: info =>
        <div
          className={'w-[max(40px,3%)] flex items-center justify-center cursor-pointer'}
          onClick={() => {}}
          onMouseDown={() => {
            setTimeout(()=> {
              toggleEditAction(info)
            }, 100)
          }}
        >
          <img src={editModeRow(info) ? applyImg : pencilImg} alt=""/>
        </div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[max(40px,3%)]',
      },
    }),
    columnHelper.accessor(row => row.id, {
      id: 'copy',
      header: () => '',
      cell: info =>
        <Link
          className={'w-[max(40px,3%)] flex items-center justify-center cursor-pointer'}
          to={`/products/create?copy=${info.row.original?.id}`}
        >
          <img src={FileImg} alt="" />
        </Link>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[max(40px,3%)]',
      }
    }),
    columnHelper.accessor(row => row.id, {
      id: 'ID',
      header: () => 'ID',
      cell: info => <div className={'w-[max(50px,4%)] break-all flex items-center justify-center text-center'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[max(50px,4%)] flex items-center justify-center text-center'
      }
    }),
    columnHelper.accessor(row => row.image, {
      id: 'image',
      header: () => 'Фото',
      cell: info =>
        <div className={'w-[max(75px,5%)] py-2 flex items-center justify-center text-center'}>
          {
            info.getValue() ?
              <img className={'rounded-xl h-[60px] w-[60px]'} src={info.getValue() || ''} alt=""/> :
              <div
                className='h-[60px] w-[60px] flex items-center justify-center text-[10px] text-gray-40 border-[1.5px] border-btn-secondary rounded-xl'>Image</div>
          }
        </div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[max(75px,5%)] flex items-center justify-center text-center'
      }
    }),
    columnHelper.accessor(row => row.title, {
      id: 'title',
      header: () => 'Название',
      cell: info => {
        return <div className={'flex flex-col items-start w-[max(8vw,20%)] px-2 py-3'}>
          {editModeRow(info) ? <EditableTextInput
              className={"w-full px-2 pb-2"}
              type={'text'}
              placeholder={'артикул'}
              value={getEditRow(info).newData.article}
              onChange={(value: any) => {
                updateField(info.row?.original?.id, 'article', value)
              }}
            />
            : info.row?.original.article ? <p className={'text-gray-40 text-xs pb-1'}>арт. {info.row?.original.article}</p> :<></>
          }
          {
            editModeRow(info) ?
              <EditableTextInput
                className={"w-full px-2"}
                type={'text'}
                value={getEditRow(info).newData.title}
                onChange={(value: any) => {
                  updateField(info.row?.original?.id, 'title', value)
                }}
              />
              :
              <Link className={'w-full text-interactive-text'} to={info.row?.original?.id != null ? `${info.row?.original?.id}/edit` : '/products'}>
                {info.getValue()}
              </Link>
          }

        </div>
      },
      enableSorting: true,
      meta: {
        widthClass: 'w-[max(8vw,20%)] px-2',
        sortField: 'title'
      }
    }),
    columnHelper.accessor(row => row.branchesCount, {
      id: 'branchesCount',
      header: () => 'Ф',
      cell: info => <div className={'w-[max(40px,3%)] break-all flex items-center justify-center text-center'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[max(40px,3%)] flex items-center justify-center text-center',
      }
    }),
    columnHelper.accessor(row => row.weight, {
      id: 'weight',
      header: () => 'Вес',
      cell: info =>
        <div className={'flex items-center w-[max(50px,7%)]'}>
        {
          editModeRow(info) ?
            <EditableTextInput
              className={"w-full px-2"}
              type={'text'}
              value={getEditRow(info).newData.weight}
              onChange={(value: any) => {
                updateField(info.row?.original?.id, 'weight', value)
              }}
            />
            :
            <div className={'w-full flex items-center justify-center'}>{info.getValue()}</div>
        }
        </div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[max(50px,7%)] justify-center',
        sortField: 'weight'
      }
    }),
    columnHelper.accessor(row => row.oldPrice, {
      id: 'oldPrice',
      header: () => 'Старая цена',
      cell: info =>
        <div className={'flex items-center w-[max(90px,10%)]'}>
          {
            editModeRow(info) ?
              <EditableTextInput
                className={"w-full px-2"}
                type={'text'}
                value={getEditRow(info).newData.oldPrice}
                onChange={(value: any) => {
                  updateField(info.row?.original?.id, 'oldPrice', value)
                }}
              />
              :
              <div className={'w-full flex items-center justify-center'}>{info.getValue()}</div>
          }
        </div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[max(90px,10%)] break-word flex justify-center',
        sortField: 'oldPrice'
      }
    }),
    columnHelper.accessor(row => row.price, {
      id: 'price',
      header: () => 'Цена',
      cell: info =>
        <div className={'flex items-center w-[max(80px,10%)]'}>
          {
            editModeRow(info) ?
              <EditableTextInput
                className={"w-full px-2"}
                type={'text'}
                value={getEditRow(info).newData.price}
                onChange={(value: any) => {
                  updateField(info.row?.original?.id, 'price', value)
                }}
              />
              :
              <div className={'w-full flex items-center justify-center'}>{info.getValue()}</div>
          }
        </div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[max(80px,10%)] justify-center',
        sortField: 'price'
      }
    }),
    columnHelper.accessor(row => row.isTop, {
      id: 'isTop',
      header: () => 'TOP товар',
      cell: info =>
        <div className={'w-[max(40px,3%)] flex items-center justify-center'}>
          <Checkbox
            value={editModeRow(info) ? getEditRow(info).newData.isTop : info.getValue()}
            disabled={/*!editModeRow(info)*/false}
            onChange={(value: any) => {
              if (!editModeRow(info)) {
                //console.log('click')
                handleIsActiveFieldUpdateAction(info.row.original.id, {
                  isTop: value
                });
              } else {
                updateField(info.row.original.id, 'isTop', value);
              }
            }}
            name={undefined}
          />
        </div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[max(40px,3%)] justify-center break-word items-center',
      }
    }),
    columnHelper.accessor(row => row.position, {
      id: 'position',
      header: () => 'Сортировка',
      cell: info =>
        <div className={'flex items-center w-[max(80px,10%)]'}>
          {
            editModeRow(info) ?
              <EditableTextInput
                className={"w-full px-2"}
                type={'text'}
                value={getEditRow(info).newData.position}
                onChange={(value: any) => {
                  updateField(info.row?.original?.id, 'position', value)
                }}
              />
              :
              <div className={'w-full flex items-center justify-center'}>{info.getValue()}</div>
          }
        </div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[max(80px,10%)] break-all justify-center ',
        sortField: 'position'
      }
    }),
    columnHelper.accessor(row => row.isActive, {
      id: 'isActive',
      header: () => 'Стоп',
      cell: info =>
        <div className={'w-[max(40px,3%)]  flex items-center justify-center '}>
          <Checkbox
            value={editModeRow(info) ? !(getEditRow(info).newData.isActive) : !(info.getValue())}
            disabled={/*!editModeRow(info)*/false}
            onChange={(value: any) => {
              value ? showStopActionWarning(() => handleIsActiveFieldChanged(info, value))
                : handleIsActiveFieldChanged(info, value);
            }}
            name={undefined}
          />
        </div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[max(40px,3%)] justify-center ',
      }
    }),
    columnHelper.accessor(row => row.isInSbisPrestoStopList, {
      id: 'isInSbisPrestoStopList',
      header: () => 'СБИС Стоп',
      cell: info =>
        <div className={'w-[max(60px,3%)]  flex items-center justify-center '}>
          {(info.getValue() && "🚫") || ""}
        </div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[max(60px,3%)] justify-center ',
      }
    }),
    columnHelper.accessor(row => row.isInIikoStopList, {
      id: 'isInIikoStopList',
      header: () => 'IIKO Стоп',
      cell: info =>
        <div className={'w-[max(60px,3%)]  flex items-center justify-center '}>
          {(info.getValue() && "🚫") || ""}
        </div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[max(60px,3%)] justify-center ',
      }
    }),
    columnHelper.accessor(row => row.stockBalance, {
      id: 'stockBalance',
      header: () => 'Остаток',
      cell: info =>
        <div className={'flex items-center w-[max(80px,10%)] '}>
          {
            editModeRow(info) ?
            <EditableTextInput
              className={"w-full px-2"}
              type={'text'}
              value={getEditRow(info).newData.stockBalance}
              onChange={(value: any) => {
                updateField(info.row?.original?.id, 'stockBalance', value)
              }}
            />
            :
            <div className={'w-full flex items-center justify-center'}>{info.getValue()}</div>
          }
        </div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[max(80px,10%)] break-all justify-center ',
        sortField: 'stockBalance'
      }
    }),
  ]

  useEffect(() => {
    const categoryFilter: any = localStorage.getItem('che_crm_goods_category')
    let limitFilter: any = localStorage.getItem('che_crm_goods_limit')

    if (!limitFilter) {
      limitFilter = JSON.stringify({limit: 10, page: 1})
      localStorage.setItem('che_crm_goods_limit', limitFilter)
    }

    if (categories.length && categoryFilter) {
      setCategory(categoryFilter)
      setApplyFilter(true)
      updateFilters('filter.categories', categoryFilter)
      updateProducts(JSON.parse(limitFilter), filters)
    } else {
      updateProducts(JSON.parse(limitFilter), filters)
    }
  }, [categories])

  const submitMassAction = () => {
    if (action === null) return
    let form = new FormData();
    if (ctgs) {
      form.set(`categoryId`, `${ctgs}`);
    }
    if (sku) {
      form.set(`skuGroupId`, `${sku}`)
    }
    if (mainSkuGood) {
      form.set(`mainGoodId`, `${mainSkuGood}`)
    }

    if (typeof brchs == 'string' && brchs == '*') {
      branches.forEach(({id}: any, i: number) => form.set(`branchesIds[${i}]`, `${id}`))
    } else {
      brchs?.forEach((b, i) => form.set(`branchesIds[${i}]`, `${b}`))
    }
    selectedRows.forEach((b: any, i: any) => form.set(`goodsIds[${i}]`, `${b}`))
    submitAction(() => {
      showSuccessToast({
        content: 'Действие выполнено успешно'
      })
      setSelectedRows([])
      setBrchs([])
      setMainSkuGood(null)
      setSku(null)
      setCtgs(null)
      setAction(null)
      updateProducts(limits, filters)
    }, action, 'products', company.id, branch.id, form)
  }

  useEffect(() => {
    if (company && branch) {
      apiClient.products.getAll(company.id, branch.id, '').then(({data, errors, message}: any) => {
        if (data) {
          setAllProducts(data)
        } else {

        }
      }).catch(() => {})
    }
  }, [branch, company])

  return (
    <div className="px-8 pb-8 pt-3">
      <div className="flex justify-between items-end">
        <div>
          <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Каталог</div>
          <div className="text-gray-40 text-base">Список товаров</div>
        </div>

        {
          !applyFilter && !products?.length && branch &&
            <Link to={'create'}>
                <BaseButton className={'bg-interactive-elem text-white'}>Добавить товар</BaseButton>
            </Link>
        }
      </div>

      {
        branch ?
          <>
            {
              (applyFilter || products?.length) ?
                <div>
                  <Actions
                    updateValue={(value: any) => {
                      setSearch(value || '')
                    }}
                    fetchData={(value: any) => {
                      setApplyFilter(true)
                      
                      updateFilters('search', value || null)
                      updateProducts({...limits, page: 1}, filters)
                    }}
                    searchValue={search}
                    classNameChildren={'w-full pl-6 flex items-center justify-between'}
                  >
                    <div className={'w-1/3'}>
                      <SingleSimpleValueSelect
                        placeholder={'Все разделы'}
                        name={'type'}
                        value={category}
                        options={categories.sort((a: any, b: any) => a.position - b.position).map(({id, title}: any) => {return {label: title, value: id}})}
                        onChange={(value: any) => {
                          setCategory(value)
                          localStorage.setItem('che_crm_goods_category', value)
                          setApplyFilter(true)
                          updateFilters('filter.categories', value)
                          updateProducts({ ...limits, page: 1 }, filters)
                        }}
                        required={true}
                        removeValue={() => {
                          setCategory(null)
                          localStorage.setItem('che_crm_goods_category', '')
                          updateFilters('filter', null)
                          updateProducts({ ...limits, page: 1 }, filters)
                        }}
                        howMushShowItems={6}
                        removeItem={true}
                      />
                    </div>
                      {
                        branch &&
                          <Link to={'create'}>
                              <BaseButton className={'bg-interactive-elem text-white ml-8'}>Добавить товар</BaseButton>
                          </Link>
                      }
                  </Actions>

                  <Export
                    title={'Экспорт товаров'}
                    types={[{ title: 'Экспортировать', type: ExportBlocks.goods }]}
                    filter={filters || null}
                    order={limits?.order || null}
                    classNameWrapper={'mt-4'}
                  />

                  <DataTable
                    data={products}
                    columns={columns}
                    usePagination={true}
                    defaultLimit={Number(JSON.parse(localStorage.getItem('che_crm_goods_limit') || '{limit: 10}')?.limit) || 10}
                    defaultPage={Number(JSON.parse(localStorage.getItem('che_crm_goods_limit') || '{page: 1}')?.page) || 1}
                    pagination={pagination}
                    updateData={(params: any) => {
                      localStorage.setItem('che_crm_goods_limit', JSON.stringify(params))
                      updateProducts(params, filters)
                    }}
                    loading={loading}
                    useMassAction={true}
                    selectedRows={selectedRows}
                    onChangeSelectionRows={(values: any) => setSelectedRows(values)}
                    rowsWithCustomBg={products.filter((product: any) => !product.isActive || (product.stockBalance !== null && product.stockBalance == 0)).map(({id}: any) => ({id, color: 'bg-error-main/[.5]'}))}
                  />
                  {selectedRows.length > 0 && <div className={"w-full rounded-xl bg-white px-3 py-5 mt-5 mb-[100px] flex items-center justify-between sticky bottom-0"}>
                      <div className={"w-5/6 flex items-center justify-center gap-x-4"}>
                          <SingleSimpleValueSelect
                              name={'xs'}
                              placeholder={'Выберите действие'}
                              value={action}
                              options={[
                                {label: 'Выберите действие', value: null},
                                {label: 'Активировать', value: 'activate'},
                                {label: 'Деактивировать', value: 'deactivate'},
                                {label: 'Перенести в раздел', value: 'attachCategories'},
                                {label: 'Скопировать в раздел', value: 'copyCategories'},
                                {label: 'Привязать к филиалу', value: 'attachBranch'},
                                {label: 'Отвязать от филиала', value: 'detachBranch'},
                                {label: 'Объединить по SKU', value: 'mergeSku'},
                                { label: 'Удалить', value: 'remove' },
                              ]}
                              onChange={(option: any) => {
                                setAction(option)
                              }}
                          />
                        {
                          (action === 'attachBranch' || action === 'detachBranch')  &&
                            <SimpleMultipleValueSelect
                                label={undefined}
                                textTooltip={undefined}
                                options={branches.map((branch: { title: any; id: any; }) => ({label: branch.title, value: branch.id}))}
                                value={brchs || []}
                                onChange={(value: any) => setBrchs(value)}
                                placeholder={'Выберите филиалы'}
                                errors={{}}
                                className={'w-[350px]'}
                            />
                        }
                        {
                          action === 'mergeSku' &&
                            <>
                            <SingleSimpleValueSelect
                                label={undefined}
                                textTooltip={undefined}
                                options={skuGroups.map(({title, innerTitle, id}: any) => {return {label: innerTitle ? innerTitle : title, value: id}})}
                                value={sku || null}
                                onChange={(value: any) => setSku(value)}
                                placeholder={'Выберите SKU группу'}
                                errors={{}}
                                wordsArray={['группа','группы','групп']}
                                className={'w-[390px]'}
                            />
                          <TextInputWithSearch
                            key={`mainSkuGood`}
                            //type={'text'}
                            name={`mainSkuGood`}
                            value={mainSkuGood}
                            //disabled={false}
                            onChange={(value: any) => {
                              setMainSkuGood(value)
                            }}
                            //readOnly={false}
                            placeholder={'Выберите основной товар'}
                            //className="grow"
                            errors={{}}
                            errorLabel={false}
                            dropDownData={allProducts.filter(({id}: any) => selectedRows.includes(id)).map(({title, id}: any) => {return {label: title, value: id}})}
                          />
                          </>
                        }
                        {
                          (action === 'copyCategories' || action === 'attachCategories')  &&
                            <SingleSimpleValueSelect
                                label={undefined}
                                textTooltip={undefined}
                                options={categories.map(({id, title}: any) => {return {label: title, value: id}})}
                                value={ctgs || null}
                                onChange={(value: any) => setCtgs(value)}
                                placeholder={'Выберите раздел'}
                                errors={{}}
                                wordsArray={['раздел','раздела','разделов']}
                                className={'w-[350px]'}
                            />
                        }
                      </div>

                      <BaseButton onClick={submitMassAction} className={"w-[250px] ml-5 bg-interactive-elem text-white"}>{action === 'remove' ? 'Удалить' : 'Изменить'} {selectedRows.length} товара
                      </BaseButton>
                  </div>}
                </div>
                :
                <div className="text-gray-40 text-3xl text-center mt-[35vh]">
                  {loading ? 'Загрузка...' : 'У вас еще нет товаров'}
                </div>
            }
          </>
          :
          <div className="text-gray-40 text-3xl text-center mt-[35vh]">
            Выберите филиал
          </div>
      }
    </div>
  )
}
