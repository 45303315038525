import React from "react";
import {useCompaniesContext} from "../../../../contexts/Companies";
import {useBranchesContext} from "../../../../contexts/Branches";

import useForm from "../../../../hooks/useForm";
import initForm from "../initForm";
import {apiClient} from "../../../../libs/api/apiClient";
import Breadcrumbs from "../../../../components/common/Breadcrumbs";
import BaseButton from "../../../../components/common/Button/BaseButton";
import Base from "./Base";
import {useNavigate} from "react-router-dom";
import {showErrorToast} from "../../../../libs/helpers/toasts";

export default function CreateOrdersTag() {
  const {company} = useCompaniesContext()
  const {branch} = useBranchesContext()

  const {data, updateData, updateField, updateFieldNoRender, errors, updateErrors, submit} = useForm(JSON.parse(JSON.stringify(initForm)))
  const navigate = useNavigate()

  const handleSubmit = () => {
    if (company && branch) {
      submit(async (form) => {
        apiClient.ordersTags.create(company.id, branch.id, {body: form}).then(({data, errors, message}) => {
          if (data) {
            updateData(data)
            navigate("/tags")
          } else {
            updateErrors(errors || {});
            showErrorToast({
              content: 'Что-то пошло не так'
            })
          }
        })
      })
    }
  }



  return (
    <div className='px-8 pb-8 pt-2 text-gray-50'>
      <div className='mb-8'>
        <div className='text-[44px] leading-[60px] mb-2'>Метки</div>
        <Breadcrumbs
          links={[
            {to: `/tags`, label: 'Список меток заказов'},
            {to: '', label: 'Создание метки заказов'},
          ]}
          back={`/tags`}
        />
      </div>
      <div className={'rounded-xl bg-white py-5'}>
        <Base errors={errors} data={data} updateField={updateField} updateFieldNoRender={updateFieldNoRender}/>
        <div className={'mt-6 border-gray-10 border-y-[1px] flex justify-end z-[50] w-full sticky bottom-0 bg-white'}>
          <BaseButton
            className={'w-[225px] bg-interactive-elem text-white m-4 py-[7px] '} onClick={handleSubmit}>Добавить метку
          </BaseButton>
        </div>
      </div>
    </div>
  )
}
