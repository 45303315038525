import React, { useState } from 'react'
import TextInput from './../../../components/common/form/input/TextInput';
import ChevronIcon from '../../../components/icons/ChevronIcon';
import TextInputMultipleList from '../../../components/common/form/input/TextInputMultipleList';

export default function ThModerator({data, updateField, errors}: any) {
  const [show, setShow] = useState(true)
  const [initialToken, setInitialToken] = useState(data.integrations.thModerator?.token)

  const updateThModerator = (name: any, value: any) => {
    updateField(`integrations.thModerator.${name}`, value)

    // Костыль. Объект thModerator обновляется полностью, кроме ключа
    const update = { ...data.integrations.thModerator }
    if (update.token === initialToken) {
      delete update.token
    }

    updateField(`integrations.thModerator`, update)
  }


  return (
    <div className={`${show ? 'h-auto' : 'h-[65px] overflow-hidden'}  border-[1px] border-gray-20 rounded-lg`}>
      <div onClick={() => setShow(!show)} className='flex items-center justify-between cursor-pointer p-4'>
        <div className='text-[22px] font-semibold'>TH Moderator</div>
        <ChevronIcon className={`${show && 'rotate-180'}`}/>
      </div>

      <div className='p-4'>
        <div className='grid grid-cols-2 gap-4 mb-4 items-end'>
          <TextInput
            label='Токен доступа'
            textTooltip='Токен доступа'
            errors={errors}
            placeholder='600'
            name={'integrations.thModerator.token'}
            value={initialToken}
            onChange={(value: any) => updateThModerator('token', value)}
            initialValueReplaceOnly={true}
          />

          <TextInput
            label='ID бота (Открыли приложение ВК)'
            textTooltip='ID бота (Открыли приложение ВК)'
            errors={errors}
            placeholder='1000'
            name={'integrations.thModerator.idOfBotsWhoOpenVkApp'}
            value={data.integrations.thModerator?.idOfBotsWhoOpenVkApp}
            onChange={(value: any) => updateThModerator('idOfBotsWhoOpenVkApp', value)}
          />

          <TextInput
            label='ID бота (Оформили заказ)'
            textTooltip='ID бота (Оформили заказ)'
            errors={errors}
            placeholder='300'
            name={'integrations.thModerator.idOfBotsWhoMakeOrder'}
            value={data.integrations.thModerator?.idOfBotsWhoMakeOrder}
            onChange={(value: any) => updateThModerator('idOfBotsWhoMakeOrder', value)}
          />

          <TextInput
            label='ID бота (Открыли сайт)'
            textTooltip='ID бота (Открыли сайт)'
            errors={errors}
            placeholder='90'
            name={'integrations.thModerator.idOfBotsWhoOpenSite'}
            value={data.integrations.thModerator?.idOfBotsWhoOpenSite}
            onChange={(value: any) => updateThModerator('idOfBotsWhoOpenSite', value)}
          />
        </div>

        <div className="flex flex-col gap-4">
          <TextInputMultipleList
            label='ID бота (Активировали промокод)'
            textTooltip='ID бота (Активировали промокод)'
            fields={['promocode', 'id']}
            fieldTypes={['input', 'input']}
            placeholders={['Промокод', 'ID группы']}
            name={'integrations.thModerator.idsOfBotsWhoUsePromocodes'}
            values={data.integrations.thModerator?.idsOfBotsWhoUsePromocodes || []}
            onChange={(value: any) => updateThModerator('idsOfBotsWhoUsePromocodes', value)}
            errors={errors}
          />

          {/*<TextInputMultipleList
            label='ID группы подписчиков (Открыли раздел каталога)'
            textTooltip='ID группы подписчиков (Открыли раздел каталога)'
            fields={['category', 'id']}
            fieldTypes={['input', 'input']}
            placeholders={['ID Раздела', 'ID группы']}
            name={'integrations.thModerator.idsOfBotsWhoOpenCatalog'}
            values={data.integrations.thModerator?.idsOfBotsWhoOpenCatalog || []}
            onChange={(value: any) => updateThModerator('idsOfBotsWhoOpenCatalog', value)}
            errors={errors}
          />

          <TextInputMultipleList
            label='ID группы подписчиков (Отфильтровали товары по метке)'
            textTooltip='ID группы подписчиков (Отфильтровали товары по метке)'
            fields={['tag', 'id']}
            fieldTypes={['input', 'input']}
            placeholders={['Код метки', 'ID группы']}
            name={'integrations.thModerator.idsOfBotsWhoIseFilterByTag'}
            values={data.integrations.thModerator?.idsOfBotsWhoIseFilterByTag || []}
            onChange={(value: any) => updateThModerator('idsOfBotsWhoIseFilterByTag', value)}
            errors={errors}
          />*/}
        </div>
      </div>
    </div>
  )
}
