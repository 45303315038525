import React, {useRef, useState} from "react";
// @ts-ignore
import ReactInputMask from "react-input-mask";
import TextInput from "./TextInput";

export default function PhoneInputMask(
  {
    mask = '+7(___)___-__-__',
    maskChar = '',
    alwaysShowMask = false,
    formatChars = {
      '_': '[0-9]',
      'a': '[A-Za-z]',
      '*': '[A-Za-z0-9]'
    },
    type='text',
    name,
    value,
    disabled = false,
    readOnly = false,
    onChange=(value: any)=>{},
    onClick=()=>{},
    onKeyDown=()=>{},
    autoComplete = 'off',
    className = '',
    classNameInput = '',
    placeholder = '',
    label = '',
    errors = {},
    icon = '',
    errorLabel = true,
    required = false,
    textTooltip = '',
    showEditSaveButton = false,
  }
) {
  const maskRef = useRef<any>(null)

  const [isEdit, setEdit] = useState<boolean>(false)

  const beforeMaskedValueChange = (newState: { selection: any; value?: any; }, oldState: any, currentState: any, userInput: any, maskOptions: any) => {
    const {value, selection} = newState;

    if (value && value.endsWith('(')) {
      return {value: '', selection: newState.selection}
    }

    if (value && (value.endsWith(')') || value.endsWith('-'))) {
      if (selection) {
        return {value, selection: {start: selection?.start + 1, end: selection?.end}}
      }
      return {value, selection: null}
    }

    return newState
  }

  return (
    <ReactInputMask
      ref={maskRef}
      mask={mask}
      maskChar={maskChar}
      alwaysShowMask={alwaysShowMask}
      formatChars={formatChars}
      onChange={onChange}
      value={value}
      disabled={disabled || (showEditSaveButton && !isEdit)}
      readOnly={readOnly}
      beforeMaskedValueChange={beforeMaskedValueChange}
    >
      {
        () => <TextInput
          type={type}
          name={name}
          onClick={onClick}
          onKeyDown={onKeyDown}
          autoComplete={autoComplete}
          className={className}
          classNameInput={classNameInput + (disabled || (showEditSaveButton && !isEdit) ? ' !text-gray-40 !pointer-events-none': '')}
          placeholder={placeholder}
          label={label}
          errors={errors}
          icon={icon}
          errorLabel={errorLabel}
          required={required}
          textTooltip={textTooltip}
          valueThrottle={false}
          getAuxElement={showEditSaveButton ? () => {
            return <span onClick={()=>setEdit(!isEdit)}>{isEdit? 'Сохранить':'Изменить'}</span>
          } : undefined}
        />
      }
    </ReactInputMask>
  )
}
