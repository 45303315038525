import React, { useEffect, useState } from 'react'
import BaseButton from '../../../components/common/Button/BaseButton'
import ScheduleTime from '../../../components/common/form/ScheduleTime';
import CheckboxList from './../../../components/common/form/checkbox/CheckboxList';
import fetchPlatforms from '../../../providers/dictionaries/platforms';

export default function Show({ data, updateField, errors }: any) {
  const [platforms, setPlatforms] = useState([])

  useEffect(() => {
    fetchPlatforms().then(data => {
      setPlatforms(data.map(platform => ({ label: platform.label, name: platform.value })))
    })
  }, [])

  return (
    <div className={'flex flex-col px-3 gap-y-6'}>
          <ScheduleTime
            label={'Показать в каталоге'}
            textTooltip={'Показать в каталоге'}
            notActiveLabel={'Не показывать'}
            name='openHours'
            value={data.openHours}
            onChange={value => updateField('openHours', value)}
            errors={errors}
          />

          <CheckboxList
            label='Платформы'
            name='platforms'
            errors={errors}
            options={platforms}
            values={data.platforms}
            onChange={value => updateField('platforms', value)}
          />
    </div>
  )
}
