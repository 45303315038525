import React from "react";
import DataTable from "../../../components/common/Table/DataTable";
import {createColumnHelper} from "@tanstack/react-table";
import {useLoyaltyTransactionsContext} from "../../../contexts/LoyaltyPrograms";
import { getDateAndTime } from "../../../libs/helpers/datetime";

export default function TableTransactions() {
  const {transactions, updateTransactions, loading, pagination}: any = useLoyaltyTransactionsContext();

  const columnHelper = createColumnHelper<any>()

  const columns = [
    columnHelper.accessor(row => row.id, {
      id: 'id',
      header: () => 'ID',
      cell: info => <div
        className={'w-[8%] font-normal break-all flex items-center pl-2'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[8%] flex justify-start text-center pl-2',
      }
    }),
    columnHelper.accessor(row => row.createdAt, {
      id: 'createdAt',
      header: () => 'Дата (GMT +3)',
      cell: info => <div className={'w-[25%] grow-[0.2] font-normal break-all flex items-center pl-2'}>{getDateAndTime(info.getValue())}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[25%] grow-[0.2] flex justify-start text-center pl-2',
        sortField: 'createdAt'
      }
    }),
    columnHelper.accessor(row => row.customer, {
      id: 'phone',
      header: () => 'Клиент (Телефон)',
      cell: info => <div className={`w-[30%] grow font-normal break-all flex justify-start items-center pl-2 ${info.getValue() ? '' : 'text-error-main'}`}>{info.getValue()?.phone || 'Клиент не найден'}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[30%] grow flex justify-start pl-2',
        sortField: 'phone'
      }
    }),
    columnHelper.accessor(row => row.amount, {
      id: 'type',
      header: () => 'Тип транзакции',
      cell: info => <div
        className={`w-[30%] grow font-normal break-all flex items-center justify-center ${info.getValue() < 0 ? 'text-error-main' : 'text-interactive-elem'}`}>
          {info.getValue() < 0 ? 'Списание' : 'Зачисление'}
        </div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[30%] grow flex justify-center',
      }
    }),
    columnHelper.accessor(row => row.amount, {
      id: 'amount',
      header: () => 'Сумма',
      cell: info => <div
        className={'w-[15%] font-normal break-all flex items-center justify-center'}>{Math.abs(info.getValue())}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[15%] flex justify-center',
      }
    }),
  ]


  return (
    <>
      <DataTable
        data={transactions}
        columns={columns}
        usePagination={true}
        pagination={pagination}
        updateData={(params: any) => {updateTransactions(params)}}
        loading={loading}
        useMassAction={false}
        classNameTable={'mt-0'}
        defaultLimit={10}
        defaultPage={1}
      />
    </>
  )
}
