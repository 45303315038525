import React, { useEffect, useId, useRef, useState } from "react";
import { ColorPicker, toColor, useColor } from "react-color-palette";
import closeIcon from '../../../../images/close.svg';
import "../../../../styles/colorPickerSelect.sass"
import Tooltip from "../../Tooltip";

export default function ColorPickerSelect({
  name,
  value,
  defaultColor = '#121212',
  disabled = false,
  readOnly = true,
  onChange = (value) => { },
  onClick = () => { },
  onKeyDown = () => { },
  autoComplete = 'off',
  className = '',
  classNameInput = '',
  placeholder = '',
  label = '',
  errors = {},
  icon = '',
  errorLabel = true,
  required = false,
  textTooltip = '',
}) {
  const id = useId();
  const input = useRef(null);
  const error = errors[name];
  const [show, setShow] = useState(false);
  const wrapper = useRef<any>(null);
  const [color, setColor] = useColor("hex", defaultColor);

  /* Закрываем при клике вне поля */
  useEffect(() => {
    function handleClick(event) {
      if (wrapper.current && !wrapper.current.contains(event.target)) {
        setShow(false);
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }

  }, [show, setShow, wrapper]);

  const onChangeColor = (color) => {
    setColor(color)
    onChange(color.hex || '')
  }

  useEffect(() => {
    delete errors[name];
  }, [value]);

  useEffect(() => {
    setColor(toColor('hex', defaultColor))
  }, [defaultColor])

  return (
    <div className={`${className}`}>
      {
        label &&
        <label className="flex items-center mb-2 font-medium text-sm">
          {label}
          <span className='text-error-main'>{required && '*'}</span>
          {
            textTooltip && <Tooltip textTooltip={textTooltip} defaultText={name} />
          }
        </label>
      }
      <div className="relative">
        <input
          id={id}
          type="text"
          name={name}
          value={color.hex}
          disabled={disabled}
          onChange={({ target }) => onChange(target.value || '')}
          onClick={() => { setShow(show => !show) }}
          onKeyDown={onKeyDown}
          readOnly={readOnly}
          className={`outline-0 disabled:bg-gray-10 disabled:text-gray-40 disabled:pointer-events-none text-gray-50 py-[10px] px-[14px] w-full text-sm bg-white border-gray-20 border-[1px] rounded-lg pr-[40px] ${classNameInput} ${error && 'border border-error-font' || 'border-0'}`}
          placeholder={placeholder}
          autoComplete={autoComplete}
          ref={input}
        />
        <div
          className={`absolute top-1/2 -translate-y-1/2 pointer-events-none right-[10px] w-[30px] h-[30px] rounded-lg`}
          style={{ backgroundColor: color.hex }}
        />
        <div className={`fixed grid w-full h-full modal-wrapper ${!show && 'hidden'}`}>
          <div ref={wrapper} className={'grid relative bg-white rounded-[10px] modal'}>
            <div className={'flex justify-between px-[10px] py-[13px] items-center'}>
              <span>Выбор цвета</span>
              <img src={closeIcon} className={'w-[20px]'} onClick={() => setShow(false)} alt="" />
            </div>
            <ColorPicker width={300} height={200} color={color} onChange={(color) => onChangeColor(color)} onChangeComplete={(color) => { onChangeColor(color) }} hideHSV alpha={true} />
          </div>

        </div>
      </div>


      {error && errorLabel && (<span className={'text-error-font text-xs font-medium'}>{error}</span>)}
    </div>
  )
}
