import React, {useEffect} from "react";
import DataTable from "../../../components/common/Table/DataTable";
import {createColumnHelper} from "@tanstack/react-table";
import {Link} from "react-router-dom";
import {useOrdersTagsContext} from "../../../contexts/Tags";
import TagLabel from "../../../components/common/form/select/datatable/tags/TagLabel";

export default function TableOrdersTags({selected, setSelected, search = null, massUpdate, setMassUpdate}: any) {
  const {ordersTags, updateOrdersTags, limits, filters, loading, updateFilters, pagination}: any = useOrdersTagsContext()

  const columnHelper = createColumnHelper<any>()

  useEffect(() => {
    if (massUpdate) {
      updateOrdersTags(limits, filters)
      setMassUpdate(false)
    }
  }, [massUpdate])

  useEffect(() => {
    updateFilters('search', search || null)
    //updateOrdersTags(limits, filters)
  }, [search])

  const columns = [
    columnHelper.accessor(row => row.id, {
      id: 'id',
      header: () => 'ID',
      cell: info => <div
        className={'w-[75px] font-normal break-all flex items-center ml-5'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[75px] flex justify-start text-center ml-5',
        sortField: 'id'
      }
    }),
    columnHelper.accessor(row => row.code, {
      id: 'code',
      header: () => 'Код',
      cell: info => <div
        className={'w-[130px] font-normal break-all flex items-center px-2'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[130px] flex justify-start text-center px-2 ',
        sortField: 'code'
      }
    }),
    columnHelper.accessor(row => row.title, {
      id: 'title',
      header: () => 'Метки',
      cell: info => <div
        className={'grow font-normal break-all flex items-center pl-2'}>
        <Link to={info.row?.original?.id != null ? `/tags/${info.row?.original?.id}/edit-orders-tag` : '/tags'}>
          <TagLabel
            textColor={info.row?.original?.color}
            backgroundColor={info.row?.original?.background}>
            {info.getValue()}
          </TagLabel>
        </Link>
      </div>,
      enableSorting: true,
      meta: {
        widthClass: 'grow flex justify-start text-center pl-2',
        sortField: 'title'
      }
    }),
  ]

  return (
    <DataTable
      data={ordersTags || []}
      columns={columns}
      usePagination={true}
      defaultLimit={5}
      defaultPage={1}
      pagination={pagination}
      updateData={(params: any) => updateOrdersTags(params, filters)}
      loading={loading}
      useMassAction={true}
      selectedRows={selected}
      onChangeSelectionRows={(values: any) => setSelected(values)}
      classNameTable={'mt-0'}
    />
  )
}
