import React, { useEffect } from 'react'
import TextInput from '../../../components/common/form/input/TextInput'
import SingleSimpleSelect from '../../../components/common/form/select/SingleSimpleSelect'
import BaseButton from '../../../components/common/Button/BaseButton'
import ImageLoadMultiple from '../../../components/common/form/image/ImageLoadMultiple'

export default function Base({data, errors, timeZones=[], updateField, updateFieldNoRender}: any) {
  useEffect(() => {
    updateField('base.__time_zone_select', timeZones.at(0))
  }, [timeZones])

  return (
    <div className={'flex flex-col px-3 gap-y-6'}>
        <TextInput
          label='Название филиала'
          textTooltip='Название филиала'
          placeholder='Введите название филиала'
          name={'base.title'}
          errors={errors}
          onChange={(value: any) => updateFieldNoRender('base.title', value)}
          value={data.base.title}
          required={true}
        />

        <SingleSimpleSelect
          textTooltip='GMT (Часовой пояс)'
          label='GMT (Часовой пояс)'
          placeholder='GMT+3 (Киров, Кировская область)'
          name={'base.timeZoneId'}
          errors={errors}
          options={timeZones}
          onChange={(option: any) => {
            updateField('base.__time_zone_select', option)
            updateField('base.timeZoneId', option.value)
          }}
          value={data?.base?.__time_zone_select}
          required={true}
        />

        <ImageLoadMultiple
          label="Слайды в шапке ПК (формат: JPG, JPEG, PNG. Можно загрузить до 10 изображений)"
          onChange={(value: any) => updateField('base.slidesDesktop', value)}
          value={data.base.slidesDesktop}
          errors={errors}
          name='base.slidesDesktop'
          max={10}
          resizeTo={[-1, -1]}
          forceAspectRatio={215/71}
          classNameImgWrapper={'!h-[88px]'}
        />

        <ImageLoadMultiple
          label="Слайды в шапке Смартфон (формат: JPG, JPEG, PNG. Можно загрузить до 10 изображений)"
          onChange={(value: any) => updateField('base.slidesMobile', value)}
          value={data.base.slidesMobile}
          errors={errors}
          name='base.slidesMobile'
          max={10}
          resizeTo={[440, 440]}
          classNameImgWrapper={'!h-[88px]'}
        />

        {/*<CityLocation
          value={data.base.city}
          onChange={value => updateField('base.city', value)}
          name='base.city'
          placeholder='Введите краткое описание'
          textTooltip='Город'
          label='Город'
          required={true}
          errors={errors}
        />*/}

    </div>
  )
}
