import React, { useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { Link } from "react-router-dom";
import BaseButton from "../../components/common/Button/BaseButton";
import DataTable from "../../components/common/Table/DataTable";
import { apiClient } from "../../libs/api/apiClient";
import { useCompaniesContext } from "../../contexts/Companies";
import editIcon from "../../images/pencil.svg";
import removeIcon from "../../images/delete.svg";
import Actions from "../../components/common/Table/Actions";
import { useEmployeesContext } from "../../contexts/Employees";
import { showErrorToast, showSuccessToast } from "../../libs/helpers/toasts";
import { dialogModal } from "../../components/common/Modal/DialogModal";
import { useBranchesContext } from "../../contexts/Branches";

export default function Employees() {
  const { company }: any = useCompaniesContext();
  const {
    employees,
    loading,
    filters,
    limits,
    pagination,
    updateFilters,
    updateEmployees,
  } = useEmployeesContext();
  const columnHelper = createColumnHelper();
  const [search, setSearch] = useState("");
  const [applyFilters, setApplyFilters] = useState(false);
  const { branch }: any = useBranchesContext();
  const columns = [
    columnHelper.accessor((row) => row.id, {
      id: "ID",
      header: () => "ID",
      cell: (info) => (
        <div className={"w-[10%] pl-4 flex items-center"}>
          {info.getValue()}
        </div>
      ),
      enableSorting: false,
      meta: {
        widthClass: "w-[10%] pl-4",
      },
    }),
    columnHelper.accessor((row) => row.name, {
      id: "companyName",
      header: () => "Имя сотрудника",
      cell: (info) => (
        <div className={"w-[40%] flex items-center"}>{info.getValue()}</div>
      ),
      enableSorting: true,
      meta: {
        widthClass: "w-[40%]",
        sortField: "name",
      },
    }),
    columnHelper.accessor((row) => row.vkId, {
      id: "active",
      header: () => "VK ID",
      cell: (info) => (
        <div className={"w-[25%] flex flex-row items-center"}>
          {info.getValue()}
        </div>
      ),
      enableSorting: false,
      meta: {
        widthClass: "w-[25%]",
      },
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => (
        <div className={"w-[25%] flex items-center justify-end pr-4 gap-4"}>
          <Link to={`/employees/${props.row?.original?.id}/edit`}>
            <img src={editIcon} alt="" />
          </Link>
          <div onClick={() => removeEmployee(props.row?.original?.id)}>
            <img src={removeIcon} alt="" />
          </div>
        </div>
      ),
    }),
  ];

  const removeEmployee = (id: any) => {
    dialogModal({
      isOpen: false,
      title: "Внимание!",
      text: "Вы уверены что хотите удалить сотрудника?",
      showCancelButton: true,
      confirmButtonText: "Удалить",
      cancelButtonText: "Вернуться",
    })
      .then((result) => {
        if (result.isConfirmed) {
          apiClient.employees
            .remove(company.id, id)
            .then(({ data, errors }: any) => {
              showSuccessToast({
                content: "Сотрудник удален успешно",
              });
              updateEmployees();
            })
            .catch((err) => {
              //updateErrors(errors || {})
              showErrorToast({
                content: "Что то пошло не так!",
              });
            });
        }
      })
      .catch(() => {});
  };

  return (
    <div className="px-8 pb-8 pt-3">
      <div className="flex justify-between items-end">
        <div>
          <div className="text-gray-50 text-[44px] leading-[60px] mb-2">
            Сотрудники
          </div>
          <div className="text-gray-40 text-base">Список сотрудников</div>
        </div>

        {!applyFilters && employees?.length === 0 && branch && (
          <Link to={"/employees/create"}>
            <BaseButton className={"bg-interactive-elem text-white"}>
              Добавить сотрудника
            </BaseButton>
          </Link>
        )}
      </div>
      {branch ? 
        <>
          {applyFilters || employees?.length > 0 ? 
            <div>
              <Actions
                updateValue={(value: any) => {
                  setSearch(value || "");
                }}
                fetchData={(value: any) => {
                  setApplyFilters(true);
                  updateFilters("search", value || null);
                  updateEmployees(limits, filters);
                }}
                searchValue={search}
                classNameChildren={"w-1/2 flex items-center justify-end"}
              >
                <Link to={"/employees/create"}>
                  <BaseButton className={"bg-interactive-elem text-white"}>
                    Добавить сотрудника
                  </BaseButton>
                </Link>
              </Actions>
              <DataTable
                data={employees}
                columns={columns}
                usePagination={true}
                defaultLimit={10}
                defaultPage={1}
                pagination={pagination}
                updateData={(params: object) =>
                  updateEmployees(params, filters)
                }
                loading={loading}
                useMassAction={false}
                selectedRows={[]}
              />
            </div>
          : 
            <div className="text-gray-40 text-3xl text-center mt-[35vh]">
              Добавьте сотрудника
            </div>
          }
        </>
      : 
        <div className="text-gray-40 text-3xl text-center mt-[35vh]">
          Выберите филиал
        </div>
      }
    </div>
  );
}
