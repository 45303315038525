import React, {useEffect, useRef, useState} from "react";
import ChevronIcon from "../../../../icons/ChevronIcon";
import DropDown from "./DropDown";
import CheckIcon from "../../../../icons/CheckIcon";
import Tooltip from "../../../Tooltip";
import {declOfNum} from "../../../../../libs/helpers/words";

export default function SimpleMultipleSelect({
  options = [],
  value,
  onChange,
  name,
  label = '',
  placeholder,
  required = false,
  textTooltip = '',
  errors={},
  errorLabel = true,
  className='',
  wordsArray = ['филиал','филиала','филиалов']
}) {
  const [show, setShow] = useState(false);
  const wrapper = useRef<any>(null);
  const error = errors[name];

  useEffect(() => {
    delete errors[name];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  /* Закрываем при клике вне поля */
  useEffect(() => {
    function handleClick(event: any) {
      if (wrapper.current && !wrapper.current.contains(event.target)) {
        setShow(false);
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }

  }, [show, setShow, wrapper]);


  return (
    <div className={`${className}`}>
      {
        label &&
        <label className="flex items-center mb-2 font-medium text-sm">
          { label }
            <span className='text-error-main'>{required && '*'}</span>
          {
            textTooltip && <Tooltip textTooltip={textTooltip} defaultText={name}/>
          }
        </label>
      }
      <div ref={wrapper} className={'relative w-full'}>
        <div className="flex items-center justify-between py-[10px] px-[14px] cursor-pointer w-full text-sm bg-white border-gray-20 border-[1px] rounded-lg" onClick={() => setShow(show => !show)}>
          <div className="flex gap-2 items-center">
            <div
              onClick={() => {
                setShow(show => !show)
                if (value === '*') {
                  onChange([])
                } else {
                  if (value.length) {
                    onChange([])
                  } else {
                    onChange('*')
                  }
                }
              }}
            >
              {
                value === '*' ? <div className="flex items-center justify-center h-[18px] w-[18px] border-[2px] border-interactive-elem bg-interactive-elem rounded-sm m-[3px]"><CheckIcon color="white"/></div> :
                <>
                  {
                    value.length ? <div className="flex items-center justify-center h-[18px] w-[18px] border-[2px] border-interactive-elem rounded-sm m-[3px]">
                      <div className="w-2 h-[2px] rounded-sm bg-interactive-elem"></div>
                    </div> :
                    <div className="h-[18px] w-[18px] border-[2px] border-interactive-elem rounded-sm m-[3px]"></div>
                  }
                </>
              }
            </div>
            <div>
              {
                value === '*' ? <span>Все</span> :
                <>
                  {
                    value.length ? <span className="text-ellipsis overflow-hidden whitespace-nowrap">{ `${value.length} ${declOfNum(value.length, wordsArray)}` }</span> :
                    <span className="text-gray-20">{ placeholder }</span>
                  }
                </>
              }
            </div>
          </div>

          <ChevronIcon/>
        </div>

        <DropDown items={options} value={value === '*' ? options : value} setShow={setShow} show={show} onChange={(option) => onChange(option)}/>
      </div>
      {error && errorLabel && (<span className={'text-error-font text-xs font-medium'}>{error}</span>)}
    </div>
  )
}
