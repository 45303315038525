import React from "react"
import { Link } from "react-router-dom"
import { useBranchesContext } from "../../../contexts/Branches"
import { useFormContext } from "../../../contexts/Form";

export const IntegrationLink = ({ link, currentUrl }: any) => {
    const { branch } = useBranchesContext()
    const { checkIsFormDirty, openDialog} = useFormContext()

    if (branch && branch?.integrations?.defaultExternalIntegration == link?.integration) {
        return (
            <Link key={link.url + link.label} to={link.url} onClick={(event) => {
                if (checkIsFormDirty()) {
                    event.preventDefault()
                    openDialog(link.url)
                }
            }}>
                <div
                    className={`${currentUrl === link.url ? 'bg-zinc-600' : 'bg-gray-40'} bg-gray-40 text-sm h-11 pl-8 flex items-center`}
                >
                    <div className="">{link.label}</div>
                </div>
            </Link>
        )
    }

    return <></>

}
