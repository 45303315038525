import React, {useEffect, useState} from 'react'
import Breadcrumbs from './../../../components/common/Breadcrumbs';
import useForm from '../../../hooks/useForm'
import {apiClient} from "../../../libs/api/apiClient";
import {useNavigate, useParams} from "react-router-dom";
import {checkCompanyAction} from "../../../libs/helpers/permissions";
import initForm from "../initForm";
import tabs from "../tabs";
import FormWrapper from "../../../components/common/form/FormWrapper";
import EditFormFields from "../../../components/common/form/EditFormFields";
import MainSettings from "../forms/MainSettings";
import Requisites from "../forms/Requisites";
import Loyalty from "../forms/Loyalty";
import Statuses from "../forms/Statuses";
import Tags from "../forms/Tags";
import Templates from "../forms/Templates";
import {showErrorToast, showSuccessToast} from "../../../libs/helpers/toasts";
import {dialogModal} from "../../../components/common/Modal/DialogModal";
import {fetchLoyaltyPrograms} from "../../../providers/dictionaries/loyaltyPrograms";
import {fetchRequiredOrderStatuses} from "../../../providers/dictionaries/companies";
import { useCompaniesContext } from '../../../contexts/Companies';
import {ICONS_TAG} from "../defaultIcons";
import { useFormContext } from '../../../contexts/Form';


const loyalties = [
  {value: 'none', label: 'Не использовать'},
  //{value: 'iiko', label: 'IIKO'},
  {value: 'frontPad', label: 'Front Pad'},
  {value: 'partner', label: 'Партнер'},
  {value: 'resultRest', label: 'Result.Rest'},
]

const domainsOptions = [
  {value: 'externalDomain', label: 'Свой домен'},
  {value: 'internalDomain', label: 'Технический домен'},
]

export default function EditCompany() {
  const { updateCompanies } = useCompaniesContext()
  const {needUpdate, setNeedUpdate} = useFormContext()
  const {id} = useParams<{id?: string}>()
  const navigate = useNavigate()
  const [tab, setTab] = useState('main')
  const [currentActiveDomainProgram, setCurrentActiveDomainProgram] = useState(1)
  const [requiredStatuses, setRequiredStatuses] = useState([])
  const [loyaltyPrograms, setLoyaltyPrograms] = useState(loyalties)
  const [loading, setLoading] = useState(false)

  const {
    data,
    updateData,
    errors,
    updateErrors,
    submit,
    updateField,
    changedFieldsRefresh,
    isDirty
  } = useForm(JSON.parse(JSON.stringify(initForm)));

  const denyRemoveCompany = !checkCompanyAction('delete', id)

  const getCompanyInfo = (id: any) => {
    fetchRequiredOrderStatuses().then((statuses) => {
      setRequiredStatuses(statuses)

      apiClient.companies.get(id).then(({data, errors}: any) => {
        if (data) {
          if (data.internalDomain && data.internalDomain !== '') {
            setCurrentActiveDomainProgram(1)
          } else if (data.externalDomain && data.externalDomain !== '') {
            setCurrentActiveDomainProgram(0)
          }
          
          data.statuses.forEach((item: any) => {
            let currentIndex = statuses.findIndex((x: any) => x.code == item.code)
            item.edit = false;
            item.required = currentIndex >= 0;
          })

          data.tags.sort((a: any, b: any) => {
            return (a.id > b.id) ? 1 : (a.id < b.id) ? -1 : 0
          }).forEach((item: any) => {
            item.edit = false
            item.iconSrc = item.icon
            //??возможныe костыли??

            //Тут удаляем, так как возвращается строка, а отправить надо файл
            delete item.icon

            // Тут мы делаем глубокую копию массива стандартных иконок
            // сделано, чтобы в каждой модалке было свое выделение иконок перед выбором
            item.__standartIcons = JSON.parse(JSON.stringify(ICONS_TAG))
          })
          updateData(data)
        } else {
          //console.log(errors)
          updateErrors(errors || {})
        }
      }).catch(() => {})
    }).catch(() => {})
  }

  const reset = () => {
    navigate('/companies')
  }

  const removeCompany = () => {
    dialogModal({
      isOpen: false,
      title: 'Внимание!',
      text: 'Вы уверены что хотите удалить компанию?',
      showCancelButton: true,
      confirmButtonText: 'Да',
      cancelButtonText: 'Нет',
    }).then((result: any) => {
      if (result.isConfirmed) {
        apiClient.companies.remove(id)
          .then(() => {
            showSuccessToast({
              content: 'Компания удалена успешно'
            })
            updateCompanies()
            navigate('/')
          }).catch((err) => {
            updateErrors(errors || {})
            showErrorToast({
              content: 'Что-то пошло не так'
            })
        })
      }
    }).catch((e) => {
      console.log(`err value = ${e}`)
    })
  }

  useEffect(() => {
    fetchLoyaltyPrograms().then((data) => {
      setLoyaltyPrograms(data)
    }).catch(() => {})
    fetchRequiredOrderStatuses().then((data) => {
      setRequiredStatuses(data)
    }).catch(() => {}).finally(() => {
      getCompanyInfo(id)
    })
  }, [id])

  const handleSubmit = (tab = null) => {
    if (data.tags)
      data.tags = data.tags.map((tag: any) => {
        tag = {...tag};
        if (typeof(tag.icon) === 'string' || tag.icon == null) {
          delete(tag.icon)
        }
        return tag;
      })
    submit((form: any) => {
      setLoading(true)
      apiClient.companies.update(id, { body: form })
        .then(({data, errors}: any) => {
            if (data) {
              changedFieldsRefresh()
              getCompanyInfo(id)
              showSuccessToast({
                autoClose: 3000,
                content: 'Компания успешно изменена'
              })
              updateCompanies()
              if (tab && typeof tab === "string") {
                setTab(tab)
              }
            } else {
              updateErrors(errors || {})
              showErrorToast({
                content: 'Что-то пошло не так'
              })
            }
          }
        ).catch((e) => {
          console.log(e)
        }).finally(() => {
          setLoading(false)
          setNeedUpdate(false)
        })
    }, 'changed')
  }

  useEffect(() => {
    if (needUpdate) {
      handleSubmit()
    }
  }, [needUpdate])

  const changeTab = (tab: any) => {
    if (Object.keys(isDirty()).length !== 0) {
      dialogModal({
        isOpen: false,
        title: 'Не сохраненные данные!',
        text: 'Вы действительно хотите перейти на следующий шаг? Все не сохраненные данные будут удалены',
        showDenyButton: true,
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: 'Сохранить и продолжить',
        denyButtonText: `Без сохранения`,
        cancelButtonText: 'Вернуться',
      }).then((result: any) => {
        if (result.isConfirmed) {
          handleSubmit(tab)
          //setTab(tab)
        } else if (result.isDenied) {
          changedFieldsRefresh()
          getCompanyInfo(id)
          setTab(tab)
        }
      }).catch((e) => {
        //console.log(`err value = ${e}`)
      })
    } else {
      setTab(tab)
    }
  }


  return (
    <>
      <div className='px-8 pb-8 pt-3 text-gray-50'>
        <div className='mb-8'>
          <div className='text-[44px] leading-[60px] mb-2'>Компании</div>
          <Breadcrumbs
            links={[
              {to: '/', label: 'Список компаний'},
              {to: '', label: 'Изменение компании'},
            ]}

            back='/'
          />
        </div>
          <FormWrapper
            tabs={tabs}
            tab={tab}
            setTab={changeTab}
          >
            <EditFormFields
              show={tab === 'main'}
              denyRemoveAction={denyRemoveCompany}
              remove={removeCompany}
              reset={reset}
              back={'/companies'}
              handleSubmit={handleSubmit}
              submitLoading={loading}
            >
              <MainSettings
                data={data}
                updateField={updateField}
                errors={errors}
                domainsPrograms={domainsOptions}
                currentActiveDomainProgram={currentActiveDomainProgram}
              />
            </EditFormFields>

            <EditFormFields
              show={tab === 'requisites'}
              denyRemoveAction={denyRemoveCompany}
              remove={removeCompany}
              reset={reset}
              back={'/companies'}
              handleSubmit={handleSubmit}
              submitLoading={loading}
            >
              <Requisites
                data={data}
                updateField={updateField}
                errors={errors}
              />
            </EditFormFields>

            <EditFormFields
              show={tab === 'loyalty'}
              denyRemoveAction={denyRemoveCompany}
              remove={removeCompany}
              reset={reset}
              back={'/companies'}
              handleSubmit={handleSubmit}
              submitLoading={loading}
            >
              <Loyalty
                data={data}
                updateField={updateField}
                errors={errors}
                loyaltyPrograms={loyaltyPrograms}
              />
            </EditFormFields>

            <EditFormFields
              show={tab === 'statuses'}
              denyRemoveAction={denyRemoveCompany}
              remove={removeCompany}
              reset={reset}
              back={'/companies'}
              handleSubmit={handleSubmit}
              classNameWrapper={'relative'}
              submitLoading={loading}
            >
              <Statuses
                data={data}
                updateField={updateField}
                updateData={updateData}
                errors={errors}
                isEdit={true}
                requiredStatuses={requiredStatuses}
              />
            </EditFormFields>

            <EditFormFields
              show={tab === 'tags'}
              denyRemoveAction={denyRemoveCompany}
              remove={removeCompany}
              reset={reset}
              back={'/companies'}
              handleSubmit={handleSubmit}
              classNameWrapper={'relative'}
              submitLoading={loading}
            >
              <Tags
                data={data}
                updateField={updateField}
                updateData={updateData}
                errors={errors}
                icons={ICONS_TAG}
              />
            </EditFormFields>

            <EditFormFields
              show={tab === 'templates'}
              denyRemoveAction={denyRemoveCompany}
              remove={removeCompany}
              reset={reset}
              back={'/companies'}
              handleSubmit={handleSubmit}
              submitLoading={loading}
            >
              <Templates
                data={data}
                updateField={updateField}
                errors={errors}
              />
            </EditFormFields>
          </FormWrapper>
      </div>
    </>
  )
}
