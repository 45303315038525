import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import {
  CompaniesTagsContextWrapper,
  CustomersTagsContextWrapper,
  OrdersTagsContextWrapper,
  PromoTagsContextWrapper,
} from "../../contexts/Tags";

import tabs from "./tabs";
import FormWrapper from "../../components/common/form/FormWrapper";
import BaseButton from "../../components/common/Button/BaseButton";
import CreateFormFields from "../../components/common/form/CreateFormFields";
import TablePromoTags from "./Promo";
import TableOrdersTags from "./Orders";
import TableCompaniesTags from "./Companies";
import Actions from "../../components/common/Table/Actions";
import TableCustomersTags from "./Customers";
import {useBranchesContext} from "../../contexts/Branches";
import SimpleMultipleValueSelect from "../../components/common/form/select/multiple/SimpleMultipleValueSelect";
import useForm from "../../hooks/useForm";
import {submitAction} from "./massActions";
import SingleSimpleValueSelect from "../../components/common/form/select/SingleSimpleValueSelect";
import {useCompaniesContext} from "../../contexts/Companies";
import {showSuccessToast} from "../../libs/helpers/toasts";

export default function Tags() {

  const allOutputTypes = [
    {label: 'Выберите действие', value: null},
    //{label: 'Активировать (не робит нужен бек)', value: 'activate'},
    //{label: 'Деактивировать (не робит нужен бек)', value: 'deactivate'},
    {label: 'Привязать к филиалу', value: 'attach'},
    {label: 'Отвязать от филиала', value: 'detach'},
    {label: 'Удалить', value: 'delete'},
    //{label: 'Копировать (не робит нужен бек)', value: 'copy'},
  ];

  const {company} = useCompaniesContext()
  const {branch, branches} = useBranchesContext()
  const {data, updateField, updateData, submit, changedFieldsRefresh} = useForm({branches: []})

  const [search, setSearch] = useState('')
  const [tab, setTab] = useState('orders')
  const [outputType, setOutputType] = useState('');
  const [selectedOrdersTag, setSelectedOrdersTag] = useState<number[]>([])
  const [selectedClientsTag, setSelectedClientsTag] = useState<number[]>([])
  const [selectedPromoTag, setSelectedPromoTag] = useState<number[]>([])
  const [selectedCompanyTag, setSelectedCompanyTag] = useState<number[]>([])

  const [ordersTagUpdate, setOrdersTagUpdate] = useState<boolean>(false)
  const [clientsTagUpdate, setClientsTagUpdate] = useState<boolean>(false)
  const [promoTagUpdate, setPromoTagUpdate] = useState<boolean>(false)
  const [companyTagUpdate, setCompanyTagUpdate] = useState<boolean>(false)

  useEffect(() => {
    setOutputType('')
    changedFieldsRefresh()
  }, [tab]);


  const submitMassAction = () => {
    if (outputType === null) return
    if (tab === 'promo') {
      updateField('tags', selectedPromoTag)
    } else if (tab === 'companies') {
      updateField('tags', selectedCompanyTag)
    } else if (tab === 'orders') {
      updateField('tags', selectedOrdersTag)
    } else if (tab === 'customers') {
      updateField('tags', selectedClientsTag)
    }

    submit( (form: any) => {
      //console.log([...form.entries()])
      submitAction(() => {
        showSuccessToast({
          content: 'Действие выполнено успешно'
        })
        changedFieldsRefresh()
        setOutputType('')
        updateData({branches: []})
        setSelectedCompanyTag([])
        setSelectedClientsTag([])
        setSelectedPromoTag([])
        setSelectedOrdersTag([])

        if (outputType === 'delete') {
          if (tab === 'promo') {
            setPromoTagUpdate(true)
          } else if (tab === 'companies') {
            setCompanyTagUpdate(true)
          } else if (tab === 'orders') {
            setOrdersTagUpdate(true)
          } else if (tab === 'customers') {
            setClientsTagUpdate(true)
          }
        }
      }, outputType, tab, company.id, branch.id, form)
    })

  }

  if (!branch) {
    return <div>
      <div className="px-8 pb-8 pt-2">
        <div className="flex flex-col justify-between">
          <div>
            <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Метки</div>
            <div className="text-gray-40 text-base">Список меток</div>
          </div>
          <div className="text-gray-40 text-3xl text-center mt-[35vh]">
            Выберите филиал
          </div>
        </div>
      </div>
    </div>
  }

  return (
    <div className="px-8 pb-8 pt-2">
      <div className="flex flex-col">
        <div className="flex items-end">
          <div>
            <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Метки</div>
            <div className="text-gray-40 text-base">Список меток</div>
          </div>
        </div>
        <div>
          <Actions
            updateValue={(value: any) => {
              setSearch(value || '')
            }}
            fetchData={(value: any) => {
              console.log('fetch')
              setCompanyTagUpdate(true)
              setClientsTagUpdate(true)
              setOrdersTagUpdate(true)
              setPromoTagUpdate(true)
            }}
            searchValue={search}
            classNameChildren={'w-1/2 flex items-center justify-end'}
          >
            {tab === 'orders' && <Link to={'create-orders-tag'}>
                <BaseButton className={"ml-5 bg-interactive-elem text-white"}>Добавить метку</BaseButton>
            </Link>}
            {tab === 'customers' && <Link to={'create-customers-tag'}>
                <BaseButton className={"ml-5 bg-interactive-elem text-white"}>Добавить метку</BaseButton>
            </Link>}
            {tab === 'promo' && <Link to={'create-promo-tag'}>
                <BaseButton className={"ml-5 bg-interactive-elem text-white"}>Добавить метку</BaseButton>
            </Link>}
            {tab === 'companies' && <Link to={'create-companies-tag'}>
                <BaseButton className={"ml-5 bg-interactive-elem text-white"}>Добавить метку</BaseButton>
            </Link>}
          </Actions>
        </div>
      </div>
      <div className={'mt-8'}>

        <FormWrapper
          tabs={tabs}
          tab={tab}
          setTab={setTab}
          heightForm={'min-h-[350px]'}
        >
          <CreateFormFields
            show={tab === 'orders'}
            setTab={setTab}
          >
            <OrdersTagsContextWrapper>
              <TableOrdersTags
                selected={selectedOrdersTag}
                setSelected={setSelectedOrdersTag}
                search={search}
                tab={tab}
                massUpdate={ordersTagUpdate}
                setMassUpdate={setOrdersTagUpdate}
              />
            </OrdersTagsContextWrapper>
          </CreateFormFields>

          <CreateFormFields
            show={tab === 'customers'}
            setTab={setTab}
          >
            <CustomersTagsContextWrapper>
              <TableCustomersTags
                selected={selectedClientsTag}
                setSelected={setSelectedClientsTag}
                search={search}
                tab={tab}
                massUpdate={clientsTagUpdate}
                setMassUpdate={setClientsTagUpdate}
              />
            </CustomersTagsContextWrapper>
          </CreateFormFields>

          <CreateFormFields
            show={tab === 'promo'}
            setTab={setTab}
          >
            <PromoTagsContextWrapper>
              <TablePromoTags
                selected={selectedPromoTag}
                setSelected={setSelectedPromoTag}
                search={search}
                tab={tab}
                massUpdate={promoTagUpdate}
                setMassUpdate={setPromoTagUpdate}
              />
            </PromoTagsContextWrapper>
          </CreateFormFields>

          {
            //TODO: выпилили метки компаний. Для их возвращения необходимо раскомменить тут в tabs.ts и в App.tsx
            /*<CreateFormFields
            show={tab === 'companies'}
            setTab={setTab}
          >
            <CompaniesTagsContextWrapper>
              <TableCompaniesTags
                selected={selectedCompanyTag}
                setSelected={setSelectedCompanyTag}
                search={search}
                tab={tab}
                massUpdate={companyTagUpdate}
                setMassUpdate={setCompanyTagUpdate}
              />
            </CompaniesTagsContextWrapper>
          </CreateFormFields>*/}

        </FormWrapper>
      </div>
      {(selectedOrdersTag.length > 0 && tab === 'orders' ||
          selectedClientsTag.length > 0 && tab === 'customers' ||
          selectedPromoTag.length > 0 && tab === 'promo' ||
          selectedCompanyTag.length > 0 && tab === "companies") &&
          <div className={'rounded-xl mt-6 mb-[200px] flex justify-between w-full bg-white gap-x-4'}>
              <div className={'w-full m-4 flex items-center justify-center gap-x-4'}>
                  <SingleSimpleValueSelect
                      className={''}
                      name={'outputType'}
                      placeholder={'Выберите действие'}
                      value={outputType}
                      options={allOutputTypes}
                      onChange={setOutputType}
                  />
                  {
                    (outputType === 'attach' || outputType === 'detach')  &&
                      <SimpleMultipleValueSelect
                        label={undefined}
                        textTooltip={undefined}
                        options={branches.filter(({id}: any) => id !== branch?.id).map((branch: { title: any; id: any; }) => ({label: branch.title, value: branch.id}))}
                        value={data.branches || []}
                        onChange={(value: any) => updateField('branches', value)}
                        name={'branches'}
                        placeholder={'Выберите филиалы'}
                        errors={{}}
                        className={'w-[350px]'}
                      />
                  }
              </div>
            {tab === 'orders' && <BaseButton
                onClick={submitMassAction}
                className={'w-fit ml-4 bg-interactive-elem text-white m-4 py-[7px]'}>Изменить {selectedOrdersTag.length} меток
            </BaseButton>}
            {tab === 'customers' && <BaseButton
                onClick={submitMassAction}
                className={'w-fit ml-4 bg-interactive-elem text-white m-4 py-[7px]'}>Изменить {selectedClientsTag.length} меток
            </BaseButton>}
            {tab === 'promo' && <BaseButton
                onClick={submitMassAction}
                className={'w-fit ml-4 bg-interactive-elem text-white m-4 py-[7px]'}>Изменить {selectedPromoTag.length} меток
            </BaseButton>}
            {tab === 'companies' && <BaseButton
                onClick={submitMassAction}
                className={'w-fit ml-4 bg-interactive-elem text-white m-4 py-[7px]'}>Изменить {selectedCompanyTag.length} меток
            </BaseButton>}
          </div>}
    </div>
  )
}
