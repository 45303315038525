import React, {createContext, useContext, useEffect, useState} from "react";
import {preparePaginationQueryParams} from "./common";
import {apiClient} from "../libs/api/apiClient";
import {useCompaniesContext} from "./Companies";
import {useBranchesContext} from "./Branches";

interface VkClientsEventsContextType {
  vkClientsEvents: any;
  loading: boolean;
  filters: any;
  updateClients: Function;
  updateFilters: Function;
  pagination: {};
}

const VkClientsEventsContext = createContext<VkClientsEventsContextType>({
  vkClientsEvents: [],
  filters: {},
  loading: false,
  updateClients: () => {},
  updateFilters: () => {},
  pagination: {},
});

export function VkClientsEventsContextWrapper({children}: any) {
  const { company }: any = useCompaniesContext()
  const { branch }: any = useBranchesContext()

  const [vkClientsEvents, setVkClientsEvents] = useState([])
  const [loading, setLoading] = useState(true)
  const [pagination, setPagination] = useState({})
  const [filters, setFilters] = useState({limit: 10})

  const updateClients = (params = {limit: 10}) => {
    if (company && branch) {
      setLoading(true)
      if (params) {
        updatePagination(params)
      }
      apiClient.customers.getCustomersVkEvents(company?.id, branch?.id, preparePaginationQueryParams({...(params || {}), ...(filters || {})}))
        .then(({data, meta, links}: any) => {
          console.log(data)
          setVkClientsEvents(data)
          if (meta) {
            setPagination(meta)
          }
        }).catch((e) => {
          console.log(e)
        }).finally(() => {
          setLoading(false)
        })
    }
  }


  const updatePagination = ({limit, order, page}: any) => {
    let tempData = filters
    if (limit) {
      tempData['limit'] = limit
    }
    if (page) {
      tempData['page'] = page
    }
    if (order) {
      tempData['order'] = order
    }

    setFilters(filters)
  }

  const updateFilters = (path: any, value: any, applyFilters = false) => {
    path = path.split('.')

    let tempData: any = filters;
    path.forEach((key: any, index: any) => {
      if (index === path.length - 1) {
        if (tempData instanceof Object) {
          tempData[key] = value
        } else {
          tempData = {[key]: value}
        }
      } else {
        if (!(tempData[key] instanceof Object)) {
          tempData[key] = {}
        }
        if (!(key in tempData)) {
          tempData[key] = {}
        }
        tempData = tempData[key]
      }
    })
    setFilters(filters)
    if (applyFilters) {
      console.log(`updateFilters value = ${value} isNull = ${typeof value === null}`);
      updateClients(filters)
    }
  }

  // This code from ClientsContext

  // const selectClient = (id: any) => {
  //   if (localStorage.getItem('clientId') != id) {
  //     localStorage.setItem('clientId', id)
  //     refreshClients()
  //   }
  // }

  // const refreshClients = () => {
  //   if (!loading) {
  //     let clientId = localStorage.getItem('clientId')

  //     if (clientId) {
  //       let temp = null
  //       vkClientsEvents.forEach((elem: any) => {
  //         if (elem.id == clientId) {
  //           temp = elem
  //         }
  //       })

  //       // if (temp) {
  //       //   setClient(temp)
  //       //   return
  //       // } else {
  //       //   localStorage.removeItem('branchId')
  //       //   setClient(null)
  //       // }
  //     }
  //   }
  // }

  //TODO: finish this func
  useEffect(() => {
     updateClients()
  }, [company?.id, branch?.id])

  return (
    <VkClientsEventsContext.Provider
      value={{vkClientsEvents, loading, filters, updateClients, updateFilters, pagination}}>
      {children}
    </VkClientsEventsContext.Provider>
  )
}

export function useVkClientsEventsContext() {
  return useContext(VkClientsEventsContext)
}
