import React, { useState, useEffect } from 'react'
import Breadcrumbs from './../../../components/common/Breadcrumbs';
import FormWrapper from '../../../components/common/form/FormWrapper';
import useForm from './../../../hooks/useForm';
import tabs from '../tabs'
import initForm from '../initForm';
import { useNavigate, useParams } from "react-router-dom";
import { useCompaniesContext } from '../../../contexts/Companies';
import { useBranchesContext } from '../../../contexts/Branches';
import {CategoriesContextWrapper, useCategoriesContext} from '../../../contexts/Categories';
import { apiClient } from '../../../libs/api/apiClient';

import Base from './Base';
import Show from './Show';
import MetaTags from './MetaTags';
import {showErrorToast, showSuccessToast} from "../../../libs/helpers/toasts";
import {dialogModal} from "../../../components/common/Modal/DialogModal";
import { useFormContext } from '../../../contexts/Form';
import EditFormFields from "../../../components/common/form/EditFormFields";

export default function EditCategory() {
  const [tab, setTab] = useState('base')
  const [category, setCategory] = useState()
  const [loading, setLoading] = useState<boolean>(true)

  const {data, updateData, updateField, updateFieldNoRender, errors, updateErrors, submit, changedFieldsRefresh, isDirty} = useForm(JSON.parse(JSON.stringify(initForm)))

  const { company } = useCompaniesContext()
  const { branch } = useBranchesContext()
  const { updateCategories } = useCategoriesContext()
  const {needUpdate,} = useFormContext()

  const navigate = useNavigate();
  const {id: categoryId} = useParams<{id?: string}>()

  const reset = () => {
    navigate('/categories')
  }

  const removeCategory = () => {
    dialogModal({
      isOpen: false,
      title: 'Внимание!',
      text: 'Вы уверены что хотите удалить раздел?',
      showCancelButton: true,
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Вернуться',
    }).then((result) => {
      if (result.isConfirmed) {
        apiClient.categories.remove(company?.id, branch?.id, categoryId)
          .then(() => {
              updateCategories()
              showSuccessToast({
                content: 'Раздел удален успешно'
              })
              navigate('/categories')
            }
          ).catch((err) => {
          showErrorToast({
            content: 'Что-то пошло не так'
          })
        })
      }
    }).catch(() => {})
  }

  const handleSubmit = () => {
    updateField('isDividedByBranches', data.isDividedByBranches)
    submit(form => {
      apiClient.categories.update(company?.id, branch?.id, categoryId, { body: form })
      .then(({data, errors, message, success}) => {
          if (errors || message) {
            updateErrors(errors || {})
            showErrorToast({
              content: 'Что-то пошло не так'
            })
          } else {
            changedFieldsRefresh()
            getCategoryInfo(categoryId)
            updateCategories()
            showSuccessToast({
              content: 'Сохранение прошло успешно'
            })
          }
        }
      )
    }, 'changed')
  }

  const getCategoryInfo = (id) => {
    if (company?.id && branch?.id) {
      setLoading(true)
      // prefetch dictionaries
      apiClient.dictionaries.platforms().then();

      apiClient.categories.get(company.id, branch?.id, id).then(({data, errors}) => {
        if (data) {
          updateData(data)
          setCategory(data)
        } else {
          console.log(errors)
          updateErrors(errors || {})
        }
        setLoading(false)
      })

    }
  }

  useEffect(() => {
    getCategoryInfo(categoryId)
  }, [company?.id, branch?.id])

  useEffect(() => {
    if (needUpdate) {
      handleSubmit()
    }
  }, [needUpdate])

  const changeTab = (tag: any) => {
    if (Object.keys(isDirty()).length !== 0) {
      dialogModal({
        isOpen: false,
        title: 'Не сохраненные данные!',
        text: 'Вы действительно хотите перейти на следующий шаг? Все не сохраненные данные будут удалены',
        showDenyButton: true,
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: 'Сохранить и продолжить',
        denyButtonText: `Без сохранения`,
        cancelButtonText: 'Вернуться',
      }).then((result) => {
        if (result.isConfirmed) {
          handleSubmit()
          setTab(tag)
        } else if (result.isDenied) {
          changedFieldsRefresh()
          getCategoryInfo(categoryId)
          setTab(tag)
        }
      }).catch(() => {})
    } else {
      setTab(tag)
    }
  }

  if (loading) return (
    <div className="text-gray-40 text-3xl text-center mt-[35vh]">
      <span>Загрузка...</span>
    </div>
  )

  return (
    <div className='px-8 pb-8 pt-2 text-gray-50'>
      <div className='mb-8'>
        <div className='text-[44px] leading-[60px] mb-2'>Каталог</div>
        <Breadcrumbs
          links={[
            {to: `/categories`, label: 'Список разделов'},
            {to: '', label: 'Редактирование раздела'},
          ]}

          back={`/categories`}
        />
      </div>

      <FormWrapper
        tabs={tabs}
        tab={tab}
        setTab={changeTab}
      >
        <EditFormFields
          show={tab === 'base'}
          remove={removeCategory}
          reset={reset}
          back={'/categories'}
          handleSubmit={handleSubmit}
        >
          <CategoriesContextWrapper>
            <Base
              data={data}
              updateField={updateField}
              updateFieldNoRender={updateFieldNoRender}
              errors={errors}
            />
          </CategoriesContextWrapper>
        </EditFormFields>

        <EditFormFields
          show={tab === 'show'}
          remove={removeCategory}
          reset={reset}
          back={'/categories'}
          handleSubmit={handleSubmit}
        >
          <Show
            data={data}
            updateField={updateField}
            errors={errors}
          />
        </EditFormFields>

        <EditFormFields
          show={tab === 'metaTags'}
          remove={removeCategory}
          reset={reset}
          back={'/categories'}
          handleSubmit={handleSubmit}
        >
          <MetaTags
            data={data}
            updateField={updateField}
            updateFieldNoRender={updateFieldNoRender}
            errors={errors}
          />
        </EditFormFields>


      </FormWrapper>
    </div>
  )
}
