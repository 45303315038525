import React from "react";
import Checkbox from "../../../../components/common/form/checkbox/Checkbox";
import {useBranchesContext} from "../../../../contexts/Branches";
import TextInput from "../../../../components/common/form/input/TextInput";
import {useOptionsContext} from "../../../../contexts/Options";
import SimpleMultipleValueSelect from "../../../../components/common/form/select/multiple/SimpleMultipleValueSelect";
import {DatatableSelect} from "../../../../components/common/form/select/datatable/DatatableSelect";
import {createColumnHelper} from "@tanstack/react-table";

export default function Base({data, errors, updateField, updateFieldNoRender}: any) {
  const { branches} = useBranchesContext()
  const { options, loading, updateOptions }: any = useOptionsContext();
  const columnHelper = createColumnHelper<any>()

  const optionsColumns  = [
    columnHelper.accessor(row => row.id, {
      id: 'ID',
      header: () => 'ID',
      cell: info => <div className={'w-[10%] flex items-center justify-center text-center'}>{ info.getValue() }</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[10%] flex items-center justify-center text-center'
      }
    }),
    columnHelper.accessor(row => row.article, {
      id: 'article',
      header: () => 'Артикул',
      cell: info => <div className={'w-[15%] flex items-center justify-center text-center'}>{ info.getValue() }</div>,
      enableSorting: false,
      meta: {
        widthClass: 'mr-4 w-[15%] flex items-center justify-center text-center'
      }
    }),
    columnHelper.accessor(row => row.title, {
      id: 'title',
      header: () => 'Название',
      cell: info =>
        <div className={'grow flex items-center'}>
          { info.getValue() }
        </div>,
      enableSorting: true,
      meta: {
        widthClass: 'grow',
        sortField: 'title'
      }
    }),
    columnHelper.accessor(row => row.weight, {
      id: 'weight',
      header: () => 'Вес',
      cell: info => <div className={'max-w-[48px] grow flex items-center justify-center'}>{ info.getValue() }</div>,
      enableSorting: true,
      meta: {
        widthClass: 'max-w-[48px] grow justify-center',
        sortField: 'weight'
      }
    }),
    columnHelper.accessor(row => row.price, {
      id: 'price',
      header: () => 'Цена',
      cell: info => <div className={'max-w-[75px] grow flex items-center justify-center'}>{ info.getValue() }</div>,
      enableSorting: true,
      meta: {
        widthClass: 'max-w-[75px] grow justify-center',
        sortField: 'price'
      }
    }),
  ]


  return (
    <div className={'flex flex-col px-3 gap-y-6'}>
      <Checkbox
        label={'Активность группы опций'}
        name={'isActive'}
        onChange={(value: any) => updateField('isActive', value)}
        value={data.isActive}
        errors={errors}
        required={true}
      />
      <TextInput
        label={'Название CRM'}
        textTooltip={'Название CRM'}
        placeholder={'Введите название CRM'}
        name={'innerTitle'}
        value={data?.innerTitle}
        onChange={value => updateFieldNoRender('innerTitle', value)}
        errors={errors}
        required={true}
      />
      <TextInput
        label={'Название группы опций'}
        textTooltip={'Название группы опций'}
        placeholder={'Введите название опции'}
        name={'title'}
        value={data?.title}
        onChange={value => updateFieldNoRender('title', value)}
        errors={errors}
        required={true}
      />
      <TextInput
        label={'Артикул'}
        textTooltip={'Артикул'}
        placeholder={'Введите артикул'}
        name={'article'}
        value={data?.article}
        onChange={value => updateFieldNoRender('article', value)}
        errors={errors}
        required={false}
      />
      <Checkbox
        label={'Можно выбрать несколько одинаковых опций'}
        name={'canSelectSeveralIdenticalOptions'}
        onChange={(value: any) => updateField('canSelectSeveralIdenticalOptions', value)}
        value={data?.canSelectSeveralIdenticalOptions}
        errors={errors}
        required={true}
      />
      <TextInput
        type={'number'}
        min={'0'}
        label={'Мин. выбор'}
        textTooltip={'Мин. выбор'}
        placeholder={'Тектовое поле'}
        name={'minSelect'}
        value={data?.minSelect}
        onChange={value => updateFieldNoRender('minSelect', value)}
        errors={errors}
      />
      <TextInput
        type={'number'}
        min={'0'}
        label={'Макс. выбор'}
        textTooltip={'Макс. выбор'}
        placeholder={'Тектовое поле'}
        name={'maxSelect'}
        value={data?.maxSelect}
        onChange={value => updateFieldNoRender('maxSelect', value)}
        errors={errors}
      />
      <TextInput
        label={'Описание'}
        textTooltip={'Описание'}
        placeholder={'Тектовое поле'}
        name={'description'}
        value={data?.description}
        onChange={value => updateFieldNoRender('description', value)}
        errors={errors}
      />
      <div className='flex flex-col gap-4 rounded-2xl border-[1px] border-gray-20 p-4'>

        <DatatableSelect
          data={options || []}
          filteredData={options || []}
          required={true}
          label={'Опции'}
          textTooltip={'Опции'}
          values={data?.options}
          errors={errors}
          loading={loading}
          name={'options'}
          placeholder={'Выберите опции'}
          removeItem={(index: any) => {
            let options = data?.options.slice()
            options.splice(index, 1)
            updateField('options', options)
          }}
          updateValues={(values: any) => updateField('options', values)}
          updateData={(params: any) => {
            updateOptions(params, null)
          }}
          columns={optionsColumns}
          buttonPlaceholder={'Выберите опции'}
        />
      </div>
      <SimpleMultipleValueSelect
        label={'Филиалы'}
        textTooltip={'Филиалы'}
        options={branches?.map((branch: any) => ({label: branch.title, value: branch.id})) || []}
        value={data?.branches || []}
        onChange={(value: any) => updateField('branches', value)}
        name={'branches'}
        placeholder={'Выберите филиалы'}
        errors={errors}
        required={true}
      />
    </div>
  )
}
