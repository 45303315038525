import * as React from "react";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid"
import TextInput from '../../../components/common/form/input/TextInput'
import Input from '../../../components/v2/form/input/Input'
import InputList from "../../../components/v2/form/input/InputList";
import InputGroup from "../../../components/v2/form/input/InputGroup";

// @ts-ignore
import menuIcon from '../../../images/menu.svg';
import InputWrapper from "../../../components/v2/form/input/common/InputWrapper";
import TokenInput from "../../../components/v2/form/input/TokenInput";
import { ScheduleItem } from "../../../types/branch";
import { diff } from "../../../libs/helpers/objects";

const getScheduleDiff = (previous: ScheduleItem[], current: ScheduleItem[]): ScheduleItem[] => {
  const diff: ScheduleItem[] = [];

  previous.forEach((previousItem: ScheduleItem) => {
    const currentItem = current.find((item: any) => previousItem.id === item.id);

    // Удаление
    if (currentItem === undefined) {
      previousItem.id && diff.push({
        id: previousItem.id,
        uid: previousItem.uid,
      });
      return;
    }

    // Ничего не поменялось
    if (!isDirty(previousItem, currentItem)) return;
    // Редактирование
    diff.push(getScheduleItemDiff(previousItem, currentItem));
  });

  // Новые записи
  current.forEach((currentItem: ScheduleItem) => {
    if (!currentItem.id && currentItem.uid
        && currentItem.executeAt && currentItem.payload?.key) {
      diff.push(currentItem);
    }
  });
  return diff;
}

const getScheduleItemDiff = (previous: ScheduleItem, current: ScheduleItem): ScheduleItem => {
  const result: ScheduleItem = {};
  if (current.executeAt && previous.executeAt !== current.executeAt) {
    result.executeAt = current.executeAt;
  }
  if (current.payload?.key && previous.payload?.key !== current.payload.key) {
    result.payload = {
      key: current.payload.key
    };
  }
  if (current.uid)
    result.uid = current.uid;
  if (current.id)
    result.id = current.id;
  return result;
}

const isDirty = (previous: ScheduleItem, current: ScheduleItem): boolean => {
  return 0 < Object.keys(diff<ScheduleItem>(previous, current)).length
}

const init: ScheduleItem = {executeAt: '', payload: {key: ''}};
const changeState = (previous: ScheduleItem[], index: number, value: ScheduleItem) => {
  return [
    ...previous.slice(0, index),
    {
      ...previous[index],
      ...value
    },
    ...previous.slice(index + 1)
  ];
}

export function YandexGeocoder({ data, updateFieldNoRender, errors }: any) {
  const [isListView, setListView] = useState(false);
  const [initialValue, _] = useState<ScheduleItem[]>(data.schedule?.yandexGeocoderKey || []);
  const [items, setItems] = useState<ScheduleItem[]>(data.schedule?.yandexGeocoderKey || [{ ...init, uid: uuid() }])

  const handleIconClick = () => {
    setListView((previous) => ! previous);
  }

  const handleBlur = (index: number, value: ScheduleItem) => {
    if (! isDirty(items[index], value)) return;
    setItems((previous) => {
      const state = changeState(previous, index, value);

      updateFieldNoRender('schedule.yandexGeocoderKey', state.filter((item) => !! item.payload?.key));
      updateFieldNoRender('schedule.yandexGeocoderKeyDiff', getScheduleDiff(initialValue, state));

      return state;
    })
  }

  const handleChange = (index: number, value: ScheduleItem) => {
    if (value.executeAt) {
      delete errors[`schedule.yandexGeocoderKey.${ index }.executeAt`];
    }

    if (value.payload?.key) {
      delete errors[`schedule.yandexGeocoderKey.${ index }.payload.key`];
    }
  }

  const addItem = () => setItems((previous) => [...previous, { ...init, uid: uuid() }]);
  const deleteItem = (index: number) => setItems((previous) => {
    const state = [...previous];

    state.splice(index, 1);

    return state;
  });

  const isItemFulfilled = (item: ScheduleItem) => !! item.payload?.key;
  const isItemDeleting = (item: ScheduleItem) => ! isItemFulfilled(item);

  const handleRemoveSchedule = () => {
    setItems([{ ...init, uid: uuid() }]);
    setListView(false);

    updateFieldNoRender('schedule.yandexGeocoderKey', []);
  }

  useEffect(() => {
    if (! Array.isArray(data.schedule?.yandexGeocoderKey)) {
      return;
    }

    setListView(true);
  }, []);

  if (! isListView) {
    return (
      <div>
        <InputWrapper
          error={ errors['integrations.keys.yandexGeocoder.apiKey'] }
        >
          <InputWrapper.Label>
            Ключ API Yandex Geocoder
          </InputWrapper.Label>
          <InputWrapper.After>
            <img
              className={ `cursor-pointer` }
              src={ menuIcon }
              alt={ 'input-icon' }
              onClick={ handleIconClick }
            />
          </InputWrapper.After>
          <TokenInput
            name={ 'integrations.keys.yandexGeocoder.apiKey' }
            placeholder='f57esc4f-8255-435t-b897-d6013dfdd14a'
            defaultValue={ data.integrations.keys?.yandexGeocoder?.apiKey }
            onBlur={ (event) => {
              if (event.target.value != data.integrations.keys?.yandexGeocoder?.apiKey)
                updateFieldNoRender('integrations.keys.yandexGeocoder.apiKey', event.target.value) 
            } }
          />
        </InputWrapper>
      </div>
    );
  }

  return (
    <div className={ 'flex flex-col gap-y-3' }>
      <div>
        <InputWrapper
          error={ errors['integrations.keys.yandexSuggest.apiKey'] }
        >
          <InputWrapper.Label>
            Текущий ключ Yandex Geocoder
          </InputWrapper.Label>
          <TokenInput
            disabled
            name={ 'integrations.keys.yandexGeocoder.apiKey' }
            placeholder='f57esc4f-8255-435t-b897-d6013dfdd14a'
            defaultValue={ data.integrations.keys?.yandexGeocoder?.apiKey }
          />
        </InputWrapper>
      </div>
      <InputList<ScheduleItem> items={ items } addItem={ addItem } deleteItem={ deleteItem }
                               isItemFulfilled={ isItemFulfilled } isItemDeleting={ isItemDeleting }>
        <InputList.Label className={ 'flex justify-between items-center mb-2 font-medium text-sm' }>
          <span>
            Расписание смены ключей Yandex Geocoder
          </span>
          <span className={ 'cursor-pointer' } onClick={ handleRemoveSchedule }>
            Удалить расписание
          </span>
        </InputList.Label>
        {/* @ts-ignore */}
        <InputList.Item<ScheduleItem>>
          { ({ item, index }) => (
            <InputGroup key={ `schedule.yandexGeocoderKey.${ item.uid || item.id }` }>
              <div className={ 'w-full' }>
                <InputWrapper error={ errors[`schedule.yandexGeocoderKey.${ index }.payload.key`] }>
                  <InputWrapper.Label>
                    Ключ API Yandex Geocoder
                  </InputWrapper.Label>
                  <TokenInput
                    name={ `schedule.yandexGeocoderKey.${ index }.payload.key` }
                    placeholder='f57esc4f-8255-435t-b897-d6013dfdd14a'
                    defaultValue={ item.payload?.key }
                    onBlur={ (event) => handleBlur(index, { payload: { key: event.target.value } }) }
                    onChange={ (event) => handleChange(index, { payload: { key: event.target.value } }) }
                  />
                </InputWrapper>
              </div>
              <div className={ 'w-full' }>
                <InputWrapper error={ errors[`schedule.yandexGeocoderKey.${ index }.executeAt`] }>
                  <InputWrapper.Label>
                    Время смены
                  </InputWrapper.Label>
                  <Input
                    type={ 'time' }
                    id={ `${ index }.executeAt` }
                    value={ item.executeAt }
                    onChange={ (event) => handleBlur(index, { executeAt: event.target.value }) }
                  />
                </InputWrapper>
              </div>
            </InputGroup>
          ) }
        </InputList.Item>
      </InputList>
    </div>
  )
}

export function YandexSuggest({ data, updateFieldNoRender, errors }: any) {
  const [isListView, setListView] = useState(false);
  const [initialValue, _] = useState<ScheduleItem[]>(data.schedule?.yandexSuggestKey || []);
  const [items, setItems] = useState<ScheduleItem[]>(data.schedule?.yandexSuggestKey || [{ ...init, uid: uuid() }])

  const handleIconClick = () => {
    setListView((previous) => ! previous);
  }

  const handleBlur = (index: number, value: ScheduleItem) => {
    if (! isDirty(items[index], value)) return;
    setItems((previous) => {
      const state = changeState(previous, index, value);

      updateFieldNoRender('schedule.yandexSuggestKey', state.filter((item) => !! item.payload?.key));
      updateFieldNoRender('schedule.yandexSuggestKeyDiff', getScheduleDiff(initialValue, state));

      return state;
    });
  }

  const handleChange = (index: number, value: ScheduleItem) => {
    if (value.executeAt) {
      delete errors[`schedule.yandexSuggestKey.${ index }.executeAt`];
    }

    if (value.payload?.key) {
      delete errors[`schedule.yandexSuggestKey.${ index }.payload.key`];
    }
  }

  const addItem = () => setItems((previous) => [...previous, { ...init, uid: uuid() }]);
  const deleteItem = (index: number) => setItems((previous) => {
    const state = [...previous];

    state.splice(index, 1);

    return state;
  });

  const isItemFulfilled = (item: ScheduleItem) => !! item.payload?.key;
  const isItemDeleting = (item: ScheduleItem) => ! isItemFulfilled(item);

  const handleRemoveSchedule = () => {
    setItems([{ ...init, uid: uuid() }]);
    setListView(false);

    updateFieldNoRender('schedule.yandexSuggestKey', []);
  }

  useEffect(() => {
    if (! Array.isArray(data.schedule?.yandexSuggestKey)) {
      return;
    }

    setListView(true);
  }, []);

  if (! isListView) {
    return (
      <div>
        <InputWrapper error={ errors['integrations.keys.yandexSuggest.apiKey'] }>
          <InputWrapper.Label>
            Ключ API Yandex Suggest
          </InputWrapper.Label>
          <InputWrapper.After>
            <img
              className={ `cursor-pointer` }
              src={ menuIcon }
              alt={ 'input-icon' }
              onClick={ handleIconClick }
            />
          </InputWrapper.After>

          <TokenInput
            name={ 'integrations.keys.yandexGeocoder.apiKey' }
            placeholder='f57esc4f-8255-435t-b897-d6013dfdd14a'
            defaultValue={ data.integrations.keys?.yandexSuggest?.apiKey }
            onBlur={ (event) => { 
              if (event.target.value != data.integrations.keys?.yandexSuggest?.apiKey)
                updateFieldNoRender('integrations.keys.yandexSuggest.apiKey', event.target.value) 
            } }
          />
        </InputWrapper>
      </div>
    );
  }

  return (
    <div className={ 'flex flex-col gap-y-3' }>
      <div>
        <InputWrapper error={ errors['integrations.keys.yandexSuggest.apiKey'] }>
          <InputWrapper.Label>
            Текущий ключ Yandex Suggest
          </InputWrapper.Label>
          <TokenInput
            disabled
            name={ 'integrations.keys.yandexSuggest.apiKey' }
            placeholder='f57esc4f-8255-435t-b897-d6013dfdd14a'
            defaultValue={ data.integrations.keys?.yandexSuggest?.apiKey }
          />
        </InputWrapper>
      </div>
      <InputList<ScheduleItem> items={ items } addItem={ addItem } deleteItem={ deleteItem }
                               isItemFulfilled={ isItemFulfilled } isItemDeleting={ isItemDeleting }>
        <InputList.Label className={ 'flex justify-between items-center mb-2 font-medium text-sm' }>
          <span>
            Расписание смены ключей Yandex Suggest
          </span>
          <span className={ 'cursor-pointer' } onClick={ handleRemoveSchedule }>
            Удалить расписание
          </span>
        </InputList.Label>
        {/* @ts-ignore */}
        <InputList.Item<ScheduleItem>>
          { ({ item, index }) => (
            <InputGroup key={ `schedule.yandexSuggestKey.${ item.uid || item.id }` }>
              <div className={ 'w-full' }>
                <InputWrapper error={ errors[`schedule.yandexSuggestKey.${ index }.payload.key`] }>
                  <InputWrapper.Label>
                    Ключ API Yandex Suggest
                  </InputWrapper.Label>

                  <TokenInput
                    name={ `schedule.yandexSuggestKey.${ index }.payload.key` }
                    placeholder='f57esc4f-8255-435t-b897-d6013dfdd14a'
                    defaultValue={ item.payload?.key }
                    onBlur={ (event) => handleBlur(index, { payload: { key: event.target.value } }) }
                    onChange={ (event) => handleChange(index, { payload: { key: event.target.value } }) }
                  />
                </InputWrapper>
              </div>
              <div className={ 'w-full' }>
                <InputWrapper>
                  <InputWrapper.Label>
                    Время смены
                  </InputWrapper.Label>
                  <Input
                    type={ 'time' }
                    value={ item.executeAt }
                    onChange={ (event) => handleBlur(index, { executeAt: event.target.value }) }
                    error={ errors[`schedule.yandexSuggestKey.${ index }.executeAt`] }
                  />
                </InputWrapper>
              </div>
            </InputGroup>
          ) }
        </InputList.Item>
      </InputList>
    </div>
  )
}

export default function Metrics({ data, updateField, errors, updateFieldNoRender }: any) {
  useEffect(() => {
    if (! data.integrations.keys) {
      updateField('integrations.keys', {})
    }
  }, [data])

  return (
    <div className={ 'flex flex-col px-3 gap-y-6' }>
      <div className='text-[22px] leading-7 font-semibold'>Метрики</div>
      <div className='grid grid-cols-2 gap-4'>
        <TextInput
          name={ 'integration.ymetrika' }
          label='Яндекс метрика'
          textTooltip='Яндекс метрика'
          placeholder='600'
          value={ data.integrations.keys?.ymetrika }
          onChange={ value => updateFieldNoRender('integrations.keys.ymetrika', value) }
          errors={ errors }
        />

        <TextInput
          name={ 'integration.googleAnalytics' }
          label='Google метрика'
          textTooltip='Google метрика'
          placeholder='1000'
          value={ data.integrations.keys?.googleAnalytics }
          onChange={ value => updateFieldNoRender('integrations.keys.googleAnalytics', value) }
          errors={ errors }
        />

        <TextInput
          name={ 'integration.yandexWebmaster' }
          label='Яндекс вебмастер'
          textTooltip='Яндекс вебмастер'
          placeholder='300'
          value={ data.integrations.keys?.yandexWebmaster }
          onChange={ value => updateFieldNoRender('integrations.keys.yandexWebmaster', value) }
          errors={ errors }
        />

        <TextInput
          name={ 'integration.googleWebmaster' }
          label='Google вебмастер'
          textTooltip='Google вебмастер'
          placeholder='90'
          value={ data.integrations.keys?.googleWebmaster }
          onChange={ value => updateFieldNoRender('integrations.keys.googleWebmaster', value) }
          errors={ errors }
        />

        <TextInput
          name={ 'integration.vkWidget' }
          label='Виджет сообщений ВК'
          textTooltip='Виджет сообщений ВК'
          placeholder='300'
          value={ data.integrations.keys?.vkWidget }
          onChange={ value => updateFieldNoRender('integrations.keys.vkWidget', value) }
          errors={ errors }
        />

        <TextInput
          name={ 'integrations.keys.dadata.token' }
          label='DaData (Токен)'
          textTooltip='DaData (Токен)'
          placeholder='300'
          value={ data.integrations.keys?.dadata?.token }
          onChange={ (value: any) => updateFieldNoRender('integrations.keys.dadata.token', value) }
          errors={ errors }
          initialValueReplaceOnly={ true }
        />

        <TextInput
          name={ 'integrations.keys.dadata.secret' }
          label='DaData (Секрет)'
          textTooltip='DaData (Секрет)'
          placeholder='300'
          value={ data.integrations.keys?.dadata?.secret }
          onChange={ (value: any) => updateFieldNoRender('integrations.keys.dadata.secret', value) }
          errors={ errors }
          initialValueReplaceOnly={ true }
        />

        <TextInput
          name={ 'integration.vkRetarget' }
          label='Ретаргетинг ВК'
          textTooltip='Ретаргетинг ВК'
          placeholder='300'
          value={ data.integrations.keys?.vkRetarget }
          onChange={ value => updateFieldNoRender('integrations.keys.vkRetarget', value) }
          errors={ errors }
        />

        <TextInput
          name={ 'integration.facebookPixel' }
          label='Pixel Facebook'
          textTooltip='Pixel Facebook'
          placeholder='90'
          value={ data.integrations.keys?.facebookPixel }
          onChange={ value => updateFieldNoRender('integrations.keys.facebookPixel', value) }
          errors={ errors }
        />
      </div>
      <div className={'grid grid-cols-2 gap-4'}>
        <YandexGeocoder data={ data } errors={ errors } updateFieldNoRender={ updateFieldNoRender }/>
        <YandexSuggest data={ data } errors={ errors } updateFieldNoRender={ updateFieldNoRender }/>
      </div>
    </div>
  )
}
