import React, {useState} from 'react'
import TextInput from '../../../components/common/form/input/TextInput'
import ColorSelect from '../../../components/common/form/select/ColorSelect'
import IconSelect from '../../../components/common/form/select/IconSelect'
import BaseButton from '../../../components/common/Button/BaseButton'
import {COLORS} from '../../../constants/colors';
import deleteIcon from '../../../images/delete.svg'
import pencilIcon from '../../../images/pencil.svg'
import applyIcon from '../../../images/apply.svg'
import ascSortIcon from '../../../images/sort_asc.svg'
import descSortIcon from '../../../images/sort-desc.svg'
import nextId from "react-id-generator";
import {getBase64FromUrl} from "../../../libs/helpers/image";

export default function Tags({data, updateData, updateField, errors, icons}: any) {
  const [addTagLoading, setAddTagLoading] = useState(false)
  const [directionCode, setDirectionCode] = useState('asc')
  const [directionText, setDirectionText] = useState('asc')

  const addTag = () => {
    setAddTagLoading(true)
    let tags = data.tags.slice(0)
    getBase64FromUrl(icons.at(0)?.icon).then((result) => {
      tags.push({
        key: nextId(),
        code: '',
        text: '',
        color: COLORS.at(0),
        background: COLORS.at(0),
        icon: result,
        iconSrc: icons.at(0)?.icon,
        //??возможный костыль??
        // Тут мы делаем глубокую копию массива стандартных иконок
        // сделано, чтобы в каждой модалке было свое выделение иконок перед выбором
        __standartIcons: JSON.parse(JSON.stringify(icons)),
        edit: true,
      })
      setAddTagLoading(false)
    })

    updateData({tags: tags})
  }

  const delTag = (index: any) => {
    let tags = data.tags.slice(0)

    tags.splice(index, 1)

    updateField('tags', tags)
  }

  const editRow = (tags: any) => {
    updateData({tags: tags})
  }

  const saveEditStatus = (tags: any) => {
    updateField('tags', tags)
  }

  const updateIcons = (tag_index: any, icon_index: any) => {
    let tags = data.tags.slice(0)

    tags[tag_index].__standartIcons.forEach((icon: any, index: any) => {
      icon.active = index === icon_index;
    })

    updateData({tags: tags})
  }

  const sortByCode = () => {
    let tags = data.tags.slice(0)
    tags.sort((a: any, b: any) => directionCode === 'asc' ? a.code - b.code : b.code - a.code);
    updateData({tags: tags})
    setDirectionCode(directionCode === 'asc' ? 'desc' : 'asc')
    setDirectionText('asc')
  }

  const sortByText = () => {
    let tags = data.tags.slice(0)
    tags.sort((a: any, b: any) => {
      if (directionText === 'asc') {
        return (a.text > b.text) ? 1 : (a.text < b.text) ? -1 : 0
      } else {
        return (a.text < b.text) ? 1 : (a.text > b.text) ? -1 : 0
      }
    });
    updateData({tags: tags})
    setDirectionText(directionText === 'asc' ? 'desc' : 'asc')
    setDirectionCode('asc')
  }

  return (
    <>
      <div className='flex h-16 border-b-[1px] border-gray-30'>
        <div className='w-[30px] flex items-center justify-center text-center'>ID</div>
        <div className='w-[70px] flex items-center justify-center' onClick={sortByCode}>
          <span className={'mr-2'}>Код</span>
          <img className={'cursor-pointer'} src={directionCode === 'asc' ? ascSortIcon : descSortIcon} alt=""/>
        </div>
        <div className='grow pl-[14px] flex items-center' onClick={sortByText}>
          <span className={'mr-2'}>Текст</span>
          <img className={'cursor-pointer'} src={directionText === 'asc' ? ascSortIcon : descSortIcon} alt=""/>
        </div>
        <div className='w-[60px] mx-2 flex items-center justify-center text-center'>Иконка</div>
        <div className='w-[60px] mx-2 flex items-center justify-center text-center'>Цвет текста</div>
        <div className='w-[60px] mx-2 flex items-center justify-center text-center'>Цвет фона</div>
        <div className='w-[11%] flex items-center justify-center text-center'>Действия</div>
      </div>

      <div>
        {
          data.tags.map((tag: any, index: any) => (
            <div key={tag.key} className='flex h-16 border-b-[1px] border-gray-30'>
              <div className='w-[30px] flex items-center justify-center text-center'>{tag.id}</div>

              <div className='w-[70px] p-1 flex items-center'>
                <TextInput
                  errorLabel={false}
                  name={`tags.${index}.code`}
                  errors={errors}
                  placeholder='Код'
                  value={tag.code}
                  disabled={!data.tags[index].edit}
                  onChange={(value: any) => {
                    let tags = data.tags.slice(0)
                    tags[index].code = value
                    editRow(tags)
                  }}
                  classNameInput='text-center disabled:border-0'
                />
              </div>

              <div className='grow p-1 flex items-center'>
                <TextInput
                  errorLabel={false}
                  name={`tags.${index}.text`}
                  errors={errors}
                  className='w-full'
                  placeholder='Текст'
                  value={tag.text}
                  onChange={(value: any) => {
                    let tags = data.tags.slice(0)
                    tags[index].text = value
                    editRow(tags)
                  }}
                  disabled={!data.tags[index].edit}
                  classNameInput={'disabled:border-0'}
                />
              </div>

              <div className='w-[60px] mx-2 flex items-center justify-center text-center'>
                <IconSelect
                  options={tag?.__standartIcons}
                  value={tag?.iconSrc}
                  color={tag?.color}
                  setStandartIndex={(icon_index: any) => updateIcons(index, icon_index)}
                  disabled={!data.tags[index].edit}
                  onChange={(icon: any, iconSrc: any) => {
                    let tags = data.tags.slice(0)
                    tags[index].iconSrc = iconSrc
                    tags[index].icon = icon
                    editRow(tags)
                  }}
                />
              </div>

              <div className='w-[60px] mx-2 flex items-center justify-center text-center'>
                <ColorSelect
                  options={COLORS}
                  value={tag.color}
                  defaultColor={tag.color}
                  disabled={!data.tags[index].edit}
                  onChange={(value: any) => {
                    let tags = data.tags.slice(0)
                    tags[index].color = value
                    editRow(tags)
                  }}
                />
              </div>

              <div className='w-[60px] mx-2 flex items-center justify-center text-center'>
                <ColorSelect
                  options={COLORS}
                  value={tag.background}
                  defaultColor={tag.background}
                  disabled={!data.tags[index].edit}
                  onChange={(value: any) => {
                    let tags = data.tags.slice(0)
                    tags[index].background = value
                    editRow(tags)
                  }}
                />
              </div>

              <div className='w-[11%] flex items-center justify-center'>
                <img className='cursor-pointer' onClick={() => {
                  let tags = data.tags.slice(0)
                  if (tags[index].edit) {
                    tags[index].edit = false
                    editRow(tags)
                    saveEditStatus(tags)
                  } else {
                    tags[index].edit = true
                    editRow(tags)
                  }
                }} src={data.tags[index].edit ? applyIcon : pencilIcon} alt=""/>
                <img className='cursor-pointer' onClick={() => delTag(index)} src={deleteIcon} alt=""/>
              </div>
            </div>
          ))
        }
      </div>
      <BaseButton disabled={addTagLoading} className={'text-white bg-interactive-elem border-btn-secondary rounded-md border-[1px] py-[7px] absolute top-[-10%] right-0'} onClick={() => addTag()}>Добавить метку</BaseButton>
    </>
  )
}
