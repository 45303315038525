import React from 'react'

export default function BaseButton({className, children, ...props}) {
  return (
    <button 
      type='button' 
      className={`disabled:pointer-events-none flex justify-center items-center transition duration-300 ease-out text-sm rounded-lg text-sm px-[22px] py-3 ${className}`}
      {...props}
    >{children}</button>
  )
}
