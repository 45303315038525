import PropTypes from "prop-types";
import React, {useEffect, useId, useRef, useState} from "react";
import CheckIcon from "../../../../icons/CheckIcon";

const defaultMaxHeight = 104;

export default function DropDown({items, value, show, setShow, onChange, type='label'}: any) {
  const id = useId();
  const container = useRef<any>(null);
  const wrapper = useRef<any>(null);
  const [position, setPosition] = useState<any>('bottom');
  const [maxHeight, setMaxHeight] = useState<any>(defaultMaxHeight);
  const [containerPosition, setContainerPosition] = useState<any>(0);

  useEffect(() => {
    function handleClick(event: any) {
      const {clientY} = event;

      if (!show) {
        setContainerPosition(clientY);
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }

  }, [show, setShow, wrapper]);

  useEffect(() => {
    if (!container) {
      return;
    }

    const nodes = Array.from(container.current.childNodes);
    const first: any = nodes.slice(0, 3);

    const height = first.reduce((total:any, item: any) => total + item.getBoundingClientRect().height, 0) + 8;
    setMaxHeight(defaultMaxHeight < height && height || defaultMaxHeight);
  }, [items])

  useEffect(() => {
    if (show) {
      setPosition('bottom');
      const windowHeight = window.innerHeight

      const {height: containerHeight} = container.current.getBoundingClientRect()
      const fromContainerToBottom = Math.abs(windowHeight - containerPosition);
      containerHeight > fromContainerToBottom && setPosition('top');
      containerHeight < fromContainerToBottom && setPosition('bottom');
    }
  }, [show, containerPosition]);

  const positionClassName = 'bottom' === position && 'top-[105%]' || 'bottom-[105%]'
  const dropDownClassName = show && `shadow-dropdown opacity-100  !max-h-[300px]` || 'opacity-0 !z-0'

  return (
    <div className={`${dropDownClassName} ${positionClassName} rounded-lg transition-all overflow-hidden max-h-0 duration-500 absolute w-full bg-white border border-gray-10 z-[1011]`} ref={wrapper}>
      <div style={{maxHeight}} className={`${!show && '!max-h-[0px]' || ''} flex flex-col overflow-y-auto overflow-x-hidden transition-all duration-500 pretty-scroll`} ref={container}>
        {
          items.map((item: any, index: any) => {
            let selected = value.map((item: any) => item.value).includes(item.value)
            return (
              <div
                className={`py-[10px] flex items-center gap-1 cursor-pointer select-none  px-[14px] transition duration-300 ${selected ? 'bg-interactive-elem text-white' : 'hover:bg-interactive-bg'} `}
                key={id + index}
                onClick={() => {
                  if (selected) {
                    onChange(value.filter((v: any) => v.value !== item.value))
                  } else {
                    onChange([...value, item])
                  }
                }}
              >
                <CheckIcon color="white"/>
                {item.label}
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

DropDown.propTypes = {
  items: PropTypes.array.isRequired,
  value: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
}
