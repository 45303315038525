import React, {useEffect, useId, useState} from "react";
// @ts-ignore
import DatePicker from 'react-datepicker';
import "../../../../styles/datepicker.sass"
// @ts-ignore
import calendarIcon from "../../../../images/calendar.svg"
import Tooltip from "../../Tooltip";

export default function DateTimePicker(
  {
    label,
    textTooltip,
    required = false,
    value = new Date(),
    onChange,
    dateFormat = "dd-MM-yyyy HH:mm",
    classNameInput = '',
    errors,
    name = '',
    placeholder = '',
    errorLabel = true
  }: any
) {
  const [date, setDate] = useState<any>(null);
  const id = useId()
  const error = errors[name];
  useEffect(() => {
    delete errors[name];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  
  useEffect(() => {
    if (value) {
      if (value instanceof Date) {
        setDate(value)
      } else if (value.indexOf('T') > -1) {
        const parts = value.split('T');
        const partsDate = parts[0].split('-');
        const partsTime = parts[1].split(':');
        setDate(new Date(partsDate[0], partsDate[1] - 1, partsDate[2], partsTime[0], partsTime[1]))
      } else {
        const parts = value.split(', ');
        const partsDate = parts[0].split('.');
        const partsTime = parts[1].split(':');
        setDate(new Date(partsDate[2], partsDate[1] - 1, partsDate[0], partsTime[0], partsTime[1], partsTime[2]))
      }
    } else {
      setDate(null)
    }
  }, [value])

  return (
    <div>
      {
        label &&
          <label htmlFor={id} className="flex items-center mb-2 font-medium text-sm">
            { label }
              <span className='text-error-main'>{required && '*'}</span>
            {
              textTooltip && <Tooltip textTooltip={textTooltip} defaultText={name}/>
            }
          </label>
      }
      <div className={'relative'}>
        <DatePicker
          id={id}
          selected={Date.parse(date)}
          className={`outline-0 disabled:text-gray-40 disabled:pointer-events-none text-gray-50 py-[10px] px-[14px] w-full text-sm bg-white border-gray-20 border-[1px] rounded-lg pr-[40px] ${classNameInput} ${error && 'border border-red-500 disabled:border disabled:border-red-500'} `}
          onChange={(date: Date) => {
            onChange(date.toLocaleString())
          }}
          timeInputLabel="Время:"
          locale={'ru'}
          dateFormat={dateFormat}
          timeFormat="HH:mm"
          showTimeInput
          placeholderText={placeholder}
        />
        <img
          className={`absolute top-1/2 -translate-y-1/2 pointer-events-none right-[10px]`}
          src={calendarIcon}
          alt={'input-icon'}
        />
      </div>

      {error && errorLabel && (<span className={'text-error-font text-xs font-medium'}>{error}</span>)}
    </div>

  )
}
