import React, {useEffect, useId, useState} from "react";
import ImageLoad from "../image/ImageLoad";
import BaseButton from "../../Button/BaseButton";
import closeIcon from "../../../../images/close.svg";
import {getBase64FromUrl, getImageUrl} from "../../../../libs/helpers/image";
import {SVGWrap} from "../../util/SVGWrap";

export default function IconModal({show = false, items = [], setStandartIndex, setShow, onSubmit, ...props}: any) {
  const [icon, setIcon] = useState(null)
  const [iconSrc, setIconSrc] = useState(null)
  const [currentIcon, setCurrentIcon] = useState<number>(-1)
  const [dataUrl, setDataUrl] = useState<any>({})

  const id = useId();

  const selectStandart = (url: any) => {
    getBase64FromUrl(url).then((result: any) => {
      setDataUrl({...dataUrl, defaultIcon: {iconSrc: url, icon: result}})
    })
  }

  const selectOwn = (value: any) => {
    getImageUrl(value).then((url:  any) => {
      setDataUrl({...dataUrl, uploadIcon: {iconSrc: url, icon: value}})
    })
  }

  useEffect(() => {
    setStandartIndex(currentIcon)
    let i = items.filter((c, i):boolean => {return  i === currentIcon})
    selectStandart(i[0]?.icon)

  }, [currentIcon])

  useEffect(() => {
    if (dataUrl?.defaultIcon?.iconSrc !== undefined) {
      setIcon(dataUrl?.defaultIcon?.icon)
      setIconSrc(dataUrl?.defaultIcon?.iconSrc)
    }else {
      setIcon(dataUrl?.uploadIcon?.icon)
      setIconSrc(dataUrl?.uploadIcon?.iconSrc)
    }
  }, [dataUrl])

  return (
    <div className={`fixed grid w-full h-full modal-wrapper ${!show && 'hidden'}`}>
      <div className={'grid relative bg-white rounded-3xl w-[35%] p-5 modal'}>
        <div className={'flex flex-col gap-y-4'}>
          <div className={'flex justify-between'}>
            <span className={'text-2xl'}>Выбрать иконку</span>
            <img className={'w-[25px] cursor-pointer'} src={closeIcon} onClick={() => {setShow(false)}} alt=""/>
          </div>
          <div className={'flex flex-col border-gray-20 border-[1px] rounded-3xl p-4 mb-4'}>
            <div className={'text-xl text-left mb-3'}>Стандартные</div>
            <div className={'flex flex-row flex-wrap'}>
              {items.map((item: any, index: any) => (
                <div
                  className={`rounded min-h-[32px] p-2 mr-2 flex justify-center shrink-0 cursor-pointer select-none items-center transition duration-300 hover:bg-gray-10 ${currentIcon == index ? 'bg-gray-10 border-[1px] border-gray-40' : ''} `}
                  key={id + index}
                  onClick={() => {
                    setCurrentIcon(index)
                  }}>
                  <SVGWrap src={item.icon} width="35px" height="35px"/>
                </div>))}
            </div>
          </div>
          <div className={'flex flex-col border-gray-20 border-[1px] rounded-3xl p-4'} onClick={()=> setCurrentIcon(-1)}>
            <div className={'text-xl text-left mb-3'}>Загрузить свою</div>
            <ImageLoad
              onInput={(value: any) => {
                if (value) {
                  selectOwn(value)
                }
              }}

              name={'logo'}
              classPlaceholderBox={'h-[50px] w-[50px]'}
              checkImageValid={(img, dataUrl, file) => {
                if (!file.type.includes('svg'))
                  return "Изображение должно иметь тип SVG"
                if (img.width !== img.height)
                  return `Изображение должно быть квадратным (текущий размер ${img.width}x${img.height})`
                return null;
              }}
              {...props}
            />
          </div>
          <div className={'flex justify-end'}>
            <BaseButton
              className={'bg-interactive-elem text-white'}
              onClick={() => {
                setShow(false);
                onSubmit(icon, iconSrc)
              }}
            >Выбрать</BaseButton>
          </div>
        </div>
      </div>
    </div>
  )
}
