import React, {useEffect, useId, useRef, useState} from "react"
import {MAX_FILE_SIZE} from "../../../../constants/files";
import BaseButton from "../../Button/BaseButton";
import Tooltip from "../../Tooltip";
import {getRandomKey} from "../../../../libs/helpers/datatable";

interface MediaLoadProps {
  className?: string;
  classPlaceholderBox?: string;
  onInput: Function;
  name: string;
  errors: any;
  label?: string;
  placeholder?: string;
  defaultValue?: any;
  onRemove: Function;
  required?: boolean;
  textTooltip?: string;
}

export default function MediaLoad(
  {
    className = '',
    classPlaceholderBox = '',
    onInput,
    name = '',
    errors = {},
    label = 'Загрузить медиа',
    placeholder = 'Media',
    defaultValue = null,
    onRemove = () => {
    },
    required = false,
    textTooltip = '',
  }: MediaLoadProps) {
  const id = useId()
  const input = useRef<any>(null);
  const video = useRef<HTMLVideoElement>(null);
  const [files, setFiles] = useState<any>([])
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [incorrectFilesError, setIncorrectFilesError] = useState('');
  const [defaultUrl, setDefaultUrl] = useState<any>(defaultValue)
  const imageTypes = ['jpeg', 'jpg', 'png', 'svg', 'webp']
  const error = errors[name];


  useEffect(() => {
    setDefaultUrl(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    onInput && onInput(files[0] || defaultUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const handleInput = ({target}: any) => {
    let targetFiles: any = [...target.files]
    const urls: any = []
    const reasons: any = []

    setIncorrectFilesError('')
    delete errors[name]

    const bigFiles = [];
    targetFiles = targetFiles.filter((file: any) => {
      //console.log(file)
      if (MAX_FILE_SIZE * 10 < file.size) {
        bigFiles.push(file.name)
      }

      return MAX_FILE_SIZE * 10 >= file.size
    })

    //console.log(target.files)

    if (0 < bigFiles.length) {
      setIncorrectFilesError(`Не все файлы были добавлены: слишком большой размер. Максимальный размер файла: 50Mb`)
    }


    Promise.allSettled([...target.files].map(getImageUrl)).then(results => {

      results.map((result, index) => {
        const {value, reason}: any = result;
        //console.log(result)
        value && urls.push(value);
        reason && reasons.push(reason);
        reason && (targetFiles = targetFiles.filter((_ :any, i: any) => i !== index));
      });

      setFiles((files: any) => [...files, ...targetFiles]);
      setSelectedFiles((selectedFiles: any) => [...selectedFiles, ...urls]);

      0 < reasons.length && (setIncorrectFilesError(value => `${0 >= value.length && 'Не все выбранные файлы были добавлены: ' || ', '} не корректный формат. Допустимые форматы - JPG, JPEG, PNG`))
    })
  }

  function getImageUrl(file: any) {
    //console.log(file)
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file)
      reader.onload = (event: any) => {
        //console.log(event)
        //console.log(file?.type.startsWith('video/'))
        if (file?.type.startsWith('video/')) {
          resolve(event.target.result)
        } else {
          const t = new Image();

          t.src = event.target.result
          t.onload = () => {
            resolve(event.target.result)
          }
          t.onerror = () => {
            reject(file.name);
          }
        }
      }
    })
  }

  const isVideoFile = () => {
    if (defaultUrl) {
      const defaultType = defaultUrl?.split('.').pop()
      return !imageTypes.includes(defaultType);
    }
    return false;
  }

  const handleClear = () => {
    input.current.value = null;
    setDefaultUrl(null);
    setFiles([]);
    setSelectedFiles([]);
    setIncorrectFilesError('');
    onRemove()
    delete errors[name];
  }

  const handleClick = () => {
    input.current.click();
  }

  return (
    <div key={id} className={`flex gap-4 ${className}`}>
      {selectedFiles.length > 0 &&
          <div className="flex flex-wrap gap-2">
            {selectedFiles.map((item: any, index: any) => (
              <div>
                {
                  item.includes('video/') ?
                    <video ref={video} width={150} autoPlay={false} controls={true} muted={true} loop={true} src={item}></video> :
                    <div className={`h-[76px] relative rounded-lg overflow-hidden flex item-center justify-center`}>
                      <img src={item} className="h-full w-auto" alt={'uploaded-image'}/>
                    </div>
                }

              </div>
            ))}
          </div>
      }

      {
        !!defaultUrl && selectedFiles.length == 0 &&
          <div className="flex flex-wrap gap-2">
              <div className=''>
                  <div className={`h-[76px] relative overflow-hidden`}>
                    {
                      isVideoFile() ?
                        <video ref={video} autoPlay={false} controls={true} width={150} muted={true} loop={true} src={defaultUrl}></video> :
                        <img src={defaultUrl} className="h-full w-auto rounded-lg" alt={'uploaded-image'}/>
                    }

                  </div>
              </div>
          </div>
      }

      {
        selectedFiles.length == 0 && !defaultUrl &&
          <div onClick={handleClick}
               className={`flex items-center justify-center text-[10px] text-gray-40 w-[76px] h-[76px] border-[1.5px] border-btn-secondary rounded-xl cursor-pointer ${classPlaceholderBox}`}>
            {placeholder}
          </div>
      }

      <input className="invisible absolute w-0"
             ref={input}
             onChange={handleInput}
             type="file"
             name="images"
             accept="video/*, image/*" multiple={false}/>
      <div className="flex flex-col justify-between">
        {
          label &&
            <label className="flex items-center mb-2 font-medium text-sm">
              {label}
                <span className='text-error-main'>{required && '*'}</span>
              {
                textTooltip && <Tooltip textTooltip={textTooltip} defaultText={name}/>
              }
            </label>
        }
        {
          (selectedFiles.length === 0 && !defaultUrl) &&
            <BaseButton className="border-btn-secondary text-interactive-text rounded-md border-[1px] py-[7px] w-min"
                        onClick={handleClick}>Загрузить</BaseButton>
        }
        {
          !(selectedFiles.length === 0 && !defaultUrl) &&
            <BaseButton className="border-state-error-main text-error-font rounded-md border-[1px] py-[7px] w-min"
                        onClick={handleClear}>Удалить</BaseButton>
        }
      </div>
      {error && (<span className={'text-error-font text-xs font-medium'}>{error}</span>)}
      {incorrectFilesError && (<span className={'text-error-font text-xs font-medium'}>{incorrectFilesError}</span>)}
    </div>
  )
}
