import React, {useCallback, useEffect, useRef, useState} from "react"
import ReactFlow, {
  addEdge, Connection, Edge, HandleType, MarkerType, Node,
  ReactFlowProvider,
  updateEdge,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import { v4 as uuid } from 'uuid'

import FunnelHeader, {AddActionEnum} from "../../../components/common/Header/FunnelHeader";
import StartNode from "./Nodes/StartNode"
import ConditionNode from "./Nodes/ConditionNode"
import TimerNode from "./Nodes/TimerNode"
import ActionNode from "./Nodes/ActionNode"
import {FunnelNodesWrapper, useFunnelNodeContext} from "../../../contexts/FunnelNodes";
import Sidebar from "./Sidebar";
import {apiClient} from "../../../libs/api/apiClient";
import {CompaniesContextWrapper, useCompaniesContext} from "../../../contexts/Companies";
import {BranchesContextWrapper, useBranchesContext} from "../../../contexts/Branches";
import Loader from "../../../components/common/Loaders/Loader";
import {showErrorToast, showSuccessToast} from "../../../libs/helpers/toasts";
import {fetchInitialTypes} from "../../../providers/dictionaries/funnels";

import "../../../styles/funnels.sass"

// @ts-ignore
import homeImg from "../../../images/home.svg"
// @ts-ignore
import plusImg from "../../../images/gray-plus.svg"
// @ts-ignore
import minusImg from "../../../images/gray-minus.svg"
import {getFormData} from "../../../libs/helpers/formData";
import {FunnelDataWrapper, useFunnelDataContext} from "../../../contexts/Funnels";
import BaseButton from "../../../components/common/Button/BaseButton";

export enum NodeTypeEnum {
  start = 'start',
  condition = 'condition',
  timer = 'timer',
  action = 'action'
}

const initialNode = { id: uuid(), type: NodeTypeEnum.start, deletable: false, position: { x: 40, y: 150 }, data: {} }

const nodeTypes = {
  start: StartNode,
  condition: ConditionNode,
  timer: TimerNode,
  action: ActionNode
}

const Flow = () => {
  const {company} = useCompaniesContext()
  const {branch} = useBranchesContext()
  const {addNode, focusNode} = useFunnelNodeContext()
  const {setInitialAction, hasFunnelDataError, dataLoading, initialAction} = useFunnelDataContext()
  const { zoomIn, zoomOut } = useReactFlow();
  const [searchParams] = useSearchParams()
  const step = searchParams.get('step') || null

  const navigate = useNavigate()
  const {id: funnelId} = useParams<{id?: string}>()
  const [nodes, setNodes, onNodesChange] = useNodesState<any>([])
  const [edges, setEdges, onEdgesChange] = useEdgesState<any>([])

  const edgeUpdateSuccessful = useRef(true);
  const [data, setData] = useState<any>({})
  const [loading, setLoading] = useState(true);
  const [showSidebar, setShowSidebar] = useState(false)
  const [selectedNode, setSelectedNode] = useState<null | Node>(null)
  const [initialNodeTypes, setInitialNodeTypes] = useState<any>([])


  const onConnect = useCallback((params: any) => {
    const color = params?.sourceHandle && params.sourceHandle == '1' ? '#55B27A' : params?.sourceHandle && params.sourceHandle == '0' ? '#E39393' : '#76767A'

    const customStyle = {
      strokeWidth: 2,
      stroke: color,
    }

    const customMarkerEnd = {
      type: MarkerType.ArrowClosed,
      width: 15,
      height: 15,
      color: color,
    }

    setEdges((eds) => addEdge({...params, zIndex: 100, style: customStyle, markerEnd: customMarkerEnd}, eds))
  }, [setEdges]);

  const onEdgeUpdateStart = useCallback((event, edge: Edge, handleType: HandleType) => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = useCallback((oldEdge: Edge, newConnection: Connection) => {
    edgeUpdateSuccessful.current = true;
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
  }, []);

  const onEdgeUpdateEnd = useCallback((event, edge: Edge) => {
    if (!edgeUpdateSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }

    edgeUpdateSuccessful.current = true;
  }, []);

  const handleNodeClick = (event: any, node: Node) => {
    if (showSidebar) {
      //setShowSidebar(false)
      return
    }

    if (node.type != NodeTypeEnum.start) {
      setShowSidebar(true)
      setSelectedNode(node)
    }
  }

  //const handleCloseTab = (ev: BeforeUnloadEvent) => {
  //  ev.preventDefault();
  //  return ev.returnValue = 'Are you sure you want to close?';
  //}

  const handleUpdate = () => {
    if (company && branch && funnelId) {
      const currData = {
        objects: {
          nodes,
          edges,
        },
        _method: 'patch'
      }
      apiClient.funnels.update(company.id, branch.id, funnelId, {body: currData}).then(({data, errors, message}: any) => {
        if (message || errors) {
          showErrorToast({
            content: message || 'Произошла ошибка при сохранении изменений'
          })
        } else {
          //console.log('update')
          showSuccessToast({
            content: 'Изменения успешно сохранены'
          })
        }
      }).catch((reason: any) => {
        showErrorToast({
          content: reason || 'Произошла ошибка при сохранении изменений'
        })
      })
    }
  }

  useEffect(() => {
    //window.addEventListener("beforeunload", (ev) => handleCloseTab(ev))

    fetchInitialTypes().then((data: any) => {
      setInitialNodeTypes(data)
    }).catch(() => {})
  }, [])

  useEffect(() => {
    if (company && branch && funnelId) {
      setLoading(true)
      apiClient.funnels.get(company.id, branch.id, funnelId).then(({data, message, errors}: any) => {
        if (data) {
          setInitialAction(data?.initialActionType)

          if (!data?.nodes) {

            initialNode.data = {
              type: data?.initialActionType,
              title: initialNodeTypes.find(({value}: any) => value == data?.initialActionType)?.label
            }

            data.nodes = [initialNode]
          }
          //TODO: исправить эту дичь.
          // Происходит когда получаю иформацию о нодах и эджах, а все числовые значения являются строками
          // такое либа (ReactFlow) хавать не хочет
          setNodes(JSON.parse(JSON.stringify(data?.nodes), (key, value) => {
            if (key == 'children' && !value) {
              return []
            }

            if (value instanceof Array) {
              return value
            }

            if (isNaN(Number(value))) {
              return value
            }

            if (key == 'deletable' || key == 'isGroup') {
              return Boolean(+value)
            }

            return +value
          }) || [])

          setEdges(JSON.parse(JSON.stringify(data?.edges), (key, value) => {
            if (isNaN(Number(value))) {
              return value
            }

            if (key == 'sourceHandle' || key == 'targetHandle') {
              return value
            }

            return +value
          }) || [])

          setData(data)

          if (step) {
            focusNode(false, step)
          }
        } else {
          showErrorToast({
            content: message || 'При загрузке информации о воронке произошла ошибка!'
          })
        }
      }).catch((reason: any) => {
        showErrorToast({
          content: reason || 'При загрузке информации о воронке произошла ошибка!'
        })
      }).finally(() => setLoading(false))

    }
  }, [company, branch])

  if (hasFunnelDataError) 
    return (<div className="App flex">
              <div className="w-full min-h-[calc(100vh-88px)]">
                <FunnelHeader
                  title={data?.title || 'Не указано'}
                  reset={() => navigate('/funnels')}
                  handleAddAction={(action: AddActionEnum) => {}}
                  handleSearchNode={(search: string) => {}}
                />
                <div className={`min-h-screen w-full flex items-center justify-center text-lg`}>Произошла ошибка при загрузке данных воронки</div>
              </div>
            </div>)

  if (loading || dataLoading)
    return <Loader title={''} SVGClassName={'!w-[30px] !h-[30px]'} className={'w-full min-h-screen'}/>

  return (
      <div className="App flex">
        <div className="w-full min-h-[calc(100vh-88px)]">
          <FunnelHeader
            title={data?.title || 'Не указано'}
            reset={() => navigate('/funnels')}
            handleAddAction={(action: AddActionEnum) => addNode(action)}
            handleSearchNode={(search: string) => focusNode(false, search)}
            initialAction={initialAction}
          />
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onNodeClick={handleNodeClick}
            onEdgeUpdate={onEdgeUpdate}
            onEdgeUpdateStart={onEdgeUpdateStart}
            onEdgeUpdateEnd={onEdgeUpdateEnd}
            elevateNodesOnSelect={false}
            onConnect={onConnect}
            className={`${showSidebar && 'bg-gray-40/[.4]'}`}
            minZoom={0.1}
            deleteKeyCode={null}
            fitView
          >
            <div className={'absolute z-[1000] flex flex-col gap-y-6 right-[14px] top-[30px] p-4 rounded-2xl bg-white'}>
              <img src={homeImg} alt="" className={'cursor-pointer'} onClick={() => focusNode(true, '')}/>
              <img src={plusImg} alt="" className={'cursor-pointer'} onClick={() => zoomIn({duration: 300})}/>
              <img className={'py-2 cursor-pointer'} src={minusImg} alt="" onClick={() => zoomOut({duration: 300})}/>
            </div>
            <div className={'absolute z-[1000] flex flex-col left-[14px] bottom-[14px]'}>
              <BaseButton
                onClick={() => handleUpdate()}
                className={'py-[10px] px-4 bg-funnels-green text-white cursor-pointer rounded-xl hover:bg-funnels-green/[.7]'}>
                Сохранить изменения
              </BaseButton>
            </div>
          </ReactFlow>
        </div>
        <Sidebar
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
          node={selectedNode}
        />
      </div>
  )
}



export default function CreateFunnel() {

  return (
    <CompaniesContextWrapper>
      <BranchesContextWrapper>
        <ReactFlowProvider>
          <FunnelNodesWrapper>
            <FunnelDataWrapper>
              <Flow />
            </FunnelDataWrapper>
          </FunnelNodesWrapper>
        </ReactFlowProvider>
      </BranchesContextWrapper>
    </CompaniesContextWrapper>
  )
}
