import React, {useEffect, useState} from "react";
import TextInputMultipleList from "../../../components/common/form/input/TextInputMultipleList";
import {useProductsContext} from "../../../contexts/Products";

export default function BaseOrdersComposition({data, errors, updateField, updateFieldNoRender}: any) {
  const {products, loading, updateProducts}: any = useProductsContext()
  const [loadProducts, setLoadProducts] = useState(false)

  useEffect(() => {
    if (!loading && !loadProducts) {
      setLoadProducts(true)
      updateProducts(null)
    }
  }, [products])

  if (loading) return (
    <div className="text-gray-40 text-3xl text-center mt-[35vh]">
      <span>Загрузка...</span>
    </div>
  )

  return (
    <>
      <TextInputMultipleList
        label={'Товары'}
        textTooltips={['Товар', 'Кол-во', 'Цена']}
        required={true}
        name={'goods'}
        values={data.goods || []}
        fields={['goodId', 'quantity', 'amount']}
        types={['', 'number', 'number']}
        min={'0'}
        fieldTypes={['search', 'input', 'input']}
        placeholders={['Товар', '2', '0']}
        onChange={(value: any) => {
          value.forEach((item: any) => {
            item.amount = products.find(({id}: any) => id == item.goodId)?.price || ''
          })
          value = value.filter((row: any) => Object.values(row).some(value => !!value))
          updateField('goods', value)
        }}
        disabledArray={[false, false, true]}
        labels={['Товар', 'Кол-во', 'Цена']}
        errors={errors}
        options={[products.map(({title, id}: any) => {return {label: title, value: id}}), [], []]}
        className={'w-full border-0 p-0'}
        remove={true}
      />
    </>
  )
}
