import React, {useEffect, useId, useState} from 'react'
import Company from './Company'
import { Link, useLocation } from 'react-router-dom'
import { useCompaniesContext } from '../../../contexts/Companies'
import MenuMultipalLink from './MenuMultipalLink'
import MenuLink from './MenuLink'

// @ts-ignore
import logo from '../../../images/logoHeader.svg'
import logoText from '../../../images/logoHeaderText.svg'
// @ts-ignore
import burgerImg from '../../../images/burger.svg'

import menuItems from './menuItems'
import useUser from "../../../hooks/useUser";
import {checkPermissionAccessMenuItem} from "../../../libs/helpers/permissions";
import { auth } from '../../../libs/auth'
import { useSidebarContext } from '../../../contexts/Sidebar'
import { useFormContext } from '../../../contexts/Form'


export default function Sidebar() {
  const { company, companies } = useCompaniesContext()
  const {hiddenMode, setHiddenMode} = useSidebarContext()
  const {user}: any = useUser()
  const id = useId()

  //для подсветки активного item
  const [currentUrl, setCurrentUrl] = useState('')
  const location = useLocation()
  const {checkIsFormDirty, openDialog} = useFormContext()

  useEffect(() => {
    setCurrentUrl(location.pathname)
  }, [location.pathname])

  return (
    <div key={id} className={`min-h-screen transition duration-500 ${hiddenMode ? 'w-[60px]' : 'max-w-[340px]'} bg-gray-50`}>
      <div className={`flex justify-between items-center px-3 h-[64px] ${hiddenMode ? 'w-[60px]' : 'w-[340px]'}`}>
        <Link to='/' onClick={(e) => {
          if (checkIsFormDirty()) {
            e.preventDefault()
            openDialog('/')
          }
        }}>
          <div className='flex items-center'>
            <img src={logo} alt="" className='h-[30px] ml-1' />
            {!hiddenMode?  <img src={logoText} alt="" className='h-[30px] ml-4' /> : <></>}
          </div>
        </Link>
        {
          !hiddenMode ? <img className='cursor-pointer' src={burgerImg} alt="" onClick={() => setHiddenMode(!hiddenMode)} /> : <></>
        }
      </div>
      {auth && auth?.current && auth?.current?.permissions && auth?.current?.permissions?.length > 0 && <Company hiddenMode={hiddenMode} setHiddenMode={(value: any) => setHiddenMode(value)}/>}
      {
        (companies?.length > 0 && company && user?.permissions?.length > 0) &&
        menuItems.map((item: any) => {
          if (hiddenMode) {
            if (item?.icon && checkPermissionAccessMenuItem(user?.permissions, item?.name, company?.id)) {
              return (
                <div
                  className={`
                    ${((item.items && item.items.find((item: any) => item.url === currentUrl))) && 'bg-zinc-900'} 
                    ${item.url === currentUrl && 'bg-zinc-900'}
                    flex items-center h-16 w-full text-white cursor-pointer hover:bg-zinc-900`}
                  onClick={() => setHiddenMode(false)}
                >
                  <div className='h-full w-16 flex items-center justify-center'>
                    <img src={item.icon} alt=""/>
                  </div>
                </div>
              )
            }
          }
          if (item?.items) {
            if (item?.name) {
              if (checkPermissionAccessMenuItem(user?.permissions, item?.name, company?.id)) {
                return (
                  <MenuMultipalLink
                    key={item.url + item.label}
                    {...item}
                    currentUrl={currentUrl}
                  />
                )
              }
            } else {
              return (
                <MenuMultipalLink
                  key={item.url + item.label}
                  {...item}
                  currentUrl={currentUrl}
                />
              )
            }
          }
          if (item?.name) {
            if (checkPermissionAccessMenuItem(user?.permissions, item?.name, company?.id)) {
              return <MenuLink
                key={item.url + item.label}
                {...item}
                currentUrl={currentUrl}
              />
            }
          } else {
            return <MenuLink
              key={item.url + item.label}
              {...item}
              currentUrl={currentUrl}
            />
          }
          return <></>
        })
      }
    </div>
  )
}
