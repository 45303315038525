import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import Base from "./Base";
import BaseButton from "../../../components/common/Button/BaseButton";
import {useNavigate, useParams} from "react-router-dom";
import useForm from "../../../hooks/useForm";
import initForm from "../initForm";
import {apiClient} from "../../../libs/api/apiClient";
import {showErrorToast, showSuccessToast} from "../../../libs/helpers/toasts";
import {useCompaniesContext} from "../../../contexts/Companies";
import {useBranchesContext} from "../../../contexts/Branches";
import {useFormContext} from "../../../contexts/Form";

export default function EditReview() {
  const {company} = useCompaniesContext()
  const {branch} = useBranchesContext()
  const {checkIsFormDirty, openDialog,  needUpdate, setNeedUpdate } = useFormContext()

  const [loading, setLoading] = useState(false)
  const {data, updateData, updateField, updateFieldNoRender, changedFieldsRefresh, errors, updateErrors, submit}: any = useForm(JSON.parse(JSON.stringify(initForm)))

  const {id} = useParams<{id?: string}>()
  const navigate = useNavigate()

  const handleSubmit = () => {
    submit(async (form: any) => {
      setLoading(true)
      apiClient.nps.orderReviews.update(company?.id, branch?.id, id,{body: form}).then(({data, errors}: any) => {
        if (data) {
          showSuccessToast({
            content: "Отзыв успешно изменен"
          })
          changedFieldsRefresh()
          getReview()
        } else {
          updateErrors(errors || {});

          showErrorToast({
            content: 'Что-то пошло не так'
          })

        }
      }).catch((e) => console.log(e)).finally(() => {
        setNeedUpdate(false)
        setLoading(false)
      })
    }, 'changed')
  }

  const reset = () => {
    navigate("/reviews")
  }

  const getReview = () => {
    if (company && branch) {
      setLoading(true)
      // prefetch dictionaries
      apiClient.dictionaries.reviewEstimations().then();

      apiClient.nps.orderReviews.get(company.id, branch.id, id).then(({data, errors}: any) => {
        if (data) {
          updateData(data)
        } else {
          updateErrors(errors || {})
          showErrorToast({
            content: 'Что-то пошло не так'
          })
        }
        setLoading(false)
      }).catch((err) => {
        setLoading(false)
        console.log(err)
      })
    }
  }

  useEffect(() => {
    getReview()
  }, [company, branch])

  useEffect(() => {
    if (needUpdate) {
      handleSubmit()
    }
  }, [needUpdate])

  if (loading) return (
    <div className="text-gray-40 text-3xl text-center mt-[35vh]">
      <span>Загрузка...</span>
    </div>
  )

  return (
    <div className='px-8 pb-8 pt-2 text-gray-50'>
      <div className='mb-8'>
        <div className='text-[44px] leading-[60px] mb-2'>NPS</div>
        <Breadcrumbs
          links={[
            {to: `/reviews`, label: 'Отзывы'},
            {to: '', label: `Редактирование отзыва #${id}`},
          ]}

          back={`/reviews`}
        />
      </div>
      <div className={'rounded-xl bg-white py-5'}>
        <Base errors={errors} data={data} updateField={updateField} updateFieldNoRender={updateFieldNoRender}/>
        <div className={'mt-6 border-gray-10 border-y-[1px] flex justify-between z-[50] w-full sticky bottom-0 bg-white'}>
          <BaseButton
            className={'border-red-500 border-[2px] text-red-500  m-4 py-[7px] '}
            onClick={(e: any) => {
              if (checkIsFormDirty()) {
                e.preventDefault()
                openDialog('/reviews')

              }else reset()
            }}
          >
            Отмена
          </BaseButton>
          <BaseButton
            className={'bg-interactive-elem text-white m-4 py-[7px] '} onClick={handleSubmit}>Сохранить
          </BaseButton>
        </div>
      </div>
    </div>
  )
}
