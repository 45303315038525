import React from "react";
import Checkbox from "../../../../components/common/form/checkbox/Checkbox";
import {useBranchesContext} from "../../../../contexts/Branches";
import TextInput from "../../../../components/common/form/input/TextInput";
import ImageLoad from "../../../../components/common/form/image/ImageLoad";
import SimpleMultipleValueSelect from "../../../../components/common/form/select/multiple/SimpleMultipleValueSelect";

export default function Base({data, errors, updateField, updateFieldNoRender}: any) {
  const { branches} = useBranchesContext()

  return (
    <div className={'flex flex-col px-3 gap-y-6'}>
      <Checkbox
        label={'Активность опции'}
        name={'isActive'}
        onChange={(value) => updateField('isActive', value)}
        value={data.isActive}
        errors={errors}
        required={true}
      />
      <TextInput
        label={'Название опции'}
        textTooltip={'Название опции'}
        placeholder={'Введите название опции'}
        name={'title'}
        value={data?.title}
        onChange={value => updateFieldNoRender('title', value)}
        errors={errors}
        required={true}
      />
      <ImageLoad
        label={'Основная фотография (формат: JPG, JPEG, PNG)'}
        errors={errors}
        name={'image'}
        defaultValue={data?.image}
        onInput={value => updateField('image', value)}
        required={true}
        resizeTo={[500,500]}//117,117
      />
      <TextInput
        label={'Артикул'}
        textTooltip={'Артикул'}
        placeholder={'Введите артикул'}
        name={'article'}
        value={data?.article}
        onChange={value => updateFieldNoRender('article', value)}
        errors={errors}
        required={false}
      />
      <TextInput
        type={'number'}
        min={'0'}
        label={'Цена'}
        textTooltip={'Цена'}
        placeholder={'Тектовое поле'}
        name={'price'}
        value={data?.price}
        onChange={value => updateFieldNoRender('price', value)}
        errors={errors}
        required={true}
      />
      <TextInput
        type={'number'}
        min={'0'}
        label={'Себестоимость'}
        textTooltip={'Себестоимость'}
        placeholder={'Тектовое поле'}
        name={'costPrice'}
        value={data?.costPrice}
        onChange={value => updateFieldNoRender('costPrice', value)}
        errors={errors}
      />
      <TextInput
        type={'number'}
        min={'0'}
        label={'Вес (гр.)'}
        textTooltip={'Вес (гр.)'}
        placeholder={'Тектовое поле'}
        name={'weight'}
        value={data?.weight}
        onChange={value => updateFieldNoRender('weight', value)}
        errors={errors}
      />
      <TextInput
        type={'number'}
        min={'0'}
        label={'Объем (мл.)'}
        textTooltip={'Объем (мл.)'}
        placeholder={'Тектовое поле'}
        name={'volume'}
        value={data?.volume}
        onChange={value => updateFieldNoRender('volume', value)}
        errors={errors}
      />
      <TextInput
        label={'Размер (произвольные единицы)'}
        textTooltip={'Размер (произвольные единицы)'}
        placeholder={'Тектовое поле'}
        name={'size'}
        value={data?.size}
        onChange={value => updateFieldNoRender('size', value)}
        errors={errors}
      />
        <SimpleMultipleValueSelect
          label={'Филиалы'}
          textTooltip={'Филиалы'}
          options={branches.map(branch => ({label: branch.title, value: branch.id}))}
          value={data?.branches || []}
          onChange={value => updateField('branches', value)}
          name={'branches'}
          placeholder={'Выберите филиалы'}
          errors={errors}
          required={true}
        />
    </div>
  )
}
