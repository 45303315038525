import {create, InstanceProps} from "react-modal-promise";
import BaseButton from "../Button/BaseButton";
import React, {useEffect, useId, useRef, useState} from "react";
// @ts-ignore
import closeIcon from "../../../images/greenClose.svg"
import TextInput from "../form/input/TextInput";
import Textarea from "../form/input/Textarea";

interface ModalProps extends InstanceProps<any> {
  isOpen: boolean
  onResolve: any
  onReject: any
  title: string
  inputType?: string
  inputLabel?: string
  inputPlaceholder?: string
  showDenyButton?: boolean
  showCancelButton?: boolean
  showCloseButton?: boolean
  allowOutsideClick?: boolean
  confirmButtonText?: string
  denyButtonText?: string
  cancelButtonText?: string
  preConfirm?: Function
  validate?: Function
  useValidation?: boolean
  classNameWrapper?: string
}

const InputModal = (
  {
    isOpen,
    onResolve,
    onReject,
    title,
    inputType = 'text',
    inputLabel = '',
    inputPlaceholder = '',
    showDenyButton = false,
    showCancelButton = false,
    showCloseButton = true,
    allowOutsideClick = true,
    confirmButtonText = 'Сохранить',
    denyButtonText = 'Удалить',
    cancelButtonText = 'Отмена',
    preConfirm = () => {},
    validate = () => {},
    useValidation = true,
    classNameWrapper = ''
  }: ModalProps) => {
  const id = useId();
  const wrapper = useRef<any>(null);
  const [value, setValue] = useState<any>('')
  const [error, setError] = useState('')

  /* Закрываем при клике вне поля*/
  useEffect(() => {
    function handleClick(event: any) {
      if (wrapper.current && !wrapper.current.contains(event.target) && allowOutsideClick) {
        onReject({isCancelled: true})
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }

  }, [isOpen, onReject, wrapper]);

  useEffect(() => {
    if (error) {
      setError('')
    }
  }, [value])

  return (
    <div id={id} className={`fixed grid w-full h-full modal-wrapper ${!isOpen && 'hidden'}`}>
      <div ref={wrapper} className={`grid relative bg-white rounded-[10px] ${classNameWrapper} max-w-[90%] max-h-[90%] modal`}>
        <div className={'flex justify-between px-4 py-[14px] items-center'}>
          <span className={'text-xl'}>{title}</span>
          {
            showCloseButton &&
              <img src={closeIcon} className={'w-[20px] cursor-pointer'} onClick={() => {
                onReject({isCancelled: true})
              }} alt=""/>
          }
        </div>
        <div className={"p-4 w-full gap-y-4 flex flex-row items-center justify-center"}>
          {
            inputType && (inputType === 'text' || inputType === 'url') &&
            <TextInput
                name={'modal-input'}
                type={inputType}
                required={false}
                value={value}
                onChange={setValue}
                placeholder={inputPlaceholder}
                label={inputLabel}
                className={'w-full'}
                errors={{}}
            />
          }
          {
            inputType && inputType === 'textarea' &&
              <Textarea
              name={'modal-area'}
              required={false}
              value={value}
              onChange={setValue}
              placeholder={inputPlaceholder}
              label={inputLabel}
              className={'w-full'} 
              maxLength={undefined}              
              />
          }
        </div>
        {
          error && error?.length &&
            <div className={'flex p-4 flex-col items-center text-error-font justify-center gap-y-2'}>
              {error}
            </div>
        }


        <div className="flex flex-row justify-between py-4 px-4 gap-y-4">
          {
            showDenyButton && <BaseButton className={'bg-error-font border-2 text-white border-error-font py-[7px]'} onClick={() => {
              onResolve({isConfirmed: false, isDenied: true})
            }}>{denyButtonText}</BaseButton>
          }
          <div className={`flex flex-row gap-x-4 items-center justify-between ${!showDenyButton && 'w-full'}`}>
            {
              showCancelButton && <BaseButton className={'bg-white border-2 text-error-font border-error-font py-[7px]'} onClick={() => {
                onReject({isCancelled: true})
              }}>{cancelButtonText}</BaseButton>
            }
            <BaseButton className={'bg-interactive-elem border-2 border-interactive-elem text-white py-[7px]'} onClick={() => {
              if (value) {
                if (useValidation) {
                  const isValid = validate(value)
                  if (isValid) {
                    preConfirm(value).then((res: any) => {
                      if (res.success) {
                        onResolve({isConfirmed: true, isDenied: false})
                      } else {
                        setError(res.error)
                      }
                    })
                  } else {
                    setError('Некорректный URL')
                  }
                } else {
                  onResolve({isConfirmed: true, isDenied: false, value: value})
                }
              } else {
                setError(inputPlaceholder || 'Необходимо ввести данные')
              }
            }}>{confirmButtonText}</BaseButton>
          </div>
        </div>
      </div>

    </div>
  )
}


export const inputModal = create(InputModal)
