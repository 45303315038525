import React from 'react'
import Senler from './Senler'
import ThModerator from './ThModerator'
import Revvy from "./Revvy";


export default function Mailing({ data, updateField, errors }: any) {
  return (
    <div className={ 'flex flex-col px-3 gap-y-6' }>
      <Senler
        data={ data }
        updateField={ updateField }
        errors={ errors }
      />

      <ThModerator
        data={ data }
        updateField={ updateField }
        errors={ errors }
      />
      <Revvy
        data={ data }
        updateField={ updateField }
        errors={ errors }
      >
      </Revvy>
    </div>
  )
}
