import React from "react";
import applyIcon from "../../../images/apply.svg"

export default function SuccessRegister({show}) {

  return (
    <div className={`w-full flex items-center justify-center min-h-screen ${show ? '' : 'hidden'}`}>
      <div className={'flex flex-col items-center w-4/12 p-6'}>
        <img src={applyIcon} alt="" className={'w-[45px] h-[35px] mb-8'}/>
        <span className={'text-gray-40 text-2xl mb-6'}>Регистрация прошла успешно!</span>
        <span className={'text-gray-40 text-lg text-center'}>На указанный адрес электронной почты было направлено письмо для подтверждения регистрации</span>
      </div>
    </div>
  )
}
