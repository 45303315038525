import React, {useEffect, useRef, useState} from "react"
import {MAX_FILE_SIZE} from "../../../../constants/files";
import BaseButton from "../../Button/BaseButton";
import closeImg from '../../../../images/close.svg'
import Tooltip from "../../Tooltip";
import {ReactCropperElement} from "react-cropper";
import {loadAndProcessImage} from "./CropAndResizeImage";

interface IImageMultipleLoadProps {
  className?: string
  classNameImg?: string
  classNameImgWrapper?: string
  classPlaceholderBox?: string
  onInput?: Function
  name: string
  errors: any
  label?: string
  placeholder?: string
  defaultValue?: any
  onRemove?: Function
  required?: boolean
  textTooltip?: string
  forceAspectRatio?: any
  resizeTo?: Array<number>|any
  allowUpscale?: any
  onChange?: Function
  onDelete?: Function
  value?: any
  max?: number,
  isReview?: boolean
}

// TODO: когда-нибудь сюда нужен будет drag&drop для перестановки файлов
export default function ImageLoadMultiple({
  className='',
  classNameImg = '',
  classNameImgWrapper = '',
  onChange = () => {},
  onDelete,
  value=[],
  label='Изображения',
  placeholder='Images',
  max=0,
  name,
  errors = {},
  required = false,
  textTooltip = '',
  forceAspectRatio = null,
  resizeTo = null,
  allowUpscale = false,
}: IImageMultipleLoadProps) {
  const input = useRef<any>(null);
  const [incorrectFilesError, setIncorrectFilesError] = useState('');
  const error = errors[name];
  const [sorted, setSorted] = useState(false)

  useEffect(() => {
    !value && onChange([]);
    if (value && !sorted) {
      value.sort((a: any, b: any) => a.position - b.position)
      setSorted(true)
    }
  }, [value, sorted]);

  const handleInput = ({target}: any) => {
    let targetFiles: any = [];
    let newFiles: any = []
    const reasons: any = [];

    setIncorrectFilesError('');
    delete errors[name];

    const bigFiles: any = [];
    const imagesToUpload = [...target.files].filter(file => {
      if (MAX_FILE_SIZE < file.size) {
        bigFiles.push(file.name);
      }
      return MAX_FILE_SIZE >= file.size;
    });

    if (0 < bigFiles.length) {
      setIncorrectFilesError(`Не все файлы были добавлены: ${new Intl.ListFormat('ru').format(bigFiles)} слишком большой размер. Максимальный размер файла: 5Mb`)
    }

    Promise.allSettled(imagesToUpload.map(getImageUrl)).then(results => {
      results.map((result) => {
        const {value : val, reason}: any = result
        if (reason === 'cancel') {
          return
        }

        if (val && val.url) {
          const {url : link, file} = val;
          let position = value?.length > 0 ? value.at(-1).position + 1 + newFiles.length : 0;
          targetFiles.push(file);
          newFiles.push({__link: link, position, file});
        }
        reason && reason !== 'success' && reasons.push(reason);
      });

      onChange([...value, ...newFiles])

      0 < reasons.length && (setIncorrectFilesError(value => `${0 >= value.length && 'Не все выбранные файлы были добавлены: ' || ', '} ${new Intl.ListFormat('ru').format(reasons)} не корректный формат. Допустимые форматы - JPG, JPEG, PNG`))
    }).then(() => {
      if (input.current)
        input.current.value = null
    });
  }

  const cropperRef = useRef<ReactCropperElement>(null)
  function getImageUrl(file: any) {
    return loadAndProcessImage(file, {
      cropperRef,
      forceAspectRatio,
      resizeTo,
      allowUpscale
    });
  }

  const remove = (index: any) => {
    onDelete && onDelete(value[index]);

    onChange(value.filter((_: any, i: any) => i !== index))

    setIncorrectFilesError('');
    delete errors[name]
  }

  const handleClick = () => {
    input.current.click();
  }

  return(
    <div className={className}>
      <div className="mb-[22px] flex items-center justify-between">
        {
          label &&
          <label className="flex items-center mb-2 font-medium text-sm">
            { label }
              <span className='text-error-main'>{required && '*'}</span>
            {
              textTooltip && <Tooltip textTooltip={textTooltip} defaultText={name}/>
            }
          </label>
        }
        {
          (value?.length < max || !max) &&
          <BaseButton className="border-btn-secondary text-interactive-text rounded-md border-[1px] py-[7px]" onClick={handleClick}>Загрузить</BaseButton>
        }
      </div>

      {value?.length > 0 ?
        <>
          <div className="flex flex-wrap items-center gap-2">
            {value.map((item: any, index: any) => (
              <div key={item?.file ? `${item.__link}_${index}` : `${item.link}_${index}`} className=''>
                <div className={`w-[88px] relative rounded-lg ${classNameImgWrapper}`}>
                  <img src={closeImg} className="absolute top-0 right-0 cursor-pointer" alt="" onClick={() => {remove(index)}} />

                  <img src={item?.file ? item.__link : item.link} className={`h-full w-auto rounded-lg object-contain ${classNameImg}`} alt={'uploaded-image'} />
                </div>
              </div>
            ))}
          </div>
        </> :
        <>
          <div onClick={handleClick} className={`flex items-center justify-center text-[10px] text-gray-40 w-full h-[76px] border-[1.5px] border-btn-secondary rounded-xl cursor-pointer`}>
            { placeholder }
          </div>
        </>
      }

      <input className="invisible absolute w-0" ref={input} onChange={handleInput} type="file" name="images" accept="image/*" multiple={true} />
      {error && (<span className={'text-error-font text-xs font-medium'}>{error}</span>)}
      {incorrectFilesError && (<span className={'text-red-cross text-xs font-medium'}>{incorrectFilesError}</span>)}
    </div>
  )
}
