import React, {useEffect, useState} from "react";
import BaseButton from "../../components/common/Button/BaseButton";
import {createColumnHelper} from "@tanstack/react-table";
import DataTable from "../../components/common/Table/DataTable";
import TextInput from "../../components/common/form/input/TextInput";
import {useBranchesContext} from "../../contexts/Branches";
import SingleSimpleValueSelect from "../../components/common/form/select/SingleSimpleValueSelect";
import RangeDatePicker from "../../components/common/form/datetime/RangeDatePicker";
import {apiClient} from "../../libs/api/apiClient";
import {preparePaginationQueryParams} from "../../contexts/common";
import {useCompaniesContext} from "../../contexts/Companies";
import {showErrorToast} from "../../libs/helpers/toasts";

export default function ReportPromoCode() {
  const {company} = useCompaniesContext()
  const {branches, branch: curBranch} = useBranchesContext()
  const [brach, setBranch] = useState(null)
  const [search, setSearch] = useState(null)
  const [orders, setOrders] = useState<any>(null)
  const [dateFilter, setDateFilter] = useState([null, null])
  const [report, setReport] = useState<any>([])
  const [promoCode, setPromoCode] = useState<any>(null)
  const [filters, setFilters] = useState<any>({})

  const columnHelper = createColumnHelper<any>()

  const submitFilter = () => {
    const filter = {
      filter: {
        createdAt: {
          after: dateFilter[0] || null,
          before: dateFilter[1] || null,
        },
        branches: brach ? [brach] : null
      }
    }
    setFilters(filter)
    getPromoCodeStats()
  }

  useEffect(() => {
    getPromoCodeStats()
  }, [orders])

  const getPromoCodeStats = () => {
    if (company && curBranch && search) {
      apiClient.reports.promoCode(company.id, curBranch.id, search, preparePaginationQueryParams({ ...filters, order: orders || null }))
        .then(({promoCode, report, message, errors}: any) => {
          if (message || errors) {
            showErrorToast({
              content: message || 'Ошибка при загрузке статистики по промокоду'
            })
          } else {
            setReport(report || {})
            setPromoCode(promoCode)
          }
        }).catch((e) => {
          showErrorToast({
            content: 'Ошибка при загрузке статистики по промокоду'
          })
        })
    }
  }

  useEffect(() => {
    getPromoCodeStats()
  }, [company, curBranch])

  const columns = [
    columnHelper.accessor(row => row.date, {
      id: 'date',
      header: () =>
        <div className={"flex flex-col relative ml-4"}>
          <p className={"absolute my-3 bottom-7 left-0"}>Клиенты</p>
          <p>Дата</p>
        </div>,
      cell: info => <div
        className={'w-[120px] ml-4 font-normal break-words flex justify-start items-center '}>{info.getValue() || 'Все дни'}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[120px] flex justify-start text-center',
        sortField: 'date'
      }
    }),
    columnHelper.accessor(row => row.ordersCount, {
      id: 'ordersCount',
      header: () => 'Кол-во',
      cell: info => <div
        className={'w-[120px] font-normal break-words flex justify-start items-center '}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[120px] flex justify-start text-center ',
        sortField: 'ordersCount'
      }
    }),
    columnHelper.accessor(row => row.totalAmount, {
      id: 'totalAmount',
      header: () => 'Сумма',
      cell: info => <div
        className={'w-[120px] font-normal break-words flex justify-start items-center '}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[120px] flex justify-start text-center ',
        sortField: 'totalAmount'
      }
    }),
    columnHelper.accessor(row => row.avgCostPrice, {
      id: 'avgCostPrice',
      header: () => 'Себест.',
      cell: info => <div
        className={'w-[120px] font-normal break-words flex justify-start items-center '}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[120px] flex justify-start text-center ',
        sortField: 'avgCostPrice'
      }
    }),
    columnHelper.accessor(row => row.avgTotal, {
      id: 'avgTotal',
      header: () => 'Ср. чек',
      cell: info => <div
        className={'w-[120px] font-normal break-words flex justify-start items-center '}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[120px] flex justify-start text-center ',
        sortField: 'avgTotal'
      }
    }),
    columnHelper.accessor(row => row.newOrdersCount, {
      id: 'newOrdersCount',
      header: () =>
        <div className={"flex flex-col relative ml-4"}>
          <p className={"absolute w-max my-3 bottom-7 left-0"}>Новые клиенты</p>
          <p>Кол-во</p>
        </div>,
      cell: info => <div
        className={'w-[120px] font-normal break-words flex justify-start items-center '}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[120px] flex justify-start text-center ',
        sortField: 'newOrdersCount'
      }
    }),
    columnHelper.accessor(row => row.newAvgTotal, {
      id: 'newAvgTotal',
      header: () => 'Сумма',
      cell: info => <div
        className={'w-[120px] font-normal break-words flex justify-start items-center '}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[120px] flex justify-start text-center ',
        sortField: 'newAvgTotal'
      }
    }),
    columnHelper.accessor(row => row.newOrdersAmount, {
      id: 'newOrdersAmount',
      header: () => 'Ср. чек',
      cell: info => <div
        className={'w-[120px] font-normal break-words flex justify-start items-center '}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[120px] flex justify-start text-center ',
        sortField: 'newOrdersAmount'
      }
    }),
    columnHelper.accessor(row => row.newAvgCostPrice, {
      id: 'newAvgCostPrice',
      header: () => 'Себест.',
      cell: info => <div
        className={'w-[120px] font-normal break-words flex justify-start items-center '}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[120px] flex justify-start text-center ',
        sortField: 'newAvgCostPrice'
      }
    }),
  ]

  if (!curBranch) {
    return <div>
      <div className="px-8 pb-8 pt-2">
        <div className="flex flex-col justify-between">
          <div>
            <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Отчеты</div>
            <div className="text-gray-40 text-base">Статистика по промокоду</div>
          </div>
          <div className="text-gray-40 text-3xl text-center mt-[35vh]">
            Выберите филиал
          </div>
        </div>
      </div>
    </div>
  }

  return (
    <div className="px-8 pb-8 pt-2">
      <div className="flex flex-col gap-y-6">
        <div className="flex justify-between items-end">
          <div>
            <p className="text-gray-50 text-[44px] leading-[60px] mb-2">Отчеты</p>
            <p className="text-gray-40 text-base">Статистика по промокоду</p>
          </div>
        </div>
        <div className={"w-full flex justify-between items-center gap-x-4"}>
          <div className={"w-2/3 flex flex-row gap-x-4 gap-y-4 "}>
            <SingleSimpleValueSelect
              options={branches.map((branch: any) => ({label: branch.title, value: branch.id}))}
              value={brach}
              onChange={(value: any) => setBranch(value)}
              placeholder={'Филиал 1'}
              className={"w-full"}
            />
            <div className={"w-full"}>
              <RangeDatePicker
                required={false}
                values={dateFilter}
                placeholder='Выберите промежуток дат'
                onChange={([startDate, endDate]: any) => {
                  setDateFilter([startDate, endDate])
                }}
                errors={{}}
                name={'date'}
                label={undefined}
                textTooltip={undefined}
              />
            </div>
            <TextInput
              placeholder='Введите промокоды'
              value={search || ''}
              onChange={(value: any) => setSearch(value)}
              className={"w-full"}
              errors={{}}
              name={''}
            />
          </div>
          <div className={"w-1/3 flex justify-end"}>
            <BaseButton onClick={() => submitFilter()} className={"ml-5 bg-interactive-elem text-white"}>Показать результат</BaseButton>
          </div>
        </div>
        <div className={"bg-white rounded-xl p-3"}>
          {
            promoCode && <div className={"border-interactive-elem border-[1px] p-4 rounded-xl"}>
              <p className={"font-medium my-1"}>{promoCode?.title}</p>
              <p className={"text-gray-40"}>
                {promoCode?.shortDescription}
              </p>
            </div>
          }

          {report?.length > 0 ?
            <DataTable
              data={report || []}
              columns={columns}
              usePagination={false}
              defaultLimit={5}
              defaultPage={1}
              defaultOrder={{date: 'desc'}}
              //pagination={pagination}
              updateData={(params: any) => setOrders(params?.order || null)}
              //loading={loading}
              classNameTable={'mt-8'}
              heightHeaderRow={'h-24'}
            /> : <div className={"text-gray-40 text-3xl text-center m-[15vh]"}>Статистики по промокоду нет</div>
          }
        </div>
      </div>
    </div>
  )

}
