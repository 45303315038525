import React, {useEffect, useState} from "react";
import {useBranchesContext} from "../../contexts/Branches";
import SingleSimpleValueSelect from "../../components/common/form/select/SingleSimpleValueSelect";
import Checkbox from "../../components/common/form/checkbox/Checkbox";
import TextInput from "../../components/common/form/input/TextInput";
import BaseButton from "../../components/common/Button/BaseButton";
import {TagsMultipleSelect} from "../../components/common/form/select/datatable/tags/TagsMultipleSelect";
import TagLabel from "../../components/common/form/select/datatable/tags/TagLabel";
import {createColumnHelper} from "@tanstack/react-table";
import {useCustomersTagsContext, useOrdersTagsContext} from "../../contexts/Tags";
import RangeDatePicker from "../../components/common/form/datetime/RangeDatePicker";
import fetchPlatforms from "../../providers/dictionaries/platforms";
import {fetchDeliveryTypes} from "../../providers/dictionaries/promoCodes";

export default function Base({data, updateField, updateFilters, updateReports, limits, filters, errors}: any) {
  const {branches} = useBranchesContext()
  const {customersTags, loadingCustomers}: any = useCustomersTagsContext()
  const {ordersTags, loadingOrders}: any = useOrdersTagsContext()
  const [deliveryTypes, setDeliveryTypes] = useState([])
  const [platforms, setPlatforms] = useState([])
  const columnHelper = createColumnHelper<any>()

  const columnsTags = [
    columnHelper.accessor(row => row.code, {
      id: 'code',
      header: () => 'Код',
      cell: info => <div className={'w-[120px] flex items-center justify-center text-center mx-2'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[120px] flex items-center justify-center text-center mx-2'
      }
    }),
    columnHelper.accessor(row => row.text, {
      id: 'title',
      header: () => 'Метки (Теги)',
      cell: info =>
        <TagLabel
          textColor={info.row?.original?.color}
          backgroundColor={info.row?.original?.background}>
          {info.getValue()}
        </TagLabel>,
      enableSorting: true,
      meta: {
        widthClass: 'min-w-[80px] flex items-center justify-center text-center',
      }
    }),
  ]

  useEffect(() => {
    fetchPlatforms().then((data) => {
      setPlatforms(data)
    }).catch(() => {})
    fetchDeliveryTypes().then((data) => {
      setDeliveryTypes(data)
    }).catch(() => {})
  }, [])

  return (
    <div className={"flex flex-col gap-y-6"}>

      <div className={"w-full flex flex-row items-center gap-x-4"}>
        <div className={"w-1/2 flex flex-row gap-x-4"}>
          <SingleSimpleValueSelect
            options={[
              {
                label: 'Все филиалы',
                value: null
              },
              ...branches.map((branch: { title: any; id: any; }) => ({label: branch.title, value: branch.id}))
            ]}
            value={data.branches || null}
            onChange={(value: any) => {
              updateField('branches', value)
              //updateFilters('filter.branches', value)
            }}
            name={'branches'}
            placeholder={'Филиал 1'}
            errors={errors}
            removeValue={() => {
              //updateFilters('filter.branches', null)
              updateField('branches', null)
            }}
            removeItem={true}
          />
          <SingleSimpleValueSelect
            name={'deliveryType'}
            placeholder={'Доставка'}
            value={data.deliveryType}
            options={[
              {
                label: 'Все типы получения',
                value: null
              }, ...deliveryTypes
            ]}
            onChange={(value: any) => {
              updateField('deliveryType', value)
              //updateFilters('filter.deliveryType', value)
            }}
            removeValue={() => {
              //updateFilters('filter.deliveryType', null)
              updateField('deliveryType', null)
            }}
            removeItem={true}
          />
          <SingleSimpleValueSelect
            name={'source'}
            placeholder={'Все платформы'}
            value={data.source}
            options={platforms}
            onChange={(value: any) => {
              updateField('source', value)
              //updateFilters('filter.source', value)
            }}
            removeValue={() => {
              //updateFilters('filter.source', null)
              updateField('source', [])
            }}
            removeItem={true}
          />
        </div>
        <div className={"w-1/2 flex flex-row gap-x-4"}>
          <Checkbox
            label={'Есть подарок'}
            name={'hasGift'}
            onChange={(value: any) => {
              updateField('hasGift', value)
              //updateFilters('filter.hasGift', value)
            }}
            value={data.hasGift}
            errors={errors}
          />
          <Checkbox
            label={'Есть скидка'}
            name={'hasDiscount'}
            onChange={(value: any) => {
              updateField('hasDiscount', value)
              //updateFilters('filter.hasDiscount', value)
            }}
            value={data.hasDiscount}
            errors={errors}
          />
        </div>
      </div>

      <div className={"w-full flex flex-row items-center gap-x-4"}>
        <div className={"w-1/2"}>
          <TagsMultipleSelect
            data={ordersTags?.map(({title, ...o}: any) => ({text: title, ...o})) || []}
            values={data.orderTags}
            errors={errors}
            name={'orderTags'}
            label={'Метки заказов'}
            placeholder={'Добавьте метки'}
            buttonPlaceholder={'Добавить'}
            loading={loadingOrders}
            updateValues={(values: any) => {
              updateField('orderTags', values)
              //updateFilters('filter.orderTags', values)
            }}
            updateData={() => {
              console.log('update')
            }}
            columns={columnsTags}
            innerPadding={'py-[5px] px-2 rounded-xl'}
            removeItem={(index: any) => {
              let options = data.orderTags.slice()
              options.splice(index, 1)
              updateField('orderTags', options)
              //updateFilters('filter.orderTags', options)
            }}
          />
        </div>
        <div className={"w-1/2"}>
          <TagsMultipleSelect
            data={customersTags?.map(({title, ...o}: any) => ({text: title, ...o})) || []}
            values={data.customerTags}
            errors={errors}
            name={'tagsCustomers'}
            label={'Метки клиентов'}
            placeholder={'Добавьте метки'}
            buttonPlaceholder={'Добавить'}
            loading={loadingCustomers}
            updateValues={(values: any) => {
              updateField('customerTags', values)
              //updateFilters('filter.customerTags', values)
            }}
            updateData={() => {
              console.log('update')
            }}
            columns={columnsTags}
            innerPadding={'py-[5px] px-2 rounded-xl'}
            removeItem={(index: any) => {
              let options = data.customerTags.slice()
              options.splice(index, 1)
              updateField('customerTags', options)
              //updateFilters('filter.customerTags', options)
            }}
          />
        </div>

      </div>

      <div className={"w-full flex flex-row items-center gap-x-4"}>
        <TextInput
          placeholder='utm_source'
          name={'utm-source'}
          value={data['utm-source']}
          onChange={(value: any) => {
            updateField('utm-source', value)
          }}
          className={"w-full"}
          errors={{}}
        />
        <TextInput
          placeholder='utm_medium'
          name={'utm-medium'}
          value={data['utm-medium']}
          onChange={(value: any) => {
            updateField('utm-medium', value)
          }}
          className={"w-full"}
          errors={{}}
        />
        <TextInput
          placeholder='utm-campaign'
          name={'utm_campaign'}
          value={data['utm-campaign']}
          onChange={(value: any) => {
            updateField('utm-campaign', value)
          }}
          className={"w-full"}
          errors={{}}
        />
        <TextInput
          placeholder='utm_content'
          name={'utm-content'}
          value={data['utm-content']}
          onChange={(value: any) => {
            updateField('utm-content', value)
          }}
          className={"w-full"}
          errors={{}}
        />
        <TextInput
          placeholder='utm_term'
          name={'utm-term'}
          value={data['utm-term']}
          onChange={(value: any) => {
            updateField('utm-term', value)
          }}
          className={"w-full"}
          errors={{}}
        />
      </div>

      <div className={"w-full flex flex-row items-center justify-between"}>
        <RangeDatePicker
          required={false}
          values={data.__date || [null, null]}
          placeholder='Выберите промежуток дат'
          onChange={([startAt, endAt]: any) => {
            updateField('__date', [startAt, endAt]);
            updateField('createdAt', { before: endAt || null, after: startAt || null })
            //updateFilters('filter.createdAt.from', startAt || null)
          }}
          errors={{}}
          name={'date'}
          label={undefined}
          textTooltip={undefined}
        />
        <BaseButton className={"ml-5 bg-interactive-elem text-white"} onClick={() => {updateReports(limits, filters)}}>Показать результат</BaseButton>
      </div>

    </div>
  );
}
