import React, { useState } from 'react'
import TextInput from './../../../components/common/form/input/TextInput';
import ChevronIcon from '../../../components/icons/ChevronIcon';

export default function Revvy({data, updateField, errors}: any) {
  const [show, setShow] = useState(true)
  const [initialPassword, setInitialPassword] = useState(data.integrations.revvy?.password)

  const updateRevvy = (name: any, value: any) => {
    updateField(`integrations.revvy.${name}`, value)

    // Костыль. Объект revvy обновляется полностью, кроме пароля
    const update = { ...data.integrations.revvy }
    if (update.password === initialPassword) {
      delete update.password
    }

    updateField(`integrations.revvy`, update)
  }


  return (
    <div className={`${show ? 'h-auto' : 'h-[65px] overflow-hidden'}  border-[1px] border-gray-20 rounded-lg`}>
      <div onClick={() => setShow(!show)} className='flex items-center justify-between cursor-pointer p-4'>
        <div className='text-[22px] font-semibold'>Отправка сообщений в What's App</div>
        <ChevronIcon className={`${show && 'rotate-180'}`}/>
      </div>

      <div className='p-4'>
        <div className='grid grid-cols-2 gap-4 mb-4 items-end'>
          <TextInput
            label='Email'
            textTooltip='Email'
            errors={errors}
            placeholder='example@email.ru'
            name={'integrations.revvy.email'}
            value={data.integrations.revvy?.email}
            onChange={(value: any) => updateRevvy('email', value)}
          />

          <TextInput
            label='Пароль'
            textTooltip='Пароль'
            errors={errors}
            placeholder='СлоЖныЙ ПаРОлЬ'
            name={'integrations.revvy.password'}
            value={initialPassword}
            onChange={(value: any) => updateRevvy('password', value)}
            initialValueReplaceOnly={true}
          />

          <TextInput
            label='Филиал'
            textTooltip='Филиал'
            errors={errors}
            placeholder='Филиал'
            name={'integrations.revvy.placeId'}
            value={data.integrations.revvy?.placeId}
            onChange={(value: any) => updateRevvy('placeId', value)}
          />
        </div>
      </div>
    </div>
  )
}
