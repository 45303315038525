const tabs = [
  {
    label: 'Все статусы',
    tag: 'statuses',
    fields: [
    ]
  },
  {
    label: 'Все правила',
    tag: 'rules',
    fields: [
    ]
  },
  {
    label: 'Все транзакции',
    tag: 'transactions',
    fields: [
    ]
  },
]

export default tabs
