import {apiClient} from "../../libs/api/apiClient";

export async function fetchRequiredOrderStatuses() {
  const { data }: any = await apiClient.dictionaries.requiredOrderStatuses()

  return data.map((status: any) => ({
    label: status.title,
    code: status.code,
    value: status.value
  }))
}
