import React, { useEffect } from 'react'
import Checkbox from '../../../components/common/form/checkbox/Checkbox'
import TextInput from '../../../components/common/form/input/TextInput'
import SingleSimpleSelect from '../../../components/common/form/select/SingleSimpleSelect'
import SimpleMultipleSelect from '../../../components/common/form/select/multiple/SimpleMultipleSelect'
import { useBranchesContext } from '../../../contexts/Branches'
import { useCategoriesContext } from '../../../contexts/Categories'
import {useParams} from "react-router-dom";

export default function Base({ data, updateField, updateFieldNoRender, errors, }: any) {
  const { branches } = useBranchesContext()
  const { categories } = useCategoriesContext()
  const {id: categoryId} = useParams<{id?: string}>()

  useEffect(() => {
    // Это нужно чтобы установить выбранный элемент в селекторе
    if (categories.length && data?.parentCategoryId && !data?.__parentCategoryId_select) {
      let category = categories.map((category: any) => ({ label: category.title, value: category.id })).filter(((item: any) => item.value == data?.parentCategoryId)).at(0)
      if (category) {
        updateField('__parentCategoryId_select', category)
      } else {
        updateField('__parentCategoryId_select', { label: '', value: null })
      }
    }
    if (branches.length && data?.branches?.length && !data?.__branches_select?.length) {

      updateField('__branches_select', branches.map(({title, id}: any) => ({ label: title, value: id })).filter(((item: any) => data?.branches.includes(item.value))))
    }
  }, [data])

  return (
    <div className={'flex flex-col px-3 gap-y-6'}>
          <Checkbox
            label='Активность раздела'
            name={'isActive'}
            value={data.isActive}
            onChange={(value: any) => updateField('isActive', value)}
            errors={errors}
          />

          <TextInput
            label='Название раздела'
            textTooltip='Название раздела'
            placeholder='Введите название раздела'
            name={'title'}
            value={data.title}
            onChange={(value: any) => updateFieldNoRender('title', value)}
            errors={errors}
            required={true}
          />

          <SingleSimpleSelect
            label='Родительский раздел'
            textTooltip='Родительский раздел'
            placeholder='Корень каталога'
            name={'parentCategoryId'}
            value={data.__parentCategoryId_select}
            onChange={(option: any) => {
              updateField('__parentCategoryId_select', option)
              updateField('parentCategoryId', option.value)
            }}
            options={categories.filter(({id}: any) => id != categoryId).map((category: any) => ({ label: category.title, value: category.id }))}
            errors={errors}
          />

          <TextInput
            type='number'
            label='Сортировка'
            textTooltip='Сортировка'
            placeholder='Введите число'
            name={'position'}
            value={data.position}
            onChange={(value: any) => updateFieldNoRender('position', value)}
            errors={errors}
          />

          <TextInput
            label='Описание'
            textTooltip='Описание'
            placeholder='Введите подробное описание'
            name={'description'}
            value={data.description}
            onChange={(value: any) => updateFieldNoRender('description', value)}
            errors={errors}
          />

          <SimpleMultipleSelect
            label={'Филиалы'}
            textTooltip={'Филиалы'}
            options={branches.map((branch: any) => ({ label: branch.title, value: branch.id }))}
            value={data.__branches_select || []}
            onChange={(value: any) => {
              updateField('__branches_select', value)
              updateField('branches', value === '*' ? value : value.map((item: any) => item.value))
            }}
            name={'branches'}
            placeholder={'Выберите филиалы'}
            errors={errors}
          />
    </div>
  )
}
