import {apiClient} from "../libs/api/apiClient";
import {useCompaniesContext} from "./Companies";
import {useBranchesContext} from "./Branches";
import {MakeContext} from "./common";

const [HistoryContextWrapper, useHistoryContext] = MakeContext({
  useEverything: () => {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    return {
      isReady: () => (company && branch),
      fetchAll: (queryParams: any) => apiClient.histories.getAll(company.id, branch.id, queryParams),
      updateDeps: [company?.id, branch?.id],
      startLimit: 10
    }
  },
  makeValue: (histories: any, updateHistories: any, updateFilters: any) => ({
    histories, updateHistories, updateFilters
  })
})

export {HistoryContextWrapper, useHistoryContext};

const [HistoryActiveContextWrapper, useHistoryActiveContext] = MakeContext({
  useEverything: () => {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    return {
      isReady: () => (company && branch),
      fetchAll: (queryParams: any) => apiClient.histories.getAllActive(company.id, branch.id, queryParams),
      updateDeps: [company?.id, branch?.id],
      startLimit: 10
    }
  },
  makeValue: (histories: any, updateHistories: any, updateFilters: any) => ({
    histories, updateHistories, updateFilters
  })
})

export {HistoryActiveContextWrapper, useHistoryActiveContext};

const [HistoryArchiveContextWrapper, useHistoryArchiveContext] = MakeContext({
  useEverything: () => {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    return {
      isReady: () => (company && branch),
      fetchAll: (queryParams: any) => apiClient.histories.getAllArchive(company.id, branch.id, queryParams),
      updateDeps: [company?.id, branch?.id],
      startLimit: 10
    }
  },
  makeValue: (histories: any, updateHistories: any, updateFilters: any) => ({
    histories, updateHistories, updateFilters
  })
})

export {HistoryArchiveContextWrapper, useHistoryArchiveContext};
