import {apiClient} from "../../libs/api/apiClient";
import {showErrorToast} from "../../libs/helpers/toasts";


enum ActionEnum {
  detach = 'detach',
  attach = 'attach',
}

enum Tabs {
  allClients= 'allClients',

}

export const submitAction = (callback: any, action: string, tab: string, companyId: any, branchId: any, data: any) => {
  switch (tab) {
    case Tabs.allClients:
      switch (action) {
        case ActionEnum.attach:
          bulkAllClientsAttach(callback, companyId, branchId, data)
          break
        case ActionEnum.detach:
          bulkAllClientsDetach(callback, companyId, branchId, data)
          break
        default:
          break
      }
      break
    default:
      break
  }
}

//-------------------------------------------ATTACH----------

 const bulkAllClientsAttach = (callback: any, companyId: any, branchId: any, data: any) => {
   apiClient.customers.bulkAttach(companyId, branchId, {body: data})
     .then(({data, errors, message}: any) => {
       if (errors || message) {
         showErrorToast({
           content: 'Что-то пошло не так'
         })
       } else {
         callback()
       }
     })
     .catch(() => {
       showErrorToast({
         content: 'Что-то пошло не так'
       })
     })
 }


//-------------------------------------------DETACH----------

const bulkAllClientsDetach = (callback: any, companyId: any, branchId: any, data: any) => {
  apiClient.customers.bulkDetach(companyId, branchId, {body: data})
    .then(({data, errors, message}: any) => {
      if (errors || message) {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
       } else {
         callback()
       }
     })
     .catch(() => {
       showErrorToast({
         content: 'Что-то пошло не так'
       })
     })
 }
