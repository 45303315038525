import * as React from "react";
import { HTMLAttributes } from "react";

type Props = React.PropsWithChildren & HTMLAttributes<HTMLDivElement> & {
  error?: string,
}

type LabelProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  required?: boolean
}

function Label(props: LabelProps) {
  const { children, required = false, ...other } = props;

  return (
    <div className={ 'flex items-center mb-2 font-medium text-sm' } { ...other } >
      { children }
      {
        required && (<span className='text-error-main'>*</span>)
      }
    </div>
  )
}

function After(props: React.PropsWithChildren) {
  return <>{ props.children }</>
}

function Before(props: React.PropsWithChildren) {
  return <>{ props.children }</>
}


export default function InputWrapper(props: Props) {
  const {
    error,
    children,
    ...other
  } = props;

  let label: React.ReactNode | undefined;
  let before: React.ReactNode[] = [];
  let after: React.ReactNode[] = [];
  let content: React.ReactNode[] = [];

  React.Children.forEach(children, (child) => {
    if (! React.isValidElement(child)) {
      return;
    }

    if (child.type === Label) {
      label = child;
    } else if (child.type === Before) {
      before.push(child);
    } else if (child.type === After) {
      after.push(child);
    } else {
      content.push(child);
    }
  });

  return (
    <>
      { !! label && (<>{ label }</>) }
      <div
        className={ `flex gap-8 outline-0 disabled:text-gray-40 text-gray-50 py-[10px] px-[14px] w-full text-sm bg-white border-gray-20 border-[1px] rounded-lg ${ (error && 'border border-red-500 disabled:border disabled:border-red-500') || '' } ` }
        { ...other }
      >
        { !! before && (<>{ before }</>) }
        { content }
        { !! after && (<>{ after }</>) }
      </div>
      { error && (<span className={ 'text-error-font text-xs font-medium' }>{ error }</span>) }
    </>
  )
}

InputWrapper.Label = Label;
InputWrapper.After = After;
InputWrapper.Before = Before;
