import React, {useEffect, useId, useRef, useState} from "react";
import DropDown from "../DropDown";
import TextInput from "../../input/TextInput";
import {Location} from "../../../../../types/location";
import {apiClient} from "../../../../../libs/api/apiClient";
import { useCompaniesContext } from "../../../../../contexts/Companies";

interface PropsType {
  value?: Location;
  onChange: (value: Location | null) => void;
  name: string;
  classNameInput?: string;
  placeholder?: string;
  label?: string;
  errors?: object;
  errorLabel?: boolean;
}

export default function CityLocation({
  value,
  onChange,
  name,
  classNameInput = '',
  placeholder='',
  label='',
  errors = {},
  errorLabel = true,
  ...props
}: PropsType) {
  const id = useId();
  const {company} = useCompaniesContext()
  const wrapper = useRef<any>(null);
  const [options, setOptions] = useState<Location[]>([]);
  const [show, setShow] = useState(false);
  const [inputValue, setInputValue] = useState<any>('');
  const [loading, setLoading] = useState(false);

  const error = errors[name];

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!inputValue || inputValue === value?.title || !inputValue?.length) {
        return;
      }

      setLoading(true);
      apiClient.suggestions.city(inputValue, company?.id)
         .then(({data}: any) => setOptions(data))
         .catch(error => console.log(error))
         .finally(() => setLoading(false));
    }, 500);

    return () => clearTimeout(timeout)
  }, [value, inputValue]);

  useEffect(() => {
    setInputValue(value?.title)

    if (!value || !value?.value || 0 === value?.value.length) {
      setOptions([]);
    }
  }, [value]);

  useEffect(() => {
    options?.length && setShow(true)
    !options?.length && setShow(false)
  }, [options]);

  /* Закрываем при клике вне поля */
  useEffect(() => {
    function handleClick(event: any) {
      if (wrapper.current && !wrapper.current.contains(event.target)) {
        setShow(false);
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }

  }, [show, setShow, wrapper]);

  function handleKeyDown(event: any) {
    if (8 === event.keyCode) {
      setOptions([]);
      onChange(null);
    }
  }

  return (
    <div ref={wrapper} className={'relative w-full'}>
      <TextInput label={label} id={id} classNameInput={classNameInput} autoComplete={'off'} onChange={(value) => setInputValue(value)} value={inputValue} name={name} placeholder={placeholder} {...props} onClick={() => setShow(show => options.length && !show || false)} onKeyDown={handleKeyDown}/>
      <DropDown type="location" items={options} value={value} setShow={setShow} show={show} onChange={(option) => {
        setShow(false);
        onChange(option);
        delete errors[name];
        delete errors[`${name}.kladrId`];
        delete errors[`${name}.guid`];
        delete errors[`${name}.title`];
        setInputValue(option.title)
      }}/>
      {error && errorLabel && (<span className={'text-error-font text-xs font-medium'}>{error}</span>)}
    </div>
  )
}
