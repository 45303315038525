import React, {useEffect, useState} from 'react'
import Checkbox from './../../../components/common/form/checkbox/Checkbox';
import TextInput from '../../../components/common/form/input/TextInput';
import {createColumnHelper} from "@tanstack/react-table";
import {TagsMultipleSelect} from "../../../components/common/form/select/datatable/tags/TagsMultipleSelect";
import TextInputsList from "../../../components/common/form/input/TextInputsList";
import {useOptionsGroupContext, useSkuGroupContext} from "../../../contexts/Options";
import TextInputMultipleList from "../../../components/common/form/input/TextInputMultipleList";
import RadioButton from "../../../components/common/form/radio/RadioButton";
import SingleSimpleValueSelect from "../../../components/common/form/select/SingleSimpleValueSelect";
import {SimpleDatatableSelect} from "../../../components/common/form/select/datatable/single/SimpleDataTableSelect";
import {apiClient} from "../../../libs/api/apiClient";
import TagLabel from "../../../components/common/form/select/datatable/tags/TagLabel";
import {useCategoriesContext} from "../../../contexts/Categories";
import {useProductsContext} from "../../../contexts/Products";
import {useParams} from "react-router-dom";
import Tooltip from "../../../components/common/Tooltip";

export default function Marks({data, updateField,updateFieldNoRender, errors, tagsArray, edit = false}: any) {
  const {optionsGroups, updateOptionsGroups, loading: optionGroupLoading}: any = useOptionsGroupContext();
  const {skuGroups, updateSkuGroups, loading: skuGroupLoading}: any = useSkuGroupContext()
  const { categories, loading: categoriesLoading } = useCategoriesContext()
  const { products, filters, updateProducts, updateFilters, loading: productsLoading }: any = useProductsContext()
  const {id: productId} = useParams<{id?: string}>()

  const [allAddToOrder, setAllAddToOrder] = useState([])

  const [skuParentId, setSkuParentId] = useState(data.skuParentId)
  const columnHelper = createColumnHelper<any>()

  const columns = [
    columnHelper.accessor(row => row.id, {
      id: 'ID',
      header: () => 'ID',
      cell: info => <div className={'w-[85px] flex items-center justify-center text-center'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[85px] flex items-center justify-center text-center'
      }
    }),
    columnHelper.accessor(row => row.text, {
      id: 'title',
      header: () => 'Метки (Теги)',
      cell: info =>
        <TagLabel
          img={info.row?.original?.icon}
          textColor={info.row?.original?.color}
          backgroundColor={info.row?.original?.background}>
          {info.getValue()}
        </TagLabel>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[64px] ml-4 flex items-center justify-center text-center'
      }
    }),
  ]

  const goodColumns = [
    columnHelper.accessor(row => row.id, {
      id: 'radio',
      header: () => '',
      cell: info => <RadioButton
        className={'flex items-center justify-center'}
        name={'__skuParentId__select'}
        value={skuParentId === info.getValue()}
        onChange={(value: any) => {
          if (value) {
            setSkuParentId(info.getValue())
          }
        }}
        errors={errors} />,
      enableSorting: false,
      meta: {
        widthClass: 'w-[20px] flex items-center justify-center text-center'
      }
    }),
    columnHelper.accessor(row => row.id, {
      id: 'ID',
      header: () => 'ID',
      cell: info => <div className={'w-[85px] flex items-center justify-center text-center'}>{ info.getValue() }</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[85px] flex items-center justify-center text-center'
      }
    }),
    columnHelper.accessor(row => row.image, {
      id: 'image',
      header: () => 'Фото',
      cell: info =>
        <div className={'mr-4 w-[64px] flex items-center justify-center text-center'}>
          {
            info.getValue() ?
              <img src={info.getValue()} alt="" />:
              <div className='h-[60px] w-[60px] flex items-center justify-center text-[10px] text-gray-40 border-[1.5px] border-btn-secondary rounded-xl'>Image</div>
          }
        </div>,
      enableSorting: false,
      meta: {
        widthClass: 'mr-4 w-[64px] flex items-center justify-center text-center'
      }
    }),
    columnHelper.accessor(row => row.title, {
      id: 'title',
      header: () => 'Название',
      cell: info =>
        <div className={'grow flex items-center'}>
          { info.getValue() }
        </div>,
      enableSorting: true,
      meta: {
        widthClass: 'grow',
        sortField: 'title'
      }
    }),
    columnHelper.accessor(row => row.weight, {
      id: 'weight',
      header: () => 'Вес',
      cell: info => <div className={'max-w-[48px] grow flex items-center justify-center'}>{ info.getValue() }</div>,
      enableSorting: true,
      meta: {
        widthClass: 'max-w-[48px] grow justify-center',
        sortField: 'weight'
      }
    }),
    columnHelper.accessor(row => row.price, {
      id: 'price',
      header: () => 'Цена',
      cell: info => <div className={'max-w-[75px] grow flex items-center justify-center'}>{ info.getValue() }</div>,
      enableSorting: true,
      meta: {
        widthClass: 'max-w-[75px] grow justify-center',
        sortField: 'price'
      }
    }),
  ]

  useEffect(() => {
    apiClient.dictionaries.goodAddToOrder().then(({ data }: any) => {
      setAllAddToOrder(data);
    })
    if (!optionGroupLoading) {
      updateOptionsGroups(null)
      updateSkuGroups(null)
    }
  }, [])

  useEffect(() => {
    if (skuGroups.length) {
      const skuGroups__select = data.skuGroups.map(({skuGroupId, title, position}: any) => {
        let skuGroup = skuGroups.find(({id}: any) => id === skuGroupId)
        return {
          skuGroupId: {label: skuGroup?.innerTitle ? skuGroup?.innerTitle : skuGroup?.title, value: skuGroup?.id},
          title: title,
          position: position || ''
        }
      })
      if (skuGroups__select.length > 0) {
        updateField('__skuGroups__select', skuGroups__select)
      }
    }
    if (optionsGroups.length) {
      const optionsGroups__select = data.optionsGroups.map((optionGroup: any) => {
        let group = optionsGroups.find(({id}: any) => id === optionGroup)
        return {label: group?.innerTitle ? group?.innerTitle : group?.title, value: group?.id}
      })

      if (optionsGroups__select.length > 0) {
        updateField('__optionsGroups__select', optionsGroups__select)
      }
    }
  }, [skuGroups, optionsGroups])

  return (
    !skuGroupLoading && !optionGroupLoading && !categoriesLoading ?
    <>
      <div className='flex flex-col gap-4 rounded-2xl border-[1px] border-gray-20 p-4'>
        <TagsMultipleSelect
          data={tagsArray}
          values={data.tags}
          errors={errors}
          name={'tags'}
          placeholder={'Выберите метки'}
          updateValues={(values: any) => {
            updateField('tags', values)
            if (!values.length) {
              updateField('emptyTags', true)
            }
          }}
          updateData={() => {}}
          columns={columns}
          buttonPlaceholder={'Изменить метки'}
          label={'Метки'}
          textTooltip={'Метки'}
          removeItem={(index: any) => {
            let options = data.tags.slice()
            options.splice(index, 1)
            updateField('tags', options)
          }}
        />
      </div>


      <Checkbox
        label='ТОР товар'
        name={'isTop'}
        value={data.isTop}
        onChange={(value: any) => updateField('isTop', value)}
        errors={errors}
      />

      <Checkbox
        label='Можно купить за 0 руб'
        name={'canBuyForFree'}
        value={data.canBuyForFree}
        onChange={(value: any) => updateField('canBuyForFree', value)}
        errors={errors}
      />

      <SingleSimpleValueSelect
        label={'Добавлять к заказу'}
        textTooltip={'Добавлять к заказу'}
        placeholder={'Не добавлять'}
        name={'addToOrder'}
        value={data.addToOrder}
        onChange={(value: any) => {
          updateField('addToOrder', value)
        }}
        options={[{label: 'Не добавлять', value: null}, ...allAddToOrder.map(({title, value}) => ({label: title, value: value}))] || []}
        errors={errors}
      />

      <TextInput
        type='number'
        min='0'
        label='Минимальное количество (шт.)'
        textTooltip='Минимальное количество (шт.)'
        placeholder='0'
        name={'minCount'}
        value={data.minCount}
        onChange={(value: any) => updateFieldNoRender('minCount', value)}
        errors={errors}
      />

      <TextInput
        type='number'
        min={0}
        max={100}
        label='Максимальная скидка (%)'
        textTooltip='Максимальная скидка (%)'
        placeholder='0'
        name={'maxDiscount'}
        value={data.maxDiscount}
        onChange={(value: any) => updateFieldNoRender('maxDiscount', value)}
        errors={errors}
      />

      <TextInput
        type='number'
        min={0}
        max={100}
        label='Максимальный бонус накопления (%)'
        textTooltip='Максимальный бонус накопления (%)'
        placeholder='0'
        name={'maxBonusAccumulation'}
        value={data.maxBonusAccumulation}
        onChange={(value: any) => updateFieldNoRender('maxBonusAccumulation', value)}
        errors={errors}
      />

      <TextInput
        type='number'
        min={0}
        max={100}
        label='Максимальный бонус оплаты (%)'
        textTooltip='Максимальный бонус оплаты (%)'
        placeholder='0'
        name={'maxBonusPayment'}
        value={data.maxBonusPayment}
        onChange={(value: any) => updateFieldNoRender('maxBonusPayment', value)}
        errors={errors}
      />

      {/* // TODO: SKU для товара */}
      <div className='w-full flex flex-col gap-4 rounded-2xl border-[1px] border-gray-20 p-4'>
        <div className="flex items-center mb-2 font-medium text-lg">
          SKU для товара
          <Tooltip textTooltip={'SKU для товара'} defaultText={'SKU для товара'}/>
        </div>
        <div className={'w-full flex flex-col items-center'}>
          <SimpleDatatableSelect
            className={'w-full mb-4'}
            data={edit ?
              products.filter((product: any) => (!product.skuParentId || product.id != productId)) || [] :
              products.filter((product: any) => !product.skuParentId) || []
            }
            label={'Основной товар'}
            textTooltip={'Основной товар'}
            value={data.skuParentId}
            errors={errors}
            name={'skuParentId'}
            placeholder={'Выбрать товар'}
            removeItem={() => {
              updateField('skuParentId', null)
            }}
            onSubmit={() => updateField('skuParentId', skuParentId)}
            updateData={(params: any) => {
              if ('search' in params) {
                updateFilters('search', params.search)
                delete params.search
              }
              updateProducts(params, filters)
            }}
            columns={goodColumns}
            resetFilters={() => {
              updateFilters('filter.categories', null)
              updateField('__tableCategories', '')
              updateProducts(null, null)
            }}
            loading={productsLoading}
          >
            <SingleSimpleValueSelect
              required={false}
              placeholder={'Выберите раздел'}
              errors={{}}
              name='__tableCategories'
              options={categories.map((category: any) => ({ label: category.title, value: category.id })) || []}
              value={data.__tableCategories || ''}
              onChange={(value: any) => {
                updateFilters('filter.categories', value)
                updateProducts(null, filters)
                updateField('__tableCategories', value)
              }}
              removeValue={() => {
                updateFilters('filter.categories', null)
                updateProducts(null, filters)
                updateField('__tableCategories', '')
              }}
              removeItem={true}
            />
          </SimpleDatatableSelect>

          <TextInputMultipleList
            textTooltips={['Группа SKU', 'Характеристика', 'Сортировка']}
            required={false}
            name={'skuGroups'}
            values={data.skuGroups || []}
            fields={['skuGroupId', 'title', 'position']}
            fieldTypes={['select', 'input', 'input']}
            placeholders = {['Выберитое группу SKU', 'Введите характеристику', '500']}
            onChange={(value: any) => {
              updateField('skuGroups', value)
              if (!value.length) {
                updateField('emptySkuGroups', true)
              }
            }}
            labels={['Группа SKU', 'Характеристика', 'Сортировка']}
            errors={errors}
            options={[skuGroups.map(({title, innerTitle, id}: any) => {return {label: innerTitle ? innerTitle : title, value: id}}), [], []]}
            className={'w-full border-0 p-0'}
            maxLength={2}
          />
        </div>
      </div>

      {/* // TODO: Группы опций */}
      <TextInputsList
        textTooltip='Группы опций'
        label='Группы опций'
        className='w-full'
        placeholder='Выберите группу опций'
        onChange={(value: any) => {
          updateField('__optionsGroups__select', value)
          updateField('optionsGroups', value.map((x: any) => x.value))
          if (!value.length) {
            updateField('emptyOptionsGroups', true)
          }
        }}
        values={data.__optionsGroups__select}
        name={'optionsGroups'}
        errors={errors}
        type={'select'}
        options={optionsGroups.map(({title, innerTitle, id}: any) => {return {label: innerTitle ? innerTitle : title, value: id}})}
      />

    </> :
      <div className={"text-gray-40 text-3xl text-center mt-[35vh]"}>
        <span>Загрузка...</span>
      </div>
  )
}
