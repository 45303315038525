import React, { useEffect, useState } from 'react'
import Checkbox from '../../../components/common/form/checkbox/Checkbox';
import { useCompaniesContext } from '../../../contexts/Companies';
import { apiClient } from "../../../libs/api/apiClient";
import BaseButton from "../../../components/common/Button/BaseButton";
import { showSuccessToast } from "../../../libs/helpers/toasts";
import { dialogModal } from "../../../components/common/Modal/DialogModal";
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SingleSelect, { Option } from "../../../components/v2/form/input/select/SingleSelect";
import Spoiler from "../../../components/v2/Spoiler";
import InputWrapper from "../../../components/v2/form/input/common/InputWrapper";
import Input from "../../../components/v2/form/input/Input";
import Tooltip from "../../../components/common/Tooltip";
import TokenInput from '../../../components/v2/form/input/TokenInput';

interface MobiDel {
  wid?: undefined | string;
  cid?: undefined | string;
  restaurantID?: undefined | string;
  user?: undefined | string;
  password?: undefined | string;
  line?: undefined | string;
  warehouseID?: undefined | string;
  driverID?: undefined | string;
  usePoints?: boolean;
  webhook?: string;
  maxBonusDiscount?: undefined | number,
  authorizationToken?: string,
  phoneMask?: undefined | string,
  domain?: undefined | string,
  integrationIdentifierSecret?: undefined | string,

  payments?: {
    cash?: undefined | string;
    'on-delivery'?: undefined | string;
    online?: undefined | string;
    transfer?: undefined | string;
    loyaltyCard?: undefined | string;
  },

  statuses?: {
    Unconfirmed?: undefined | string;
    WaitCooking?: undefined | string;
    ReadyForCooking?: undefined | string;
    CookingStarted?: undefined | string;
    CookingCompleted?: undefined | string;
    Waiting?: undefined | string;
    OnWay?: undefined | string;
    Delivered?: undefined | string;
    Closed?: undefined | string;
    Cancelled?: undefined | string;
  },
  salesChannel?: {
    vk?: string,
    site?: string,
    ios?: string,
    android?: string,
  }
}

interface Status {
  title: string,
  code: string,
  options: Option<string>[]
}

interface Options {
  statuses: Status[],
}

type UpdateField = (name: string, value: any) => void;
type UpdateIntegration = () => void;

interface Props {
  data: {
    id: number,
    integrations: {
      mobiDel: MobiDel
    }
  },
  updateField: UpdateField,
  errors: object
}

const fetchOptions = async (companyId: bigint, branchId: bigint): Promise<Options> => {
  try {
    const response = await apiClient.branches.integrations.mobiDel.options(companyId, branchId);

    if (! response) {
      return {statuses: []};
    }

    if ("string" === typeof response) {
      return {statuses: []};
    }

    const {data} = response as any;

    if (! data?.hasOwnProperty('statuses')) {
      return {statuses: []};
    }

    return {statuses: data?.statuses || []};
  } catch (e) {
  }

  return {statuses: []};
}

const isDirty = (previous: object, current: object): boolean => {
  return Object
    .keys(current)
    .reduce((changed: boolean, key: string) => {
      if (undefined === previous[key]) {
        return true;
      }

      if (null === current[key] && null === previous[key]) {
        return changed || false;
      }

      if (null !== current[key] && typeof current[key] === 'object') {
        return changed || isDirty(previous[key], current[key]);
      }

      return changed || previous[key] !== current[key]
    }, false);
}

export default function MobiDel({ data, updateField, errors }: Props) {
  const { company } = useCompaniesContext();

  const [options, setOptions] = useState<Options>({ statuses: [] });
  const [properties, setProperties] = useState<MobiDel>(data.integrations?.mobiDel || {})
  const [loadSecretToken, setLoadSecretToken] = useState(false);
  const [initialProperties, setInitialProperties] = useState<MobiDel>(properties)

  const updateIntegration: UpdateIntegration = () => {
    // Костыль. Объект mobiDel обновляется полностью, кроме пароля
    const update = { ...properties };
    if (update.password === initialProperties.password) {
      delete update.password;
    }
    updateField(`integrations.mobiDel`, update)
  }

  const updateProperties = (updating: MobiDel) => {
    delete errors['integrations.mobiDel.integrationIdentifierSecret'];

    setProperties(options => ({...options, ...updating}));
  }

  useEffect(() => {
    if (! (company?.id && data?.id)) {
      return;
    }

    fetchOptions(company.id, BigInt(data.id))
      .then(options => setOptions(options))
      .catch(error => console.log(error))
  }, [company?.id, data?.id]);

  useEffect(() => {
    if (! isDirty(data.integrations.mobiDel || {}, properties)) {
      return;
    }

    updateIntegration();
  }, [JSON.stringify(properties)]);

  const updateSecretToken = () => {
    if (! properties.integrationIdentifierSecret) {
      setLoadSecretToken(true);

      apiClient.branches.updateSecterToken(company.id)
        .then(async ({ data }) => {
          updateProperties({integrationIdentifierSecret: data.secret as string});
        })
        .finally(() => {
          setLoadSecretToken(false);
        });

      return;
    }

    dialogModal({
      isOpen: false,
      title: 'Внимание!',
      text: 'Вы уверены что хотите обновить секретный ключ?',
      showCancelButton: true,
      confirmButtonText: 'Обновить',
      cancelButtonText: 'Вернуться',
    })
      .then((result) => {
        if (! result.isConfirmed) {
          return;
        }

        setLoadSecretToken(true);
        apiClient.branches.updateSecterToken(company.id).then(async ({ data }) => {
          updateProperties({integrationIdentifierSecret: data.secret as string});
        })
      })
      .catch(() => {
      })
      .then(() => {
        setLoadSecretToken(false);
      });
  }

  const statusesFields = options.statuses.map((status) => (
    <SingleSelect
      value={properties.statuses?.[status.code] || ''}
      label={`${status.title}`}
      options={status.options || []}
      onChange={(option) => updateProperties({statuses: {...properties.statuses, [status.code]: option.value}})}
    />
  ));

  return (
    <Spoiler title={ 'MobiDel' } defaultOpen={ true }>
      <div className={ 'space-y-8' }>
        <div className='grid grid-cols-2 gap-4'>
          <div>
            <InputWrapper>
              <InputWrapper.Label>
                UID предприятия
              </InputWrapper.Label>
              <Input
                name={ 'integrations.mobiDel.wid' }
                placeholder={ 'UID предприятия' }
                defaultValue={ properties.wid }
                onBlur={ (event) => updateProperties({ wid: event.target.value }) }
              />
            </InputWrapper>
          </div>

          <div>
            <InputWrapper>
              <InputWrapper.Label>
                ID предприятия
              </InputWrapper.Label>
              <Input
                name={ 'integrations.mobiDel.cid' }
                placeholder={ 'ID предприятия' }
                defaultValue={ properties.cid }
                onBlur={ (event) => updateProperties({ cid: event.target.value }) }
              />
            </InputWrapper>
          </div>

          <div>
            <InputWrapper>
              <InputWrapper.Label>
                UID службы доставки
              </InputWrapper.Label>
              <Input
                name={ 'integrations.mobiDel.restaurantID' }
                placeholder={ 'UID службы доставки' }
                defaultValue={ properties.restaurantID }
                onBlur={ (event) => updateProperties({ restaurantID: event.target.value }) }
              />
            </InputWrapper>
          </div>

          <div>
            <InputWrapper>
              <InputWrapper.Label>
                <span>Домен</span>
                <Tooltip
                  textTooltip={ 'Заполняется в случае, если CRM развернута на своём сервере. По умолчанию mobidel.ru' }/>
              </InputWrapper.Label>
              <Input
                name={ 'integrations.mobiDel.domain' }
                placeholder={ 'mobidel.ru' }
                defaultValue={ properties.domain }
                onBlur={ (event) => updateProperties({ domain: event.target.value }) }
              />
            </InputWrapper>
          </div>
        </div>

        <div className='grid grid-cols-2 gap-4'>
          <div>
            <InputWrapper>
              <InputWrapper.Label>
                Имя пользователя диспетчера
              </InputWrapper.Label>
              <Input
                name={ 'integrations.mobiDel.user' }
                placeholder={ 'disp52123' }
                defaultValue={ properties.user }
                onBlur={ (event) => updateProperties({ user: event.target.value }) }
              />
            </InputWrapper>
          </div>

          <div>
            <InputWrapper>
              <InputWrapper.Label>
                Пароль диспетчера
              </InputWrapper.Label>
              <TokenInput
                name={ 'integrations.mobiDel.password' }
                placeholder={ 'oyG25XsbXc' }
                defaultValue={ properties.password }
                onBlur={ (event) => updateProperties({ password: event.target.value }) }
              />
            </InputWrapper>
          </div>

          <div>
            <InputWrapper>
              <InputWrapper.Label>
                Телефон службы доставки
              </InputWrapper.Label>
              <Input
                name={ 'integrations.mobiDel.line' }
                placeholder={ 'Телефон службы доставки' }
                defaultValue={ properties.line }
                onBlur={ (event) => updateProperties({ line: event.target.value }) }
              />
            </InputWrapper>
          </div>

          <div>
            <InputWrapper>
              <InputWrapper.Label>
                Точка комплектации
              </InputWrapper.Label>
              <Input
                name={ 'integrations.mobiDel.warehouseID' }
                placeholder={ 'Точка комплектации' }
                defaultValue={ properties.warehouseID }
                onBlur={ (event) => updateProperties({ warehouseID: event.target.value }) }
              />
            </InputWrapper>
          </div>

          <div>
            <InputWrapper>
              <InputWrapper.Label>
                Курьер
              </InputWrapper.Label>
              <Input
                name={ 'integrations.mobiDel.driverID' }
                placeholder={ 'Курьер' }
                defaultValue={ properties.driverID }
                onBlur={ (event) => updateProperties({ driverID: event.target.value }) }
              />
            </InputWrapper>
          </div>
        </div>

        <div className='grid grid-cols-2 gap-4'>
          <div>
            <InputWrapper>
              <InputWrapper.Label>
                Маска телефона
              </InputWrapper.Label>
              <Input
                name={ 'integrations.mobiDel.phoneMask' }
                placeholder={ '7xxxxxxxxxx' }
                defaultValue={ properties.phoneMask }
                onBlur={ (event) => updateProperties({ phoneMask: event.target.value }) }
              />
            </InputWrapper>
          </div>
        </div>

        <div className='grid grid-cols-2 gap-4'>
          <div>
            <InputWrapper>
              <InputWrapper.Label>
                Тип оплаты, Наличные
              </InputWrapper.Label>
              <Input
                name={ 'integrations.mobiDel.payments.cash' }
                placeholder={ 'Тип оплаты, Наличные' }
                defaultValue={ properties.payments?.cash }
                onBlur={ (event) => updateProperties({ payments: { ...properties.payments, cash: event.target.value } }) }
              />
            </InputWrapper>
          </div>

          <div>
            <InputWrapper>
              <InputWrapper.Label>
                Тип оплаты, Картой при получении
              </InputWrapper.Label>
              <Input
                name={ 'integrations.mobiDel.payments.on-delivery' }
                placeholder={ 'Тип оплаты, Картой при получении' }
                defaultValue={ properties.payments?.['on-delivery'] }
                onBlur={ (event) => updateProperties({
                  payments: {
                    ...properties.payments,
                    ['on-delivery']: event.target.value
                  }
                }) }
              />
            </InputWrapper>
          </div>

          <div>
            <InputWrapper>
              <InputWrapper.Label>
                Тип оплаты, Online
              </InputWrapper.Label>
              <Input
                name={ 'integrations.mobiDel.payments.online' }
                placeholder={ 'Тип оплаты, Online' }
                defaultValue={ properties.payments?.online }
                onBlur={ (event) => updateProperties({
                  payments: {
                    ...properties.payments,
                    online: event.target.value
                  }
                }) }
              />
            </InputWrapper>
          </div>

          <div>
            <InputWrapper>
              <InputWrapper.Label>
                Тип оплаты, Мобильный банк (Перевод)
              </InputWrapper.Label>
              <Input
                name={ 'integrations.mobiDel.payments.transfer' }
                placeholder={ 'Тип оплаты, Мобильный банк (Перевод)' }
                defaultValue={ properties.payments?.transfer }
                onBlur={ (event) => updateProperties({
                  payments: {
                    ...properties.payments,
                    transfer: event.target.value
                  }
                }) }
              />
            </InputWrapper>
          </div>
        </div>

        <div className='grid grid-cols-2 gap-4'>
          <div>
            <InputWrapper>
              <InputWrapper.Label>
                Лимит списания баллов (0-100)
              </InputWrapper.Label>
              <Input
                defaultValue={ properties.maxBonusDiscount }
                name={ 'integrations.mobiDel.maxBonusDiscount' }
                placeholder={ 'Лимит списания баллов (0-100)' }
                max={ 100 }
                min={ 0 }
                onBlur={ (event) => {
                  const value = Math.max(0, Math.min(100, +event.target.value));
                  event.target.value = value.toString();
                  updateProperties({ maxBonusDiscount: value });
                } }
              />
            </InputWrapper>
          </div>
        </div>

        <div className='grid grid-cols-2 gap-4'>
          <div>
            <InputWrapper>
              <InputWrapper.Label>
                Источник, мобильное приложение iOS
              </InputWrapper.Label>
              <Input
                name={ 'integrations.mobiDel.salesChannel.ios' }
                placeholder={ 'Источник, мобильное приложение iOS' }
                defaultValue={ properties.salesChannel?.ios }
                onBlur={ (event) => updateProperties({
                  salesChannel: {
                    ...properties.salesChannel,
                    ios: event.target.value
                  }
                }) }
              />
            </InputWrapper>
          </div>

          <div>
            <InputWrapper>
              <InputWrapper.Label>
                Источник, сайт
              </InputWrapper.Label>
              <Input
                name={ 'integrations.mobiDel.salesChannel.site' }
                placeholder={ 'Источник, сайт' }
                defaultValue={ properties.salesChannel?.site }
                onBlur={ (event) => updateProperties({
                  salesChannel: {
                    ...properties.salesChannel,
                    site: event.target.value
                  }
                }) }
              />
            </InputWrapper>
          </div>

          <div>
            <InputWrapper>
              <InputWrapper.Label>
                Источник, мобильное приложение Android
              </InputWrapper.Label>
              <Input
                name={ 'integrations.mobiDel.salesChannel.android' }
                placeholder={ 'Источник, мобильное приложение Android' }
                defaultValue={ properties.salesChannel?.android }
                onBlur={ (event) => updateProperties({
                  salesChannel: {
                    ...properties.salesChannel,
                    android: event.target.value
                  }
                }) }
              />
            </InputWrapper>
          </div>

          <div>
            <InputWrapper>
              <InputWrapper.Label>
                Источник, мобильное приложение Android
              </InputWrapper.Label>
              <Input
                name={ 'integrations.mobiDel.salesChannel.android' }
                placeholder={ 'Источник, мобильное приложение Android' }
                defaultValue={ properties.salesChannel?.android }
                onBlur={ (event) => updateProperties({
                  salesChannel: {
                    ...properties.salesChannel,
                    android: event.target.value
                  }
                }) }
              />
            </InputWrapper>
          </div>
        </div>

        <div className='grid grid-cols-4 gap-4'>
          { statusesFields }
        </div>

        <div className={ 'grid grid-cols-1 mb-4' }>
          <div className={ `flex items-end gap-5 py-3 px-4 border-gray-20 border-[1px] rounded-lg` }>
            <div className={ 'w-full' }>
              <InputWrapper
                error={ errors['integrations.partner.integrationIdentifierSecret'] }
              >
                <InputWrapper.Label>
                  Секретный ключ
                </InputWrapper.Label>
                <span
                  className={ 'pointer-events-none select-none' }>{ properties.integrationIdentifierSecret || 'Секретный ключ' }</span>
              </InputWrapper>
            </div>

            <BaseButton onClick={ updateSecretToken }
                        className={ "bg-interactive-elem text-white h-[42px] min-w-[110px]" }>
              {
                loadSecretToken ?
                  <div className={ 'flex flex-row items-center justify-center' }>
                    <svg
                      className="animate-spin h-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                              strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </div> : 'Обновить'
              }
            </BaseButton>
            <CopyToClipboard text={ properties.integrationIdentifierSecret }
                             onCopy={ () => showSuccessToast({ content: "Секретный ключ скопирован", }) }>
              <BaseButton onClick={ () => window.navigator }
                          className={ "bg-interactive-elem text-white h-[42px]" }> Копировать </BaseButton>
            </CopyToClipboard>
          </div>
        </div>

        <div className='flex flex-col gap-2'>
          {/* Инвертируем значение, ибо бек принемает send..., а лейбал: 'Не...' */ }
          <Checkbox
            label='Не использовать баллы'
            name={ 'integrations.partner.usePoints' }
            value={ ! Boolean(+(properties?.usePoints || 0)) }
            onChange={ (value: any) => updateProperties({ usePoints: ! value }) }
          />

          <div className={ 'mt-2' }>
            <Spoiler title={ 'Подробнее' } size={'sm'} toggleTextColor={"text-interactive-text"}>
              <div className={ "flex flex-col font-medium mt-3 gap-y-2 max-w-[90%]" }>
                <div className='mb-4'>
                  Для того что бы связь работала, необходимо выполнить следующие пункты:
                </div>
                <ul className='mb-4'>
                  <li>
                    1. Перейти в нужную службу доставки
                  </li>
                  <li>
                    2. В разделе CallBack API указать URL API: { (data.integrations.mobiDel.webhook && (
                    <span className='font-semibold underline'>{ data.integrations.mobiDel.webhook }</span>)) || (<span
                    className={ 'text-gray-500' }>ссылка на веб-хук будет доступна после сохранения настроек</span>) }
                  </li>
                  <li>
                    3. Указать любой логин и пароль. Эти данные нами не используются, т.к. проверка валидности
                    веб-хука происходит от параметра <code>secret</code> в URL API
                  </li>
                </ul>
              </div>
            </Spoiler>
          </div>
        </div>
      </div>
    </Spoiler>
  )
}
