import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import FormWrapper from "../../../components/common/form/FormWrapper";
import tabs from "./tabs";
import CreateFormFields from "../../../components/common/form/CreateFormFields";
import {useCompaniesContext} from "../../../contexts/Companies";
import {useBranchesContext} from "../../../contexts/Branches";
import useForm from "../../../hooks/useForm";
import initForm from "../initForm";
import Base from "../forms/Base";
import Clients from "../forms/Clients";
import {apiClient} from "../../../libs/api/apiClient";
import {showErrorToast} from "../../../libs/helpers/toasts";
import {useNavigate} from "react-router-dom";
import {preparePaginationQueryParams} from "../../../contexts/common";

export default function CreateMailing() {
  const {company}: any = useCompaniesContext()
  const {branch, branches}: any = useBranchesContext()
  const {data, updateField, updateFieldNoRender, updateData, errors, updateErrors, submit}: any = useForm(JSON.parse(JSON.stringify(initForm)))
  const [tab, setTab] = useState('base')
  const [allCustomerTags, setAllCustomerTags] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleSubmit = () => {
    if (company && branch) {
      submit(async (form: any) => {
        setLoading(true)
        apiClient.mailings.create(company.id, branch.id, {body: form}).then(({data, errors, message}: any) => {
          if (data) {
            updateData(data)
            navigate("/mailings")
          } else {
            updateErrors(errors || {});
            checkErrors(errors, true)
            showErrorToast({
              content: 'Что-то пошло не так'
            })
          }
        }).catch(() => {
          showErrorToast({
            content: 'Что-то пошло не так'
          })
        }).finally(() => setLoading(false))
      })
    }
  }

  // Выглядит как срань, ею и является
  // Если придумаешь вариант получше отыскать на каком табе ошибка, то флаг в руки
  const [tabsHasError, setTabsHasError] = useState<any>([])

  useEffect(() => {
    const interval = setInterval(() => {
      if ( Object.keys(errors).length > 0) {
        checkErrors(errors)
      } else if (tabsHasError.length > 0) {
        setTabsHasError([])
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [JSON.stringify(errors)]);

  useEffect(() => {
    if (company && branches?.length) {
      const queryParams = preparePaginationQueryParams({filter: {branches: data.branches === '*' ? branches.map(({id}: any) => (id)) : data.branches}})

      apiClient.customersTags.getAllCompany(company.id, queryParams).then(({data, message, errors}: any) => {
        if (data) {
          setAllCustomerTags(data)
        } else {
          showErrorToast({
            content: message || `При загрузке меток клиентов произошла ошибка`
          })
        }
      }).catch(() => {
        showErrorToast({
          content: `При загрузке меток клиентов произошла ошибка`
        })
      })
    }
  }, [company, data.branches])

  const checkErrors = (err=errors, tab = false) => {
    let tabsError: any = []
    let errorsName = Object.keys(err)

    tabs.map(elem => {
      if (elem?.fields) {
        let hasError = false
        for (let i = 0; i < elem.fields.length; i++) {
          let fieldName = elem.fields[i];
          for (let j = 0; j < errorsName.length; j++) {
            let error = errorsName[j]

            if (error.includes(fieldName) && error.startsWith(fieldName)) {
              hasError = true
              break
            }
          }

          if (hasError) {
            break
          }
        }

        if (hasError) {
          if (tab && !tabsError.length) {
            setTab(elem.tag)
          }
          tabsError.push(elem.tag)
        }
      }
    })

    setTabsHasError(tabsError)
  }

  return (
    <div className='px-8 pb-8 pt-2 text-gray-50'>
      <div className='mb-8'>
        <div className='text-[44px] leading-[60px] mb-2'>Рассылки</div>
        <Breadcrumbs
          links={[
            {to: `/mailings`, label: 'Список рассылок'},
            {to: '', label: 'Создание расссылки'},
          ]}
          back={`/mailings`}
        />
      </div>
      <div className={'rounded-xl bg-white py-5'}>
        <FormWrapper
          tabs={tabs}
          tab={tab}
          setTab={setTab}
        >
          <CreateFormFields
            show={tab === 'base'}
            nextOption='clients'
            setTab={setTab}
          >
            <Base data={data} updateField={updateField} updateFieldNoRender={updateFieldNoRender} errors={errors} branches={branches}/>
          </CreateFormFields>
          <CreateFormFields
            show={tab === 'clients'}
            setTab={setTab}
            onSubmit={handleSubmit}
            submitLabel='Создать рассылку'
            submitLoading={loading}
          >
            <Clients data={data} updateField={updateField} updateFieldNoRender={updateFieldNoRender} errors={errors} tagsArray={allCustomerTags}/>
          </CreateFormFields>

        </FormWrapper>
      </div>
    </div>
  )
}
