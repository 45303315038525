import nextId from "react-id-generator";
import {COLORS} from "../../constants/colors";

const initForm = {
  emails: [],
  hideDetails: false,
  details: {},
  loyaltyProgram: 'none',
  isCustomerAddressMustBeInDeliveryZone: true,
  statuses: [
    {
      key: nextId(),
      code: "accepted",
      title: "Принят",
      color: COLORS.at(0),
      listen: false,
      notification: "Заказ принят",
      required: true
    },
    {
      key: nextId(),
      code: "delivery",
      title: "Отправлен",
      color: COLORS.at(0),
      listen: false,
      notification: "Заказ отправлен",
      required: true
    },
    {
      key: nextId(),
      code: "cancelled",
      title: "Отменен",
      color: COLORS.at(0),
      listen: false,
      notification: "Заказ отменён",
      required: true
    },
    {
      key: nextId(),
      code: "completed",
      title: "Выполнен",
      color: COLORS.at(0),
      listen: false,
      notification: "Заказ выполнен",
      required: true
    },
  ],
  tags: [],
  template: {
    code: "default",
    options: {
      theme: "light",
      cartMobileOrientation: "vertical",
      mainColor: '#61A0FF',
      additionalColor: '#9747FF',
      orderButtonColor: '#9747FF'
    },
    logo: null,
    logoThumbnail: null,
    favicon: null,
  },
}

export default initForm
