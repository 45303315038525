import React, {useEffect, useState} from "react"
import {useCompaniesContext} from "../../../contexts/Companies";
import {useBranchesContext} from "../../../contexts/Branches";
import useForm from "../../../hooks/useForm";
import initForm from "../CreateFunnelGroup/initForm";
import {useNavigate, useParams} from "react-router-dom";
import {apiClient} from "../../../libs/api/apiClient";
import {showErrorToast, showSuccessToast} from "../../../libs/helpers/toasts";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import Base from "../forms/Base";
import {dialogModal} from "../../../components/common/Modal/DialogModal";
import EditFormFields from "../../../components/common/form/EditFormFields";
import {useFormContext} from "../../../contexts/Form";


export default function EditFunnelGroup() {
  const {company} = useCompaniesContext()
  const { branch, branches } = useBranchesContext()
  const {data, updateField, updateFieldNoRender, updateData, changedFieldsRefresh, errors, updateErrors, submit, isDirty} = useForm(JSON.parse(JSON.stringify(initForm)))
  const { needUpdate, setNeedUpdate } = useFormContext()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const {id: funnelGroupId} = useParams<{id?: string}>()

  const handleSubmit = () => {
    submit(async (form: any) => {
      apiClient.funnelGroup.update(company?.id, branch?.id, funnelGroupId,{body: form}).then(({data, errors}: any) => {
        if (data) {
          showSuccessToast({
            content: "Группа воронок успешно изменена"
          })
          changedFieldsRefresh()
          getFunnelGroup()
        } else {
          updateErrors(errors || {});

          showErrorToast({
            content: 'Что-то пошло не так'
          })

        }
      }).catch((e) => console.log(e)).finally(() => setNeedUpdate(false))
    }, 'changed')
  }

  useEffect(() => {
    if (needUpdate) {
      handleSubmit()
    }
  }, [needUpdate])

  const getFunnelGroup = () => {
    if (company && branch) {
      setLoading(true)
      apiClient.funnelGroup.get(company.id, branch.id, funnelGroupId).then(({data, errors}: any) => {
        if (data) {
          updateData(data)
        } else {
          updateErrors(errors || {})
          showErrorToast({
            content: 'Что-то пошло не так'
          })
        }
        setLoading(false)
      }).catch((err) => {
        setLoading(false)
      })
    }
  }

  const removeFunnelGroup = () => {
    dialogModal({
      isOpen: false,
      title: 'Внимание!',
      text: 'Вы уверены что хотите удалить группу воронок?',
      showCancelButton: true,
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Вернуться',
    }).then((result) => {
      if (result.isConfirmed) {
        apiClient.funnelGroup.remove(company.id, branch.id, funnelGroupId)
          .then(({data, errors}: any) => {
            showSuccessToast({
              content: "Группа воронок успешно удалена",
            })
            navigate('/funnels')
          }).catch((err) => {
          updateErrors(errors || {})
          //updateData(company)
          showErrorToast({
            content: 'Что-то пошло не так'
          })
        })
      }
    }).catch(() => {})
  }

  const reset = () => {
    navigate('/funnels')
  }

  useEffect(() => {
    getFunnelGroup()
  }, [branch])

  return (
    <div className='px-8 pb-8 pt-2 text-gray-50'>
      <div className='mb-8'>
        <div className='text-[44px] leading-[60px] mb-2'>Редактирование группы воронок</div>
        <Breadcrumbs
          links={[
            {to: `/funnels`, label: 'Список групп воронок'},
            {to: '', label: 'Редактирование группы воронок'},
          ]}
          back={`/funnels`}
        />
      </div>
      <div className={'rounded-xl bg-white py-5'}>
        <EditFormFields
          show={true}
          denyRemoveAction={false}
          remove={removeFunnelGroup}
          reset={reset}
          back={'/funnels'}
          handleSubmit={handleSubmit}
          submitLoading={loading}
        >
          <Base
            errors={errors}
            data={data}
            updateField={updateField}
            updateFieldNoRender={updateFieldNoRender}
            branches={branches.map((branch: any) => ({label: branch.title, value: branch.id}))}
            funnels={[]}
            funnelGroupId={funnelGroupId}
            isEdit={true}
          />
        </EditFormFields>
      </div>
    </div>
  )
}
