import React, {memo} from "react"
import {Handle, Position} from "reactflow";
// @ts-ignore
import conditionImg from "../../../../images/condition.svg"
// @ts-ignore
import copyImg from "../../../../images/copy.svg"
// @ts-ignore
import trashImg from "../../../../images/trash.svg"
import {useFunnelNodeContext} from "../../../../contexts/FunnelNodes";
import {useFunnelDataContext} from "../../../../contexts/Funnels";
import {renderConditions} from "./components/conditions";

const ConditionNode = ({id, data, selected, isConnectable}: any) => {
  const {removeNode, copyNode, isValidConnection} = useFunnelNodeContext()
  const {conditions, filters} = useFunnelDataContext()

  return (
    <div className={'relative group'}>
      <div className={'h-[10px] bg-transparent'}></div>
      <div className={'rounded-2xl bg-funnels-yellow text-sm min-w-[300px] max-w-[500px] shadow-xl'}>
        <Handle
          className={'!top-[34px]'}
          type="target"
          position={Position.Left}
          id={'in'}
          isConnectable={isConnectable}
          isConnectableStart={false}
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
          }}
          isValidConnection={(connection) => isValidConnection(connection)}
        />
        <div className={'rounded-t-2xl px-3 py-[10px] h-[46px]'}>
          <div className={'flex flex-row items-center justify-between gap-x-2'}>
            <div className={'flex flex-row items-center gap-x-2'}>
              <img src={conditionImg} alt=""/>
              <span>Условие</span>
            </div>
            <div>
              {data?.title}
            </div>
          </div>
        </div>
        {
          data?.conditions &&
          conditions &&
          conditions?.terms &&
          Object.keys(conditions?.terms).length > 0 ? <div className={'flex py-4 px-3 bg-white text-gray-40 w-full'}>
              {renderConditions(data?.conditions, true, conditions?.terms, conditions?.definitions, filters)}
          </div> :
            <div className={'flex py-10 px-3 bg-white text-gray-40'}>
              <div className={'flex w-full px-2 py-[15px] border-[1px] cursor-pointer hover:bg-gray-10/[.8] border-dashed rounded-xl items-center justify-center'}>
                Нажмите на блок, чтобы добавить условие
              </div>
            </div>
        }

        <div className={'flex px-3 bg-white text-gray-20 '}>
          <div className={'flex w-full px-3 py-[14px] border-t-[1px] border-gray-30'}>
            Выполняется условие
          </div>
        </div>
        <div className={'flex px-3 bg-white text-gray-20 rounded-b-2xl'}>
          <div className={'flex w-full px-3 py-[14px] border-t-[1px] border-gray-30'}>
            Не выполняется условие
          </div>
        </div>
        <Handle
          className={'!bottom-[63px] !top-auto !right-[10px] !bg-funnels-green'}
          type="source"
          position={Position.Right}
          id={'1'}
          isConnectable={isConnectable}
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
          }}
          isValidConnection={(connection) => isValidConnection(connection)}
        />
        <Handle
          className={'!bottom-[15px] !top-auto !right-[10px] !bg-funnels-red'}
          type="source"
          position={Position.Right}
          id={'0'}
          isConnectable={isConnectable}
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
          }}
          isValidConnection={(connection) => isValidConnection(connection)}
        />
      </div>
      <div className={`absolute bottom-[100%] w-full duration-200 flex flex-row items-center justify-between left-0 scale-0 transition-all group-hover:scale-100 ${selected && 'scale-100'}`}>
        <div
          className={'rounded-xl cursor-pointer p-3 border-[1px] bg-white border-funnels-red hover:bg-gray-10'}
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
            removeNode(id)
          }}>
          <img src={trashImg} alt=""/>
        </div>
        <div
          className={'rounded-xl cursor-pointer p-3 border-[1px] bg-white border-funnels-green hover:bg-gray-10'}
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
            copyNode(id)
          }}>
          <img src={copyImg} alt=""/>
        </div>
      </div>
    </div>
  )
}

export default memo(ConditionNode)
