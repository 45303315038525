import React from "react";
import SimpleMultipleSelect from "./SimpleMultipleSelect";

export default function SimpleMultipleValueSelect({
  options,
  value,
  onChange,
  ...props
}: any) {
  if (value == null) value = [];
  return <SimpleMultipleSelect {...props}
    options={options}
    value={value === '*' ? '*' : value.map((v: any) => options.find((x: any) => x.value === v) || '')}
    onChange={(options: any) => onChange(options === '*' ? '*' : options.map((opt: any) => opt.value))}
  />
}
