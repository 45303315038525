import React, {useId, useRef, useState} from "react";
// @ts-ignore
import searchIcon from "../../../images/search.svg"
import useValueThrottle from "../../../hooks/useValueThrottle";

export default function Search(
  {
    name = 'searchTable',
    value = '',
    updateValue = (value: any) => {},
    fetchData = (value: any) => {},
    className = '',
    classNameInput = '',
    disabled = false,
    placeholder = 'Поиск',
    autoComplete = 'off',
    ...props
  }) {
  const id = useId()
  const input = useRef(null);

  const searching = (value: any) => {
    updateValue(value)
    fetchData(value)
  }

  const [value0, setValue0] = useValueThrottle<string | undefined>(value, searching, 700)

  return (
    <div className={`${className}`}>
      <div className={'relative'}>
        <input
          id={id}
          type={'text'}
          name={name}
          value={value0}
          disabled={false}
          onChange={({target}) => {
            setValue0(target.value || '')
          }}
          readOnly={false}
          className={`outline-0 disabled:text-gray-40 disabled:pointer-events-none text-gray-50 py-[10px] px-[14px] w-full text-sm bg-white border-gray-20 border-[1px] rounded-lg pl-[60px] ${classNameInput}`}
          placeholder={placeholder}
          autoComplete={autoComplete}
          ref={input}
          {...props}
        />
        <img src={searchIcon} className={'absolute top-1/2 -translate-y-1/2 w-[25px] h-[25px] pointer-events-none left-[20px]'} alt=""/>
      </div>
    </div>
  )
}
