import {useEffect, useState} from "react";
import {auth} from "../libs/auth";

export default function useUser() {
  const [user, setUser] = useState(null);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const subscription = auth.observable.subscribe(user => setUser(user));
    return () => {subscription.unsubscribe(); setLoad(false)};
  }, [])

  return {user, load};
}