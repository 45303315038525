const tabs = [
  {
    label: 'Основная информация',
    tag: 'base',
    required: true,
    fields: [
      'title',
      'hasLink',
      'textLink',
      'url',
      'media',
      'startPublishedAt',
      'endPublishedAt',
      'platforms',
      'countViews'
    ]
  },
  {
    label: 'Выборка клиентов',
    tag: 'clients',
    fields: [
      'withTags',
      'withoutTags'
    ]
  },
]

export default tabs
