import React from 'react'
import BaseButton from '../../../components/common/Button/BaseButton'
import {useFormContext} from "../../../contexts/Form";


export default function EditFormFields({show=false, children,  remove, reset, back, handleSubmit, submitLoading = false, denyRemoveAction = false, classNameWrapper=''}: any) {
  const {checkIsFormDirty, openDialog} = useFormContext()

  return (
    <div className={`${!show && 'hidden'} w-full flex flex-col gap-y-4 justify-between ${classNameWrapper}`}>
      <div className={'pl-6 pr-3 py-2 flex flex-col'}>
        <div className={`flex flex-col gap-y-4`}>
          {children}
        </div>
      </div>

      <div className={'flex justify-between border-gray-10 border-[1px] border-x-0 p-[16px] sticky bottom-0 bg-white'}>
        <div className={'flex'}>
          <BaseButton disabled={denyRemoveAction} className={`bg-red-500 border-red-500 border-[2px] text-white mr-[10px] py-[7px] ${denyRemoveAction ? 'hidden': ''}`} onClick={remove}>Удалить</BaseButton>
        </div>
        <div className={'flex'}>
          <BaseButton
            onClick={(e: any) => {
              if (checkIsFormDirty()) {
                e.preventDefault()
                openDialog(back)

            }else reset()
            }}
            className={'bg-white border-red-500 border-[2px] text-red-500 mr-[10px] py-[7px]'}>Отмена</BaseButton>
          <BaseButton disabled={submitLoading} className={`bg-interactive-elem text-white py-[7px] ${submitLoading && 'bg-interactive-text'}`} onClick={handleSubmit}>
            {
              submitLoading ?
                <div className={'flex flex-row items-center justify-center w-[120px]'}>
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
                : 'Сохранить'
            }
          </BaseButton>
        </div>
      </div>
    </div>
  )
}
