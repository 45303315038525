const initialTabs = [
  {
    label: 'Воронки',
    tag: 'funnels',
  },
  {
    label: 'Группы воронок',
    tag: 'groups',
    options: []
  },

]

export default initialTabs
