import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import tabs from "../tabs";
import useForm from "../../../hooks/useForm";
import initForm from "../initForm";
import BaseReviews from "../CreateOrders/BaseReviews";
import BaseOrdersComposition from "../CreateOrders/BaseOrdersComposition";
import BaseOrders from "../CreateOrders/BaseOrders";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import FormWrapper from "../../../components/common/form/FormWrapper";
import EditFormFields from "../../../components/common/form/EditFormFields";
import {fetchEstimations, fetchPaymentTypes} from "../../../providers/dictionaries/orders";
import {PromoContextWrapper} from "../../../contexts/Promo";
import {apiClient} from "../../../libs/api/apiClient";
import {useCompaniesContext} from "../../../contexts/Companies";
import {useBranchesContext} from "../../../contexts/Branches";
import {ProductsContextWrapper} from "../../../contexts/Products";
import {fetchTypes, fetchStatuses} from "../../../providers/dictionaries/promoCodes";
import {showErrorToast, showSuccessToast} from "../../../libs/helpers/toasts";
import {dialogModal} from "../../../components/common/Modal/DialogModal";
import { useFormContext } from "../../../contexts/Form";

export default function EditOrders() {
  const {company}: any = useCompaniesContext()
  const {branch}: any = useBranchesContext()
  const {needUpdate, setNeedUpdate} = useFormContext()
  const {
    data,
    updateData,
    updateField,
    updateFieldNoRender,
    errors,
    updateErrors,
    submit,
    isDirty,
    changedFieldsRefresh
  } = useForm(JSON.parse(JSON.stringify(initForm)))

  const [orderStatuses, setOrderStatuses] = useState([])
  const [reviewEstimations, setReviewEstimations] = useState([])
  const [paymentTypes, setPaymentTypes] = useState([])
  const [tab, setTab] = useState('baseInformation')
  const [promoTypes, setPromoTypes] = useState([])
  const [pickupPoints, setPickupPoints] = useState([])
  const [phoneInfo, setPhoneInfo] = useState(null)
  const [loading, setLoading] = useState<boolean>(true)


  const navigate = useNavigate();
  const {id: orderId} = useParams<{id?: string}>()

  const getOrder = () => {
    if (company && branch) {
      apiClient.orders.get(company.id, branch.id, orderId).then(({data, errors}: any) => {
        if (data) {
          updateData(data)
        } else {
          updateErrors(errors || {})
          showErrorToast({
            content: 'Что-то пошло не так'
          })
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }

  const handleSubmit = () => {
    submit(async (form: any) => {
      apiClient.orders.update(company?.id, branch?.id, orderId,{body: form}).then(({data, errors}: any) => {
        if (data) {
          showSuccessToast({
            content: "Заказ успешно изменен",
          })
          changedFieldsRefresh()
          getOrder()
        } else {
          updateErrors(errors || {});

          showErrorToast({
            content: 'Что-то пошло не так'
          })

        }
      }).catch((e) => console.log(e)).finally(() => setNeedUpdate(false))
    }, 'changed')
  }

  useEffect(() => {
    if (needUpdate) {
      handleSubmit()
    }
  }, [needUpdate])

  const handleRemove = () => {
    dialogModal({
      isOpen: false,
      title: 'Внимание!',
      text: 'Вы уверены что хотите удалить заказ?',
      showCancelButton: true,
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Вернуться',
    }).then((result) => {
      if (result.isConfirmed) {
        apiClient.orders.remove(company.id, branch.id, orderId)
          .then(({data, errors}: any) => {
            showSuccessToast({
              content: "Заказ успешно удален",
            })
            navigate('/orders')
          }).catch((err) => {
          updateErrors(errors || {})
          //updateData(company)
          showErrorToast({
            content: 'Что-то пошло не так'
          })
        })
      }
    }).catch(() => {})
  }


  useEffect(() => {
    if (company && branch) {
      setLoading(true)
      apiClient.branches.get(company.id, branch.id).then(({data, errors}: any) => {
        if (data) {
          setPickupPoints(data?.order?.pickupPoints || [])
          setPhoneInfo(data?.extended?.phoneCode || null)
        } else {
          console.log(errors)
        }
        setLoading(false)
      })
    }
  }, [branch])

  useEffect(() => {
    getOrder()
  }, [company, branch])

  const changeTab = (tag: any) => {
    if (Object.keys(isDirty()).length !== 0) {
      dialogModal({
        isOpen: false,
        title: 'Не сохраненные данные!',
        text: 'Вы действительно хотите перейти на следующий шаг? Все не сохраненные данные будут удалены',
        showDenyButton: true,
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: 'Сохранить и продолжить',
        denyButtonText: `Без сохранения`,
        cancelButtonText: 'Вернуться',
      }).then((result) => {
        if (result.isConfirmed) {
          handleSubmit()
          setTab(tag)
        } else if (result.isDenied) {
          //getProductsInfo(productId)
          setTab(tag)
        }
      }).catch(() => {})
    } else {
      setTab(tag)
    }
  }

  const handelReset = () => {
    navigate('/orders')
  }

  useEffect(() => {
    fetchEstimations().then(data => {
      setReviewEstimations(data)
    })
    fetchPaymentTypes().then(data => {
      setPaymentTypes(data)
    })
    fetchStatuses().then(data => {
      setOrderStatuses(data)
    })
    fetchTypes().then(data => {
      setPromoTypes(data)
    })
  }, [])

  if (loading) return (
    <div className="text-gray-40 text-3xl text-center mt-[35vh]">
      <span>Загрузка...</span>
    </div>
  )

  return (
    <div className='px-8 pb-8 pt-2 text-gray-50'>
      <div className='mb-8'>
        <div className='text-[44px] leading-[60px] mb-2'>Заказы</div>
        <Breadcrumbs
          links={[
            {to: `/orders`, label: 'Список заказов'},
            {to: '', label: 'Редактирование заказа'},
          ]}
          back={`/orders`}
        />
      </div>
      <div className={'rounded-xl bg-white py-5'}>

        <FormWrapper
          tabs={tabs}
          tab={tab}
          setTab={changeTab}
        >
          <EditFormFields
            show={tab === 'baseInformation'}
            remove={handleRemove}
            reset={handelReset}
            back={'/orders'}
            handleSubmit={handleSubmit}
          >
            <PromoContextWrapper>
              <BaseOrders
                data={data}
                updateField={updateField}
                updateFieldNoRender={updateFieldNoRender}
                errors={errors}
                paymentTypes={paymentTypes}
                promoStatuses={orderStatuses}
                promoTypes={promoTypes}
                phoneInfo={phoneInfo}
                pickupPoints={pickupPoints.map(({id, address}: any) => {return {label: address?.title, value: id}})}
                edit={true}
                statuses={company && company?.statuses ? company?.statuses.map(({code, title}: any) => {return {label: title, value: code}}) : []}
              />
            </PromoContextWrapper>
          </EditFormFields>

          <EditFormFields
            show={tab === 'orderComposition'}
            remove={handleRemove}
            reset={handelReset}
            back={'/orders'}
            handleSubmit={handleSubmit}
          >
            <ProductsContextWrapper>
              <BaseOrdersComposition
                data={data}
                updateField={updateField}
                updateFieldNoRender={updateFieldNoRender}
                errors={errors}
              />
            </ProductsContextWrapper>
          </EditFormFields>

          <EditFormFields
            show={tab === 'review'}
            remove={handleRemove}
            reset={handelReset}
            back={'/orders'}
            handleSubmit={handleSubmit}
          >
            <BaseReviews
              data={data}
              updateField={updateField}
              updateFieldNoRender={updateFieldNoRender}
              errors={errors}
              reviewEstimations={reviewEstimations}
            />
          </EditFormFields>

        </FormWrapper>
      </div>
    </div>
  )
}
