import React, {useEffect, useRef, useState} from "react";
import Tooltip from "../../../../Tooltip";
// @ts-ignore
import closeIcon from '../../../../../../images/greenClose.svg'
import BaseButton from "../../../../Button/BaseButton";
import SimpleDataTableModal from "./SimpleDataTableModal";

export function SimpleDatatableSelect(
  {
    children,
    data,
    errors,
    errorLabel = true,
    label,
    className = '',
    required = false,
    textTooltip = '',
    name,
    placeholder,
    value,
    onSubmit,
    removeItem,
    updateData,
    columns,
    loading,
    resetFilters = () => {},
    buttonPlaceholder = 'Выберите товар'
  }: any) {
  const [show, setShow] = useState(false);
  const wrapper = useRef<any>(null);
  const error = errors[name];

  useEffect(() => {
    delete errors[name];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  /* Закрываем при клике вне поля */
  useEffect(() => {
    function handleClick(event: any) {
      if (wrapper.current && !wrapper.current.contains(event.target)) {
        setShow(false);
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }

  }, [show, setShow, wrapper]);

  useEffect(() => {
    if (!show) {
      resetFilters()
    }
  }, [show])

  return (
    <div className={`${className}`}>
      {
        label &&
          <label className="flex items-center mb-2 font-medium text-sm">
            {label}
              <span className='text-error-main'>{required && '*'}</span>
            {
              textTooltip && <Tooltip textTooltip={textTooltip} defaultText={name}/>
            }
          </label>
      }
      <div ref={wrapper} className={'relative w-full'}>
        <div
          className="flex items-center justify-between py-[20px] px-[14px] w-full text-sm bg-white border-gray-20 border-[1px] rounded-2xl">
          <div className="flex gap-2 items-center">
            <div className={'flex flex-row items-center'}>
              {
                loading ?
                  <p className={"text-gray-20"}>Загрузка...</p> :
                value ?
                      <div className="flex flex-row items-center px-3">
                        <span
                          className="mr-2 text-ellipsis overflow-hidden whitespace-nowrap text-gray-20">{data.find((item: any) => item?.id === value)?.title || ''}</span>
                        <img src={closeIcon} alt="" className="w-[16px] h-[16px] cursor-pointer" onClick={() => {
                          removeItem()
                        }}/>
                      </div>
                  :
                  <span className="text-gray-20">{placeholder}</span>
              }

            </div>
          </div>
          <BaseButton
            className={'bg-white border-interactive-elem border-[2px] text-interactive-elem mr-[10px] py-[7px]'}
            onClick={() => setShow(show => !show)}>{buttonPlaceholder}</BaseButton>

        </div>

        <SimpleDataTableModal
          title={placeholder}
          setShow={setShow}
          show={show}
          loading={loading}
          data={data}
          columns={columns}
          updateData={updateData}
          onSubmit={onSubmit}
          buttonPlaceholder={buttonPlaceholder}
        >
          {children}
        </SimpleDataTableModal>
      </div>
      {error && errorLabel && (<span className={'text-error-font text-xs font-medium'}>{error}</span>)}
    </div>
  )
}
