import React from "react";
import DataTable from "../../components/common/Table/DataTable";
import {createColumnHelper} from "@tanstack/react-table";
import {useBranchesContext} from "../../contexts/Branches";

export default function ReportTransaction() {
  const columnHelper = createColumnHelper()
  const {branch} = useBranchesContext()

  const dataTransaction = [
    {
      id: '17382', date: '11.04.23 17:47', sum: '4500.00', tariff: 'Профессиональный - 10%', period: '1 мес.', status: 'Успешно', document: 'Акт_№139_от_11.04.23.pdf'
    },
    {
      id: '67327', date: '11.04.23 17:47', sum: '5400.00', tariff: 'Профессиональный - 10%', period: '1 мес.', status: 'Инициирована', document: 'Акт_№139_от_11.04.23.pdf'
    },
    {
      id: '17382', date: '11.04.23 17:47', sum: '4500.00', tariff: 'Профессиональный - 10%', period: '1 мес.', status: 'Успешно', document: 'Акт_№139_от_11.04.23.pdf'
    },
    {
      id: '17382', date: '11.04.23 17:47', sum: '5400.00', tariff: 'Профессиональный - 10%', period: '1 мес.', status: 'Инициирована', document: 'Акт_№13_от_11.04.23.pdf'
    }
  ]

  const columns = [
    columnHelper.accessor(row => row.id, {
      id: 'id',
      header: () => 'ID',
      cell: info => <div
        className={"w-[80px] font-normal break-all flex justify-start items-center mx-4"}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: "w-[80px] flex justify-start text-center mx-4",
      }
    }),
    columnHelper.accessor(row => row.date, {
      id: 'date',
      header: () => 'Дата',
      cell: info => <div
        className={"w-[135px] font-normal break-words flex justify-start items-center mr-4"}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: "w-[135px] flex justify-start text-center mr-4",
        sortField: 'date'
      }
    }),
    columnHelper.accessor(row => row.sum, {
      id: 'sum',
      header: () => 'Сумма',
      cell: info => <div
        className={"w-[95px] font-normal break-words flex justify-start items-center mr-4"}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: "w-[95px] flex justify-start text-center mr-4",
        sortField: 'sum'
      }
    }),
    columnHelper.accessor(row => row.tariff, {
      id: 'tariff',
      header: () => 'Тариф',
      cell: info => <div
        className={"w-[220px] font-normal break-words flex justify-start items-center mr-4 text-gray-40"}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: "w-[220px] flex justify-start text-center mr-4",
        sortField: 'tariff'
      }
    }),
    columnHelper.accessor(row => row.period, {
      id: 'period',
      header: () => 'Период',
      cell: info => <div
        className={"w-[95px] font-normal break-words flex justify-center items-center mr-4"}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: "w-[95px] flex justify-center text-center mr-4",
      }
    }),
    columnHelper.accessor(row => row.status, {
      id: 'status',
      header: () => 'Статус',
      cell: info => <div
        className={"w-[170px] font-normal break-words flex justify-center items-center mr-4"}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: "w-[170px] flex justify-center text-center mr-4",
      }
    }),
    columnHelper.accessor(row => row.document, {
      id: 'document',
      header: () => 'Документы',
      cell: info =>
        <div className={"grow min-w-[200px] max-w-[250px] my-2 font-normal break-all flex justify-start items-center"}>
          <div className={"bg-gray-20 text-white py-1 px-4 rounded-full"}>{info.getValue()}</div>
        </div>,
      enableSorting: false,
      meta: {
        widthClass: "grow min-w-[200px] flex justify-start text-center",
      }
    }),
  ]

  if (!branch) {
    return <div>
      <div className="px-8 pb-8 pt-2">
        <div className="flex flex-col justify-between">
          <div>
            <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Отчеты</div>
            <div className="text-gray-40 text-base">История платежей</div>
          </div>
          <div className="text-gray-40 text-3xl text-center mt-[35vh]">
            Выберите филиал
          </div>
        </div>
      </div>
    </div>
  }
  return (
    <div className="px-8 pb-8 pt-2 w-[calc(99vw-285px)]">
      <div className="flex flex-col gap-y-6">
        <div className="flex justify-between items-end">
          <div>
            <p className="text-gray-50 text-[44px] leading-[60px] mb-2">Отчеты</p>
            <p className="text-gray-40 text-base">История платежей</p>
          </div>
        </div>
        {dataTransaction.length > 0 ?
          <DataTable
            data={dataTransaction || []}
            columns={columns}
            usePagination={false}
            defaultLimit={10}
            defaultPage={1}
            //pagination={pagination}
            updateData={(params) => console.log('+')}
            //loading={loading}
            classNameTable={'mt-0'}
          /> : <div className={"text-gray-40 text-3xl text-center m-[15vh]"}>Истории платежей нет</div>
        }
      </div>
    </div>
  )
}
