import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import BaseButton from "../../../components/common/Button/BaseButton";
import { Link, useParams } from "react-router-dom";
import { apiClient } from "../../../libs/api/apiClient";
import { useCompaniesContext } from "../../../contexts/Companies";
import { useBranchesContext } from "../../../contexts/Branches";
import initForm from "../initForm";
import { useProductsContext } from "../../../contexts/Products";
import { showErrorToast } from "../../../libs/helpers/toasts";
import { getDate, getTime } from "../../../libs/helpers/datetime";
import { fetchPaymentTypes } from "../../../providers/dictionaries/orders";
import Loader from "../../../components/common/Loaders/Loader";


const OrderDetailLayout = ({ children }: any) => {
  return (
    <div className={"px-8 pb-8 pt-2"}>
      <div className={"flex flex-col gap-y-6"}>
        <div className={"text-[44px] leading-[60px] mb-2"}>Заказы</div>
        <Breadcrumbs
          links={[
            { to: `/orders`, label: 'Список заказов' },
            { to: '', label: 'Просмотр заказа' },
          ]}
          back={`/orders`}
        />
        <div className={"flex flex-col px-3 gap-y-6 rounded-xl bg-white py-5"}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default function OutputOrders() {
  const { company } = useCompaniesContext()
  const { branch } = useBranchesContext()
  const { products, updateProducts }: any = useProductsContext()
  const [order, setOrder] = useState(JSON.parse(JSON.stringify(initForm)))
  const [loading, setLoading] = useState<boolean>(true)
  const [paymentType, setPaymentType] = useState<any>([])
  const [error, setError] = useState<any>(null)
  const [deliveryType, setDeliveryType] = useState<any>([
    { title: 'Доставка', value: 'courier' },
    { title: 'Самовывоз', value: 'pickup' }
  ])
  const [promo, setPromo] = useState<any>(null)
  const { id: orderId } = useParams<{ id?: string }>()

  const getOrder = () => {
    if (company && branch) {
      apiClient.orders.get(company.id, branch.id, orderId).then(({ data, message, errors }: any) => {
        if (data) {
          setOrder(data)
        } else {
          showErrorToast({
            content: 'Произошла ошибка при загрузке данных о заказе'
          })
          setError(message || 'Не удалось загрузить информацию о заказе. Попробуйте повторить запрос позже...')
        }
      }).catch((err) => {
        showErrorToast({
          content: 'Произошла ошибка при загрузке данных о заказе'
        })
        setError('Не удалось загрузить информацию о заказе. Попробуйте повторить запрос позже...')
        console.log(err)
      }).finally(() => setLoading(false))
    }
  }

  useEffect(() => {
    getOrder()
    fetchPaymentTypes().then((data: any) => {
      setPaymentType(data)
    }).catch((reason: any) => { })
    updateProducts(null)
  }, [company, branch])

  useEffect(() => {
    if (order?.promoCodeId) {
      apiClient?.promo.get(company?.id, branch?.id, order?.promoCodeId).then(({data, message, errors}: any) => {
        if (data) {
          setPromo(data?.code)
        }
      }).catch((reason: any) => {})
    }
  }, [order?.promoCodeId])

  if (loading)
    return (
      <OrderDetailLayout>
        <Loader title={''} SVGClassName={'!w-[30px] !h-[30px]'} className={'w-full h-full'} />
      </OrderDetailLayout>
    )

  if (error) {
    return (
      <OrderDetailLayout>
        {error}
      </OrderDetailLayout>
    )
  }

  return (
    <OrderDetailLayout>
      <div className={"flex flex-col gap-y-8"}>
        <div className={"flex flex-col gap-y-3"}>
          <p className={"font-medium"}>Статус заказа</p>
          <p>{company?.statuses?.find(({ code }: any) => code == order?.status)?.title || 'Не указано'}</p>
        </div>
        <div className={"flex flex-col gap-y-3"}>
          <p className={"font-medium"}>Клиент</p>
          <p>{order?.customer?.name || '-'}</p>
        </div>
        <div className={"flex flex-col gap-y-3"}>
          <p className={"font-medium"}>Номер телефона</p>
          <p>{order?.customer?.phone || '-'}</p>
        </div>
        <div className={"flex flex-col gap-y-3"}>
          <p className={"font-medium"}>Способ получения</p>
          <p>{deliveryType?.find(({ value }: any) => value == order?.deliveryType)?.title || 'Не указано'}</p>
        </div>
        {
          order?.deliveryAddress && <div className={"flex flex-col gap-y-3"}>
            <p className={"font-medium"}>Адрес доставки</p>
            <p>{order?.deliveryAddress?.title || '-'}</p>
          </div>
        }
        {
          order?.preorder &&
          <div className={"flex flex-col gap-y-8"}>
            <div className={"flex flex-col gap-y-3"}>
              <p className={"font-medium"}>Дата предзаказа</p>
                <p>{getDate(order?.preorderAt) || '-'}</p>
            </div>
            <div className={"flex flex-col gap-y-3"}>
              <p className={"font-medium"}>Время предзаказа</p>
                <p>{getTime(order?.preorderAt) || '-'}</p>
            </div>
          </div>
        }
        <div className={"flex flex-col gap-y-3"}>
          <p className={"font-medium"}>Способ оплаты</p>
          <p>{paymentType?.find(({ value }: any) => value == order?.paymentTypeCode)?.label || 'Не указано'}</p>
        </div>
        <div className={"flex flex-col gap-y-3"}>
          <p className={"font-medium"}>Количество персон</p>
          <p>{order?.personCount || 'Не указано'}</p>
        </div>
        {
          order?.promoCodeId &&
          <div className={"flex flex-col gap-y-3"}>
            <p className={"font-medium"}>Промокод</p>
            <p>{promo || order?.promoCodeId || '-'}</p>
          </div>
        }
        <div className={"flex flex-col gap-y-3"}>
          <p className={"font-medium"}>Состав заказа</p>
          {
            order?.goods?.map((c: any, index: any) => {
              return <div key={index} className={"flex flex-row p-3 border-y-[2px]"}>
                <span className={"mr-8"}>{index + 1}</span>
                <span className={"flex mr-8 gap-x-2"}><p className={"font-medium"}>Товар:</p> {products?.find(({ id }) => id === c?.goodId)?.title || ''}</span>
                <span className={"flex mr-8 gap-x-2"}><p className={"font-medium"}>Количество:</p> {c?.quantity}</span>
                <span className={"flex mr-8 gap-x-2"}><p className={"font-medium"}>Цена:</p> {c?.amount}</span>
              </div>
            })
          }
        </div>
        <div className={"flex flex-col gap-y-3"}>
          <p className={"font-medium"}>Сумма</p>
          <p>{order?.amount}</p>
        </div>
        {order?.dicount && <div className={"flex flex-col gap-y-3"}>
          <p className={"font-medium"}>Скидка</p>
          <p>{order?.discount}</p>
        </div>}
        <div className={"flex flex-col gap-y-3"}>
          <p className={"font-medium"}>Итого</p>
          <p>{order?.total}</p>
        </div>
      </div>
      <div className={"border-gray-10 border-y-[1px] flex justify-between z-[50]  w-full sticky bottom-0 bg-white"}>
        <BaseButton
          className={"bg-interactive-elem text-white m-4 py-[7px]"}>Чек
        </BaseButton>
        <Link to={`/orders/${order?.id}/edit`}>
          <BaseButton
            className={"w-[150px] bg-interactive-elem text-white m-4 py-[7px]"}>Редактировать
          </BaseButton>
        </Link>
      </div>
    </OrderDetailLayout>
  )
}
