import React, {useState} from "react";
import Base from "./Base";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import BaseButton from "../../../components/common/Button/BaseButton";
import useForm from "../../../hooks/useForm";
import initFormBase from "../initFormBase";
import {PromoTagsContextWrapper} from "../../../contexts/Tags";
import {apiClient} from "../../../libs/api/apiClient";
import {useCompaniesContext} from "../../../contexts/Companies";
import {useBranchesContext} from "../../../contexts/Branches";
import {useNavigate} from "react-router-dom";
import {CategoriesContextWrapper} from "../../../contexts/Categories";
import {ProductsContextWrapper} from "../../../contexts/Products";
import {showErrorToast, showSuccessToast} from "../../../libs/helpers/toasts";

export default function CreatePromo() {
  const {company} = useCompaniesContext()
  const {branch} = useBranchesContext()
  const {data, updateField, updateFieldNoRender, errors, updateErrors, submit} = useForm(JSON.parse(JSON.stringify(initFormBase)))
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const handleSubmit = () => {
    submit(async (form: any) => {
      setLoading(true)
      apiClient.promo.create(company?.id, branch?.id, {body: form}).then(({data, errors}: any) => {
        if (data) {
          showSuccessToast({
            content: "Промокод успешно создана",
          })
          navigate('/promo')
        } else {
          updateErrors(errors || {});

          showErrorToast({
            content: 'Что-то пошло не так'
          })

        }
      }).catch(() => {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      }).finally(() => setLoading(false))
    })
  }

  return (
    <>
      <div className='px-8 pb-8 pt-2 text-gray-50'>
        <div className='mb-8'>
          <div className='text-[44px] leading-[60px] mb-2'>Промокоды</div>
          <Breadcrumbs
            links={[
              {to: `/promo`, label: 'Список промокодов'},
              {to: '', label: 'Создание промокодов'},
            ]}

            back={`/promo`}
          />
        </div>
        <div className={'rounded-xl bg-white py-5'}>
          <ProductsContextWrapper>
            <CategoriesContextWrapper>
              <PromoTagsContextWrapper>
                <Base errors={errors} data={data} updateField={updateField} updateFieldNoRender={updateFieldNoRender}/>
              </PromoTagsContextWrapper>
            </CategoriesContextWrapper>
          </ProductsContextWrapper>
          <div className={'mt-6 border-gray-10 border-y-[1px] flex justify-end z-[50] w-full sticky bottom-0 bg-white'}>
            <BaseButton
              disabled={loading}
              className={`w-[225px] bg-interactive-elem text-white m-4 py-[7px] ${loading && 'bg-interactive-text'}`}
              onClick={handleSubmit}>
              {
                loading ?
                  <div className={'flex flex-row items-center justify-center w-[120px]'}>
                    <svg
                      className="animate-spin h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </div>
                  : "Добавить промокод"
              }
            </BaseButton>
          </div>
        </div>
      </div>
    </>
  )
}
