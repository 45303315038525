import React, {useEffect, useState} from "react";
import Actions from "../../components/common/Table/Actions";
import BaseButton from "../../components/common/Button/BaseButton";
import DataTable from "../../components/common/Table/DataTable";
import {createColumnHelper} from "@tanstack/react-table";
import {Link} from "react-router-dom";
import {useNpsReviewContext} from "../../contexts/NPS";
import RangeDatePicker from "../../components/common/form/datetime/RangeDatePicker";
import {fetchSources} from "../../providers/dictionaries/nps";
import {fetchEstimations, fetchOrderStatuses} from "../../providers/dictionaries/orders";
import {getDateAndTime} from "../../libs/helpers/datetime";
import {useBranchesContext} from "../../contexts/Branches";
import Export from "../../components/common/Export";
import { ExportBlocks } from "../../components/common/Export/helpers";
import androidIcon from "../../images/android.svg"
import iosIcon from "../../images/ios.svg"
import siteIcon from "../../images/site.svg"
import phoneIcon from "../../images/phone.svg"
import vkIcon from "../../images/vk.svg"

export default function Reviews() {
  const [search, setSearch] = useState('')
  const [applyFilter, setApplyFilter] = useState(false)
  const [sources, setSources] = useState<any>([])
  const [estimations, setEstimations] = useState<any>([])
  const [statuses, setStatuses] = useState<any>([])
  const [dateFilter, setDateFilter] = useState([null, null])
  const {reviews, pagination, updateNpsReviews, limits, filters, updateFilters, loading}: any = useNpsReviewContext()
  const {branch} = useBranchesContext()

  const columnHelper = createColumnHelper<any>()

  const searching = (value: any) => {
    setSearch(value || '')
    updateFilters('search', value || null)
  }

  const submitFilter = () => {
    setApplyFilter(true)
    updateNpsReviews(limits, filters)
  }

  useEffect(() => {
    fetchSources().then((data) => {
      setSources(data)
    }).catch(() => {})
    fetchEstimations().then((data) => {
      setEstimations(data)
    }).catch(() => {})
    fetchOrderStatuses().then((data) => {
      setStatuses(data)
    }).catch(() => {})
  }, [])


  const columns = [
    columnHelper.accessor(row => row.orderId, {
      id: 'orderId',
      header: () => 'ID Заказа',
      cell: info => <div
        className={'w-[90px] font-normal break-all flex justify-center items-center mx-3'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[90px] flex justify-center text-center mx-3',
      }
    }),
    columnHelper.accessor(row => row.createdAt, {
      id: 'createdAt',
      header: () => 'Дата',
      cell: info => <div
        className={"w-[80px] font-normal flex justify-center items-center mr-3"}>{getDateAndTime(info.getValue())}</div>,
      enableSorting: true,
      meta: {
        widthClass: "w-[80px] flex justify-center mr-3",
        sortField: 'createdAt'
      }
    }),
    columnHelper.accessor(row => row.customer, {
      id: 'customer',
      header: () => 'Клиент',
      cell: info => <div
        className={"w-[160px] font-normal flex justify-start items-center mr-3"}>
          {
          info.getValue() ? <Link to={info.getValue()?.id != null ? `/clients/${info.getValue()?.id}/edit` : '/clients'} className={"flex flex-col"}>
            <p className={"text-interactive-text"}>{info.getValue()?.name}</p>
            <p>{info.getValue()?.phone}</p>
          </Link> : <p className="text-error-font">Клиент не найден</p>
          }

      </div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[160px] flex justify-start mr-3',
      }
    }),
    columnHelper.accessor(row => row.text, {
      id: 'text',
      header: () => 'Отзыв',
      cell: info => <div
        className={"w-2/4 font-normal flex justify-start break-all items-center mr-3 py-2"}>
        <Link to={info.row?.original?.id != null ? `/reviews/${info.row?.original?.id}/edit` : '/reviews'} className={'text-interactive-elem'}>
          {info.getValue()}
        </Link>
      </div>,
      enableSorting: false,
      meta: {
        widthClass: "w-2/4 flex justify-start mr-3",
      }
    }),
    columnHelper.accessor(row => row.status, {
      id: 'status',
      header: () => 'Статус',
      cell: info => <div
        className={'w-[130px] font-normal flex justify-center items-center'}>{statuses.find((data: any) => data.value === info.getValue())?.label || ''}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[130px] flex justify-center',
      }
    }),
    columnHelper.accessor(row => row.estimation, {
      id: 'estimation',
      header: () => 'Оценка',
      cell: info => <div
        className={'w-[70px] font-normal flex justify-center items-center'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[70px] flex justify-center',
      }
    }),
    columnHelper.accessor(row => row.source, {
      id: 'source',
      header: () => 'Источник',
      cell: info => <div
      className={'w-[80px] font-normal flex justify-center items-center mx-3'}>
      {
        info.getValue() === 'android' && <img className={'w-[20px] h-[20px]'} src={androidIcon} alt=""/>
      }
      {
        info.getValue() === 'ios' && <img className={'w-[20px] h-[20px]'} src={iosIcon} alt=""/>
      }
      {
        info.getValue() === 'site' && <img className={'w-[20px] h-[20px]'} src={siteIcon} alt=""/>
      }
      {
        info.getValue() === 'phone' && <img className={'w-[20px] h-[20px]'} src={phoneIcon} alt=""/>
      }
      {
        info.getValue() === 'vk' && <img className={'w-[20px] h-[20px]'} src={vkIcon} alt="" />
      }
    </div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[80px] flex justify-center mx-3',
      }
    }),
  ]

  if (!branch) return <div>
    <div className="px-8 pb-8 pt-2 ">
      <div className="flex flex-col justify-between">
        <div>
          <div className="text-gray-50 text-[44px] leading-[60px] mb-2">NPS</div>
          <div className="text-gray-40 text-base">Отзывы</div>
        </div>
        <div className="text-gray-40 text-3xl text-center mt-[35vh]">
          Выберите филиал
        </div>
      </div>
    </div>
  </div>

  if (!applyFilter && !reviews?.length) return (
    <div className="px-8 pb-8 pt-2 ">
      <div className="flex flex-col gap-y-6">
        <div className="flex justify-between items-end">
          <div>
            <div className="text-gray-50 text-[44px] leading-[60px] mb-2">NPS</div>
            <div className="text-gray-40 text-base">Отзывы</div>
          </div>
        </div>
      </div>
      <div className="text-gray-40 text-3xl text-center mt-[35vh]">
        {loading ? 'Загрузка...' : 'Отзывов нет'}
      </div>
    </div>
  )

  return (
    <div className={"px-8 pb-8 pt-2 "}>
      <div className={"flex flex-col gap-y-6"}>
        <div className={"flex justify-between items-end"}>
          <div>
            <div className={"text-gray-50 text-[44px] leading-[60px] mb-2"}>NPS</div>
            <div className={"text-gray-40 text-base"}>Отзывы</div>
          </div>
        </div>
        <div className={"w-full"}>

          <Actions
            updateValue={(value: any) => {
              searching(value)
            }}
            fetchData={(value: any) => {

            }}
            searchValue={search}
            classNameSearch={"w-full mr-4"}
            classNameMarginSearch={"mt-0"}
          >
            <div className={"w-full flex flex-row items-center justify-between"}>
              <RangeDatePicker
                required={false}
                values={dateFilter}
                placeholder='Выберите промежуток дат'
                onChange={([startAt, endAt]: any) => {
                  setDateFilter([startAt, endAt]);
                  if ((startAt && endAt) || (startAt === null && endAt === null)) {
                    updateFilters('filter', {
                      createdAt: {
                        after: startAt,
                        before: endAt
                      }
                    })
                  }
                }}
                errors={{}}
                name={'dateFilter'}
                label={undefined}
                textTooltip={undefined}
              />
              <BaseButton onClick={submitFilter} className={"w-1/3 bg-interactive-elem text-white"}>Найти</BaseButton>
            </div>
          </Actions>
        </div>

        <Export
            title={'Экспорт отзывов'}
            types={[{ title: 'Экспортировать', type: ExportBlocks.reviews }]}
            filter={filters || null}
            order={limits?.order || null}
        />

        <DataTable
          data={reviews}
          columns={columns}
          usePagination={true}
          defaultLimit={10}
          defaultPage={1}
          pagination={pagination}
          updateData={(params: any) => updateNpsReviews(params, filters)}
          loading={loading}
          classNameTable={'mt-0'}
        />

      </div>
    </div>
  )
}
