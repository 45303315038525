const tabs = [
  {
    label: 'Метки заказов',
    tag: 'orders',
    fields: [
    ]
  },
  {
    label: 'Метки клиентов',
    tag: 'customers',
    fields: [
    ]
  },
  {
    label: 'Метки промокодов',
    tag: 'promo',
    fields: [
    ]
  },
  /*{
    label: 'Метки компании',
    tag: 'companies',
    fields: [
    ]
  },*/
]

export default tabs
