import React, { useEffect, useRef, useState } from 'react'
import { useBranchesContext } from '../../../contexts/Branches'
import ChevronIcon from './../../icons/ChevronIcon';
import { Link, useLocation } from 'react-router-dom';
import { useFormContext } from '../../../contexts/Form';

export default function Branches() {
  const { branches, branch, selectBranch } = useBranchesContext()
  const {checkIsFormDirty, openDialog} = useFormContext()
  const [showList, setShowList] = useState(false)
  const wrapper = useRef<any>(null);
  const location = useLocation()

  useEffect(() => {
    setShowList(false)
  }, [location])

  useEffect(() => {
    function handleClick(event: any) {
      if (wrapper.current && !wrapper.current.contains(event.target)) {
        setShowList(false);
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }

  }, [showList, setShowList, wrapper]);

  return (
    <div ref={wrapper} className='relative h-full  flex items-center cursor-pointer' onClick={() => setShowList(!showList)}>
      <div>{branch ? branch.title : 'Выбрать филиал'}</div>
      <div className='h-full w-16 flex items-center justify-center'>
        <ChevronIcon className={`duration-300 ${showList && 'rotate-180'}`}/>
      </div>

      <div className={`${showList ? 'max-h-[180px]' : 'max-h-[0]'} overflow-y-hidden  min-w-[276px] bg-gray-10 top-16 -left-8 absolute text-gray-50 font-medium z-[1000]`}>
        <div className='max-h-[132px] text-base overflow-y-auto overflow-x-hidden pretty-scroll'>
          {
            branches && branches.map((elem: any) => (
              <div key={elem.id} className={`flex items-center h-11 pl-8 hover:bg-interactive-elem ${branch && branch?.id && branch?.id === elem.id && 'bg-interactive-elem'}`} onClick={() => {selectBranch(elem.id); setShowList(false)}}>
                { elem.title }
              </div>
            ))
          }

        </div>
        <Link to={'/branches'} onClick={(e) => {
          if (checkIsFormDirty()) {
            e.preventDefault()
            openDialog('/branches')
          } else {
            setShowList(false)
          }
        }}>
          <div className='flex items-center justify-center text-center h-12 border-t-[1px] border-gray-20 text-sm'>
            Список филиалов
          </div>
        </Link>
      </div>
    </div>
  )
}
