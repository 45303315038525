import { useCompaniesContext } from "../../contexts/Companies";
import {auth} from "../auth";
import { redirect } from "react-router-dom";

type Config = {
  headers: {
    Accept: string,
    'Content-Type'?: string
  },
  body: string | FormData
  method: string
};

export function prepareConfig(method: string, options: any): Config {
  const user = auth.current;

  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method,
    ...options
  };

  if (user?.token) {
    config.headers['Authorization'] = `Bearer ${user.token}`;
  }

  if (options?.companyId) {
    config.headers['companyId'] = options?.companyId;
  }

  return config as Config;
}

export async function checkResponse(response: Response) {
  const {status} = response;

  const throwable = [413, 409, 404];

  if (401 === status) {
    await auth.clearUser()
    redirect("/")

    return 401;
  }

  if (throwable.includes(status)) {
    return status;
  }

  if (503 === status) {
    return 503;
  }

  return null;
}
