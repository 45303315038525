import React from "react";
import SingleSimpleValueSelect from "../../../../components/common/form/select/SingleSimpleValueSelect";
import TextInput from "../../../../components/common/form/input/TextInput";
import DateTimePicker from "../../../../components/common/form/datetime/DateTimePicker";

interface IProps {
  data: any,
  timers: any,
  updateField: (field: string, value: any) => void,
  errors: any
}

export enum TimerType {
  today = 'today',
  tomorrow = 'tomorrow',
  weekday = 'weekday',
  nearestWeekday = 'nearest-weekday',
  nearestTime = 'nearest-time',
  days = 'days',
  hours = 'hours',
  minutes = 'minutes',
  seconds = 'seconds',
  datetime = 'datetime'
}


enum FieldsType {
  select = "select",
  dateTime = "datetime",
  date = "date",
  time = "time",
  number = "number"
}


export default function TimerNodeBase(
  {
    timers,
    data,
    updateField,
    errors
  }: IProps) {

  const getLabel = (type: TimerType) => {
    switch (type) {
      case TimerType.days:
        return 'Количество дней'
      case TimerType.hours:
        return 'Количество часов'
      case TimerType.minutes:
        return 'Количество минут'
      case TimerType.seconds:
        return 'Количество секунд'
      default:
        return 'Количество'
    }
  }

  const renderTimeField = (name: string) => {
    return <TextInput
      type={'time'}
      className={'w-full'}
      label={'Время таймера (чч:мм)'}
      onChange={(value: any) => updateField(`body.${name}`, value)}
      value={data?.body[name] || null}
      name={`${name}`}
      errors={errors}
    />
  }

  const renderDateTimeField = (name: string) => {
    return <DateTimePicker
      value={data?.body[name] || null}
      onChange={(date: Date) => {
        updateField(`body.${name}`, date)
      }}
      errors={{}}
      name={name}
      label={'Выберите дату и время'}
      textTooltip={''}
      required={false}
      placeholder={'Выберите дату и время'}
    />
  }

  const renderSelectField = (name: string, items: any[]) => {
    return <SingleSimpleValueSelect
      name={'xs'}
      placeholder={'Выберите день'}
      value={data?.body[name]}
      options={items?.map(({title, value}: any) => ({label: title, value})) || []}
      onChange={(option: any) => {
        updateField(`body.${name}`, option)
      }}
    />
  }

  const renderNumberField = (name: string) => {
    return <TextInput
      min={0}
      type={'number'}
      className={'w-full'}
      label={getLabel(data?.body?.type)}
      onChange={(value: any) => updateField(`body.${name}`, value)}
      value={data?.body[name] || ''}
      name={`${name}`}
      errors={errors}
    />
  }

  const renderFields = () => {
    const inputs = timers.find(({value}: any) => value == data?.body?.type)?.inputs

    if (inputs && inputs instanceof Array && inputs.length > 0) {
      return inputs.map(({type, name, items}: any) => {
        switch (type) {
          case FieldsType.time:
            return renderTimeField(name)
          case FieldsType.number:
            return renderNumberField(name)
          case FieldsType.select:
            return renderSelectField(name, items)
          case TimerType.datetime:
            return renderDateTimeField(name)
          default:
            return <></>
        }
      })
    }

    return <></>
  }

  return (
    <div className={'flex flex-col gap-y-6 text-black py-8 overflow-y-auto min-h-[70%]'}>
      <SingleSimpleValueSelect
        className={''}
        name={'timerType'}
        placeholder={'Выберите действие'}
        label={'Тип ожидания таймера'}
        value={data?.body?.type || ''}
        options={timers?.map(({title, value}: any) => ({label: title, value}))}
        onChange={(value: any) => {
          updateField('body', {})
          updateField('body.type', value)
        }}
      />

      {renderFields()}

    </div>
  )
}
