import React from "react";
import DataTable from "../../components/common/Table/DataTable";
import {useHistoryArchiveContext} from "../../contexts/Histories";

export function ArchiveHistoriesTable({columns}: any) {
  const {histories, updateHistories, loading, pagination}: any = useHistoryArchiveContext()

  return (
    <DataTable
      data={histories || []}
      columns={columns}
      updateData={(params: any) => {updateHistories(params)}}
      pagination={pagination}
      loading={loading}
      useMassAction={false}
      usePagination={true}
      defaultLimit={10}
      defaultPage={1}
      defaultOrder={{ 'position': 'asc' }}
    />
  )
}
