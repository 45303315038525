import * as React from "react";
import { PropsWithChildren, useEffect, useRef, useState } from "react";

type Position = 'bottom' | 'top'
type Props<T> = PropsWithChildren & {
  items: T[],
  isOpen: boolean,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  maxDisplayedItems?: number,
}
type Item<T> = ({ item, index }: { item: T, index: number }) => React.ReactNode;

const defaultMaxHeight = 104;

export default function DropDown<T>({items, isOpen, setIsOpen, children, maxDisplayedItems = 3}: Props<T>) {
  const container = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<Position>('bottom');
  const [maxHeight, setMaxHeight] = useState(defaultMaxHeight);
  const [containerPosition, setContainerPosition] = useState(0);

  useEffect(() => {
    function handleClick(event: MouseEvent) {
      const {clientY} = event;

      if (! isOpen) {
        setContainerPosition(clientY);
      }

      if (container.current && container.current.contains(event.target as Node)) {
        return;
      }

      setIsOpen(false);
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [isOpen]);

  useEffect(() => {
    if (! container) {
      return;
    }

    const nodes: ChildNode[] = Array.from(container.current?.childNodes || []);
    const first = nodes.slice(0, maxDisplayedItems);

    const height = first.reduce((total, item) => total + (item.parentElement?.getBoundingClientRect().height || 0), 0) + 8;
    setMaxHeight((defaultMaxHeight < height && height) || defaultMaxHeight);
  }, [items, maxDisplayedItems])

  useEffect(() => {
    setPosition('bottom');
    const windowHeight = window.innerHeight

    const fromContainerToBottom = Math.abs(windowHeight - containerPosition);
    maxHeight > fromContainerToBottom && setPosition('top');
    maxHeight <= fromContainerToBottom && setPosition('bottom');
  }, [containerPosition, maxHeight, maxDisplayedItems]);

  const positionClassName = ('bottom' === position && 'top-[105%]') || 'bottom-[105%]'
  const dropDownClassName = (isOpen && `shadow-dropdown opacity-100 py-2 !max-h-[300px]`) || 'opacity-0 !z-0'

  return (
    <div className={`${dropDownClassName} ${positionClassName} transition-all max-h-0 duration-500 absolute w-full bg-white border border-gray-10 z-[9999]`}>
      <div style={{maxHeight}} className={`${(! isOpen && '!max-h-[0px]') || ''} flex flex-col gap-1 px-2 overflow-y-auto overflow-x-hidden transition-all duration-500 pretty-scroll`} ref={container}>
        { React.Children.map(children, child => {
          if (! (React.isValidElement(child) && child.type === DropDown.Item)) {
            return;
          }

          return items.map((item, index) =>
            React.cloneElement(child, { item, index } as {})
          );
        }) }

      </div>
    </div>
  )
}

DropDown.Item = function <T>({ children, item, index }: {
  children: Item<T>,
  item: T,
  index: number
}) {
  return (<>{ children({ item, index }) } </>);
}
