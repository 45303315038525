import React from "react";
import TableStocks from "./TableStocks";

import {StocksContextWrapper} from "../../contexts/Stocks";
import {useBranchesContext} from "../../contexts/Branches";
import BaseButton from "../../components/common/Button/BaseButton";
import {Link} from "react-router-dom";

export default function Stocks() {
  const {branch} = useBranchesContext()

  return (
    <div className="px-8 pb-8 pt-2">
      <div className="flex justify-between items-end">
        <div>
          <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Акции</div>
          <div className="text-gray-40 text-base">Список акций</div>
        </div>
        {
          !!branch &&
          <Link to={'create'}>
            <BaseButton className={"ml-5 bg-interactive-elem text-white"}>Создать акцию</BaseButton>
          </Link>
        }
      </div>
      <StocksContextWrapper>
        <TableStocks/>
      </StocksContextWrapper>
    </div>
  )
}
