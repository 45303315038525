import {apiClient} from "../../libs/api/apiClient";

async function fetchTimeZones() {
  const { data }: any = await apiClient.dictionaries.timeZones()

  return data.map((tz: any) => ({
    label: `GMT${tz.offset} (${tz.title})`,
    value: tz.id
  }))
}

export default fetchTimeZones
