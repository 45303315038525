import React, { useState } from "react";
import TextInput from "../../../components/common/form/input/TextInput";
import { TagsMultipleSelect } from "../../../components/common/form/select/datatable/tags/TagsMultipleSelect";
import { createColumnHelper } from "@tanstack/react-table";
import { useCustomersTagsContext } from "../../../contexts/Tags";
import TagLabel from "../../../components/common/form/select/datatable/tags/TagLabel";
import SimpleDatePicker from "../../../components/common/form/datetime/SimpleDatePicker";
import { useCompaniesContext } from "../../../contexts/Companies";
import PhoneMaskedInput from "../../../components/v2/form/input/PhoneMaskedInput";
import InputWrapper from "../../../components/v2/form/input/common/InputWrapper";

export default function Base({data, errors, updateField, phoneInfo, isEdit, updateFieldNoRender}: any) {
  const {customersTags, loading}: any = useCustomersTagsContext()
  const {company} = useCompaniesContext()
  const [isPhoneEditable, setIsPhoneEditable] = useState(! isEdit);

  const columnHelper = createColumnHelper<any>()

  const columns = [
    columnHelper.accessor(row => row.id, {
      id: 'ID',
      header: () => 'ID',
      cell: info => <div className={'w-[85px] flex items-center justify-center text-center'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[85px] flex items-center justify-center text-center'
      }
    }),
    columnHelper.accessor(row => row.text, {
      id: 'title',
      header: () => 'Метки (Теги)',
      cell: info =>
        <TagLabel
          img={info?.row?.original?.icon}
          textColor={info.row?.original?.color}
          backgroundColor={info.row?.original?.background}>
          {info.getValue()}
        </TagLabel>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[64px] ml-4 flex items-center justify-center text-center'
      }
    }),
  ]

  return (
    <div className={ 'flex flex-col px-3 gap-y-6' }>
      { company.loyaltyProgram === 'own' && isEdit &&
          <div className=" text-gray-50  w-full text-sm bg-white border-gray-20 border-[1px] rounded-lg ">
              <div
                  className="bg-gray-10 font-bold text-gray-50 py-[10px] px-[14px] rounded-tr-lg rounded-tl-lg ">Основная
                  информация
              </div>
              <div className="py-[10px] px-[14px]">
                  <h3 className="font-bold text-gray-40 mb-[5px]">Программа лояльности</h3>
                  <div className="flex flex-col gap-y-1 text-gray-50">
                      <p>Статус: { data?.bonusProgramStatus?.title }</p>
                      <p>Бонусные баллы: { data?.bonusScore }</p>
                      <p>Статусные баллы: { data?.statusScore }</p>
                  </div>
              </div>
          </div>
      }

      <TextInput
        label='ФИО клиента'
        textTooltip='ФИО клиента'
        placeholder='Введите ФИО клиента'
        name={ 'name' }
        value={ data.name }
        onChange={ (value: any) => updateFieldNoRender('name', value) }
        errors={ errors }
        required={ true }
      />
      <div>
        <InputWrapper error={errors['phone']}>
          <InputWrapper.Label required={ true }>
            Телефон клиента
          </InputWrapper.Label>
          {
            isEdit && (
              <InputWrapper.After>
                <span className={'cursor-pointer text-sm text-interactive-elem'} onClick={ () => setIsPhoneEditable(state => ! state) }>{ isPhoneEditable ? 'Сохранить' : 'Изменить' }</span>
              </InputWrapper.After>
            )
          }
          <PhoneMaskedInput onFulfilled={ value => updateField('phone', value) } onChange={() => delete errors['phone']} defaultValue={ data.phone } disabled={! isPhoneEditable}
                            mask={ phoneInfo ? `${ phoneInfo?.code } ${ phoneInfo?.mask }` : '+7 (___)___-__-__' }/>
        </InputWrapper>
      </div>

      <div className='flex flex-col gap-4 rounded-2xl border-[1px] border-gray-20 p-4'>
        <TagsMultipleSelect
          data={ customersTags?.map(({ title, ...o }: any) => ({ text: title, ...o })) || [] }
          values={ data.tags }
          errors={ errors }
          name={ 'tags' }
          loading={ loading }
          placeholder={ 'Выберите метки' }
          updateValues={ (values: any) => updateField('tags', values) }
          updateData={ () => {
            console.log('update')
          } }
          columns={ columns }
          buttonPlaceholder={ 'Изменить метки' }
          label={ 'Метки' }
          textTooltip={ 'Метки' }
          removeItem={ (index: any) => {
            let options = data.tags.slice()
            options.splice(index, 1)
            updateField('tags', options)
          } }
        />
      </div>

      <TextInput
        type={ 'number' }
        min={ '0' }
        max={ '100' }
        label='Скидка (%)'
        textTooltip='Скидка (%)'
        placeholder='Текстовое поле'
        name={ 'personalDiscount' }
        value={ data.personalDiscount }
        onChange={ (value: any) => updateFieldNoRender('personalDiscount', value) }
        errors={ errors }
      />
      <TextInput
        label='ID Вконтакте'
        textTooltip='ID Вконтакте'
        placeholder='Введите ID Вконтакте'
        name={ 'socials.vk.uid' }
        value={ data?.socials?.vk?.uid }
        onChange={ (value: any) => updateFieldNoRender('socials.vk.uid', value) }
        errors={ errors }
      />
      <TextInput
        label='Имя пользователя Telegram'
        textTooltip='Имя пользователя Telegram'
        placeholder='Введите имя пользователя Telegram'
        name={ 'socials.tg.username' }
        value={ data?.socials?.tg?.username || data?.socials?.tg?.uid }
        onChange={ (value: any) => updateFieldNoRender('socials.tg.uid', value) }
        errors={ errors }
      />
      <TextInput
        label='E-mail'
        textTooltip='E-mail'
        placeholder='Введите E-mail'
        name={ 'email' }
        value={ data.email }
        onChange={ (value: any) => updateFieldNoRender('email', value) }
        errors={ errors }
      />
      <SimpleDatePicker
        value={ data.birthDate }
        onChange={ (date: Date) => {
          updateField('birthDate', date ? date.toLocaleDateString() : null)
        } }
        errors={ errors }
        name={ 'birthDate' }
        label={ 'День рождения' }
        textTooltip={ 'День рождения' }
        required={ false }
        placeholder={ 'Введите дату рождения' }
      />
    </div>
  )
}
