
import BaseButton from "../../Button/BaseButton";
import React, { useEffect, useId, useMemo, useRef, useState } from "react";
// @ts-ignore
import closeIcon from "../../../../images/greenClose.svg"
import DataTable from "../../Table/DataTable";
import { createColumnHelper } from "@tanstack/react-table";
import Search from "../../Table/Search";
import { create, InstanceProps } from "react-modal-promise";
import RadioButton from "../../form/radio/RadioButton";
import { apiRequest } from "../../../../libs/api/apiRequest";
import { showErrorToast } from "../../../../libs/helpers/toasts";
import { preparePaginationQueryParams } from "../../../../contexts/common";
import Filters from "./helpers/Filters";
import { useLocation } from "react-router-dom";

interface ModalProps extends InstanceProps<any> {
  isOpen: boolean
  onResolve: any
  onReject: any
  title: string
  value: any
  errors?: any
  items?: Array<any>
  showDenyButton?: boolean
  showCancelButton?: boolean
  showCloseButton?: boolean
  showSubmitButton?: boolean
  allowOutsideClick?: boolean
  confirmButtonText?: string
  denyButtonText?: string
  cancelButtonText?: string
  classNameWrapper?: string
  filtersData?: any
  requestUrl?: string
  isMultiply?: boolean
  fields?: any,
  valueIdentifier?: string
  //columns?: Array<any>
}

const CustomSelectModal = (
  {
    isOpen,
    onResolve,
    onReject,
    title,
    value,
    showDenyButton = false,
    showCancelButton = false,
    showCloseButton = true,
    showSubmitButton = true,
    allowOutsideClick = false,
    confirmButtonText = 'Сохранить',
    denyButtonText = 'Вернуться',
    cancelButtonText = 'Отмена',
    classNameWrapper = '',
    filtersData = [],
    requestUrl = '',
    isMultiply = false,
    fields = {},
    valueIdentifier = 'value',
    items = []
    //columns = []
  }: ModalProps) => {
  const id = useId();
  const wrapper = useRef<any>(null)
  const [selectedRows, setSelectedRows] = useState<any>(value || '')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(items);
  const columnHelper = createColumnHelper<any>()

  const updateData = (params: any = {}) => {
    setLoading(true)

    const queryParams = preparePaginationQueryParams({ ...params })

    apiRequest(`${requestUrl}${queryParams}`)
      .then(({ data, message, errors }: any) => {
        if (message || errors) {
          showErrorToast({
            content: message || 'Что-то пошло не так'
          })
          return
        }

        setData(data)
      })
      .catch((reason: any) => {
        console.log(reason)
        showErrorToast({
          content: reason || 'Что-то пошло не так'
        })
      }).finally(() => setLoading(false))
  }

  useEffect(() => {
    if (!loading && data.length == 0) {
      updateData()
    }
  }, [])

  const columns = useMemo(() => {
    let temp: any[] = []

    if (data && data?.length > 0) {
      Object.keys(data[0]).forEach((key: string) => {
        if (!Object.keys(fields).includes(key)) return

        temp.push(columnHelper.accessor(row => row[key], {
          id: `${key}`,
          header: () => fields[key],
          cell: info => {
            if (info?.getValue() instanceof Object) {
              return <></>
            }

            if (typeof info?.getValue() == 'string' && info?.getValue().startsWith('http')) {
              return (
                <div className={"min-w-[200px] max-w-[200px] break-words flex flex-row items-center justify-center"}>
                  <img className={'rounded-xl h-[60px] w-[60px]'} src={info.getValue() || ''} alt="" />
                </div>
              )
            } 

            return (<div className={'min-w-[200px] max-w-[200px] break-words font-normal flex flex-col justify-center items-center text-center'}>
                {info.getValue()}
            </div>)
          },
          enableSorting: false,
          meta: {
            widthClass: 'min-w-[200px] max-w-[200px] break-words flex justify-center items-center text-center',
          },
          minSize: 200,
        }))
      })
    }

    return temp;
  }, [data])

  /* Закрываем при клике вне поля*/
  useEffect(() => {
    function handleClick(event: any) {
      if (wrapper.current && !wrapper.current.contains(event.target) && allowOutsideClick) {
        onReject({ isCancelled: true })
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }

  }, [isOpen, onReject, wrapper]);

  useEffect(() => {
    setSelectedRows(value)
  }, [value])

  return (
    <div id={id} className={`fixed grid w-full h-full overflow-y-auto modal-wrapper ${!isOpen && 'hidden'}`}>
      <div ref={wrapper} className={`grid relative bg-white rounded-[10px] max-w-[90%] max-h-full modal ${classNameWrapper}`}>
        <div className={'flex justify-between px-4 py-[14px] items-center'}>
          <span className={'text-xl'}>{title}</span>
          {
            showCloseButton &&
            <img src={closeIcon} className={'w-[20px] cursor-pointer'} onClick={() => {
              onReject({ isCancelled: true })
            }} alt="" />
          }
        </div>
        <div className="p-4">
          <div className={'flex flex-col gap-y-2'}>
            <Filters
              filtersData={filtersData}
              submit={(params) => {
                updateData(params)
              }}
            />
            <DataTable
              classNameTable={'p-3 mb-[20px] !pt-0'}
              classNameRow={`justify-between ${columns.length >= 5 ? 'w-fit' : 'w-auto' }`}
              data={data || []}
              columns={columns || []}
              usePagination={false}
              useMassAction={isMultiply}
              selectedRows={isMultiply ? selectedRows : []}
              onChangeSelectionRows={(values: any) => {
                setSelectedRows(values)
              }}
              selectedRow={selectedRows || ''}
              useSingleSelect={!isMultiply}
              selectRow={(value: any) => {
                setSelectedRows(value)
              }}
              updateData={(params: any) => updateData(params)}
              fixedHeader={'sticky top-0 bg-white z-[1010] justify-between'}
              rowIdentifierField={valueIdentifier}
              heightHeaderRow={`${columns.length >= 5 ? 'w-fit' : 'w-auto' } h-14`}
              loading={loading}
              useSortable={false}
            />
          </div>
        </div>

        <div className="flex flex-row justify-between py-4 px-4 gap-y-4">
          {
            showDenyButton && <BaseButton className={'bg-error-font border-2 text-white border-error-font py-[7px]'} onClick={() => {
              onResolve({ isConfirmed: false, isDenied: true })
            }}>{denyButtonText}</BaseButton>
          }
          <div className={`flex flex-row gap-x-4 items-center justify-between ${!showDenyButton && 'w-full'}`}>
            {
              showCancelButton && <BaseButton className={'bg-white border-2 text-error-font border-error-font py-[7px]'} onClick={() => {
                onReject({ isCancelled: true })
              }}>{cancelButtonText}</BaseButton>
            }
            {
              showSubmitButton &&
              <BaseButton className={'bg-interactive-elem border-2 border-interactive-elem text-white py-[7px]'} onClick={() => {
                onResolve({ isConfirmed: true, isDenied: false, value: selectedRows })
              }}>{confirmButtonText}</BaseButton>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export const customSelectModal = create(CustomSelectModal)