import React, {useEffect, useState} from 'react'
import Breadcrumbs from './../../../components/common/Breadcrumbs';
import useForm from '../../../hooks/useForm'
import { apiClient } from '../../../libs/api/apiClient'
import { useNavigate } from "react-router-dom";
// @ts-ignore
import {checkCompanyCreate} from "../../../libs/helpers/permissions";
import CreateFormFields from "../../../components/common/form/CreateFormFields";
import FormWrapper from "../../../components/common/form/FormWrapper";
import initForm from "../initForm";
import tabs from "../tabs";
import MainSettings from "../forms/MainSettings";
import Requisites from "../forms/Requisites";
import Loyalty from "../forms/Loyalty";
import Statuses from "../forms/Statuses";
import Tags from "../forms/Tags";
import Templates from "../forms/Templates";
import {showErrorToast} from "../../../libs/helpers/toasts";
import {fetchLoyaltyPrograms} from "../../../providers/dictionaries/loyaltyPrograms";
import { useCompaniesContext } from '../../../contexts/Companies';
import {ICONS_TAG} from "../defaultIcons";
import {fetchRequiredOrderStatuses} from "../../../providers/dictionaries/companies";
import nextId from "react-id-generator";
import {COLORS} from "../../../constants/colors";

// TODO
const loyalties = [
  {value: 'none', label: 'Не использовать'},
  //{value: 'iiko', label: 'IIKO'},
  {value: 'frontPad', label: 'Front Pad'},
  {value: 'partner', label: 'Партнер'},
  {value: 'resultRest', label: 'Result.Rest'},
]

const domainsOptions = [
  {value: 'externalDomain', label: 'Свой домен'},
  {value: 'internalDomain', label: 'Технический домен'},
]

export default function CreateCompany() {
  const { updateCompanies } = useCompaniesContext()
  const navigate = useNavigate();
  const [tab, setTab] = useState('main')
  const [requiredStatuses, setRequiredStatuses] = useState([])
  const {data, updateData, updateField, errors, updateErrors, submit} = useForm(JSON.parse(JSON.stringify(initForm)));
  const [loyaltyPrograms, setLoyaltyPrograms] = useState(loyalties)
  const [loading, setLoading] = useState(false)

  const handleSubmit = () => {
    if (data.tags)
      data.tags = data.tags.map((tag: any) => {
        tag = {...tag};
        if (typeof(tag.icon) === 'string' || tag.icon == null) {
          delete(tag.icon)
        }
        return tag;
      })
    submit(async (form: any) => {
      setLoading(true)
      apiClient.companies.create({body: form}).then(({data, errors}: any) => {
        if (data) {
          navigate("/")
          updateCompanies()
        } else {
          updateErrors(errors || {});
          showErrorToast({
            content: 'Что-то пошло не так'
          })
        }
      }).catch(() => {
        showErrorToast({
          content: 'Что-то пошло не так'
        })
      }).finally(() => setLoading(false))
    })
  }

  useEffect(() => {
    if (!checkCompanyCreate()) {
      showErrorToast({
        content: "Нет доступа"
      })
      navigate('/companies')
    }
    fetchLoyaltyPrograms().then((data) => {
      setLoyaltyPrograms(data)
    }).catch(() => {})
    fetchRequiredOrderStatuses().then((res) => {
      setRequiredStatuses(res)
      let statuses: any = []
      res.forEach((status: any) => {
        statuses.push({
          "key": nextId(),
          "code": status.code,
          "title": status.label,
          "color": COLORS.at(0),
          "listen": false,
          "notification": status.label,
          "required": true,
          'edit': false,
        })
      })
      updateField('statuses', statuses);
    }).catch(() => {})
  }, [])

  // Выглядит как срань, ею и является
  // Если придумаешь вариант получше отыскать на каком табе ошибка, то флаг в руки
  const [tabsHasError, setTabsHasError] = useState([])

  useEffect(() => {
    const interval = setInterval(() => {
      if ( Object.keys(errors).length > 0) {
        checkErrors(errors)
      } else if (tabsHasError.length > 0) {
        setTabsHasError([])
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [JSON.stringify(errors)]);

  const checkErrors = (err=errors, tab = false) => {
    let tabsError: any = []
    let errorsName = Object.keys(err)

    tabs.map((elem: any) => {
      if (elem?.options) {
        elem?.options.forEach((elemInOptions: any) => {
          if (elemInOptions?.fields) {
            let hasError = false
            for (let i = 0; i < elemInOptions.fields.length; i++) {
              let fieldName = elemInOptions.fields[i];
              for (let j = 0; j < errorsName.length; j++) {
                let error = errorsName[j]

                if (error.includes(fieldName)) {
                  hasError = true
                  break
                }
              }

              if (hasError) {
                break
              }
            }

            if (hasError) {
              if (tab && !tabsError.length) {
                setTab(elemInOptions.tag)
              }
              tabsError.push(elemInOptions.tag)
            }
          }
        })
      }
      // Для таба первого уровня
      if (elem?.fields) {
        let hasError = false
        for (let i = 0; i < elem.fields.length; i++) {
          let fieldName = elem.fields[i];
          for (let j = 0; j < errorsName.length; j++) {
            let error = errorsName[j]

            if (error.includes(fieldName) && error.startsWith(fieldName)) {
              hasError = true
              break
            }
          }

          if (hasError) {
            break
          }
        }

        if (hasError) {
          if (tab && !tabsError.length) {
            setTab(elem.tag)
          }
          tabsError.push(elem.tag)
        }
      }
    })

    setTabsHasError(tabsError)
  }

  return (
    <>

      <div className='px-8 pb-8 pt-3 text-gray-50'>
        <div className='mb-8'>
          <div className='text-[44px] leading-[60px] mb-2'>Компании</div>
          <Breadcrumbs
            links={[
              {to: '/', label: 'Список компаний'},
              {to: '', label: 'Создание компании'},
            ]}

            back='/'
          />
        </div>

          <FormWrapper
            tabs={tabs}
            tab={tab}
            setTab={setTab}
            tabsHasError={tabsHasError}
          >
            <CreateFormFields
              show={tab === 'main'}
              nextOption='requisites'
              setTab={setTab}
            >
              <MainSettings
                data={data}
                updateField={updateField}
                errors={errors}
                domainsPrograms={domainsOptions}
                currentActiveDomainProgram={''}
              />
            </CreateFormFields>

            <CreateFormFields
              show={tab === 'requisites'}
              nextOption='loyalty'
              setTab={setTab}
            >
              <Requisites
                data={data}
                updateField={updateField}
                errors={errors}
              />
            </CreateFormFields>

            <CreateFormFields
              show={tab === 'loyalty'}
              nextOption='statuses'
              setTab={setTab}
            >
              <Loyalty
                data={data}
                updateField={updateField}
                errors={errors}
                loyaltyPrograms={loyaltyPrograms}
              />
            </CreateFormFields>

            <CreateFormFields
              show={tab === 'statuses'}
              nextOption='tags'
              setTab={setTab}
              classNameWrapper={'relative'}
            >
              <Statuses
                data={data}
                updateField={updateField}
                updateData={updateData}
                errors={errors}
                isEdit={false}
                requiredStatuses={requiredStatuses}
              />
            </CreateFormFields>

            <CreateFormFields
              show={tab === 'tags'}
              nextOption={'templates'}
              setTab={setTab}
              classNameWrapper={'relative'}
            >
              <Tags
                data={data}
                updateField={updateField}
                updateData={updateData}
                errors={errors}
                icons={ICONS_TAG}
              />
            </CreateFormFields>

            <CreateFormFields
              show={tab === 'templates'}
              onSubmit={handleSubmit}
              submitLabel='Создать компанию'
              submitLoading={loading}
            >
              <Templates
                data={data}
                updateField={updateField}
                errors={errors}
              />
            </CreateFormFields>
          </FormWrapper>
      </div>
    </>
  )
}
