const tabs = [
  {
    label: 'Основная информация',
    tag: 'base',
    required: true,
    fields: [
      'title',
      'link',
      'subtitle',
      'text',
      'channels',
      'sendAt',
      'priority',
      'image',
    ]
  },
  {
    label: 'Выборка клиентов',
    tag: 'clients',
    fields: [
      'sendToAll',
      'withTags',
      'withoutTags'
    ]
  },
]

export default tabs
