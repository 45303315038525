import React, {useEffect, useState} from "react";
import tooltipIcon from '../../../images/tooltip.svg'

//TODO: убрать во всех полях формы, где используется тултип showTooltip и использовать только textTooltip
export default function Tooltip(
  {
    textTooltip = '',
    defaultText = 'Нет информации',
    position = 'right'
  }) {
  const [positionClass, setPositionClass] = useState('bottom-[150%] left-[150%]')

  useEffect(() => {
    switch (position) {
      case 'top':
        setPositionClass('bottom-[150%] left-0')
        break
      case 'left':
        setPositionClass('bottom-[100%] right-[150%]')
        break
      case 'right':
        setPositionClass('bottom-[100%] left-[150%]')
        break
      case 'bottom':
        setPositionClass('top-[150%] left-0')
        break
    }
  }, [position])

  return (
    <div className={'group relative ml-2'}>
      <img src={tooltipIcon} alt=""/>
      <div
        style={{filter: 'drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.1))'}}
        className={`absolute ${positionClass} z-[99999] w-max max-w-[300px] scale-0 transition-all rounded bg-white p-2 text-xs text-black group-hover:scale-100`}>
        { textTooltip || defaultText }
      </div>
    </div>
  )
}
