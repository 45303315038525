import * as React from "react";
import { useEffect } from "react";

import InputLabel from "./common/Label";

type InputListItemTypeChildren<T> = ({ item, index }: { item: T, index: number }) => React.ReactNode;

type Props<T> = React.PropsWithChildren & React.HTMLProps<HTMLDivElement> & {
  items: Array<T>,
  addItem: () => void,
  deleteItem: (index: number) => void,
  isItemFulfilled: (item: T) => boolean,
  isItemDeleting: (item: T) => boolean
}

export default function InputList<T>(props: Props<T>) {
  const { children, items, addItem, deleteItem, isItemDeleting, isItemFulfilled, ...other } = props;

  let label : React.ReactNode | undefined;

  const isNeedAddItem = (items: T[]) => {
    const last = items.at(-1);

    if (! last) {
      return false;
    }

    return isItemFulfilled(last);
  }

  const removeItemsIfNeeded = () => {
    if (items.length === 1) {
      return;
    }

    items.forEach((item, index) => {
      if (index === items.length - 1) {
        return;
      }

      if (isItemDeleting(item)) {
        deleteItem(index)
      }
    })
  }

  useEffect(() => {
    if (isNeedAddItem(items)) {
      addItem();
    }

    removeItemsIfNeeded()
  }, [JSON.stringify(items)]);

  React.Children.forEach(children, (child) => {
    if (! React.isValidElement(child)) {
      return;
    }

    if (child.type === InputLabel) {
      label = child
    }
  });

  return (
    <div className={ 'flex flex-col gap-y-3' } { ...other }>
      { !! label && (<>{ label }</>) }

      { React.Children.map(children, child => {
        if (! (React.isValidElement(child) && child.type === InputList.Item)) {
          return;
        }

        return items.map((item, index) =>
          React.cloneElement(child, { item, index } as {})
        );
      }) }
    </div>
  );
}

InputList.Label = InputLabel;
InputList.Item = <T, >({ children, item, index }: {
  children: InputListItemTypeChildren<T>,
  item: T,
  index: number
}) => {
  return (<>{ children({ item, index }) } </>);
}