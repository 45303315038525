import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import BaseButton from "../../components/common/Button/BaseButton";
import FormWrapper from "../../components/common/form/FormWrapper";
import tabs from "./tabs";
import CreateFormFields from "../../components/common/form/CreateFormFields";
import Questions from "./Questions";
import Answers from "./Answers";
import SingleSimpleValueSelect from "../../components/common/form/select/SingleSimpleValueSelect";
import useForm from "../../hooks/useForm";
import initForm from "../FoodRating/initForm";
import {useBranchesContext} from "../../contexts/Branches";
import {NpsAnswerContextWrapper, NpsQuestionContextWrapper} from '../../contexts/NPS'
import {apiClient} from "../../libs/api/apiClient";
import {useCompaniesContext} from "../../contexts/Companies";
import RangeDatePicker from "../../components/common/form/datetime/RangeDatePicker";
import Export from "../../components/common/Export";
import { ExportBlocks } from "../../components/common/Export/helpers";

export default function Nps() {
  const {company} = useCompaniesContext()
  const [tab, setTab] = useState('questions')
  const [questions, setQuestions] = useState([])
  const {branches, branch} = useBranchesContext()
  const [applyFilter, setApplyFilter] = useState(false)

  useEffect(() => {
    if (company && branch) {
      apiClient.nps.questions.getAll(company.id, branch.id, '').then(({data, errors, message}: any) => {
        if (data) {
          setQuestions(data)
        }
      }).catch(() => {

      })
    }
  }, [company, branch])

  const {data, updateField} = useForm(JSON.parse(JSON.stringify(initForm)))

  return (
    <div className={"px-8 pb-8 pt-2"}>
      {
        !!branch ?
        <>
          <div className={"flex flex-col gap-y-6"}>
            {tab === 'answers'
              ? <>
                <div>
                  <div className={"text-gray-50 text-[44px] leading-[60px] mb-2"}>NPS</div>
                  <div className={"text-gray-40 text-base"}>Список ответов</div>
                </div>
                <div className={" flex flex-row items-center justify-between gap-x-3"}>
                  <div className={"w-1/2 flex gap-x-3"}>
                    <SingleSimpleValueSelect
                      name={'branches'}
                      placeholder={'Филиал 1'}
                      value={data.branches}
                      options={branches.map(({title, id}: any) => ({label: title, value: id}))}
                      onChange={(value: any) => {
                        updateField('branches', value)
                      }}
                      removeItem={true}
                      removeValue={() => {
                        updateField('branches', null)
                      }}
                    />
                    <SingleSimpleValueSelect
                      name={'questions'}
                      placeholder={'Все вопросы'}
                      value={data.questions}
                      options={questions.map(({text, id}) => ({label: text, value: id}))}
                      onChange={(value: any) => {
                        updateField('questions', value)
                      }}
                      removeItem={true}
                      removeValue={() => {
                        updateField('questions', null)
                      }}
                    />
                  </div>
                  <div className={"w-1/2 flex gap-x-3"}>
                    <RangeDatePicker
                      required={false}
                      values={data.createdAt || [null, null]}
                      placeholder='Выберите промежуток дат'
                      onChange={([startAt, endAt]: any) => {
                        updateField('createdAt', [startAt, endAt]);
                      }}
                      errors={{}}
                      name={'dateFilter'}
                      label={undefined}
                      textTooltip={undefined}
                    />
                    <BaseButton onClick={() => {
                      setApplyFilter(true)
                    }} className={"w-1/2 bg-interactive-elem text-white"}>Показать результат</BaseButton>
                  </div>
                </div>
                  <Export
                    title={'Экспорт ответов'}
                    types={
                      [
                        { title: 'Экспортировать', type: ExportBlocks.answers }
                      ]
                    }
                    filter={{
                      'createdAt': {
                        'after': data?.createdAt?.at(0) || null,
                        'before': data?.createdAt?.at(1) || null, 
                      },
                      'questions': data?.questions, 
                      'branches': data?.branches 
                    } || null}
                    order={null}
                  />
              </>
              : <div className={"flex justify-between items-end"}>
                <div>
                  <div className={"text-gray-50 text-[44px] leading-[60px] mb-2"}>NPS</div>
                  <div className={"text-gray-40 text-base"}>Список вопросов</div>
                </div>
                <Link to={'create-question'}>
                  <BaseButton className={"ml-5 bg-interactive-elem text-white"}>Добавить вопрос</BaseButton>
                </Link>
              </div>
            }
          </div>
          <div className={'mt-8'}>
            <FormWrapper
              tabs={tabs}
              tab={tab}
              setTab={setTab}
              heightForm={'min-h-[350px]'}
            >
              <CreateFormFields
                show={tab === 'questions'}
                setTab={setTab}
              >
                <NpsQuestionContextWrapper>
                  <Questions/>
                </NpsQuestionContextWrapper>
              </CreateFormFields>

              <CreateFormFields
                show={tab === 'answers'}
                setTab={setTab}
              >
                <NpsAnswerContextWrapper>
                  <Answers
                    data={data}
                    applyFilter={applyFilter}
                    setApplyFilter={setApplyFilter}
                  />
                </NpsAnswerContextWrapper>
              </CreateFormFields>

            </FormWrapper>
          </div>
        </> :
        <div>
          <div className={"text-gray-50 text-[44px] leading-[60px] mb-2"}>NPS</div>
          <div className="text-gray-40 text-3xl text-center mt-[35vh]">
            Выберите филиал
          </div>
        </div>
      }
    </div>
  )
}
