import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import BaseButton from "../../components/common/Button/BaseButton";
import FormWrapper from "../../components/common/form/FormWrapper";
import CreateFormFields from "../../components/common/form/CreateFormFields";
import tabs from "./tabs";
import {createColumnHelper} from "@tanstack/react-table";
import {Mailing} from "./types";
import {getDateAndTime} from "../../libs/helpers/datetime";
import DataTable from "../../components/common/Table/DataTable";
import {useMailingsArchiveContext, useMailingsContext} from "../../contexts/Mailings";
import {useBranchesContext} from "../../contexts/Branches";
import fetchChannels from "../../providers/dictionaries/mailings";

export default function Mailings() {
  const [tab, setTab] = useState('active')
  const {mailings, pagination, filters, updateMailings, loading}: any = useMailingsContext()
  const {archiveMailings, pagination: archivePagination, filters: archiveFilters, updateArchiveMailings, loading: archiveLoading}: any = useMailingsArchiveContext()
  const {branch} = useBranchesContext()
  const [channels, setChannels] = useState<any>([])

  useEffect(() => {
    fetchChannels().then((data) => {
      setChannels(data)
    }).catch(() => {})
  }, [])

  const columnHelper = createColumnHelper<Mailing>()

  const columns = [
    columnHelper.accessor(row => row.id, {
      id: 'id',
      header: () => 'ID',
      cell: info => <div
        className={'w-[5%] font-normal break-all flex items-center ml-5'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[5%] flex justify-start text-center ml-5',
        sortField: 'createdAt'
      }
    }),
    columnHelper.accessor(row => row.sendAt, {
      id: 'sendAt',
      header: () => 'Дата',
      cell: info => <div
        className={'w-[15%] font-normal break-all flex items-center ml-5'}>{getDateAndTime(info.getValue())}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[15%] flex justify-start text-center ml-5',
        sortField: 'sendAt'
      }
    }),
    columnHelper.accessor(row => row.image, {
      id: 'image',
      header: () => 'Фото',
      cell: info =>
        <div className={'mr-4 w-[15%] flex items-center justify-center text-center'}>
          {
            info.getValue() ?
              <img className='h-[60px] w-[60px] rounded-xl' src={info.getValue() || ''} alt="" />
              :
              <div className='h-[60px] w-[60px] flex items-center justify-center text-[10px] text-gray-40 border-[1.5px] border-btn-secondary rounded-xl'>Image</div>
          }
        </div>,
      enableSorting: false,
      meta: {
        widthClass: 'mr-4 w-[15%] flex items-center justify-center text-center'
      }
    }),
    columnHelper.accessor(row => row.title, {
      id: 'title',
      header: () => 'Заголовок',
      cell: info => <div
        className={'w-[20%] font-normal break-all flex items-center px-2'}>
        <Link className={'text-interactive-elem'} to={`/mailings/${info.row?.original?.id}/edit`}>
          {info.getValue()}
        </Link>
      </div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[20%] flex justify-start text-center px-2 ',
      }
    }),
    columnHelper.accessor(row => row.recipientsCount, {
      id: 'recipientsCount',
      header: () => 'Получатели',
      cell: info => <div
        className={'font-normal break-all flex items-center ml-5 w-[10%]'}>{(!info.row?.original?.sentAt) && '~'} {info.getValue() || '0'}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[10%] flex justify-start text-center ml-5',
      }
    }),
    columnHelper.accessor(row => row.channels, {
      id: 'channels',
      header: () => 'Место размещения',
      cell: info => <div
        className={'font-normal flex items-center px-2 text-ellipsis w-[35%] break-word'}>{info.getValue() ? info.getValue()?.map((channel: any) => channels.find((item: any) => item.value == channel)?.label)?.join(', ') : ''}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[35%] flex justify-start text-center px-2',
      }
    }),
  ]

  if (!branch) {
    return <div>
      <div className="px-8 pb-8 pt-2">
        <div className="flex flex-col justify-between">
          <div>
            <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Рассылки</div>
            <div className="text-gray-40 text-base">Список рассылок</div>
          </div>
          <div className="text-gray-40 text-3xl text-center mt-[35vh]">
            Выберите филиал
          </div>
        </div>
      </div>
    </div>
  }

  return (
    <div className="px-8 pb-8 pt-2">
      <div className="flex flex-col">
        <div className="flex justify-between items-end">
          <div>
            <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Рассылки</div>
            <div className="text-gray-40 text-base">Список рассылок</div>
          </div>
          <div className={'flex flex-row justify-end items-center'}>
            <Link to={'create'}>
              <BaseButton className={"ml-5 bg-interactive-elem text-white"}>Добавить рассылку</BaseButton>
            </Link>
          </div>
        </div>

      </div>
      <div className={'mt-8'}>

        <FormWrapper
          tabs={tabs}
          tab={tab}
          setTab={setTab}
          heightForm={'min-h-[350px]'}
        >
          <CreateFormFields
            show={tab === 'active'}
            setTab={setTab}
          >
            <DataTable
              data={mailings || []}
              columns={columns}
              updateData={(params: any) => updateMailings(params, filters)}
              pagination={pagination}
              loading={loading}
              useMassAction={false}
              usePagination={true}
              defaultLimit={10}
              defaultPage={1}
            />
          </CreateFormFields>

          <CreateFormFields
            show={tab === 'archive'}
            setTab={setTab}
          >
            <DataTable
              data={archiveMailings || []}
              columns={columns}
              updateData={(params: any) => updateArchiveMailings(params, archiveFilters)}
              pagination={archivePagination}
              loading={archiveLoading}
              useMassAction={false}
              usePagination={true}
              defaultLimit={10}
              defaultPage={1}
            />
          </CreateFormFields>

        </FormWrapper>
      </div>
    </div>
  )
}
