import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import BaseButton from '../../components/common/Button/BaseButton'
import { useCompaniesContext } from '../../contexts/Companies'
import { useBranchesContext } from '../../contexts/Branches'
import { useCategoriesContext } from '../../contexts/Categories'
import { createColumnHelper } from '@tanstack/react-table'
import DataTable from "../../components/common/Table/DataTable";
import deleteImg from '../../images/delete.svg'
import pencilImg from '../../images/pencil.svg'
import { apiClient } from '../../libs/api/apiClient';
import Actions from "../../components/common/Table/Actions";
import { showErrorToast, showSuccessToast } from "../../libs/helpers/toasts";
import { dialogModal } from "../../components/common/Modal/DialogModal";
import SingleSimpleValueSelect from "../../components/common/form/select/SingleSimpleValueSelect";
import useForm from "../../hooks/useForm";
import { submitAction } from "./massActions";
import SimpleMultipleValueSelect from "../../components/common/form/select/multiple/SimpleMultipleValueSelect";

type CategoryData = {
  id: number,
  title: string,
  branchesCount: number | null,
  position: number | null,
  platforms: Array<string> | null
}

export default function Categories() {
  const { company }: any = useCompaniesContext()
  const { branch, branches }: any = useBranchesContext();
  const { categories, updateCategories, updatePagination, updateFilters, pagination, loading }: any = useCategoriesContext()

  const [search, setSearch] = useState('')
  const [applyFilter, setApplyFilter] = useState(false)
  const [action, setAction] = useState<null | string>(null)

  const { data, updateField, updateData, submit, changedFieldsRefresh } = useForm({
    categoriesIds: []
  })

  //not working
  const submitMassAction = () => {
    if (action === null) return
    submit((form: any) => {
      submitAction(() => {
        showSuccessToast({
          content: 'Действие выполнено успешно'
        })
        changedFieldsRefresh()
        updateData({
          categoriesIds: []
        })
        setAction(null)
      }, action, 'categories', company.id, branch.id, form)
    })
  }

  const removeCategory = (categoryId: any) => {
    dialogModal({
      isOpen: false,
      title: 'Внимание!',
      text: 'Вы уверены что хотите удалить раздел?',
      showCancelButton: true,
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Вернуться',
    }).then((result) => {
      if (result.isConfirmed) {
        apiClient.categories.remove(company?.id, branch?.id, categoryId)
          .then(({ data, errors }: any) => {
            updateCategories()
            showSuccessToast({
              content: 'Раздел удален успешно',
            })
          }
          ).catch((err) => {
            showErrorToast({
              content: 'Что-то пошло не так'
            })
          })
      }
    }).catch(() => { })
  }

  const columnHelper = createColumnHelper<CategoryData>()

  const columns = [
    columnHelper.accessor(row => row.id, {
      id: 'ID',
      header: () => 'ID',
      cell: info => <div className={'w-10 flex items-center justify-center text-center'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-10 flex items-center justify-center text-center'
      }
    }),
    columnHelper.accessor(row => row.title, {
      id: 'title',
      header: () => 'Название',
      cell: info => <div className={'grow flex items-center'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'grow',
        sortField: 'title'
      }
    }),
    columnHelper.accessor(row => row.branchesCount, {
      id: 'branchesCount',
      header: () => 'Ф',
      cell: info => <div className={'max-w-[75px] grow flex items-center justify-center'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'max-w-[75px] grow justify-center',
        sortField: 'branchesCount'
      }
    }),
    columnHelper.accessor(row => row.position, {
      id: 'position',
      header: () => 'Сортировка',
      cell: info => <div className={'max-w-[160px] grow flex items-center justify-center'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'max-w-[160px] grow justify-center',
        sortField: 'position'
      }
    }),
    columnHelper.accessor(row => row?.platforms?.join(' / '), {
      id: 'platforms',
      header: () => 'Платформа',
      cell: info => <div className={'w-[190px] flex items-center'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[190px]',
        //sortField: 'platforms'
      }
    }),
    columnHelper.accessor(row => row.id, {
      id: 'action',
      header: () => '',
      cell: info =>
        <div className={'w-[72px] flex items-center'}>
          <Link to={`${info.getValue()}/edit`} className='w-1/2 flex item-center justify-center cursor-pointer'>
            <img src={pencilImg} alt="" />
          </Link>
          <div onClick={() => removeCategory(info.getValue())} className='w-1/2 flex item-center justify-center cursor-pointer'>
            <img src={deleteImg} alt="" />
          </div>
        </div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[72px]',
      }
    }),
  ]

  useEffect(() => {
    setApplyFilter(false)
  }, [branch])

  if (!branch) {
    return (<div className="px-8 pb-8 pt-2">
      <div className="flex justify-between items-end">
        <div>
          <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Каталог</div>
          <div className="text-gray-40 text-base">Список разделов</div>
        </div>
      </div>
      <div className="text-gray-40 text-3xl text-center mt-[35vh]">
        Выберите филиал
      </div>
    </div>)
  }

  if (!applyFilter && categories?.length === 0) 
    return (
      <div className="px-8 pb-8 pt-2">
        <div className="flex flex-col gap-y-6">
          <div className="flex justify-between items-end">
            <div>
              <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Каталог</div>
              <div className="text-gray-40 text-base">Список разделов</div>
            </div>
            {
              branch &&
              <Link to={'create'}>
                <BaseButton className={'bg-interactive-elem text-white'}>Добавить раздел</BaseButton>
              </Link>
            }
          </div>
        </div>
        <div className="text-gray-40 text-3xl text-center mt-[35vh]">
          У вас еще нет разделов
        </div>
      </div>
    )

  return (
    <div className="px-8 pb-8 pt-2">
      <div className="flex justify-between items-end">
        <div>
          <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Каталог</div>
          <div className="text-gray-40 text-base">Список разделов</div>
        </div>
      </div>
          <div>
            <Actions
              updateValue={(value: any) => {
                setSearch(value || '')
              }}
              fetchData={(value: any) => {
                setApplyFilter(true)
                updatePagination({ page: 1 })
                updateFilters('search', value || null, true)
              }}
              searchValue={search}
              classNameChildren={'w-1/2 flex items-center justify-end'}
            >
              {
                branch &&
                <Link to={'create'}>
                  <BaseButton className={'bg-interactive-elem text-white'}>Добавить раздел</BaseButton>
                </Link>
              }
            </Actions>
            <DataTable
              data={categories || []}
              columns={columns}
              usePagination={true}
              defaultLimit={10}
              defaultPage={1}
              pagination={pagination}
              updateData={(params: any) => { updateCategories(params) }}
              loading={loading}
              useMassAction={true}
              selectedRows={data.categoriesIds || []}
              onChangeSelectionRows={(values: any) => {
                updateField('categoriesIds', values)
              }}
              rowsWithCustomBg={categories.filter((category: any) => !category.isActive).map(({ id }: any) => ({ id, color: 'bg-error-main/[.5]' }))}
            />

            {data?.categoriesIds?.length > 0 &&
              <div className={"w-full rounded-xl bg-white px-3 py-5 my-10 flex items-center justify-between border-[1px] sticky bottom-0"}>
                <div className={"w-5/6 flex items-center justify-center gap-x-4"}>
                  <SingleSimpleValueSelect
                    name={'xs'}
                    placeholder={'Выберите действие'}
                    value={action}
                    options={[
                      { label: 'Выберите действие', value: null },
                      { label: 'Активировать', value: 'activate' },
                      { label: 'Деактивировать', value: 'deactivate' },
                      { label: 'Привязать к филиалу', value: 'attach' },
                      { label: 'Отвязать от филиала', value: 'detach' },
                    ]}
                    onChange={(option: any) => {
                      setAction(option)
                    }}
                  />

                  {(action === 'attach' || action === 'detach') &&
                    <SimpleMultipleValueSelect
                      label={undefined}
                      textTooltip={undefined}
                      options={branches.filter(({ id }: any) => id !== branch?.id).map((branch: { title: any; id: any; }) => ({ label: branch.title, value: branch.id }))}
                      value={data.branchesIds || []}
                      onChange={(value: any) => updateField('branchesIds', value)}
                      name={'branchesIds'}
                      placeholder={'Выберите филиалы'}
                      errors={{}}
                      className={'w-[350px]'}
                    />
                  }

                </div>

                <BaseButton onClick={submitMassAction}
                  className={"w-[250px] ml-5 bg-interactive-elem text-white"}>Изменить {data.categoriesIds?.length} раздел
                </BaseButton>
              </div>}

          </div>
    </div>
  )
}
