import React, {useEffect} from "react";
import DataTable from "../../../components/common/Table/DataTable";
import {createColumnHelper} from "@tanstack/react-table";
import {useOptionsGroupContext} from "../../../contexts/Options";
import {Link} from "react-router-dom";

export default function TableOptionsGroups({selected, setSelected, massUpdate, setMassUpdate}: any) {
  const {optionsGroups, updateOptionsGroups, limits, filters, loading, pagination}: any = useOptionsGroupContext();

  useEffect(() => {
    if (massUpdate) {
      updateOptionsGroups(limits, filters)
      setMassUpdate(false)
    }
  }, [massUpdate])

  const columnHelper = createColumnHelper<any>()

  const columns = [
    columnHelper.accessor(row => row.id, {
      id: 'id',
      header: () => 'ID',
      cell: info => <div
        className={'w-[70px] font-normal break-all flex items-center mx-2'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[70px] flex justify-start text-center mx-2',
      }
    }),
    columnHelper.accessor(row => row.article, {
      id: 'article',
      header: () => 'Артикул',
      cell: info => <div
        className={'w-[100px] font-normal break-all flex items-center mr-3'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[100px] flex justify-start text-center mr-3',
        sortField: 'article'
      }
    }),
    columnHelper.accessor(row => row.innerTitle, {
      id: 'innerTitle',
      header: () => 'Название в CRM',
      cell: info => <div
        className={'w-2/5 font-normal break-words flex justify-start items-center mr-3 text-interactive-text'}>
        <Link to={info.row?.original?.id != null ? `/options/${info.row?.original?.id}/edit-options-group` : '/options'}>
          {info.getValue()}
        </Link>
      </div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-2/5 flex justify-start text-center mr-3',
        sortField: 'innerTitle'
      }
    }),
    columnHelper.accessor(row => row.title, {
      id: 'title',
      header: () => 'Публичное название',
      cell: info => <div
        className={'w-2/5 font-normal break-words flex justify-start items-center mr-3 text-interactive-text'}>
        <Link to={info.row?.original?.id != null ? `/options/${info.row?.original?.id}/edit-options-group` : '/options'}>
          {info.getValue()}
        </Link>
      </div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-2/5 flex justify-start text-center ',
        sortField: 'title'
      }
    }),
    columnHelper.accessor(row => row.branchesCount, {
      id: 'branchesCount',
      header: () => 'Ф',
      cell: info => <div
        className={'w-[60px] font-normal break-all flex items-center justify-center'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[60px] flex justify-center',
      }
    }),
    columnHelper.accessor(row => row.minSelect, {
      id: 'minSelect',
      header: () => 'Мин',
      cell: info => <div
        className={'min-w-[80px] font-normal flex items-center justify-center'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'min-w-[80px] flex justify-start  text-center',
        sortField: 'minSelect'
      }
    }),
    columnHelper.accessor(row => row.maxSelect, {
      id: 'maxSelect',
      header: () => 'Макс',
      cell: info => <div
        className={'min-w-[100px] font-normal flex items-center justify-center'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'min-w-[100px] flex justify-start text-center',
        sortField: 'maxSelect'
      }
    }),
  ]

  return (
      <DataTable
        data={optionsGroups}
        columns={columns}
        usePagination={true}
        defaultLimit={5}
        defaultPage={1}
        pagination={pagination}
        updateData={(params: any) => updateOptionsGroups(params)}
        loading={loading}
        useMassAction={true}
        selectedRows={selected}
        onChangeSelectionRows={(values: any) => setSelected(values)}
        classNameTable={'mt-0'}
        rowsWithCustomBg={optionsGroups.filter((group: any) => !group.isActive).map(({id}: any) => ({id, color: 'bg-error-main/[.5]'}))}
      />
  )
}
