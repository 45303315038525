import React, {useEffect, useReducer} from "react";
import SingleSelectButton from "../../../../../components/common/form/select/conditions/SingleSelectButton";
import BaseButton from "../../../../../components/common/Button/BaseButton";
import {v4 as uuid} from "uuid"
import SingleSimpleValueSelect from "../../../../../components/common/form/select/SingleSimpleValueSelect";
// @ts-ignore
import trashImg from "../../../../../images/delete.svg"
import { renderField } from "../helpers/renderFields";

interface IConditionsProps {
  conditions: ConditionType,
  value?: object|null,
  errors?: any,
  className?: string,
  onChange: Function,
  loading?:boolean,
  initialConditionOptions?: any[]
}

type ConditionType = {
  terms: object,
  definitions: object
  inputs?: object
  filters?: object
}

interface IData {
  id: string,
  data: any,
  children: Array<IData>,
  type: string
}

enum Actions {
  and = 'AND',
  or = 'OR'
}

export default function ConditionSelect(
  {
    conditions,
    value,
    errors,
    onChange = (value: any) => {},
    initialConditionOptions
  }: IConditionsProps
) {
  const [localData, setLocalData] = useReducer((state: any, updates: any) => ({...state, ...updates}), {
    id: uuid(),
    data: {
      isGroup: true,
      body: {
        logicOperator: Actions.and
      }
    },
    children: [],
    type: 'group'
  });

  useEffect(() => {
    if (value) {
      setLocalData(value)
    }
  }, [value])


  const customUpdate = (data: IData, [after, before]: any, names = ['before', 'after']) => {
    const temp = Object.assign({}, data)
    setPropByName(temp.data.body, names[1] || 'after', after)
    setPropByName(temp.data.body, names[0] || 'before', before)
    setLocalData(localData)
    onChange(localData)
  }

  const getPropByName = (obj: object, prop: string): any => {
    if(typeof obj === 'undefined' || !prop) {
      return null
    }

    const _index = prop.indexOf('.')
    if(_index > -1) {
      return getPropByName(obj[prop.substring(0, _index)], prop.substring(_index + 1));
    }

    return obj[prop]
  }

  const setPropByName = (obj: any, prop: string, value: any): void => {
    if(typeof obj === 'undefined') {
      obj = value
      return
    }

    const _index = prop.indexOf('.')
    if(_index > -1) {

      if (typeof obj[prop.substring(0, _index)] === 'undefined') {
        obj[prop] = value
        return
      }

      return setPropByName(obj[prop.substring(0, _index)], prop.substring(_index + 1), value)
    }

    obj[prop] = value
    return
  }

  //получение списка условий
  const getItems = (children: Array<any>) => {
    let result: any[] = []
    children.forEach((child: string) => {
      if (child in conditions.terms) {
        result.push(conditions.terms[child])
      }
    })

    return result;
  }

  //добавление выборанного условия
  const addCondition = (data: IData, type: any) => {
    let newCond: IData = {
      id: uuid(),
      data: {
        isGroup: conditions.terms[type].isGroup,
        body: {
          logicOperator: conditions.terms[type].isGroup ? Actions.and : null,
        }
      },
      children: [],
      type: type
    }

    const definition = conditions?.definitions[type]?.inputs?.find((input: any) => input.name == 'operator')

    if (definition && 'items' in definition && definition?.items.length > 0) {
      newCond.data.body.operator = definition?.items.length > 0 ? definition?.items[0]?.value : null
    }

    if ('children' in data && data.children instanceof Array) {
      data.children.push(newCond)
    } else {
      data.children = [newCond]
    }
    setLocalData({...localData})
    onChange({...localData})
  }

  //удаление условия или группы
  const removeBlock = (data: IData) => {
    findAndRemove(localData, data.id)
  }

  const updateField = (data: IData, value: any, field = '') => {
    const temp = Object.assign({}, data)
    setPropByName(temp.data.body, field, value)
    //temp.data.value = value
    setLocalData({...localData})
    onChange({...localData})
  }

  //рекурсивно ищем блок по айди и удаляем
  const findAndRemove = (data: IData, id: any) => {
    if (!('children' in data) || data.children === null) {
      return
    }

    const elem = data?.children.find((data: IData) => data?.id == id)

    if (!elem) {
      data?.children.forEach((child: IData) => findAndRemove(child, id))
      return
    }

    data?.children.splice(data?.children.findIndex(data => data?.id === id), 1);

    setLocalData(localData)
    onChange(localData)
  }

  const renderDefault = (text: any, data: IData) => {
    const definition = conditions?.definitions[data.type] || []
    //какая то проверка на смену инпутов, если изменилось то надо проверить дату и удалить поля которых нет для текущего выбора
    return (
      <div className={'relative flex flex-col items-center gap-y-3'}>
        <div className={'flex flex-row justify-between items-center w-full'}>
          <div>{text}</div>
          <BaseButton
            onClick={() => removeBlock(data)}
            className={'bg-white border-[1px] h-[42px] !py-0 border-funnels-green rounded-lg'}>
            <img className={'w-5 h-5'} src={trashImg} alt=""/>
          </BaseButton>
        </div>

        {
          definition &&
          'inputs' in definition &&
          definition?.inputs?.length > 0 &&
          definition?.inputs?.map((type: any) => (
            <div className={'w-full'}>
              {renderField(type, data, conditions, updateField, errors, customUpdate, definition)}
            </div>
          ))
        }
      </div>
    )
  }

  const removeField = (data: IData, field = '') => {
    const temp = Object.assign({}, data)
    delete temp.data.body[field]

    setLocalData({ ...localData })
    onChange({ ...localData })
  }

  //рендер элемента в зависимости от его типа
  const renderItem = (data: IData) => {
    const title = conditions.terms[data.type]?.title
    return data.data.isGroup ? <></> : renderDefault(title, data)
  }

  //рекурсивный рендер блоков
  const renderBlock = (data: IData, removable = true) => {
    const definition = conditions?.definitions[data.type] || []

    return (
      <div key={data.id} className={`flex flex-col min-w-[200px] ${removable ? 'p-2 border-[1px] border-gray-20 rounded-xl gap-y-2' : 'gap-y-4 py-2'}`}>
        {removable && data?.data?.isGroup && 
          <div className={'flex flex-col gap-y-2'}>
            <div className={'flex flex-row items-center justify-between gap-x-[5px]'}>
              <span>{conditions.terms[data.type]?.title || 'Условие'}</span>
              <div>
                <SingleSimpleValueSelect
                  className={''}
                  name={'xs'}
                  placeholder={'Добавить условие'}
                  value={''}
                  options={getItems(conditions.terms[data.type]?.children || []).map(({ title, name }: any) => ({ label: title, value: name }))}
                  onChange={(option: any) => {
                    addCondition(data, option)
                  }}
                />
              </div>
              <SingleSelectButton
                className={'text-sm h-[42px]'}
                value={data?.data?.body?.logicOperator}
                items={[
                  { title: 'Всем условиям', value: 'AND' },
                  { title: 'Любому из условий', value: 'OR' }
                ]}
                onChange={(value: any) => {
                  const temp = Object.assign({}, data)
                  temp.data.body.logicOperator = value.value
                  setLocalData(localData)
                  onChange(localData)
                }}
              />
              <BaseButton onClick={() => removeBlock(data)} className={'bg-white border-[1px] h-[42px] !py-0 border-funnels-green rounded-lg'}>
                <img className={'w-5 h-5'} src={trashImg} alt="" />
              </BaseButton>
            </div>
            {
              definition &&
              'inputs' in definition &&
              definition?.inputs?.length > 0 &&
              definition?.inputs?.map((type: any) => (
                <div className={'w-full'}>
                  {renderField(type, data, conditions, updateField, errors, customUpdate, removeField, definition)}
                </div>
              ))
            }
          </div>
        }

        {
          'children' in data &&
          data?.children instanceof Array &&
          data.children.length > 0 &&
          data.children.map((children) => (
            renderBlock(children)
          ))
        }
        {renderItem(data)}
      </div>
    )
  }

  return (
    <div className={``}>
      <div className={'flex flex-col gap-y-4'}>
        <SingleSimpleValueSelect
          className={''}
          name={''}
          placeholder={'Добавить условие'}
          label={'Условия'}
          textTooltip={'Условия'}
          value={''}
          options={initialConditionOptions ? getItems(initialConditionOptions).map(({title, name}: any) => ({label: title, value: name})) : getItems(conditions.terms[localData.type]?.children || []).map(({title, name}: any) => ({label: title, value: name}))}
          onChange={(value: any) => {
            addCondition(localData, value)
          }}
          howMushShowItems={5}
        />
        <SingleSimpleValueSelect
          className={'text-sm'}
          value={localData.data?.body?.logicOperator}
          options={[
            {label: 'Всем условиям', value: 'AND'},
            {label: 'Любому из условий', value: 'OR'}
          ]}
          label={'Должно выполняться'}
          textTooltip={'Должно выполняться'}
          onChange={(value: any) => {
            const temp = Object.assign({}, localData)
            temp.data.body.logicOperator = value
            setLocalData(localData)
            onChange(localData)
          }}
        />
      </div>

      {conditions?.terms && Object.values(localData).length > 0 && localData?.children?.length > 0 && renderBlock(Object.assign({}, localData), false)}
    </div>
  )
}
