// 

const initForm = {
  isActive: true,
  branches: [],
  __branches_select: [],
  openHours: {
    monday: {
      active: true,
      hours: ['00:00-24:00']
    },
    tuesday: {
      active: true,
      hours: ['00:00-24:00']
    },
    wednesday: {
      active: true,
      hours: ['00:00-24:00']
    },
    thursday: {
      active: true,
      hours: ['00:00-24:00']
    },
    friday: {
      active: true,
      hours: ['00:00-24:00']
    },
    saturday: {
      active: true,
      hours: ['00:00-24:00']
    },
    sunday: {
      active: true,
      hours: ['00:00-24:00']
    },
  },
  platforms: ['site', 'ios', 'vk', 'android'],
  isDividedByBranches: false
}

export default initForm