import React, {useId, useState} from "react";
import BaseButton from "../../../components/common/Button/BaseButton";
import SingleSimpleValueSelect from "../../../components/common/form/select/SingleSimpleValueSelect";
import Textarea from "../../../components/common/form/input/Textarea";
import ImageLoadMultiple from "../../../components/common/form/image/ImageLoadMultiple";

export default function BaseReviews({data, errors, updateField, reviewEstimations, updateFieldNoRender}: any) {

  const id = useId()

  return (
    <div className={"flex flex-col px-3 gap-y-6"}>

      <div className={"border-[1px] border-gray-20 rounded-xl p-4"}>
        <label htmlFor={id} className="flex items-center mb-2 font-medium text-sm">
          Статус отзыва
        </label>
        <div className={"flex h-[35px] w-full"}>
          <BaseButton onClick={() => {updateField('review.status', 'moderating'); delete errors['review.status']}}
            className={`w-1/2 ${data?.review?.status === 'moderating' ? "bg-interactive-elem text-white" : "bg-gray-30 text-gray-40"} rounded-r-none`}>На модерации</BaseButton>
          <BaseButton onClick={() => {updateField('review.status', 'published'); delete errors['review.status']}}
            className={`w-1/2 ${data?.review?.status === 'published' ? "bg-interactive-elem text-white" : "bg-gray-30 text-gray-40 "} rounded-none`}>Опубликован</BaseButton>
          <BaseButton onClick={() => {updateField('review.status', 'hidden'); delete errors['review.status']}}
            className={`w-1/2 ${data?.review?.status === 'hidden' ? "bg-error-main text-white" : "bg-gray-30 text-gray-40 "} rounded-l-none`}>Скрыт</BaseButton>
        </div>

        {
          !!errors['review.status']?.at(0) &&
          <span className={'text-error-font text-xs font-medium'}>{errors['review.status'].at(0)}</span>
        }
      </div>

      <SingleSimpleValueSelect
        label={'Оценка'}
        name={'review.estimation'}
        placeholder={'Выберите вариант'}
        value={data?.review?.estimation}
        options={reviewEstimations}
        onChange={(option: any) => {updateField('review.estimation', option)}}
        errors={errors}
      />

      <Textarea
        label='Текст отзыва'
        textTooltip='Текст отзыва'
        placeholder='Введите текст'
        name={'review.text'}
        value={data?.review?.text || ''}
        onChange={value => updateFieldNoRender('review.text', value.trim().length !== 0 ? value : "")}
        errors={errors}
        required={false}
      />

      <Textarea
        label='Ответ на отзыв'
        textTooltip='Ответ на отзыв'
        placeholder='Введите текст'
        name={'review.answer'}
        value={data?.review?.answer || ''}
        onChange={value => updateFieldNoRender('review.answer', value.trim().length !== 0 ? value : "")}
        errors={errors}
      />

      <ImageLoadMultiple
        label="Фотографии клиента (формат: JPG, JPEG, PNG. Можно загрузить до 4 изображений)"
        onChange={(value: any) => {
          updateField('review.images', value)
        }}
        onDelete={(value: any) => {
          if (value?.id) {
            if (data?.review?.detachedImages) {
              return updateField('review.detachedImages', [...data?.review?.detachedImages, value?.id])
            }
            return updateField('review.detachedImages', [value?.id])
          }
        }}
        value={data?.review?.images || []}
        errors={errors}
        name='review.images'
        max={4}
        classNameImgWrapper={'!h-[88px]'}
      />
    </div>
  )
}
