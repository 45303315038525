import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import Base from "../form/Base"
import BaseButton from "../../../components/common/Button/BaseButton";
import {ProductsContextWrapper} from "../../../contexts/Products";
import useForm from "../../../hooks/useForm";
import initForm from "../initForm";
import fetchPlatforms from "../../../providers/dictionaries/platforms";
import {useBranchesContext} from "../../../contexts/Branches";
import {CategoriesContextWrapper} from "../../../contexts/Categories";
import {CustomersTagsContextWrapper} from "../../../contexts/Tags";
import {apiClient} from "../../../libs/api/apiClient";
import {useNavigate, useParams} from "react-router-dom";
import {useCompaniesContext} from "../../../contexts/Companies";
import {showErrorToast, showSuccessToast} from "../../../libs/helpers/toasts";
import {dialogModal} from "../../../components/common/Modal/DialogModal";
import {useFormContext} from "../../../contexts/Form";

export default function EditStock() {
  const {company} = useCompaniesContext()
  const { branch, branches } = useBranchesContext()
  const {checkIsFormDirty, openDialog,  needUpdate, setNeedUpdate } = useFormContext()

  const [platforms, setPlatforms] = useState([])
  const [loading, setLoading] = useState<boolean>(true)


  const {data, updateData, updateField, updateFieldNoRender, errors, updateErrors, changedFieldsRefresh, submit} = useForm(JSON.parse(JSON.stringify(initForm)))
  const navigate = useNavigate()
  const {id: stockId} = useParams<{id?: string}>()

  useEffect(() => {
    fetchPlatforms().then(data => {
      setPlatforms(data.map(platform => ({ label: platform.label, name: platform.value })))
    })

  }, [])


  useEffect(() => {
    getStock()
  }, [company, branch])

  const reset = () => {
    navigate('/stocks')
  }

  const getStock = () => {
    if (company && branch) {
      setLoading(true)

      apiClient.stocks.get(company.id, branch.id, stockId).then(({data, errors}: any) => {
        if (data) {
          updateData(data)
        } else {
          updateErrors(errors || {})
          showErrorToast({
            content: 'Что-то пошло не так'
          })
        }
        setLoading(false)
      }).catch((err) => {
        setLoading(false)
      })
    }
  }

  const removeStock = () => {
    dialogModal({
      isOpen: false,
      title: 'Внимание!',
      text: 'Вы уверены что хотите удалить акцию?',
      showCancelButton: true,
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Вернуться',
    }).then((result) => {
      if (result.isConfirmed) {
        apiClient.stocks.remove(company.id, branch.id, stockId)
          .then(({data, errors}: any) => {
            showSuccessToast({
              content: "Акция успешно удалена",
            })
              navigate('/stocks')
            }
          ).catch((err) => {
          updateErrors(errors || {})
          //updateData(company)
          showErrorToast({
            content: 'Что-то пошло не так'
          })
        })
      }
    }).catch(() => {})
  }

  const handleSubmit = () => {
    submit(async (form: any) => {
      //TODO: надо сделать так чтобы прокидывался пустой массив. Сейчас они тупо затираются на этапе генерации формДаты, но тогда надо изменять на бэке чтоб на всех массивах принималось null или []
      apiClient.stocks.update(company?.id, branch?.id, stockId,{body: form}).then(({data, errors}: any) => {
        if (data) {
          showSuccessToast({
            content: "Акция успешно изменена",
          })
          changedFieldsRefresh()
          getStock()
        } else {
          updateErrors(errors || {});

          showErrorToast({
            content: 'Что-то пошло не так'
          })

        }
      }).catch(() => { }).finally(() => setNeedUpdate(false))
    }, 'changed')
  }

  useEffect(() => {
    if (needUpdate) {
      handleSubmit()
    }
  }, [needUpdate])

  if (loading) return (
    <div className="text-gray-40 text-3xl text-center mt-[35vh]">
      <span>Загрузка...</span>
    </div>
  )

  return (
    <div className='px-8 pb-8 pt-2 text-gray-50'>
      <div className='mb-8'>
        <div className='text-[44px] leading-[60px] mb-2'>Акции</div>
        <Breadcrumbs
          links={[
            {to: `/stocks`, label: 'Список акций'},
            {to: '', label: 'Редактирование акции'},
          ]}
          back={`/stocks`}
        />
      </div>
      <div className={'rounded-xl bg-white py-5'}>
        <ProductsContextWrapper>
          <CategoriesContextWrapper>
            <CustomersTagsContextWrapper>
              <Base
                errors={errors}
                data={data}
                updateField={updateField}
                updateFieldNoRender={updateFieldNoRender}
                branches={branches.map((branch: any) => ({label: branch.title, value: branch.id}))}
                platforms={platforms}
              />
            </CustomersTagsContextWrapper>
          </CategoriesContextWrapper>
        </ProductsContextWrapper>
        <div className={'mt-6 border-gray-10 border-y-[1px] flex justify-between z-[50] w-full sticky bottom-0 bg-white'}>
          <BaseButton className={`bg-red-500 border-red-500 border-[2px] text-white m-4 py-[7px] ${company && branch ? '' : 'hidden'}`} onClick={removeStock}>Удалить</BaseButton>
          <div className={'flex flex-row items-center'}>
            <BaseButton
              className={'border-red-500 border-[2px] text-red-500  m-4 py-[7px] '}
              onClick={(e: any) => {
                if (checkIsFormDirty()) {
                  e.preventDefault()
                  openDialog('/stocks')

                }else reset()
              }}
            >
              Отмена
            </BaseButton>
            <BaseButton
              className={'w-[225px] bg-interactive-elem text-white m-4 py-[7px] '}
              onClick={handleSubmit}
            >
              Сохранить
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  )
}
