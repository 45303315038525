import React from "react";
import SingleSimpleValueSelect from "../../../../../components/common/form/select/SingleSimpleValueSelect";
import BaseButton from "../../../../../components/common/Button/BaseButton";
import ColorPickerSelect from "../../../../../components/common/form/color/ColorPickerSelect";
import RangeDatePicker from "../../../../../components/common/form/datetime/RangeDatePicker";
import ImageLoad from "../../../../../components/common/form/image/ImageLoad";
import TextEditor from "../../../../../components/common/form/input/TextEditor";
import TextInput from "../../../../../components/common/form/input/TextInput";
import TextInputMultipleList from "../../../../../components/common/form/input/TextInputMultipleList";
import Textarea from "../../../../../components/common/form/input/Textarea";
import ConditionSelect from "../select/ConditionSelect";
import SimpleDatePicker from "../../../../../components/common/form/datetime/SimpleDatePicker";
import DropDownComment from "../../../../../components/common/Comment/DropDown";
import Checkbox from "../../../../../components/common/form/checkbox/Checkbox";
import { customSelectModal } from "../../../../../components/common/Modal/Funnels/CustomSelectModal";

export enum FieldsType {
    singleSelect = "single-select",
    dateRange = "date-range",
    date = "date",
    time = "time",
    number = "number",
    text = "text",
    multiSelect = "multiple-select",
    image = 'image',
    color = 'color',
    textarea = 'textarea',
    boolean = 'boolean',
    array = 'array',
    conditions = 'conditions'
}

interface IData {
    id: string,
    data: any,
    children: Array<IData>,
    type: string
}

const showSelectModal = (isMultyple: boolean = true, data: IData, name: string, options: any, currValue: any, updateField = (data: any, value: any, name: any) => { }, action: any = null, headers: any = {'value': 'ID', 'title': 'Название' }) => {
    customSelectModal({
        classNameWrapper: '!max-w-[1000px] min-w-[350px] w-[60%]',
        isOpen: false,
        title: 'Выбрать значения',
        showCancelButton: false,
        confirmButtonText: 'Выбрать',
        cancelButtonText: 'Вернуться',
        allowOutsideClick: false,
        value: currValue || '',
        fields: headers || {},
        isMultiply: isMultyple,
        valueIdentifier: 'value',
        requestUrl: action?.filterUri,
        filtersData: action?.filters || [],
        items: options || []
    }).then((result) => {
        if (result.isConfirmed) {
            updateField(data, result.value, name)
        }
    }).catch(() => { })

}

const getPropByName = (obj: object, prop: string): any => {
    if (typeof obj === 'undefined' || !prop) {
        return null
    }

    const _index = prop.indexOf('.')
    if (_index > -1) {
        return getPropByName(obj[prop.substring(0, _index)], prop.substring(_index + 1));
    }

    return obj[prop]
}

const renderSingleSelectField = (data: IData, type: any, updateField = (data: any, value: any, name: any) => {}, errors = {}, action: any = null) => {

    if ('selectingInModal' in type && type?.selectingInModal) {
        let currValue = getPropByName(data?.data?.body, type?.name || 'value') || null
        let error = null

        if (data.id in errors && type?.name in errors[data.id]) {
            error = errors[data.id][type?.name][0]
        }

        return (
            <div className={`flex flex-col w-full`}>
                <div className={`flex items-center mb-2 font-medium text-sm`}>
                    {type?.label}
                </div>
                <div className={`w-full flex flex-row flex-wrap items-center ${currValue ? 'gap-x-3' : ''}`}>
                    {
                        currValue && <div className={'flex flex-row flex-wrap items-center gap-x-2'}>
                            {
                                type?.items?.find((option: any) => currValue == option?.value)?.title
                            }
                        </div>
                    }
                    {
                        (type?.items && type?.items?.length > 0) ?
                        <BaseButton onClick={() => showSelectModal(false, data, type?.name || 'value', type?.items, currValue, updateField, action, type?.tableHeaders)}
                            className={'py-[7px] bg-interactive-elem text-white cursor-pointer rounded-lg'}>
                            {currValue ? 'Изменить' : 'Выбрать'}
                        </BaseButton> :
                        <span className={'py-[7px] px-[10px] bg-interactive-elem/[.45] text-white rounded-lg'}>
                            Нет данных для выбора
                        </span>
                    }
                    {
                        error && <span className={'text-error-font font-medium text-xs'}>{error}</span>
                    }
                </div>
            </div>
        )
    }

    return (
        <SingleSimpleValueSelect
            value={getPropByName(data?.data?.body, type?.name)}
            options={type?.items?.map(({ title, value }: any) => ({ label: title, value }))}
            onChange={(value: any) => {
                updateField(data, value, type?.name)
            }}
            className={''}
            label={type?.label || ''}
            textTooltip={type?.tooltip ? type?.tooltip : type?.label || ''}
            required={type?.required || false}
            errors={errors[data.id] || {}}
            name={`${type?.name}`}
        />
    )
}

const renderBooleanField = (data: IData, type: any, updateField = (data: any, value: any, name: any) => { }, errors = {},) => {
    const currValue = getPropByName(data?.data?.body, type?.name);

    if (typeof currValue !== 'boolean' && !(0 === currValue || 1 === currValue)) {
        updateField(data, !!currValue, type?.name)
    }

    return (
        <div className="flex flex-col gap-y-2">
            <div className={'flex flex-row items-center gap-x-3'}>
                <Checkbox
                    className={'text-sm h-[42px]'}
                    value={!!currValue}
                    label={type?.label || ''}
                    onChange={(value: any) => {
                        updateField(data, value, type?.name);
                    }} 
                    name={`${data?.id}-${type?.name}`}                
                />
            </div>
            {type?.spoiler && renderSpoiler(type?.spoiler)}
        </div>
    )
}

const renderTextAreaField = (data: IData, type: any, updateField = (data: any, value: any, name: any) => { }, errors = {},) => {

    if ('formatted' in type) {
        return <div className="flex flex-col gap-y-2">
            <TextEditor
                label={type?.label || ''}
                textTooltip={type?.label || ''}
                placeholder={type?.label || ''}
                value={getPropByName(data?.data?.body, type?.name)}
                onChange={(value: any) => updateField(data, value, type?.name)}
                errors={errors[data.id] || {}}
                name={`${type?.name}`}
            />
            {type?.spoiler && renderSpoiler(type?.spoiler)}
        </div>
    }

    return (
        <div className="flex flex-col gap-y-2">
            <Textarea
                value={getPropByName(data?.data?.body, type?.name)}
                onChange={(value: any) => {
                    if (!value) {
                        delete data.data.body[type?.name]
                        return
                    }
                    updateField(data, value, type?.name);
                }}
                className={''}
                name={`${type?.name}`}
                maxLength={undefined}
                label={type?.label || ''}
                required={type?.required}
                errors={errors[data.id] || {}}
            />
            {type?.spoiler && renderSpoiler(type?.spoiler)}
        </div>
    )
}

const renderImageField = (data: IData, type: any, updateField = (data: any, value: any, name: any) => { }, errors = {},) => {

    return (
        <div className="flex flex-col gap-y-2">
            <ImageLoad
                label={type?.label || 'Загрузка изображения'}
                errors={errors[data.id] || {}}
                name={`${type?.name}`}
                defaultValue={getPropByName(data?.data?.body, type?.name)}
                onInput={(value: any) => updateField(data, value, type?.name)}
                onRemove={() => {
                    updateField(data, null, type?.name)
                }}
                required={type?.required || false}
                isBase64={true}
            />
            {type?.spoiler && renderSpoiler(type?.spoiler)}
        </div>
    )
}

const renderColorField = (data: IData, type: any, updateField = (data: any, value: any, name: any) => { }, errors = {},) => {
    const currValue = getPropByName(data?.data?.body, type?.name)

    if (!currValue) {
        updateField(data, '#61A0FF', type?.name)
    }

    return (
        <div className="flex flex-col gap-y-2">
            <ColorPickerSelect
                label={type?.label || 'Выбор цвета'}
                textTooltip={'Нет информации'}
                name={`${type?.name}`}
                placeholder={'Синий #61A0FF'}
                defaultColor={currValue || '#61A0FF'}
                value={currValue}
                onChange={(value) => updateField(data, value, type?.name)}
                className={''}
                required={type?.required || false}
                errors={errors[data.id] || {}}
            />
            {type?.spoiler && renderSpoiler(type?.spoiler)}
        </div>

    )
}

const renderConditionField = (data: IData, action: any, conditions: any, updateField = (data: any, value: any, name: any) => { }, errors = {},) => {
    let error = null

    if (data.id in errors && action.name in errors[data.id]) {
        error = errors[data.id][action.name][0]
    }

    return (
        <div className={`w-full p-2 border-[1px] flex flex-col gap-y-2 border-gray-20 rounded-lg ${error ? 'border-funnels-red' : 'border-gray-20'}`}>
            <span className={'text-xs font-medium text-error-font'}>{error}</span>
            <ConditionSelect
                initialConditionOptions={action?.items}
                value={getPropByName(data?.data?.body, action?.name)}
                onChange={(value: any) => updateField(data, value, action?.name)}
                conditions={conditions}
                errors={errors}
            />
        </div>
    )
}

const renderDateTimeField = (data: IData, type: any, updateField = (data: any, value: any, name: any) => { }, errors = {},) => {
    //const name = names?.length ? parseName(names[0]) : 'value'

    return (
        <div className={'w-full'}>
            <SimpleDatePicker
                value={getPropByName(data?.data?.body, type?.name)}
                onChange={(date: Date) => {
                    updateField(data, date ? date.toLocaleDateString() : null, type?.name)
                }}
                errors={errors[data.id] || {}}
                name={`${type?.name}`}
                label={type?.label || ''}
                textTooltip={type?.label || ''}
                required={type?.required || false}
                dateFormat={'dd.MM.yyyy'}
                placeholder={'Выберите дату'}
            />
            {type?.spoiler && renderSpoiler(type?.spoiler)}
        </div>
    )
}

const renderDateTimeRangeField = (data: IData, type: any, updateField: any = () => { }, errors = {},) => {

    return (
        <div className="flex flex-col gap-y-2">
            <RangeDatePicker
                classNameInput={'w-full'}
                values={[getPropByName(data?.data?.body, type?.name[1] || 'after'), getPropByName(data?.data?.body, type?.name[0] || 'before')] || [null, null]}
                label={type?.label || ''}
                textTooltip={type?.label || ''}
                placeholder='Выберите промежуток дат'
                onChange={([after, before]: any) => {
                    updateField(data, [after, before], type?.name)
                }}
                errors={errors[data.id] || {}}
                name={`${data.id}`}
                required={type?.required || false}
            />
            {type?.spoiler && renderSpoiler(type?.spoiler)}
        </div>
        
    )
}

const renderMultiSelectField = (data: IData, type: any, updateField = (data: any, value: any, name: any) => { }, errors = {}, action: any = null) => {
    let currValue = getPropByName(data?.data?.body, type?.name || 'value') || []
    let error = null

    if (data.id in errors && type?.name in errors[data.id]) {
        error = errors[data.id][type?.name][0]
    }

    if (!(currValue instanceof Array)) {
        currValue = [currValue]
        updateField(data, currValue, type?.name)
    }

    return (
        <div className={`w-full flex flex-row flex-wrap items-center ${(currValue && currValue instanceof Array && currValue.length > 0) ? 'gap-x-3' : ''}`}>
            <div className={'flex flex-row flex-wrap items-center gap-x-2'}>
                {
                    currValue &&
                    currValue instanceof Array &&
                    type?.items?.filter((option: any) => currValue?.map((x: any) => (String(x)))?.includes(String(option?.value)))
                        .map(({ title, value }: any) => (`${title} (${value})`))
                        .join(', ')
                }
            </div>
            {
            (type?.items && type?.items?.length > 0) ?
                <BaseButton onClick={() => showSelectModal(true, data, type?.name || 'value', type?.items, currValue, updateField, action, type?.tableHeaders)}
                    className={'py-[7px] bg-interactive-elem text-white cursor-pointer rounded-lg'}>
                    {(currValue && currValue instanceof Array && currValue.length > 0) ? 'Изменить' : 'Выбрать'}
                </BaseButton> :
                <span className={'py-[7px] px-[10px] bg-interactive-elem/[.45] text-white rounded-lg'}>
                    Нет данных для выбора
                </span>
            }
            {
                error && <span className={'text-error-font font-medium text-xs'}>{error}</span>
            }
        </div>
    )
}

const renderTextInputField = (data: IData, type: any, inputType: any = 'number', updateField = (data: any, value: any, name: any) => { }, errors = {},) => {
    //const name = names?.length ? parseName(names[0]) : 'value'

    let value = getPropByName(data?.data?.body, type?.name)

    return (
        <div className="flex flex-col gap-y-2">
            <TextInput
                min={inputType == 'number' ? 0 : undefined}
                errors={errors[data.id] || {}}
                name={`${type?.name}`}
                type={inputType}
                className={''}
                value={value || value === 0 ? value : ''}
                onChange={(value: any) => {
                    if (!value) {
                        delete data.data.body[type?.name]
                        return
                    }
                    updateField(data, value, type?.name)
                }}
                label={type?.label || ''}
                textTooltip={type?.label || ''}
                required={type?.required || false}
            />
            {type?.spoiler && renderSpoiler(type?.spoiler)}
        </div>
    )
}

const renderArrayField = (data: IData, type: any, updateField = (data: any, value: any, name: any) => { }, errors = {},) => {
    //const name = names?.length ? parseName(names[0]) : 'value'

    if (!type?.properties || (type?.properties instanceof Array && type?.properties.length == 0)) {
        return <></>
    }

    return (
        <div className="flex flex-col gap-y-2">
            <TextInputMultipleList
                name={`${type?.name}`}
                values={getPropByName(data?.data?.body, type?.name) || []}
                fields={type?.properties.map(({ name }: any) => (name))}
                labels={type?.properties.map(({ label }: any) => (label))}
                fieldTypes={type?.properties.map(({ input }: any) => {
                    if (input == FieldsType.singleSelect) {
                        return 'select'
                    }
                    
                    if (input == FieldsType.multiSelect) {
                        return FieldsType.multiSelect;
                    }

                    return 'input'
                })}
                types={type?.properties.map(({ input }: any) => {
                    if (input == FieldsType.number) {
                        return 'number'
                    }
                    return 'text'
                })}
                textTooltips={type?.properties.map(({ label }: any) => (label))}
                onChange={(value: any) => {
                    updateField(data, value, type?.name)
                }}
                required={type?.required || false}
                label={type?.label || ''}
                textTooltip={type?.label || ''}
                options={type?.properties.map(({ items }: any) => {
                    if (items) {
                        return items.map(({ title, value }: any) => ({ label: title, value }))
                    }
                    return []
                })}
                className={'w-full !border-0 !p-0'}
                errors={errors[data.id] || {}}
                hasSelectSameElements={true}
            />
            {type?.spoiler && renderSpoiler(type?.spoiler)}
        </div>
    )
}

const renderSpoiler = (spoiler: any) => {
    return <DropDownComment title={'test'} height={'300px'}><p>{spoiler}</p></DropDownComment>
}

export const renderField = (
    type: any, 
    data: IData, 
    conditions: any = null, 
    updateField = (data: any, value: any, name: any) => { }, 
    errors: any = {}, 
    customUpdate: any = () => { }, 
    action: any
    ) => {

    if ('show' in type) {
        let show = true

        Object.entries(type.show).forEach(([key, value]: any) => {
            if (getPropByName(data?.data?.body, key) != value) {
                delete data.data.body[type?.name]
                show = false
            }
        })

        if (!show)
            return <></>
    }

    /**
     * Тут костыль для необязательного поля условий
     * При удалении всех условий надо все затереть, чтобы не упала ошибка валидации на поле children
     */
    if (type?.name === 'conditions' && !getPropByName(data?.data?.body, 'conditions')?.children?.length) {
        delete data.data.body[type?.name]
    }

    switch (type.input) {
        case FieldsType.date:
            return renderDateTimeField(data, type, updateField, errors)
        case FieldsType.multiSelect:
            return renderMultiSelectField(data, type, updateField, errors, action)
        case FieldsType.singleSelect:
            return renderSingleSelectField(data, type, updateField, errors, action)
        case FieldsType.dateRange:
            return renderDateTimeRangeField(data, type, customUpdate, errors)
        case FieldsType.number:
            return renderTextInputField(data, type, 'number', updateField, errors)
        case FieldsType.text:
            return renderTextInputField(data, type, 'text', updateField, errors)
        case FieldsType.color:
            return renderColorField(data, type, updateField, errors)
        case FieldsType.time:
            return renderTextInputField(data, type, 'time', updateField, errors)
        case FieldsType.textarea:
            return renderTextAreaField(data, type, updateField, errors)
        case FieldsType.image:
            return renderImageField(data, type, updateField, errors)
        case FieldsType.boolean:
            return renderBooleanField(data, type, updateField, errors)
        case FieldsType.array:
            return renderArrayField(data, type, updateField, errors)
        case FieldsType.conditions:
            return renderConditionField(data, type, conditions || {terms: {}, definitions: {}}, updateField, errors)
        default:
            return <></>
    }
}
