import React, { useEffect, useState } from "react";
import TextInput from "../../../components/common/form/input/TextInput";
import DataTable from "../../../components/common/Table/DataTable";
import {createColumnHelper} from "@tanstack/react-table";
import SingleSimpleValueSelect from "../../../components/common/form/select/SingleSimpleValueSelect";
import BaseButton from "../../../components/common/Button/BaseButton";
import RangeDatePicker from "../../../components/common/form/datetime/RangeDatePicker";
import {useBranchesContext} from "../../../contexts/Branches";
import { useVkClientsEventsContext } from "../../../contexts/VkClientsEvents";
import Export from "../../../components/common/Export";
import { ExportBlocks } from "../../../components/common/Export/helpers";
import { apiClient } from "../../../libs/api/apiClient";

export default function VkClients() {
  const {vkClientsEvents, updateClients, pagination, loading, updateFilters, filters } = useVkClientsEventsContext()
  const [dateFilter, setDateFilter] = useState([new Date().toDateString(), new Date().toDateString()])
  const [applyFilter, setApplyFilter] = useState(false)
  const [searchIdVk, setSearchIdVk] = useState('')
  const [eventTypes, setEventTypes] = useState<{title: string, value: string, code: string}[]>([])
  const [selectEventFilter, setSelectEventFilter] = useState<null | string>(null)


  useEffect(() => {
    apiClient.dictionaries.appEventsTypes()
      .then(({data}: any) => {
        setEventTypes(data)
      })
  }, [])

  useEffect(() => {
    setApplyFilter(true)
    updateFilters('filter', {
      'createdAt][after': dateFilter[0], 
      'createdAt][before': dateFilter[1], 
      'vkId': searchIdVk? searchIdVk : null, 
      'event': selectEventFilter? selectEventFilter : null
    })
  }, [dateFilter, searchIdVk, selectEventFilter])

  const columnHelper = createColumnHelper<any>()

  const columns = [
    columnHelper.accessor(row => row.customerId, {
      id: 'customerId',
      header: () => 'ID',
      cell: info => <div
        className={'w-[10%] font-normal break-all flex items-center ml-4'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[10%] flex ml-4',
        sortField: 'customerId'
      }
    }),
    columnHelper.accessor(row => row.customerName, {
      id: 'customerName',
      header: () => 'Имя',
      cell: info => <div
        className={'w-[25%] font-normal grow flex items-center break-all ml-2'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[25%] ml-2',
        sortField: 'customerName'
      }
    }),
    columnHelper.accessor(row => row.uid, {
      id: 'uid',
      header: () => 'ID VK',
      cell: info => <div
        className={'w-[26%] break-all flex items-center ml-[2%] '}>
        {info.getValue()}
      </div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[26%] flex text-center ml-[2%]',
        sortField: 'uid'
      }
    }),
  
    columnHelper.accessor(row => row.createdAt, {
      id: 'createdAt',
      header: () => 'Дата/Время',
      cell: info => <div
        className={'w-[20%] mr-[20px] font-normal grow flex items-center break-words'}>{info.getValue()}</div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[20%] mr-[20px] text-center',
        sortField: 'createdAt'
      }
    }),
    columnHelper.accessor(row => row.event, {
      id: 'event',
      header: () => 'Событие',
      cell: info => <div className={'w-full font-normal grow flex flex-col justify-center  break-words'}>
        {info.getValue()}: 
        {info.row?.original?.data &&
          <div>{info.row?.original?.data}</div>
        }
      
      </div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-full',
      }
    }),
  ]

  const {branch} = useBranchesContext()
  
  if (!branch) {
    return <div>
      <div className="px-8 pb-8 pt-2">
        <div className="flex flex-col justify-between">
          <div>
            <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Клиенты</div>
            <div className="text-gray-40 text-base">Посетители ВК</div>
          </div>
          <div className="text-gray-40 text-3xl text-center mt-[35vh]">
            Выберите филиал
          </div>
        </div>
      </div>
    </div>
  }

  return (
    <div className="px-8 pb-8 pt-2">
      <div className="flex flex-col gap-y-6">
        <div className="flex justify-between items-end">
          <div>
            <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Клиенты</div>
            <div className="text-gray-40 text-base">Посетители ВК</div>
          </div>
        </div>
        <div className={"flex items-center gap-x-4"}>
          <div className={'w-[360px]'}>
            <SingleSimpleValueSelect
              label={'Фильтр'}
              name={'xs'}
              placeholder={'Все события'}
              value={selectEventFilter || ''}
              options={[{label: 'Все события', value: 'all-events'},...eventTypes.map(type => {
                return {label: type.title, value: type.value}
              })]}
              onChange={option => {
                if (option === 'all-events') {
                  setSelectEventFilter(null)
                  return
                }
                setSelectEventFilter(option)
              }}
            />
          </div>
          <div className={'w-[250px] flex flex-col'}>
            <div className={'w-full'}>
              <RangeDatePicker
                label={'Промежуток дат'}
                required={false}
                values={dateFilter}
                placeholder='Выберите промежуток дат'
                onChange={([startAt, endAt]: any) => {
                  setDateFilter([startAt, endAt])
                }}
                errors={{}}
                name={'dateFilter'}
              />
            </div>
          </div>
          <div className={'w-[270px]'}>
            <TextInput
              name={''}
              errors={{}}
              value={searchIdVk}
              onChange={(value: string) => setSearchIdVk(value)}
              label={'Ссылка или ID VK пользователя'}
              placeholder={'https://vk.com/durov'}
            />
          </div>
          <BaseButton onClick={() => {
            updateClients({...filters, page: 1})}
          } className={"bg-interactive-elem text-white mt-[25px]"}>Найти</BaseButton>

        </div>
        <Export
          title={'Экспорт клиентов'}
          types={[{ title: 'Экспортировать', type: ExportBlocks.customersVkEvents }]}
          filter={{ ...filters, limit: null, page: null }}
        // order={pagination?.order}
        />
        {
          vkClientsEvents?.length > 0 ?
            <>
              <DataTable
                data={vkClientsEvents}
                columns={columns}
                usePagination={true}
                defaultLimit={10}
                defaultPage={1}
                pagination={pagination}
                updateData={(params) => updateClients(params)}
                loading={loading}
                classNameTable={'mt-0'}
              />
            </>

            : <div></div>
        }
      </div>
    </div>

  )
}
