import React, {useEffect, useState} from "react";
import TextInput from "../../../components/common/form/input/TextInput";
import DateTimePicker from "../../../components/common/form/datetime/DateTimePicker";
import CheckboxList from "../../../components/common/form/checkbox/CheckboxList";
import ImageLoad from "../../../components/common/form/image/ImageLoad";
import SingleSimpleValueSelect from "../../../components/common/form/select/SingleSimpleValueSelect";
import TextEditor from "../../../components/common/form/input/TextEditor";
import fetchChannels from "../../../providers/dictionaries/mailings";
import {dialogModal} from "../../../components/common/Modal/DialogModal";
import SimpleMultipleValueSelect from "../../../components/common/form/select/multiple/SimpleMultipleValueSelect";
import Textarea from "../../../components/common/form/input/Textarea";


export default function Base({data, updateField, errors, branches, updateFieldNoRender}: any) {

  const [channels, setChannels] = useState<any>([])

  useEffect(() => {
    fetchChannels().then((data) => {
      setChannels(data)
    }).catch(() => {})
  }, [])

  useEffect(() => {
    delete errors['priority']

  }, [data.priority])

  const showSmsChannelWarning = (callback: any) => {
    dialogModal({
      isOpen: false,
      title: 'Подтвердите действие',
      text: 'Для осуществления рассылки СМС сообщений необходимо заполнить поля интеграции. Отправленные сообщения тарифицируются на основании условий использования вашего смс сервиса. Одно СМС может содержать не более 70 символов, максимальное количество СМС в одной рассылке 8. Пожалуйста, обратите внимание, что существуют ограничения, установленные смс сервисами, на количество отправляемых сообщений в течение суток.',
      showCancelButton: true,
      confirmButtonText: 'Подтведить',
      cancelButtonText: 'Отменить',
      textClassName: 'max-w-[450px]'
    }).then((result) => {
      if (result.isConfirmed) {
        callback()
      }
    }).catch(() => {})
  }

  return (
    <div className={'flex flex-col px-3 gap-y-6'}>
      <CheckboxList
        label='Тип уведомления'
        name='channels'
        errors={errors}
        options={channels.map((platform: any) => ({ label: platform.label, name: platform.value }))}
        values={data.channels || []}
        onChange={(value: any) => {
          //TODO: убрать условие чтобы разблокировать выбор варианта email рассылки
          if (!data.channels.includes('email') && value.includes('email')) {
            return
          }

          if (!data.channels.includes('sms') && value.includes('sms')) {
            showSmsChannelWarning(() => updateField('channels', value))
          } else {
            updateField('channels', value)
          }
        }}
        required={true}
      />

      <TextInput
        label={'Название в CRM'}
        textTooltip={'Название в CRM'}
        placeholder={'Введите название в CRM'}
        required={true}
        errors={errors}
        value={data.title}
        onChange={(value: any) => updateFieldNoRender('title', value)}
        name={'title'}
      />

      {
        (data.channels.includes('lk') || data.channels.includes('push') || data.channels.includes('email')) &&
          <TextInput
            label={'Заголовок сообщения'}
            textTooltip={'Заголовок сообщения'}
            placeholder={'Введите заголовок сообщения'}
            required={true}
            errors={errors}
            value={data.subtitle}
            onChange={(value: any) => updateFieldNoRender('subtitle', value)}
            name={'subtitle'}
          />
      }

      {
        data.channels.some((value: any) => channels.map((channel: any) => (channel.value)).filter((channelValue: any) => channelValue !== 'push' && channelValue !== 'sms').includes(value)) && <TextEditor
          label="Основное сообщение"
          textTooltip="Введите основное сообщение"
          placeholder="Основное сообщение"
          value={data.text}
          onChange={(value: any) => updateFieldNoRender('text', value)}
          errors={errors}
          required={true}
          name={'text'}
        />
      }

      {
        (data.channels.includes('push') || data.channels.includes('lk')) &&
          <Textarea
            label='Сообщение для моб. пуша'
            textTooltip='Сообщение для моб. пуша'
            placeholder='Введите cообщение для моб. пуша'
            name={'pushText'}
            value={data.pushText}
            onChange={value => updateFieldNoRender('pushText', value)}
            errors={errors}
            showLength={true}
            maxLength={178}
          />
      }

      {
        data.channels.includes('sms') &&
          <Textarea
              label='Сообщение для смс'
              textTooltip='Сообщение для смс'
              placeholder='Введите cообщение для смс'
              name={'smsText'}
              value={data.smsText}
              onChange={value => updateFieldNoRender('smsText', value)}
              errors={errors}
              showLength={true}
              maxLength={536}
          />
      }

        <ImageLoad
          label='Изображение (формат: JPG, JPEG, PNG)'
          defaultValue={data.image}
          onInput={(value: null) => {
              if (typeof value !== 'undefined') {
                  if (value !== null) {
                      updateField('image', value)
                  }
              }
          }}
          name={'image'}
          onRemove={() => {
              updateField('image', null)
          }}
          errors={errors}
          required={false}
          resizeTo={[820, 820]}
        />

      {
        data.channels.includes('push') && <TextInput
          label={'Ссылка'}
          textTooltip={'Ссылка'}
          placeholder={'Введите ссылку, которая ведет на внутренние разделы приложения'}
          errors={errors}
          value={data.link}
          onChange={(value: any) => updateFieldNoRender('link', value)}
          name={'link'}
        />
      }

      <DateTimePicker
        value={data.sendAt || null}
        onChange={(date: Date) => updateField('sendAt', date)}
        errors={errors}
        name={'sendAt'}
        label={'Дата и время отправки'}
        textTooltip={'Дата и время отправки'}
        required={true}
        placeholder={'Выберите дату и время'}
      />

      <SimpleMultipleValueSelect
        label={'Филиалы'}
        textTooltip={'Филиалы'}
        options={branches.map((branch: any) => ({label: branch.title, value: branch.id}))}
        value={data.branches || []}
        onChange={(value: any) => updateField('branches', value)}
        name={'branches'}
        placeholder={'Выберите филиалы'}
        errors={errors}
      />

      <div className={`flex flex-col gap-y-4 rounded-xl border-[1px] p-4 ${errors['priority'] ? 'border-error-main' : 'border-gray-20'}`}>
        <div>Порядок оповещения</div>

        {errors['priority'] && (<span className={'text-error-font text-xs font-medium'}>{errors['priority']}</span>)}

        <SingleSimpleValueSelect
          label={'1. Приоритет'}
          name={'outputType1'}
          placeholder={'Селект'}
          value={data.priority?.at(0) || ''}
          options={channels.filter((platform: any) => data.channels?.includes(platform.value) && (!data.priority?.includes(platform.value) || platform.value == data.priority?.at(0)))}
          onChange={(value: any) => {
            let temp = data.priority.slice()
            temp[0] = value;
            updateField('priority', temp)
          }}
          errors={errors}
          required={false}
          removeItem={true}
          removeValue={() => {
            let temp = data.priority.slice()
            temp[0] = '';
            updateField('priority', temp)
          }}
        />

        <SingleSimpleValueSelect
          label={'2. Приоритет'}
          name={'outputType2'}
          placeholder={'Селект'}
          value={data.priority?.at(1) || ''}
          options={channels.filter((platform: any) => data.channels?.includes(platform.value) && (!data.priority?.includes(platform.value) || platform.value == data.priority?.at(1)))}
          onChange={(value: any) => {
            //addPriority(value, data.outputType1)
            let temp = data.priority.slice()
            temp[1] = value;
            updateField('priority', temp)
            //updateField('outputType1', value)
          }}
          errors={errors}
          required={false}
          removeItem={true}
          removeValue={() => {
            let temp = data.priority.slice()
            temp[1] = ''
            updateField('priority', temp)
          }}
        />

        <SingleSimpleValueSelect
          label={'3. Приоритет'}
          name={'outputType3'}
          placeholder={'Селект'}
          value={data.priority?.at(2) || ''}
          options={channels.filter((platform: any) => data.channels?.includes(platform.value) && (!data.priority?.includes(platform.value) || platform.value == data.priority?.at(2)))}
          onChange={(value: any) => {
            //addPriority(value, data.outputType1)
            let temp = data.priority.slice()
            temp[2] = value;
            updateField('priority', temp)
            //updateField('outputType1', value)
          }}
          errors={errors}
          required={false}
          removeItem={true}
          removeValue={() => {
            let temp = data.priority.slice()
            temp[2] = ''
            updateField('priority', temp)
          }}
        />

        <SingleSimpleValueSelect
          label={'4. Приоритет'}
          name={'outputType4'}
          placeholder={'Селект'}
          value={data.priority?.at(3) || ''}
          options={channels.filter((platform: any) => data.channels?.includes(platform.value) && (!data.priority?.includes(platform.value) || platform.value == data.priority?.at(3)))}
          onChange={(value: any) => {
            //addPriority(value, data.outputType1)
            let temp = data.priority.slice()
            temp[3] = value;
            updateField('priority', temp)
            //updateField('outputType1', value)
          }}
          errors={errors}
          required={false}
          removeItem={true}
          removeValue={() => {
            let temp = data.priority.slice()
            temp[3] = ''
            updateField('priority', temp)
          }}
        />

        <SingleSimpleValueSelect
          label={'5. Приоритет'}
          name={'outputType5'}
          placeholder={'Селект'}
          value={data.priority?.at(4) || ''}
          options={channels.filter((platform: any) => data.channels?.includes(platform.value) && (!data.priority?.includes(platform.value) || platform.value == data.priority?.at(4)))}
          onChange={(value: any) => {
            //addPriority(value, data.outputType1)
            let temp = data.priority.slice()
            temp[4] = value;
            updateField('priority', temp)
            //updateField('outputType1', value)
          }}
          errors={errors}
          required={false}
          removeItem={true}
          removeValue={() => {
            let temp = data.priority.slice()
            temp[4] = ''
            updateField('priority', temp)
          }}
        />

        <SingleSimpleValueSelect
          label={'6. Приоритет'}
          name={'outputType6'}
          placeholder={'Селект'}
          value={data?.priority?.at(5) || ''}
          options={channels.filter((platform: any) => data.channels?.includes(platform.value) && (!data.priority?.includes(platform.value) || platform.value == data.priority?.at(5)))}
          onChange={(value: any) => {
            //addPriority(value, data.outputType1)
            let temp = data.priority.slice()
            temp[5] = value;
            updateField('priority', temp)
            //updateField('outputType1', value)
          }}
          errors={errors}
          required={false}
          removeItem={true}
          removeValue={() => {
            let temp = data.priority.slice()
            temp[5] = ''
            updateField('priority', temp)
          }}
        />

      </div>
    </div>
  )
}
