import React, {useId, useState} from "react";

import Checkbox from "../../../components/common/form/checkbox/Checkbox";
import TextInput from "../../../components/common/form/input/TextInput";
import SingleSimpleValueSelect from "../../../components/common/form/select/SingleSimpleValueSelect";
import Textarea from "../../../components/common/form/input/Textarea";
import BaseButton from "../../../components/common/Button/BaseButton";
import HouseLocation from "../../../components/common/form/select/location/House";
import DateTimePicker from "../../../components/common/form/datetime/DateTimePicker";
import {SimpleDatatableSelect} from "../../../components/common/form/select/datatable/single/SimpleDataTableSelect";
import {createColumnHelper} from "@tanstack/react-table";
import RadioButton from "../../../components/common/form/radio/RadioButton";
import {usePromoContext} from "../../../contexts/Promo";
import CityLocation from "../../../components/common/form/select/location/City";
import PhoneInputMask from "../../../components/common/form/input/PhoneInputMask";

export default function BaseOrders({data, errors, updateField, updateFieldNoRender,statuses, paymentTypes, pickupPoints=[], promoTypes = [], promoStatuses = [], phoneInfo, edit = false}: any) {

  const [promoCodeId, setPromoCodeId] = useState(data.promoCodeId)
  const {promoCodes, updatePromoCodes, limits, filters, updateFilters}: any = usePromoContext()
  const id = useId()
  const columnHelper = createColumnHelper<any>()

  const columns = [
    columnHelper.accessor(row => row.id, {
      id: 'radio',
      header: () => '',
      cell: info => <RadioButton
        className={'flex items-center justify-center'}
        name={'__skuParentId__select'}
        value={promoCodeId === info.getValue()}
        onChange={(value: any) => {
          if (value) {
            setPromoCodeId(info.getValue())
          }
        }}
        errors={errors} />,
      enableSorting: false,
      meta: {
        widthClass: 'w-[20px] flex items-center justify-center text-center'
      }
    }),
    columnHelper.accessor(row => row.id, {
      id: 'ID',
      header: () => 'ID',
      cell: info => <div className={'w-[85px] flex items-center justify-center text-center'}>{ info.getValue() }</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[85px] flex items-center justify-center text-center'
      }
    }),
    columnHelper.accessor(row => row.code, {
      id: 'code',
      header: () => 'Код',
      cell: info =>
        <div className={'grow flex items-center'}>
          { info.getValue() }
        </div>,
      enableSorting: true,
      meta: {
        widthClass: 'grow',
        sortField: 'code'
      }
    }),
    columnHelper.accessor(row => row.title, {
      id: 'title',
      header: () => 'Название',
      cell: info =>
        <div className={'grow flex items-center'}>
          { info.getValue() }
        </div>,
      enableSorting: true,
      meta: {
        widthClass: 'grow',
        sortField: 'title'
      }
    }),
    columnHelper.accessor(row => row.type, {
      id: 'type',
      header: () => 'Тип',
      cell: info =>
        <div className={'w-[120px] flex items-center'}>
          { promoTypes.find(({value}: any) => value === info.getValue())?.label || '' }
        </div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[120px]',
        sortField: 'type'
      }
    }),
    columnHelper.accessor(row => row.status, {
      id: 'status',
      header: () => 'Статус',
      cell: info =>
        <div className={'w-[120px] flex items-center'}>
          { promoStatuses.find(({value}: any) => value === info.getValue())?.label || '' }
        </div>,
      enableSorting: true,
      meta: {
        widthClass: 'w-[120px]',
        sortField: 'status'
      }
    }),
  ]

  return (
    <div id={id} className={"flex flex-col px-3 gap-y-6"}>
      <Checkbox
        label={'Предзаказ'}
        name={'preorder'}
        onChange={(value: any) => updateField('preorder', value)}
        value={data.preorder}
        errors={errors}
      />
        <DateTimePicker
          value={data.createdAt || null}
          onChange={(date: Date) => updateField('createdAt', date)}
          errors={errors}
          name={'createdAt'}
          label={'Дата'}
          textTooltip={'Дата'}
          required={false}
          placeholder={'Выберите дату и время'}
          dateFormat={'dd.MM.yy HH:mm'}
        />

      <SingleSimpleValueSelect
        label={'Статус заказа'}
        name={'status'}
        textTooltip={'Статус заказа'}
        placeholder={'Выберите статус заказа'}
        value={data.status}
        options={statuses}
        onChange={(option: any) => {
          updateField('status', option)
        }}
        errors={errors}
        required={true}
      />

      <TextInput
        label={'Имя клиента'}
        textTooltip={'Имя клиента'}
        placeholder={'Введите имя'}
        name={'clientName'}
        value={edit ? data.customer?.name : data.clientName}
        onChange={(value: any) => {
          if (edit) {
            updateFieldNoRender('customer.name', value)
          }
          updateFieldNoRender('clientName', value)
        }}
        errors={errors}
        required={true}
      />
      <PhoneInputMask
        mask={phoneInfo ? `${phoneInfo?.code} ${phoneInfo?.mask}` : '+7 (___)___-__-__'}
        label='Телефон клиента'
        textTooltip='Телефон клиента'
        placeholder='Введите номер телефона клиента'
        name={'phone'}
        value={edit ? data.customer?.phone :data.clientPhone}
        onChange={(value: any) => {
          let field = 'clientPhone'
          if (edit) {
            field = 'customer.phone'
          }
          if (typeof value === 'string' && value.length <= (phoneInfo.code.length + phoneInfo.mask.length + 1)) {
            updateField(field, value)
            return
          } else if (typeof value === "string" && (value.length === phoneInfo?.code.length+1 || !value?.length)) {
            updateField(field, '')
            return
          }
        }}
        errors={errors}
        required={true}
      />
      <div className={"flex h-[35px] w-full"}>
        <BaseButton onClick={() => updateField('deliveryType', 'courier')}
                    className={`w-1/2 ${data.deliveryType === 'courier' ? 'bg-interactive-elem text-white' : 'bg-gray-30 text-gray-40'} rounded-r-none`}>Доставка</BaseButton>
        <BaseButton onClick={() => updateField('deliveryType', 'pickup')}
                    className={`w-1/2 ${data.deliveryType === 'pickup' ? 'bg-interactive-elem text-white' : 'bg-gray-30 text-gray-40'} rounded-l-none `}>Самовызов</BaseButton>
      </div>

      {data.deliveryType === 'pickup' &&
          <SingleSimpleValueSelect
              label={'Точка самовывоза'}
              name={'pickupPoint'}
              textTooltip={'Точка самовывоза'}
              placeholder={'Выберите точку самовывоза'}
              value={data.pickupPoint}
              options={pickupPoints}
              onChange={(option: any) => {
                updateField('pickupPoint', option)
              }}
              errors={errors}
              required={true}
          />
      }
      {data?.deliveryType === 'courier' &&
          <div className={'flex flex-col gap-4 rounded-2xl border-[1px] border-gray-20 p-4'}>
              <div>Адрес доставки</div>
              <CityLocation
                  value={data.deliveryCity}
                  onChange={value => {
                    updateField('deliveryCity', value)
                    updateField('deliveryAddress', '')
                    updateField('entrance', value)
                    updateField('deliveryFloor', value)
                    updateField('apartmentNumber', value)
                  }}
                  name='deliveryCity'
                  placeholder='Город'
                  textTooltip='Город'
                  label='Город'
                  required={false}
                  errors={errors}
              />
              {data?.deliveryCity && <div className={'flex flex-col gap-4'}>
                <HouseLocation
                  label={'Адрес доставки'}
                  textTooltip={'Адрес доставки'}
                  name={`deliveryAddress`}
                  value={data.deliveryAddress || ''}
                  onChange={(value) => {
                    updateField('deliveryAddress', value)
                  }}
                  placeholder={'Адрес доставки'}
                  errors={errors}
                  searchWithCity={false}
                  cityGuid={data?.deliveryCity?.guid}
                />
                  <div className={'flex justify-between items-end gap-x-1'}>
                      <TextInput
                          type={'number'}
                          min={'1'}
                          label={'Подъезд'}
                          textTooltip={'Подъезд'}
                          placeholder='Подъезд'
                          name={'entrance'}
                          value={data.entrance || ''}
                          onChange={(value: any) => {
                            updateFieldNoRender('entrance', value)
                          }}
                          errors={errors}
                      />
                      <TextInput
                          type={'number'}
                          min={'0'}
                          label={'Этаж'}
                          textTooltip={'Этаж'}
                          placeholder='Этаж'
                          name={'floor'}
                          value={data.floor || ''}
                          onChange={(value: any) => {
                            updateFieldNoRender('floor', value)
                          }}
                          errors={errors}
                      />
                      <TextInput
                          type={'number'}
                          min={'1'}
                          label={'Квартира'}
                          textTooltip={'Квартира'}
                          placeholder='Квартира'
                          name={'flat'}
                          value={data.flat || ''}
                          onChange={(value: any) => {
                            updateFieldNoRender('flat', value)
                          }}
                          errors={errors}
                      />
                  </div>
              </div>}

              {
                !!errors?.deliveryAddress?.at(0) &&
                <span className={'text-error-font text-xs font-medium'}>{errors?.deliveryAddress?.at(0)}</span>
              }
          </div>
      }

      {
        data.preorder ?
          <DateTimePicker
            value={data.preorderAt || null}
            onChange={(date: Date) => updateField('preorderAt', date)}
            errors={errors}
            name={'preorderAt'}
            label={'Дата предзаказа'}
            textTooltip={'Дата предзаказа'}
            required={false}
            placeholder={'Выберите дату и время предзаказа'}
            dateFormat={'dd.MM.yy HH:mm'}
          /> : <></>
      }
      <SingleSimpleValueSelect
        label={'Способ оплаты'}
        name={'paymentTypeCode'}
        textTooltip={'Способ оплаты'}
        placeholder={'Выберите способ оплаты'}
        value={data.paymentTypeCode}
        options={paymentTypes}
        onChange={(option: any) => {
          updateField('paymentTypeCode', option)
        }}
        errors={errors}
        required={true}
      />
      <TextInput
        type={'number'}
        min={'0'}
        label={'Введите количество персон'}
        textTooltip={'Введите количество персон'}
        placeholder='Введите количество персон'
        name={'personCount'}
        value={data.personCount}
        onChange={(value: any) => {
          updateFieldNoRender('personCount', value)
        }}
        errors={errors}
      />
      <Textarea
        label='Комментарий к заказу'
        textTooltip='Комментарий к заказу'
        placeholder='Введите текст'
        name={'comment'}
        value={data.comment || ''}
        onChange={value => updateFieldNoRender('comment', value.trim().length !== 0 ? value : null)}
        errors={errors} 
        maxLength={undefined}      
      />

      <SimpleDatatableSelect
        className={'w-full mb-4'}
        data={promoCodes || []}
        label={'Промокоды'}
        textTooltip={'Промокоды'}
        value={data.promoCodeId}
        errors={errors}
        name={'promoCodeId'}
        placeholder={'Выберите промокод'}
        removeItem={() => {
          updateField('promoCodeId', null)
        }}
        onSubmit={() => updateField('promoCodeId', promoCodeId)}
        updateData={(params: any) => {
          if ('search' in params) {
            updateFilters('search', params.search)
            delete params.search
          }
          updatePromoCodes(params, filters)
        }}
        columns={columns}
        resetFilters={() => {
          updateFilters('filter', null)
          updatePromoCodes(null, null)
          updateField('__tableCategories', '')
        }}
        buttonPlaceholder="Выбрать промокод"
      >
        <SingleSimpleValueSelect
          required={false}
          placeholder={'Все статусы'}
          errors={{}}
          name='__tableCategories'
          options={promoStatuses || []}
          value={data.__tableCategories || ''}
          onChange={(value: any) => {
            updateFilters('filter.status', value)
            updatePromoCodes(limits, filters)
            updateField('__tableCategories', value)
          }}
          removeValue={() => {
            updateFilters('filter.status', null)
            updatePromoCodes(limits, filters)
            updateField('__tableCategories', '')
          }}
          removeItem={true}
        />
      </SimpleDatatableSelect>

    </div>
  )
}
