import React, {useId, useEffect} from "react";

export default function Checkbox(
  {
    className = '',
    labelClassName= '',
    label='',
    name,
    value,
    onChange,
    errors = {},
    disabled = false,
    checkboxClassName = '',
    ...props
  }) {
  const id = useId();
  const error = errors[name]

  const handleChange = ({target}) => {
    onChange(target.checked)
  }

  useEffect(() => {
    delete errors[name];
  }, [value]);


  return (
    <div className={`${className}`}>
      <div className={`flex items-center cursor-pointer ${checkboxClassName}`}>
        <input type="checkbox" id={id} checked={value} name={name} onChange={handleChange} disabled={disabled} className={'hidden'} {...props}/>

        <label
          htmlFor={id}
          className={`flex items-center text-sm text-gray-90 font-normal cursor-pointer ${checkboxClassName} ${labelClassName} `}
        >
          <div className={`flex items-center justify-center w-[18px] h-[18px] border-[2px] border-gray-20 bg-white rounded-sm cursor-pointer ${label && 'mr-4 '} ${value && 'border-none !bg-interactive-elem '} ${checkboxClassName}` }>
            <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 7L5 11L15 1" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
          {label}
        </label>
      </div>
      {error && (<span className={`text-error-font text-xs font-medium`}>{error}</span>)}
    </div>
  )
}
