const tabs = [
  {
    label: 'Вопросы',
    tag: 'questions',
    fields: [
    ]
  },
  {
    label: 'Ответы',
    tag: 'answers',
    fields: [
    ]
  },

]

export default tabs
