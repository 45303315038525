import React, {useEffect} from "react";
import {createColumnHelper} from "@tanstack/react-table";
import DataTable from "../../../components/common/Table/DataTable";
import {getDateAndTime} from "../../../libs/helpers/datetime";
import {useNpsAnswerContext} from "../../../contexts/NPS";


export default function Answers({data, applyFilter, setApplyFilter}: any) {
  const columnHelper = createColumnHelper<any>()
  const {npsAnswers, updateNpsAnswers, filters, limits, updateFilters, loading, pagination}: any = useNpsAnswerContext()

  const columns = [
    columnHelper.accessor(row => row.createdAt, {
      id: 'createdAt',
      header: () => 'Дата',
      cell: info => <div
        className={"w-[20%] font-normal text-interactive-elem flex justify-center text-center items-center mx-3 cursor-pointer"}
        onClick={info.row.getToggleExpandedHandler()}
      >
        {getDateAndTime(info.getValue())}
        {
          /*info.row.getCanExpand() && (
            <div
              {...{
                onClick: info.row.getToggleExpandedHandler(),
                style: { cursor: 'pointer' },
              }}
            >
              {info.row.getIsExpanded() ? '👇' : '👉'}
            </div>
          )*/
        }
      </div>,
      enableSorting: true,
      meta: {
        widthClass: "w-[20%] flex justify-center mx-3",
        sortField: 'createdAt'
      }
    }),
    columnHelper.accessor(row => row?.order, {
      id: 'orderId',
      header: () => 'Заказ',
      cell: info => <div
        className={'w-[20%] font-normal break-all flex justify-center items-center mx-3'}>{info.getValue()?.id || 'Не найден'}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[20%] flex justify-center text-center mx-3',
      }
    }),
    columnHelper.accessor(row => row?.customer, {
      id: 'clientId',
      header: () => 'Клиент',
      cell: info => <div
        className={'w-[20%] font-normal break-all flex flex-col justify-center mx-2'}>
        {
          info.getValue() ?
            <div className={'flex flex-col justify-center'}>
              <p>
                {info.getValue()?.name || ''}
              </p>
              <p>
                {info.getValue()?.phone || ''}
              </p>
            </div> :
            <div>
              Неизвестно
            </div>
        }


      </div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[20%] flex justify-center text-center mx-2',
      }
    }),
    columnHelper.accessor(row => row.order, {
      id: 'questionsCount',
      header: () => 'Вопросы',
      cell: info => <div
        className={'w-[20%] font-normal break-all flex justify-center items-center mx-2'}>
        {info.getValue()?.questionnaire?.questionsCount || ''}
      </div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[20%] flex justify-center text-center mx-2',
      }
    }),
    columnHelper.accessor(row => row.answers?.length, {
      id: 'answers',
      header: () => 'Ответы',
      cell: info => <div
        className={'w-[20%] font-normal break-all flex justify-center items-center mx-2'}>
        {info.getValue()}
      </div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[20%] flex justify-center text-center mx-2',
      }
    }),
  ]

  const subRow = (info: any) => {
    return <div className={'flex flex-col items-center justify-center gap-y-4 p-4 w-full bg-gray-10'}>
      {
        info.row.original.answers &&
        info.row.original.answers instanceof Array &&
        info.row.original.answers.length > 0 ?
        info.row.original.answers.map(({question, answer}: any, index: number) =>
          <div className={'flex flex-row gap-x-4 w-full items-center'}>
            <div className={'flex flex-row gap-x-4 w-1/2 items-center'}>
              <div className={'px-2'}>Вопрос:</div>
              <div className={'px-2'}>{question?.text || 'Вопрос не определен'}</div>
            </div>
            <div className={'flex flex-row gap-x-4 w-1/2 items-center'}>
              <div className={'px-2'}>Ответ:</div>
              <div className={'px-2'}>{answer || '---'}</div>
            </div>
          </div>
        ) :
          <div className={'flex flex-row gap-x-4 w-full justify-center items-center'}>
            Нет информации
          </div>
      }
    </div>

  }

  useEffect(() => {
    if (applyFilter) {
      setApplyFilter(false)
      updateNpsAnswers(limits, filters)
    }
  }, [applyFilter])

  useEffect(() => {
    let filter = {
      createdAt: {
        after: data?.createdAt && data.createdAt.length ? data?.createdAt[0] : null,
        before: data?.createdAt && data.createdAt.length ? data?.createdAt[1] : null
      },
      branches: data?.branches || null,
      questions: data?.questions || null
    }
    updateFilters('filter', filter)
  }, [data])

  return (
    <div className={'w-full overflow-x-auto'}>
      <DataTable
        data={npsAnswers || []}
        columns={columns}
        usePagination={false}
        defaultLimit={10}
        defaultPage={1}
        pagination={pagination}
        updateData={(params: any) => updateNpsAnswers(params, filters)}
        loading={loading}
        classNameTable={'mt-0'}
        useExpanded={true}
        expandedComponent={(row: any) => subRow(row)}
       />
    </div>
  )
}
