const tabs = [
  {
    label: 'Основная информация',
    tag: 'base',
    required: true,
    fields: [
      'isActive',
      'image',
      'goods',
      'title',
      'article',
      'additionalPhotos',
      'position',
      'categories',
      'price',
      'oldPrice',
      'costPrice',
      'weight',
      'volume',
      'size',
      'foodValuePerHundredGram.energyValue',
      'foodValuePerHundredGram.protein',
      'foodValuePerHundredGram.fat',
      'foodValuePerHundredGram.carbohydrate',
      'foodValuePerPortion.energyValue',
      'foodValuePerPortion.protein',
      'foodValuePerPortion.fat',
      'foodValuePerPortion.carbohydrate',
      'shortDescription',
      'description',
      'goodsCanBeAddedToCart',
      'branches',
      'openHours',
      'platforms',
    ]
  },
  {
    label: 'Метки и Акции',
    tag: 'marks',
    fields: [
      'tags',
      'isTop',
      'canBuyForFree',
      'minCount',
      'maxDiscount',
      'maxBonusAccumulation',
      'maxBonusPayment',
      'skuParentId',
      'skuGroups',
      'optionsGroups',
      'addToOrder'
    ]
  },
  {
    label: 'Мета теги',
    tag: 'metaTags',
    fields: [
      'isDividedByBranches',
      'metaTitle',
      'metaKeywords',
      'metaDescription',
    ]
  },
  {
    label: 'Ограничение количества',
    tag: 'count',
    fields: [
      'stockBalance',
    ]
  },
]

export default tabs
