import React from "react";
import {TagsMultipleSelect} from "../../../components/common/form/select/datatable/tags/TagsMultipleSelect";
import {createColumnHelper} from "@tanstack/react-table";
import TagLabel from "../../../components/common/form/select/datatable/tags/TagLabel";
import {Tag} from "../types";
import {useCustomersTagsContext} from "../../../contexts/Tags";


export default function Clients({data, updateField, errors}: any) {
  const {customersTags, loading}: any = useCustomersTagsContext()
  const columnHelper = createColumnHelper<Tag>()

  const columns = [
    columnHelper.accessor(row => row.id, {
      id: 'ID',
      header: () => 'ID',
      cell: info => <div className={'w-[85px] flex items-center justify-center text-center'}>{info.getValue()}</div>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[85px] flex items-center justify-center text-center'
      }
    }),
    columnHelper.accessor(row => row.text, {
      id: 'title',
      header: () => 'Метки (Теги)',
      cell: info =>
        <TagLabel
          img={info?.row?.original?.icon || ''}
          textColor={info.row?.original?.color || ''}
          backgroundColor={info.row?.original?.background || ''}>
          {info.getValue()}
        </TagLabel>,
      enableSorting: false,
      meta: {
        widthClass: 'w-[64px] ml-4 flex items-center justify-center text-center'
      }
    }),
  ]

  return (
    <div className={'flex flex-col px-3 gap-y-6'}>
      <TagsMultipleSelect
        data={customersTags?.map(({title, ...o}: any) => ({text: title, ...o})).filter(({id}: any) => !data.withoutTags?.includes(id)) || []}
        values={data.withTags}
        errors={errors}
        name={'withTags'}
        placeholder={'Выберите метки'}
        updateValues={(values: any) => {
          updateField('withTags', values)
          if (!values.length) {
            updateField('emptyWithTags', true)
          }
        }}
        updateData={() => {
        }}
        columns={columns}
        buttonPlaceholder={'Изменить метки'}
        label={'Клиенты с метками'}
        textTooltip={'Клиенты с метками'}
        removeItem={(index: any) => {
          let options = data.withTags.slice()
          options.splice(index, 1)
          updateField('withTags', options)
        }}
        loading={loading}
      />

      <TagsMultipleSelect
        data={customersTags?.map(({title, ...o}: any) => ({text: title, ...o})).filter(({id}: any) => !data.withTags?.includes(id)) || []}
        values={data.withoutTags}
        errors={errors}
        name={'withoutTags'}
        placeholder={'Выберите метки'}
        updateValues={(values: any) => {
          updateField('withoutTags', values)
          if (!values.length) {
            updateField('emptyWithoutTags', true)
          }
        }}
        updateData={() => {
        }}
        columns={columns}
        buttonPlaceholder={'Изменить метки'}
        label={'Клиенты без меток'}
        textTooltip={'Клиенты без меток'}
        removeItem={(index: any) => {
          let options = data.withoutTags.slice()
          options.splice(index, 1)
          updateField('withoutTags', options)
        }}
        loading={loading}
      />
    </div>
  )
}
