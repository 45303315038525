import React from 'react'

import Senler from '../CreateBranch/Senler'
import ThModerator from '../CreateBranch/ThModerator'
import Revvy from "../CreateBranch/Revvy";

export default function Mailing({ data, updateField, errors, updateFieldNoRender }: any) {
  return (
    <div className={ 'flex flex-col px-3 gap-y-6' }>
      {
        <>
          <Senler
            data={ data }
            updateField={ updateField }
            errors={ errors }
          />

          <ThModerator
            data={ data }
            updateField={ updateField }
            errors={ errors }
          />

          <Revvy
            data={ data }
            updateField={ updateField }
            errors={ errors }
          >
          </Revvy>
        </>
      }
    </div>
  )
}
