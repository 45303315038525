import React, { useState } from "react";
import ChevronIcon from "../../icons/ChevronIcon";


export default function DropDownComment({ children, title, height='600px' }: any) {
  const [show, setShow] = useState(false)

  return (
    <div className="w-full flex flex-col border-[1px] border-interactive-elem rounded-lg px-3 py-2">
      <div className={`flex flex-row justify-between items-center cursor-pointer`} onClick={() => setShow(!show)}>
        <div className="text-sm text-interactive-text font-medium">Подробнее</div>
        <div className='h-full w-16 flex items-center justify-center'>
          <ChevronIcon color='interactive' className={`duration-300 ${show && 'rotate-180'}`} />
        </div>
      </div>
      <div className={`${show ? `max-h-[${height}]` : "max-h-0 invisible"} text-sm transition-all duration-300 ease-in-out overflow-hidden`}>
        {children}
      </div>
    </div>
  )
}