import React, { useEffect, useState } from 'react'
import TextInput from './../../../components/common/form/input/TextInput';
import TextInputsList from '../../../components/common/form/input/TextInputsList';
import { useCompaniesContext } from '../../../contexts/Companies';

import Frontpad from '../CreateBranch/Frontpad';
import IIKO from '../CreateBranch/IIKO';
import Partner from '../CreateBranch/Partner';
import fetchLocationSources from '../../../providers/dictionaries/locationSources';
import SingleSimpleValueSelect from '../../../components/common/form/select/SingleSimpleValueSelect';
import MobiDel from "../CreateBranch/MobiDel";
import SBISPresto from "../CreateBranch/SBISPresto";

export default function Integrations({ data, updateField, errors, updateFieldNoRender }: any) {
  const { company } = useCompaniesContext()

  let iikoStatusesFields = [
    'statusUnprocessed',
    'statusUnconfirmed',
    'statusWaitCooking',
    'statusReadyForCooking',
    'statusCookingStarted',
    'statusCookingCompleted',
    'statusWaiting',
    'statusOnWay',
    'statusDelivery',
    'statusClosed',
    'statusCancelled',
  ]

  const iikoStatusesSelectInit = () => {
    if (data?.integrations?.iiko && company?.statuses) {
      let statuses = (company?.statuses.map(status => ({
        label: `${ status.title } (${ status.code })`,
        value: status.code
      })))

      iikoStatusesFields.forEach(field => {
        if (data.integrations.iiko[field] && ! data.integrations.iiko[`__${ field }_select`]) {
          updateField(`integrations.iiko.__${ field }_select`, statuses.filter(status => status.value == data.integrations.iiko[field]).at(0))
        }
      });
    }
  }

  useEffect(() => {
    iikoStatusesSelectInit()
  }, [data, company])

  const [locationSources, setLocationSources] = useState([])
  useEffect(() => {
    fetchLocationSources().then(data => {
      setLocationSources(data)
    })
  }, [])

  useEffect(() => {
    if (locationSources.length && data?.integrations?.locationSource && ! data?.integrations?.__locationSource_select) {
      updateField('integrations.__locationSource_select', locationSources.filter(((item: any) => item.value == data?.integrations?.locationSource)).at(0))
    } else if (locationSources.length && ! data?.integrations?.locationSource && ! data?.integrations?.__locationSource_select) {
      updateField('integrations.__locationSource_select', locationSources.at(0))
    }
  }, [data])

  const renderIntegration = () => {
    switch (data?.integrations?.defaultExternalIntegration) {
      case 'frontpad':
        return (
          <Frontpad
            data={ data }
            updateField={ updateField }
            errors={ errors }
          />
        );
      case 'partner':
        return (
          <Partner
            data={ data }
            updateField={ updateField }
            errors={ errors }
          />
        );
      case 'iiko':
        return (
          <IIKO
            data={ data }
            updateField={ updateField }
            errors={ errors }
          />
        );
      case 'mobiDel':
        return (
          <MobiDel
            data={ data }
            updateField={ updateField }
            errors={ errors }
          />
        );
      case 'sbisPresto': {
        return (
          <SBISPresto data={ data } updateField={ updateField } errors={ errors }/>
        )
      }
      default:
        return (<></>)
    }
  }

  return (
    <div className={ 'flex flex-col px-3 gap-y-6' }>
      {

        <>
          <div className='flex flex-col gap-y-2'>
            <div className='font-bold'>Уведомления об ошибках</div>
            <TextInputsList
              label='E-mail'
              textTooltip='E-mail'
              placeholder='example@exp.com'
              name={ 'notifications.emailsForErrorNotifications' }
              errors={ errors }
              onChange={ (value) => updateField('notifications.emailsForErrorNotifications', value) }
              values={ data.notifications.emailsForErrorNotifications || [] }
            />

            <TextInputsList
              label='VK пользователь'
              textTooltip='VK пользователь'
              placeholder='ID'
              name={ 'notifications.vkForErrorNotifications' }
              errors={ errors }
              onChange={ (value) => updateField('notifications.vkForErrorNotifications', value) }
              values={ data.notifications.vkForErrorNotifications || [] }
            />
            <div className={ `py-3 px-4 border-gray-20 border-[1px] rounded-lg` }>
              <TextInput
                label='Telegram ID'
                textTooltip='Telegram ID'
                placeholder='@'
                name={ 'notifications.tgForErrorNotifications' }
                errors={ errors }
                onChange={ (value) => updateFieldNoRender('notifications.tgForErrorNotifications', value) }
                value={ data.notifications.tgForErrorNotifications }
              />
            </div>
          </div>

          <SingleSimpleValueSelect
            textTooltip='Интеграция с внешней црм'
            label='Интеграция с внешней црм'
            placeholder='Выберите интеграцию'
            name={ 'integrations.defaultExternalIntegration' }
            errors={ errors }
            onChange={ (option: any) => {
              updateField('integrations.defaultExternalIntegration', option)
            } }
            value={ data.integrations?.defaultExternalIntegration || '' }
            options={ [
              { label: 'Frontpad', value: 'frontpad' },
              { label: 'Partner', value: 'partner' },
              { label: 'IIKO', value: 'iiko' },
              { label: 'MobiDel', value: 'mobiDel' },
              { label: 'СБИС Presto', value: 'sbisPresto' },
              { label: 'Не использовать', value: '' },
            ] }
          />

          { renderIntegration() }
        </>
      }

    </div>
  )
}
