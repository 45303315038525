import React, {useEffect, useState} from "react";
import SimpleMultipleValueSelect from "../../../../components/common/form/select/multiple/SimpleMultipleValueSelect";
import {useBranchesContext} from "../../../../contexts/Branches";
import TextInput from "../../../../components/common/form/input/TextInput";
import SingleSimpleValueSelect from "../../../../components/common/form/select/SingleSimpleValueSelect";
import {apiClient} from "../../../../libs/api/apiClient";

export default function Base({data, errors, updateField, updateFieldNoRender}: any) {
  const { branches} = useBranchesContext()

  const [allOutputTypes, setAllOutputTypes] = useState([])
  useEffect(() => {
    apiClient.dictionaries.outputTypes().then(({data}) => {
      setAllOutputTypes(data);
    })
  }, [])

  return (
    <div className={'flex flex-col px-3 gap-y-6'}>
      <TextInput
        label={'Название CRM'}
        textTooltip={'Название CRM'}
        placeholder={'Введите название CRM'}
        name={'innerTitle'}
        value={data.innerTitle}
        onChange={value => updateFieldNoRender('innerTitle', value)}
        errors={errors}
        required={true}
      />
      <TextInput
        label={'Название группы “SKU”'}
        textTooltip={'Название группы “SKU”'}
        placeholder={'Введите название опции'}
        name={'title'}
        value={data.title}
        onChange={value => updateFieldNoRender('title', value)}
        errors={errors}
        required={true}
      />
      <SingleSimpleValueSelect
        label={'Тип вывода'}
        textTooltip={'Тип вывода'}
        name={'outputType'}
        placeholder={'Селект'}
        value={data.outputType || ''}
        options={allOutputTypes.map(({title, value}) => ({label : title, value}))}
        onChange={value => updateField('outputType', value)}
        errors={errors}
        required={true}
      />
      <SimpleMultipleValueSelect
        label={'Филиалы'}
        textTooltip={'Филиалы'}
        options={branches.map(branch => ({label: branch.title, value: branch.id}))}
        value={data.branches || []}
        onChange={value => updateField('branches', value)}
        name={'branches'}
        placeholder={'Выберите филиалы'}
        errors={errors}
        required={true}
      />
    </div>
  )
}
