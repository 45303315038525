//

export const initCreateForm = {
  base: {},
  extended: {
    regions: [],
    orderCities: [],
    openHours: {
      monday: {
        active: true,
        hours: ['00:00-24:00']
      },
      tuesday: {
        active: true,
        hours: ['00:00-24:00']
      },
      wednesday: {
        active: true,
        hours: ['00:00-24:00']
      },
      thursday: {
        active: true,
        hours: ['00:00-24:00']
      },
      friday: {
        active: true,
        hours: ['00:00-24:00']
      },
      saturday: {
        active: true,
        hours: ['00:00-24:00']
      },
      sunday: {
        active: true,
        hours: ['00:00-24:00']
      },
    },
    addresses: [],
    paymentDescription: [],
    deliveryDescription: [],
    stockDescription: [],
    allowOrderAfterHours: false,
    allowAssignPhone: true,
    allowGuestOrderInMobile: true,
    allowedAuthorizeTypes: ["sms", "call", "vk", "tg"],
    vkCommunities: [],
  },
  notifications: {
    vkAdministrators: '',
    vkAppOpenedNotify: true,
    emailsForServiceNotifications: '',
    emailsForErrorNotifications: '',
    vkForErrorNotifications: '',
    tgChatIdsForSystemNotifications: [],
  },
  contacts: {
    items: []
  },
  order: {
    pickupOnly: false,
    hideDeliveryPrice: false,
    preorderAllowedTimes: [],
    fields: {
      clientEmail: {isUsed: true, isRequired: true},
      entrance: {isUsed: true, isRequired: true},
      personsCount: {isUsed: true, isRequired: true},
      floor: {isUsed: true, isRequired: true},
      comment: {isUsed: true, isRequired: true},
      flat: {isUsed: true, isRequired: true},
      changeFromBill: {isUsed: true, isRequired: true},
      promoCode: {isUsed: true, isRequired: true},
      // deliveryZone: {isUsed: true, isRequired: true},
      deliveryDate: {isUsed: true, isRequired: true},
      deliveryType: {isUsed: true, isRequired: true},
    },
    deliveryTypes: '',
    pickupPoints: [],
    paymentTypes: ['cash'],
    usePickup: true,
    useCourier: true,
  },
  integrations: {
    keys: {},
    frontpad: {},
    iiko: {},
    partner: {},
    senler: {
      //idsOfGroupsWhoUsePromocodes: [],
      //idsOfGroupsWhoOpenCatalog: [],
      //idsOfGroupsWhoIseFilterByTag: [],
    },
    thModerator: {
      idsOfBotsWhoUsePromocodes: [],
      idsOfBotsWhoOpenCatalog: [],
      idsOfBotsWhoIseFilterByTag: [],
    },
    smsService: {
      __code_select: {label: 'Не использовать', value: null}
    },
    phoneCallService: {
      __code_select: {label: 'Не использовать', value: null}
    }
  },
  paymentSystems: {
    sendOnlyAfterPayment: false,
    sberbank: {}
  },
  messageTemplates: {},
}

export const initUpdateForm = {
  base: {},
  extended: {
    regions: [],
    orderCities: [],
    openHours: {
      monday: {
        active: true,
        hours: ['00:00-24:00']
      },
      tuesday: {
        active: true,
        hours: ['00:00-24:00']
      },
      wednesday: {
        active: true,
        hours: ['00:00-24:00']
      },
      thursday: {
        active: true,
        hours: ['00:00-24:00']
      },
      friday: {
        active: true,
        hours: ['00:00-24:00']
      },
      saturday: {
        active: true,
        hours: ['00:00-24:00']
      },
      sunday: {
        active: true,
        hours: ['00:00-24:00']
      },
    },
    addresses: [],
    paymentDescription: [],
    deliveryDescription: [],
    stockDescription: [],
    allowOrderAfterHours: false,
    allowAssignPhone: true,
    allowGuestOrderInMobile: true,
    allowedAuthorizeTypes: ["sms", "call", "vk", "tg"],
    vkCommunities: [],
  },
  notifications: {
    vkAdministrators: [],
    vkAppOpenedNotify: true,
    emailsForServiceNotifications: [],
    emailsForErrorNotifications: [],
    vkForErrorNotifications: [],
  },
  contacts: {
    items: []
  },
  order: {
    pickupOnly: false,
    hideDeliveryPrice: false,
    preorderAllowedTimes: [],
    fields: {
      email: {isUsed: true, isRequired: true},
      pickup: {isUsed: true, isRequired: true},
      //delivery: {isUsed: true, isRequired: true},
      //deliveryZone: {isUsed: true, isRequired: true},
      pickupPoint: {isUsed: true, isRequired: true},
      deliveryDate: {isUsed: true, isRequired: true},
      deliveryTime: {isUsed: true, isRequired: true},
      city: {isUsed: true, isRequired: true},
      street: {isUsed: true, isRequired: true},
      house: {isUsed: true, isRequired: true},
      //building: {isUsed: true, isRequired: true},
      //entrance: {isUsed: true, isRequired: true},
      //floor: {isUsed: true, isRequired: true},
      //flat: {isUsed: true, isRequired: true},
      changeFromBill: {isUsed: true, isRequired: true},
      personsCount: {isUsed: true, isRequired: true},
      promocode: {isUsed: true, isRequired: true},
      comment: {isUsed: true, isRequired: true},
      //confirmOrderWithoutCall: {isUsed: true, isRequired: true},
      //contactlessDelivery: {isUsed: true, isRequired: true},
      //birthDate: {isUsed: true, isRequired: true},
    },
    deliveryTypes: [],
    pickupPoints: [],
    paymentTypes: ['cash']
  },
  integrations: {
    keys: {},
    frontpad: {},
    iiko: {},
    partner: {},
    senler: {
      //idsOfGroupsWhoUsePromocodes: [],
      //idsOfGroupsWhoOpenCatalog: [],
      //idsOfGroupsWhoIseFilterByTag: [],
    },
    thModerator: {
      idsOfBotsWhoUsePromocodes: [],
      idsOfBotsWhoOpenCatalog: [],
      idsOfBotsWhoIseFilterByTag: [],
    },
    smsService: {
      __code_select: {label: 'Не использовать', value: null}
    },
    phoneCallService: {
      __code_select: {label: 'Не использовать', value: null}
    }
  },
  paymentSystems: {
    sendOnlyAfterPayment: false,
    yooKassa: {}
  },
  messageTemplates: {},
}

export default {initCreateForm, initUpdateForm}
