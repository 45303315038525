export function getFormData(action = 'create', object: object, formData: any, outerKey: string | null = null) {
  Object.keys(object).reduce((formData, key) => {
    if (null === object[key]) {
      //console.log(`key = ${key} value ${object[key]}`)
      if (key.slice(0, 2) !== '__') {
        if (outerKey) {
          formData.append(`${outerKey}[${key}]`, null)
        } else {
          formData.append(`${key}`, '')
        }
      }
      return formData;
    }
    /*проверка на пустой массив. Протестить чтобы нигде не заговнило отправку данных*/
    if ('object' === typeof object[key] && object[key] instanceof Array && !object[key].length && action === 'update') {
      if (key.slice(0, 2) !== '__') {
        if (outerKey) {
          formData.append(`${outerKey}[${key}]`, [])
        } else {
          formData.append(`${key}`, [])
        }
      }
      return formData
    }

    if ('object' === typeof object[key] && !(object[key] instanceof File) && !(object[key] instanceof Date) && key.slice(0, 2) !== '__') {
      if (outerKey) {
        return getFormData(action, object[key], formData, `${outerKey}[${key}]`)
      }

      return getFormData(action, object[key], formData, key)
    }

    let value = object[key];

    /* Чтобы не перезаписывать родительский объект через ассоциацию */
    if ('boolean' === typeof value) {
      value = +value
    }

    if (key.slice(0, 2) !== '__') {
      if (outerKey) {
        formData.append(`${outerKey}[${key}]`, value)
        return formData;
      }

      formData.append(key, value);
      return formData;
    }
    return formData;
  }, formData);

  return formData;
}
