import React from 'react'
import { Link } from 'react-router-dom'
import { useFormContext } from '../../../contexts/Form'

export default function MenuLink({ icon, label, url, currentUrl }) {
  const { checkIsFormDirty, openDialog } = useFormContext();

  return (
    <Link to={url} onClick={(event) => {
      if (checkIsFormDirty()) {
        event.preventDefault()
        openDialog(url)
      }
    }}>
      <div
        className={`${currentUrl === url && 'bg-zinc-900'} flex items-center h-16 w-full text-white cursor-pointer hover:bg-zinc-900`}
      >
        <div className='h-full w-16 flex items-center justify-center'>
          <img src={icon} alt="" />
        </div>
        <div className='grow font-medium'>{label}</div>
      </div>
    </Link>
  )
}
