import React from "react";
import BaseButton from "../../../Button/BaseButton"
import useForm from '../../../../../hooks/useForm'
import TextInput from "../../../form/input/TextInput";
import SimpleMultipleValueSelect from "../../../form/select/multiple/SimpleMultipleValueSelect";
import SingleSimpleValueSelect from "../../../form/select/SingleSimpleValueSelect";


interface IProps {
    filtersData?: any
    submit: Function
}

interface IFilterProps {
    label?: string|null
    input?: string
    name: string
    placeholder?: string|null
    required?: boolean
    items?: Array<any>
    selectingInModal?: boolean
}

export default function Filters({filtersData, submit}: IProps) {

    const {data, updateField} = useForm({})

    const getPropByName = (obj: object, prop: string): any => {
        if (typeof obj === 'undefined' || !prop) {
            return null
        }

        const _index = prop.indexOf('.')
        if (_index > -1) {
            return getPropByName(obj[prop.substring(0, _index)], prop.substring(_index + 1));
        }

        return obj[prop]
    }

    const renderFilter = (filter: IFilterProps) => {
        const currValue = getPropByName(data, filter?.name)
        
        if (filter.input == 'text') {
            return <TextInput
                name={`${filter.name}`}
                type={'text'}
                className={'w-[45%]'}
                classNameInput="h-[46px]"
                value={currValue || ''}
                onChange={(value: any) => {
                    updateField(filter.name, value);
                } }
                label={filter?.label || ''}
                textTooltip={filter?.label || ''}
                required={filter?.required || false} 
                placeholder={filter?.placeholder || ''}
                errors={{}}            
            />
        }

        if (filter.input == "multiple-select") {
            return <SimpleMultipleValueSelect
                    className={'w-[45%]'}
                    label={filter?.label || ''}
                    textTooltip={filter?.label || ''}
                    options={filter?.items?.map((item: any) => ({ label: item.title, value: item.value }))}
                    value={currValue || []}
                    onChange={(value: any) => {
                        if (value == '*') {
                            value = filter?.items?.map((item: any) => (item.value ))
                        }

                        updateField(filter?.name, value)
                    }}
                    name={filter?.name}
                    placeholder={filter?.placeholder || ''}
                    errors={{}}
                    wordsArray={['выбрано', 'выбрано', 'выбрано']}
                />
        }

        if (filter.input == 'single-select') {
            return <SingleSimpleValueSelect
                className={'!w-[45%]'}
                label={filter?.label || ''}
                textTooltip={filter?.label || ''}
                options={filter?.items?.map((item: any) => ({ label: item.title, value: item.value }))}
                value={currValue || []}
                onChange={(value: any) => {
                    updateField(filter?.name, value)
                }}
                name={filter?.name}
                placeholder={filter?.placeholder || ''}
                errors={{}}
                removeValue={() => {
                    updateField(filter?.name, null)
                }}
                removeItem={true}
            />
        }
    }

    if (!filtersData || filtersData?.length == 0) {
        return <></>
    }

    return (
        <div className="flex flex-col gap-y-2 items-end mb-3">
            <div className={`flex flex-row flex-wrap justify-between items-center gap-y-2 gap-x-2 w-full`}>
                {
                    filtersData?.map((filter: IFilterProps) => renderFilter(filter))
                }
                <BaseButton
                    className={'w-fit bg-interactive-elem border-2 border-interactive-elem text-white py-[7px]'}
                    onClick={() => submit(data)}>
                    Применить
                </BaseButton>
            </div>
            
        </div>
        
    )
}