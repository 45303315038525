import React, {useEffect, useId, useRef, useState} from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel, Row
} from '@tanstack/react-table'
import firstPageIcon from '../../../images/pagination-first-page.svg'
import prevPageIcon from '../../../images/pagination-prev-page.svg'
import  nextPageIcon from '../../../images/pagination-next-page.svg'
import lastPageIcon from '../../../images/pagination-last-page.svg'
import ascOrderIcon from '../../../images/sort_asc.svg'
import descOrderIcon from '../../../images/sort-desc.svg'
import Checkbox from "../form/checkbox/Checkbox";
import TextInput from "../form/input/TextInput";
import { showWarningToast } from "../../../libs/helpers/toasts";
import RadioButton from "../form/radio/RadioButton";

interface IDataTableProps {
  data: Array<any>,
  columns: any,
  updateData: Function,
  pagination?: any,
  loading?: boolean,
  onChangeSelectionRows?: Function,
  selectedRows?: Array<any>,
  defaultPage?: number,
  defaultLimit?: number,
  usePagination?: boolean,
  useMassAction?: boolean,
  useSingleSelect?: boolean,
  useSortable?: boolean,
  selectedRow?: any,
  selectRow?: Function,
  classNameTable?: string,
  heightHeaderRow?: string,
  defaultOrder?: any,
  useExpanded?: boolean,
  expandedComponent?: (props: { row: Row<any> }) => JSX.Element
  classNameRow?: string
  rowsWithCustomBg?: rowWithCustomBg[],
  rowIdentifierField?: string
  fixedHeader?: string,
  currentPage?: number,
  setCurrentPage?: (p: number) => void
}

type rowWithCustomBg = {
  id: any,
  color: string
}

export default function DataTable(
  {
    data,
    columns,
    updateData,
    pagination = null,
    loading = false,
    onChangeSelectionRows = (rows: any) => {},
    selectedRows = [],
    defaultPage = 1,
    defaultLimit = 10,
    usePagination = true,
    useMassAction = false,
    useSingleSelect = false,
    selectedRow = null,
    selectRow,
    classNameTable = 'mt-8',
    heightHeaderRow = 'h-16',
    defaultOrder = {},
    useExpanded = false,
    expandedComponent = (row: any) => <div></div>,
    classNameRow = '',
    rowIdentifierField = 'id',
    fixedHeader = '',
    rowsWithCustomBg = [],
    useSortable = true,
    ...props
  }: IDataTableProps) {
  const [sorting, setSorting] = useState<any>(defaultOrder)
  const [totalPage, setTotalPage] = useState(1)
  const [totalRows, setTotalRows] = useState(0)
  const [currentPage, setCurrentPage] = useState(defaultPage)
  const [paginationPage, setPaginationPage] = useState(defaultPage)
  const [limit, setLimit] = useState(defaultLimit)
  const [changeLimit, setChangeLimit] = useState(false)
  const [changePage, setChangePage] = useState(false)
  const [rowSelection, setRowSelection] = useState(selectedRows)
  const id = useId()
  const datatableRef = useRef<any>(null)

  const changeSort = (field: string) => {
    let sortingField = {}
    if (sorting[field]) {
      sortingField[field] = sorting[field] === 'desc' ? 'asc' : 'desc'
    } else {
      sortingField[field] = 'desc'
    }
    setSorting(sortingField)
  }

  const changePageSize = () => {
    if (limit < 1 || limit > totalRows) {
      showWarningToast({
        content: `Количество результатов должно быть в диапазоне от 1 до ${totalRows}`
      })
      setChangeLimit(false)
      setLimit(defaultLimit)
      return
    }

    setChangeLimit(false)
    updateData({
      limit,
      page: 1,
      order: sorting
    })
  }

  const changeSortingOrCurrentPage = () => {
    if (!useSortable) {
      return
    }

    let params: any = {
      order: Object.keys(sorting).length ? sorting : null
    }
    if (usePagination) {
      params.limit = limit;
      params.page = currentPage;
    }

    updateData(params)
  }

  useEffect(() => {
    if (usePagination) {
      setTotalPage(pagination?.last_page)
      setTotalRows(pagination?.total)
      if (pagination?.current_page && currentPage != pagination?.current_page) {
        setCurrentPage(pagination.current_page)
      }
    }
  }, [JSON.stringify(pagination)])

  useEffect(() => {
    setPaginationPage(currentPage)
    changeSortingOrCurrentPage()
  }, [sorting, currentPage])

  useEffect(() => {
    onChangeSelectionRows(rowSelection)
  }, [rowSelection?.join(',')])

  useEffect(()=> {
    setRowSelection(selectedRows)
  }, [selectedRows?.join(',')])

  const toggleAllRows = () => {
    if (rowSelection.length === table.getRowModel().rows.length) {
      setRowSelection([])
    } else {
      let rows = table.getRowModel().rows.map((cell: any) => {
        return cell?.original[rowIdentifierField]
      })
      setRowSelection(rows)
    }
  }

  const toggleRow = (id: any, checked: any) => {
    let rows = rowSelection.slice()
    if (!checked) {
      const index = rows.findIndex((item: any) => item == id)
      rows.splice(index, 1)
    } else {
      rows.push(id)
    }
    setRowSelection(rows)
  }

  const table = useReactTable<any>({
    data,
    columns,
    getRowCanExpand: () => useExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: useExpanded ? getExpandedRowModel() : undefined,
    manualPagination: true,
    autoResetPageIndex: false,
    pageCount: totalPage || -1
  })

  return (
    <div id={id} ref={datatableRef} className={`flex flex-col`}>
      <div key={id} className={`w-full bg-white rounded-2xl text-sm font-medium ${!usePagination ? 'overflow-y-auto pretty-scroll max-h-[calc(100vh-300px)] pr-4' : ''} ${classNameTable}`}>
        {table.getHeaderGroups().map((headerGroup) => (
          <div className={`flex border-b-[1px] border-gray-30 ${heightHeaderRow} ${fixedHeader}`} key={headerGroup.id}>
            {useMassAction ?
              <div className={'flex items-center justify-center pl-3'}>
                <Checkbox
                  name={''}
                  onChange={() => {toggleAllRows()}}
                  className={''}
                  disabled={false}
                  value={rowSelection.length == table?.getRowModel()?.rows?.length} />
              </div> : <></>
            }
            {
              useSingleSelect ? <div className="w-[30px] pl-3"></div> : <></>
            }
            {headerGroup.headers.map((header: any) => (
              <div
                onClick={() => {
                  if (header.column?.columnDef?.meta?.sortField) {
                    changeSort(header.column?.columnDef?.meta?.sortField)
                  }
                }}
                className={`${header.column.columnDef?.meta?.widthClass} flex items-center text-center`}
                style={header.column.columnDef?.meta?.style || null}
                key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                {header.column.getCanSort() ?
                  <div className={'ml-3 cursor-pointer'}>
                    <img src={sorting[header.column?.columnDef?.meta?.sortField] && sorting[header.column?.columnDef?.meta?.sortField] === 'desc' ? descOrderIcon : ascOrderIcon} alt="" />
                  </div>
                  : <></>
                }
              </div>
            ))}
          </div>
        ))
        }
        {loading ?
          <div className="flex min-h-[4rem] border-b-[1px] border-gray-30">
            <span className={'flex justify-center items-center w-full'}>Загрузка...</span>
          </div>
          : !table.getRowModel()?.rows.length ?
            <div className="flex min-h-[4rem] border-b-[1px] border-gray-30">
              <span className={'flex justify-center items-center w-full'}>Данные отсутствуют</span>
            </div>
            : table.getRowModel().rows.map(row => (
              <div>
                <div className={`flex min-h-[4rem] ${classNameRow} border-b-[1px] border-gray-30 ${rowsWithCustomBg && rowsWithCustomBg?.find(({id}: any) => row?.original[rowIdentifierField] == id)?.color || ''}`} key={row.id}>
                  {useMassAction ?
                    <div className={'flex items-center justify-center pl-3 hover:z-50'}>
                      <Checkbox
                        name={''}
                        onChange={(value: any) => {toggleRow(row?.original[rowIdentifierField], value)}}
                        className={''}
                        disabled={false}
                        value={!(typeof rowSelection.find((id: any) => id == row?.original[rowIdentifierField]) == 'undefined')} />
                    </div> : <></>
                  }

                  {useSingleSelect ?
                    <div className={'flex items-center justify-center pl-3'}>
                      <RadioButton
                        className={'flex items-center justify-center'}
                        name={''}
                        value={selectedRow === row?.original[rowIdentifierField]}
                        onChange={() => {
                          selectRow && selectRow(row?.original[rowIdentifierField] || null)
                        }}
                        errors={{}} 
                      />
                    </div> : <></>
                  }

                  {row.getVisibleCells().map((cell: any) => (
                    flexRender(cell.column.columnDef.cell, cell.getContext())
                  ))}
                </div>
                {useExpanded && row.getIsExpanded() && (
                  <div className="flex min-h-[4rem] border-b-[1px] border-gray-30">
                    {expandedComponent({ row })}
                  </div>
                )}
              </div>
          ))}
        {
          usePagination && (
            <div className={'flex min-h-[60px] items-center justify-between gap-y-4 px-[20px]'}>
              <div className={'flex'}>
                <span>Выведено {table.getRowModel().rows.length < limit ? totalRows : pagination.to} из {totalRows} записей</span>
              </div>
              <div className={'flex gap-x-4 items-center'}>
                <div className={'flex items-center'}>
                  <span className={'mr-2'}>Показывать результатов</span>
                  <TextInput 
                    type={'number'}
                    min={1}
                    name={'selectResultCount'}
                    value={Number(limit).toString()} 
                    errors={{}}
                    className={`max-w-[70px] min-w-[70px]`}
                    classNameInput={`[appearance:textfield] text-center [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                    onChange={(value: any) => {
                      setChangeLimit(true)
                      setLimit(Number(value))
                    }}     
                    onKeyDown={(event) => {
                      if (event.key === 'Enter' && changeLimit) {
                        changePageSize()
                      }
                    }}  
                    onBlur={() => {
                      if (changeLimit) {
                        changePageSize()
                      }
                    }}       
                  />
                </div>
                <div className={'flex gap-x-2 items-center'}>
                  <div className={`flex flew-row items-center ml-3`}>
                    <span className={'mr-2'}>Страница</span>
                    <TextInput
                      type={'number'}
                      min={1}
                      max={totalPage || 1}
                      name={'selectPage'}
                      value={Number(paginationPage).toString()}
                      errors={{}}
                      className={`max-w-[50px] min-w-[50px]`}
                      classNameInput={`[appearance:textfield] text-center [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                      onChange={(value: any) => {
                        setChangePage(true)
                        setPaginationPage(Number(value))
                      }}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter' && changePage) {
                          if (!paginationPage || paginationPage < 1 || paginationPage > totalPage) {
                            showWarningToast({
                              content: `Страница пагинации должна быть в диапазоне от 1 до ${totalPage}`
                            })
                            setChangePage(false)
                            setPaginationPage(currentPage)
                            return
                          }
                          setCurrentPage(paginationPage || currentPage)
                        }
                      }}  
                      onBlur={() => {
                        if (changePage) {
                          if (!paginationPage || paginationPage < 1 || paginationPage > totalPage) {
                            showWarningToast({
                              content: `Страница пагинации должна быть в диапазоне от 1 до ${totalPage}`
                            })
                            setChangePage(false)
                            setPaginationPage(currentPage)
                            return
                          }
                          setCurrentPage(paginationPage || currentPage)
                        }
                      }}
                    />
                    {totalPage ? <span className={'ml-2'}>из {totalPage}</span> : <></>}
                  </div>

                  <div className={`flex flex-row items-center gap-x-2 ml-3`}>
                    <button
                      onClick={() => setCurrentPage(currentPage - 1)}
                      disabled={currentPage - 1 < 1}
                      className={'h-[32px] w-[32px] flex items-center justify-center rounded-full bg-white disabled:opacity-[0.6] disabled:pointer-events-none cursor-pointer border border-interactive-elem'}>
                      <img src={prevPageIcon} alt="" />
                    </button>

                    <button
                      onClick={() => setCurrentPage(currentPage + 1)}
                      disabled={currentPage + 1 > totalPage}
                      className={'h-[32px] w-[32px] flex items-center justify-center rounded-full bg-white disabled:opacity-[0.6] disabled:pointer-events-none cursor-pointer border border-interactive-elem'}>
                      <img src={nextPageIcon} alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
}
